import React, { ReactElement } from 'react';
import { Text, StyleSheet } from 'react-native';
import _fonts from '../../../../../_fonts';
import { Theme, useThemeStyle, WW } from '../../../../../_utils';

const SectionTitle = ({ children }: { children: string }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return <Text style={themedStyle.title}>{children}</Text>;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        title: {
            color: theme.main,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.04,
            marginBottom: WW * 0.01,
            marginTop: WW * 0.03,
        },
    });

export default SectionTitle;
