import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { PrimaryButton, SecondaryButton } from 'Components';

const ManageButtons = ({
    status,
    isInFuture,
    compact,
    onCheckIn,
    onCheckOut,
    onUpdate,
    onCancel,
}: {
    status: 'shouldCheckIn' | 'canCheckOutEditable' | 'canCheckOut' | 'checkedOut' | 'editable';
    isInFuture: boolean;
    compact: boolean;
    onCheckIn: () => void;
    onCheckOut: () => void;
    onUpdate: () => void;
    onCancel: () => void;
}) => {
    const { t } = useTranslation();

    const containerStyle = {
        gap: smallestMargin,
        flexGrow: 0,
        paddingLeft: screenMargin,
        paddingRight: screenMargin,
        display: 'flex',
    } as const;

    const compactButtonsStyle = { flex: 1, flexBasis: '40%' } as const;

    const compactButtons = compact || status === 'canCheckOutEditable';
    const editableButtons = (
        <View style={[{ gap: smallestMargin }, compactButtons ? { flexDirection: 'row' } : undefined]}>
            <SecondaryButton
                text={t('expandedReservation:change')}
                icon="editPencil"
                onPress={onUpdate}
                style={compactButtons ? compactButtonsStyle : undefined}
            />
            <TouchableOpacity
                style={compactButtons ? compactButtonsStyle : undefined}
                onPress={isInFuture ? undefined : onCancel}
            >
                <SecondaryButton
                    status={isInFuture ? null : 'disabled'}
                    text={t('expandedReservation:cancel')}
                    icon="cross"
                    onPress={onCancel}
                    variant="danger"
                />
            </TouchableOpacity>
        </View>
    );

    switch (status) {
        case 'shouldCheckIn':
            return (
                <View style={containerStyle}>
                    <PrimaryButton onPress={onCheckIn} text={t('expandedReservation:checkIn')} />
                </View>
            );
        case 'canCheckOut':
        case 'canCheckOutEditable':
            return (
                <View style={containerStyle}>
                    {status === 'canCheckOutEditable' ? editableButtons : null}
                    <SecondaryButton text={t('expandedReservation:checkOut')} onPress={onCheckOut} />
                </View>
            );
        case 'editable':
            return <View style={containerStyle}>{editableButtons}</View>;
        case 'checkedOut':
            return null;
    }
};

export default ManageButtons;
