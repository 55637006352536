import React, { ComponentProps, memo, ReactElement, useRef } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Menu } from 'react-native-popup-menu';
import { FileLink } from 'types/Base';
import {
    AttachmentBubble,
    Bubble,
    LinkPreview,
    MessageAuthor,
    MessageText,
    SenderAvatar,
    ChatContextMenu,
    BaseContainer,
    DeletedMessage,
} from './components';
import { fileIsImage, fromNowFormat, isWeb, mapFromMessageFileToHeimeFile, useDocumentsView } from '../../_utils';
import { getUsernameFromProfile } from '../../_utils/misc';
import { DeletedUser, ExternalUserProfile, UserCollectionItemWithAvatar } from '../../types/User';
import { UnixTimeCode } from '../../types/Utility';
var linkify = require('linkify-it')();

interface MessageBubbleProps {
    author: ExternalUserProfile | DeletedUser | UserCollectionItemWithAvatar | null;
    isSelf: boolean;
    content: string;
    created_at: UnixTimeCode;
    files: FileLink[];
    onImagePress(index: number): void;
    displayTimeMode: 'onlyHour' | 'full';
    contextActions?: ComponentProps<typeof ChatContextMenu>['contextActions'];
    showAuthor?: boolean;
    edited: boolean;
    deleted: boolean;
    deletedMessage: string;
}

const MessageBubble = ({
    author,
    isSelf,
    content,
    created_at,
    files,
    onImagePress,
    displayTimeMode,
    edited,
    contextActions = [],
    showAuthor = true,
    deleted,
    deletedMessage,
}: MessageBubbleProps): ReactElement => {
    const { t } = useTranslation();
    const ref = useRef<Menu>(null);
    const senderName = author ? ('fname' in author ? getUsernameFromProfile(author) : author.name) : '';

    const hourFormatted =
        displayTimeMode === 'onlyHour'
            ? moment.unix(created_at).format('HH:mm')
            : fromNowFormat(moment.unix(created_at).toDate(), true);

    const [loader, handleDocumentPress] = useDocumentsView();

    const contentLink = linkify.test(content) ? linkify.match(content)[0] : '';
    const allContentIsLink = contentLink.raw === content;

    const handleLongPress = () => {
        ref.current?.open();
    };

    return (
        <BaseContainer isMe={isSelf}>
            {isSelf ? undefined : (
                <SenderAvatar
                    senderName={senderName}
                    userId={author && typeof author.id === 'number' ? author.id : undefined}
                    avatarUrl={author && 'avatar' in author ? author.avatar : undefined}
                />
            )}
            {deleted ? (
                <DeletedMessage leftMargin={!isSelf} message={deletedMessage} />
            ) : (
                <ChatContextMenu menuRef={ref} contextActions={contextActions}>
                    <Bubble
                        author={showAuthor ? <MessageAuthor authorName={senderName} /> : undefined}
                        content={
                            content && !allContentIsLink ? (
                                <MessageText
                                    selectable={(contextActions.length === 0 || isWeb()) && !deleted}
                                    text={content}
                                    isOwn={isSelf}
                                />
                            ) : undefined
                        }
                        time={(edited ? t('chat:edited') : '') + hourFormatted}
                        senderAvatar={!isSelf}
                        isMe={isSelf}
                        attachment={
                            <>
                                {loader}
                                {files.length > 0 ? (
                                    files.map((file, index) => {
                                        const isImage = fileIsImage({
                                            ...file,
                                            status: 'added',
                                            index: 0,
                                            uri: file.original,
                                        });
                                        return (
                                            <AttachmentBubble
                                                onLongPress={handleLongPress}
                                                key={file.id}
                                                onPress={() => {
                                                    if (isImage) {
                                                        onImagePress(index);
                                                    } else {
                                                        const mapped = mapFromMessageFileToHeimeFile(file);
                                                        handleDocumentPress(mapped);
                                                    }
                                                }}
                                                type={isImage ? 'media' : 'files'}
                                                file={file}
                                                isFirst={index === 0}
                                            />
                                        );
                                    })
                                ) : contentLink ? (
                                    <LinkPreview
                                        onLongPress={handleLongPress}
                                        link={contentLink.url}
                                        allContentIsLink={allContentIsLink}
                                    />
                                ) : null}
                            </>
                        }
                    />
                </ChatContextMenu>
            )}
        </BaseContainer>
    );
};

export default memo(MessageBubble);
