import React, { ReactElement } from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import _fonts from '../../_fonts';
import { Theme, useThemeStyle, WW } from '../../_utils';
import { smallestFontSize } from '../../_utils/sizes';

const DocumentItem = ({
    onPress,
    icon,
    title,
    desc,
}: {
    onPress: () => void;
    icon: ReactElement;
    title: string;
    desc: string;
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <TouchableOpacity onPress={onPress} style={themedStyle.documentContainer}>
            {icon}
            <Text style={themedStyle.titleText}>{title}</Text>
            <Text style={themedStyle.sizeText}>{desc}</Text>
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        documentContainer: {
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
            paddingTop: WW * 0.06,
            paddingBottom: WW * 0.06,
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottomColor: theme.lightGrey,
            borderBottomWidth: 1,
        },
        titleText: {
            flex: 1,
            fontFamily: _fonts.primaryFontBold,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            fontSize: WW * 0.05,
            color: theme.main,
        },
        sizeText: {
            fontFamily: _fonts.primaryFont,
            fontSize: smallestFontSize,
            color: theme.main,
        },
    });

export { DocumentItem };
