import React, { ReactElement, useContext } from 'react';
import { Text, View, StyleSheet, FlatList } from 'react-native';
import { ChatInfo } from '_api/useChats';
import { useOwnProfile } from '_api/useProfile';
import _fonts from '_fonts';
import Images from '_images';
import { useThemeStyle, WW } from '_utils';
import { Theme, ThemeContext } from '_utils/themeContext';
import { CacheImage } from 'Components';
import { UserCollectionItemWithAvatar } from 'types/User';

interface ActionImageProps {
    chatInfo: ChatInfo;
    userId: number;
}

const ActionImage = ({ chatInfo, userId }: ActionImageProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const groupChat = chatInfo.users.length > 2 && chatInfo.user_group === null;
    const relatedToGroup = chatInfo.user_group !== null;
    const { data: profile } = useOwnProfile();
    let groupAvatars: UserCollectionItemWithAvatar[] = [];
    if (groupChat) {
        groupAvatars = chatInfo.users.filter(({ id }) => id !== (profile?.id ?? 0));
    } else if (!relatedToGroup) {
        groupAvatars = chatInfo.users.filter(({ id }) => id !== userId).slice(0, 1);
    }

    return (
        <View style={themedStyle.groupImageContainer}>
            <FlatList
                contentContainerStyle={themedStyle.contentContainer}
                numColumns={2}
                columnWrapperStyle={themedStyle.contentWrapper}
                data={groupAvatars}
                keyExtractor={(item, index) => item + index.toString()}
                renderItem={({ item, index }) => {
                    if (groupAvatars.length === 1) {
                        return <CacheImage source={item.avatar ?? Images.defaultAvatar} style={themedStyle.avatar} />;
                    }
                    if (groupAvatars.length > 4 && index === 3) {
                        return (
                            <View style={[themedStyle.groupWrapper, { backgroundColor: theme.main }]}>
                                <Text style={themedStyle.smallText}>{`+${groupAvatars.length - 3}`}</Text>
                            </View>
                        );
                    }
                    if (index < 3) {
                        return (
                            <View style={themedStyle.groupWrapper}>
                                <CacheImage
                                    source={item.avatar ?? Images.defaultAvatar}
                                    style={themedStyle.smallAvatar}
                                />
                            </View>
                        );
                    }

                    return null;
                }}
            />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        headerAction: {
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
            width: WW * 0.1,
            height: WW * 0.1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.lightGrey,
            borderRadius: (WW * 0.1) / 2,
        },
        groupImageContainer: {
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
            width: WW * 0.1,
            height: WW * 0.1,
        },
        contentContainer: { flex: 1, justifyContent: 'center' },
        contentWrapper: { justifyContent: 'space-around' },
        groupWrapper: {
            marginLeft: WW * 0.002,
            marginRight: WW * 0.002,
            marginTop: WW * 0.002,
            marginBottom: WW * 0.002,
            width: WW * 0.045,
            height: WW * 0.045,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.lightGrey,
            borderRadius: (WW * 0.045) / 2,
            overflow: 'hidden',
        },
        groupImage: {
            width: WW * 0.045,
            height: WW * 0.045,
            borderRadius: (WW * 0.045) / 2,
            overflow: 'hidden',
        },
        smallText: {
            color: theme.mainText,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.02,
        },
        avatar: { width: WW * 0.1, height: WW * 0.1, borderRadius: (WW * 0.1) / 2, overflow: 'hidden' },
        smallAvatar: {
            width: WW * 0.045,
            height: WW * 0.045,
        },
    });

export default ActionImage;
