import React, { ReactElement } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { PageContainer } from './common';
import _fonts from '../../../../_fonts';
import { Theme, useThemeStyle, WH, WW } from '../../../../_utils';
import { HeaderWithNav, PrimaryButton, RadioButton } from '../../../../Components';

const ActivityAlert = ({ goBack, goToPreview }: { goBack(): void; goToPreview(): void }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { field } = useController({ name: 'message_type' });
    const { watch } = useFormContext();
    const relatedGroups = watch('user_groups');

    const handleChange = (fieldType: number) => field.onChange(fieldType);

    return (
        <>
            <HeaderWithNav onPress={goBack} title={t('newActivity:receive_alert')} />
            <PageContainer>
                <Text style={themedStyle.title}>{t('newActivity:receive_alert')}</Text>

                <RadioGroup
                    hasGroups={relatedGroups.length > 0}
                    values={t('activityDetail:noticeOptions', { returnObjects: true })}
                    value={field.value}
                    onChange={handleChange}
                />
                <Text style={themedStyle.text}>{t('newActivity:send_out_alert')}</Text>
            </PageContainer>
            <PrimaryButton text={t('newActivity:continue').toUpperCase()} onPress={goToPreview} bottomAction="modal" />
        </>
    );
};

interface RadioGroupProps {
    values: Record<number, string>;
    onChange(newValue: number): void;
    value: number;
    hasGroups?: boolean;
}

function RadioGroup({ value, onChange, values, hasGroups }: RadioGroupProps): ReactElement {
    const themedStyle = useThemeStyle(styles);
    const keys = Object.entries(values);

    return (
        <View style={themedStyle.radioGrop}>
            {keys.map(([id, item]) => (
                <>
                    {!hasGroups && id === '5' ? null : (
                        <RadioButton
                            style={themedStyle.check}
                            key={id}
                            title={item}
                            checked={parseInt(id, 10) === value}
                            onPress={() => onChange(parseInt(id, 10))}
                        />
                    )}
                </>
            ))}
        </View>
    );
}

const styles = (theme: Theme) =>
    StyleSheet.create({
        radioGrop: { marginTop: WW * 0.04 },
        check: {
            marginTop: WH * 0.01,
        },
        title: {
            marginTop: WW * 0.04,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WH * 0.035,
            color: theme.black,
        },
        text: {
            color: theme.main,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            marginTop: WW * 0.04,
            marginBottom: WW * 0.04,
        },
    });

export default ActivityAlert;
