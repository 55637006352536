import React, { PropsWithChildren, ReactElement } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { screenMargin } from '../../../../../_utils/sizes';

const PageContainer = ({
    children,
    disableHorizontalPadding = false,
}: PropsWithChildren<{ disableHorizontalPadding?: boolean }>): ReactElement => {
    return (
        <ScrollView
            style={[
                styles.content,
                disableHorizontalPadding
                    ? {}
                    : {
                          paddingLeft: screenMargin,
                          paddingRight: screenMargin,
                      },
            ]}
            keyboardShouldPersistTaps="always"
        >
            {children}
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    content: {
        flex: 1,
        paddingBottom: screenMargin,
    },
});

export default PageContainer;
