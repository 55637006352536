import React, { ReactElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Text, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';
import { Activity as ActivityType } from '_api/useActivities';
import _fonts from '../_fonts';
import useAppNavigation from '../_navigator/hooks/useAppNavigation';
import { WW, WH, useThemeStyle, Theme } from '../_utils';
import { Icon } from '../Components';

interface ActivityProps {
    data: ActivityType;
    primary: boolean;
    signedUp: boolean;
    style: StyleProp<ViewStyle>;
}

const Activity = ({ data, primary, signedUp, style }: ActivityProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    let dateText = themedStyle.dateText;
    primary && (dateText = themedStyle.dateTextPrimary);

    let start = moment.unix(data.start_at);
    let end = moment.unix(data.end_at);
    const price = data?.paymentStrategy.price;
    return (
        <TouchableOpacity
            style={[themedStyle.main, style]}
            onPress={() => navigate('ActivitySelected', { activityId: `${data.id}` })}
        >
            <View style={themedStyle.mainContent}>
                <View style={themedStyle.date}>
                    <Text style={[dateText, themedStyle.dateNum]}>{start.format('D')}</Text>
                    <Text style={[dateText, themedStyle.day]}>{start.format('MMMM').toUpperCase()}</Text>
                </View>
                <View style={themedStyle.infoWrapper}>
                    <Text style={themedStyle.title} numberOfLines={2}>
                        {data.title}
                    </Text>
                    <View style={themedStyle.additional}>
                        <>
                            <Icon scale={0.7} name="clockCheck" color="secondaryText" />
                            <Text style={themedStyle.chipText}>{`${start.format('HH:mm')} - ${end.format(
                                'HH:mm',
                            )}`}</Text>
                        </>
                        {signedUp ? (
                            <>
                                <Icon scale={0.7} name="user" color="secondaryText" />
                                <Text style={themedStyle.chipText}>{t('activities:signed_up')}</Text>
                            </>
                        ) : price ? (
                            <>
                                <Icon scale={0.7} name="dollarCircle" color="secondaryText" />
                                <Text style={themedStyle.chipText}>{`${price} ${t('activity:Kr').toLowerCase()}`}</Text>
                            </>
                        ) : null}
                    </View>
                </View>
                <Icon name="chevron" color="black" />
            </View>
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            borderBottomWidth: 1,
            borderTopWidth: 1,
            borderTopColor: theme.shadow,
            borderBottomColor: theme.shadow,
        },
        mainContent: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        infoWrapper: {
            flex: 1,
            marginLeft: WW * 0.03,
            paddingTop: WH * 0.01,
            paddingBottom: WH * 0.01,
        },
        chipText: { color: theme.secondaryText },
        date: {
            marginTop: WW * 0.01,
            marginBottom: WW * 0.01,
            marginRight: WW * 0.01,
            marginLeft: WW * 0.01,
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: WW * 0.13,
        },
        dateText: {
            color: theme.mainText,
        },
        dateTextPrimary: {
            color: theme.main,
        },
        dateNum: {
            fontSize: WW * 0.07,
            fontFamily: _fonts.primaryFontBold,
        },
        day: {
            fontSize: WW * 0.03,
            fontFamily: _fonts.primaryFontBold,
        },
        title: {
            fontSize: WW * 0.04,
            color: theme.black,
            fontFamily: _fonts.primaryFontBold,
        },
        additional: {
            flexDirection: 'row',
            marginTop: WH * 0.005,
            flexWrap: 'wrap',
            alignItems: 'center',
        },
    });

export default Activity;
