import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { subtitleFontSize, titleFontSize } from '_utils/sizes';
import { StatusType } from '../../../_api/useSupports';
import { useThemeStyle, WW, getReportStatusTranslationString } from '../../../_utils';
import { Theme, ThemeContext } from '../../../_utils/themeContext';
import { HeimeText, Icon } from '../../../Components';

interface ReportStatusProps {
    status: StatusType;
    large?: boolean;
    circle?: boolean;
    chevron?: boolean;
}

const statusColors = {
    0: {
        // New
        backgroundColor: 'errorLight',
        textColor: 'red',
    },
    1: {
        // Processing
        backgroundColor: 'mediumBackground',
        textColor: 'black',
    },
    2: {
        // Solved
        backgroundColor: 'lightGreen',
        textColor: 'main',
    },
    3: {
        // Closed
        backgroundColor: 'lightMustard',
        textColor: 'secondary',
    },
} as const;

const ReportStatus = ({ status, large, chevron, circle = true }: ReportStatusProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();

    const statusName = t(getReportStatusTranslationString(status));

    const colors = statusColors[status];

    return (
        <View
            style={[
                themedStyle.wrapper,
                {
                    backgroundColor: theme[colors.backgroundColor],
                },
                large ? themedStyle.largeWrapper : undefined,
            ]}
        >
            {circle ? (
                <View
                    style={[
                        themedStyle.circle,
                        large ? themedStyle.largeCircle : undefined,
                        { backgroundColor: theme[colors.textColor] },
                    ]}
                />
            ) : null}
            <HeimeText
                style={[
                    themedStyle.text,
                    { color: theme[colors.textColor] },
                    large ? themedStyle.largeText : undefined,
                ]}
            >
                {statusName}
            </HeimeText>
            {chevron ? <Icon name="chevron" color={colors.textColor} style={themedStyle.chevronStyle} /> : null}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        wrapper: {
            flexDirection: 'row',
            backgroundColor: theme.lightGreen,
            borderRadius: 50,
            alignSelf: 'flex-start',
            alignItems: 'center',
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
        },
        largeWrapper: {
            marginLeft: WW * 0.05,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
        },
        circle: {
            marginRight: WW * 0.01,
            width: 5,
            height: 5,
            borderRadius: 5,
        },
        largeCircle: {
            marginRight: WW * 0.02,
            width: 10,
            height: 10,
            borderRadius: 5,
        },
        text: {
            fontSize: subtitleFontSize,
        },
        largeText: {
            fontSize: titleFontSize,
        },
        chevronStyle: {
            transform: [{ rotate: '90deg' }],
        },
    });

export default ReportStatus;
