import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateTitle, PageContainer } from './common';
import { FormInput, HeaderWithNav, PrimaryButton } from '../../../../Components';

const ActivityDescription = ({ goBack, goToPreview }: { goBack(): void; goToPreview(): void }): ReactElement => {
    const { t } = useTranslation();
    return (
        <>
            <HeaderWithNav onPress={goBack} title={t('newActivity:title_create_activity')} />
            <PageContainer>
                <CreateTitle>{t('newActivity:about_activity')}</CreateTitle>
                <FormInput
                    placeholder={t('newActivity:enter_description')}
                    name="description"
                    label={t('newActivity:description')}
                    autoFocus
                    multiline
                />
            </PageContainer>
            <PrimaryButton text={t('newActivity:continue').toUpperCase()} onPress={goToPreview} bottomAction="modal" />
        </>
    );
};

export default ActivityDescription;
