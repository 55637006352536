import { useCallback } from 'react';
import { useOwnProfile } from '_api/useProfile';
import { Message, isNoticeMessage } from 'types/message';

const useCalculateMessageRead = (): ((message: Message) => boolean) => {
    const { data: ownProfile } = useOwnProfile();

    return useCallback(
        (message: Message) => {
            if (isNoticeMessage(message)) {
                return !message.unreadUsers.includes(ownProfile?.id ?? 0);
            }
            return message.readUsers.includes(ownProfile?.id ?? 0);
        },
        [ownProfile],
    );
};

export default useCalculateMessageRead;
