import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { ViewStyle, StyleProp } from 'react-native';
import { MenuProvider as OriginalMenuProvider } from 'react-native-popup-menu';
import { useThemeContext } from '_utils/themeContext';

interface MenuContextProps {
    isBackdropVisible: boolean;
    setBackdropVisible: (visible: boolean) => void;
}
const MenuContext = createContext<MenuContextProps | undefined>(undefined);
const MenuProvider = ({
    children,
    menuProviderWrapper,
}: {
    children: React.ReactNode;
    menuProviderWrapper?: StyleProp<ViewStyle>;
}) => {
    const [isBackdropVisible, setBackdropVisible] = useState(false);

    const { theme } = useThemeContext();

    return (
        <MenuContext.Provider
            value={useMemo(() => ({ isBackdropVisible, setBackdropVisible }), [isBackdropVisible, setBackdropVisible])}
        >
            <OriginalMenuProvider
                skipInstanceCheck
                customStyles={{
                    backdrop: isBackdropVisible ? { backgroundColor: theme.black, opacity: 0.3 } : {},
                    menuProviderWrapper,
                }}
            >
                {children}
            </OriginalMenuProvider>
        </MenuContext.Provider>
    );
};
const useMenu = (): MenuContextProps => {
    const context = useContext(MenuContext);
    if (!context) {
        throw new Error('useMenu must be used within a MenuProvider');
    }
    return context;
};

export const useToggleBackdrop = (visible: boolean) => {
    const { setBackdropVisible } = useMenu();
    useEffect(() => {
        setBackdropVisible(visible);
    }, [setBackdropVisible, visible]);
};

export default MenuProvider;
