import React, { ReactElement, useCallback, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { StyleProp, StyleSheet, View, ViewStyle, TouchableOpacity, LayoutAnimation } from 'react-native';
import ControlledInput from './ControlledInput';
import Icon from './Icon/Icon';
import IconButton from './IconButton';
import _fonts from '../_fonts';
import { useThemeStyle, WW } from '../_utils';
import { Theme } from '../_utils/themeContext';

interface ExpandableSearchInputProps {
    value: string;
    onChange(text: string): void;
    buttonText: string;
    inputStyle?: StyleProp<ViewStyle>;
}

const ExpandableSearchInput = ({
    onChange,
    value,
    buttonText,
    inputStyle,
}: ExpandableSearchInputProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const [expanded, setExpanded] = useState(value !== '');

    useFocusEffect(
        useCallback(() => {
            return () => {
                onChange('');
                setExpanded(false);
            };
        }, [onChange]),
    );

    if (expanded) {
        return (
            <ControlledInput
                leftImage={<Icon name="search" color="secondaryText" />}
                onChange={onChange}
                value={value}
                containerStyle={(themedStyle.searchContainer, inputStyle)}
                placeholder={buttonText}
                autoFocus
                rightImage={
                    value ? (
                        <TouchableOpacity
                            onPress={() => onChange('')}
                            pressRetentionOffset={{ top: 20, bottom: 20, right: 20, left: 40 }}
                            style={themedStyle.removeButton}
                        >
                            <Icon name="cross" color="mediumGrey" />
                        </TouchableOpacity>
                    ) : undefined
                }
            />
        );
    }

    const handleExpand = () => {
        LayoutAnimation.configureNext({
            duration: 200,
            create: { type: 'spring', property: 'scaleX', springDamping: 0.8 },
        });
        setExpanded(true);
    };

    return (
        <View style={themedStyle.buttonContainer}>
            <IconButton text={buttonText} icon="search" onPress={handleExpand} />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        button: {
            width: WW * 0.4,
            paddingTop: WW * 0.03,
            paddingBottom: WW * 0.03,
            paddingRight: WW * 0.03,
            paddingLeft: WW * 0.03,
            backgroundColor: theme.mainBackground,
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            borderColor: theme.greyMedium,
            borderWidth: 1,
        },
        buttonContainer: {
            alignSelf: 'flex-end',
        },
        searchContainer: {
            flexGrow: 1,
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
        },
        emptyContainer: {
            marginTop: WW * 0.1,
            paddingTop: WW * 0.05,
            paddingBottom: WW * 0.05,
            paddingLeft: WW * 0.05,
            paddingRight: WW * 0.05,
            alignSelf: 'center',
            backgroundColor: theme.chatMyMessageBubble,
            borderRadius: 15,
        },
        emptyText: {
            fontFamily: _fonts.primaryFont,
        },
        removeButton: {
            flexGrow: 0,
            flexBasis: 40,
            display: 'flex',
            alignItems: 'center',
            paddingHorizontal: 10,
            marginLeft: -10,
        },
    });

export default ExpandableSearchInput;
