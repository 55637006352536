import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useCoops } from '_api/useCoops';
import { useSelectedCoopItem } from '_utils/hooks';
import useThemeStyle from '_utils/hooks/useThemeStyle';
import { smallestMargin } from '_utils/sizes';
import { Theme } from '_utils/themeContext';
import { showToast } from '_utils/toast';
import Icon from 'Components/Icon/Icon';
import MainLogo from 'Components/MainLogo';
import NotificationCountBadge from './NotificationCountBadge';
import { useGetReservations } from '../../_api/useReservations';
import useAppNavigation from '../hooks/useAppNavigation';
import useResetNavigationStack from '../hooks/useResetNavigationStack';

const MainHeader = ({ padBottom }: { padBottom?: boolean }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const resetNav = useResetNavigationStack();
    const coops = useCoops().data ?? [];
    const selectedCoop = useSelectedCoopItem();
    useGetReservations();
    const insets = useSafeAreaInsets();

    const handleReset = () => {
        resetNav();
        // We know what we are doing here
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        navigate('Main', { screen: 'HomeTab', params: { screen: 'StartScreen', params: undefined } } as any);
    };

    const handleLongPress =
        coops.length > 1
            ? () => {
                  showToast({ header: selectedCoop?.name ?? '', type: 'info', text: '' });
              }
            : undefined;

    return (
        <View
            style={[
                themedStyle.main,
                padBottom ? themedStyle.mainPadBottom : undefined,
                insets.top === 0 ? { paddingTop: smallestMargin } : undefined,
            ]}
        >
            <TouchableOpacity
                style={themedStyle.logoButton}
                accessibilityLabel={t('navigation:go_home')}
                onPress={handleReset}
                onLongPress={handleLongPress}
            >
                <MainLogo />
            </TouchableOpacity>
            <View style={themedStyle.buttonsContainer}>
                <TouchableOpacity
                    onPress={() => {
                        navigate('Notifications', undefined);
                    }}
                    style={themedStyle.iconButton}
                    accessibilityLabel={t('navigation:bellExplainer')}
                >
                    <View style={themedStyle.iconContainer}>
                        <Icon name="bellFilled" color="main" />
                        <NotificationCountBadge />
                    </View>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => {
                        navigate('profileStack', undefined);
                    }}
                    accessibilityLabel={t('navigation:profileExplainer')}
                    style={themedStyle.iconButton}
                >
                    <View style={themedStyle.iconContainer}>
                        <Icon name="userFilled" color="main" />
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.shadow,
            paddingLeft: 24,
            paddingRight: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'stretch',
        },
        mainPadBottom: {
            paddingBottom: smallestMargin,
        },
        logoButton: { flex: 1, justifyContent: 'center' },
        buttonsContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
        },
        iconButton: { marginLeft: 8, marginRight: 8, display: 'flex', justifyContent: 'center' },
        iconContainer: {
            borderRadius: 50,
            backgroundColor: theme.lightMustard,
            paddingTop: 8,
            paddingRight: 8,
            paddingLeft: 8,
            paddingBottom: 8,
        },
    });

export default MainHeader;
