import React, { ReactElement } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { smallestMargin, smallestMarginPixels } from '_utils/sizes';
import { ApiImage } from 'types/Base';
import Images from '../../../_images';
import { Theme, useThemeStyle, WH, WW } from '../../../_utils';
import { CacheImage } from '../../../Components';
import { UserCollectionItemWithAvatar } from '../../../types/User';

interface AvatarProps {
    type: 'ChatRelatedToGroup' | 'OneToOne' | 'GroupChat';
    usersToDisplay: UserCollectionItemWithAvatar[];
    groupPicture?: ApiImage;
}

const GroupAvatar = ({ type, usersToDisplay, groupPicture }: AvatarProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    switch (type) {
        case 'OneToOne': {
            if (usersToDisplay.length > 1) {
                console.warn('Expected only one picture received several');
            }

            const user = usersToDisplay[0];
            return (
                <CacheImage
                    source={user?.avatar ?? Images.defaultAvatar}
                    style={[themedStyle.container, themedStyle.avatar]}
                />
            );
        }
        case 'ChatRelatedToGroup': {
            return (
                <CacheImage
                    source={groupPicture ?? Images.boardSection}
                    style={[themedStyle.container, themedStyle.avatar]}
                />
            );
        }
        case 'GroupChat': {
            const nUsers = usersToDisplay.length;
            const usersWithAvatar = usersToDisplay.filter((item) => Boolean(item.avatar));
            const usersWithNoAvatar = usersToDisplay.filter((item) => !item.avatar);
            const toDisplay = [...usersWithAvatar, ...usersWithNoAvatar].filter((_, i) =>
                nUsers === 4 ? i <= 3 : i < 3,
            );

            return (
                <View style={themedStyle.container}>
                    {toDisplay.map(({ avatar, id }, i) => {
                        const containerStyle = [
                            themedStyle.groupAvatar,
                            nUsers === 2
                                ? [
                                      themedStyle.diagonalAvatar,
                                      i === 0 ? themedStyle.diagonalTop : themedStyle.diagonalBottom,
                                  ]
                                : nUsers === 3 && i === 2
                                  ? { marginLeft: 0.025 * WH, marginRight: 0.025 * WH }
                                  : undefined,
                        ];
                        return <CacheImage key={id} source={avatar ?? Images.defaultAvatar} style={containerStyle} />;
                    })}
                    {nUsers > 4 ? (
                        <View style={[themedStyle.groupAvatar, themedStyle.groupCountView]}>
                            <Text style={themedStyle.groupCountText}>{'+' + `${nUsers - 3}`}</Text>
                        </View>
                    ) : null}
                </View>
            );
        }
    }
};

const avatarSize = WW * 0.18;

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            width: avatarSize,
            height: avatarSize,
            marginRight: smallestMargin,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: smallestMarginPixels,
        },
        avatar: {
            width: avatarSize,
            height: avatarSize,
            borderRadius: avatarSize / 2,
            overflow: 'hidden',
        },
        groupAvatar: {
            width: WW * 0.08,
            height: WW * 0.08,
            borderRadius: WH / 4,
            backgroundColor: theme.lightGrey,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
        },
        diagonalAvatar: {
            position: 'absolute',
            width: WW * 0.09,
            height: WW * 0.09,
        },
        diagonalTop: { left: 0, top: 0 },
        diagonalBottom: { bottom: 0, right: 0 },
        groupCountView: {
            backgroundColor: theme.main,
        },
        groupCountText: {
            alignSelf: 'center',
            color: theme.white,
            fontWeight: 'bold',
        },
    });

export default GroupAvatar;
