import { ComponentProps } from 'react';
import { LayoutChangeEvent, NativeSyntheticEvent, TextInput, TextInputChangeEventData } from 'react-native';

// This is a hack to make the text input grow with the text
const adjustTextSize = (evt: NativeSyntheticEvent<TextInputChangeEventData> | LayoutChangeEvent): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const el: any = evt?.target || (evt.nativeEvent as any).target;
    if (el) {
        el.style.height = 0;
        const newHeight = el.offsetHeight - el.clientHeight + el.scrollHeight;
        el.style.height = `${newHeight}px`;
    }
};

const options: Partial<ComponentProps<typeof TextInput>> = {
    onChange: adjustTextSize as ComponentProps<typeof TextInput>['onChange'],
    onLayout: adjustTextSize as ComponentProps<typeof TextInput>['onLayout'],
};

export default options;
