import { useEffect } from 'react';
import { useOwnProfile } from '_api/useProfile';
import { useSelectedCoop } from 'SelectedCoop';
import {
    setAmplitudeUserId,
    removeAmplitudeUserId,
    setAmplitudeCooperative,
    removeAmplitudeCooperative,
} from './Amplitude';
import { useSelectedCoopItem } from './hooks';
import { setSentryUser, removeSentryUser, setSentryCooperative, removeSentryCooperative } from './Sentry';

export const useRegisterUserContext = (): void => {
    const { data: profile } = useOwnProfile();

    useEffect(() => {
        if (profile) {
            setSentryUser(profile.id + '');
            setAmplitudeUserId(profile.id + '');
        }
        return () => {
            removeSentryUser();
            removeAmplitudeUserId();
        };
    }, [profile]);
};

export const useSelectedCoopContext = (): void => {
    const selectedCoopId = useSelectedCoop();
    const selectedcoop = useSelectedCoopItem();

    useEffect(() => {
        if (selectedCoopId) {
            setSentryCooperative({ id: selectedCoopId, name: selectedcoop?.name ?? '' });
            setAmplitudeCooperative({ id: selectedCoopId, name: selectedcoop?.name ?? '' });
        }
        return () => {
            removeSentryCooperative();
            removeAmplitudeCooperative();
        };
    }, [selectedCoopId, selectedcoop]);
};
