import moment from 'moment';
import { Product } from 'types/Category';

type ExtractByValue<T, K extends keyof T, V> = T extends Record<K, V> ? T : never;

type bookingData = {
    quantity: number;
    start: Date;
    end: Date;
};

type paymentStrategyType = NonNullable<Product['paymentStrategy']>;

type Calculator<key> = (
    booking: bookingData,
    paymentStrategy: ExtractByValue<paymentStrategyType, 'type', key>,
) => number;

type baseCalculator = Calculator<Exclude<paymentStrategyType['type'], 'rate_per_hour_with_night_price'>>;

const rate_per_booking: baseCalculator = (_, { price }) => {
    return price;
};

const rate_per_night: baseCalculator = ({ start, end }, { price }) => {
    const duration = Math.ceil(moment(end).diff(start, 'days', true));
    return price * duration;
};

const rate_per_hour: baseCalculator = ({ start, end }, { price }) => {
    const duration = Math.ceil(moment(end).diff(start, 'hours', true));
    return price * duration;
};

const rate_per_capacity: baseCalculator = ({ quantity }, { price }) => {
    return price * quantity;
};

const rate_per_capacity_times_hour: baseCalculator = ({ start, end, quantity }, { price }) => {
    const duration = Math.ceil(moment(end).diff(start, 'hours', true));
    return price * quantity * duration;
};

const rate_per_capacity_times_night: baseCalculator = ({ start, end, quantity }, { price }) => {
    const duration = Math.ceil(moment(end).diff(start, 'days', true));
    return price * quantity * duration;
};

const night_day_hour_diff: Calculator<'rate_per_hour_with_night_price'> = (
    { start, end, quantity },
    { nighttime_price, daytime_price, nighttime_start_hour, nighttime_end_hour },
) => {
    // We need to count the amount of hours in nighttime, and the amount of hours in daytime to calculate the price
    const startMoment = moment(start);
    const endMoment = moment(end);
    const duration = Math.ceil(endMoment.diff(startMoment, 'hours', true));
    let nightDuration = 0;
    let dayDuration = 0;
    for (let i = 0; i < duration; i++) {
        const currentMoment = moment(startMoment).add(i, 'hours');
        if (currentMoment.hours() >= nighttime_start_hour || currentMoment.hours() < nighttime_end_hour) {
            nightDuration++;
        } else {
            dayDuration++;
        }
    }
    return (nighttime_price * nightDuration + daytime_price * dayDuration) * quantity;
};

const getPrice = (booking: bookingData, paymentStrategy: Product['paymentStrategy']) => {
    const strategy = paymentStrategy;
    if (!strategy) {
        return 0;
    }
    switch (strategy.type) {
        case 'rate_per_booking':
            return rate_per_booking(booking, strategy);
        case 'rate_per_night':
            return rate_per_night(booking, strategy);
        case 'rate_per_hour':
            return rate_per_hour(booking, strategy);
        case 'rate_per_booking_capacity':
            return rate_per_capacity(booking, strategy);
        case 'rate_per_hour_per_capacity':
            return rate_per_capacity_times_hour(booking, strategy);
        case 'rate_per_night_per_capacity':
            return rate_per_capacity_times_night(booking, strategy);
        case 'rate_per_hour_with_night_price':
            return night_day_hour_diff(booking, strategy);
    }
    throw new Error('Unknown payment strategy: ' + JSON.stringify(strategy));
};

export default getPrice;
