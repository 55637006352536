import { useState, useCallback, useEffect } from 'react';
import { AppState, AppStateStatus } from 'react-native';
import { permissions } from '../permission';

type PermissionStatusType = 'unavailable' | 'blocked' | 'denied' | 'granted' | 'limited' | null;

const usePermissionStatus = (): PermissionStatusType => {
    const [permissionStatus, setPermissionStatus] = useState<PermissionStatusType>(null);

    const onCheckPermission = useCallback(async () => {
        const status = await permissions.check('notifications');

        setPermissionStatus(status);
    }, []);

    useEffect(() => {
        onCheckPermission();
    });

    useEffect(() => {
        const listener = AppState.addEventListener('change', (state: AppStateStatus) => {
            if (state === 'active') {
                onCheckPermission();
            }
        });

        return () => {
            listener.remove();
        };
    });

    return permissionStatus;
};

export default usePermissionStatus;
