import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageType } from 'types/message';
import SharingCategorySelected from './SharingAll/SharingCategorySelected';

const Items = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <SharingCategorySelected
            types={[MessageType.Products]}
            header={t('sharingSelected:messageType:Products')}
            emptyText={t('sharingAll:noMessageType:Products')}
        />
    );
};

export default Items;
