import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import MemberProofModal from './MemberProofModal';
import { useGetMemberBenefit } from '../../_api/useMemberBenefits';
import { isAppError, openURL, Theme, useThemeStyle } from '../../_utils';
import { screenMargin, titleFontSize, subtitleFontSize } from '../../_utils/sizes';
import {
    BottomSpacer,
    FullWidthPictureCarousel,
    HeaderWithNav,
    HeimeText,
    Loader,
    PromoDisplay,
    PrimaryButton,
    QueryItemView,
} from '../../Components';
import NotFoundErrorScreen from '../../Components/NotFoundErrorScreen';

interface MemberBenefitSelectedProps {
    route: { params: { memberBenefitId: string } };
}

const MemberBenefitSelected = ({ route }: MemberBenefitSelectedProps): ReactElement => {
    const { t } = useTranslation();
    const { data, isLoading, isError, error, refetch, isRefetching } = useGetMemberBenefit(
        parseInt(route.params.memberBenefitId, 10),
    );
    const themedStyle = useThemeStyle(styles);
    const [proofVisible, setProofVisible] = useState(false);

    if (isError) {
        if (isAppError(error) && error.response?.status === 404) {
            return <NotFoundErrorScreen type="MemberBenefit" />;
        } else {
            throw error;
        }
    }

    const handleUrlPress = () => openURL(data?.url ?? '', t);
    const handleShowProofPress = () => setProofVisible(true);

    return (
        <View style={themedStyle.container}>
            <HeaderWithNav title={t('memberBenefit:title')} safeArea />
            {isLoading ? (
                <Loader />
            ) : data ? (
                <QueryItemView isRefreshing={isRefetching} onRefresh={refetch}>
                    <FullWidthPictureCarousel pictures={data.images} />
                    <View style={themedStyle.itemContainer}>
                        <HeimeText maxFontSizeMultiplier={2} style={themedStyle.title}>
                            {data.title}
                        </HeimeText>
                        <HeimeText maxFontSizeMultiplier={2} linkify style={themedStyle.description}>
                            {data.description}
                        </HeimeText>
                    </View>
                </QueryItemView>
            ) : null}

            {data?.promo_code ? (
                <View style={themedStyle.pinContainer}>
                    <PromoDisplay
                        pin={data?.promo_code}
                        copiedString={t('memberBenefit:copied')}
                        description={t('memberBenefit:promo_code')}
                    />
                </View>
            ) : null}
            {data?.url ? (
                <PrimaryButton
                    bottomAction={data?.show_live_ticket ? 'modal' : true}
                    text={t('memberBenefit:visit_website')}
                    onPress={handleUrlPress}
                />
            ) : null}
            {data?.show_live_ticket ? (
                <PrimaryButton bottomAction text={t('memberBenefit:showProof')} onPress={handleShowProofPress} />
            ) : null}
            {proofVisible ? <MemberProofModal onDismiss={() => setProofVisible(false)} /> : null}
            <BottomSpacer />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: theme.lightBackground,
            flex: 1,
        },
        itemContainer: { paddingLeft: screenMargin, paddingRight: screenMargin },
        pinContainer: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            marginBottom: screenMargin,
        },
        title: {
            fontSize: titleFontSize,
            color: theme.main,
            fontWeight: 'bold',
            marginBottom: screenMargin,
        },
        description: {
            fontSize: subtitleFontSize,
            color: theme.secondaryText,
        },
    });

export default MemberBenefitSelected;
