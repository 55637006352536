import React, { ReactElement, useCallback, useMemo, useContext } from 'react';
import { FlashList } from '@shopify/flash-list';
import { ActivityIndicator, ViewStyle } from 'react-native';
import { useVisualRefetchState } from '../../_utils';
import { ThemeContext } from '../../_utils/themeContext';

interface ItemWithId {
    id: number | string;
}

interface QuerySectionedViewProps<ItemType> {
    sections: { data: ItemType[]; title?: string }[];
    renderItem: ({ item }: { item: ItemType }) => ReactElement;
    top?: ReactElement;
    bottom?: ReactElement;
    loadMore?: () => void;
    isLoadingMore?: boolean;
    onRefresh(): void;
    isRefreshing: boolean;
    containerStyle?: ViewStyle;
    header?: (info: { title: string }) => React.ReactElement | null;
    emptyComponent?: ReactElement;
    keyboardShouldPersistTaps?: 'always' | 'never' | 'handled';
}

const QuerySectionedView = <ItemType extends ItemWithId>({
    sections,
    renderItem,
    top: Top,
    bottom: Bottom,
    isLoadingMore,
    loadMore,
    isRefreshing,
    onRefresh,
    containerStyle,
    header,
    emptyComponent,
    keyboardShouldPersistTaps,
}: QuerySectionedViewProps<ItemType>): ReactElement => {
    const { theme } = useContext(ThemeContext);
    const [refreshing, handleRefresh] = useVisualRefetchState(isRefreshing, onRefresh);
    const keyExtractor = useCallback(
        (item: ItemType | string, index: number) => (typeof item === 'string' ? item : `${item.id} ${index}`),
        [],
    );

    const footerComponent = useMemo(() => {
        return (
            <>
                {isLoadingMore ? <ActivityIndicator color={theme.black} /> : null}
                {Bottom}
            </>
        );
    }, [Bottom, isLoadingMore, theme.black]);

    const handleOnEndReached = () => {
        if (loadMore) {
            loadMore();
        }
    };

    const mappedData = useMemo(
        () =>
            sections.reduce(
                (curr, section) => {
                    if (section.title) {
                        return [...curr, section.title, ...section.data];
                    }
                    return [...curr, ...section.data];
                },
                [] as (ItemType | string)[],
            ),
        [sections],
    );

    const stickyHeaderIndices = useMemo(
        () =>
            mappedData
                .map((item, index) => {
                    if (typeof item === 'string') {
                        return index;
                    } else {
                        return null;
                    }
                })
                .filter((item) => item !== null) as number[],
        [mappedData],
    );

    return (
        <FlashList
            key={sections.length}
            estimatedItemSize={80}
            contentContainerStyle={containerStyle}
            data={mappedData}
            keyExtractor={keyExtractor}
            renderItem={({ item }) => {
                if (typeof item === 'string') {
                    // Rendering header
                    return header ? header({ title: item }) : null;
                } else {
                    // Render item
                    return renderItem({ item });
                }
            }}
            getItemType={(item) => {
                return typeof item === 'string' ? 'sectionHeader' : 'row';
            }}
            ListHeaderComponent={Top}
            onEndReached={handleOnEndReached}
            ListFooterComponent={footerComponent}
            onEndReachedThreshold={0.5}
            onRefresh={handleRefresh}
            refreshing={refreshing}
            ListEmptyComponent={emptyComponent}
            removeClippedSubviews={false}
            keyboardShouldPersistTaps={keyboardShouldPersistTaps}
            stickyHeaderIndices={stickyHeaderIndices}
        />
    );
};

export default QuerySectionedView;
