import React, { ReactElement, useContext } from 'react';
import { Text, StyleSheet, ViewStyle, StyleProp, View, TouchableOpacity } from 'react-native';
import _fonts from '../_fonts';
import { WH } from '../_utils';
import { ThemeContext } from '../_utils/themeContext';

interface SelectorChipProps {
    variant: 'PrimaryWhite' | 'WhitePrimary' | 'GreyPrimary' | 'GreyGrey' | 'WhitePrimaryGrey' | 'lightPrimary';
    title?: string;
    content?: ReactElement | null;
    onSelect?: () => void;
    containerStyle?: StyleProp<ViewStyle>;
    maxFontSizeMultiplier?: number;
}

const SelectorChip = ({
    variant,
    title,
    content = null,
    onSelect,
    containerStyle,
    maxFontSizeMultiplier,
}: SelectorChipProps): ReactElement => {
    const { theme } = useContext(ThemeContext);
    const jsxContent = (
        <>
            {content}
            {title ? (
                <Text
                    maxFontSizeMultiplier={maxFontSizeMultiplier}
                    style={[
                        styles.title,
                        {
                            color:
                                variant === 'PrimaryWhite'
                                    ? theme.mainText
                                    : variant === 'GreyGrey'
                                      ? theme.secondaryText
                                      : theme.main,
                        },
                    ]}
                >
                    {title}
                </Text>
            ) : null}
        </>
    );

    const calculatedStyle = [
        styles.main,
        {
            borderWidth: 1,
            backgroundColor:
                variant === 'PrimaryWhite'
                    ? theme.main
                    : variant === 'WhitePrimary' || variant === 'WhitePrimaryGrey'
                      ? theme.mainBackground
                      : variant === 'lightPrimary'
                        ? theme.lightGreen
                        : theme.lightGrey,
            borderColor:
                variant === 'WhitePrimary'
                    ? theme.main
                    : variant === 'GreyGrey' || variant === 'GreyPrimary'
                      ? theme.lightGrey
                      : variant === 'lightPrimary'
                        ? theme.lightGreen
                        : theme.greyMedium,
        },
        containerStyle,
    ];

    if (onSelect) {
        return (
            <TouchableOpacity style={calculatedStyle} onPress={onSelect}>
                {jsxContent}
            </TouchableOpacity>
        );
    }

    return <View style={calculatedStyle}>{jsxContent}</View>;
};

const styles = StyleSheet.create({
    main: {
        minHeight: WH * 0.04,
        marginRight: WH * 0.01,
        paddingLeft: WH * 0.02,
        paddingRight: WH * 0.02,
        borderRadius: WH * 0.05,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontFamily: _fonts.primaryFont,
        fontSize: WH * 0.02,
    },
});

export default SelectorChip;
