import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import _fonts from '_fonts';
import { Theme, useThemeStyle, useDocumentsView, WW } from '_utils';
import { useBottomSpacer } from '_utils/hooks';
import { QueryView } from 'Components';
import FileIcon from 'Components/Icon/FileIcon';
import { File, Folder } from 'types/Heime';
import { ArrayElement } from 'types/Utility';
import { DocumentItem } from './DocumentItem';

interface DocumentListProps {
    data?: Folder;
    searchedText: string;
}

function getMatchingFiles(doc: Folder, searchedText: string): (Folder | File)[] {
    const result = [];
    if (doc.name.toLowerCase().includes(searchedText.toLowerCase())) {
        result.push(doc);
    }
    doc.files.forEach((file) => {
        if (file.name.toLocaleLowerCase().includes(searchedText.toLowerCase())) {
            result.push(file);
        }
    });
    doc.sub_folders.forEach((folder) => {
        result.push(...getMatchingFiles(folder, searchedText));
    });

    return result;
}

const countFiles = (acc: number, curr: Folder): number =>
    acc + curr.files.length + curr.sub_folders.reduce(countFiles, 0);

const DocumentList = ({ data, searchedText }: DocumentListProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const paddingBottom = useBottomSpacer();

    const [loader, handlePress] = useDocumentsView();

    const filteredData = useMemo(() => {
        if (!data) {
            return [];
        }
        if (!data.files) {
            return [];
        }
        const addId = (item: File | Folder) => ({ ...item, id: Math.random() });
        if (searchedText !== '') {
            return getMatchingFiles(data, searchedText)
                .map(addId)
                .sort((a, b) => a.name.localeCompare(b.name));
        }
        return [...(data.sub_folders ?? []), ...(data.files ?? [])]
            .map(addId)
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [data, searchedText]);

    const renderItem = ({ item }: { item: ArrayElement<typeof filteredData> }) => {
        const isFolder = (item as Folder).files !== undefined;
        const folder = item as Folder;
        const file = item as File;

        return (
            <DocumentItem
                icon={<FileIcon fileName={isFolder ? '' : item.name} />}
                onPress={() => {
                    handlePress(item);
                }}
                title={item.name}
                desc={
                    isFolder
                        ? t('documents:files', {
                              count: folder.files.length + folder.sub_folders.reduce(countFiles, 0),
                          })
                        : file.size
                }
            />
        );
    };

    return (
        <>
            <QueryView
                keyboardShouldPersistTaps
                emptyList={
                    <View style={themedStyle.emptyContainer}>
                        <Text style={themedStyle.emptyText}>
                            {searchedText ? t('documents:notFound') : t('documents:noResult')}
                        </Text>
                    </View>
                }
                data={filteredData}
                renderItem={renderItem}
                containerStyle={{ paddingBottom }}
            />
            {loader}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        emptyContainer: {
            marginTop: WW * 0.04,
            paddingTop: WW * 0.05,
            paddingBottom: WW * 0.05,
            paddingLeft: WW * 0.05,
            paddingRight: WW * 0.05,
            alignSelf: 'center',
            backgroundColor: theme.chatMyMessageBubble,
            borderRadius: 15,
        },
        emptyText: {
            fontFamily: _fonts.primaryFont,
        },
    });

export default DocumentList;
