import React, { ReactElement, useContext, useEffect, useMemo, useState, useCallback, memo } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Keyboard } from 'react-native';
import { MessageType } from 'types/message';
import { allScreens, nonScreens } from './navigationConfiguration/screenConfiguration';
import { NavigationDestination, NotScreenType, ScreenType } from './navigationConfiguration/types';
import TabBar from './TabBar';
import { useGetChats } from '../_api/useChats';
import { useGetMessages } from '../_api/useMessages';
import { useCalculateMessageRead } from '../_utils';
import { flattenIniniteResult } from '../_utils/misc';
import { ThemeContext } from '../_utils/themeContext';
import { NavigationErrorScreen } from '../Components';

const getItemsWithParent = (
    term: NavigationDestination | null,
): [NavigationDestination, NotScreenType | ScreenType][] =>
    [...Object.entries(nonScreens), ...allScreens].filter(([_, { parent }]) => term === parent) as unknown as [
        NavigationDestination,
        NotScreenType | ScreenType,
    ][];

const initialItems = getItemsWithParent(null);

// We use stack and not native-stack because we then get issues with menu providers
const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

interface RenderConfigurationProps {
    initialRoute: string;
}
const RenderConfiguration = ({ initialRoute }: RenderConfigurationProps): ReactElement => {
    const { theme } = useContext(ThemeContext);
    const { data: chats } = useGetChats();
    const { data: messagesNotNotices } = useGetMessages([MessageType.Products, MessageType.Helpers]);
    const { data: notices } = useGetMessages([MessageType.Requests, MessageType.ByBoard]);
    const { t } = useTranslation();
    const calculateMesageRead = useCalculateMessageRead();

    const [keyboardVisible, setKeyboardVisible] = useState(false);

    useEffect(() => {
        const keyboardWillShowListener = Keyboard.addListener('keyboardWillShow', () => setKeyboardVisible(true));
        const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => setKeyboardVisible(true));
        const keyboardWillHideListener = Keyboard.addListener('keyboardWillHide', () => setKeyboardVisible(false));
        const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => setKeyboardVisible(false));
        return () => {
            keyboardWillShowListener.remove();
            keyboardDidShowListener.remove();
            keyboardWillHideListener.remove();
            keyboardDidHideListener.remove();
        };
    }, []);

    const renderItem = useCallback(
        (Type: typeof Stack | typeof Tab) =>
            // We allow this because of the useCallback
            // eslint-disable-next-line react/no-unstable-nested-components
            ([name, settings]: [NavigationDestination, ScreenType | NotScreenType]) => {
                if ('type' in settings) {
                    const { screenOptions, defaultRoute, component: Component, backBehavior } = settings;
                    if (settings.type === 'group') {
                        return (
                            <Type.Group key={name} screenOptions={screenOptions}>
                                {getItemsWithParent(name).map(renderItem(Type ?? Stack))}
                            </Type.Group>
                        );
                    } else if (settings.type === 'stack') {
                        const navOptions = settings.navOptions ? settings.navOptions(theme, keyboardVisible) : {};
                        const children = (
                            <Stack.Navigator
                                initialRouteName={defaultRoute}
                                screenOptions={{
                                    animationEnabled: true,
                                    ...navOptions,
                                }}
                            >
                                {getItemsWithParent(name).map(renderItem(Stack))}
                            </Stack.Navigator>
                        );
                        return (
                            <Type.Screen
                                name={name}
                                options={{
                                    ...screenOptions,
                                    tabBarLabel: t(screenOptions?.tabBarLabel as 'navigation:home'),
                                    tabBarBadge:
                                        screenOptions?.tabBarBadge === 'chat'
                                            ? flattenIniniteResult(chats)
                                                  .filter((chat) => chat.not_read_messages_count > 0)
                                                  .reduce<number | undefined>((curr) => (curr ? ++curr : 1), undefined)
                                            : screenOptions?.tabBarBadge === 'messagesWithoutToNeighbor'
                                              ? flattenIniniteResult(messagesNotNotices)
                                                    .filter((message) => !calculateMesageRead(message))
                                                    .reduce<number | undefined>(
                                                        (curr) => (curr ? ++curr : 1),
                                                        undefined,
                                                    )
                                              : screenOptions?.tabBarBadge === 'messagesToNeighbor'
                                                ? flattenIniniteResult(notices)
                                                      .filter((message) => !calculateMesageRead(message))
                                                      .reduce<number | undefined>(
                                                          (curr) => (curr ? ++curr : 1),
                                                          undefined,
                                                      )
                                                : undefined,
                                }}
                                key={name}
                            >
                                {(args) =>
                                    Component ? (
                                        <ErrorBoundary FallbackComponent={NavigationErrorScreen}>
                                            <Component {...args}>{children}</Component>
                                        </ErrorBoundary>
                                    ) : (
                                        children
                                    )
                                }
                            </Type.Screen>
                        );
                    } else if (settings.type === 'tab') {
                        const children = (
                            <Tab.Navigator
                                initialRouteName={defaultRoute}
                                screenOptions={settings.navOptions}
                                backBehavior={backBehavior}
                                tabBar={(props) => <TabBar {...props} />}
                            >
                                {getItemsWithParent(name).map(renderItem(Tab))}
                            </Tab.Navigator>
                        );

                        return (
                            <Type.Screen name={name} key={name} options={screenOptions}>
                                {() =>
                                    Component ? (
                                        <ErrorBoundary FallbackComponent={NavigationErrorScreen}>
                                            <Component>{children}</Component>
                                        </ErrorBoundary>
                                    ) : (
                                        children
                                    )
                                }
                            </Type.Screen>
                        );
                    }
                } else {
                    const { component: Component, initialParams } = settings;
                    return (
                        <Type.Screen
                            key={name}
                            name={name}
                            initialParams={initialParams}
                            options={{
                                headerShown: false,
                            }}
                        >
                            {(args) => (
                                <ErrorBoundary FallbackComponent={NavigationErrorScreen}>
                                    <Component {...args} />
                                </ErrorBoundary>
                            )}
                        </Type.Screen>
                    );
                }
            },
        [calculateMesageRead, chats, keyboardVisible, messagesNotNotices, notices, t, theme],
    );

    const render = useMemo(() => initialItems.map(renderItem(Stack)), [renderItem]);

    return (
        <Stack.Navigator initialRouteName={initialRoute} screenOptions={{ headerShown: false, animationEnabled: true }}>
            {render}
        </Stack.Navigator>
    );
};

export default memo(RenderConfiguration);
