import { useMemo } from 'react';
import moment from 'moment';
import { useOwnProfile } from '../../_api/useProfile';
import { useGetRatings } from '../../_api/useRatings';
import { flattenIniniteResult } from '../misc';

const useStarAvailableToGive = (): number => {
    const { data: ownProfile } = useOwnProfile();
    const { data: ratings } = useGetRatings(ownProfile?.id);

    return useMemo(() => {
        const moments = flattenIniniteResult(ratings)
            .filter(({ own }) => own)
            .map(({ created_at }) => moment.unix(created_at));

        if (moments.length === 0) {
            return -Infinity;
        }

        const latest = moment.max(moments);
        const diff = moment().diff(latest, 'h');
        return 24 - diff;
    }, [ratings]);
};

export default useStarAvailableToGive;
