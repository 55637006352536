import { useCallback, useEffect, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Mutex } from 'async-mutex';
import moment from 'moment';
import { authorization } from '../../_api';
import { loggedInState } from '../../_redux/settings';
import { isAppError, isNetworkError } from '../../_utils';
import { addBreadcrumb, captureException } from '../../_utils/Sentry';

const mutex = new Mutex();

const useRefreshToken = (
    state: loggedInState,
    onRefreshToken: (result: loggedInState) => void,
): (() => Promise<string>) => {
    addBreadcrumb('tokenHandling', 'render cycle (First one sets state)', 'log');
    const stateRef = useRef<Promise<loggedInState>>(Promise.resolve(state));

    useEffect(() => {
        stateRef.current = Promise.resolve(state);
        addBreadcrumb('tokenHandling', 'effect setting state', 'log');
    }, [state]);

    const refreshToken = useCallback(async (): Promise<loggedInState> => {
        addBreadcrumb('tokenHandling', 'Refreshing', 'log');
        const result = await authorization.loginTokenAsync(state.refresh_token);
        if (result.data) {
            const data = {
                isLoggedIn: true,
                access_token: result.data.access_token,
                refresh_token: result.data.refresh_token,
                expires_in: moment().add({ seconds: result.data.expires_in }).unix(),
            };
            onRefreshToken(data);
            return data;
        }
        throw new Error('Unexpected error occured, result.data not truthy');
    }, [state.refresh_token, onRefreshToken]);

    const errorFunc = useRef<() => void>();
    const resolveFunc = useRef<(value: loggedInState) => void>();
    const { mutateAsync, isPending: isLoading } = useMutation<loggedInState, unknown, void>({
        mutationFn: refreshToken,
        retry(failureCount, error) {
            const appError = isAppError(error);
            const networkError = appError && isNetworkError(error);
            addBreadcrumb(
                'tokenHandling',
                `Retrying: failureCount:${failureCount} isAppError: ${appError} isNetworkError:${networkError}`,
                'log',
            );
            if (appError) {
                if (error.response?.status === 401) {
                    return failureCount < 3;
                }
                if (networkError) {
                    return true;
                }
            }
            return failureCount < 3;
        },
        retryDelay(failureCount, error) {
            if (isAppError(error) && isNetworkError(error)) {
                if (failureCount < 3) {
                    return 0;
                }
                return 1000;
            }
            return Math.min(1000 * 2 ** failureCount, 30000);
        },
        onError: (ex: unknown) => {
            addBreadcrumb('requestError', 'refreshed failed', 'error');
            if (isAppError(ex)) {
                if (ex.response?.status === 401) {
                    addBreadcrumb('requestError', 'refreshed failed', 'error');
                    captureException(new Error('Tokens invalid'));
                    errorFunc.current?.();
                }
            }
            captureException(ex as Error);
            captureException(new Error("Couldn't refresh token"));
            throw ex;
        },
        onSuccess: (data) => {
            addBreadcrumb('tokenHandling', 'Resolving data', 'log');
            if (resolveFunc.current) {
                resolveFunc.current(data);
            } else {
                addBreadcrumb('tokenHandling', 'No resolveFunc.current', 'log');
            }
        },
    });

    useEffect(() => {
        const expiresInMs = moment.unix(state.expires_in).subtract({ day: 1 }).diff(moment());
        if (isLoading || expiresInMs < 1000 * 60 * 5) {
            return;
        }
        const timeout = setTimeout(async () => {
            addBreadcrumb('tokenHandling', 'Timeout refreshing', 'log');
            const release = await mutex.acquire();
            const currState = await stateRef.current;
            try {
                if (moment.unix(currState.expires_in).subtract({ day: 1 }).diff(moment())) {
                    addBreadcrumb('tokenHandling', 'Setting new promise for stateRef.current', 'log');
                    stateRef.current = new Promise((resolve, reject) => {
                        resolveFunc.current = resolve;
                        errorFunc.current = reject;
                    });
                    await mutateAsync();
                }
            } finally {
                release();
            }
        }, expiresInMs);
        return () => clearTimeout(timeout);
    }, [state.expires_in, mutateAsync, isLoading]);

    const getAccessToken = useCallback(async (): Promise<string> => {
        addBreadcrumb('tokenHandling', 'getting', 'log');

        const release = await mutex.acquire();
        try {
            const stateArg = await stateRef.current;
            if (!stateArg.isLoggedIn || moment().isAfter(moment.unix(stateArg.expires_in))) {
                addBreadcrumb('tokenHandling', 'Setting new promise for stateRef.current', 'log');
                stateRef.current = new Promise((resolve, reject) => {
                    resolveFunc.current = resolve;
                    errorFunc.current = reject;
                });
                addBreadcrumb(
                    'tokenHandling',
                    `need to refresh: isLoggedIn:${stateArg.isLoggedIn} || expired:(${moment().isAfter(
                        moment.unix(stateArg.expires_in),
                    )})`,
                    'log',
                );
                return (await mutateAsync())?.access_token ?? '';
            }
            return stateArg.access_token;
        } finally {
            release();
        }
    }, [mutateAsync]);

    return getAccessToken;
};

export default useRefreshToken;
