import React, { Fragment, ReactElement } from 'react';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import Icon from './Icon/Icon';
import { useThemeStyle, WH, Theme } from '../_utils';

const ImagePlaceholder = ({ onPress }: { onPress?: () => void }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const Element = onPress ? TouchableWithoutFeedback : Fragment;
    return (
        <Element accessibilityLabel={onPress ? '' : undefined} onPress={onPress}>
            <View style={[themedStyle.center, themedStyle.image]}>
                <View style={themedStyle.noImageWrapper}>
                    <Icon name="image" color="secondaryLight" />
                </View>
            </View>
        </Element>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        image: {
            backgroundColor: theme.mediumBackground,
            height: WH * 0.35,
        },
        center: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        noImageWrapper: {
            transform: [{ scale: 3 }],
        },
    });

export default ImagePlaceholder;
