import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, ListRenderItemInfo, ViewStyle } from 'react-native';
import ChatItem from './ChatItem';
import GroupAvatar from './GroupAvatar';
import { Chat } from '../../../_api/useChats';
import { useOwnProfile } from '../../../_api/useProfile';
import { WH } from '../../../_utils';
import getChatName from '../../../_utils/getChatName';
import QueryView from '../../../Components/QueryViews/QueryView';
import { ArrayElement } from '../../../types/Utility';

export interface ChatListProps {
    chats: Chat[];
    fetchNextPage: () => void;
    isFetchingNextPage: boolean;
    onChatSelect: (id: number) => void;
    style?: ViewStyle;
}

const ChatList = ({ onChatSelect, fetchNextPage, isFetchingNextPage, style, chats }: ChatListProps): ReactElement => {
    const { t } = useTranslation();
    const onClickItem = useCallback(
        (chat: Chat) => {
            if (onChatSelect) {
                onChatSelect(chat.id);
            }
        },
        [onChatSelect],
    );
    const { data: profile } = useOwnProfile();
    const renderItem = ({ item }: ListRenderItemInfo<ArrayElement<ChatListProps['chats']>>) => {
        const type =
            item.user_group !== null ? 'ChatRelatedToGroup' : item.users.length <= 2 ? 'OneToOne' : 'GroupChat';
        const onPress = () => onClickItem(item);
        const otherMemmbers = item.users.filter(({ id }) => id !== (profile?.id ?? 0));
        const avatar = (
            <GroupAvatar
                type={type}
                usersToDisplay={otherMemmbers}
                groupPicture={item.user_group?.main_picture ?? undefined}
            />
        );

        const lastMessageSenderName =
            item.last_message?.user === profile?.id
                ? t('chatAll:sentByYou')
                : item.users.find(({ id }) => id === item.last_message?.user)?.name.split(' ')[0];

        return (
            <ChatItem
                onPress={onPress}
                avatar={avatar}
                name={getChatName(item, profile?.id ?? 0, t)}
                lastMessage={
                    item.last_message
                        ? `${
                              (type !== 'OneToOne' || !item.name || item.last_message?.user === profile?.id) &&
                              lastMessageSenderName
                                  ? `${lastMessageSenderName}${
                                        item.last_message.text ? ': ' : ' ' + t('chatAll:sentMessage')
                                    }`
                                  : ''
                          }${item.last_message.text ? item.last_message.text : ''}`
                        : ''
                }
                unreadMessageCount={item.not_read_messages_count}
                timeStamp={item.last_message ? item.last_message.created_at : item.created_at}
            />
        );
    };

    return (
        <QueryView
            style={[styles.main, style]}
            data={chats}
            renderItem={renderItem}
            loadMore={fetchNextPage}
            isLoadingMore={isFetchingNextPage}
        />
    );
};

const styles = StyleSheet.create({
    main: {
        paddingTop: WH * 0.01,
        paddingBottom: WH * 0.01,
        flex: 1,
        maxWidth: '100%',
    },
});

export { ChatList };
