import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFocusEffect } from '@react-navigation/native';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { z } from 'zod';
import { useAnswerAction, useMarkProcedureCompleted } from '_api/useProcedures';
import { useAppNavigation } from '_navigator';
import { Theme, useThemeStyle, useConfirmDiscard, showToast } from '_utils';
import { screenMargin } from '_utils/sizes';
import { RoutePropsSchema, RoutePropNumberNullable } from 'types/Utility';
import { ApproveCleaning, Checklist, Information, OpenDoor, Pictures, SkipActionModal } from './components';
import { useProcedureContext } from '../../ProcedureContext';
import { ProcedureScreenProps, useProcedureNavigation } from '../../ProcedureNavigation';

const routeSchema = RoutePropsSchema(z.object({ actionIndex: z.number(), reservationId: RoutePropNumberNullable }));

const formSchema = z.object({
    value: z.string().nullable(),
    options: z.array(z.union([z.string().nullable(), z.boolean()])).optional(),
});
type formValues = z.infer<typeof formSchema>;

const ProcedureAction = (props: ProcedureScreenProps<'ProcedureAction'>): ReactElement => {
    const {
        route: {
            params: { actionIndex, reservationId },
        },
    } = routeSchema.parse(props);
    const themedStyle = useThemeStyle(styles);
    const { push, goBack } = useProcedureNavigation();
    const { t } = useTranslation();
    const { openProcedureAction, skipAction, context, state } = useProcedureContext();
    const { bookingId, procedure, procedureInstance } = context;
    const { navigate } = useAppNavigation();
    const [showingSkipModal, setShowingSkipModal] = useState(false);
    const action = procedure.actions[actionIndex];
    const { isPending: isAnswering, mutate: answerAction } = useAnswerAction(bookingId, procedure.id, action.id);
    const { isPending: isCompleting, mutate: completeAction } = useMarkProcedureCompleted(bookingId, procedure.id);
    const isPending = isAnswering || isCompleting;

    const getFormValues = useCallback((): formValues => {
        const answer = procedureInstance?.answers.find(({ action_id }) => action_id === action.id);

        if (answer) {
            return {
                ...answer,
                options:
                    action.type === 'CHECKLIST'
                        ? action.options.map(({ id }) =>
                              Boolean(
                                  answer.options.find(
                                      ({ procedure_action_options_id }) => procedure_action_options_id === id,
                                  ),
                              ),
                          )
                        : [],
            };
        }
        return {
            value: '',
            options: 'options' in action ? action.options.map(() => '') : [],
        };
    }, [action, procedureInstance?.answers]);
    const form = useForm({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: getFormValues(),
    });

    const { content: confirmDiscardContent } = useConfirmDiscard(
        form.formState.isDirty && !isPending && !showingSkipModal,
        true,
        goBack,
    );

    useFocusEffect(
        useCallback(() => {
            form.reset(getFormValues());
            openProcedureAction(actionIndex, goBack);
        }, [actionIndex, form, getFormValues, goBack, openProcedureAction]),
    );

    const navigateNext = useCallback(() => {
        const isLastStep = actionIndex === procedure.actions.length - 1;

        if (isLastStep) {
            completeAction(null, {
                onSuccess() {
                    navigate('ReservationSelected', { id: bookingId });
                    showToast({
                        type: 'success',
                        header: t(`procedure:completion_success_${procedure.type}`),
                        text: t(`procedure:completion_success_text_${procedure.type}`),
                        position: 'bottom',
                    });
                },
                onError: () => {
                    showToast({
                        type: 'error',
                        header: t('procedure:completion_error'),
                        text: '',
                        position: 'bottom',
                    });
                },
            });
        } else {
            push('ProcedureAction', {
                actionIndex: actionIndex + 1,
                reservationId: reservationId + '',
            });
        }
    }, [
        actionIndex,
        bookingId,
        completeAction,
        navigate,
        procedure.actions.length,
        procedure.type,
        push,
        reservationId,
        t,
    ]);

    const submitData = useCallback(
        (values: formValues, onSuccess: () => void) => {
            if (isAnswering) {
                return;
            }

            const options =
                action.type === 'CHECKLIST'
                    ? values.options
                          ?.filter(Boolean)
                          .filter((art, index) => action.options[index])
                          .map((_, index) => ({ procedure_action_options_id: action.options[index].id }))
                    : action.type === 'PICTURES'
                      ? values.options?.filter(Boolean).map((value, index) => ({
                            file_id: value as string,
                            procedure_action_options_id: action.options[index].id,
                        }))
                      : [];
            answerAction(
                { ...values, options: options ?? [] },
                {
                    onError: () => {
                        showToast({
                            type: 'error',
                            header: t('procedure:answer_error'),
                            text: '',
                            position: 'bottom',
                        });
                    },
                    onSuccess,
                },
            );
        },
        [action, answerAction, isAnswering, t],
    );

    const handleContinue = useCallback(() => {
        const values = form.getValues();
        const onSuccess = navigateNext;
        submitData(values, onSuccess);
    }, [form, navigateNext, submitData]);

    const handleSkipInModal = useCallback(() => {
        const defaultAction = () => {
            skipAction();
            navigateNext();
            setShowingSkipModal(false);
        };
        if (action.type === 'CHECKLIST') {
            const values = form.getValues();
            if (values.options?.find(Boolean)) {
                submitData(values, defaultAction);
                return;
            }
        }
        defaultAction();
    }, [action.type, form, navigateNext, skipAction, submitData]);

    const handleSkip = useCallback(() => {
        if (state.hasSkippedAction) {
            handleSkipInModal();
        } else {
            setShowingSkipModal(true);
        }
    }, [handleSkipInModal, state.hasSkippedAction]);

    const Content = useMemo(() => {
        switch (action.type) {
            case 'OPEN_DOOR':
                return <OpenDoor onContinue={handleContinue} onSkip={handleSkip} isContinuing={isPending} />;
            case 'APPROVE_CLEANING':
                return <ApproveCleaning onContinue={handleContinue} onSkip={handleSkip} isContinuing={isPending} />;
            case 'CHECKLIST':
                return (
                    <Checklist
                        action={action}
                        onContinue={handleContinue}
                        onSkip={handleSkip}
                        isContinuing={isPending}
                    />
                );
            case 'INFORMATION':
                return <Information onContinue={handleContinue} isContinuing={isPending} text={action.description} />;
            case 'PICTURES':
                return <Pictures action={action} onContinue={handleContinue} onSkip={handleSkip} />;
        }
        return null;
    }, [action, handleContinue, handleSkip, isPending]);

    return (
        <View style={themedStyle.container}>
            <FormProvider {...form}>{Content}</FormProvider>
            {showingSkipModal ? (
                <SkipActionModal onSkip={handleSkipInModal} onClose={() => setShowingSkipModal(false)} />
            ) : null}
            {confirmDiscardContent}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            height: '100%',
            backgroundColor: theme.lightBackground,
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            paddingBottom: screenMargin,
        },
    });

export default ProcedureAction;
