import { RefObject, useCallback, useRef } from 'react';
import { PanGestureHandlerGestureEvent, ScrollView } from 'react-native-gesture-handler';
import {
    useAnimatedStyle,
    useAnimatedGestureHandler,
    withTiming,
    runOnJS,
    useSharedValue,
} from 'react-native-reanimated';

const useDraggable = ({
    initialHeight,
    menuHeight,
    isOpen,
    setOpen,
}: {
    initialHeight: number;
    menuHeight: number;
    isOpen: boolean;
    setOpen: (arg: boolean) => void;
}): [
    togglePlusMenu: () => void,
    eventHandler: (event: PanGestureHandlerGestureEvent) => void,
    animatedStyle: ReturnType<typeof useAnimatedStyle>,
    scrollRef: RefObject<ScrollView>,
] => {
    const offset = useSharedValue(initialHeight);
    const pressed = useSharedValue(false);

    const scrollRef = useRef<ScrollView>(null);

    const onFinishedAnim = useCallback(
        (finished: boolean | undefined) => {
            if (finished) {
                setOpen(false);
            }
        },
        [setOpen],
    );

    const eventHandler = useAnimatedGestureHandler({
        onStart: (_event, _ctx) => {
            pressed.value = isOpen;
        },
        onActive: (event, _ctx) => {
            if (pressed.value) {
                offset.value = Math.min(menuHeight - event.translationY, menuHeight);
            }
        },
        onEnd: (_event, _ctx) => {
            pressed.value = false;
            if (offset.value < menuHeight - menuHeight / 4) {
                offset.value = withTiming(initialHeight, { duration: 100 }, (finished) => {
                    runOnJS(onFinishedAnim)(finished);
                });
            } else {
                offset.value = withTiming(menuHeight, { duration: 100 });
            }
        },
    });

    const animatedStyle = useAnimatedStyle(() => {
        return {
            height: offset.value,
        };
    });

    const togglePlusMenu = useCallback(() => {
        if (isOpen) {
            offset.value = withTiming(initialHeight);
            setTimeout(() => {
                onFinishedAnim(true);
            }, 200);
        } else {
            setOpen(true);
            scrollRef.current?.scrollTo({ x: 0, y: 0 });
            offset.value = withTiming(menuHeight);
        }
    }, [menuHeight, offset, onFinishedAnim, isOpen, initialHeight, setOpen, scrollRef]);

    return [togglePlusMenu, eventHandler, animatedStyle, scrollRef];
};

export default useDraggable;
