import { PropsWithChildren, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { Theme, useThemeStyle, useDebounceValue } from '_utils';
import { captureException } from '_utils/Sentry';
import { smallestMargin, subtitleFontSize } from '_utils/sizes';
import { HeimeText, SecondaryButton } from 'Components';
import { useTimeSync } from '../../../TimeSyncContext';

const TimeSyncGuard = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const { status, refetch, isLoading } = useTimeSync();

    // We debounce this, as focus effect could be off for a second sometimes.
    const debouncedStatus = useDebounceValue(status, 1000);
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    useEffect(() => {
        if (status) {
            captureException(new Error(`TimeSyncGuard: ${status}`));
        }
    }, [status]);

    if (debouncedStatus) {
        return (
            <View>
                <HeimeText style={themedStyle.text}>
                    {debouncedStatus === 'changedClockNegative'
                        ? t('expandedReservation:changedClockNegative')
                        : debouncedStatus === 'serverAndPhoneOutOfSync'
                          ? t('expandedReservation:serverAndPhoneOutOfSync')
                          : t('expandedReservation:noDataToCompare')}
                </HeimeText>
                <SecondaryButton
                    status={isLoading ? 'loading' : undefined}
                    onPress={isLoading ? () => {} : refetch}
                    text={t('expandedReservation:tryAgain')}
                />
            </View>
        );
    }

    return <>{children}</>;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        text: {
            fontSize: subtitleFontSize,
            backgroundColor: theme.error,
            color: theme.errorLight,
            padding: smallestMargin,
            marginBottom: smallestMargin,
        },
    });

export default TimeSyncGuard;
