import React, { ReactElement, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { Platform, View } from 'react-native';
import { useOwnProfile } from '_api/useProfile';
import { useBottomSpacer } from '_utils/hooks';
import ActivitySuggestionCta from './ActivitySuggestionCta';
import EmptyActivities from './EmptyActivities';
import { useGetActivities, Activity as ActivityType } from '../../../_api/useActivities';
import Images from '../../../_images';
import { WW, WH, isWeb } from '../../../_utils';
import { flattenIniniteResult } from '../../../_utils/misc';
import { screenMargin, smallestMargin } from '../../../_utils/sizes';
import { CacheImage, Loader, QuerySectionedView, SectionHeader } from '../../../Components';
import Activity from '../../../Components/Activity';

const getMonthString = (date: Moment) => {
    if (date.isSame(moment(), 'year')) {
        return date.format('MMMM');
    } else {
        return date.format('MMMM YYYY');
    }
};

interface ActivitiesListProps {
    filter?: 'future' | 'futureOnlyAttending' | 'previous';
}
const ActivitiesList = ({ filter = 'future' }: ActivitiesListProps): ReactElement => {
    const { data: ownProfile } = useOwnProfile();
    const { data, isLoading, fetchNextPage, isFetchingNextPage, refetch, isFetching, isError, error } =
        useGetActivities(
            filter === 'previous' ? moment.unix(0).startOf('day') : moment().startOf('day'),
            filter === 'previous' ? moment().endOf('day') : undefined,
            filter === 'futureOnlyAttending',
            filter === 'previous',
        );

    const filtered = useMemo(() => {
        const object = flattenIniniteResult(data).reduce(
            (curr, value) => {
                const month = getMonthString(
                    filter === 'previous'
                        ? moment.unix(value.start_at)
                        : moment.max([moment.unix(value.start_at), moment()]),
                );
                const arr = curr[month] ?? [];
                arr.push(value);
                curr[month] = arr;
                return curr;
            },
            {} as Record<string, ActivityType[]>,
        );
        return Object.entries(object).map(([key, array]) => ({ title: key, data: array }));
    }, [data, filter]);

    const renderItem = ({ item: activity }: { item: ActivityType }) => {
        let signedUp = Boolean(activity.arrangers.find((item) => item.id === ownProfile?.id) || activity.approved);

        return <Activity primary key={activity.id} data={activity} style={styles.activity} signedUp={signedUp} />;
    };

    const paddingBottom = useBottomSpacer();
    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        throw error;
    }

    if (filtered.length === 0) {
        return <EmptyActivities filter={filter} />;
    }

    return (
        <View style={[styles.margin, styles.main]}>
            <QuerySectionedView
                containerStyle={
                    isWeb()
                        ? { paddingBottom, paddingRight: screenMargin, paddingLeft: screenMargin }
                        : { paddingBottom }
                }
                emptyComponent={
                    <CacheImage
                        resizeMode={'contain'}
                        source={Images.no_result}
                        style={[styles.image, styles.margin, styles.imagePadding]}
                    />
                }
                sections={filtered}
                header={({ title }) => <SectionHeader title={title} />}
                renderItem={renderItem}
                loadMore={fetchNextPage}
                isLoadingMore={isFetchingNextPage}
                onRefresh={refetch}
                isRefreshing={isFetching}
                bottom={
                    <View
                        style={{
                            width: '100%',
                            marginTop: screenMargin,
                            marginBottom: screenMargin,
                            paddingHorizontal: screenMargin,
                        }}
                    >
                        {filter === 'future' ? <ActivitySuggestionCta /> : null}
                    </View>
                }
            />
        </View>
    );
};

const styles = Platform.select({
    default: {
        main: { marginTop: smallestMargin, height: '100%' as const },
        activity: {
            marginBottom: smallestMargin,
        },
        margin: { marginRight: screenMargin, marginLeft: screenMargin },
        image: {
            flex: 1,
            width: '100%' as const,
        },
        imagePadding: {},
    },
    web: {
        main: { marginTop: WW * 0.02, height: '100%' as const },
        activity: {
            paddingBottom: WH * 0.02,
        },
        margin: {
            marginLeft: -screenMargin / 2,
            marginRight: -screenMargin / 2,
        },
        imagePadding: {
            paddingRight: screenMargin,
            paddingLeft: screenMargin,
        },
    },
});

export default ActivitiesList;
