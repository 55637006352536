import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { screenMargin } from '_utils/sizes';
import { HeimeText } from 'Components';
import { BottomButtons, ContentDisplay } from './common';

const Information = ({
    onContinue,
    isContinuing,
    text,
}: {
    onContinue(): void;
    isContinuing: boolean;
    text: string | null;
}) => {
    const { t } = useTranslation();
    const themedStyle = useThemeStyle(styles);
    const { setValue } = useFormContext();

    const handleContinue = () => {
        setValue('value', true, { shouldDirty: true });
        onContinue();
    };

    return (
        <ContentDisplay
            content={
                <ScrollView bounces={false}>
                    <HeimeText variant="title">{t('procedure:information_header')}</HeimeText>
                    <HeimeText maxFontSizeMultiplier={2} linkify variant="subtitle" style={themedStyle.subtitle}>
                        {text}
                    </HeimeText>
                </ScrollView>
            }
            buttons={
                <BottomButtons
                    buttons={[{ text: t('procedure:continue'), onPress: handleContinue, isLoading: isContinuing }]}
                />
            }
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        subtitle: {
            marginTop: screenMargin,
            color: theme.darkGray,
        },
    });

export default Information;
