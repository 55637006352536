import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { Activity as PropsActivity } from '_api/useActivities';
import { useOwnProfile } from '_api/useProfile';
import { Theme, WH, WW, isWeb, useThemeStyle } from '_utils';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { Activity, Loader, Icon, BottomSpacer, HeimeText } from 'Components';
import EmptyActivitiesPreview from './EmptyActivitiesPreview';

interface ActivitiesPreviewProps {
    filteredActivities: PropsActivity[];
    isLoading: boolean;
    isFetchingNextPage: boolean;
}

const ActivitiesPreview = ({
    filteredActivities,
    isLoading,
    isFetchingNextPage,
}: ActivitiesPreviewProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const ownId = useOwnProfile().data?.id;

    const nextHeader = <HeimeText style={themedStyle.label}>{t('home:next')}</HeimeText>;

    return (
        <>
            <View style={[themedStyle.container, themedStyle.backgroundColor]}>
                <View style={themedStyle.handle}>
                    <Icon name="handle" color="white" />
                </View>
                {isLoading ? (
                    <>
                        {nextHeader}
                        <ActivityIndicator />
                    </>
                ) : filteredActivities.length > 0 ? (
                    <>
                        {nextHeader}
                        {filteredActivities.map((filAct) => (
                            <Activity
                                key={filAct.id}
                                primary
                                style={themedStyle.activity}
                                data={filAct}
                                signedUp={Boolean(
                                    filAct.arrangers.find((item) => item.id === ownId) || filAct.approved,
                                )}
                            />
                        ))}
                        {isFetchingNextPage ? <Loader /> : null}
                    </>
                ) : null}
                <View style={{ marginBottom: screenMargin }} />
                <EmptyActivitiesPreview />
                <BottomSpacer />
            </View>
            {isWeb() ? null : <View style={[themedStyle.bottomColorView, themedStyle.backgroundColor]} />}
        </>
    );
};

export default ActivitiesPreview;

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            borderTopRightRadius: 40,
            borderTopLeftRadius: 40,
            shadowColor: theme.black,
            alignItems: 'stretch',
            marginTop: 'auto',
        },
        handle: {
            alignItems: 'center',
            marginTop: smallestMargin,
        },
        label: {
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: WW * 0.04,
            marginTop: WH * 0.03,
            marginBottom: WH * 0.03,
            color: theme.black,
        },
        activity: {
            marginBottom: 0,
            borderBottomWidth: 0,
        },
        noActivitiesMessage: {
            textAlign: 'center',
            color: theme.black,
        },
        createSuggestion: {
            textAlign: 'center',
            color: theme.hyperText,
        },
        backgroundColor: {
            backgroundColor: theme.mainBackground,
        },
        bottomColorView: { position: 'absolute', height: WH, width: '100%', bottom: -WH },
    });
