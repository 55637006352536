import { useIsFocused } from '@react-navigation/native';
import { InfiniteData, UseInfiniteQueryResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { ApiImage } from 'types/Base';
import { useLaravelInfinteQuery } from './utility';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import { LaravelPagingResponse } from '../types/Utility';

export interface MemberBenefit {
    id: number;
    title: string;
    description: string;
    url: string;
    promo_code: string;
    show_live_ticket: boolean;
    images: ApiImage[];
}

const useGetMemberBenefits = (): UseInfiniteQueryResult<InfiniteData<MemberBenefit[]>, string | Error> => {
    const isFocused = useIsFocused();
    const selectedCoopId = useSelectedCoop();
    return useLaravelInfinteQuery(
        ['memberBenefits', selectedCoopId],
        async ({ pageParam = 1, getAuthHeader }) =>
            await axios.get<LaravelPagingResponse<MemberBenefit[]>>(`cooperatives/${selectedCoopId}/member_benefits`, {
                headers: { authorization: await getAuthHeader() },
                params: { page: pageParam },
            }),
        {
            gcTime: Infinity,
            staleTime: 1000 * 60 * 5,
            enabled: isFocused,
        },
    );
};

const useGetMemberBenefit = (benefitId: number): UseQueryResult<MemberBenefit, string | Error> => {
    const isFocused = useIsFocused();
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: ['memberBenefit', selectedCoopId, benefitId],

        queryFn: async () => {
            const result = await axios.get<MemberBenefit>(
                `cooperatives/${selectedCoopId}/member_benefits/${benefitId}`,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data) {
                throw new Error('Result returned with no data');
            }

            return result.data;
        },

        gcTime: 1000 * 60 * 60,
        staleTime: 1000 * 60 * 1,

        initialData: () => {
            const relatedQueries = queryClient
                .getQueryCache()
                .getAll()
                .filter(
                    (item) =>
                        !item.isStale() &&
                        item.queryKey?.[0] === 'memberBenefits' &&
                        item.queryKey?.[1] === selectedCoopId,
                );

            let result: MemberBenefit | undefined;
            relatedQueries?.forEach((query) =>
                (query.state.data as InfiniteData<LaravelPagingResponse<MemberBenefit[]>>)?.pages?.forEach(({ data }) =>
                    data?.forEach((benefit: MemberBenefit) => {
                        if (benefitId === benefit.id) {
                            result = benefit;
                        }
                    }),
                ),
            );
            return result;
        },

        initialDataUpdatedAt: moment().valueOf(),
        enabled: isFocused,
    });
};

export { useGetMemberBenefits, useGetMemberBenefit };
