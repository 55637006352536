import React, { ComponentProps, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, TouchableOpacity, Image, TouchableWithoutFeedback } from 'react-native';
import { useUploadFile } from '_api/useFile';
import Images from '_images';
import { Theme, useThemeStyle, showToast, WW, WH, useImageSelector, isWeb, isAndroid } from '_utils';
import { track } from '_utils/Amplitude';
import { captureException } from '_utils/Sentry';
import { screenMargin } from '_utils/sizes';
import { CacheImage, FileActionSheet, SecondaryButton } from 'Components';
import { ApiImage, FileImage } from 'types/Base';
import { FileToBeUploaded } from 'types/Utility';
import { OpacityEffect, UploadProgress } from './components';
import getPictureFromFacebook, { disposePictureFromFacebook } from './getPictureFromFacebook';
import DescribeText from '../DescribeText';

const getDefaultUploadState = (avatar: string | null, initialAvatar: ApiImage | FileImage | null): ImageUploadState =>
    avatar === undefined && initialAvatar ? { status: 'EXIST', avatar: initialAvatar } : { status: 'NONE' };

type ImageUploadState =
    | { status: 'NONE'; cancelled?: boolean }
    | { status: 'EXIST'; avatar: ApiImage | FileImage; cancelled?: boolean }
    | { status: 'SELECTED'; progress: number }
    | { status: 'DOWNLOADED'; progress: number; url: string }
    | { status: 'UPLOADED'; url: string }; // Do we have the url here or do we need to get it somehow

const ChangeAvatar = ({ initialAvatar }: { initialAvatar: ApiImage | FileImage | null }) => {
    const [actionSheetOpen, setActionSheetOpen] = useState<null | 'edit' | 'create'>(null);
    const handleCloseActionSheet = () => setActionSheetOpen(null);
    const { setValue, watch, setError, clearErrors } = useFormContext<{ avatar: string | null }>();
    const avatar = watch('avatar');
    const [uploadState, setUploadState] = useState<ImageUploadState>(getDefaultUploadState(avatar, initialAvatar));
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { mutateAsync } = useUploadFile();

    useEffect(() => {
        track('Showing change avatar', {
            state: uploadState.status,
        });
    }, [uploadState.status]);

    const handleAddImages = (images: Array<FileToBeUploaded | { path: string; contentType: string }>) => {
        if (images.length > 0) {
            const firstImage = images[0];
            if ('path' in firstImage) {
                handleUploadFile(firstImage);
            } else if (firstImage.file && firstImage.file instanceof File) {
                handleUploadFile({ path: firstImage.file, contentType: firstImage.file.type });
            } else {
                throw new Error('Unsupported image type');
            }
        }
    };
    const [onStartSelecting, modal] = useImageSelector(handleAddImages, handleCloseActionSheet, false);
    const handleChangePhoto = () => {
        track('Change avatar');
        setActionSheetOpen('edit');
    };

    const handleUploadPhoto = () => {
        track('Upload photo');
        setActionSheetOpen('create');
    };

    const updateStateIfNotCancelled = (state: ImageUploadState) =>
        setUploadState((curr) => ('cancelled' in curr && curr.cancelled ? curr : state));

    const handleUploadFile = async (image: { path: string | File; contentType: string }) => {
        const pathToImage = typeof image.path === 'string' ? image.path : URL.createObjectURL(image.path);
        updateStateIfNotCancelled({ status: 'DOWNLOADED', progress: 0, url: pathToImage });
        try {
            setError('block_submit' as 'avatar', { message: t('profileEdit:uploading_file') });
            const uploadResult = await mutateAsync({
                ...image,
                path: image.path,
                onProgress: (progress) =>
                    updateStateIfNotCancelled({ status: 'DOWNLOADED', progress: progress * 100, url: pathToImage }),
            });
            setValue('avatar', uploadResult.success, { shouldDirty: true });
            updateStateIfNotCancelled({ status: 'UPLOADED', url: pathToImage });
        } catch (e) {
            captureException(e);
            showToast({
                type: 'error',
                header: t('global:upload_failed'),
                text: '',
            });
            updateStateIfNotCancelled(getDefaultUploadState(avatar, initialAvatar));
        } finally {
            clearErrors('block_submit' as 'avatar');
        }
    };

    const handleFacebookUploadState = (progress: number) =>
        updateStateIfNotCancelled({ status: 'SELECTED', progress: progress * 100 });
    const handleUploadFacebook = async () => {
        track('Upload facebook');
        disposePictureFromFacebook();
        setActionSheetOpen(null);
        setUploadState({ status: 'SELECTED', progress: 0 });
        let image = null;
        try {
            image = await getPictureFromFacebook(t, handleFacebookUploadState);
        } catch (e) {
            showToast({
                header: t('profileEdit:facebookLoginErrorHeader'),
                text: 'facebookLoginErrorDescription',
                type: 'error',
            });
            captureException(e);
        }

        if (!image) {
            setUploadState(getDefaultUploadState(avatar, initialAvatar));
            return null;
        }

        await handleUploadFile(image);
    };

    const actions = [
        {
            type: 'Library',
            onSelect: () => onStartSelecting({ cropping: true, type: 'library' }),
        },
        {
            type: 'Camera',
            onSelect: () => onStartSelecting({ cropping: true, type: 'camera' }),
        },
        isWeb()
            ? null
            : {
                  type: 'Facebook',
                  onSelect: handleUploadFacebook,
              },
        {
            type: 'Remove',
            onSelect: () => {
                setValue('avatar', null, { shouldDirty: true });
                setUploadState({ status: 'NONE' });
                setActionSheetOpen(null);
            },
        },
    ].filter(Boolean) as ComponentProps<typeof FileActionSheet>['actions'];
    const content = (
        <>
            {modal}
            {actionSheetOpen ? (
                <FileActionSheet
                    actions={actions.filter((arg) =>
                        actionSheetOpen === 'create' ? ['Library', 'Camera'].includes(arg.type) : true,
                    )}
                    onClose={handleCloseActionSheet}
                />
            ) : null}
        </>
    );

    if (uploadState.status === 'EXIST') {
        return (
            <>
                <TouchableOpacity
                    accessibilityLabel={t('profileEdit:change_picture')}
                    onPress={handleChangePhoto}
                    style={themedStyle.avatarButton}
                >
                    <CacheImage<FileImage | ApiImage> source={uploadState.avatar} style={themedStyle.avatar} />
                </TouchableOpacity>
                {content}
            </>
        );
    } else if (uploadState.status === 'NONE') {
        return (
            <>
                <SecondaryButton
                    icon="plus"
                    text={t('onboarding:upload_photo')}
                    onPress={handleUploadPhoto}
                    style={{ marginTop: screenMargin }}
                />
                {isWeb() ? null : (
                    <>
                        <SecondaryButton
                            text={
                                <>
                                    <CacheImage
                                        style={{ width: 24, height: 24, marginRight: screenMargin }}
                                        resizeMode="contain"
                                        source={Images.facebook}
                                    />
                                    {t('onboarding:upload_facebook')}
                                </>
                            }
                            onPress={handleUploadFacebook}
                            style={{ marginTop: screenMargin }}
                        />
                    </>
                )}
                <DescribeText>{t('onboarding:add_photo')}</DescribeText>
                {content}
            </>
        );
    } else if (uploadState.status === 'SELECTED') {
        return (
            <>
                <TouchableWithoutFeedback
                    accessibilityLabel={t('profileEdit:change_picture')}
                    onPress={handleChangePhoto}
                    style={themedStyle.avatarButton}
                >
                    <View style={themedStyle.avatarButton}>
                        <UploadProgress progress={uploadState.progress / 100 / 2} avatarSize={avatarSize} />
                        <OpacityEffect>
                            <View style={[themedStyle.avatar, themedStyle.downloadingAvatarBackground]} />
                        </OpacityEffect>
                    </View>
                </TouchableWithoutFeedback>
                {content}
            </>
        );
    } else if (uploadState.status === 'DOWNLOADED') {
        return (
            <>
                <TouchableOpacity
                    accessibilityLabel={t('profileEdit:change_picture')}
                    onPress={handleChangePhoto}
                    style={themedStyle.avatarButton}
                >
                    <OpacityEffect>
                        <Image
                            source={{ uri: isAndroid() ? `file://${uploadState.url}` : uploadState.url }}
                            style={themedStyle.avatar}
                        />
                    </OpacityEffect>
                    <UploadProgress progress={uploadState.progress / 100 / 2 + 0.5} avatarSize={avatarSize} />
                </TouchableOpacity>
                {content}
            </>
        );
    } else if (uploadState.status === 'UPLOADED') {
        return (
            <>
                <TouchableOpacity
                    accessibilityLabel={t('profileEdit:change_picture')}
                    onPress={handleChangePhoto}
                    style={themedStyle.avatarButton}
                >
                    <Image
                        source={{ uri: isAndroid() ? `file://${uploadState.url}` : uploadState.url }}
                        style={themedStyle.avatar}
                    />
                </TouchableOpacity>
                {content}
            </>
        );
    }

    throw new Error('Unknown state');
};

const avatarSize = Math.min(WW * 0.4, WH * 0.4);

const styles = (theme: Theme) =>
    StyleSheet.create({
        avatarButton: { alignItems: 'center', marginTop: screenMargin },
        avatar: {
            width: avatarSize,
            height: avatarSize,
            borderRadius: avatarSize / 2,
            overflow: 'hidden',
        },
        downloadingAvatarBackground: {
            backgroundColor: theme.main,
        },
        container: {
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
        },
    });

export default ChangeAvatar;
