import React, { ReactElement } from 'react';
import { DateTimePickerEvent } from '@react-native-community/datetimepicker';
import moment from 'moment';
import { DimensionValue, StyleSheet, View } from 'react-native';
import Modal from '../Modal/Modal';
import PrimaryButton from '../PrimaryButton';

interface DateTimePickerModalWebProps {
    mode: 'date' | 'time';
    onChange: ((d: Date) => void) & ((d: DateTimePickerEvent, da?: Date | undefined) => void);
    onConfirm(d: Date): void;
    onCancel(): void;
    date: Date;
    minuteInterval?: number;
    minimumDate?: Date;
    maximumDate?: Date;
    confirmTextIOS: string;
}

const DateTimePickerModal = ({
    mode,
    onCancel,
    onConfirm,
    date,
    onChange,
    minimumDate,
    maximumDate,
    minuteInterval,
    confirmTextIOS,
}: DateTimePickerModalWebProps): ReactElement => {
    const handleChange = (ev: Event) => {
        const newDate = new Date((ev.target as unknown as { value: string })?.value);
        if (!isNaN(newDate.valueOf())) {
            onChange(newDate);
        }
    };

    const formatDate = (val: Date) => moment(val).format('yyyy-MM-DD');

    return (
        <Modal
            onDismiss={onCancel}
            header={''}
            content={
                <>
                    {mode === 'date' ? (
                        React.createElement('input', {
                            type: 'date',
                            onChange: handleChange,
                            style: { fontSize: '1.5rem', marginTop: '40px' },
                            value: formatDate(date),
                            min: minimumDate ? formatDate(minimumDate) : null,
                            max: maximumDate ? formatDate(maximumDate) : null,
                        })
                    ) : (
                        <TimeInput minuteInterval={minuteInterval ?? 5} date={date} onChange={onChange} />
                    )}
                    <PrimaryButton style={styles.button} onPress={() => onConfirm(date)} text={confirmTextIOS} />
                </>
            }
        />
    );
};

interface TimeInputProps {
    date: Date;
    onChange(date: Date): void;
    minuteInterval: number;
}

const hourOptions = new Array(24).fill(0).map((v, num) => ({ value: num, text: num }));

const TimeInput = ({ date, onChange, minuteInterval }: TimeInputProps) => {
    const hour = date.getHours();
    const minute = date.getMinutes();

    const handleHourChange = (el: { target: { value: number } }) => {
        const newHour = el.target.value;
        const newDate = new Date(date);
        newDate.setHours(newHour);
        onChange(newDate);
    };

    const handleMinuteChange = (el: { target: { value: number } }) => {
        const newMinute = el.target.value;
        const newDate = new Date(date);
        newDate.setMinutes(newMinute);
        onChange(newDate);
    };

    const minuteOptions = new Array(60 / minuteInterval)
        .fill(0)
        .map((v, num) => ({ value: num * minuteInterval, text: num * minuteInterval }));

    return (
        <View style={styles.timeContainer}>
            {React.createElement('select', {
                value: hour,
                onChange: handleHourChange,
                style: { flexGrow: 1, fontSize: '1.5rem' },
                children: [
                    hourOptions.map((item) =>
                        React.createElement('option', { key: item.value, value: item.value, children: item.text }),
                    ),
                ],
            })}
            {React.createElement('select', {
                value: minute,
                onChange: handleMinuteChange,
                style: { flexGrow: 1, fontSize: '1.5rem', marginLeft: '1rem' },
                children: minuteOptions.map((item) =>
                    React.createElement('option', { key: item.value, value: item.value, children: item.text }),
                ),
            })}
        </View>
    );
};

const styles = StyleSheet.create({
    timeContainer: { display: 'flex', flexDirection: 'row', width: '100%', marginTop: 40 },
    button: { marginTop: '1rem' as DimensionValue },
});

export default DateTimePickerModal;
