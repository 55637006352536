import { useIsFocused } from '@react-navigation/native';
import {
    InfiniteData,
    QueryClient,
    QueryFilters,
    QueryKey,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { AxiosPromise } from 'axios';
import { z, ZodTypeDef } from 'zod';
import { captureException, addBreadcrumb } from '_utils/Sentry';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop/hooks';
import { createLaravelPagingResponseSchema, FileToBeUploaded, LaravelPagingResponse } from '../types/Utility';

export const useLaravelInfinteQuery = <ItemType, ErrorType = string | Error>(
    queryKey: QueryKey,
    queryFunc: (params: {
        pageParam: number | unknown;
        selectedCoopId: number;
        getAuthHeader(): Promise<string>;
    }) => AxiosPromise<LaravelPagingResponse<ItemType>>,
    queryOptions: Omit<
        UseInfiniteQueryOptions<LaravelPagingResponse<ItemType>, ErrorType, InfiniteData<ItemType>>,
        'queryKey' | 'queryFn' | 'getNextPageParam' | 'initialPageParam'
    > = {},
    zodSchema?: z.ZodSchema<ItemType, z.ZodTypeDef, unknown>,
): UseInfiniteQueryResult<InfiniteData<ItemType>, ErrorType> => {
    const isFocused = useIsFocused();
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();

    const values = useInfiniteQuery<LaravelPagingResponse<ItemType>, ErrorType, InfiniteData<ItemType>>({
        queryKey,
        queryFn: async (params) => {
            const result = await queryFunc({ ...params, selectedCoopId, getAuthHeader });

            if (!result.data.data) {
                throw new Error('Result returned with success == false');
            }
            if (zodSchema) {
                return safeParse(
                    result.data,
                    createLaravelPagingResponseSchema(zodSchema),
                ) as LaravelPagingResponse<ItemType>;
            }
            return result.data;
        },
        getNextPageParam: getNextPageParamFromLaravelPaging,
        initialPageParam: 1,
        select: selectDataPerPage,
        enabled: isFocused,
        ...queryOptions,
    });

    return values;
};

const getNextPageParamFromLaravelPaging = (lastPage: LaravelPagingResponse<unknown>): number | undefined => {
    if (lastPage.meta && lastPage.meta.last_page > lastPage.meta.current_page) {
        return lastPage.meta.current_page + 1;
    }
};

const selectDataPerPage = <ItemType>(responseData: InfiniteData<LaravelPagingResponse<ItemType>>) => ({
    ...responseData,
    pages: responseData.pages.map((page) => page.data),
});

export const getFileObjectFunc = (objectName: string): ((arg: FileToBeUploaded) => { data: string; name: string }) =>
    (({ uri, index, name }: FileToBeUploaded) => ({ data: uri, name: name ? name : objectName + index })) ?? [];

const getValueWithPath = (obj: unknown, path: Array<string | number>): unknown => {
    return path.reduce((acc, key) => {
        if (acc && typeof acc === 'object') {
            if (key in acc) {
                return (acc as Record<string | number, unknown>)[key];
            }
        }
        return undefined;
    }, obj);
};

const getZodMessage = (issue: z.ZodIssue, value: unknown): string => {
    const path = issue.path.join('.');
    if (issue.code === 'invalid_union') {
        const findUnion = issue.unionErrors.find(
            (error) => !error.issues.some((innerIssue) => innerIssue.path[innerIssue.path.length - 1] === 'type'),
        );
        if (findUnion) {
            return findUnion.issues
                .map((innerIssue) =>
                    JSON.stringify({
                        message: innerIssue.message,
                        path: innerIssue.path.join('.'),
                        code: innerIssue.code,
                        value: getValueWithPath(value, innerIssue.path),
                        outerValue: getValueWithPath(value, innerIssue.path.splice(0, innerIssue.path.length - 1)),
                    }),
                )
                .join(', ');
        }
        const unionErrors = issue.unionErrors
            .map((unionError, index) => {
                return `Union member ${index + 1}, value:"${JSON.stringify(
                    getValueWithPath(value, issue.path),
                )}": ${unionError.errors.map(getZodMessage).join(', ')}`;
            })
            .join(' | ');
        return `${path}: Invalid union. ${unionErrors}`;
    } else {
        return `${path}: ${issue.message}`;
    }
};

/**
 * This function parses the given value with the given schema, and returns the parsed value.
 * If we are in development mode, it will throw an error if the value does not match the schema. If not it will just report the error.
 * @param value - The value to be parsed.
 * @param schema - The Zod schema to parse the value with.
 * @returns The parsed value if successful, or the original value if an error occurs outside of development mode.
 */
export function safeParse<T>(value: unknown, schema: z.ZodSchema<T, ZodTypeDef, unknown>): T {
    try {
        return schema.parse(value);
    } catch (e) {
        if (e instanceof z.ZodError) {
            console.error(e.errors.map((val) => getZodMessage(val, value)).join('\n'));
            const message = e.errors.map((val) => getZodMessage(val, value)).join('; ');
            console.error(message);
            addBreadcrumb('errorContext', message, 'error');
        }
        if (__DEV__) {
            throw e;
        } else {
            captureException(e);
            return value as T;
        }
    }
}

export const getPlaceholderSearchData =
    <ReturnType extends { id: number }>(
        queryClient: QueryClient,
        queryFilters: QueryFilters,
        itemFilter: (item: ReturnType) => boolean,
        sort?: (a: ReturnType, b: ReturnType) => number,
    ) =>
    (): InfiniteData<LaravelPagingResponse<Array<ReturnType>>> | undefined => {
        const relatedQuerydata =
            queryClient.getQueriesData<InfiniteData<LaravelPagingResponse<Array<ReturnType>>>>(queryFilters);
        const foundItemIds = new Set();
        const foundItems: ReturnType[] = [];
        relatedQuerydata?.forEach(([, result]) => {
            result?.pages?.forEach(({ data }) =>
                data?.forEach((item) => {
                    if (foundItemIds.has(item.id)) {
                        return;
                    }

                    if (itemFilter(item)) {
                        foundItemIds.add(item.id);
                        foundItems.push(item);
                    }
                }),
            );
        });
        if (foundItems.length === 0) {
            return undefined;
        }

        const sorted = sort ? foundItems.sort(sort) : foundItems;
        return { pages: [{ data: sorted, meta: {} } as LaravelPagingResponse<ReturnType[]>], pageParams: [] };
    };
