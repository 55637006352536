import React, { ReactElement, useEffect, useState } from 'react';
import { getLinkPreview } from 'link-preview-js';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import MessageAlertModal from './common/MessageAlertModal';
import _fonts from '../../../_fonts';
import { useThemeStyle, WW } from '../../../_utils';
import { smallestFontSize } from '../../../_utils/sizes';
import { Theme } from '../../../_utils/themeContext';
import { CacheImage, Icon } from '../../../Components';

interface LinkPreview {
    url: string;
    title?: string | undefined;
    siteName?: string | undefined;
    description?: string | undefined;
    mediaType: string;
    contentType?: string | undefined;
    images?: string[] | undefined;
    videos?: {
        url: string | undefined;
        secureUrl: string | null | undefined;
        type: string | null | undefined;
        width: string | undefined;
        height: string | undefined;
    }[];
    favicons: string[];
    hasError?: boolean;
}

interface LinkPreviewProps {
    link: string;
    allContentIsLink: boolean;
    onLongPress?: () => void;
}

const LinkPreview = ({ link, allContentIsLink, onLongPress }: LinkPreviewProps): ReactElement | null => {
    const themedStyle = useThemeStyle(styles);
    const [linkData, setLinkData] = useState<LinkPreview | null>(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        getLinkPreview(link)
            .then((data) => {
                setLinkData(data);
            })
            .catch((error) => {
                setLinkData({ ...error, hasError: true });
            });
    }, [link]);

    const handleToggleModal = () => setModalOpen((curr) => !curr);

    if ((linkData !== null && !linkData.hasError) || allContentIsLink) {
        return (
            <>
                <TouchableOpacity onLongPress={onLongPress} onPress={handleToggleModal} style={themedStyle.main}>
                    {linkData !== null &&
                    !linkData.hasError &&
                    ((linkData.images && linkData.images.length) || (linkData.favicons && linkData.favicons.length)) ? (
                        <View style={themedStyle.previewImageWrapper}>
                            <CacheImage
                                resizeMode={'cover'}
                                style={{ width: WW * 0.25, height: WW * 0.25 }}
                                source={{ original: linkData.images?.[0] ? linkData.images[0] : linkData.favicons[0] }}
                            />
                        </View>
                    ) : null}

                    <View style={themedStyle.contentMain}>
                        <View style={themedStyle.content}>
                            <View style={themedStyle.previewContent}>
                                <Text numberOfLines={1} style={themedStyle.title}>
                                    {linkData?.title ? linkData.title : linkData?.url ? linkData?.url : link}
                                </Text>
                                {linkData?.title ? (
                                    <Text numberOfLines={1} style={themedStyle.subTitle}>
                                        {linkData?.url ?? link}
                                    </Text>
                                ) : undefined}
                            </View>
                            <Icon name="chevron" color="mediumGrey" />
                        </View>
                    </View>
                </TouchableOpacity>
                {modalOpen ? <MessageAlertModal onDismiss={handleToggleModal} link={link ?? linkData?.url} /> : null}
            </>
        );
    } else {
        return null;
    }
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            width: WW * 0.75,
            flexDirection: 'row',
            backgroundColor: theme.mainBackground,
        },
        contentMain: {
            flex: 1,
            borderWidth: 1,
            borderColor: theme.lightGrey,
            paddingLeft: WW * 0.02,
            minHeight: 70,
        },
        previewImageWrapper: {
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 1,
            borderColor: theme.lightGrey,
            width: WW * 0.25,
            height: WW * 0.25,
        },
        title: {
            fontSize: smallestFontSize,
            fontFamily: _fonts.primaryFontBold,
            color: theme.darkGreen,
        },
        subTitle: {
            fontSize: WW * 0.03,
            fontFamily: _fonts.primaryFont,
            color: theme.secondaryText,
        },
        content: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: WW * 0.03,
            paddingBottom: WW * 0.03,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
        },
        previewContent: { flex: 1 },
    });

export default LinkPreview;
