import React, { ReactElement } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { showToast } from '_utils';
import { useSendNotification } from '../../../../_api/useActivities';
import { Modal, GenericSuccessScreen, FormInput } from '../../../../Components';

const schema = z.object({
    title: z.string().nonempty(),
    content: z.string().nonempty(),
});

export type FormValues = z.infer<typeof schema>;

interface SendActivityAlertModal {
    onDismiss(): void;
    activityId: number;
}

const SendActivityAlertModal = ({ onDismiss, activityId }: SendActivityAlertModal): ReactElement => {
    const { t } = useTranslation();
    const form = useForm<FormValues>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: { title: '', content: '' },
    });

    const { mutate: sendNotification, isSuccess, isPending: isLoading } = useSendNotification();

    const handleSendAlertConfirmClick = (value: FormValues) => {
        sendNotification([activityId, value], {
            onError: () => {
                showToast({
                    header: t('group:createGroup:alertError'),
                    text: '',
                    type: 'error',
                });
            },
        });
    };

    const hasError = !form.formState.isValid;

    return (
        <Modal
            onDismiss={onDismiss}
            header={isSuccess ? '' : t('activityDetail:sentAlertModal:send_alert')}
            content={
                isSuccess ? (
                    <GenericSuccessScreen message={t('activityDetail:sentAlertModal:success')} />
                ) : (
                    <FormProvider {...form}>
                        <FormInput label={t('activityDetail:sentAlertModal:title')} name="title" autoFocus />
                        <FormInput label={t('activityDetail:sentAlertModal:message')} multiline name="content" />
                    </FormProvider>
                )
            }
            buttons={
                isSuccess
                    ? [
                          {
                              text: t('activityDetail:sentAlertModal:continue').toUpperCase(),
                              onPress: onDismiss,
                          },
                      ]
                    : [
                          {
                              isLoading: isLoading,
                              isDisabled: hasError,
                              text: t('activityDetail:sentAlertModal:send').toUpperCase(),
                              onPress: form.handleSubmit(handleSendAlertConfirmClick),
                          },
                      ]
            }
        />
    );
};

export default SendActivityAlertModal;
