import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useLeaveChat } from '../../../_api/useChats';
import { useAppNavigation } from '../../../_navigator';
import { Modal } from '../../../Components';

interface LeaveChatModal {
    onClose(): void;
    chatId: number;
}

const LeaveChatModal = ({ onClose, chatId }: LeaveChatModal): ReactElement => {
    const { t } = useTranslation();
    const { mutate: leaveChat, isPending: isLeavingChat } = useLeaveChat();
    const { navigate } = useAppNavigation();

    const handleLeave = () => {
        onClose();
        navigate('ChatsAll', { showBackHeader: 'false' });
        leaveChat(chatId);
    };

    return (
        <Modal
            onDismiss={onClose}
            header={t('chatInfo:leaveModal:header')}
            content={<View />}
            buttons={[
                { text: t('chatInfo:leaveModal:back'), onPress: onClose },
                {
                    text: t('chatInfo:leaveModal:approve'),
                    onPress: handleLeave,
                    type: 'danger',
                    isLoading: isLeavingChat,
                },
            ]}
        />
    );
};

export default LeaveChatModal;
