import { useCallback } from 'react';
import { useMarkChatRead } from '../../_api/useChats';
import { cancelNotification, getDisplayedNotifications } from '../../_dependencies/notifee';

const useChatRead = (chatId: number): (() => void) => {
    const { mutate: markRead } = useMarkChatRead();

    return useCallback(() => {
        markRead(chatId);
        getDisplayedNotifications().then((notifications) =>
            notifications
                .filter((notification) => notification?.notification?.data?.chat_room_id === `${chatId}`)
                .forEach((notification) => cancelNotification(notification.id ?? '')),
        );
    }, [chatId, markRead]);
};

export default useChatRead;
