import React, { ReactElement } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import HeimeText from './HeimeText';
import { useThemeStyle, Theme } from '../_utils';
import { smallestMargin, subtitleFontSize } from '../_utils/sizes';

interface InfoMessageProps {
    message: string;
    containerStyle: ViewStyle;
}

const InfoMessage = ({ message, containerStyle }: InfoMessageProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View style={[themedStyle.emptyContainer, containerStyle]}>
            <HeimeText style={themedStyle.emptyText}>{message}</HeimeText>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        emptyContainer: {
            paddingTop: smallestMargin,
            paddingBottom: smallestMargin,
            paddingLeft: smallestMargin,
            paddingRight: smallestMargin,
            backgroundColor: theme.chatMyMessageBubble,
            borderRadius: 15,
        },
        emptyText: {
            fontSize: subtitleFontSize,
            color: theme.main,
        },
    });

export default InfoMessage;
