import React, { useContext } from 'react';
import { StyleSheet, View, Pressable } from 'react-native';
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { Theme, WW, useThemeStyle } from '_utils';
import { smallestFontSize, smallestMargin } from '_utils/sizes';
import { ThemeContext } from '_utils/themeContext';
import HeimeText from './HeimeText';
import InputLabel from './InputLabel';

const Toggle = ({
    description,
    label,
    value,
    onChange,
}: {
    label: string;
    description?: string;
    value: boolean;
    onChange: (value: boolean) => void;
}) => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);

    const thumbStyle = useAnimatedStyle(() => {
        return {
            transform: [
                { translateX: withTiming(value ? containerWidth - padding * 2 - circleWidth : 0, { duration: 250 }) },
            ],
        };
    });

    const containerStyle = useAnimatedStyle(() => {
        return {
            backgroundColor: withTiming(value ? theme.main : theme.lightGrey, { duration: 250 }),
        };
    });

    const handlePress = () => {
        onChange(!value);
    };

    return (
        <Pressable onPress={handlePress}>
            <InputLabel>{label}</InputLabel>
            <View style={themedStyle.content}>
                {description && <HeimeText style={themedStyle.description}>{description}</HeimeText>}
                <Animated.View style={[themedStyle.container, containerStyle]}>
                    <Animated.View style={[themedStyle.circle, thumbStyle]} />
                </Animated.View>
            </View>
        </Pressable>
    );
};

const containerWidth = WW * 0.15;
const circleWidth = containerWidth * 0.4;
const padding = circleWidth * 0.2;

const styles = (theme: Theme) =>
    StyleSheet.create({
        content: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: smallestMargin,
        },
        description: {
            fontSize: smallestFontSize,
            color: theme.mediumGrey,
            flex: 1,
        },
        container: {
            width: containerWidth,
            height: circleWidth + padding * 2,
            borderRadius: circleWidth,
            padding,
            justifyContent: 'center',
        },
        circle: {
            width: circleWidth,
            height: circleWidth,
            borderRadius: circleWidth / 2,
            backgroundColor: theme.white,
        },
    });

export default Toggle;
