import { useCallback } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { Cooperative, CooperativeSchema } from 'types/Cooperative';
import { safeParse } from './utility';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import { Folder } from '../types/Heime';
import { createLaravelPagingResponseSchema } from '../types/Utility';

interface Documents {
    documents: Folder;
}

const schema = createLaravelPagingResponseSchema(z.array(CooperativeSchema));
const getCoops = async (getAuthHeader: () => Promise<string>) => {
    const authHeader = await getAuthHeader();
    const coops = await axios.get('cooperatives', {
        headers: { authorization: authHeader },
    });
    return safeParse(coops.data, schema).data;
};

const coopKey = ['Coops'];

const usePrefetchCoop = (): (() => void) => {
    const queryClient = useQueryClient();
    const getAUthHeader = useGetAccessTokenHeader();

    return useCallback(() => {
        queryClient.prefetchQuery({ queryKey: coopKey, queryFn: () => getCoops(getAUthHeader) });
    }, [getAUthHeader, queryClient]);
};

const useCoops = (): UseQueryResult<Cooperative[], string | Error> => {
    const getHeader = useGetAccessTokenHeader();

    return useQuery({
        queryKey: coopKey,
        queryFn: () => getCoops(getHeader),
        staleTime: 1000 * 60 * 60 * 24 * 7,
        gcTime: Infinity,
    });
};

const useGetDocuments = (): UseQueryResult<Folder, string | Error> => {
    const isFocused = useIsFocused();
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();

    return useQuery({
        queryKey: ['documents', selectedCoopId],

        queryFn: async () => {
            const result = await axios.get<Documents>(`cooperatives/${selectedCoopId}/docs`, {
                headers: { authorization: await getAuthHeader() },
            });
            if (!result.data) {
                throw new Error('Result returned with no data');
            }
            return (
                result.data.documents ?? {
                    name: '',
                    last_modified: 0,
                    files: [],
                    sub_folders: [],
                }
            );
        },

        gcTime: Infinity,
        staleTime: 1000 * 60 * 5,
        enabled: isFocused,
    });
};

export { useCoops, usePrefetchCoop, useGetDocuments };
