import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';

export interface InfoPage {
    id: number;
    title: string;
    content: string;
}

const useGetTermOfSale = (): UseQueryResult<InfoPage, string | Error> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    return useQuery({
        queryKey: ['termOfSales', selectedCoopId],

        queryFn: async () => {
            const result = await axios.get<InfoPage>('info/36', {
                headers: { authorization: await getAuthHeader() },
            });
            if (!result.data) {
                throw new Error('Result returned with no data');
            }
            return result.data;
        },

        gcTime: Infinity,
        staleTime: 1000 * 60 * 60 * 24 * 7,
    });
};

export { useGetTermOfSale };
