import { useOwnProfile } from '../../_api/useProfile';
import { GenericCollectionItem } from '../../types/Utility';

const useUserInUserCollection = (): ((users: GenericCollectionItem[], userId?: number) => boolean) => {
    const { data: profile } = useOwnProfile();
    return (users: GenericCollectionItem[], userId) => {
        const id = userId ?? profile?.id;
        return users.find((user) => user.id === id) !== undefined;
    };
};

export default useUserInUserCollection;
