/* eslint-env browser */
/**
 * Trick for getting file selector cancel using `body.onfocus` event found at
 * http://trishulgoel.com/handle-cancel-click-on-file-input/
 */

import type {
    DocumentPickerResponse,
    pick as ImportPick,
    pickSingle as ImportPickSingle,
} from 'react-native-document-picker';

const input = document.createElement('input');
input.type = 'file';

function removeUri(file: Blob & { uri?: string }): void {
    URL.revokeObjectURL(file.uri ?? '');

    delete file.uri;
}

const getFunc =
    (isMultiple: boolean): typeof pick =>
    (opts) => {
        // Revoke previous objectUrls
        const { files: oldFiles } = input;
        if (oldFiles) {
            Array.prototype.forEach.call(oldFiles, removeUri);
        }

        const promise = new Promise<DocumentPickerResponse[]>(function (resolve) {
            function onChange(event: Event) {
                input.removeEventListener('change', onChange, true);

                const { files: newFiles } = event.target as HTMLInputElement;

                const result = Array.from(newFiles ?? []).map((blob) => {
                    const mapped: File & { uri?: string; fileCopyUri?: string } = blob;
                    mapped.uri = URL.createObjectURL(blob);
                    mapped.fileCopyUri = URL.createObjectURL(blob);

                    return mapped as File & { uri: string; fileCopyUri: string };
                });
                input.value = '';
                resolve(result);
            }

            input.addEventListener('change', onChange, true);
        });

        input.accept = opts?.type
            ? typeof opts.type === 'string'
                ? opts.type
                : ((opts?.type as string[]) ?? []).join(',')
            : '';
        input.multiple = isMultiple;

        input.click();

        return promise;
    };

const pick: typeof ImportPick = getFunc(true);
const pickSingle: typeof ImportPickSingle = async (options) => (await getFunc(false)(options))[0];

const object = { pick, pickSingle };

export default object;
