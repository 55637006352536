import { z } from 'zod';

export const ApiImageSchemaNoId = z.object({
    original: z.string(),
    '64x0': z.string().optional(),
    '128x0': z.string().optional(),
    '512x0': z.string().optional(),
    '1024x0': z.string().optional(),
    '2048x0': z.string().optional(),
});

export const ApiImageSchema = ApiImageSchemaNoId.merge(
    z.object({
        id: z.number(),
    }),
);

export const FileLinkSchema = ApiImageSchema.merge(
    z.object({
        id: z.number(),
        name: z.string(),
        ext: z.union([z.string(), z.boolean()]),
        last_modified: z.number(),
    }),
);

export const FileImageSchema = z.object({
    original: z.string(),
    url64: z.string().nullable(),
    url128: z.string().nullable(),
    url512: z.string().nullable(),
    url1024: z.string().nullable(),
    url2048: z.string().nullable(),
    blurhash: z.string().nullable(),
});

export type FileImage = z.infer<typeof FileImageSchema>;

export type ApiImage = z.infer<typeof ApiImageSchema>;
export type ApiImageObject = z.infer<typeof ApiImageSchema> & { id: number };
export type FileLink = z.infer<typeof FileLinkSchema>;
