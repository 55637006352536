import React, { ReactElement } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, TextStyle } from 'react-native';
import HeimeText from './HeimeText';
import _fonts from '../_fonts';
import { WW } from '../_utils';
import { subtitleFontSize } from '../_utils/sizes';

interface LabelValueProps {
    orientation?: 'vertical';
    style?: StyleProp<ViewStyle>;
    switched?: boolean;
    fixedWidth?: boolean;
    label: string;
    value: string;
    labelStyle?: StyleProp<TextStyle>;
}

const LabelValue = ({
    orientation,
    style,
    switched,
    fixedWidth,
    label,
    value,
    labelStyle,
}: LabelValueProps): ReactElement => {
    let labelStyles = [
        styles.text,
        switched ? styles.labelSwitched : undefined,
        fixedWidth ? styles.fixedWidth : undefined,
        labelStyle,
    ];
    let valueStyles = [styles.text, switched ? undefined : styles.value];
    let mainStyles = [styles.main, style, orientation === 'vertical' ? styles.vertical : undefined];

    return (
        <View style={mainStyles}>
            <HeimeText maxFontSizeMultiplier={2} numberOfLines={fixedWidth ? 1 : undefined} style={labelStyles}>
                {label}
            </HeimeText>
            <HeimeText maxFontSizeMultiplier={2} linkify style={valueStyles} selectable>
                {value}
            </HeimeText>
        </View>
    );
};

const styles = StyleSheet.create({
    main: {
        flexDirection: 'row',
    },
    vertical: {
        flexDirection: 'column',
    },
    text: {
        marginRight: WW * 0.02,
        fontSize: subtitleFontSize,
    },
    labelSwitched: {
        fontFamily: _fonts.primaryFontBold,
    },
    value: {
        fontFamily: _fonts.primaryFont,
    },
    fixedWidth: {
        width: '35%',
    },
});

export default LabelValue;
