import moment from 'moment';
import { Reservation } from '../types/Reservation';

/**
 * Function that returns if reservation is on going
 */
const isReservationOnGoing = ({ start_at, end_at }: Pick<Reservation, 'start_at' | 'end_at'>): boolean => {
    const now = moment();
    return now.isBetween(moment.unix(start_at), moment.unix(end_at));
};

export default isReservationOnGoing;
