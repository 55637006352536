import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { GroupImage, NonUserModal } from '.';
import BoardListItem from './BoardListItem';
import { ContactGroup, Member } from '../../../_api/useContactGroup';
import _fonts from '../../../_fonts';
import { useAppNavigation } from '../../../_navigator';
import { useThemeStyle, WW, getFullName } from '../../../_utils';
import { smallestFontSize } from '../../../_utils/sizes';
import { Theme } from '../../../_utils/themeContext';
import { Icon } from '../../../Components';

interface BoardListProps {
    onExpandCollapse: (id: number) => void;
    groups: (ContactGroup & { expanded?: boolean })[];
    opened: Record<string, boolean>;
    emptyString: string;
}

const BoardList = ({ onExpandCollapse, groups, emptyString, opened }: BoardListProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const [openModal, setOpenModal] = useState<Member | null>(null);

    const handleCloseModal = () => setOpenModal(null);
    const onUserClick = (selectedUser: Member) => {
        if (selectedUser.type === 'external') {
            setOpenModal(selectedUser);
        } else {
            navigate('Profile', { id: selectedUser?.id ?? 0 });
        }
    };

    return (
        <>
            <FlatList
                ListEmptyComponent={
                    <View style={themedStyle.emptyContainer}>
                        <Text style={themedStyle.emptyText}>{emptyString}</Text>
                    </View>
                }
                data={groups}
                ItemSeparatorComponent={() => {
                    return <View style={themedStyle.itemSeparator} />;
                }}
                keyExtractor={(_item, index) => index.toString()}
                renderItem={({ item }) => {
                    const forcedOpen = item.members.length === 1 || item.expanded;
                    const isOpen = opened[`${item.id}`] || forcedOpen;
                    return (
                        <View style={themedStyle.itemContainer}>
                            <TouchableOpacity
                                onPress={() => {
                                    onExpandCollapse(item.id);
                                }}
                                activeOpacity={forcedOpen ? 1 : undefined}
                                style={themedStyle.headerContainer}
                            >
                                <GroupImage users={item.members} />
                                <View style={themedStyle.main}>
                                    <Text maxFontSizeMultiplier={2} style={themedStyle.titleText}>
                                        {item.name}
                                    </Text>
                                    <Text style={themedStyle.subTitleText}>
                                        {t('board:members', { count: item.members.length })}
                                    </Text>
                                </View>
                                {forcedOpen ? null : (
                                    <View style={isOpen ? themedStyle.chevronExapnded : themedStyle.chevronCollapse}>
                                        <Icon name="chevron" color="black" />
                                    </View>
                                )}
                            </TouchableOpacity>

                            {isOpen
                                ? item.members.map((u) => {
                                      const isMember = u.type === 'user';
                                      return (
                                          <BoardListItem
                                              onPress={() => {
                                                  onUserClick(u);
                                              }}
                                              roles={u.roles}
                                              key={u.id}
                                              avatar={u.avatar}
                                              boardMember={isMember}
                                              actionButton={
                                                  <View style={themedStyle.userActionButton}>
                                                      <Icon
                                                          name={isMember ? 'userFilled' : 'infoFilled'}
                                                          color="mainText"
                                                      />
                                                  </View>
                                              }
                                              title={getFullName(u.fname, u.lname)}
                                              tags={u.tags}
                                          />
                                      );
                                  })
                                : null}
                        </View>
                    );
                }}
            />
            {openModal ? <NonUserModal user={openModal} onDismiss={handleCloseModal} /> : undefined}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },
        itemSeparator: {
            borderBottomColor: theme.lightGrey,
            borderBottomWidth: 5,
        },
        itemContainer: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
        },
        headerContainer: {
            alignItems: 'center',
            flexDirection: 'row',
        },
        titleText: {
            color: theme.black,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.045,
        },
        subTitleText: {
            color: theme.secondaryText,
            fontFamily: _fonts.primaryFont,
            fontSize: smallestFontSize,
        },
        chevronExapnded: {
            transform: [{ rotate: '-90deg' }],
        },
        chevronCollapse: {
            transform: [{ rotate: '90deg' }],
        },
        userActionButton: {
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.main,
            width: WW * 0.1,
            height: WW * 0.1,
            borderRadius: (WW * 0.1) / 2,
        },
        emptyContainer: {
            paddingTop: WW * 0.05,
            paddingBottom: WW * 0.05,
            paddingLeft: WW * 0.05,
            paddingRight: WW * 0.05,
            backgroundColor: theme.chatMyMessageBubble,
            borderRadius: 15,
            alignSelf: 'center',
        },
        emptyText: {
            fontFamily: _fonts.primaryFont,
        },
    });

export default BoardList;
