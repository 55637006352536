import React, { ReactElement, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import moment from 'moment';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { z } from 'zod';
import { screenMargin, subtitleFontSize } from '_utils/sizes';
import { AboutMe, ChangeAvatar, DescribeText, EditProfileInfo, ErrorText } from './components';
import PhoneNumberModal from './components/PhoneNumberModal';
import { useOwnProfile, useUpdateProfile } from '../../_api/useProfile';
import { KeyboardAwareScrollView } from '../../_dependencies/keyboardAware';
import { SafeAreaView } from '../../_dependencies/safeArea';
import { useAppNavigation } from '../../_navigator';
import { Theme, useThemeStyle, useConfirmDiscard, showToast, isAppError, isNetworkError } from '../../_utils';
import { HeaderWithNav, PhoneNumberInput, PrimaryButton } from '../../Components';
import { cleanPhoneNumber, OwnUserProfile } from '../../types/User';

const mapToDefault = (profile: OwnUserProfile): FormValues => ({
    birthday: profile?.birthday ? moment.unix(profile?.birthday).toDate() : null,
    firstName: profile.fname,
    lastName: profile.lname,
    email: profile.email ?? '',
    aboutMe: profile.about_me,
    hideBirthday: profile.hide_birthday,
    hideEmail: profile.hide_mail,
    hidePhone: profile.hide_phone,
    avatar: profile.avatar?.original ? undefined : null,
    phone: profile.phone ?? '',
});

const getSchema = (hasEmail: boolean) =>
    z.object({
        firstName: z.string().min(1, 'onboarding:firstName_error'),
        lastName: z.string().min(1, 'onboarding:lastName_error'),
        email: hasEmail
            ? z.string().min(1, 'onboarding:email_error').email('onboarding:invalidEmail')
            : z.union([z.string().email('onboarding:invalidEmail'), z.string().length(0)]).optional(),
        aboutMe: z.string(),
        phone: z.string().max(15, 'onboarding:phone_length').min(1, 'onboarding:phone_error'),
        birthday: z.date().nullable(),
        hideEmail: z.boolean(),
        hidePhone: z.boolean(),
        hideBirthday: z.boolean(),
        avatar: z.string().nullable().optional(),
    });

type FormValues = z.infer<ReturnType<typeof getSchema>>;

const ProfileEdit = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    // Expected to be loaded at this point
    const { data: profile } = useOwnProfile();
    const { mutate: updateProfile, isPending: isUpdatingProfile } = useUpdateProfile();
    const { goBack: internalGoBack } = useAppNavigation();

    const { formState, watch, setValue, handleSubmit, setError, ...methods } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: mapToDefault(profile as OwnUserProfile),
        resolver: zodResolver(getSchema(Boolean(profile?.email))),
    });

    const { content } = useConfirmDiscard(formState.isDirty && !isUpdatingProfile, true, internalGoBack);

    const oldNumber = profile?.phone;

    const [phonelModalOpen, setPhoneModalOpen] = useState(false);
    const handleClosePhoneModal = () => setPhoneModalOpen(false);

    const [phone] = watch(['phone']);

    const handleContinue = handleSubmit((formValue: FormValues) => {
        setPhoneModalOpen(false);
        updateProfile(
            {
                fname: formValue.firstName,
                lname: formValue.lastName,
                email: formValue.email,
                about_me: formValue.aboutMe,
                phone: cleanPhoneNumber(formValue.phone),
                hide_mail: formValue.hideEmail,
                hide_phone: formValue.hidePhone,
                hide_birthday: formValue.hideBirthday,
                avatar: formValue.avatar,
                birthday: formValue.birthday ? moment(formValue.birthday).unix() : undefined,
            },
            {
                onSuccess: () => {
                    internalGoBack();
                },
                onError: (e) => {
                    if (isAppError<FormValues>(e)) {
                        if (isNetworkError(e)) {
                            return;
                        }
                        if (e.response?.data.errors?.email?.[0]) {
                            setError('email', {
                                type: 'manual',
                                message: e.response?.data.errors?.email?.[0],
                            });
                            return;
                        }
                        if (e.response?.data.errors?.phone?.[0]) {
                            setError('phone', {
                                type: 'manual',
                                message: e.response?.data.errors?.phone?.[0],
                            });
                            return;
                        }
                    }

                    showToast({
                        type: 'error',
                        header: t('global:error_saving_header'),
                        text: t('global:error_saving_description'),
                        position: 'bottom',
                    });
                },
            },
        );
    });

    const handleSave = () => {
        if (oldNumber !== phone) {
            setPhoneModalOpen(true);
        } else {
            handleContinue();
        }
    };

    const errorMessage = Object.values(formState.errors).reduce((curr, val) => (curr ? curr : (val.message ?? '')), '');

    return (
        <SafeAreaView style={themedStyle.safeAreaView} edges={['bottom', 'left', 'right']}>
            <HeaderWithNav title={t('profileEdit:title')} />
            <KeyboardAwareScrollView style={themedStyle.body}>
                <FormProvider
                    setValue={setValue}
                    watch={watch}
                    formState={formState}
                    handleSubmit={handleSubmit}
                    setError={setError}
                    {...methods}
                >
                    <ChangeAvatar initialAvatar={profile?.avatar ?? null} />
                    <EditProfileInfo
                        center={
                            <Controller
                                name="phone"
                                render={({ field }) => (
                                    <PhoneNumberInput
                                        label={t('profileEdit:phone')}
                                        placeholder={t('profileEdit:phone')}
                                        {...field}
                                    />
                                )}
                            />
                        }
                        spaceContainer={false}
                    />
                    <AboutMe />
                </FormProvider>
            </KeyboardAwareScrollView>
            <View>
                {errorMessage ? <ErrorText>{t(errorMessage as 'global:noLang')}</ErrorText> : undefined}
                <PrimaryButton
                    status={isUpdatingProfile ? 'loading' : errorMessage ? 'disabled' : null}
                    bottomAction="modal"
                    onPress={handleSave}
                    text={t('profileEdit:submit').toUpperCase()}
                />
            </View>
            {phonelModalOpen && (
                <PhoneNumberModal
                    onDismiss={handleClosePhoneModal}
                    onConfirm={handleContinue}
                    header={t('profileEdit:updateMobileModal:header')}
                    content={<DescribeText>{t('profileEdit:updateMobileModal:description')}</DescribeText>}
                />
            )}
            {content}
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        safeAreaView: {
            display: 'flex',
            height: '100%',
            backgroundColor: theme.mainBackground,
        },
        body: {
            paddingRight: 16,
            paddingLeft: 16,
            flex: 1,
        },
        inputSpacer: { marginBottom: screenMargin },
        inputPrefix: {
            fontSize: subtitleFontSize,
            color: theme.black,
        },
    });

export default ProfileEdit;
