import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DeviceTokenState = {
    device_id: string | null;
    device_token: string | null;
    locale: string | null;
};

const initialState: DeviceTokenState = { device_id: null, device_token: null, locale: null };

const deviceTokenSlice = createSlice({
    name: 'deviceToken',
    initialState,
    reducers: {
        clearDevice: (state) => {
            state.device_id = null;
            state.device_token = null;
        },
        setDevice: (state, action: PayloadAction<{ device_id: string; device_token: string; locale: string }>) => {
            state.device_id = action.payload.device_id;
            state.device_token = action.payload.device_token;
            state.locale = action.payload.locale;
        },
    },
});

export const { clearDevice, setDevice } = deviceTokenSlice.actions;

export default deviceTokenSlice.reducer;
