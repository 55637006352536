import React, { PropsWithChildren, ReactElement } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

interface MainActionContainerProps {
    style?: StyleProp<ViewStyle>;
}

const MainActionContainer = ({ children, style }: PropsWithChildren<MainActionContainerProps>): ReactElement => {
    return <View style={[styles.main, style]}>{children}</View>;
};

const styles = StyleSheet.create({
    main: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
    },
});

export default MainActionContainer;
