import { useCallback } from 'react';
import { useAppNavigation } from '../../_navigator';

const useNavigateToProfile = (): ((profile: number) => void) => {
    const { navigate } = useAppNavigation();
    return useCallback(
        (userId: number) => {
            navigate('Profile', { id: userId });
        },
        [navigate],
    );
};
export default useNavigateToProfile;
