import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { smallestMargin } from '_utils/sizes';
import { HeimeText, SecondaryButton } from 'Components';

const NotSupported = ({ onSkip, actionText }: { onSkip(): void; actionText: string }) => {
    const { t } = useTranslation();
    return (
        <View style={{ gap: smallestMargin, flex: 1 }}>
            <HeimeText variant="title">{t('procedure:not_supported')}</HeimeText>
            <HeimeText variant="subtitle">{`${actionText}, ${t('procedure:not_supported_use_mobile')}`}</HeimeText>
            <SecondaryButton text={t('procedure:skip')} onPress={onSkip} />
        </View>
    );
};

export default NotSupported;
