import React, { ComponentProps, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import Animated, { useSharedValue, withSequence, withTiming } from 'react-native-reanimated';
import { useThemeStyle, WH } from '_utils';
import { screenMargin, smallestMargin, titleFontSize } from '_utils/sizes';
import { Theme } from '_utils/themeContext';
import { Icon } from 'Components';

interface AmountSelectorProps {
    amount: number;
    onSetAmount(amount: number): void;
    maxAmount: number;
    disableIncrease: boolean;
    onDisabledIncreasePress?: () => void;
}
const AmountSelector = ({
    amount,
    onSetAmount,
    maxAmount,
    disableIncrease,
    onDisabledIncreasePress,
}: AmountSelectorProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const onDecrement = () => onSetAmount(amount - 1);
    const onIncrement = () => onSetAmount(amount + 1);

    return (
        <View style={themedStyle.container}>
            <Text style={themedStyle.label}>{t('serviceExpanded:selectAmount')}</Text>
            <View style={themedStyle.selectorContainer}>
                <IconButton onPress={onDecrement} icon="minus" disabled={amount === 1} />
                <NumberDisplay amount={amount} maxAmount={maxAmount} onSetAmount={onSetAmount} />
                <IconButton
                    onPress={onIncrement}
                    onDisabledPress={onDisabledIncreasePress}
                    icon="plus"
                    disabled={disableIncrease}
                />
            </View>
        </View>
    );
};

interface IconButtonProps {
    onPress(): void;
    onDisabledPress?(): void;
    icon: ComponentProps<typeof Icon>['name'];
    disabled: boolean;
}

const IconButton = ({ onPress, icon, disabled, onDisabledPress }: IconButtonProps) => {
    const themedStyle = useThemeStyle(styles);
    return (
        <TouchableOpacity
            key={disabled ? 1 : 2}
            onPress={disabled ? onDisabledPress : onPress}
            style={[themedStyle.iconButton, disabled ? themedStyle.disabledButton : undefined]}
        >
            <Icon name={icon} color="black" style={themedStyle.icon} />
        </TouchableOpacity>
    );
};

const NumberDisplay = ({
    amount,
    maxAmount,
    onSetAmount,
}: {
    amount: number;
    maxAmount: number;
    onSetAmount(num: number): void;
}) => {
    const themedStyle = useThemeStyle(styles);
    const [textValue, setTextValue] = useState(amount + '');
    const [isEditing, setIsEditing] = useState(false);

    const numericTextvalue = parseInt(textValue, 10);
    const isValid = numericTextvalue >= 1 && numericTextvalue <= maxAmount;

    const textStyles = [themedStyle.amountText, isValid ? undefined : themedStyle.disabledText];

    const animatedValue = useSharedValue(1); // 1 is the default scale

    useEffect(() => {
        animatedValue.value = withSequence(withTiming(1.2), withTiming(1));
        setTextValue(amount + '');
    }, [amount, animatedValue]);

    if (isEditing) {
        const handleChangeAmount = (val: string) => {
            setTextValue(val);
            const numericValue = parseInt(val, 10);
            if (numericValue >= 1 && numericValue <= maxAmount) {
                onSetAmount(numericValue);
            }
        };

        return (
            <TextInput
                autoFocus
                keyboardType="number-pad"
                value={textValue}
                onChangeText={handleChangeAmount}
                style={textStyles}
            />
        );
    }
    return (
        <Animated.Text
            onPress={() => setIsEditing(true)}
            style={[
                themedStyle.amountText,
                isValid ? undefined : themedStyle.disabledText,
                { transform: [{ scale: animatedValue }] },
            ]}
        >
            {amount}
        </Animated.Text>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: { marginTop: screenMargin, marginBottom: screenMargin },
        label: { fontSize: titleFontSize, fontWeight: 'bold' },
        selectorContainer: { display: 'flex', marginTop: smallestMargin, flexDirection: 'row', alignItems: 'center' },
        iconButton: {
            borderRadius: WH / 2,
            borderWidth: 1,
            borderColor: theme.secondaryLight,
            paddingTop: smallestMargin,
            paddingBottom: smallestMargin,
            paddingLeft: smallestMargin,
            paddingRight: smallestMargin,
        },
        icon: { transform: [{ scale: 1.5 }] },
        amountText: {
            fontSize: titleFontSize,
            fontWeight: 'bold',
            marginLeft: screenMargin,
            marginRight: screenMargin,
            minWidth: titleFontSize * 1.5,
            textAlign: 'center',
        },
        disabledButton: {
            opacity: 0.3,
        },
        disabledText: {
            backgroundColor: theme.lightRed,
        },
    });

export default AmountSelector;
