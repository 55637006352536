import React, { ReactElement, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Images from '_images';
import { WH, WW, isReservationOnGoing } from '_utils';
import { smallestFontSize, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { screenMargin } from '_utils/sizes.web';
import { ThemeContext } from '_utils/themeContext';
import { CacheImage, SelectorChip, Icon, PrimaryButton, HeimeText, PinDisplay } from 'Components';
import { OwnReservation } from 'types/Reservation';
import ReservationChips from './ReservationChips';

interface ReservationListItemProps {
    data: OwnReservation;
    onPress(openLock: 'default' | 'openLock' | 'checkIn'): void;
}

const ReservationListItem = ({ data, onPress }: ReservationListItemProps): ReactElement => {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext);
    const image = data.booked?.picture ?? Images.reservationSection;

    const handleOpacityPress = () => onPress('default');
    const handleOpenLockPress = useCallback(() => onPress('openLock'), [onPress]);
    const handleCheckInPress = useCallback(() => onPress('checkIn'), [onPress]);

    const pin = (data.booked.locks?.find((lock) => 'pin' in lock && lock.pin) as null | { pin: string })?.pin ?? '';

    const optionalContent = useMemo(() => {
        if (data.check_in && !data.has_checked_in && isReservationOnGoing(data)) {
            return (
                <PrimaryButton text={t('reservations:checkIn')} onPress={handleCheckInPress} style={style.lockButton} />
            );
        } else if (pin) {
            return (
                <View style={style.lockButton}>
                    <PinDisplay pin={pin} />
                </View>
            );
        } else if (isReservationOnGoing(data) && (data.booked.locks?.length ?? 0) > 0 && !data.has_checked_out) {
            return (
                <PrimaryButton
                    style={style.lockButton}
                    text={t('reservations:openLock')}
                    onPress={handleOpenLockPress}
                />
            );
        }
        return null;
    }, [data, handleCheckInPress, handleOpenLockPress, pin, t]);

    const nLocks = data?.booked?.locks?.length ?? 0;
    return (
        <TouchableOpacity style={[style.main, { borderColor: theme.lightGreen }]} onPress={handleOpacityPress}>
            <View style={style.absolute_tag_container}>
                {data.booked.capacity > 1 ? (
                    <SelectorChip
                        title={t('reservations:quantity', { quantity: data.quantity })}
                        variant="GreyPrimary"
                    />
                ) : null}
                {isReservationOnGoing(data) ? (
                    <SelectorChip
                        content={<Icon style={style.icon} name="clockSpinning" color="main" />}
                        title={t('reservations:onGoing')}
                        variant="lightPrimary"
                    />
                ) : null}
            </View>
            <CacheImage resizeMode={'cover'} source={image} style={style.image} />
            <Text style={style.title}>{data.booked.name}</Text>
            <ReservationChips start_at={data.start_at} end_at={data.end_at}>
                {nLocks > 0 ? (
                    <SelectorChip
                        content={
                            <>
                                <Icon name="lock" color="main" />
                                <Text
                                    style={[
                                        style.tagText,
                                        {
                                            color: theme.main,
                                        },
                                    ]}
                                >
                                    {nLocks === 1
                                        ? t('reservations:locks:singular')
                                        : `${nLocks} ${t('reservations:locks:plural')}`}
                                </Text>
                            </>
                        }
                        containerStyle={[style.chipStyle]}
                        variant="GreyPrimary"
                    />
                ) : null}
            </ReservationChips>
            {data.reason ? (
                <HeimeText style={[style.reasonText, { color: theme.darkGray }]}>{data.reason}</HeimeText>
            ) : null}
            {optionalContent}
        </TouchableOpacity>
    );
};

const style = StyleSheet.create({
    main: {
        paddingLeft: WW * 0.05,
        paddingRight: WW * 0.05,
        paddingTop: WW * 0.02,
        paddingBottom: WW * 0.05,
        alignItems: 'stretch',
        marginBottom: WW * 0.02,
        borderWidth: 1,
        position: 'relative',
    },
    image: {
        width: '100%',
        height: WH * 0.25,
        marginBottom: WW * 0.02,
    },
    chipStyle: {
        marginTop: WW * 0.02,
    },
    lockButton: {
        marginTop: WW * 0.02,
    },
    title: {
        fontWeight: 'bold',
        fontSize: WW * 0.06,
    },
    absolute_tag_container: {
        position: 'absolute',
        zIndex: 1,
        left: screenMargin,
        right: screenMargin,
        top: WW * 0.04,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    tagText: {
        marginLeft: WW * 0.02,
        fontSize: subtitleFontSize,
    },
    icon: {
        marginRight: WW * 0.02,
    },
    reasonText: { fontSize: smallestFontSize, marginTop: smallestMargin },
});

export default ReservationListItem;
