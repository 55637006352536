import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageType } from 'types/message';
import SharingCategorySelected from './SharingAll/SharingCategorySelected';

const HelpfulNeighbor = (): ReactElement => {
    const { t } = useTranslation();
    return (
        <SharingCategorySelected
            types={[MessageType.Helpers]}
            header={t('sharingSelected:messageType:Helpers')}
            emptyText={t('sharingAll:noMessageType:Helpers')}
        />
    );
};

export default HelpfulNeighbor;
