import React, { ReactElement, useMemo } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useSelectedCoopItem } from '_utils/hooks';
import { captureException } from '_utils/Sentry';
import MessageExpiredItem from './MessageExpiredItem';
import NotificationItem from './NotificationItem';
import { useGetNotifications, useMarkNotificationSeen } from '../../_api/useNotifications';
import { useFindUser } from '../../_api/useUsers';
import { cancelAllNotifications, getDisplayedNotifications, cancelNotification } from '../../_dependencies/notifee';
import {
    getReportStatusTranslationString,
    Theme,
    useThemeStyle,
    WH,
    WW,
    useHandleNotificationClick,
} from '../../_utils';
import { capitalizeFirstLetter, getUsernameFromProfile } from '../../_utils/misc';
import { Loader, SmallHeader, QueryView, EmptyList } from '../../Components';
import { ArrayElement } from '../../types/Utility';

const Notifications = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';
    const {
        data: notifications,
        isLoading: isLoadingNotifications,
        fetchNextPage,
        isFetchingNextPage,
        refetch,
        isFetching,
        isError,
        error,
    } = useGetNotifications();
    const { mutate: markNotificationsSeen } = useMarkNotificationSeen();

    const pressHandler = useHandleNotificationClick();

    const getUser = useFindUser();

    if (isError) {
        throw error;
    }

    const flatNotifications = useMemo(
        () =>
            (notifications?.pages ?? []).reduce((curr, page) => {
                if (page) {
                    curr.push(...page);
                }
                return curr;
            }, []),
        [notifications?.pages],
    );

    useFocusEffect(
        React.useCallback(() => {
            // We only want blur effect
            return () => {
                const notSeenNotifications = flatNotifications.filter(({ seen, id }) => !seen && id);
                if (notSeenNotifications.length > 0) {
                    markNotificationsSeen(notSeenNotifications.map((item) => item.id));
                }

                const notificationIds = flatNotifications.map((item) => item.id + '');
                // Find displayed notifications that are in notifications list and remove them
                getDisplayedNotifications().then((displayedNotifications) => {
                    displayedNotifications
                        .filter((notification) =>
                            notificationIds.includes((notification.notification?.data?.id ?? '0') + ''),
                        )
                        .forEach((notification) => {
                            if (notification.id) {
                                cancelNotification(notification.id);
                            }
                        });
                });
                cancelAllNotifications();
            };
        }, [flatNotifications, markNotificationsSeen]),
    );

    const renderNotification = ({ item }: { item: ArrayElement<typeof flatNotifications> }) => {
        switch (item.type) {
            case 'NewChatMessage': {
                const handlePress = () => {
                    pressHandler.handleChatPress(item.chat_room_id);
                };

                const arr = item.text?.split('\n');

                return (
                    <NotificationItem
                        image={item.image}
                        circularImage
                        title={t('notifications:newChatMessage:title', { name: item.sender })}
                        content={item.text ? `"${arr[0]}"${arr[1] ? '...' : ''}` : undefined}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:newChatMessage:buttonText'),
                        }}
                        createdAt={item.created_at}
                        isSeen={item.seen}
                    />
                );
            }
            case 'ActivityInvite': {
                const handlePress = () => {
                    pressHandler.handleActivityPress(item.activity_id);
                };
                return (
                    <NotificationItem
                        image={item.image}
                        title={item.title}
                        content={t('notifications:activityInvite:content')}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:activityInvite:buttonText'),
                        }}
                        createdAt={item.created_at}
                        isSeen={item.seen}
                    />
                );
            }
            case '24HoursBeforeActivity': {
                const handlePress = () => {
                    pressHandler.handleActivityPress(item.activity_id);
                };
                return (
                    <NotificationItem
                        image={item.image}
                        title={t('notifications:tfHoursBeforeActivity:title', { name: item.title })}
                        content={t('notifications:tfHoursBeforeActivity:content')}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:tfHoursBeforeActivity:buttonText'),
                        }}
                        createdAt={item.created_at}
                        isSeen={item.seen}
                    />
                );
            }
            case '24HoursBeforeRegistrationDeadline': {
                const handlePress = () => {
                    pressHandler.handleActivityPress(item.activity_id);
                };
                return (
                    <NotificationItem
                        image={item.image}
                        title={t('notifications:tfHoursBeforeSignUpTerm:title', { name: item.title })}
                        content={t('notifications:tfHoursBeforeSignUpTerm:content')}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:tfHoursBeforeSignUpTerm:buttonText'),
                        }}
                        createdAt={item.created_at}
                        isSeen={item.seen}
                    />
                );
            }
            case 'ActivityInvitedUsers': {
                const handlePress = () => {
                    pressHandler.handleActivityPress(item.activity_id);
                };

                return (
                    <NotificationItem
                        image={item.image}
                        title={item.title}
                        createdAt={item.created_at}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:activityInvitedUsers:buttonText'),
                        }}
                        isSeen={item.seen}
                    />
                );
            }
            case 'ActivityCustomNotify':
            case 'custom_notify': {
                const handlePress = () => {
                    pressHandler.handleActivityPress(item.activity_id);
                };
                return (
                    <NotificationItem
                        image={item.image}
                        title={t('notifications:activityCustomNotify:title', { name: item.title })}
                        createdAt={item.created_at}
                        content={t('notifications:activityCustomNotify:content', { name: item.content })}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:activityCustomNotify:buttonText'),
                        }}
                        noLimit
                        isSeen={item.seen}
                    />
                );
            }
            case 'CancelledActivity': {
                return (
                    <NotificationItem
                        title={t('notifications:cancelledActivity:title', { name: item.title })}
                        createdAt={item.created_at}
                        noLimit
                        isSeen={item.seen}
                    />
                );
            }
            case 'UserGroupCreated': {
                const handlePress = () => {
                    pressHandler.handleGroupPress(item.user_group_id);
                };

                return (
                    <NotificationItem
                        image={item.image}
                        circularImage={true}
                        title={item.title}
                        content={item.content}
                        button={
                            item.user_group_id
                                ? {
                                      onPress: handlePress,
                                      text: t('notifications:userGroupCreated:buttonText'),
                                  }
                                : undefined
                        }
                        createdAt={item.created_at ?? moment().unix()}
                        isSeen={item.seen}
                    />
                );
            }
            case 'SupportCreated': {
                const handlePress = () => pressHandler.handleReportedMessagePress(item.support_id);
                return (
                    <NotificationItem
                        title={t('notifications:supportCreated:title', {
                            boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                            name: getUsernameFromProfile(getUser(item.creator)),
                        })}
                        content={item.title}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:supportCreated:buttonText'),
                        }}
                        createdAt={item.created_at}
                        isSeen={item.seen}
                    />
                );
            }

            case 'SupportStatus': {
                const handlePress = () => pressHandler.handleReportedMessagePress(item.support_id);
                return (
                    <NotificationItem
                        title={t('notifications:supportStatus:title', {
                            boardNounCapitalized: capitalizeFirstLetter(
                                t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                            ),
                            name: t(getReportStatusTranslationString(item.status)),
                            title: item.title,
                        })}
                        createdAt={item.created_at}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:supportStatus:buttonText'),
                        }}
                        isSeen={item.seen}
                    />
                );
            }
            case 'SupportNoteCreated': {
                const handlePress = () => pressHandler.handleReportedMessagePress(item.support_id);
                return (
                    <NotificationItem
                        title={item.title}
                        content={`"${item.content}"`}
                        createdAt={item.created_at}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:supportStatus:buttonText'),
                        }}
                        isSeen={item.seen}
                    />
                );
            }
            case 'LockLowBattery': {
                return (
                    <NotificationItem
                        title={t('notifications:lowBattery:title', {
                            lockName: item.lock_name,
                            percentage: item.lock_current_percentage,
                        })}
                        content={`${t('notifications:lowBattery:content', {
                            productsName: localizeArray(
                                item.products.map(({ name }) => `"${name}"`),
                                t,
                            ),
                        })} ${t(`notifications:lowBattery:content${item.lock_type}`)} ${t(
                            'notifications:lowBattery:contentPost',
                        )}`}
                        noLimit
                        createdAt={item.created_at}
                        isSeen={item.seen}
                        image={item.products.find((arg) => arg.image)?.image ?? undefined}
                    />
                );
            }
            case 'NewMessageComment': {
                const handlePress = () => pressHandler.handleMessageCommentPress(item.messageId);
                return (
                    <NotificationItem
                        title={t('notifications:newMessageComment:title', { title: item.message_title })}
                        content={`${getUsernameFromProfile(getUser(item.comment_creator))}: ${
                            item.comment_content
                                ? `"${item.comment_content}"`
                                : item.isImage
                                  ? t('notifications:newMessageComment:contentImage')
                                  : item.isFile
                                    ? t('notifications:newMessageComment:contentFile')
                                    : ''
                        }`}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:newMessageComment:buttonText'),
                        }}
                        createdAt={item.created_at}
                        isSeen={item.seen}
                    />
                );
            }
            case 'NewSurvey': {
                const handlePress = () => pressHandler.handleSurveyPress(item.survey_id);
                return (
                    <NotificationItem
                        title={t('notifications:newSurvey:title', { title: item.survey_title })}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:newSurvey:buttonText'),
                        }}
                        createdAt={item.created_at}
                        isSeen={item.seen}
                    />
                );
            }
            case 'ReminderSurvey': {
                const handlePress = () => pressHandler.handleSurveyPress(item.survey_id);
                return (
                    <NotificationItem
                        title={t('notifications:reminderSurvey:title', { title: item.survey_title })}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:reminderSurvey:buttonText'),
                        }}
                        createdAt={item.created_at}
                        isSeen={item.seen}
                    />
                );
            }
            case 'PushUnhandledCooperativeUserRegistration': {
                const handlePress = () => pressHandler.handleUserRegistrationPress(item.url);
                return (
                    <NotificationItem
                        title={t('notifications:unhandledCooperativeUserRegistration:title', {
                            count: item.user_registration_count,
                        })}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:unhandledCooperativeUserRegistration:buttonText'),
                        }}
                        createdAt={item.created_at}
                        isSeen={item.seen}
                    />
                );
            }
            case 'ArrangerAdded': {
                const handlePress = () => pressHandler.handleActivityPress(item.activity_id);
                return (
                    <NotificationItem
                        title={t('notifications:arrangerAdded:title', { title: item.title })}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:arrangerAdded:buttonText'),
                        }}
                        createdAt={item.created_at}
                        isSeen={item.seen}
                    />
                );
            }
            case 'ArrangerRemoved': {
                const handlePress = () => pressHandler.handleActivityPress(item.activity_id);
                return (
                    <NotificationItem
                        title={t('notifications:arrangerRemoved:title', { title: item.title })}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:arrangerRemoved:buttonText'),
                        }}
                        createdAt={item.created_at}
                        isSeen={item.seen}
                    />
                );
            }
            case 'ActivityRateUs': {
                const handlePress = () => pressHandler.handleRateHeimePress();
                return (
                    <NotificationItem
                        title={t('notifications:activityRateUs:title')}
                        button={{
                            onPress: handlePress,
                            text: t('notifications:activityRateUs:buttonText'),
                        }}
                        content={t('notifications:activityRateUs:content')}
                        createdAt={item.created_at}
                        isSeen={item.seen}
                    />
                );
            }
            case 'MessageExpiring':
            case 'MessageSetExpired': {
                return <MessageExpiredItem {...item} />;
            }
            case 'BookingNotification': {
                const handlePress = () => pressHandler.handleBookingNotificationPress(item);
                const startDiff = moment.duration(moment().diff(moment(item.booking_start), 'milliseconds'));
                const endDiff = moment.duration(moment().diff(moment(item.booking_end), 'milliseconds'));
                const isRelativeToStart = item.time_to_send_type === 'start';
                const endIsPast = moment(item.booking_end).isBefore(moment());
                const startIsPast = moment(item.booking_start).isBefore(moment());
                const hasProcedureToDo = item.procedure_to_do && !endIsPast;
                const title = (() => {
                    if (hasProcedureToDo) {
                        return isRelativeToStart
                            ? startIsPast
                                ? t('notifications:bookingNotification:start_past_procedure_title', {
                                      name: item.product_name,
                                      timeString: startDiff.humanize(),
                                  })
                                : t('notifications:bookingNotification:start_future_procedure_title', {
                                      name: item.product_name,
                                      timeString: startDiff.humanize(),
                                  })
                            : t('notifications:bookingNotification:end_future_procedure_title', {
                                  name: item.product_name,
                                  timeString: endDiff.humanize(),
                              });
                    }

                    return isRelativeToStart
                        ? startIsPast
                            ? t('notifications:bookingNotification:start_past_title', {
                                  name: item.product_name,
                                  timeString: startDiff.humanize(),
                              })
                            : t('notifications:bookingNotification:start_future_title', {
                                  name: item.product_name,
                                  timeString: startDiff.humanize(),
                              })
                        : endIsPast
                          ? t('notifications:bookingNotification:end_past_title', {
                                name: item.product_name,
                                timeString: endDiff.humanize(),
                            })
                          : t('notifications:bookingNotification:end_future_title', {
                                name: item.product_name,
                                timeString: endDiff.humanize(),
                            });
                })();

                return (
                    <NotificationItem
                        title={title}
                        button={
                            !endIsPast
                                ? hasProcedureToDo
                                    ? {
                                          onPress: startIsPast ? handlePress : () => {},
                                          text:
                                              item.time_to_send_type === 'start'
                                                  ? t('notifications:bookingNotification:buttonText_start_procedure')
                                                  : t('notifications:bookingNotification:buttonText_end_procedure'),
                                          status: startIsPast ? null : 'disabled',
                                      }
                                    : {
                                          onPress: handlePress,
                                          text: t('notifications:bookingNotification:buttonText'),
                                      }
                                : undefined
                        }
                        createdAt={item.created_at}
                        isSeen={item.seen}
                    />
                );
            }
        }

        captureException(new Error(`Unknown notification type: ${item.type}, id: ${item.id}`));
        if ('text' in item && item.text) {
            return <NotificationItem title={item.text} createdAt={item.created_at} isSeen={item.seen} />;
        }
        return null;
    };

    return (
        <>
            <SmallHeader title={t('notifications:title')} />
            {isLoadingNotifications ? (
                <Loader />
            ) : flatNotifications.length > 0 ? (
                <QueryView
                    style={themedStyle.scroll}
                    data={flatNotifications}
                    renderItem={renderNotification}
                    loadMore={fetchNextPage}
                    isLoadingMore={isFetchingNextPage}
                    onRefresh={refetch}
                    isRefreshing={isFetching}
                />
            ) : (
                <View style={themedStyle.empty}>
                    <EmptyList message={t('notifications:no_notifications_found')} />
                </View>
            )}
        </>
    );
};

const localizeArray = function <T extends Function>(array: string[], t: T) {
    if (array.length === 0) {
        return '';
    }
    if (array.length === 1) {
        return array[0];
    }

    const others = array.splice(0, array.length - 1);
    return t('notifications:others', { others: others.join(', '), last: array[0] });
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        scroll: {
            backgroundColor: theme.mainBackground,
            width: WW,
            height: '100%',
        },
        empty: {
            height: '100%',
            display: 'flex',
            backgroundColor: theme.mainBackground,
            paddingBottom: WH * 0.2,
        },
    });

export default Notifications;
