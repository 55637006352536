import React, { ReactElement } from 'react';
import { StyleSheet, TextStyle } from 'react-native';
import { HeimeText } from 'Components';
import { titleFontSize } from '../../_utils/sizes';

interface TitleProps {
    text: string;
    style?: TextStyle;
}

const Title = ({ text, style }: TitleProps): ReactElement => {
    return <HeimeText style={[styles.header, style]}>{text}</HeimeText>;
};

const styles = StyleSheet.create({
    header: {
        marginTop: 24,
        fontSize: titleFontSize,
        fontWeight: 'bold',
        textAlign: 'center',
    },
});

export default Title;
