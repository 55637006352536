import React, { PropsWithChildren, ReactElement, useContext } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { WH, WW } from '_utils';
import { Theme, ThemeContext } from '_utils/themeContext';

interface LockRingProps {
    color: keyof Theme;
    onPress?: (() => void) | null;
}
const LockRing = ({ children, color, onPress }: PropsWithChildren<LockRingProps>): ReactElement => {
    const { theme } = useContext(ThemeContext);

    const content = (
        <View
            style={[
                styles.lockContainer,
                {
                    borderTopColor: theme[color],
                    borderRightColor: theme[color],
                    borderBottomColor: theme[color],
                    borderLeftColor: theme[color],
                },
            ]}
        >
            {children}
        </View>
    );

    if (onPress) {
        return (
            <TouchableOpacity style={styles.container} onPress={onPress}>
                {content}
            </TouchableOpacity>
        );
    }

    return <View style={styles.container}>{content}</View>;
};
const styles = StyleSheet.create({
    container: { alignItems: 'center', justifyContent: 'center' },
    lockContainer: {
        paddingTop: WW * 0.15,
        paddingRight: WW * 0.15,
        paddingBottom: WW * 0.15,
        paddingLeft: WW * 0.15,
        borderTopEndRadius: WH,
        borderTopStartRadius: WH,
        borderBottomEndRadius: WH,
        borderBottomStartRadius: WH,
        borderTopWidth: 2,
        borderRightWidth: 2,
        borderBottomWidth: 2,
        borderLeftWidth: 2,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: WW * 0.02,
    },
});

export default LockRing;
