import React, { PropsWithChildren, ReactElement } from 'react';
import { View, StyleSheet, ViewStyle, StyleProp } from 'react-native';
import { useThemeStyle, WW, Theme } from '../_utils';

interface ContainerProps {
    style?: StyleProp<ViewStyle>;
}

const Container = ({ style, children }: PropsWithChildren<ContainerProps>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return <View style={[themedStyle.container, style]}>{children}</View>;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: theme.shadow,
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
        },
    });

export default Container;
