import { NavigationState, StackActions, useNavigation } from '@react-navigation/core';

const useResetNavigationStack = (): ((toReset?: string[]) => void) => {
    const { dispatch } = useNavigation();

    const handleReset = (toReset?: string[]) =>
        dispatch(((state: NavigationState) => {
            if (state) {
                state.routes
                    .filter((item) => !toReset || toReset.includes(item.name))
                    .forEach((tab) => {
                        const stackKey = (tab.state as NavigationState)?.key;
                        if (stackKey) {
                            dispatch({
                                ...StackActions.popToTop(),
                                target: stackKey,
                            });
                        }
                    });
            }
            return;
        }) as unknown as NavigationState);

    return handleReset;
};

export default useResetNavigationStack;
