import { useCallback } from 'react';
import moment from 'moment';
import { Product } from 'types/Category';
import { UnixTimeCode } from 'types/Utility';
import { ProductDetailProps } from './ProductDetail';
import { useGetProduct } from '../../_api/useCategories';
import { useGetProductReservations } from '../../_api/useReservations';
import { useAppNavigation } from '../../_navigator';

const useProductDetailHook = ({
    route,
}: ProductDetailProps): {
    isLoadingDetails: boolean;
    product: Product | undefined;
    isRefreshing: boolean;
    refresh(): void;
    isError: boolean;
    error: string | Error | null;
    onReserve(initialDate: Date | null, wantsToPay: boolean): void;
    loadReservationsFrom: UnixTimeCode;
} => {
    const { push } = useAppNavigation();
    const {
        data: product,
        isLoading: isLoadingProduct,
        isError: isErrorLoadingProduct,
        error: errorProduct,
        isRefetching: isRefetchingProduct,
        refetch: refetchProduct,
    } = useGetProduct(route.params.productId, route.params.categoryId);

    // Value below is synced with productReserve screen
    const loadReservationsFrom = moment().startOf('day');
    const { refetch: refetchReservations, isRefetching: isRefetchingReservations } = useGetProductReservations(
        route.params.productId,
        loadReservationsFrom,
    );

    const prefetchReservationsFrom = moment().startOf('month');
    useGetProductReservations(route.params.productId, prefetchReservationsFrom);

    const handleRefresh = () => {
        refetchProduct();
        refetchReservations();
    };

    const onReserve = useCallback(
        (initialDate: Date | null, wantsToPay: boolean) => {
            push('ProductReserve', {
                categoryId: route.params.categoryId,
                productId: route.params.productId,
                initialDate: initialDate ? moment(initialDate).unix() : undefined,
                skipPayment: !wantsToPay,
            });
        },
        [push, route.params.categoryId, route.params.productId],
    );

    return {
        isLoadingDetails: isLoadingProduct,
        product,
        refresh: handleRefresh,
        isRefreshing: isRefetchingProduct || isRefetchingReservations,
        onReserve,
        isError: isErrorLoadingProduct,
        error: errorProduct,
        loadReservationsFrom: loadReservationsFrom.unix(),
    };
};

export { useProductDetailHook };
