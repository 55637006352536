import { useCallback, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { PinDisplay, PrimaryButton } from 'Components';
import type { Lock } from 'types/Reservation';
import LockModal from './LockModal';
import RemoteUnlockModal from './LockModal/RemoteUnlockModal';
import TimeSyncGuard from '../common/TimeSyncGuard';

const LockButtons = ({
    locks,
    isInFuture,
    isInPast,
    startTime,
    initiallyUnlockLock,
    secondsUntilReservationEnd,
}: {
    locks: Lock[];
    isInFuture: boolean;
    isInPast: boolean;
    startTime: Date;
    initiallyUnlockLock: boolean;
    secondsUntilReservationEnd: number;
}) => {
    const { t } = useTranslation();
    const initialLockOpenRemote = locks[0] && 'open_remote' in locks[0] && locks[0].open_remote;
    const [unlockingLock, setUnlockingLock] = useState(() =>
        initiallyUnlockLock && !initialLockOpenRemote ? (locks?.[0] ?? null) : null,
    );
    const [remoteUnlocking, setRemoteUnlocking] = useState(() =>
        initiallyUnlockLock && initialLockOpenRemote ? (locks?.[0] ?? null) : null,
    );

    const getUnlockPress = (lock: Lock) => () => {
        setUnlockingLock(lock);
    };
    const handleLockModalClose = useCallback(() => {
        setUnlockingLock(null);
        setRemoteUnlocking(null);
    }, []);
    const getRemoteUnlockPress = (lock: Lock) => () => {
        setRemoteUnlocking(lock);
    };

    if (locks.length === 0) {
        return null;
    }

    if (isInFuture || isInPast) {
        const pin = (locks.find((item) => 'pin' in item && item.pin) as null | { pin: string })?.pin;
        if (pin) {
            return (
                <View style={styles.buttonContainer}>
                    <PinDisplay pin={pin} />
                </View>
            );
        }
        return (
            <View style={styles.buttonContainer}>
                <TimeSyncGuard>
                    <PrimaryButton
                        status={'disabled'}
                        onPress={() => {}}
                        text={
                            isInFuture
                                ? `${t('expandedReservation:canUnlockIn')} ${moment
                                      .duration(moment(startTime).diff(moment().startOf('minute')))
                                      .humanize()}`
                                : t('expandedReservation:expired')
                        }
                    />
                </TimeSyncGuard>
            </View>
        );
    }

    return (
        <View style={styles.buttonContainer}>
            {locks?.map((lock, index) => {
                const multipleLocks = locks.length > 1;
                const unlockText = `${t('expandedReservation:unlock')}${multipleLocks ? ` ${lock.name}` : ''}`;
                const remoteUnlockText = `${t('expandedReservation:remote_unlock')}${
                    multipleLocks ? ` ${lock.name}` : ''
                }`;
                const onPress = getUnlockPress(lock);
                if (lock.type === 'danalock' || lock.type === 'danalockRelay') {
                    return (
                        <DanalockButton
                            key={lock.id}
                            pin={lock.pin}
                            open_remote={lock.open_remote}
                            handleUnlockPress={getUnlockPress(lock)}
                            unlockText={unlockText}
                            remoteUnlockText={remoteUnlockText}
                            handleRemoteUnlockPress={getRemoteUnlockPress(lock)}
                        />
                    );
                }

                return (
                    <TimeSyncGuard key={index}>
                        <PrimaryButton onPress={onPress} text={unlockText} />
                    </TimeSyncGuard>
                );
            })}

            {unlockingLock != null ? (
                <LockModal
                    secondsUntilReservationEnd={secondsUntilReservationEnd}
                    lock={unlockingLock}
                    onClose={handleLockModalClose}
                />
            ) : null}
            {remoteUnlocking != null ? (
                <RemoteUnlockModal lock={remoteUnlocking} onClose={handleLockModalClose} />
            ) : null}
        </View>
    );
};

const DanalockButton = ({
    pin,
    open_remote,
    handleUnlockPress,
    unlockText,
    remoteUnlockText,
    handleRemoteUnlockPress,
}: {
    pin: string | null;
    open_remote: boolean;
    handleUnlockPress: () => void;
    unlockText: string;
    remoteUnlockText: string;
    handleRemoteUnlockPress: () => void;
}) => {
    let otherOptions = [];
    if (pin && open_remote) {
        otherOptions.push('remote');
    }
    if (open_remote || pin) {
        otherOptions.push('ble');
    }

    const unlockButton = (inRow: boolean) => (
        <PrimaryButton
            onPress={handleUnlockPress}
            text={unlockText}
            style={
                inRow
                    ? { minHeight: subtitleFontSize + screenMargin * 2, flexGrow: 1, flexShrink: 1, flexBasis: '50%' }
                    : { flexGrow: 1 }
            }
        />
    );

    if (otherOptions.length === 0) {
        return <TimeSyncGuard>{unlockButton(false)}</TimeSyncGuard>;
    }

    return (
        <View>
            {pin ? <PinDisplay pin={pin} /> : null}
            <View style={styles.otherOptionsContainer}>
                {open_remote ? (
                    <TimeSyncGuard>
                        <PrimaryButton
                            style={styles.optionButton}
                            onPress={handleRemoteUnlockPress}
                            text={remoteUnlockText}
                        />
                        {unlockButton(true)}
                    </TimeSyncGuard>
                ) : (
                    <TimeSyncGuard>{unlockButton(false)}</TimeSyncGuard>
                )}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    moreContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: smallestMargin,
        paddingBottom: smallestMargin,
    },
    otherOptionsContainer: {
        paddingTop: smallestMargin,
        flexDirection: 'row',
        gap: smallestMargin,
    },
    optionButton: {
        minHeight: subtitleFontSize + screenMargin * 2,
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '50%',
    },
    buttonContainer: {
        paddingLeft: screenMargin,
        paddingRight: screenMargin,
        paddingBottom: smallestMargin,
        gap: smallestMargin,
        flexDirection: 'column',
    },
});

export default LockButtons;
