import { ComponentProps, useRef } from 'react';
import { ViewToken } from 'react-native';
import { WW, useCalculateMessageRead } from '_utils';
import { QueryView } from 'Components';
import { Message } from 'types/message';
import useReadMessage from '../useReadMessage';

const useMarkMessageRead = (): Pick<
    ComponentProps<typeof QueryView>,
    'onViewableItemsChanged' | 'viewabilityConfig'
> => {
    const markRead = useReadMessage();
    const isRead = useCalculateMessageRead();

    const onViewableItemsChanged = useRef((info: { viewableItems: Array<ViewToken>; changed: Array<ViewToken> }) => {
        const messageIds = info.viewableItems
            .map(({ item }) => item)
            .filter((msg: Message) => !isRead(msg))
            .map(({ id }) => id);

        messageIds.length && markRead(messageIds, false);
    }).current;

    const viewConfigRef = useRef({ viewAreaCoveragePercentThreshold: WW * 0.1 });

    return { onViewableItemsChanged, viewabilityConfig: viewConfigRef.current };
};

export default useMarkMessageRead;
