import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet } from 'react-native';
import Images from '_images';
import { useAppNavigation } from '_navigator';
import { openURL, Theme, useThemeStyle, WH } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { CardButton, EmptyList, HeimeText, MainActionContainer } from 'Components';
import type { Category, Product } from 'types/Category';

interface ServicesListProps {
    items: (Product | Category)[];
}

const ServicesList = ({ items }: ServicesListProps): ReactElement => {
    const { navigate, push } = useAppNavigation();
    const themeStyles = useThemeStyle(styles);
    const { t } = useTranslation();

    const renderItem = (
        item: Product | Category,
        parentCategoryId: number | undefined,
        index: number,
    ): ReactElement | null => {
        const Image = item?.pictures?.[0] ? item.pictures[0] : Images.tent;
        return (
            <CardButton
                icon={Image}
                label={item.name}
                onPress={() => {
                    if ('external_link' in item && item.external_link) {
                        openURL(item.external_link, t);
                    } else if ('categories' in item) {
                        push('Category', { categoryId: item.id });
                    } else {
                        navigate('ProductDetail', { productId: item.id, categoryId: parentCategoryId });
                    }
                }}
                style={cardStyles.cardStyle}
                key={`${parentCategoryId}-${'categories' in item ? 'category' : 'product'}-${item.id}-${index}`}
            />
        );
    };

    const filteredItems = items.filter(
        (item) => !('categories' in item) || item.external_link || item.categories.length || item.products.length,
    );

    const buckets = filteredItems.reduce(
        (acc, item) => {
            if ('categories' in item && !item.external_link) {
                return [...acc, item];
            } else {
                const last = acc[acc.length - 1];
                if (!last) {
                    return [[item]];
                } else if ('categories' in last) {
                    return [...acc, [item]];
                } else {
                    return [...acc.slice(0, acc.length - 1), [...last, item]];
                }
            }
        },
        [] as (Category | (Product | Category)[])[],
    );

    return (
        <FlatList
            data={buckets}
            renderItem={({ item, index }) => {
                if ('categories' in item) {
                    const children = [...item.categories, ...item.products];
                    if (children.length === 0) {
                        return null;
                    }
                    return (
                        <React.Fragment key={`category-${item.id}-${index}`}>
                            <HeimeText style={themeStyles.catTitle}>{item.name}</HeimeText>
                            <MainActionContainer>
                                {children.map((arg, innerIndex) => renderItem(arg, item.id, innerIndex))}
                            </MainActionContainer>
                        </React.Fragment>
                    );
                }
                return (
                    <MainActionContainer key={`actionContainer-${index}`}>
                        {item.map((arg, innerIndex) => renderItem(arg, undefined, innerIndex))}
                    </MainActionContainer>
                );
            }}
            keyExtractor={(item, index) => ('categories' in item ? `category-${item.id}` : `actionContainer-${index}`)}
            ListEmptyComponent={<EmptyList message={t('services:empty')} />}
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        catTitle: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            marginBottom: smallestMargin,
            fontWeight: 'bold',
            fontSize: subtitleFontSize,
            color: theme.shadowText,
        },
    });

const cardStyles = StyleSheet.create({
    cardStyle: { marginBottom: WH * 0.03 },
});

export default ServicesList;
