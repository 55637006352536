import React, { ReactElement, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { z } from 'zod';
import { useMarkSurveySent, useSurvey } from '_api/useSurveys';
import { useAppNavigation } from '_navigator';
import { Theme, useThemeStyle } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { HeimeText, QueryItemView, PrimaryButton, SecondaryButton } from 'Components';
import { RoutePropNumberNullable, RoutePropsSchema } from 'types/Utility';
import { AnswerOverview } from '../components';
import { useSurveyContext } from '../SurveyContext';
import { SurveyScreenProps } from '../SurveyNavigation';

const routeSchema = RoutePropsSchema(z.object({ apartmentId: RoutePropNumberNullable }));

const SurveyComplete = (props: SurveyScreenProps<'SurveyComplete'>): ReactElement => {
    const {
        route: {
            params: { apartmentId },
        },
    } = routeSchema.parse(props);
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const {
        state: { surveyId },
        openSurveyComplete,
    } = useSurveyContext();
    const { data, refetch, isRefetching } = useSurvey(surveyId);
    const { goBack, navigate } = useAppNavigation();
    const { mutate } = useMarkSurveySent(surveyId);

    if (!data) {
        throw new Error('Unexpected null data');
    }

    useFocusEffect(
        useCallback(() => {
            openSurveyComplete(goBack);
        }, [goBack, openSurveyComplete]),
    );

    const handleContinue = () => {
        mutate({ apartment_id: apartmentId });
        navigate('SurveysAll', undefined);
    };

    const closeModal = () => {
        navigate('SurveysAll', undefined);
    };

    return (
        <View style={themedStyle.outerContainer}>
            <QueryItemView onRefresh={refetch} isRefreshing={isRefetching} style={themedStyle.container}>
                <HeimeText style={themedStyle.explainer}>{t('surveys:noMoreQuestions')}</HeimeText>
                <AnswerOverview
                    questions={data.questions}
                    answers={
                        data.responses.find(
                            (item) =>
                                item.status === 'DRAFT' &&
                                (data.recipients_type === 'USERS' || item.apartment_id === apartmentId),
                        )?.answers ?? []
                    }
                />
            </QueryItemView>
            <SecondaryButton text={t('surveys:finishLater')} onPress={closeModal} style={themedStyle.secondaryButton} />
            <PrimaryButton text={t('surveys:sendSurvey')} onPress={handleContinue} bottomAction />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        outerContainer: { height: '100%', backgroundColor: theme.lightBackground },
        container: { height: '100%', paddingLeft: screenMargin, paddingRight: screenMargin },
        explainer: { fontSize: subtitleFontSize, marginBottom: smallestMargin },
        secondaryButton: { marginLeft: screenMargin, marginRight: screenMargin, marginBottom: smallestMargin },
    });

export default SurveyComplete;
