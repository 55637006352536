import { ReactElement, useContext } from 'react';
import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import useTabPressReset from '_navigator/hooks/useTabPressReset';
import { WW, useThemeStyle } from '_utils';
import { smallestMargin } from '_utils/sizes';
import { ThemeContext } from '_utils/themeContext';
import { HeimeText, UnreadBadge } from 'Components';

interface TabButtonProps {
    options: BottomTabNavigationOptions;
    isFocused: boolean;
    onPress: () => void;
    onLongPress: () => void;
    routeName: string;
    badgeCount?: number;
}

const TabButton = ({
    options,
    isFocused,
    onPress,
    onLongPress,
    routeName,
    badgeCount,
}: TabButtonProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const tabBarIcon = options.tabBarIcon;
    useTabPressReset(routeName);

    return (
        <TouchableOpacity
            activeOpacity={1}
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            onLongPress={onLongPress}
            style={themedStyle.tabButton}
        >
            <View style={themedStyle.contentContainer}>
                {tabBarIcon
                    ? tabBarIcon({
                          focused: isFocused,
                          color: isFocused ? theme.main : theme.mediumGrey,
                          size: 24,
                      })
                    : null}

                {badgeCount ? <UnreadBadge count={badgeCount} variant="small" /> : null}
            </View>
            <HeimeText
                style={[
                    themedStyle.tabButtonText,
                    {
                        color: isFocused ? theme.main : theme.mediumGrey,
                    },
                ]}
                numberOfLines={1}
                selectable={false}
            >
                {typeof options.tabBarLabel === 'string' ? options.tabBarLabel : options.title}
            </HeimeText>
        </TouchableOpacity>
    );
};

const styles = () =>
    StyleSheet.create({
        tabButton: {
            display: 'flex',
            flexDirection: 'column',
            flexBasis: WW / 5,
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: smallestMargin,
        },
        contentContainer: { flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
        tabButtonText: {
            width: WW / 4,
            textAlign: 'center',
        },
    });

export default TabButton;
