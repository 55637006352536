import React, { ReactElement, useContext } from 'react';
import { ActivityIndicator, Dimensions, Modal, StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { ThemeContext } from '../_utils/themeContext';

interface ModalLoaderProps {
    onDismiss?: () => void;
}

const ModalLoader = ({ onDismiss }: ModalLoaderProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);

    return (
        <Modal onDismiss={onDismiss} transparent style={themedStyle.loader} visible>
            <TouchableWithoutFeedback onPress={onDismiss} style={themedStyle.backdrop}>
                <View style={themedStyle.backdrop}>
                    <ActivityIndicator color={theme.white} size={'large'} />
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
};
const styles = (theme: Theme) =>
    StyleSheet.create({
        loader: {
            height: '100%',
        },
        backdrop: {
            height: Dimensions.get('window').height,
            width: Dimensions.get('window').width,
            backgroundColor: theme.transparentBlack,
            justifyContent: 'center',
            alignItems: 'center',
        },
    });

export default ModalLoader;
