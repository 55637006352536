import { ReactElement } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { z } from 'zod';
import { useGetApartment } from '_api/useApartments';
import { Loader } from 'Components';
import { Apartment } from 'types/Apartment';
import { RoutePropsSchema } from 'types/Utility';
import { EditDetails, EnterPhone } from './subScreens';
import { TenantEditStackParams } from './TenantEditNavigation';

const Stack = createNativeStackNavigator<TenantEditStackParams>();

const routeSchema = RoutePropsSchema(z.object({ userId: z.number().nullable(), apartmentId: z.number() }));

const TenantEdit = (props: z.infer<typeof routeSchema>): ReactElement => {
    const { userId, apartmentId } = routeSchema.parse(props).route.params;
    const { data: apartment, isLoading, isError, error } = useGetApartment(apartmentId);
    if (isError) {
        throw error;
    }

    if (isLoading) {
        return <Loader />;
    }

    const tenant = apartment?.tenants.find((maybeTenant) => maybeTenant.id === userId) as Apartment['tenants'][number];

    if (!tenant && userId) {
        throw new Error(`Could not find tenant: ${userId} in apartment:${apartmentId}`);
    }

    return (
        <Stack.Navigator
            initialRouteName={userId ? 'EditDetails' : 'EnterPhone'}
            screenOptions={{ headerShown: false }}
        >
            <Stack.Screen name="EnterPhone" component={EnterPhone} initialParams={{ apartmentId }} />
            <Stack.Screen
                name="EditDetails"
                initialParams={
                    userId
                        ? {
                              type: 'editUser',
                              user: { ...tenant, has_email: Boolean(tenant.email), lname: tenant.lname ?? '' },
                              apartmentId,
                          }
                        : { apartmentId }
                }
                component={EditDetails}
            />
        </Stack.Navigator>
    );
};

export default TenantEdit;
