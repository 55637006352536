import React, { ReactElement } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { z } from 'zod';
import { track } from '_utils/Amplitude';
import { useSelectedCoopItem } from '_utils/hooks';
import OnboardingTitles from 'Screens/Onboarding/Components/OnboardingTitles';
import { EditProfileInfo, ErrorText } from './components';
import { useOwnProfile, useUpdateProfile } from '../../_api/useProfile';
import { KeyboardAwareScrollView } from '../../_dependencies/keyboardAware';
import { SafeAreaView } from '../../_dependencies/safeArea';
import { useAppNavigation } from '../../_navigator';
import { Theme, useThemeStyle, useConfirmDiscard, showToast, isAppError, isNetworkError } from '../../_utils';
import { screenMargin } from '../../_utils/sizes';
import { PrimaryButton } from '../../Components';
import { OwnUserProfile } from '../../types/User';
import OnboardingHeader from '../Onboarding/Components/OnboardingHeader';

const mapToDefault = (profile: OwnUserProfile): FormValues => ({
    birthday: profile.birthday ? moment.unix(profile?.birthday).toDate() : null,
    firstName: profile.fname,
    lastName: profile.lname,
    email: profile.email ?? '',
    hideBirthday: profile.hide_birthday,
    hideEmail: profile.hide_mail,
    hidePhone: profile.hide_phone,
});

const getSchema = (hasEmail: boolean) =>
    z.object({
        firstName: z.string().min(1, 'onboarding:firstName_error'),
        lastName: z.string().min(1, 'onboarding:lastName_error'),
        email: hasEmail
            ? z.string().min(1, 'onboarding:email_error').email('onboarding:invalidEmail')
            : z.union([z.string().email('onboarding:invalidEmail'), z.string().length(0)]).optional(),
        birthday: z.date().nullable(),
        hideEmail: z.boolean(),
        hidePhone: z.boolean(),
        hideBirthday: z.boolean(),
    });

type FormValues = z.infer<ReturnType<typeof getSchema>>;

const ProfileEdit = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';
    // Expected to be loaded at this point
    const { data: profile } = useOwnProfile();
    const { mutate: updateProfile, isPending: isUpdatingProfile } = useUpdateProfile();
    const { goBack: internalGoBack, navigate } = useAppNavigation();

    const { formState, watch, setValue, handleSubmit, setError, ...methods } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: mapToDefault(profile as OwnUserProfile),
        resolver: zodResolver(getSchema(Boolean(profile?.email))),
    });

    const { content } = useConfirmDiscard(formState.isDirty && !isUpdatingProfile, true, internalGoBack);

    const handleContinue = handleSubmit((formValue: FormValues) => {
        track('ProfileConfirmContinue', {
            fname: formValue.firstName === profile?.fname,
            lname: formValue.lastName === profile?.lname,
            email: formValue.email === profile?.email,
            hide_mail: formValue.hideEmail === profile?.hide_mail,
            hide_phone: formValue.hidePhone === profile?.hide_phone,
            hide_birthday: formValue.hideBirthday === profile?.hide_birthday,
            birthday: formValue.birthday ? moment(formValue.birthday).unix() === profile?.birthday : false,
        });
        const waitForSuccess = formValue.email;
        updateProfile(
            {
                fname: formValue.firstName,
                lname: formValue.lastName,
                email: formValue.email,
                hide_mail: formValue.hideEmail,
                hide_phone: formValue.hidePhone,
                hide_birthday: formValue.hideBirthday,
                birthday: formValue.birthday ? moment(formValue.birthday).unix() : undefined,
            },
            {
                onSuccess: () => waitForSuccess && navigate('ProfileDescribeInfo', undefined),
                onError: (e) => {
                    if (!waitForSuccess) {
                        return;
                    }
                    if (isAppError<FormValues>(e)) {
                        if (isNetworkError(e)) {
                            return;
                        }
                        if (e.response?.data.errors?.email?.[0]) {
                            setError('email', {
                                type: 'manual',
                                message: e.response?.data.errors?.email?.[0],
                            });
                            return;
                        }
                    }

                    showToast({
                        type: 'error',
                        header: t('global:error_saving_header'),
                        text: t('global:error_saving_description'),
                        position: 'bottom',
                    });
                },
            },
        );
        if (!waitForSuccess) {
            navigate('ProfileDescribeInfo', undefined);
        }
    });

    const errorMessage = Object.values(formState.errors).reduce((curr, val) => (curr ? curr : (val.message ?? '')), '');

    return (
        <SafeAreaView style={themedStyle.safeAreaView} edges={['top']}>
            <OnboardingHeader currentStep={3} />
            <KeyboardAwareScrollView style={themedStyle.body}>
                <FormProvider
                    setValue={setValue}
                    watch={watch}
                    formState={formState}
                    handleSubmit={handleSubmit}
                    setError={setError}
                    {...methods}
                >
                    <OnboardingTitles
                        title={t('onboarding:profile_confirm_title')}
                        subTitle={t('onboarding:profile_confirm_subtitle', {
                            boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                        })}
                    />
                    <EditProfileInfo spaceContainer />
                </FormProvider>
            </KeyboardAwareScrollView>
            <View>
                {errorMessage ? <ErrorText>{t(errorMessage as 'global:noLang')}</ErrorText> : undefined}
                <PrimaryButton
                    status={isUpdatingProfile ? 'loading' : errorMessage ? 'disabled' : null}
                    bottomAction="modal"
                    onPress={handleContinue}
                    text={t('profileEdit:continue').toUpperCase()}
                />
            </View>
            {content}
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        safeAreaView: {
            display: 'flex',
            height: '100%',
            backgroundColor: theme.mainBackground,
        },
        body: {
            paddingRight: screenMargin,
            paddingLeft: screenMargin,
            flex: 1,
        },
    });

export default ProfileEdit;
