import React, { ComponentProps, ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useGetMessages } from '_api/useMessages';
import { useDebounceValue } from '_utils';
import { flattenIniniteResult } from '_utils/misc';
import { ExpandableSearchInput, QueryView } from 'Components';
import { Message } from 'types/message';

/**
 * A function that handles searching for messages, takes predefined type, and returns a search input, loadingState, and the retrieved data
 * @returns
 */
const useSearchMessages = (
    types: Message['type'][],
): [
    ReactElement,
    'loading' | 'searching' | 'refetching' | 'fetchingNextPage' | null,
    Pick<ComponentProps<typeof QueryView>, 'onRefresh' | 'loadMore'>,
    Message[],
    boolean,
] => {
    const { t } = useTranslation();
    const [searchedText, setSearchedText] = useState<string>('');
    const debouncedSearch = useDebounceValue(searchedText);
    const { data, isLoading, fetchNextPage, isFetchingNextPage, refetch, isRefetching } = useGetMessages(
        types,
        debouncedSearch,
        debouncedSearch !== '',
    );
    const { isLoading: isLoadingInitialItems } = useGetMessages(types);

    const filteredData = useMemo(() => flattenIniniteResult(data), [data]);

    return [
        <ExpandableSearchInput
            key="search"
            buttonText={t('global:search')}
            value={searchedText}
            onChange={setSearchedText}
            inputStyle={styles.searchInput}
        />,
        isLoadingInitialItems
            ? 'loading'
            : isLoading && !data
              ? 'searching'
              : isRefetching
                ? 'refetching'
                : isFetchingNextPage
                  ? 'fetchingNextPage'
                  : null,
        { loadMore: fetchNextPage, onRefresh: refetch },
        filteredData,
        debouncedSearch !== '',
    ];
};

const styles = StyleSheet.create({
    searchInput: { flexGrow: 1 },
});

export default useSearchMessages;
