import { useMemo } from 'react';
import { useApartmentQueries } from '../../_api/useApartments';
import { useOwnProfile } from '../../_api/useProfile';
import { useSelectedCoop } from '../../SelectedCoop';

const useRefreshApartments = (): [boolean, () => void] => {
    const { data: profile, refetch: refetchProfile, isRefetching: isRefetchingProfile } = useOwnProfile();
    const selectedCoop = useSelectedCoop();
    const userApartments = useMemo(
        () =>
            (
                profile?.cooperative_apartments?.filter(({ cooperative_id }) => selectedCoop === cooperative_id) ?? []
            ).map(({ id }) => id),
        [profile?.cooperative_apartments, selectedCoop],
    );

    const { refetch: refetchApartments, isRefetching: isRefetchingApartments } = useApartmentQueries(userApartments);

    const isRefetching = isRefetchingProfile || isRefetchingApartments;
    const handleRefetch = () => {
        refetchApartments();
        refetchProfile();
    };
    return [isRefetching, handleRefetch];
};

export default useRefreshApartments;
