import React, { PropsWithChildren, ReactElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import OverviewLabel from './OverviewLabel';
import { Theme, useThemeStyle, WH } from '../../../_utils';
import { DateWithSlots, SelectorChip } from '../../../Components';

interface DateTimeExplainerProps {
    onEdit?: () => void;
    start: Date;
    end: Date;
}

const DateTimeExplainer = ({
    onEdit,
    start,
    end,
    children,
}: PropsWithChildren<DateTimeExplainerProps>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const momentStart = moment(start);
    const momentEnd = moment(end);
    const isSameDay = momentStart.isSame(end, 'date');

    return (
        <OverviewLabel onEdit={onEdit} label={t('activityDetail:date')}>
            <View style={themedStyle.borderContainer}>
                <DateWithSlots
                    disabled={false}
                    title={isSameDay ? t('activityDetail:start_end_time') : t('activityDetail:start_time')}
                    date={momentStart}
                    showMonth
                    slots={[
                        <SelectorChip
                            variant="WhitePrimaryGrey"
                            key="start"
                            title={momentStart.format('HH:mm') + (isSameDay ? ` - ${momentEnd.format('HH:mm')}` : '')}
                        />,
                    ]}
                    compact={false}
                />
                {!isSameDay && (
                    <DateWithSlots
                        disabled={false}
                        title={t('activityDetail:end_time')}
                        date={momentEnd}
                        slots={[
                            <SelectorChip variant="WhitePrimaryGrey" key="start" title={momentEnd.format('HH:mm')} />,
                        ]}
                        compact={false}
                    />
                )}
            </View>
            {children}
        </OverviewLabel>
    );
};

export default DateTimeExplainer;

const styles = (theme: Theme) =>
    StyleSheet.create({
        borderContainer: {
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderTopColor: theme.mediumBackground,
            borderBottomColor: theme.mediumBackground,

            marginTop: WH * 0.01,
        },
    });
