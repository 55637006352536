import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { Icon } from 'Components';

const InfoButton = ({ onPress }: { onPress(): void }): ReactElement => {
    const { t } = useTranslation();
    return (
        <TouchableOpacity
            onPress={onPress}
            style={styles.pressable}
            accessibilityHint={t('serviceExpanded:open_explainer')}
        >
            <Icon name="support" color="main" />
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    pressable: { padding: smallestMargin, marginRight: screenMargin },
});

export default InfoButton;
