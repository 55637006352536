import { PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { screenMargin, subtitleFontSize } from '_utils/sizes';
import { HeimeText } from 'Components';

const DescribeText = ({ children }: PropsWithChildren<unknown>) => {
    const themedStyle = useThemeStyle(styles);
    return <HeimeText style={themedStyle.description}>{children}</HeimeText>;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        description: {
            fontSize: subtitleFontSize,
            color: theme.secondaryText,
            textAlign: 'center',
            marginTop: screenMargin,
        },
    });

export default DescribeText;
