import React, { PropsWithChildren, ReactElement } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import _fonts from '_fonts';
import { WW, WH, useThemeStyle, Theme } from '_utils';
import { Icon } from 'Components';

interface OverviewLabelProps {
    label: string;
    onEdit?: () => void;
    size?: 'normal' | 'big';
}

const OverviewLabel = ({
    label,
    onEdit,
    size = 'normal',
    children,
}: PropsWithChildren<OverviewLabelProps>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const content = (
        <>
            <View style={themedStyle.contentContainer}>
                <Text
                    maxFontSizeMultiplier={size === 'big' ? 2 : 1.2}
                    style={[themedStyle.label, size === 'big' ? themedStyle.sizeBig : undefined]}
                >
                    {label}
                </Text>
                {children}
            </View>
            {onEdit ? (
                <View style={themedStyle.editWrapper}>
                    <Icon name={'edit'} color="mediumGrey" />
                </View>
            ) : null}
        </>
    );
    if (onEdit) {
        return (
            <TouchableOpacity onPress={onEdit} style={themedStyle.justifiedRow}>
                {content}
            </TouchableOpacity>
        );
    } else {
        return <View style={themedStyle.justifiedRow}>{content}</View>;
    }
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        label: {
            fontFamily: _fonts.primaryFontBold,
            color: theme.black,
            fontSize: WW * 0.045,
            marginTop: 10,
            flex: 1,
        },
        sizeBig: {
            fontSize: WW * 0.06,
        },
        editWrapper: {
            marginTop: WH * 0.01,
            marginBottom: WH * 0.01,
            marginLeft: WW * 0.02,
            backgroundColor: theme.lightGrey,
            paddingLeft: WW * 0.01,
            paddingRight: WW * 0.01,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            borderRadius: 50,
            flexShrink: 0,
        },
        justifiedRow: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 48,
        },
        contentContainer: { display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'stretch' },
    });

export default OverviewLabel;
