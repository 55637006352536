import { useIsFocused } from '@react-navigation/native';
import {
    InfiniteData,
    QueryClient,
    QueryKey,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from '@tanstack/react-query';
import axios from 'axios';
import type { Moment } from 'moment';
import moment from 'moment';
import { ApiImageObject } from 'types/Base';
import { useOwnProfile } from './useProfile';
import { getFileObjectFunc, getPlaceholderSearchData, useLaravelInfinteQuery } from './utility';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import {
    FileUpload,
    GenericCollectionItem,
    isFileToBeDeleted,
    isFileToBeUploaded,
    LaravelPagingResponse,
    UnixTimeCode,
} from '../types/Utility';

enum MessageType {
    'None' = 0,
    'All' = 1,
    'With same interests' = 2,
    'OnlySignedUp' = 3,
    'OnlyNotSignedUp' = 4,
    'GroupsOnly' = 5,
}

export interface Activity {
    id: number;
    /** The amount that the user has signed up, 1 == Himself, 2 himself and one other  */
    approved_sum: number;
    pictures: ApiImageObject[];
    arrangers: GenericCollectionItem[];
    start_at: UnixTimeCode;
    end_at: UnixTimeCode;
    /**
     * Regisration deadline
     */
    last_confirm_at: UnixTimeCode | null;
    /**
     * Cancellation deadline
     */
    last_cancel_at: UnixTimeCode | null;
    /**
     * User is signed up
     */
    approved: boolean;
    /**
     * True if there is no maximum amount of attendees
     */
    no_quantity: boolean;
    /**
     * Maximum amount of people signed up (excluding organizer)
     */
    approved_total_sum: number;

    /**
     * Available quantity
     */
    quantity: number;

    /**
     *  Maximum number of attendees
     **/
    max_quantity: number;
    /**
     * Quantity not processed yet
     * Meaning that this amount is currently being ordered and not available for others, but not confirmed either
     */
    not_approved_quantity: number;
    title: string;
    user_groups?: GenericCollectionItem[];
    cooperative_group_id: number | null;
    location: string;
    description: string;
    /**
     * Closed(1)[true], Open(0)[false]
     */
    users_type: 1 | 0;
    /**
     * Users that have access to this activity, if empty: everyone
     */
    users: GenericCollectionItem[];
    paymentStrategy: {
        price: number;
        mva: number;
        bookKeepingAccount: string;
    };
    payment_account_id: string | null;
    notify_type: MessageType;

    /**
     * Returns false if users get a refund when cancelling
     */
    no_refund: boolean;
}

const invalidateActivitiesQuery = (queryClient: QueryClient): void => {
    queryClient.invalidateQueries({
        queryKey: ['activity'],
    });
    queryClient.invalidateQueries({
        queryKey: ['activities'],
    });
};

const useGetActivities = (
    from?: Moment,
    to?: Moment,
    userIsSignedUp?: boolean,
    latestFirst?: boolean,
): UseInfiniteQueryResult<InfiniteData<Activity[]>, string | Error> => {
    const isFocused = useIsFocused();
    const queryClient = useQueryClient();
    const selectedCoopId = useSelectedCoop();
    const { data: profile } = useOwnProfile();

    return useLaravelInfinteQuery(
        ['activities', selectedCoopId, from?.unix(), to?.unix(), userIsSignedUp ?? false, latestFirst ?? false],
        async ({ pageParam = 1, getAuthHeader }) =>
            await axios.get<LaravelPagingResponse<Activity[]>>(`cooperatives/${selectedCoopId}/activities`, {
                headers: { authorization: await getAuthHeader() },
                params: {
                    page: pageParam,
                    from: from?.unix(),
                    to: to?.unix(),
                    approved: userIsSignedUp ? 1 : 0,
                    latest: latestFirst ? 1 : 0,
                },
            }),
        {
            gcTime: 1000 * 60 * 60 * 24 * 7,
            staleTime: 1000 * 60 * 15,
            placeholderData: () => {
                if (latestFirst) {
                    // Having issue with chached values updating so doing it this way for now
                    return undefined;
                }
                return getPlaceholderSearchData<Activity>(
                    queryClient,
                    {
                        queryKey: ['activities', selectedCoopId],
                    },
                    (activity) => {
                        const start = moment.unix(activity.start_at);
                        const end = moment.unix(activity.end_at);
                        const isSignedUp =
                            activity.arrangers.find((item) => item.id === profile?.id) || activity.approved;
                        if (from && end.isBefore(from)) {
                            return false;
                        }
                        if (to && start.isAfter(to)) {
                            return false;
                        }
                        if (userIsSignedUp && !isSignedUp) {
                            return false;
                        }
                        return true;
                    },
                )();
            },
            enabled: isFocused,
        },
    );
};

const getActivityKey = (selectedCoopId: number, activityId: number): QueryKey => [
    'activity',
    selectedCoopId,
    activityId,
];

const useGetActivity = (activityId: number): UseQueryResult<Activity, string | Error> => {
    const isFocused = useIsFocused();
    const queryClient = useQueryClient();
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();

    return useQuery({
        queryKey: getActivityKey(selectedCoopId, activityId),

        queryFn: async () => {
            const result = await axios.get<Activity>(`cooperatives/${selectedCoopId}/activities/${activityId}`, {
                headers: { authorization: await getAuthHeader() },
            });

            if (!result.data) {
                throw new Error('Result returned with no data');
            }
            return result.data;
        },
        gcTime: 1000 * 60 * 60,
        staleTime: 1000 * 60 * 1,
        initialData: () => {
            const relatedQueries = queryClient
                .getQueryCache()
                .getAll()
                .filter(
                    (item) =>
                        !item.isStale() && item.queryKey?.[0] === 'activities' && item.queryKey?.[1] === selectedCoopId,
                );

            let result: Activity | undefined;
            relatedQueries?.forEach((query) =>
                (query.state.data as InfiniteData<LaravelPagingResponse<Activity[]>>)?.pages?.forEach(({ data }) =>
                    data?.forEach((activity: Activity) => {
                        if (activityId === activity.id) {
                            result = activity;
                        }
                    }),
                ),
            );
            return result;
        },
        initialDataUpdatedAt: moment().subtract({ minutes: 2 }).valueOf(),
        enabled: isFocused,
    });
};
export interface MutateActivityBody {
    start_at: Date;
    end_at: Date;
    last_confirm_at: Date | null;
    last_cancel_at: Date | null;
    quantity: number;
    no_quantity: boolean;
    name: string;
    description: string;
    location: string;
    payment_account_id: string | null;
    paymentStrategy: {
        price: number;
        mva: number;
        bookKeepingAccount: string;
    };
    users_type: boolean;
    message_type: Activity['notify_type'];
    users: number[];
    user_groups: number[];
    cooperative_group_id: number | null;
    active: boolean;
    pictures: FileUpload[];
    arrangers: number[];
}

const mapBodyToApi = (activity: MutateActivityBody) => {
    return {
        ...activity,
        start_at: moment(activity.start_at).unix(),
        end_at: moment(activity.end_at).unix(),
        last_confirm_at: activity.last_confirm_at ? moment(activity.last_confirm_at).unix() : null,
        last_cancel_at: activity.last_cancel_at ? moment(activity.last_cancel_at).unix() : null,
        pictures: activity.pictures.filter(isFileToBeUploaded).map(getFileObjectFunc(activity.name)),
        removePictures: activity.pictures.filter(isFileToBeDeleted).map((file) => file.id),
    };
};

const useCreateActivity = (): UseMutationResult<{ success: number }, string | Error, MutateActivityBody> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (activity: MutateActivityBody) => {
            const result = await axios.post<{ success: number }>(
                `cooperatives/${selectedCoopId}/activities`,
                mapBodyToApi(activity),
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Activity creation return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['activities'],
            });
        },
    });
};

const useEditActivity = (): UseMutationResult<{ success: number }, string | Error, [number, MutateActivityBody]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ([activityId, activity]: [number, MutateActivityBody]) => {
            const result = await axios.patch<{ success: number }>(
                `cooperatives/${selectedCoopId}/activities/${activityId}`,
                mapBodyToApi(activity),
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Activity edit return unsuccessful result');
            }
            return result.data;
        },
        onSettled: (_r, _e) => {
            invalidateActivitiesQuery(queryClient);
        },
    });
};

const useSendNotification = (): UseMutationResult<
    { success: number },
    string | Error,
    [number, { title: string; content: string }]
> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ([activityId, notification]: [number, { title: string; content: string }]) => {
            const result = await axios.patch<{ success: number }>(
                `cooperatives/${selectedCoopId}/activities/${activityId}/notify`,
                notification,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Activity send notification return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            invalidateActivitiesQuery(queryClient);
        },
    });
};

const useCancelActivity = (): UseMutationResult<{ success: boolean }, string | Error, [number, boolean]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ([activityId, refund]) => {
            const result = await axios.delete<{ success: boolean }>(
                `cooperatives/${selectedCoopId}/activities/${activityId}`,
                {
                    data: { refund },
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Activity cancellation return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            invalidateActivitiesQuery(queryClient);
        },
    });
};

export {
    useGetActivities,
    useCreateActivity,
    useEditActivity,
    useSendNotification,
    useCancelActivity,
    invalidateActivitiesQuery,
    useGetActivity,
    getActivityKey,
};
