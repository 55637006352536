import React, { ReactElement, useEffect, useMemo } from 'react';
import UnreadBadge from 'Components/UnreadBadge';
import { useGetNotifications } from '../../_api/useNotifications';
import { setBadgeCount } from '../../_dependencies/notifee';

const NotificationCountBadge = (): ReactElement | null => {
    const { data: notifications } = useGetNotifications();

    const unreadCount = useMemo(
        () =>
            (notifications?.pages ?? [])
                .reduce((curr, page) => {
                    if (page) {
                        curr.push(...page);
                    }
                    return curr;
                }, [])
                .filter(({ seen }) => seen === false).length,
        [notifications?.pages],
    );

    useEffect(() => {
        setBadgeCount(unreadCount);
    }, [unreadCount]);

    if (unreadCount === 0) {
        return null;
    }

    return <UnreadBadge top={-5} right={-5} variant="small" count={unreadCount} />;
};

export default NotificationCountBadge;
