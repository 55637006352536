import { useCallback, useState } from 'react';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Apartment, ApartmentSchema } from 'types/Apartment';
import { safeParse } from './utility';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import { Folder } from '../types/Heime';

const useGetApartment = (apartmentId: number): UseQueryResult<Apartment, string | Error> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    return useQuery({
        queryKey: ['apartment', selectedCoopId, apartmentId],

        queryFn: async () => {
            const authHeader = await getAuthHeader();
            const result = await axios.get<Apartment>(`cooperatives/${selectedCoopId}/apartments/${apartmentId}`, {
                headers: { authorization: authHeader },
            });
            return safeParse(result.data, ApartmentSchema);
        },

        gcTime: Infinity,
        staleTime: 1000 * 60 * 60 * 5,
    });
};

const useGetApartmentDocs = (apartmentId: number): UseQueryResult<Folder, string | Error> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    return useQuery({
        queryKey: ['apartmentDocs', selectedCoopId, apartmentId],

        queryFn: async () => {
            const authHeader = await getAuthHeader();
            const result = await axios.get<{ documents: Folder }>(
                `cooperatives/${selectedCoopId}/apartments/${apartmentId}/docs2`,
                {
                    headers: { authorization: authHeader },
                },
            );
            return (
                result.data.documents ?? {
                    name: '',
                    last_modified: 0,
                    files: [],
                    sub_folders: [],
                }
            );
        },

        gcTime: Infinity,
        staleTime: 1000 * 60 * 60 * 5,
    });
};

const useApartmentQueries = (
    apartmentIds: number[],
): { isLoading: boolean; refetch(): void; isRefetching: boolean } => {
    const [isRefetching, setIsRefetching] = useState(false);
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();

    const queries = apartmentIds.map((id) => [
        queryClient.getQueryState(['apartment', selectedCoopId, id]),
        queryClient.getQueryState(['apartmentDocs', selectedCoopId, id]),
    ]);

    const isLoading = queries.reduce((curr, [apQuery]) => {
        return curr && apQuery?.status === 'pending';
    }, false);

    const handleRefetch = async () => {
        setIsRefetching(true);
        const queryiesApp = queryClient.refetchQueries({ queryKey: ['apartment'], type: 'active' });
        const queryiesDocs = queryClient.refetchQueries({ queryKey: ['apartmentDocs'], type: 'active' });
        await Promise.all([queryiesApp, queryiesDocs]);
        setIsRefetching(false);
    };

    return { isLoading, refetch: handleRefetch, isRefetching };
};

const useInvalidateApartment = (): ((apartmentId: number) => void) => {
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();
    return useCallback(
        (apartmentId: number) => {
            queryClient.invalidateQueries({
                queryKey: ['apartment', selectedCoopId, apartmentId],
            });
        },
        [queryClient, selectedCoopId],
    );
};

export interface MutateSupportBody {
    area: number;
    living_area: number;
    type: 'apartment' | 'detached_house' | 'semi_detached_house' | 'room';
    project_name: string;
    legal_name: string;
    address: string;
    floor: number;
    rooms: number;
    parking_spot: string;
}

interface HeimeErrorResponse<DataType> {
    message: string;
    errors: Record<keyof DataType, string[]>;
}
const useEditApartment = (): UseMutationResult<
    { success: number },
    string | Error | AxiosError<HeimeErrorResponse<MutateSupportBody>, [number, MutateSupportBody]>,
    [number, MutateSupportBody]
> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const invalidateApartment = useInvalidateApartment();

    return useMutation({
        mutationFn: async ([apartmentId, apartment]: [number, MutateSupportBody]) => {
            const result = await axios.patch<{ success: number }>(
                `cooperatives/${selectedCoopId}/apartments/${apartmentId}`,
                apartment,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Apartment edit return unsuccessful result');
            }
            return result.data;
        },
        onSettled: (_r, _e, [apartmentId]) => invalidateApartment(apartmentId),
    });
};

type apartmentUserMutation = {
    email: string;
    phone: string;
    fname: string;
    lname: string;
    tenant_role: 'owner' | 'livesWith' | 'renting' | 'other';
    notify: 'email' | 'sms' | 'none';
};

type addApartmentUserBody = apartmentUserMutation;

const useAddApartmentUser = (): UseMutationResult<
    { success: boolean },
    string | Error,
    [number, addApartmentUserBody]
> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const invalidateApartment = useInvalidateApartment();
    return useMutation({
        mutationFn: async ([apartmentId, body]: [number, addApartmentUserBody]) => {
            const result = await axios.post<{ success: boolean }>(
                `cooperatives/${selectedCoopId}/apartments/${apartmentId}/users`,
                body,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Apartment add tenant return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: (d, [apartmentId]) => invalidateApartment(apartmentId),
    });
};

const useUpdateApartmentUser = (): UseMutationResult<
    { success: boolean },
    string | Error,
    { apartmentId: number; id: number; body: apartmentUserMutation | Pick<apartmentUserMutation, 'tenant_role'> }
> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const invalidateApartment = useInvalidateApartment();
    return useMutation({
        mutationFn: async ({
            apartmentId,
            id,
            body,
        }: {
            apartmentId: number;
            id: number;
            body: apartmentUserMutation | Pick<apartmentUserMutation, 'tenant_role'>;
        }) => {
            const result = await axios.patch<{ success: boolean }>(
                `cooperatives/${selectedCoopId}/apartments/${apartmentId}/users/${id}`,
                body,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Apartment edit return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: (d, { apartmentId }) => invalidateApartment(apartmentId),
    });
};

const useConnectApartmentUser = (): UseMutationResult<
    { success: boolean },
    string | Error,
    { apartmentId: number; id: number; body: Pick<apartmentUserMutation, 'tenant_role' | 'notify'> }
> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const invalidateApartment = useInvalidateApartment();
    return useMutation({
        mutationFn: async ({
            apartmentId,
            id,
            body,
        }: {
            apartmentId: number;
            id: number;
            body: Pick<apartmentUserMutation, 'tenant_role' | 'notify'>;
        }) => {
            const result = await axios.patch<{ success: boolean }>(
                `cooperatives/${selectedCoopId}/apartments/${apartmentId}/users/${id}/add`,
                body,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Apartment connect tenant return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: (d, { apartmentId }) => invalidateApartment(apartmentId),
    });
};

const useRemoveApartmentUser = (): UseMutationResult<
    { success: boolean },
    string | Error,
    { apartmentId: number; userId: number }
> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const invalidateApartment = useInvalidateApartment();
    return useMutation({
        mutationFn: async ({ apartmentId, userId }: { apartmentId: number; userId: number }) => {
            const result = await axios.delete<{ success: boolean }>(
                `cooperatives/${selectedCoopId}/apartments/${apartmentId}/users/${userId}`,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Apartment remove tenant return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: (d, { apartmentId }) => invalidateApartment(apartmentId),
    });
};

export {
    useGetApartment,
    useGetApartmentDocs,
    useApartmentQueries,
    useEditApartment,
    useRemoveApartmentUser,
    useAddApartmentUser,
    useUpdateApartmentUser,
    useConnectApartmentUser,
};
