import { ComponentProps } from 'react';
import { Modal as RNModal, View, StyleSheet } from 'react-native';
import { WH, WW } from '_utils';

const Modal = ({ children, ...props }: ComponentProps<typeof RNModal>) => {
    return (
        <RNModal {...props} style={styles.modal} transparent>
            <View style={styles.cover} />
            <View style={styles.content}>{children}</View>
        </RNModal>
    );
};

const styles = StyleSheet.create({
    modal: { justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' },
    cover: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    content: { maxWidth: WW, maxHeight: WH, margin: 'auto' },
});

export default Modal;
