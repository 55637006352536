import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../Components';

interface DeleteSharingModal {
    onDismiss(): void;
    onDelete(): void;
}

const DeleteSharingModal = ({ onDismiss, onDelete }: DeleteSharingModal): ReactElement => {
    const { t } = useTranslation();
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDelete = async () => {
        setIsDeleting(true);
        onDelete();
    };

    return (
        <Modal
            onDismiss={onDismiss}
            header={t('sharingSelected:deleteModal:header')}
            buttons={[
                { text: t('sharingSelected:deleteModal:back'), onPress: onDismiss },
                {
                    text: t('sharingSelected:deleteModal:approve'),
                    onPress: handleDelete,
                    type: 'danger',
                    isLoading: isDeleting,
                },
            ]}
        />
    );
};

export default DeleteSharingModal;
