import React, { ReactElement } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Icon from './Icon/Icon';
import Loader from './Loader';
import { useChatWithNeighbour, useThemeStyle, WW, Theme } from '../_utils';

interface ChatActionButtonProps {
    userId: number;
}

const ChatActionButton = ({ userId }: ChatActionButtonProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { onChat, isLoading } = useChatWithNeighbour();

    const handleChatClick = () => {
        onChat(userId);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <TouchableOpacity onPress={handleChatClick} style={themedStyle.iconButton}>
            <View style={themedStyle.iconWrapper}>
                <Icon name="commentDots" color="mainBackground" />
            </View>
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        iconButton: {
            justifyContent: 'center',
        },
        iconWrapper: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.main,
            borderRadius: (WW * 0.1) / 2,
            height: WW * 0.1,
            width: WW * 0.1,
        },
    });

export default ChatActionButton;
