import React, { ComponentProps, ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableHighlight, TouchableOpacity, View } from 'react-native';
import Images from '_images';
import { Theme, useThemeStyle } from '_utils';
import { smallestMargin, smallestMarginPixels, titleFontSize } from '_utils/sizes';
import { ThemeContext } from '_utils/themeContext';
import CacheImage from 'Components/CacheImage';
import HeimeText from 'Components/HeimeText';
import Icon from 'Components/Icon/Icon';
import SecondaryButton from 'Components/SecondaryButton';
import ModalLoader from '../../../ModalLoader';

interface ActionSheetProps {
    actionItems: ({
        label: string;
        onPress(): void;
        textColor: keyof Theme;
    } & (
        | {
              icon?: ComponentProps<typeof Icon>['name'];
          }
        | { image?: keyof typeof Images }
    ))[];
    onCancel(): void;
    isLoading?: boolean;
    title: string;
    onTitlePress?(): void;
}
const ActionSheet = ({ actionItems, onCancel, isLoading, title, onTitlePress }: ActionSheetProps): ReactElement => {
    const commonThemedStyle = useThemeStyle(commonStyles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const titleContent = <HeimeText style={commonThemedStyle.title}>{title}</HeimeText>;
    return (
        <View>
            {isLoading ? <ModalLoader /> : null}
            {onTitlePress ? <TouchableOpacity onPress={onTitlePress}>{titleContent}</TouchableOpacity> : titleContent}

            {actionItems.map((actionItem, index) => {
                return (
                    <TouchableHighlight
                        style={[
                            commonThemedStyle.actionSheetView,
                            index === actionItems.length - 1 && commonThemedStyle.last,
                        ]}
                        underlayColor={'#f7f7f7'}
                        key={index}
                        onPress={actionItem.onPress}
                    >
                        <>
                            {'icon' in actionItem && actionItem.icon ? (
                                <Icon
                                    style={{ width: 24, height: 24 }}
                                    color={actionItem.textColor ?? 'black'}
                                    name={actionItem.icon}
                                />
                            ) : 'image' in actionItem && actionItem.image ? (
                                <CacheImage style={{ width: 24, height: 24 }} source={Images[actionItem.image]} />
                            ) : null}
                            <HeimeText
                                style={[
                                    commonThemedStyle.actionSheetText,
                                    actionItem.textColor ? { color: theme[actionItem.textColor] } : null,
                                ]}
                            >
                                {actionItem.label}
                            </HeimeText>
                        </>
                    </TouchableHighlight>
                );
            })}
            <SecondaryButton text={t('global:close')} onPress={onCancel} />
        </View>
    );
};

const commonStyles = (theme: Theme) =>
    StyleSheet.create({
        last: {
            borderBottomWidth: 0,
        },
        actionSheetText: {
            color: theme.black,
            fontWeight: 'bold',
            fontSize: titleFontSize,
        },
        actionSheetView: {
            gap: smallestMargin,
            borderBottomColor: theme.secondaryLight,
            borderBottomWidth: StyleSheet.hairlineWidth,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: smallestMarginPixels * 2,
            paddingBottom: smallestMarginPixels * 2,
        },
        title: {
            fontSize: titleFontSize,
            fontWeight: 'bold',
            marginBottom: smallestMarginPixels * 2,
            color: theme.darkGray,
        },
    });

export default ActionSheet;
