import { AppError } from '../types/Utility';

const isNetworkError = function (error: AppError<unknown>): boolean {
    return (
        error.message.includes('Network Error') ||
        (error.message.includes('timeout of') && error.message.includes('exceeded'))
    );
};

export default isNetworkError;
