import { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { Theme, WH, isWeb, useThemeStyle } from '_utils';
import { smallestFontSize, smallestMargin, smallestMarginPixels, subtitleFontSize } from '_utils/sizes';
import HeimeText from './HeimeText';

interface UnreadBadgeProps {
    count: number;
    variant: 'small' | 'large';
    right?: number;
    top?: number;
}

const UnreadBadge = ({ count, variant, right, top }: UnreadBadgeProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View
            style={[
                themedStyle.container,
                variant === 'small' ? themedStyle.containerSmall : themedStyle.containerLarge,
                right !== undefined ? { right } : {},
                top !== undefined ? { top } : {},
            ]}
        >
            <HeimeText
                style={[themedStyle.text, variant === 'large' ? themedStyle.textLarge : themedStyle.textSmall]}
                numberOfLines={1}
            >
                {count}
            </HeimeText>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            position: 'absolute',
            backgroundColor: theme.error,
            alignItems: 'center',
            justifyContent: 'center',
        },
        containerSmall: {
            borderRadius: WH,
            top: Math.min(-smallestMarginPixels / 2, isWeb() ? -5 : 0),
            right: Math.min(-smallestMarginPixels, isWeb() ? -10 : 0),
            minWidth: Math.max(smallestMarginPixels * 2.5, isWeb() ? 20 : 0),
            minHeight: Math.max(smallestMarginPixels * 2.5, isWeb() ? 20 : 0),
        },
        containerLarge: {
            borderRadius: WH,
            top: smallestMarginPixels,
            right: smallestMargin,
            minWidth: Math.max(smallestMarginPixels * 4, isWeb() ? 40 : 0),
            minHeight: Math.max(smallestMarginPixels * 4, isWeb() ? 40 : 0),
        },
        text: {
            color: theme.lightBackground,
            fontWeight: 'bold',
        },
        textSmall: {
            fontSize: smallestFontSize,
        },
        textLarge: {
            fontSize: subtitleFontSize,
        },
    });

export default UnreadBadge;
