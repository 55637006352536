import React, { ReactElement } from 'react';
import { TouchableOpacity, View, Text, StyleSheet, StyleProp, TextStyle, ViewStyle } from 'react-native';
import _fonts from '../_fonts';
import { Theme, useThemeStyle, WH, WW } from '../_utils';

interface ListItemProps {
    onPress?: () => void;
    avatar?: ReactElement;
    title: string;
    subtitle?: string;
    subtitleStyle?: StyleProp<TextStyle>;
    titleStyle?: StyleProp<TextStyle>;
    actionButton?: ReactElement | null;
    containerStyle?: StyleProp<ViewStyle>;
    containerWrapper?: StyleProp<ViewStyle>;
    numberOfLines?: number | null;
}

const ListItem = ({
    onPress,
    avatar,
    title,
    subtitle,
    subtitleStyle,
    containerStyle,
    containerWrapper,
    titleStyle,
    actionButton,
    numberOfLines = 2,
}: ListItemProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const content = (
        <>
            <View>{avatar}</View>
            <View style={[themedStyle.contentWrapper, containerWrapper]}>
                <Text
                    maxFontSizeMultiplier={2}
                    numberOfLines={numberOfLines === null ? undefined : numberOfLines}
                    style={[themedStyle.title, titleStyle]}
                >
                    {title}
                </Text>
                {subtitle ? (
                    <Text
                        numberOfLines={numberOfLines === null ? undefined : numberOfLines}
                        style={[themedStyle.desc, subtitleStyle]}
                    >
                        {subtitle}
                    </Text>
                ) : null}
            </View>
            {actionButton ? actionButton : undefined}
        </>
    );

    if (onPress) {
        return (
            <TouchableOpacity activeOpacity={1} onPress={onPress} style={[themedStyle.main, containerStyle]}>
                {content}
            </TouchableOpacity>
        );
    }
    return <View style={[themedStyle.main, containerStyle]}>{content}</View>;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flexDirection: 'row',
            alignItems: 'stretch',
            paddingTop: WH * 0.02,
            paddingBottom: WH * 0.02,
            borderBottomColor: theme.lightGrey,
            borderBottomWidth: 1,
        },
        title: {
            fontSize: WW * 0.045,
            color: theme.darkGray,
            fontFamily: _fonts.primaryFontBold,
        },
        desc: {
            color: theme.secondaryText,
            fontSize: WW * 0.04,
            fontFamily: _fonts.primaryFont,
        },
        contentWrapper: {
            flex: 2,
            justifyContent: 'center',
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
        },
    });

export default ListItem;
