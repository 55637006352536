import { useReanimatedKeyboardAnimation } from 'react-native-keyboard-controller';
import { useAnimatedStyle } from 'react-native-reanimated';
import { useKeyboardIsVisible, WH } from '_utils';
import { isAndroid } from '_utils/isPlatform';

const useTranslatedKeyboardHeight = isAndroid()
    ? () => {
          const keyboardIsVisible = useKeyboardIsVisible();
          return useAnimatedStyle(() => {
              return {
                  transform: [{ translateY: keyboardIsVisible ? WH : 0 }],
              };
          });
      }
    : () => {
          const { height: keyboardHeight } = useReanimatedKeyboardAnimation();
          return useAnimatedStyle(() => {
              return {
                  transform: [{ translateY: Math.abs(keyboardHeight.value) }],
              };
          }, [keyboardHeight]);
      };

export default useTranslatedKeyboardHeight;
