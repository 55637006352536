import React, { PropsWithChildren, ReactElement, useContext } from 'react';
import { RefreshControl, StyleProp, StyleSheet, ViewStyle, ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Theme, useThemeStyle, useVisualRefetchState } from '../../../_utils';
import { ThemeContext } from '../../../_utils/themeContext';

interface ColoredBackgroundProps {
    style?: StyleProp<ViewStyle>;
    isRefreshing?: boolean;
    onRefresh?: () => void;
}

const ColoredBackground = ({
    children,
    style,
    isRefreshing,
    onRefresh,
}: PropsWithChildren<ColoredBackgroundProps>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const [refreshing, handleRefresh] = useVisualRefetchState(isRefreshing ?? false, onRefresh ?? (() => {}));
    const insets = useSafeAreaInsets();

    return (
        <ScrollView
            contentContainerStyle={[
                {
                    paddingTop: insets.top,
                },
                style,
            ]}
            style={themedStyle.main}
            nestedScrollEnabled={true}
            refreshControl={
                onRefresh && (
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={handleRefresh}
                        tintColor={theme.main}
                        colors={[theme.main]}
                    />
                )
            }
        >
            {children}
        </ScrollView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: theme.shadow,
            flexGrow: 1,
        },
    });

export default ColoredBackground;
