import React, { FC, PropsWithChildren, useMemo } from 'react';
import { useSelectedCoopItem } from './hooks';
import _colors from '../_colors';

export interface Theme {
    mainBackground: string;
    accent: string;
    main: string;
    mainText: string;
    secondary: string;
    secondaryLight: string;
    shadow: string;
    darkerShadow: string;
    secondaryBackground: string;
    lightBackground: string;
    dangerBackground: string;
    mediumBackground: string;
    darkGray: string;
    black: string;
    alert: string;
    lightRed: string;
    transparent_red: string;
    red: string;
    hyperText: string;
    error: string;
    errorLight: string;
    chatMyMessageBubble: string;
    chatOtherMessageBubble: string;
    chatMessageText: string;
    secondaryText: string;
    darkGreen: string;
    lightGreen: string;
    lightMustard: string;
    mediumGrey: string;
    success: string;
    darkGrey: string;
    white: string;
    lightGrey: string;
    graphite: string;
    greyMedium: string;
    transparentBlack: string;
    lightYellow: string;
    yellow: string;
    lightGreen1: string;
    green: string;
    ownChatMessageText: string;
    myChatMessageBubble: string;
    otherChatMessageText: string;
    transparent: string;
    shadowText: string;
}

const defaultTheme = _colors as unknown as Theme;

const initialState = {
    theme: defaultTheme,
};

const ThemeContext = React.createContext<{ theme: Theme }>(initialState);

const ThemeProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const selectedCoop = useSelectedCoopItem();

    const themes = useMemo(() => {
        const coopTheme =
            selectedCoop?.white_label_theme && !Array.isArray(selectedCoop?.white_label_theme)
                ? selectedCoop?.white_label_theme
                : null;
        return {
            theme: {
                mainBackground: coopTheme?.mainBackground || defaultTheme.mainBackground,
                accent: coopTheme?.accent || defaultTheme.accent,
                main: coopTheme?.main || defaultTheme.main,
                mainText: coopTheme?.mainText || defaultTheme.mainText,
                secondary: coopTheme?.secondary || defaultTheme.secondary,
                secondaryLight: coopTheme?.secondaryLight || defaultTheme.secondaryLight,
                shadow: coopTheme?.shadow || defaultTheme.shadow,
                darkerShadow: coopTheme?.darkerShadow || defaultTheme.darkerShadow,
                secondaryBackground: coopTheme?.secondaryBackground || defaultTheme.secondaryBackground,
                lightBackground: coopTheme?.lightBackground || defaultTheme.lightBackground,
                dangerBackground: coopTheme?.dangerBackground || defaultTheme.dangerBackground,
                mediumBackground: coopTheme?.mediumBackground || defaultTheme.mediumBackground,
                darkGray: coopTheme?.darkGray || defaultTheme.darkGray,
                black: coopTheme?.black || defaultTheme.black,
                alert: coopTheme?.alert || defaultTheme.alert,
                lightRed: coopTheme?.lightRed || defaultTheme.lightRed,
                transparent_red: coopTheme?.transparent_red || defaultTheme.transparent_red,
                red: coopTheme?.red || defaultTheme.red,
                hyperText: coopTheme?.hyperText || defaultTheme.hyperText,
                error: coopTheme?.error || defaultTheme.error,
                errorLight: coopTheme?.errorLight || defaultTheme.errorLight,
                chatMyMessageBubble: coopTheme?.chatMyMessageBubble || defaultTheme.chatMyMessageBubble,
                chatOtherMessageBubble: coopTheme?.chatOtherMessageBubble || defaultTheme.chatOtherMessageBubble,
                chatMessageText: coopTheme?.chatMessageText || defaultTheme.chatMessageText,
                secondaryText: coopTheme?.secondaryText || defaultTheme.secondaryText,
                darkGreen: coopTheme?.darkGreen || defaultTheme.darkGreen,
                lightGreen: coopTheme?.lightGreen || defaultTheme.lightGreen,
                lightMustard: coopTheme?.lightMustard || defaultTheme.lightMustard,
                mediumGrey: coopTheme?.mediumGrey || defaultTheme.mediumGrey,
                success: coopTheme?.success || defaultTheme.success,
                darkGrey: coopTheme?.darkGrey || defaultTheme.darkGrey,
                white: coopTheme?.white || defaultTheme.white,
                lightGrey: coopTheme?.lightGrey || defaultTheme.lightGrey,
                graphite: coopTheme?.graphite || defaultTheme.graphite,
                greyMedium: coopTheme?.greyMedium || defaultTheme.greyMedium,
                transparentBlack: coopTheme?.transparentBlack || defaultTheme.transparentBlack,
                lightYellow: coopTheme?.lightYellow || defaultTheme.lightYellow,
                yellow: coopTheme?.yellow || defaultTheme.yellow,
                lightGreen1: coopTheme?.lightGreen1 || defaultTheme.lightGreen1,
                green: coopTheme?.green || defaultTheme.green,
                ownChatMessageText: coopTheme?.ownChatMessageText || defaultTheme.ownChatMessageText,
                myChatMessageBubble: coopTheme?.myChatMessageBubble || defaultTheme.myChatMessageBubble,
                otherChatMessageText: coopTheme?.otherChatMessageText || defaultTheme.otherChatMessageText,
                shadowText: coopTheme?.shadowText || defaultTheme.shadowText,
                transparent: defaultTheme.transparent,
            },
        };
    }, [selectedCoop?.white_label_theme]);

    return <ThemeContext.Provider value={themes}>{children}</ThemeContext.Provider>;
};

const useThemeContext = () => {
    const context = React.useContext(ThemeContext);
    if (context === undefined) {
        throw new Error('useThemeContext must be used within a ThemeProvider');
    }
    return context;
};

export { ThemeProvider, ThemeContext, useThemeContext };
