import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCoops } from '_api/useCoops';
import { useGetMessages } from '_api/useMessages';
import { useSurveys } from '_api/useSurveys';
import { ScreenName } from '_navigator/navigationConfiguration/types';
import { useCalculateMessageRead } from '_utils';
import { flattenIniniteResult } from '_utils/misc';
import { MessageType } from 'types/message';
import { Activities, Notices, Services, Sharing } from './icons';
import { Card, SmallButton } from './types';

export const useSmallButtons = (): SmallButton<ScreenName>[] => {
    const surveyCount = flattenIniniteResult(useSurveys().data).filter(
        (item) =>
            item.status === 'ACTIVE' && item.responses.filter((response) => response.status === 'SENT').length === 0,
    ).length;

    return useMemo(
        () => [
            {
                destination: 'Board',
                icon: 'boardMembers',
                text: 'board',
            },
            {
                destination: 'Documents',
                icon: 'folder',
                text: 'documents',
            },
            {
                destination: 'MyHome',
                icon: 'home',
                text: 'myHouse',
            },
            {
                destination: 'NeighborsAll',
                icon: 'users',
                text: 'neighbours',
            },
            {
                destination: 'GroupsAll',
                icon: 'groups',
                text: 'groups',
            },
            {
                destination: 'Profile',
                icon: 'user',
                text: 'myProfile',
            },
            {
                destination: 'MemberBenefitsAll',
                icon: 'tags',
                text: 'memberBenefits',
            },
            {
                destination: 'ReportsAll',
                icon: 'alert',
                text: 'reports',
            },
            {
                destination: 'SurveysAll',
                icon: 'survey',
                text: 'surveys',
                unreadCount: surveyCount,
            },
            {
                destination: 'ChatsAll',
                params: { showBackHeader: 'true' as const },
                icon: 'chat',
                text: 'chat',
            } as SmallButton<'ChatsAll'>,
        ],
        [surveyCount],
    );
};

export const useLargeButtons = (selectedCoopId: number): Card<ScreenName>[] => {
    const { t } = useTranslation();
    const { data: coops } = useCoops();

    const { data: noticesData } = useGetMessages([MessageType.Requests, MessageType.ByBoard]);
    const calculateMesageRead = useCalculateMessageRead();

    const cards = useMemo(() => {
        const selectedCoop = coops?.find((coop) => coop.id === selectedCoopId);

        const activitiesImage = selectedCoop?.white_label_activities
            ? { icon: { ...selectedCoop.white_label_activities, priority: 'high' } }
            : { svg: Activities };
        const noticesImage = selectedCoop?.white_label_notices
            ? { icon: { ...selectedCoop.white_label_notices, priority: 'high' } }
            : { svg: Notices };
        const servicesImage = selectedCoop?.white_label_services
            ? { icon: { ...selectedCoop.white_label_services, priority: 'high' } }
            : { svg: Services };
        const sharingImage = selectedCoop?.white_label_neighborsharing
            ? { icon: { ...selectedCoop.white_label_neighborsharing, priority: 'high' } }
            : { svg: Sharing };

        const activities: Card<'ActivitiesAll'> = {
            ...activitiesImage,
            title: t('home:activities'),
            screen: 'ActivitiesAll' as const,
        };

        const notices: Card<'MessagesToNeighbors'> = {
            ...noticesImage,
            title: t('home:notices'),
            screen: 'MessagesToNeighbors' as const,
            params: { showBackHeader: 'true' as const },
            unreadCount: flattenIniniteResult(noticesData)
                .filter((message) => !calculateMesageRead(message))
                .reduce<number | undefined>((curr) => (curr ? ++curr : 1), undefined),
        };

        const services: Card<'Services'> = {
            ...servicesImage,
            title: t('home:services'),
            screen: 'Services' as const,
            params: { showBackHeader: 'true' as const },
        };

        const sharing: Card<'NeighborSharing'> = {
            ...sharingImage,
            title: t('home:sharing'),
            screen: 'NeighborSharing' as const,
            params: undefined,
        };

        return [activities, notices, services, sharing];
    }, [calculateMesageRead, coops, noticesData, selectedCoopId, t]);

    return cards;
};
