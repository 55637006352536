import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios from 'axios';
import { API_URL } from '_constants';
import { fetchBlob, fetchWrap } from '_dependencies/fetchBlob';
import { useGlobalState } from '../_utils';
import { useGetAccessTokenHeader } from '../_utils/Axios';

const useUploadFile = (): UseMutationResult<
    { success: string },
    string | Error,
    { contentType: string; path: string | File; onProgress?: (progress: number) => void }
> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const { selectedLanguage } = useGlobalState((state) => state.selectedLanguage);

    return useMutation({
        mutationFn: async ({ contentType, path, onProgress }) => {
            if (path instanceof File) {
                const formData = new FormData();
                formData.append('file', path);
                const result = await axios.post('/file', formData, {
                    headers: {
                        authorization: await getAuthHeader(),
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (!result.data.success) {
                    throw new Error('Uploading file returned unsuccessful result');
                }

                return result.data;
            } else {
                const result = await fetchBlob(
                    'post',
                    `${API_URL(selectedLanguage ?? 'no')}file`,
                    { authorization: await getAuthHeader(), accept: 'application/json' },
                    [
                        {
                            name: 'file',
                            filename: 'file',
                            type: contentType,
                            data: fetchWrap(path),
                        },
                    ],
                ).uploadProgress({ interval: 10 }, (written, total) => {
                    onProgress && onProgress(written / total);
                });
                const parsedResult = JSON.parse(result.data);

                if (!parsedResult.success) {
                    throw new Error('Uploading file returned unsuccessful result');
                }

                return parsedResult;
            }
        },
        retry: 3,
    });
};

export { useUploadFile };
