import React, { ReactElement, useContext } from 'react';
import { Moment } from 'moment';
import { StyleSheet, Text, View, TouchableWithoutFeedback } from 'react-native';
import _fonts from '../_fonts';
import { useThemeStyle, WH, WW } from '../_utils';
import { capitalizeFirstLetter } from '../_utils/misc';
import { bigNumberFontSize, smallestMargin, subtitleFontSize } from '../_utils/sizes';
import { Theme, ThemeContext } from '../_utils/themeContext';

interface DateWithSlotsProps {
    disabled?: boolean;
    date: Moment;
    title: string;
    slots: ReactElement[];
    onPress?: () => void;
    compact?: boolean;
    showMonth?: boolean;
    disablePadding?: boolean;
    borderBottom?: boolean;
}

const DateWithSlots = ({
    disabled,
    date,
    title,
    slots,
    onPress,
    compact = true,
    showMonth = false,
    disablePadding = false,
    borderBottom = false,
}: DateWithSlotsProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const content = (
        <View
            style={[
                themedStyle.main,
                disablePadding
                    ? undefined
                    : {
                          paddingBottom: WH * 0.02,
                      },
                borderBottom ? { borderBottomWidth: 1, borderBottomColor: theme.lightGrey } : undefined,
                compact ? themedStyle.mainCompact : undefined,
            ]}
        >
            <View style={themedStyle.dateWrapper}>
                <Text style={[themedStyle.date, { color: disabled ? theme.secondaryText : theme.main }]}>
                    {date.format('D')}
                </Text>
                {showMonth ? (
                    <Text style={[themedStyle.day, { color: disabled ? theme.secondaryText : theme.main }]}>
                        {capitalizeFirstLetter(date.format('MMMM'))}
                    </Text>
                ) : null}
                <Text style={[themedStyle.day, { color: disabled ? theme.secondaryText : theme.main }]}>
                    {capitalizeFirstLetter(date.format('dddd'))}
                </Text>
            </View>
            <View style={themedStyle.contentWrapper}>
                <Text style={[themedStyle.title, { color: disabled ? theme.secondaryText : theme.darkGray }]}>
                    {title}
                </Text>
                {slots.length > 0 ? <View style={themedStyle.slotWrapper}>{slots}</View> : null}
            </View>
        </View>
    );
    if (onPress) {
        return <TouchableWithoutFeedback onPress={onPress}>{content}</TouchableWithoutFeedback>;
    }

    return content;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flexDirection: 'row',
            display: 'flex',
        },
        mainCompact: {
            borderBottomWidth: 1,
            borderBottomColor: theme.secondaryText,
            marginLeft: WH * 0.02,
            marginRight: WH * 0.02,
            marginBottom: WH * 0.02,
        },
        dateWrapper: {
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginLeft: WH * 0.01,
            marginRight: WH * 0.01,
            minWidth: WW * 0.15,
            flexShrink: 0,
        },
        date: {
            fontFamily: _fonts.primaryFontBold,
            color: theme.main,
            fontSize: bigNumberFontSize,
        },
        day: {
            fontFamily: _fonts.primaryFont,
            color: theme.main,
            fontSize: WH * 0.015,
        },
        contentWrapper: {
            justifyContent: 'center',
            flexDirection: 'column',
            marginLeft: WH * 0.01,
            marginRight: WH * 0.01,
            flex: 1,
            maxWidth: '100%',
            flexGrow: 1,
        },
        title: {
            marginLeft: WH * 0.02,
            marginRight: WH * 0.02,
            marginTop: WH * 0.01,
            marginBottom: WH * 0.01,
            fontFamily: _fonts.primaryFontBold,
            fontSize: subtitleFontSize,
        },
        slotWrapper: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: smallestMargin },
    });

export default DateWithSlots;
