import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import InAppReview from 'react-native-in-app-review';
import { useOwnProfile } from '_api/useProfile';
import { SafeAreaView } from '_dependencies/safeArea';
import { useAppNavigation } from '_navigator';
import { Theme, WW, getFullName, isWeb, isiOS, openURL, showToast, useConfirmDiscard, useThemeStyle } from '_utils';
import { track } from '_utils/Amplitude';
import { captureUserFeedback } from '_utils/Sentry';
import { smallestMargin, screenMargin, subtitleFontSize, titleFontSize } from '_utils/sizes';
import { HeaderWithNav, HeimeText, Icon, ControlledInput, MainLogo, PrimaryButton, SecondaryButton } from 'Components';

const ImageWidth = WW * 0.8;
const ImageHeight = ImageWidth * 0.3;

const RateHeime = () => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const [nStars, setNumberOfStars] = useState<null | number>(null);
    const [isGivingFeedback, setIsGivingFeedback] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [feedback, setFeedback] = useState('');
    const { navigate, goBack: internalGoBack } = useAppNavigation();
    const { data: ownProfile } = useOwnProfile();

    const handleSubmitPress = async () => {
        track('rateUs', { stars: nStars });
        if (nStars && nStars >= 4) {
            if (isWeb()) {
                openURL('rate.heime.com', t);
                navigate('Notifications', undefined);
                return;
            }
            setIsLoading(true);
            InAppReview.RequestInAppReview()
                .then((wasSuccessful) => {
                    track('rateUsFinished', { inAppReviewSuccess: wasSuccessful });
                })
                .catch((error) => {
                    track('rateUsError', { inAppReviewError: error });
                })
                .finally(() => {
                    navigate('StartScreen', undefined);
                });
        } else {
            setIsGivingFeedback(true);
        }
    };
    const handleLaterPress = () => {
        navigate('Notifications', undefined);
    };

    const handleSubmitFeedback = () => {
        track('rateUsFeedback', { feedback });

        captureUserFeedback(feedback, getFullName(ownProfile?.fname, ownProfile?.lname));
        setFeedback('');
        showToast({
            type: 'success',
            header: t('rateUs:feedback_success'),
            text: '',
        });
        setTimeout(() => {
            navigate('StartScreen', undefined);
        }, 0);
    };

    const { goBack, content } = useConfirmDiscard(Boolean(feedback), false, internalGoBack);

    return (
        <SafeAreaView edges={['left', 'right', 'bottom']} style={themedStyle.main}>
            <HeaderWithNav title={t('rateUs:header')} onPress={goBack} />
            {content}
            {isGivingFeedback ? (
                <>
                    <ScrollView bounces={false} contentContainerStyle={themedStyle.container}>
                        <HeimeText style={themedStyle.title}>{t('rateUs:feedback_title')}</HeimeText>
                        <HeimeText style={themedStyle.subtitle}>{t('rateUs:feedback_subtitle')}</HeimeText>
                        <ControlledInput
                            value={feedback}
                            onChange={(arg) => setFeedback(arg ?? '')}
                            label={t('rateUs:feedback_label')}
                            placeholder={t('rateUs:feedback_placeholder')}
                            multiline
                            numberOfLines={4}
                            autoFocus
                        />
                    </ScrollView>
                    <PrimaryButton
                        text={t('rateUs:submit')}
                        onPress={handleSubmitFeedback}
                        status={feedback ? undefined : 'disabled'}
                    />
                </>
            ) : (
                <>
                    <ScrollView bounces={false} contentContainerStyle={themedStyle.container}>
                        <MainLogo imageWidth={ImageWidth} imageHeight={ImageHeight} />
                        <HeimeText style={themedStyle.title}>{t('rateUs:title')}</HeimeText>
                        <HeimeText style={themedStyle.subtitle}>{t('rateUs:subtitle')}</HeimeText>
                        <View style={themedStyle.starsContainer}>
                            {[1, 2, 3, 4, 5].map((star) => (
                                <TouchableOpacity key={star} onPress={() => setNumberOfStars(star)}>
                                    <Icon
                                        name={nStars && star <= nStars ? 'starRatingFilled' : 'starRating'}
                                        color="white"
                                    />
                                </TouchableOpacity>
                            ))}
                        </View>
                    </ScrollView>
                    <PrimaryButton
                        text={
                            nStars && nStars >= 4
                                ? isWeb()
                                    ? t('rateUs:title')
                                    : isiOS()
                                      ? t('rateUs:goToStore_ios')
                                      : t('rateUs:goToStore_android')
                                : t('rateUs:submit')
                        }
                        onPress={handleSubmitPress}
                        status={nStars ? (isLoading ? 'loading' : undefined) : 'disabled'}
                    />
                    <SecondaryButton text={t('rateUs:cancel')} onPress={handleLaterPress} />
                </>
            )}
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: theme.mainBackground,
            height: '100%',
            paddingHorizontal: screenMargin,
            gap: smallestMargin,
            paddingBottom: screenMargin,
        },
        container: {
            alignItems: 'center',
            justifyContent: 'center',
            gap: screenMargin,
            flexGrow: 1,
        },
        title: {
            marginTop: screenMargin,
            fontSize: titleFontSize,
            fontWeight: 'bold',
            textAlign: 'center',
            color: theme.darkGrey,
        },
        subtitle: {
            fontSize: subtitleFontSize,
            textAlign: 'center',
            color: theme.mediumGrey,
        },
        starsContainer: {
            flexDirection: 'row',
            gap: smallestMargin,
        },
    });

export default RateHeime;
