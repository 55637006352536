import axios, { AxiosPromise } from 'axios';

interface CheckResponseOwnCode {
    sms_code_send: false;
    own_code_left_attempts: number;
}

interface CheckResponseOTPCode {
    sms_code_send: true;
}

type CheckResponse = (CheckResponseOTPCode | CheckResponseOwnCode) & {
    success: boolean;
};

const checkAsync = (phone: string): AxiosPromise<CheckResponse> => {
    return axios.post<CheckResponse>(
        'check',
        { phone },
        { validateStatus: (status) => (status >= 200 && status < 300) || status === 422 },
    );
};

export interface LoginResponse {
    token_type: 'Bearer';
    expires_in: number;
    access_token: string;
    refresh_token: string;
}

const client_id = 1;
const client_secret = 'FXNT8DOwiXyE6I3ugflhVlYL2OysHbHJAdeXaPkn';

const loginAsync = (phone: string, pin: string): AxiosPromise<LoginResponse> => {
    return axios.post<LoginResponse>('oauth/token', {
        username: phone,
        password: pin,
        grant_type: 'password',
        client_id,
        client_secret,
    });
};

const loginTokenAsync = (refresh_token: string): AxiosPromise<LoginResponse> => {
    return axios.post<LoginResponse>('oauth/token', {
        refresh_token,
        grant_type: 'refresh_token',
        client_id,
        client_secret,
    });
};

interface ResendResponse {
    success: boolean;
    sms_code_send: boolean;
    for_testing?: boolean;
}
const resendAsync = (phone: string): AxiosPromise<ResendResponse> => {
    return axios.post<ResendResponse>('check', { phone });
};

interface ForgottenResponse {
    sms_code_send: boolean;
    success: boolean;
}
const forgottenAsync = (phone: string): AxiosPromise<ForgottenResponse> => {
    return axios.post<ForgottenResponse>('forgotten', { phone });
};

export { checkAsync, loginAsync, resendAsync, forgottenAsync, loginTokenAsync };
