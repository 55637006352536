import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, StyleProp, ViewStyle, TouchableHighlight, Keyboard } from 'react-native';
import FileActionSheet from './FileActionsheet';
import useFileState from './useFileState';
import { Theme, useThemeStyle, WW, bytesToString, fileIsImage, useImageSelector, isTruthy } from '../../_utils';
import { screenMargin, smallestMargin, subtitleFontSize } from '../../_utils/sizes';
import { FileUpload, StaleFile, isFileToBeUploaded, isStaleFile } from '../../types/Utility';
import CacheImage from '../CacheImage';
import HeimeText from '../HeimeText';
import Icon from '../Icon/Icon';
import InputLabel from '../InputLabel';

interface FileSelectorProps {
    files: FileUpload[];
    onChange: (files: FileUpload[]) => void;
    title?: string;
    optional?: boolean;
    style?: StyleProp<ViewStyle>;
    openInitially?: boolean;
    type?: 'image' | 'file';
    filesPosition?: 'top' | 'bottom';
}

const FileSelector = ({
    files,
    onChange,
    style,
    title,
    type,
    optional,
    openInitially = false,
    filesPosition = 'top',
}: FileSelectorProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const [isSheetVisible, setIsSheetVisible] = useState(false);

    useEffect(() => {
        if (openInitially) {
            setTimeout(() => setIsSheetVisible(openInitially), 100);
        }
    }, [openInitially]);

    const { onAdd, onDelete } = useFileState(files, onChange);

    useEffect(() => {
        if (isSheetVisible) {
            Keyboard.dismiss();
        }
    }, [isSheetVisible]);

    // We toggle in case we end up in a weird state
    const showActionSheet = () => setIsSheetVisible((curr) => !curr);
    const closeActionSheet = () => setIsSheetVisible(false);

    const [onSelect, permission] = useImageSelector(onAdd, closeActionSheet, true);

    const filesOverview = useMemo(() => {
        const getRemoveFilePress = (file: FileUpload) => () => {
            onDelete(file);
        };
        return [...files.filter(isStaleFile), ...files.filter(isFileToBeUploaded)].map((file) => (
            <View key={file.name ?? file.index} style={themedStyle.fileContainer}>
                <Icon name="paperClip" color="mediumGrey" />
                {fileIsImage(file) ? (
                    <>
                        <CacheImage<StaleFile | { original: string }>
                            style={themedStyle.imagePreview}
                            source={isStaleFile(file) ? file : { original: file.uri }}
                            resizeMode="contain"
                        />
                        <View style={themedStyle.spacer} />
                    </>
                ) : (
                    <HeimeText style={themedStyle.fileName}>{file.name}</HeimeText>
                )}
                {isFileToBeUploaded(file) && file.size ? <HeimeText>{bytesToString(file.size)}</HeimeText> : null}
                <TouchableOpacity onPress={getRemoveFilePress(file)}>
                    <Icon name="cross" color="red" />
                </TouchableOpacity>
            </View>
        ));
    }, [
        files,
        onDelete,
        themedStyle.fileContainer,
        themedStyle.fileName,
        themedStyle.imagePreview,
        themedStyle.spacer,
    ]);

    return (
        <View style={style}>
            {title ? <InputLabel optional={optional}>{title}</InputLabel> : null}
            {filesPosition === 'top' ? filesOverview : null}
            <TouchableHighlight onPress={showActionSheet}>
                <View style={themedStyle.titleWrapper}>
                    <View style={themedStyle.addIconWrapper}>
                        <Icon name="paperClip" color="mediumGrey" />
                    </View>
                    <HeimeText maxFontSizeMultiplier={2} style={themedStyle.explainer}>
                        {t('global:attachFile')}
                    </HeimeText>
                </View>
            </TouchableHighlight>
            {isSheetVisible ? (
                <FileActionSheet
                    actions={[
                        type !== 'file'
                            ? {
                                  type: 'Library' as const,
                                  onSelect: () => onSelect({ cropping: false, type: 'library' }),
                              }
                            : null,
                        type !== 'file'
                            ? { type: 'Camera' as const, onSelect: () => onSelect({ cropping: false, type: 'camera' }) }
                            : null,
                        type !== 'image' ? { type: 'File' as const, onChange: onChange } : null,
                    ].filter(isTruthy)}
                    onClose={closeActionSheet}
                />
            ) : null}
            {filesPosition === 'bottom' ? filesOverview : null}
            {permission}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        titleWrapper: {
            paddingTop: WW * 0.1,
            paddingBottom: WW * 0.1,
            paddingLeft: smallestMargin,
            paddingRight: smallestMargin,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.mediumBackground,
        },
        explainer: {
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            marginTop: screenMargin,
            textAlign: 'center',
            color: theme.darkGrey,
        },
        avatarWrapper: {
            flexDirection: 'row',
            justifyContent: 'center',
        },
        imagePreview: {
            width: WW * 0.2,
            height: WW * 0.2,
        },
        addIconWrapper: {
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            backgroundColor: theme.mainBackground,
            borderRadius: 50,
        },
        imageContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        fileContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: WW * 0.02,
            alignItems: 'center',
        },
        fileName: {
            flex: 1,
            marginLeft: WW * 0.02,
            marginRight: WW * 0.02,
        },
        spacer: { flex: 1 },
    });

export default FileSelector;
