import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import { Theme, ThemeContext } from '../_utils/themeContext';

const Loader = ({ bgColor }: { bgColor?: keyof Theme }): ReactElement => {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext);
    return (
        <View
            style={[styles.container, { backgroundColor: bgColor ? theme[bgColor] : theme.mainBackground }]}
            accessibilityState={{ busy: true }}
            accessibilityLabel={t('global:loading')}
        >
            <ActivityIndicator color={theme.accent} size={'large'} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        justifyContent: 'center',
    },
});

export default Loader;
