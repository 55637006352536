import { useContext, useMemo } from 'react';
import { Theme, ThemeContext } from '../themeContext';

const useThemeStyle = <T>(styles: (theme: Theme) => T): T => {
    const context = useContext(ThemeContext);
    return useMemo(() => {
        return styles(context.theme);
    }, [styles, context.theme]);
};

export default useThemeStyle;
