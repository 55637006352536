import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageType } from 'types/message';
import SharingCategorySelected from './SharingAll/SharingCategorySelected';

const NeighborSharing = (): ReactElement => {
    const { t } = useTranslation();
    return (
        <SharingCategorySelected
            types={[MessageType.Helpers, MessageType.Products]}
            header={t('sharingAll:neighborSharingTitle')}
            emptyText={t('sharingAll:noMessageType:neighborSharing')}
        />
    );
};

export default NeighborSharing;
