import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { SelectorChip } from '../../../Components';

interface TimeExplainerProps {
    from: string;
    to: string;
}

const TimeExplainer = ({ from, to }: TimeExplainerProps): ReactElement => {
    const { t } = useTranslation();
    const noop = () => {};

    return (
        <View style={styles.explainer}>
            <View style={styles.elementContainer}>
                <Text style={styles.labelText}>{t('serviceExpanded:checkin')}</Text>
                <SelectorChip variant="WhitePrimary" title={from} onSelect={noop} />
            </View>
            <View style={styles.elementContainer}>
                <Text style={styles.labelText}>{t('serviceExpanded:checkout')}</Text>
                <SelectorChip variant="WhitePrimary" title={to} onSelect={noop} />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    explainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        rowGap: smallestMargin,
        marginTop: screenMargin / 2,
        marginBottom: screenMargin / 2,
    },
    elementContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
    labelText: { fontWeight: 'bold', marginRight: 8 },
});

export default TimeExplainer;
