import { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useRemoveApartmentUser } from '_api/useApartments';
import { useOwnProfile } from '_api/useProfile';
import { useGetUser } from '_api/useUsers';
import Images from '_images';
import { useAppNavigation } from '_navigator';
import { WH, WW, getUsernameFromProfile } from '_utils';
import { screenMargin } from '_utils/sizes';
import { ThemeContext } from '_utils/themeContext';
import { ContextMenu, ContextTrigger, HeimeText, Modal, SecondaryButton, ListItem, CacheImage } from 'Components';
import { Apartment } from 'types/Apartment';

interface ApartmentDocumentsProps {
    apartment: Apartment;
}

const ApartmentTenants = ({ apartment }: ApartmentDocumentsProps): ReactElement => {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext);
    const { data: profile } = useOwnProfile();
    const ownRole = apartment.tenants.find((user) => user.id === profile?.id)?.tenant_role;
    const { navigate } = useAppNavigation();
    const [isMoreMenuOpen, setIsMoreMenuOpen] = useState<number | undefined>(undefined);
    const [removeModalId, setRemoveModalId] = useState<number | undefined>(undefined);
    const removingUser = useGetUser(removeModalId);
    const { isPending: isRemovingUser, mutate: removeUser } = useRemoveApartmentUser();

    const handleAddUser = () => {
        navigate('TenantEdit', { apartmentId: apartment.id, userId: null });
    };
    const handleEditUser = (userId: number) => {
        navigate('TenantEdit', { apartmentId: apartment.id, userId });
        setIsMoreMenuOpen(undefined);
    };
    const handleRemoveUser = (userId: number) => {
        setIsMoreMenuOpen(undefined);
        setRemoveModalId(userId);
    };

    const handleModalDismiss = () => {
        setRemoveModalId(undefined);
    };
    const handleConfirmRemove = () => {
        if (removeModalId) {
            removeUser({ apartmentId: apartment.id, userId: removeModalId }, { onSuccess: handleModalDismiss });
        }
    };

    const removeFromCoop =
        !removingUser ||
        !('cooperative_apartments' in removingUser) ||
        (removingUser?.cooperative_apartments ?? []).length === 1;

    return (
        <View style={styles.main}>
            <ScrollView contentContainerStyle={styles.container}>
                {apartment.tenants.map((user) => (
                    <ListItem
                        key={user.id}
                        title={getUsernameFromProfile(user)}
                        onPress={user.status === 'active' ? () => navigate('Profile', { id: user.id }) : undefined}
                        avatar={
                            <CacheImage
                                source={user?.avatar ? user.avatar : Images.defaultAvatar}
                                style={styles.avatar}
                            />
                        }
                        subtitleStyle={user.tenant_role === 'owner' ? { color: theme.red } : undefined}
                        subtitle={t(`myHome:role_${user.tenant_role}`)}
                        actionButton={
                            user.id === profile?.id || ownRole !== 'owner' ? undefined : (
                                <ContextMenu
                                    onToggle={() =>
                                        setIsMoreMenuOpen((curr) => (curr === user.id ? undefined : user.id))
                                    }
                                    isOpen={isMoreMenuOpen === user.id}
                                    trigger={<ContextTrigger />}
                                    actions={[
                                        {
                                            text: t('myHome:editUser'),
                                            onPress: () => handleEditUser(user.id),
                                        },
                                        {
                                            text: t('myHome:removeUser'),
                                            onPress: () => handleRemoveUser(user.id),
                                            type: 'danger',
                                        },
                                    ]}
                                />
                            )
                        }
                    />
                ))}
                {ownRole === 'owner' ? (
                    <SecondaryButton onPress={handleAddUser} text={t('myHome:addTenant')} />
                ) : undefined}
            </ScrollView>
            {removeModalId ? (
                <Modal
                    header={t('myHome:removeTenantConfirmHeader')}
                    onDismiss={handleModalDismiss}
                    content={
                        <HeimeText>
                            {removeFromCoop
                                ? t('myHome:removeTenantConfirmText')
                                : t('myHome:removeTenantConfirmText2')}
                        </HeimeText>
                    }
                    buttons={[
                        {
                            text: t('myHome:removeTenantCancel'),
                            onPress: handleModalDismiss,
                            type: 'secondary',
                        },
                        {
                            text: t('myHome:removeTenantConfirm'),
                            onPress: handleConfirmRemove,
                            type: 'danger',
                            isLoading: isRemovingUser,
                        },
                    ]}
                />
            ) : null}
        </View>
    );
};

const styles = StyleSheet.create({
    main: {
        flex: 1,
        marginBottom: WH * 0.06,
    },
    container: {
        paddingLeft: screenMargin,
        paddingRight: screenMargin,
    },
    avatar: {
        width: WW * 0.18,
        height: WW * 0.18,
        borderRadius: (WW * 0.18) / 2,
        overflow: 'hidden',
    },
});

export default ApartmentTenants;
