import React, { ReactElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Text, View, StyleSheet } from 'react-native';
import _fonts from '../../../../../_fonts';
import { useThemeStyle, WW } from '../../../../../_utils';
import { smallestFontSize } from '../../../../../_utils/sizes';
import { Theme } from '../../../../../_utils/themeContext';
import { Icon } from '../../../../../Components';
import { UnixTimeCode } from '../../../../../types/Utility';

interface MessageRatingProps {
    text: string;
    isOwn: boolean;
    created_at: UnixTimeCode;
}

const MessageRating = ({ text, isOwn, created_at }: MessageRatingProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    return (
        <View style={isOwn ? themedStyle.ownRatingBubble : themedStyle.othersRatingBubble}>
            <View style={isOwn ? themedStyle.ownIconWrapper : themedStyle.othersIconWrapper}>
                <Icon name={isOwn ? 'star' : 'starFilled'} color={isOwn ? 'secondary' : 'white'} />
            </View>

            <View style={themedStyle.content}>
                <Text style={isOwn ? themedStyle.ownTitle : themedStyle.othersTitle}>
                    {isOwn ? t('chat:starSended') : t('chat:starReceived')}
                    <Text style={themedStyle.text}>{text}</Text>
                </Text>
                <Text style={themedStyle.time}>{moment.unix(created_at).format('HH:mm')}</Text>
            </View>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        ownRatingBubble: {
            alignSelf: 'flex-end',
            borderRadius: 24,
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
            width: WW * 0.85,
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            borderWidth: 1,
            borderColor: theme.lightGrey,
            borderBottomRightRadius: 0,
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: theme.white,
        },
        othersRatingBubble: {
            alignSelf: 'flex-start',
            borderRadius: 24,
            width: WW * 0.85,
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
            borderBottomLeftRadius: 0,
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: theme.lightGreen,
        },
        ownIconWrapper: {
            borderRadius: WW * 0.2,
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            backgroundColor: theme.lightGrey,
        },
        othersIconWrapper: {
            borderRadius: WW * 0.2,
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            backgroundColor: theme.darkGreen,
        },
        content: {
            flex: 1,
            paddingLeft: WW * 0.04,
        },
        ownTitle: {
            fontFamily: _fonts.primaryFont,
            fontSize: smallestFontSize,
            color: theme.secondary,
        },
        othersTitle: {
            fontFamily: _fonts.primaryFont,
            fontSize: smallestFontSize,
            color: theme.darkGreen,
        },
        text: {
            fontFamily: _fonts.primaryFontBold,
            fontSize: smallestFontSize,
            color: theme.black,
        },
        time: {
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.03,
            color: theme.secondaryLight,
        },
    });

export default MessageRating;
