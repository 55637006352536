import useAppNavigation from './hooks/useAppNavigation';
import useResetNavigationStack from './hooks/useResetNavigationStack';
import { useContextMenuFocusEffect } from './PlusMenuContext';

interface RouteParams<Params> {
    route: { params: Params };
}

export type { RouteParams };
export { useAppNavigation, useResetNavigationStack, useContextMenuFocusEffect };
