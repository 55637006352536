import React, { PropsWithChildren, ReactElement } from 'react';
import { KeyboardAvoidingView, SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import MenuProvider from 'Components/MenuProvider';
import { useThemeStyle, isiOS, WH, WW, Theme } from '../../_utils';
import Footer from '../../Components/Footer';

const Container = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <SafeAreaView style={themedStyle.fullHeight}>
            <KeyboardAvoidingView style={themedStyle.fullHeight} behavior={isiOS() ? 'padding' : undefined}>
                <GestureHandlerRootView style={themedStyle.fullHeight}>
                    <MenuProvider>
                        <ScrollView
                            alwaysBounceVertical={false}
                            keyboardShouldPersistTaps="always"
                            style={themedStyle.container}
                            contentContainerStyle={themedStyle.contentContainer}
                        >
                            {children}
                        </ScrollView>
                        <Footer />
                    </MenuProvider>
                </GestureHandlerRootView>
            </KeyboardAvoidingView>
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        fullHeight: {
            height: '100%',
            backgroundColor: theme.mainBackground,
        },
        container: {
            height: '100%',
            display: 'flex',
            backgroundColor: theme.mainBackground,
        },
        contentContainer: {
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            marginTop: WH * 0.01,
            marginBottom: WH * 0.01,
            marginLeft: WW * 0.05,
            marginRight: WW * 0.05,
            flex: 1,
        },
    });

export default Container;
