import React, { ComponentProps, ReactElement } from 'react';
import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';
import Icon from 'Components/Icon/Icon';

const TabOptions: BottomTabNavigationOptions = {
    header: () => null,
};

export const tabIcon =
    (focusedName: ComponentProps<typeof Icon>['name'], notFocusedName: ComponentProps<typeof Icon>['name']) =>
    (props: { focused: boolean }): ReactElement => (
        <Icon
            {...props}
            color={props.focused ? 'main' : 'mediumGrey'}
            name={props.focused ? focusedName : notFocusedName}
        />
    );
export default TabOptions;
