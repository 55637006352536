import React, { ReactElement } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text } from 'react-native';
import _fonts from '../../../../_fonts';
import { Theme, useThemeStyle, WW } from '../../../../_utils';
import { CooperativeGroupDropdown, FormInput, PrimaryButton } from '../../../../Components';
import { FormValues } from '../SharingNew';

const HelpNeighbor = ({
    onSubmit,
    isLoading,
}: {
    onSubmit: (values: FormValues) => void;
    isLoading?: boolean;
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { handleSubmit, formState, watch } = useFormContext<FormValues>();
    const { field } = useController({ name: 'title' });

    const isEditing = watch('isEditing');
    const disableButton = !formState.isValid;

    return (
        <>
            <Text style={themedStyle.title}>{t('sharingNew:helpNeighbour:title')}</Text>
            <FormInput
                maxLength={40}
                optional
                optionalText={t('sharingNew:helpNeighbour:textLimit', { count: field.value.length })}
                placeholder={t('sharingNew:helpNeighbour:enterTitle')}
                name="title"
                label={t('sharingNew:helpNeighbour:textTitle')}
                autoFocus
            />
            <FormInput
                placeholder={t('sharingNew:helpNeighbour:enterDesc')}
                multiline
                numberOfLines={2}
                name="content"
                label={t('sharingNew:helpNeighbour:textDesc')}
            />
            <CooperativeGroupDropdown isCreatingNew={!isEditing} />
            <PrimaryButton
                style={themedStyle.button}
                status={isLoading ? 'loading' : disableButton ? 'disabled' : null}
                text={t('sharingNew:publish').toUpperCase()}
                onPress={handleSubmit(onSubmit)}
            />
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        title: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.02,
            fontFamily: _fonts.primaryFontBold,
            color: theme.black,
            fontSize: WW * 0.05,
        },
        button: {
            marginTop: WW * 0.04,
            marginBottom: WW * 0.04,
        },
    });
export default HelpNeighbor;
