import { memo } from 'react';
import { StackHeader } from '_navigator/components';
import TabOptions, { tabIcon } from '_navigator/TabOptions';
import SCREENS from 'Screens';
import { ValueOf } from 'types/Utility';

export const allStaticScreens = {
    JoinGroups: { component: SCREENS.JoinGroups, parent: 'Onboarding' },
    GroupSelectedOnboard: { component: SCREENS.GroupSelected, parent: 'Onboarding' },
    PrivacyPolicy: {
        component: SCREENS.PrivacyPolicy,
        parent: 'Onboarding',
        initialParams: { isNotOnboarding: 'false' },
    },
    AllowPushAlertsStep: { component: SCREENS.AllowPushAlertsStep, parent: 'Onboarding' },
    ProfileConfirmInfo: { component: SCREENS.ProfileConfirmInfo, parent: 'Onboarding' },
    ProfileDescribeInfo: { component: SCREENS.ProfileDescribeInfo, parent: 'Onboarding' },

    CreatePin: { component: SCREENS.CreatePin, parent: 'modalPresentation' },
    Notifications: { component: SCREENS.Notifications, parent: 'modalPresentation' },
    ActivityNew: { component: SCREENS.ActivityNew, parent: 'modalPresentation' },
    ReservationFinal: { component: SCREENS.ReservationFinal, parent: 'modalPresentation' },
    Transaction: { component: SCREENS.Transaction, parent: 'modalPresentation' },
    GroupNew: { component: SCREENS.GroupNew, parent: 'modalPresentation' },
    SharingNew: { component: SCREENS.SharingNew, parent: 'modalPresentation' },
    HomeEdit: { component: SCREENS.HomeEdit, parent: 'modalPresentation' },
    ReportNew: { component: SCREENS.ReportNew, parent: 'modalPresentation' },
    ManageLicensePlates: { component: SCREENS.ManageLicensePlates, parent: 'modalPresentation' },
    PrivacyPolicyNotOnboarding: {
        component: SCREENS.PrivacyPolicy,
        parent: 'modalPresentation',
        initialParams: { isNotOnboarding: 'true' },
    },
    RateHeime: { component: SCREENS.RateHeime, parent: 'modalPresentation' },
    ProfileAboutMe: { component: SCREENS.ProfileAboutMe, parent: 'modalPresentation' },

    ActivitySuggestions: { component: SCREENS.ActivitySuggestions, parent: 'ActivitySuggestion' },
    ActivitySuggestionSelected: { component: SCREENS.ActivitySuggestionSelected, parent: 'ActivitySuggestion' },

    ProfileEdit: { component: SCREENS.ProfileEdit, parent: 'profileStack' },
    OwnProfile: { component: SCREENS.Profile, parent: 'profileStack', initialParams: { isModal: true } },

    ChatNewName: { component: SCREENS.ChatNewName, parent: 'chatNewStack' },
    ChatNewMembers: {
        component: SCREENS.ChatNewMembers,
        parent: 'chatNewStack',
    },

    GroupsAll: { component: SCREENS.GroupsAll, parent: 'More' },
    GroupSelected: { component: SCREENS.GroupSelected, parent: 'More' },

    Services: { component: SCREENS.Services, parent: 'Reserving' },
    Category: { component: SCREENS.Category, parent: 'Reserving' },
    ProductDetail: { component: SCREENS.ProductDetail, parent: 'Reserving' },
    ProductReserve: { component: SCREENS.ProductReserve, parent: 'Reserving' },

    SharingSelected: { component: SCREENS.SharingSelected, parent: 'modalPresentation' },

    NeighborsAll: { component: SCREENS.NeighborsAll, parent: 'More' },
    HelpfulNeighbors: { component: SCREENS.HelpfulNeighbor, parent: 'More' },
    Items: { component: SCREENS.Items, parent: 'More' },

    MemberBenefitsAll: { component: SCREENS.MemberBenefitsAll, parent: 'More' },
    MemberBenefitSelected: { component: SCREENS.MemberBenefitSelected, parent: 'More' },

    ChatsAll: { component: SCREENS.ChatAll, parent: 'Chat', initialParams: { showBackHeader: 'false' } },
    ChatSelected: { component: SCREENS.ChatSelected, parent: 'chatStack' },
    ChatInfo: { component: SCREENS.ChatInfo, parent: 'chatStack' },

    MoreSelect: { component: SCREENS.MoreMenu, parent: 'More' },
    TermsOfSale: { component: SCREENS.TermsOfSale, parent: 'modalPresentation' },
    MyHome: { component: SCREENS.MyHome, parent: 'More' },
    TenantEdit: { component: SCREENS.TenantEdit, parent: 'modalPresentation' },

    Contact: { component: SCREENS.Contact, parent: 'More' },
    Board: { component: SCREENS.Board, parent: 'More' },
    Documents: { component: SCREENS.Documents, parent: 'More' },
    FolderSelected: { component: SCREENS.FolderSelected, parent: 'More' },
    ReservationsAll: { component: SCREENS.ReservationsAll, parent: 'More' },
    ReservationSelected: { component: SCREENS.ReservationSelected, parent: 'More' },
    ReservationEdited: { component: SCREENS.ProductReserve, parent: 'More' },
    Profile: { component: SCREENS.Profile, parent: 'More' },
    ReportsAll: { component: SCREENS.ReportsAll, parent: 'More' },
    ReportSelected: { component: SCREENS.ReportSelected, parent: 'modalPresentation' },
    UserMenu: { component: SCREENS.SettingsMenu, parent: 'More' },

    ImagePreview: { component: SCREENS.ImagePreview, parent: 'ownModal' },

    StartScreen: { component: SCREENS.Home, parent: 'HomeTab' },
    NeighborSharing: { component: SCREENS.NeighborSharing, parent: 'HomeTab' },
    ActivitiesAll: {
        component: SCREENS.ActivitiesAll,
        parent: 'HomeTab',
    },
    ActivitySelected: { component: SCREENS.ActivitySelected, parent: 'HomeTab' },
    MessagesToNeighbors: {
        component: SCREENS.NoticesAll,
        parent: 'HomeTab',
        initialParams: { showBackHeader: 'false' },
    },
    OwnSharingCategory: { component: SCREENS.OwnSharingCategory, parent: 'HomeTab' },

    SurveysAll: { component: SCREENS.SurveysAll, parent: 'More' },
    SurveySelected: { component: SCREENS.SurveySelected, parent: 'modalPresentation' },

    Procedure: { component: SCREENS.Procedure, parent: 'modalPresentation' },
} as const;

export const nonScreens = {
    modalPresentation: {
        type: 'group',
        screenOptions: { presentation: 'modal' },
        parent: null,
        defaultRoute: 'OwnProfile',
    },
    ownModal: {
        type: 'group',
        screenOptions: { presentation: 'transparentModal' },
        parent: 'modalPresentation',
        defaultRoute: 'ImagePreview',
    },
    chatNewStack: {
        type: 'stack',
        component: StackHeader,
        parent: 'modalPresentation',
        defaultRoute: 'ChatNewMembers',
    },
    ActivitySuggestion: {
        type: 'stack',
        parent: 'modalPresentation',
        defaultRoute: 'ActivitySuggestions',
    },
    chatStack: {
        type: 'stack',
        parent: 'modalPresentation',
        defaultRoute: 'ChatSelected',
    },
    profileStack: {
        type: 'stack',
        parent: 'modalPresentation',
        defaultRoute: 'OwnProfile',
    },
    Onboarding: {
        type: 'stack',
        parent: null,
        backBehavior: undefined,
        defaultRoute: 'PrivacyPolicy',
    },
    Main: {
        type: 'tab',
        navOptions: TabOptions,
        parent: null,
        defaultRoute: 'HomeTab',
        backBehavior: 'history',
    },
    HomeTab: {
        type: 'stack',
        screenOptions: {
            tabBarIcon: tabIcon('homeFilled', 'home'),
            tabBarLabel: 'navigation:home',
            tabBarBadge: 'messagesToNeighbor',
        },
        parent: 'Main',
        defaultRoute: 'StartScreen',
    },
    Reserving: {
        type: 'stack',
        screenOptions: {
            tabBarIcon: tabIcon('bookingFilled', 'booking'),
            tabBarLabel: 'navigation:reservations',
        },
        parent: 'Main',
        defaultRoute: 'Services',
    },
    Chat: {
        type: 'stack',
        screenOptions: {
            tabBarIcon: tabIcon('chatFilled', 'chat'),
            tabBarLabel: 'navigation:chat',
            tabBarBadge: 'chat',
        },
        parent: 'Main',
        defaultRoute: 'ChatsAll',
    },
    More: {
        type: 'stack',
        screenOptions: {
            tabBarIcon: tabIcon('moreFilled', 'more'),
            tabBarLabel: 'navigation:more',
            tabBarBadge: 'messagesWithoutToNeighbor',
        },
        parent: 'Main',
        defaultRoute: 'MoreSelect',
    },
} as const;

export const allScreens = (function () {
    const stackNames = Object.entries(nonScreens)
        .filter(([_, options]) => options.type === 'stack' && options.parent === 'Main')
        .map(([name]) => name) as Array<keyof typeof nonScreens>;

    const screensWithStackParent = Object.entries(allStaticScreens).filter(
        (
            arg: [string, ValueOf<typeof allStaticScreens>],
        ): arg is [keyof typeof allStaticScreens, ValueOf<typeof allStaticScreens>] => {
            const [_, options] = arg;
            if ('parent' in options) {
                return stackNames.includes(options.parent as keyof typeof nonScreens);
            } else {
                arg[1];
                return false;
            }
        },
    );

    const entries = Object.entries(allStaticScreens).map(([name, options]) => [
        name,
        { ...options, component: memo(options.component) },
    ]) as [keyof typeof allStaticScreens, ValueOf<typeof allStaticScreens> | { parent: keyof typeof nonScreens }][];

    return screensWithStackParent.reduce((acc, [name, options]) => {
        stackNames.forEach((stack) => {
            if (options.parent === stack) {
                return;
            }
            acc.push([name, { ...options, parent: stack }]);
        });
        return acc;
    }, entries);
})();
export const getAllScreens = () => allScreens;
export const getAllStaticScreens = () => allStaticScreens;
export const getNonScreens = () => nonScreens;
