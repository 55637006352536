import React, { PropsWithChildren, ReactElement } from 'react';
import { Modal as RNModal, StyleSheet, View, Dimensions } from 'react-native';
import { Theme, useThemeStyle } from '_utils';

const Modal = ({ children, onClose }: PropsWithChildren<{ onClose(): void }>): ReactElement => {
    const themedStyle = useThemeStyle(styles);

    return (
        <RNModal transparent onDismiss={onClose} onRequestClose={onClose}>
            <View style={themedStyle.nativeModal}>
                <View style={themedStyle.cover} />
                {children}
            </View>
        </RNModal>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        nativeModal: {
            width: Dimensions.get('window').width,
            height: Dimensions.get('window').height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        cover: {
            width: Dimensions.get('window').width,
            height: Dimensions.get('window').height,
            backgroundColor: theme.black,
            opacity: 0.4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    });

export default Modal;
