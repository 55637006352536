import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useMarkMessageRead } from '../../_api/useMessages';
import { useGetNotifications, useMarkNotificationSeen } from '../../_api/useNotifications';
import { cancelNotification, getDisplayedNotifications } from '../../_dependencies/notifee';
import { flattenIniniteResult } from '../../_utils/misc';

const useReadMessage = (): ((messageId: number[], isRead: boolean) => void) => {
    const queryClient = useQueryClient();
    const { mutate: markMessageRead, isPending: isLoading } = useMarkMessageRead();
    const { data: userNotifications } = useGetNotifications();
    const { mutate: markNotificationSeen } = useMarkNotificationSeen();

    return useCallback(
        (messageId: number[], isRead: boolean) => {
            if (
                queryClient.isMutating({ mutationKey: ['messages'] }) ||
                queryClient.isMutating({ mutationKey: ['notifications'] })
            ) {
                return;
            }

            if (!isRead && !isLoading) {
                markMessageRead(messageId);
            }

            const relatedNotifications = flattenIniniteResult(userNotifications)
                .filter(
                    (notification) =>
                        (notification.type === 'NewMessageComment' && messageId.includes(notification.messageId)) ||
                        (notification.type === 'MessageCreated' && messageId.includes(notification.notice_id)),
                )
                // .map((not) => console.log(not) || not)
                .map(({ id }) => id);

            if (relatedNotifications.length) {
                markNotificationSeen(relatedNotifications);
            }

            const stringMessageId = messageId.map((id) => `${id}`);
            getDisplayedNotifications().then((notifications) =>
                notifications
                    .filter(
                        (notification) =>
                            notification?.notification?.data?.notice_id &&
                            typeof notification?.notification?.data?.notice_id === 'string' &&
                            stringMessageId.includes(notification?.notification?.data?.notice_id),
                    )
                    .forEach((notification) => cancelNotification(notification.id ?? '')),
            );
        },
        [markMessageRead, markNotificationSeen, queryClient, userNotifications, isLoading],
    );
};

export default useReadMessage;
