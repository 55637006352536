import { WH } from './dimensions';

export const screenMargin = WH * 0.03;
export const titleFontSize = '2rem';
export const subtitleFontSize = '1.5rem';
export const subtitleLineHeight = '2.25rem';
export const bigNumberFontSize = '4rem';
export const smallestMargin = '0.25rem';
export const smallestMarginPixels = 4;
export const smallestFontSize = '1rem';
