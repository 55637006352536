import React, { ReactElement, useContext, useMemo, useState } from 'react';
import Clipboard from '@react-native-clipboard/clipboard';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { MemberBenefit, useGetMemberBenefits } from '_api/useMemberBenefits';
import { useAppNavigation } from '_navigator';
import { isWeb, openURL, showToast, Theme, useThemeStyle, WH, WW } from '_utils';
import { useBottomSpacer } from '_utils/hooks';
import { flattenIniniteResult } from '_utils/misc';
import { screenMargin, subtitleFontSize, titleFontSize } from '_utils/sizes';
import { ThemeContext } from '_utils/themeContext';
import {
    CacheImage,
    EmptyList,
    ExpandableSearchInput,
    HeaderWithNav,
    HeimeText,
    Icon,
    Loader,
    QueryView,
    ReadMore,
} from 'Components';

const MemberBenefitsAll = (): ReactElement => {
    const { t } = useTranslation();
    const { data, isLoading, isError, error, refetch, isRefetching, isFetchingNextPage, fetchNextPage } =
        useGetMemberBenefits();
    const paddingBottom = useBottomSpacer();

    if (isError) {
        throw error;
    }

    const [searchString, setSearchString] = useState('');
    const items = useMemo(
        () => flattenIniniteResult(data).filter((item) => !searchString || item.title.includes(searchString)),
        [data, searchString],
    );
    const themedStyle = useThemeStyle(styles);
    return (
        <View style={themedStyle.container}>
            <HeaderWithNav title={t('moreMenu:memberBenefits')} safeArea />
            {isLoading ? (
                <Loader bgColor="lightBackground" />
            ) : (
                <QueryView
                    isLoadingMore={isFetchingNextPage}
                    loadMore={() => fetchNextPage()}
                    isRefreshing={isRefetching}
                    onRefresh={refetch}
                    data={items}
                    renderItem={({ item }) => <MemberBenefitItem {...item} />}
                    top={
                        searchString || items.length > 3 ? (
                            <View style={themedStyle.searchContainer}>
                                <ExpandableSearchInput
                                    buttonText={t('global:search')}
                                    value={searchString}
                                    onChange={setSearchString}
                                />
                            </View>
                        ) : undefined
                    }
                    emptyList={
                        <EmptyList message={searchString ? t('memberBenefits:noResult') : t('memberBenefits:empty')} />
                    }
                    containerStyle={{ paddingBottom }}
                />
            )}
        </View>
    );
};

const MemberBenefitItem = ({ images, title, description, url, id, promo_code }: MemberBenefit): ReactElement => {
    const { navigate } = useAppNavigation();
    const themedStyle = useThemeStyle(itemStyle);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();

    const handlePress = () => {
        navigate('MemberBenefitSelected', { memberBenefitId: `${id}` });
        return false;
    };

    const handleUrlPress = () => {
        openURL(url, t);
    };

    const handleCodePress = () => {
        Clipboard.setString(promo_code);
        showToast({
            header: t('memberBenefit:copied'),
            text: '',
            type: 'success',
            position: 'bottom',
            shortTime: true,
        });
    };

    const picture = images?.[0];
    return (
        <TouchableOpacity onPress={handlePress} style={themedStyle.container}>
            <View style={themedStyle.top}>
                {picture ? <CacheImage style={themedStyle.image} resizeMode="contain" source={picture} /> : null}
                <View style={themedStyle.textContainer}>
                    <HeimeText style={themedStyle.title}>{title}</HeimeText>
                    <ReadMore
                        numberOfLines={4}
                        onReadMorePress={handlePress}
                        textStyle={themedStyle.description}
                        text={description}
                    />
                </View>
            </View>
            <View style={themedStyle.bottom}>
                {promo_code ? (
                    <TouchableOpacity onPress={handleCodePress} style={themedStyle.bottomItem}>
                        <View style={themedStyle.bottomItemContent}>
                            <HeimeText style={themedStyle.promoCode}>{promo_code}</HeimeText>
                            <Icon name="copySheets" color="white" />
                        </View>
                    </TouchableOpacity>
                ) : null}
                {url ? (
                    <View style={themedStyle.urlContainer}>
                        <TouchableOpacity onPress={handleUrlPress} style={themedStyle.url}>
                            <View style={themedStyle.urlText}>
                                <HeimeText style={{ color: theme.main }}>{t('memberBenefits:url')}</HeimeText>
                                <Icon name="globe" color="main" />
                            </View>
                        </TouchableOpacity>
                    </View>
                ) : null}
            </View>
        </TouchableOpacity>
    );
};

const itemStyle = (theme: Theme) =>
    StyleSheet.create({
        container: {
            marginTop: screenMargin,
            marginLeft: screenMargin,
            marginRight: screenMargin,
            borderTopWidth: 1,
            borderRightWidth: 1,
            borderBottomWidth: 1,
            borderLeftWidth: 1,
            borderTopColor: theme.main,
            borderRightColor: theme.main,
            borderBottomColor: theme.main,
            borderLeftColor: theme.main,
            borderRadius: 10,
            overflow: 'hidden',
            backgroundColor: theme.mainBackground,
        },
        top: {
            display: 'flex',
            flexDirection: 'row',
            overflow: 'hidden',
            flex: isWeb() ? 1 : undefined,
        },
        image: { width: WW * 0.4, minHeight: WH * 0.2 },
        textContainer: {
            paddingTop: screenMargin / 2,
            paddingRight: screenMargin / 2,
            paddingLeft: screenMargin / 2,
            paddingBottom: screenMargin / 2,
            flex: 1,
            borderLeftWidth: 1,
            borderLeftColor: theme.main,
        },
        title: {
            fontWeight: 'bold',
            color: theme.main,
            marginBottom: screenMargin / 2,
            fontSize: titleFontSize,
        },
        bottom: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            backgroundColor: theme.main,
            color: theme.lightBackground,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
        },
        bottomItem: { flex: 1, justifyContent: 'center', alignItems: 'center' },
        bottomItemContent: { flexDirection: 'row', alignItems: 'center' },
        urlContainer: { flex: 1, justifyContent: 'center', alignItems: 'center', minHeight: WH * 0.05 },
        promoCode: {
            color: theme.lightBackground,
            fontWeight: 'bold',
            fontSize: titleFontSize,
            padding: screenMargin / 4,
        },
        url: {
            backgroundColor: theme.lightBackground,
            color: theme.main,
            marginTop: screenMargin / 4,
            marginRight: screenMargin / 4,
            marginBottom: screenMargin / 4,
            marginLeft: screenMargin / 4,
            paddingTop: screenMargin / 4,
            paddingRight: screenMargin / 4,
            paddingBottom: screenMargin / 4,
            paddingLeft: screenMargin / 4,
            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
        },
        urlText: { flexDirection: 'row', alignItems: 'center' },
        description: {
            color: theme.secondaryText,
            fontSize: subtitleFontSize,
        },
    });

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: { backgroundColor: theme.lightBackground, flex: 1 },
        searchContainer: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            marginTop: screenMargin / 2,
        },
    });

export default MemberBenefitsAll;
