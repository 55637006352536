import { useCoops } from '../../_api/useCoops';
import { useSelectedCoop } from '../../SelectedCoop';
import { ArrayElement } from '../../types/Utility';

const useSelectedCoopItem = (): ArrayElement<Exclude<ReturnType<typeof useCoops>['data'], undefined>> | undefined => {
    const selectedCoopId = useSelectedCoop();
    const { data: coops = [] } = useCoops();

    return coops.find(({ id }) => id === selectedCoopId);
};

export default useSelectedCoopItem;
