import { AppDispatch } from '.';
import { clearDevice } from './deviceToken';
import { reset as resetSelectedCoop } from './selectedCoop';
import { reset as SettingReset } from './settings';

/**
 * Function that performs a logout regarding the redux state
 */
const logout = (dispatch: AppDispatch): void => {
    dispatch(SettingReset());
    dispatch(resetSelectedCoop());
    dispatch(clearDevice());
};

export default logout;
