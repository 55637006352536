import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import { Path, Svg } from 'react-native-svg';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { HeimeText } from 'Components';

const PressHere = ({
    language_variant = 'suggestion',
    onPress,
}: {
    language_variant?: 'suggestion' | 'other_neighborhood';
    onPress(): void;
}) => {
    const { t } = useTranslation();
    const { theme } = useThemeContext();

    const strings =
        language_variant === 'suggestion'
            ? {
                  title: t('activity:suggestions:press_here_title'),
                  description: t('activity:suggestions:press_here_description'),
              }
            : {
                  title: t('activity:suggestions:press_here_title_other_neighborhood'),
                  description: t('activity:suggestions:press_here_description_other_neighborhood'),
              };

    return (
        <TouchableOpacity
            style={{
                padding: screenMargin,
                borderRadius: 12,
                borderWidth: 1,
                borderColor: theme.lightGrey,
                gap: screenMargin,
                flexDirection: 'row',
                alignItems: 'center',
            }}
            onPress={onPress}
        >
            <View
                style={{
                    borderRadius: 8,
                    overflow: 'hidden',
                    width: 64,
                    height: 64,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: theme.lightGreen,
                }}
            >
                <Svg
                    style={{
                        padding: (64 - 36) / 2,
                        zIndex: 1,
                    }}
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                >
                    <Path
                        d="M15 26.4879V30C15 31.6569 16.3431 33 18 33C19.6569 33 21 31.6569 21 30V26.4879M18 3V4.5M4.5 18H3M8.25 8.25L7.34985 7.34985M27.75 8.25L28.6504 7.34985M33 18H31.5M27 18C27 22.9706 22.9706 27 18 27C13.0294 27 9 22.9706 9 18C9 13.0294 13.0294 9 18 9C22.9706 9 27 13.0294 27 18Z"
                        stroke={theme.main}
                        strokeWidth={3}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </Svg>
            </View>
            <View style={{ gap: smallestMargin, flex: 1 }}>
                <HeimeText style={{ textAlign: 'left' }} variant="title">
                    {strings.title}
                </HeimeText>
                <HeimeText variant="subtitle">{strings.description}</HeimeText>
            </View>
        </TouchableOpacity>
    );
};

export default PressHere;
