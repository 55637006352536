import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useGetSupports } from '_api/useSupports';
import _fonts from '_fonts';
import useAppNavigation from '_navigator/hooks/useAppNavigation';
import { Theme, useThemeStyle, WW } from '_utils';
import useBottomSpacer from '_utils/hooks/useBottomSpacer';
import useDebounceValue from '_utils/hooks/useDebounceValue';
import { flattenIniniteResult } from '_utils/misc';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { QueryView, Loader, ExpandableSearchInput, QueryItemView } from 'Components';
import { ArrayElement } from 'types/Utility';
import { ReportItem } from './components';

interface ReportsListProps {
    filter: 'open' | 'closed' | null;
    publicAndOwn?: boolean;
}

const ReportsList = ({ filter, publicAndOwn = false }: ReportsListProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const [searchedText, setSearchedText] = useState<string>('');
    useDebounceValue(searchedText, 350);
    const { data, isLoading, isError, error, refetch, fetchNextPage, isFetching, isFetchingNextPage } = useGetSupports(
        filter,
        publicAndOwn,
        searchedText,
    );
    const { navigate } = useAppNavigation();
    const paddingBottom = useBottomSpacer();

    const reports = useMemo(() => {
        return flattenIniniteResult(data);
    }, [data]);

    const handleSearchTextChange = useCallback((newStr: string) => setSearchedText(newStr), []);

    const renderReports = ({ item }: { item: ArrayElement<typeof reports> }) => {
        return (
            <TouchableOpacity
                onPress={() => {
                    navigate('ReportSelected', { supportId: item.id });
                }}
            >
                <ReportItem
                    id={item.id}
                    status={item.status}
                    title={item.title}
                    content={item.content}
                    creator={item.creator}
                    createdAt={item.created_at}
                />
            </TouchableOpacity>
        );
    };

    if (isError) {
        throw error;
    }

    return (
        <QueryItemView virtualized containerStyle={{ paddingBottom }}>
            <View style={themedStyle.searchContainer}>
                <ExpandableSearchInput
                    value={searchedText}
                    onChange={handleSearchTextChange}
                    buttonText={t('reports:search')}
                />
            </View>
            <QueryView
                data={reports}
                renderItem={renderReports}
                onRefresh={refetch}
                isRefreshing={isFetching}
                loadMore={fetchNextPage}
                isLoadingMore={isFetchingNextPage}
                style={themedStyle.container}
                emptyList={
                    reports.length === 0 && isLoading ? (
                        <Loader bgColor="lightBackground" />
                    ) : (
                        <View style={themedStyle.emptyContainer}>
                            <Text style={themedStyle.emptyText}>
                                {t(searchedText ? 'reports:noSearchResult' : 'reports:noReports')}
                            </Text>
                        </View>
                    )
                }
            />
        </QueryItemView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        searchContainer: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            marginTop: screenMargin,
        },
        emptyContainer: {
            marginTop: WW * 0.1,
            paddingTop: WW * 0.05,
            paddingBottom: WW * 0.05,
            paddingLeft: WW * 0.05,
            paddingRight: WW * 0.05,
            alignSelf: 'center',
            backgroundColor: theme.chatMyMessageBubble,
            borderRadius: 15,
        },
        emptyText: {
            fontFamily: _fonts.primaryFont,
        },
        container: {
            gap: smallestMargin,
        },
    });

export default ReportsList;
