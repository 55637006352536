import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'Components';

interface DeleteMessageModalProps {
    onDismiss(): void;
    onDelete(): void;
}

const DeleteCommentModal = ({ onDismiss, onDelete }: DeleteMessageModalProps): ReactElement => {
    const { t } = useTranslation();
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDelete = async () => {
        setIsDeleting(true);
        onDelete();
    };

    return (
        <Modal
            onDismiss={onDismiss}
            header={t('sharingSelected:deleteCommentModal:header')}
            buttons={[
                { text: t('sharingSelected:deleteCommentModal:back'), onPress: onDismiss },
                {
                    text: t('sharingSelected:deleteCommentModal:approve'),
                    onPress: handleDelete,
                    type: 'danger',
                    isLoading: isDeleting,
                },
            ]}
        />
    );
};

export default DeleteCommentModal;
