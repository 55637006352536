import React, { ComponentProps, ReactElement, useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { useSendMessage } from '_api/useChats';
import { flattenIniniteResult } from '_utils/misc';
import { FileUpload, isFileToBeUploaded } from 'types/Utility';
import { SetAdminModal, MembersList } from './components';
import { Group, useEditGroup, useGetGroup, useGetGroupFiles, useJoinGroup } from '../../_api/useGroups';
import { useOwnProfile } from '../../_api/useProfile';
import { SafeAreaView } from '../../_dependencies/safeArea';
import _fonts from '../../_fonts';
import Images from '../../_images';
import { useAppNavigation, useContextMenuFocusEffect } from '../../_navigator';
import { isAppError, isNetworkError, showToast, Theme, useThemeStyle, WW } from '../../_utils';
import { useUserInUserCollection } from '../../_utils/hooks';
import { screenMargin, smallestMargin, subtitleFontSize, titleFontSize } from '../../_utils/sizes';
import {
    Loader,
    PrimaryButton,
    QueryItemView,
    SecondaryButton,
    ReadMore,
    HeaderWithNav,
    FullWidthPictureCarousel,
    CacheImage,
    HeimeText,
    Tabs,
    MediaListView,
    Modal,
    FileSelector,
    NotFoundErrorScreen,
    BottomSpacer,
} from '../../Components';

const GroupSelected = ({ route }: { route: { params: { groupId: number; isOnboarding?: boolean } } }): ReactElement => {
    const groupId = route?.params?.groupId;
    const isOnboarding = route.params.isOnboarding ?? false;
    if (!groupId) {
        throw new Error('No group id received in params');
    }
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    useContextMenuFocusEffect(['groupNew']);
    const {
        data: group,
        isLoading,
        isFetching: isRefetchingGroup,
        refetch: refetchGroup,
        isError,
        error,
    } = useGetGroup(groupId);
    const { isFetching: isRefetchingFiles, refetch: refetchFiles } = useGetGroupFiles(groupId);
    const refetch = () => {
        refetchGroup();
        refetchFiles();
    };
    const { mutate: joinGroup, isPending: isJoiningGroup } = useJoinGroup();
    const { mutate: editGroup, isPending: isSettingGroupAdmin } = useEditGroup();
    const userIsMember = useUserInUserCollection();

    const [adminModalOpen, setAdminModalOpen] = useState<boolean | number>(false);

    const [selectedUser, setSelectedUser] = useState<{ id: number; name: string } | undefined>(undefined);

    const handleCreateActivityPress = () => navigate('ActivityNew', { groups: [group?.id ?? 0] });
    const handleJoinGroupPress = () => {
        joinGroup(groupId, {
            onSuccess: () => {
                refetch();
            },
        });
    };
    const handleEditPress = () => {
        navigate('GroupNew', { group: group });
    };
    const openChat = () => {
        navigate('ChatSelected', { chatId: `${group?.chat_room_id}` });
    };

    const handleRegretSetAdmin = () => setAdminModalOpen(false);
    const handleAdminModal = (user: { id: number; name: string }) => {
        setSelectedUser(user);
        setAdminModalOpen(true);
    };

    const handleSetAdminConfirmClick = async (user: { id: number; name: string }) => {
        if (user && group) {
            editGroup(
                [
                    groupId,
                    {
                        name: group.name,
                        description: group?.description,
                        admins: [...group.admins.map((admin) => admin.id), user.id],
                        cooperative_group_id: group.cooperative_group_id,
                    },
                ],
                {
                    onSuccess: () => {
                        setAdminModalOpen(false);
                        refetch();
                    },
                    onError: () => {
                        setAdminModalOpen(false);
                    },
                },
            );
        } else {
            throw new Error(
                'User or Group was undefined in handleSetAdminConfirmClick, user:' + user?.id + 'group:' + group?.id,
            );
        }
    };

    if (isError) {
        if (isAppError(error) && error.response?.status === 404) {
            return <NotFoundErrorScreen type="Group" />;
        } else {
            throw error;
        }
    }

    let content = null;
    if (isLoading || !group) {
        content = <Loader />;
    } else {
        const isMember = userIsMember(group.users);
        const canEdit = group.is_admin && !isOnboarding;

        content = (
            <>
                <HeaderWithNav
                    action={
                        canEdit ? (
                            <TouchableOpacity onPress={handleEditPress}>
                                <CacheImage resizeMode={'contain'} source={Images.edit} style={themedStyle.editIcon} />
                            </TouchableOpacity>
                        ) : undefined
                    }
                    titleLeft
                    title={t('group:groupSelected:title')}
                    safeArea
                />
                <QueryItemView
                    isRefreshing={isRefetchingFiles || isRefetchingGroup}
                    onRefresh={refetch}
                    style={themedStyle.container}
                    virtualized
                >
                    <FullWidthPictureCarousel
                        onEditPlaceholder={canEdit ? handleEditPress : undefined}
                        pictures={group.pictures}
                    />
                    <View style={[themedStyle.container, themedStyle.textContainer]}>
                        <HeimeText maxFontSizeMultiplier={2} style={themedStyle.title}>
                            {group.name}
                        </HeimeText>
                        <ReadMore
                            textStyle={themedStyle.subTitle}
                            maxFontSizeMultiplier={2}
                            numberOfLines={4}
                            text={group.description}
                        />
                    </View>
                    {isMember && !isOnboarding ? (
                        <MemberDetails group={group} onAdmin={handleAdminModal} />
                    ) : group.users.length > 0 ? (
                        <>
                            <View style={themedStyle.memberTitle}>
                                <HeimeText style={themedStyle.titleSmall}>{t('group:groupSelected:members')}</HeimeText>
                                <HeimeText style={themedStyle.subTitleSmall}>{`(${group.users.length})`}</HeimeText>
                            </View>
                            <GroupMemberList group={group} showActionButton={!isOnboarding} />
                        </>
                    ) : null}
                </QueryItemView>

                {isMember && !isOnboarding && (
                    <SecondaryButton
                        style={themedStyle.secondaryButton}
                        text={t('group:groupSelected:open_chat').toUpperCase()}
                        onPress={openChat}
                    />
                )}
                {isMember ? (
                    isOnboarding ? null : (
                        <PrimaryButton
                            text={t('group:groupSelected:create_activity').toUpperCase()}
                            onPress={handleCreateActivityPress}
                            bottomAction
                        />
                    )
                ) : (
                    <PrimaryButton
                        text={t('group:groupSelected:join_group').toUpperCase()}
                        onPress={handleJoinGroupPress}
                        status={isJoiningGroup ? 'loading' : null}
                        bottomAction
                    />
                )}
                {adminModalOpen
                    ? selectedUser && (
                          <SetAdminModal
                              user={{ id: selectedUser?.id, name: selectedUser?.name }}
                              onDismiss={handleRegretSetAdmin}
                              onSetAdmin={handleSetAdminConfirmClick}
                              isSetting={isSettingGroupAdmin}
                          />
                      )
                    : null}
                {isOnboarding ? null : <BottomSpacer />}
            </>
        );
    }

    if (isOnboarding) {
        return (
            <SafeAreaView style={themedStyle.main} edges={['left', 'right']}>
                {content}
            </SafeAreaView>
        );
    }

    return <View style={themedStyle.main}>{content}</View>;
};

interface MemberDetailsProps {
    group: Group;
    onAdmin: (user: { id: number; name: string }) => void;
}
const MemberDetails = ({ group, onAdmin }: MemberDetailsProps) => {
    const { data, isLoading, isError, error, ...rest } = useGetGroupFiles(group.id);
    const [showingAddModal, setShowingAddModal] = useState<'files' | 'media' | false>(false);
    const { t } = useTranslation();

    const getHandleAdd = (arg: 'files' | 'media' | false) => () => {
        setShowingAddModal(arg);
    };

    const handleClose = () => {
        setShowingAddModal(false);
    };

    if (isError) {
        throw error;
    }

    const files = flattenIniniteResult(data);

    return (
        <>
            <Tabs
                tabs={[
                    {
                        title: t('group:groupSelected:members'),
                        content: <GroupMemberList group={group} onSetAdmin={onAdmin} />,
                    },
                    {
                        title: t('group:groupSelected:images'),
                        content: isLoading ? (
                            <Loader />
                        ) : (
                            <MediaListView
                                onAdd={getHandleAdd('media')}
                                emptyText={t('group:groupSelected:no_images')}
                                type="media"
                                files={files}
                                {...rest}
                            />
                        ),
                    },
                    {
                        title: t('group:groupSelected:files'),
                        content: isLoading ? (
                            <Loader />
                        ) : (
                            <MediaListView
                                onAdd={getHandleAdd('files')}
                                emptyText={t('group:groupSelected:no_files')}
                                type="files"
                                files={files}
                                {...rest}
                            />
                        ),
                    },
                ]}
            />
            {showingAddModal ? (
                <AddFilesModal chatId={group.chat_room_id} onClose={handleClose} type={showingAddModal} />
            ) : null}
        </>
    );
};

interface AddFilesModalProps {
    onClose: () => void;
    type: 'media' | 'files';
    chatId: number;
}
const AddFilesModal = ({ onClose, chatId, type }: AddFilesModalProps) => {
    const [files, setFiles] = useState<FileUpload[]>([]);
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    const { mutate: sendMessage, isPending: isLoading } = useSendMessage({
        retry: (failureCount: number, error: AxiosError | unknown) => {
            if (isAppError(error) && isNetworkError(error)) {
                return failureCount < 3;
            }
            return false;
        },
        retryDelay: (failureCount: number) => {
            return failureCount * 1000;
        },
    });

    const handleSendMessage = () => {
        sendMessage(
            [
                chatId,
                {
                    text: '',
                    files: files.filter(isFileToBeUploaded),
                },
            ],
            {
                onError: (e) => {
                    const isNetworkProblem = isAppError(e) && isNetworkError(e);
                    showToast({
                        type: 'error',
                        header: isNetworkProblem ? t('global:networkError_header') : t('global:error_sending_header'),
                        text: isNetworkProblem
                            ? t('global:networkError_description')
                            : t('global:error_sending_description'),
                        position: 'top',
                    });
                },
                onSuccess: onClose,
            },
        );
    };

    return (
        <Modal
            header={t('group:groupSelected:add_files')}
            onDismiss={onClose}
            content={
                <>
                    <HeimeText style={themedStyle.uploadExplainer}>
                        {t('group:groupSelected:files_description')}
                    </HeimeText>
                    <FileSelector
                        type={type === 'media' ? 'image' : 'file'}
                        openInitially
                        files={files}
                        onChange={setFiles}
                        filesPosition="bottom"
                    />
                </>
            }
            buttons={[
                {
                    text: t('group:groupSelected:upload'),
                    isDisabled: files.length === 0,
                    isLoading: isLoading,
                    onPress: handleSendMessage,
                },
            ]}
        />
    );
};

const GroupMemberList = ({
    group,
    ...props
}: Omit<ComponentProps<typeof MembersList>, 'users' | 'admins' | 'ownId' | 'groupId'> & { group: Group }) => {
    const ownId = useOwnProfile()?.data?.id ?? 0;

    return <MembersList users={group.users} admins={group.admins} ownId={ownId} groupId={group.id} {...props} />;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
        secondaryButton: { marginLeft: screenMargin, marginRight: screenMargin, marginBottom: smallestMargin },
        container: {
            paddingTop: 0,
            paddingBottom: screenMargin,
        },
        textContainer: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            paddingBottom: 0,
        },
        title: {
            marginTop: subtitleFontSize,
            marginBottom: subtitleFontSize,
            fontFamily: _fonts.primaryFontBold,
            fontSize: titleFontSize,
            color: theme.darkGray,
        },
        memberTitle: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: smallestMargin,
            marginLeft: screenMargin,
            marginRight: screenMargin,
        },
        subTitle: {
            fontSize: subtitleFontSize,
            color: theme.secondaryText,
        },
        titleSmall: {
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            color: theme.darkGray,
        },
        subTitleSmall: {
            fontSize: subtitleFontSize,
            color: theme.secondaryText,
        },
        editIcon: {
            width: WW * 0.08,
            height: WW * 0.08,
            marginRight: screenMargin,
            marginTop: screenMargin / 2,
            marginBottom: screenMargin / 2,
        },
        uploadExplainer: { fontSize: subtitleFontSize, textAlign: 'center', marginBottom: screenMargin },
    });

export { GroupSelected as GroupDetail };
