import React, { ReactElement } from 'react';
import { DateTimePickerEvent } from '@react-native-community/datetimepicker';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { isiOS } from '../../../../../_utils';
import { DateTimePickerModal } from '../../../../../Components';

interface ModalDatePickerProps {
    value: Moment;
    onChange(newDate: Date): void;
    onClose(): void;
    minimumDate?: Date | undefined;
    maximumDate?: Date | undefined;
    display?: 'calendar' | 'spinner';
}

const ModalDatePicker = ({
    value,
    onChange,
    onClose,
    minimumDate,
    maximumDate,
    display = 'calendar',
}: ModalDatePickerProps): ReactElement => {
    const { t } = useTranslation();
    const handleChangeDate = (newDate: Date | undefined) => {
        if (newDate) {
            onChange(newDate);
        }
    };

    const handleConfirm = (date: Date) => {
        onClose();
        if (date) {
            handleChangeDate(date);
        }
    };

    return (
        <DateTimePickerModal
            isVisible
            mode="date"
            display={
                isiOS()
                    ? display === 'calendar'
                        ? ('inline' as 'default')
                        : 'spinner'
                    : display === 'spinner'
                      ? display
                      : 'default'
            }
            onConfirm={handleConfirm}
            onChange={
                handleChangeDate as ((d: Date) => void) & ((d: DateTimePickerEvent, da?: Date | undefined) => void)
            }
            onCancel={onClose}
            date={value.toDate()}
            minimumDate={minimumDate}
            maximumDate={maximumDate}
            cancelTextIOS={t('global:cancel')}
            confirmTextIOS={t('global:confirm')}
            locale="nb"
        />
    );
};

export default ModalDatePicker;
