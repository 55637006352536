import React, { ReactElement } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useCreateRating } from '../_api/useRatings';
import { showToast, useStarAvailableToGive } from '../_utils';
import { FormInput, Modal } from '../Components';

const schema = z.object({
    message: z.string().optional(),
});

export type FormValues = z.infer<typeof schema>;

interface SendFeedbackProps {
    onDismiss(): void;
    userId: number;
    header: string;
}

const SendFeedbackModal = ({ onDismiss, userId, header }: SendFeedbackProps): ReactElement => {
    const { t } = useTranslation();
    const { mutate: createRating, isPending: isLoading } = useCreateRating();
    const form = useForm<FormValues>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: { message: '' },
    });

    const starAvailable = useStarAvailableToGive();
    const hasError = !form.formState.isValid;

    const handleConfirmClick = (value: FormValues) => {
        createRating(
            { text: value.message, user_id: userId },
            {
                onSuccess: () => {
                    onDismiss();
                },
                onError: () => {
                    showToast({
                        header: t('global:task_failed_header'),
                        text: t('global:task_failed_description'),
                        type: 'error',
                    });
                },
            },
        );
    };

    return (
        <Modal
            onDismiss={onDismiss}
            header={header}
            content={
                <FormProvider {...form}>
                    <FormInput
                        label={t('sharingSelected:helperProfile:comment')}
                        name="message"
                        placeholder={t('sharingSelected:contactNeighbor:placeHolderMessage')}
                        autoFocus
                        optional
                        multiline
                    />
                </FormProvider>
            }
            buttons={[
                {
                    isLoading: isLoading,
                    isDisabled: hasError || starAvailable > 0,
                    text:
                        starAvailable <= 0
                            ? t('sharingSelected:contactNeighbor:button').toUpperCase()
                            : t('sharingSelected:helperProfile:willBeAvailable', {
                                  count: starAvailable,
                              }).toUpperCase(),
                    onPress: form.handleSubmit(handleConfirmClick),
                },
            ]}
        />
    );
};

export default SendFeedbackModal;
