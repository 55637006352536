import { useState, useEffect } from 'react';

const useVisualRefetchState = (isRefreshing: boolean, onRefresh: () => void): [boolean, () => void] => {
    const [refreshing, setRefreshing] = useState(false);

    useEffect(() => {
        if (!isRefreshing) {
            setRefreshing(false);
        }
    }, [isRefreshing]);

    const handleRefresh = () => {
        setRefreshing(true);
        onRefresh();
    };

    return [refreshing, handleRefresh];
};

export default useVisualRefetchState;
