import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface loggedInState {
    isLoggedIn: boolean;
    access_token: string;
    refresh_token: string;
    expires_in: DurationInSeconds;
}

type SettingsState = {
    sessionInfo: loggedInState | null;
};
type DurationInSeconds = number;

const initialState: SettingsState = {
    sessionInfo: null,
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        reset: () => {
            return initialState;
        },
        setSessionInfo: (state, action: PayloadAction<loggedInState>) => {
            state.sessionInfo = action.payload;
        },
    },
});

export const { reset, setSessionInfo } = settingsSlice.actions;

export default settingsSlice.reducer;
