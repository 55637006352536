import moment from 'moment';

/**
 * Returns date string based on the dates(if two provided) relation and relation to the current time.
 * @param first
 * @param second
 */
const getRelatedDateString = (first: Date, second?: Date): [string, string] => {
    const firstMoment = moment(first);
    const now = moment();

    const isToday = firstMoment.isSame(now, 'day');
    const isThisMonth = firstMoment.isSame(now, 'month');
    const isThisYear = firstMoment.isSame(now, 'year');
    const isNextWeek = firstMoment.isBetween(now, now.clone().add(1, 'week'));

    let firstString = 'HH:mm';

    if (!isThisYear) {
        firstString = 'YYYY ' + firstString;
    }

    if (!isThisMonth) {
        firstString = 'MMM ' + firstString;
    }

    if (!isToday) {
        firstString = 'DD. ' + firstString;
        if (isNextWeek) {
            firstString = 'dddd ' + firstString;
        }
    }

    if (!second) {
        return [firstMoment.format(firstString), ''];
    }

    const secondMoment = moment(second);
    const isSameDay = firstMoment.isSame(secondMoment, 'day');
    const isSameMonth = firstMoment.isSame(secondMoment, 'month');
    const isSameYear = firstMoment.isSame(secondMoment, 'year');
    let secondString = 'HH:mm';

    if (!isSameDay) {
        secondString = 'DD. ' + secondString;
        if (isNextWeek) {
            secondString = 'dddd ' + secondString;
        }
    }

    if (!isSameMonth) {
        secondString = 'MMM ' + secondString;
    }

    if (!isSameYear) {
        secondString = 'YYYY ' + secondString;
    }

    return [firstMoment.format(firstString), secondMoment.format(secondString)];
};

export default getRelatedDateString;
