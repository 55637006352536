import React, { ReactElement, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, Text, StyleSheet, BackHandler } from 'react-native';
import { Menu, MenuTrigger, MenuOptions, MenuOption } from 'react-native-popup-menu';
import { Loader } from '.';
import _fonts from '../_fonts';
import { useThemeStyle, WW } from '../_utils';
import { screenMargin } from '../_utils/sizes';
import { Theme, ThemeContext } from '../_utils/themeContext';

interface ContextMenuProps {
    onToggle(): void;
    actions: { loading?: boolean; onPress(): void; text: string; type?: 'danger' | 'normal' }[];
    isOpen: boolean;
    trigger: ReactElement;
}
const ContextMenu = ({ onToggle, actions, isOpen, trigger }: ContextMenuProps): ReactElement => {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext);
    const themedStyle = useThemeStyle(styles);
    useEffect(() => {
        if (isOpen) {
            const handler = BackHandler.addEventListener('hardwareBackPress', () => {
                onToggle();
                return true;
            });
            return () => handler.remove();
        }
    }, [isOpen, onToggle]);

    return (
        <Menu opened={isOpen} onBackdropPress={onToggle}>
            <MenuTrigger
                customStyles={{
                    triggerOuterWrapper: { marginTop: 'auto', marginBottom: 'auto' },
                }}
                onPress={onToggle}
            >
                {trigger}
            </MenuTrigger>
            <MenuOptions customStyles={{ optionsWrapper: { backgroundColor: theme.mainBackground } }}>
                <MenuOption disableTouchable>
                    <TouchableOpacity
                        accessibilityLabel={t('global:close')}
                        onPress={onToggle}
                        style={themedStyle.closeIconWrapper}
                    >
                        <Text style={themedStyle.closeIcon}>{'+'}</Text>
                    </TouchableOpacity>
                </MenuOption>

                {actions.map((button, i) => (
                    <MenuOption
                        key={button.text}
                        onSelect={button.onPress}
                        text={button.loading ? undefined : button.text}
                        children={button.loading ? <Loader /> : undefined}
                        customStyles={{
                            optionText: {
                                color: button.type === 'danger' ? theme.error : theme.main,
                                fontSize: WW * 0.04,
                            },
                            optionWrapper: {
                                paddingLeft: screenMargin,
                                paddingRight: screenMargin,
                                paddingTop: (screenMargin * 2) / 3,
                                paddingBottom: (screenMargin * 2) / 3,
                                borderBottomColor: theme.lightGrey,
                                borderBottomWidth: i !== actions.length - 1 ? 1 : 0,
                                minHeight: 40,
                            },
                        }}
                    />
                ))}
            </MenuOptions>
        </Menu>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        closeIconWrapper: { alignSelf: 'flex-end' },
        closeIcon: {
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            paddingTop: WW * 0.02,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.03,
            transform: [{ rotate: '45deg' }, { scale: 2 }],
            color: theme.main,
        },
    });

export default ContextMenu;
