import React, { ComponentProps, PropsWithChildren, ReactElement, useCallback, useContext } from 'react';
import {
    ActivityIndicator,
    RefreshControl,
    ScrollView,
    SectionList,
    StyleProp,
    StyleSheet,
    View,
    ViewStyle,
} from 'react-native';
import useVisualRefetchState from '_utils/hooks/useVisualRefetchState';
import { isWeb, Theme, useThemeStyle, WH } from '../../_utils';
import { ThemeContext } from '../../_utils/themeContext';

interface QueryItemBaseProps extends Pick<ComponentProps<typeof ScrollView>, 'keyboardShouldPersistTaps'> {
    onRefresh?: () => void;
    isRefreshing?: boolean;
    style?: StyleProp<ViewStyle>;
    containerStyle?: StyleProp<ViewStyle>;
    /**
     * Apply some default styling
     */
    styled?: boolean;
    itemRef?: React.MutableRefObject<{ scrollToEnd(): void } | undefined>;
    scrollEnabled?: boolean;
}

interface VirtualizedProps {
    virtualized: true;
    loadMore?: () => void;
    isLoadingMore?: boolean;
}

interface NonVirtualizedProps {
    virtualized?: false;
    loadMore?: undefined;
    isLoadingMore?: undefined;
    listKey?: undefined;
}

type QueryItemViewProps = QueryItemBaseProps & (VirtualizedProps | NonVirtualizedProps);

const QueryItemView = ({
    isRefreshing = false,
    onRefresh,
    style,
    containerStyle,
    styled,
    children,
    virtualized = false,
    keyboardShouldPersistTaps,
    itemRef,
    loadMore,
    isLoadingMore,
    scrollEnabled,
}: PropsWithChildren<QueryItemViewProps>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const [refreshing, handleRefresh] = useVisualRefetchState(isRefreshing, onRefresh ?? (() => {}));

    const props = {
        style: [styled ? themedStyle.styledMain : undefined, themedStyle.main, style],
        contentContainerStyle: [styled ? themedStyle.styledContentContainer : undefined, containerStyle],
        refreshControl: onRefresh ? <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} /> : undefined,
        scrollEnabled,
    };

    const sectionListRef = useCallback(
        (sectionList: SectionList) => {
            if (itemRef) {
                if (isWeb()) {
                    itemRef.current = {
                        scrollToEnd: () => {
                            const node = sectionList?.getScrollableNode?.();
                            if (node) {
                                // @ts-ignore // No way to type this, we are using web only code
                                node.scrollTop = node.scrollHeight;
                            }
                        },
                    };
                } else {
                    itemRef.current = {
                        scrollToEnd: () => sectionList?.getScrollResponder?.()?.scrollToEnd(),
                    };
                }
            }
        },
        [itemRef],
    );
    const scrollViewRef = useCallback(
        (scrollView: ScrollView) => {
            if (itemRef) {
                itemRef.current = { scrollToEnd: () => scrollView.scrollToEnd() };
            }
        },
        [itemRef],
    );

    if (virtualized) {
        const footerComponent = isLoadingMore ? <ActivityIndicator color={theme.black} /> : <View />;

        const handleOnEndReached = loadMore ? () => loadMore() : undefined;

        return (
            <SectionList
                keyboardShouldPersistTaps={keyboardShouldPersistTaps}
                removeClippedSubviews={false}
                {...props}
                ListHeaderComponent={<>{children}</>}
                sections={[{ data: [{}] }]}
                renderItem={() => <></>}
                ref={sectionListRef}
                onEndReached={handleOnEndReached}
                ListFooterComponent={footerComponent}
                onEndReachedThreshold={0.5}
            />
        );
    }

    return (
        <ScrollView
            keyboardShouldPersistTaps={keyboardShouldPersistTaps}
            ref={scrollViewRef}
            removeClippedSubviews={false}
            {...props}
        >
            {children}
        </ScrollView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },
        styledMain: {
            backgroundColor: theme.shadow,
        },
        styledContentContainer: { paddingTop: WH * 0.01, paddingBottom: WH * 0.01 },
    });

export default QueryItemView;
