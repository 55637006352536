import { useEffect } from 'react';
import {
    InfiniteData,
    QueryClient,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { Activity, getActivityKey, invalidateActivitiesQuery } from './useActivities';
import { useLaravelInfinteQuery } from './utility';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import { ExternalUserProfile } from '../types/User';
import { LaravelPagingResponse } from '../types/Utility';

const getSignedUpUsersKey = (selectedCoopId: number, activityId: number) => [
    'signedupUsers',
    selectedCoopId,
    activityId,
];

interface SignedUpUser extends ExternalUserProfile {
    approved_sum: number;
}

const useGetSignedupUsers = (
    activityId: number,
    automaticallyLoadRest: boolean,
): UseInfiniteQueryResult<InfiniteData<SignedUpUser[]>, string | Error> => {
    const selectedCoopId = useSelectedCoop();
    const query = useLaravelInfinteQuery(
        getSignedUpUsersKey(selectedCoopId, activityId),
        async ({ pageParam = 1, getAuthHeader }) =>
            await axios.get<LaravelPagingResponse<SignedUpUser[]>>(
                `cooperatives/${selectedCoopId}/activities/${activityId}/approved_users`,
                {
                    headers: { authorization: await getAuthHeader() },
                    params: { page: pageParam },
                },
            ),
        {
            gcTime: 1000 * 60 * 60,
            staleTime: 1000 * 60 * 1,
        },
    );

    useEffect(() => {
        if (automaticallyLoadRest && query.hasNextPage && !query.isFetchingNextPage) {
            query.fetchNextPage();
        }
    }, [automaticallyLoadRest, query]);

    return query;
};

const updateActivityQuery = (
    queryClient: QueryClient,
    selectedCoopId: number,
    activityId: number,
    quantity: number,
) => {
    queryClient.setQueryData<Activity | undefined>(getActivityKey(selectedCoopId, activityId), (data) => {
        if (data) {
            return { ...data, approved: quantity !== 0, approved_sum: quantity };
        }
        return undefined;
    });
};

const useSignUpForActivity = (): UseMutationResult<
    { success: number; redirect?: string; transaction_id?: string },
    string | Error,
    [number, number]
> => {
    const queryClient = useQueryClient();
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    return useMutation({
        mutationFn: async ([activityId, quantity]) => {
            const headers = { authorization: await getAuthHeader() };

            const addReponse = await axios.post<{ success: number; redirect?: string; transaction_id?: string }>(
                `cooperatives/${selectedCoopId}/activities/${activityId}/add`,
                {
                    quantity,
                },
                { headers },
            );
            if (!addReponse.data.success) {
                throw new Error('signing up for activity failed. Response:' + JSON.stringify(addReponse.data));
            }
            return addReponse.data;
        },
        onSettled: () => {
            invalidateActivitiesQuery(queryClient);
        },
        onSuccess: (params, [activityId, quantity]) => {
            queryClient.invalidateQueries({ queryKey: getSignedUpUsersKey(selectedCoopId, activityId) });
            updateActivityQuery(queryClient, selectedCoopId, activityId, quantity);
        },
    });
};

const useChangeSignUpForActivity = (): UseMutationResult<
    { success: number; redirect?: string; transaction_id?: string },
    string | Error,
    [number, number]
> => {
    const queryClient = useQueryClient();
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    return useMutation({
        mutationFn: async ([activityId, quantity]) => {
            const headers = { authorization: await getAuthHeader() };

            const changeResponse = await axios.patch<{ success: number }>(
                `cooperatives/${selectedCoopId}/activities/${activityId}/change`,
                {
                    quantity,
                },
                { headers },
            );
            if (!changeResponse.data.success) {
                throw new Error(
                    'Changing signup up for activity failed. Response:' + JSON.stringify(changeResponse.data),
                );
            }
            return changeResponse.data;
        },
        onSettled: () => {
            invalidateActivitiesQuery(queryClient);
        },
        onSuccess: (params, [activityId, quantity]) => {
            queryClient.invalidateQueries({ queryKey: getSignedUpUsersKey(selectedCoopId, activityId) });
            updateActivityQuery(queryClient, selectedCoopId, activityId, quantity);
        },
    });
};

export { useGetSignedupUsers, useSignUpForActivity, useChangeSignUpForActivity };
