import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnixTimeCode } from '../types/Utility';

export type TimeSync = { appStarts: UnixTimeCode[]; appStartsError: boolean };

const initialState: TimeSync = { appStarts: [], appStartsError: false };

const addProfileModalSlice = createSlice({
    name: 'timeSync',
    initialState,
    reducers: {
        registerAppStart: (state, action: PayloadAction<UnixTimeCode>) => {
            if (state.appStarts.length) {
                const prevVal = state.appStarts[state.appStarts.length - 1];
                if (action.payload < prevVal) {
                    state.appStartsError = true;
                } else {
                    state.appStarts = [action.payload];
                }
            } else {
                state.appStarts = [action.payload];
            }
        },
        resetAppStarts: (state) => {
            state.appStarts = [];
            state.appStartsError = false;
        },
    },
});

export const { registerAppStart, resetAppStarts } = addProfileModalSlice.actions;

export default addProfileModalSlice.reducer;
