import React, { ReactElement } from 'react';
import { StyleSheet, TouchableWithoutFeedback } from 'react-native';
import Images from '../../../../../../_images';
import { Theme, useThemeStyle, WW } from '../../../../../../_utils';
import { CacheImage } from '../../../../../../Components';
import { ExternalUserProfile } from '../../../../../../types/User';

interface ProfileAvatarProps {
    profile: Pick<ExternalUserProfile, 'avatar'>;
}

const ProfileAvatar = ({ profile }: ProfileAvatarProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <TouchableWithoutFeedback style={themedStyle.avatarWrapper}>
            <CacheImage source={profile?.avatar ?? Images.defaultAvatar} style={themedStyle.avatar} />
        </TouchableWithoutFeedback>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        avatarWrapper: {
            borderRadius: (WW * 0.25) / 2,
            width: WW * 0.25,
            height: WW * 0.25,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: theme.lightGreen,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
        },
        avatar: {
            width: WW * 0.25,
            height: WW * 0.25,
            borderRadius: (WW * 0.25) / 2,
            overflow: 'hidden',
        },
    });

export default ProfileAvatar;
