import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useProlongMessage } from '_api/useMessages';
import { useAppNavigation } from '_navigator';
import { showToast } from '_utils';
import { Notification } from 'types/Notification';
import NotificationItem from './NotificationItem';

const MessageExpiredItem = ({
    active_until,
    message_id,
    title: message_title,
    created_at,
    seen,
    ...rest
}: Extract<Notification, { type: 'MessageExpiring' | 'MessageSetExpired' }>) => {
    const { t } = useTranslation();
    const { mutate, isPending } = useProlongMessage();
    const { navigate } = useAppNavigation();

    const status = useMemo(() => {
        if (!active_until) {
            return 'never_expires';
        }
        if (moment().isBefore(moment(active_until))) {
            if (moment().add(3, 'day').isBefore(moment(active_until))) {
                return 'active';
            }
            return 'active_can_prolong';
        }
        return 'expired';
    }, [active_until]);

    const goToMessage = () => navigate('SharingSelected', { messageId: message_id });

    const handlePress = () => {
        if (status === 'active' || status === 'never_expires') {
            goToMessage();
        } else {
            mutate([message_id, moment().add(1, 'month').toISOString()], {
                onSuccess: () => {
                    showToast({
                        header:
                            status === 'active_can_prolong'
                                ? t('notifications:messageExpired:prolonged')
                                : t('notifications:messageExpired:reactivated'),
                        text: t('notifications:messageExpired:month_from_now'),
                        type: 'success',
                    });
                    goToMessage();
                },
                onError: () => {
                    showToast({
                        header: t('notifications:messageExpired:prolong_error'),
                        text: '',
                        type: 'error',
                    });
                },
            });
        }
    };

    const title = useMemo(() => {
        if (status === 'never_expires') {
            return t('notifications:messageExpired:title_no_date');
        }
        if (status === 'expired') {
            if (rest.type === 'MessageSetExpired') {
                return t('notifications:messageExpired:title_expired_author', {
                    actor_name: rest.actor_name,
                });
            }
            return t('notifications:messageExpired:title_expired');
        }

        const diff = moment.duration(moment().diff(moment(active_until), 'milliseconds'));

        if (rest.type === 'MessageSetExpired') {
            return t('notifications:messageExpired:title_expires_future_author', {
                actor_name: rest.actor_name,
                timeString: diff.humanize(),
            });
        }

        return t('notifications:messageExpired:title_expires_future', {
            timeString: diff.humanize(),
        });
    }, [active_until, rest, status, t]);

    const description = useMemo(() => {
        if (status === 'never_expires') {
            return t('notifications:messageExpired:description_no_date', {
                title: message_title,
            });
        }
        if (status === 'expired') {
            return t('notifications:messageExpired:description_expired', {
                title: message_title,
            });
        }
        if (status === 'active_can_prolong') {
            return t('notifications:messageExpired:description_prolong', {
                title: message_title,
            });
        }
        return t('notifications:messageExpired:description_no_prolong', {
            title: message_title,
        });
    }, [message_title, status, t]);

    const buttonText = useMemo(() => {
        if (status === 'never_expires' || status === 'active') {
            return t('notifications:messageExpired:seeMessage');
        }
        if (status === 'expired') {
            return t('notifications:messageExpired:reactivate');
        }
        return t('notifications:messageExpired:prolong');
    }, [status, t]);

    return (
        <NotificationItem
            title={title}
            content={description}
            createdAt={created_at}
            button={{
                onPress: handlePress,
                text: buttonText,
                icon: status === 'active_can_prolong' ? 'history' : undefined,
                status: isPending ? 'loading' : null,
            }}
            isSeen={seen}
            noLimit
        />
    );
};

export default MessageExpiredItem;
