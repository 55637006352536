import { useMemo } from 'react';
import { Cooperative } from 'types/Cooperative';

const useApartmentColumnOrder = (viewType: Cooperative['apartment_display']) =>
    useMemo(() => {
        switch (viewType) {
            case 'project_name':
                return ['project_name', 'legal_name', 'address'] as const;
            case 'legal_name':
                return ['legal_name', 'project_name', 'address'] as const;
            case 'address':
                return ['address', 'project_name', 'legal_name'] as const;
        }
        throw new Error('Invalid viewType: ' + viewType);
    }, [viewType]);

export default useApartmentColumnOrder;
