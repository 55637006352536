import React, { ComponentProps, ReactElement } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';
import ImageSelector from './ImageSelector';
import { FileUpload } from '../../types/Utility';
import ControlledInput from '../ControlledInput';

interface FormImageSelectorProps<FormType> extends Omit<ComponentProps<typeof ControlledInput>, 'images' | 'onChange'> {
    name: Path<FormType>;
}

const FormImageSelector = <FormType extends FieldValues = Record<string, string>>({
    name,
    ...imageSelectorProps
}: FormImageSelectorProps<FormType>): ReactElement => {
    const { field } = useController<FormType>({ name });

    const handleChange = (values: FileUpload[]) => {
        field.onChange(values, { shouldValidate: true });
    };

    return <ImageSelector {...imageSelectorProps} {...field} images={field.value} onChange={handleChange} />;
};

export default FormImageSelector;
