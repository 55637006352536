import { t as tImport } from 'i18next';
import { z } from 'zod';
import { TenantRole } from './Apartment';
import { ApiImage, ApiImageSchemaNoId, FileImage } from './Base';
import { GenericCollectionItemSchema, UnixTimeCode } from './Utility';

export const UserCollectionItemWithAvatarSchema = GenericCollectionItemSchema.merge(
    z.object({
        avatar: ApiImageSchemaNoId.nullable(),
        no_avatar: z.string().nullable(),
    }),
);

export type UserCollectionItemWithAvatar = z.infer<typeof UserCollectionItemWithAvatarSchema>;

interface Role {
    id: number;
    name: string;
}

interface CooperativeRole extends Role {
    cooperative_id: number;
}

export interface ExternalUserProfile {
    id: number;
    rating: number;
    email?: string;
    phone?: string;
    fname: string;
    lname: string;
    cooperative_roles: CooperativeRole[];
    cooperative_apartments?: {
        id: number;
        name: string;
        cooperative_id: number;
        cooperative_name: string;
        tenants: {
            user_id: number;
            tenant_role: TenantRole;
        }[];
        parking_spot: string | null;
    }[];
    tags?: { tag: string; contact_user_group_id: number; cooperative_id: number }[];
    gdpr: boolean;
    about_me: string;
    hide_mail: boolean;
    hide_phone: boolean;
    hide_birthday: boolean;
    birthday?: UnixTimeCode;
    avatar: ApiImage | FileImage | null;
    cooperative_name: string | null;
}

export interface OwnUserProfile extends ExternalUserProfile {
    roles: { id: number; name: string }[];
    stop_neighbor_messages_notification: boolean;
    stop_board_messages_notification: boolean;
}

export type DeletedUser = z.infer<typeof deletedUserSchema> & { avatar?: undefined } & Pick<
        ExternalUserProfile,
        'fname' | 'lname'
    >;

const withApartment = z.object({
    apartment_id: z.number(),
    apartment_name: z.string(),
    apartment_role: z.union([z.literal('owner'), z.literal('renting'), z.literal('other'), z.literal('livesWith')]),
});

const withoutApartment = z.object({
    apartment_id: z.null(),
    apartment_name: z.null(),
    apartment_role: z.null(),
});

const baseSchema = z.object({
    id: z.string(),
    user_id: z.number(),
    fname: z.string().nullable(),
    lname: z.string().nullable(),
    email: z.string().nullable(),
    phone: z.string().nullable(),
});

export const deletedUserSchema = z.union([baseSchema.merge(withApartment), baseSchema.merge(withoutApartment)]);

export const getDeletedUserFallbackName = (deletedUser: z.infer<typeof deletedUserSchema>, t: typeof tImport) => {
    return deletedUser.apartment_id
        ? t(`global:deleted_user_with_apartment_${deletedUser.apartment_role}`, {
              apartment: deletedUser.apartment_name,
          })
        : t('global:deleted_user_no_apartment');
};

export const isNonDeletedUser = (user: ExternalUserProfile | DeletedUser | null): user is ExternalUserProfile =>
    user ? ('user_id' in user ? false : true) : false;

export const cleanPhoneNumber = (phone: string) =>
    phone
        .split(/[^0-9]/g)
        .join('')
        .replace(/^00/, '');
