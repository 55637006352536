import React, { memo, ReactElement, useState } from 'react';
import Clipboard from '@react-native-clipboard/clipboard';
import { useTranslation } from 'react-i18next';
import { useDeleteMessageComment } from '_api/useMessages';
import { showToast } from '_utils';
import { FileLink } from 'types/Base';
import DeleteCommentModal from './DeleteCommentModal';
import { useGetUser } from '../../../_api/useUsers';
import { UnixTimeCode } from '../../../types/Utility';
import MessageBubble from '../../MessageBubble/MessageBubble';

interface CommentItemProps {
    content: string | undefined;
    created_at: UnixTimeCode;
    creator: number;
    files: FileLink[];
    isSelf: boolean;
    onImageClick(index: number): void;
    edited: boolean;
    deleted: boolean;
    commentId: number | null;
    messageId: number;
    onEdit?(): void;
    editable: boolean;
}

const CommentItem = ({
    content,
    creator,
    created_at,
    isSelf,
    files,
    onImageClick,
    edited,
    deleted,
    commentId,
    messageId,
    onEdit,
    editable,
}: CommentItemProps): ReactElement => {
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const { t } = useTranslation();
    const user = useGetUser(creator);
    const { mutate } = useDeleteMessageComment();

    const openDeleteModal = () => setIsDeleteModalVisible(true);
    const copyComment = () => {
        Clipboard.setString(content ?? '');
        showToast({
            type: 'success',
            header: t('sharingSelected:copiedToClipboard'),
            text: '',
            shortTime: true,
            position: 'bottom',
        });
    };

    return (
        <>
            {isDeleteModalVisible ? (
                <DeleteCommentModal
                    onDismiss={() => setIsDeleteModalVisible(false)}
                    onDelete={() => {
                        if (commentId) {
                            mutate(
                                { commentId, messageId },
                                {
                                    onSettled: () => setIsDeleteModalVisible(false),
                                },
                            );
                        }
                    }}
                />
            ) : null}
            <MessageBubble
                author={user}
                isSelf={isSelf}
                showAuthor={!isSelf}
                created_at={created_at}
                content={content ?? ''}
                displayTimeMode="full"
                files={files}
                edited={edited}
                deleted={deleted}
                deletedMessage={t('sharingSelected:deleted_comment')}
                contextActions={
                    isSelf && editable && onEdit
                        ? [
                              {
                                  text: t('sharingSelected:copy'),
                                  onPress: copyComment,
                                  icon: 'copySheets',
                              },
                              {
                                  text: t('sharingSelected:edit'),
                                  onPress: onEdit,
                                  icon: 'edit',
                              },
                              {
                                  text: t('sharingSelected:delete'),
                                  onPress: openDeleteModal,
                                  icon: 'trash',
                                  variant: 'danger',
                              },
                          ]
                        : []
                }
                onImagePress={onImageClick}
            />
        </>
    );
};

export default memo(CommentItem);
