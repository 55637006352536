import { RefObject, useMemo, useRef } from 'react';
import { NavigationContainerRef, NavigationState, ParamListBase, PartialState, Route } from '@react-navigation/native';
import { track } from '_utils/Amplitude';

interface NestedRoute extends Route<string> {
    state?: NavigationState | PartialState<NavigationState>;
}

const getActiveRouteName = (state: NavigationState | PartialState<NavigationState>): string | null => {
    if (state && 'index' in state && typeof state.index === 'number' && state.routes[state.index]) {
        const route = state.routes[state.index] as NestedRoute;
        if (route.state) {
            return getActiveRouteName(route.state);
        }
        return route.name;
    }
    return null;
};

const useTrackNavigation = (navigationRef: RefObject<NavigationContainerRef<ParamListBase>>) => {
    const routeNameRef = useRef<string | null>(null);

    return useMemo(
        () => ({
            onReady: () => {
                if (navigationRef.current) {
                    routeNameRef.current = getActiveRouteName(navigationRef.current.getRootState());
                }
            },
            onStateChange: () => {
                if (!navigationRef.current) {
                    return;
                }
                const previousRouteName = routeNameRef.current;
                const currentRouteName = getActiveRouteName(navigationRef.current.getRootState());

                if (currentRouteName && previousRouteName !== currentRouteName) {
                    track('Screen View', { screenName: currentRouteName });
                }
                routeNameRef.current = currentRouteName;
            },
        }),
        [navigationRef],
    );
};

export default useTrackNavigation;
