import { useCreateChat } from '../../_api/useChats';
import { useAppNavigation } from '../../_navigator';

const useChatWithNeighbour = (): {
    onChat(users: number, preNavigate?: () => void): void;
    isLoading: boolean;
} => {
    const { mutate, isPending: isLoading } = useCreateChat();
    const { navigate } = useAppNavigation();
    const onChat = (user: number, preNavigate?: () => void) => {
        mutate(
            { users: [user], name: null },
            {
                onSuccess: (response) => {
                    if (preNavigate) {
                        preNavigate();
                    }
                    navigate('ChatSelected', {
                        chatId: `${response.success}`,
                    });
                },
            },
        );
    };
    return { onChat, isLoading };
};

export default useChatWithNeighbour;
