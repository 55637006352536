import React, { ReactElement, useContext, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, StyleProp, ViewStyle, LayoutAnimation } from 'react-native';
import HeimeText from './HeimeText';
import { Theme, useThemeStyle, WH, WW } from '../_utils';
import { ThemeContext } from '../_utils/themeContext';

interface TabsProps {
    style?: StyleProp<ViewStyle>;
    tabs: { title: string; content: ReactElement }[];
    background?: keyof Theme;
}

const Tabs = ({ style, tabs, background = 'mainBackground' }: TabsProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const [activeTab, setActiveTab] = useState<number>(0);

    const handleSetActiveTab = (index: number) => {
        LayoutAnimation.configureNext({
            duration: 200,
            create: { type: 'spring', property: 'scaleX', springDamping: 0.8 },
        });
        setActiveTab(index);
    };

    return (
        <View style={style}>
            <View style={[themedStyle.tabsWrapper, { backgroundColor: theme[background] }]}>
                {tabs.map(({ title }, index) => (
                    <TouchableOpacity
                        key={title}
                        onPress={() => handleSetActiveTab(index)}
                        accessibilityState={{ selected: index === activeTab }}
                        style={[themedStyle.tab, index === activeTab ? themedStyle.activeTab : undefined]}
                    >
                        <HeimeText
                            style={[themedStyle.tabText, index === activeTab ? themedStyle.activeTabText : undefined]}
                        >
                            {title.toUpperCase()}
                        </HeimeText>
                    </TouchableOpacity>
                ))}
            </View>
            {tabs[activeTab].content}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        tabsWrapper: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            shadowColor: theme.black,
            shadowOffset: { height: 4, width: 0 },
            shadowOpacity: 0.1,
            zIndex: 2,
        },
        tab: {
            flex: 1,
            borderBottomWidth: 2,
            borderBottomColor: theme.lightBackground,
            paddingTop: WH * 0.02,
            paddingBottom: WH * 0.02,
        },
        tabText: {
            textAlign: 'center',
            color: theme.main,
            letterSpacing: 1,
            fontSize: WW * 0.04,
            fontWeight: 'normal',
        },
        activeTab: {
            borderBottomColor: theme.secondaryBackground,
        },
        activeTabText: {
            fontWeight: 'bold',
        },
    });

export default Tabs;
