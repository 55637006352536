import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useGlobalState from '_utils/hooks/useGlobalState';
import { reset, selectCoop } from '../_redux/selectedCoop';

/**
 * Get the selected coop. Should be used in a child component of @see SelectedCoopProvider
 * @returns id of selectedCoop
 * @throws throws render error if no selectedCoop
 */
const useSelectedCoop = (): number => {
    const value = useGlobalState((state) => state.selectedCoop);
    if (!value) {
        throw new Error('Unexpected state, no selectedCoop');
    }
    return value;
};

/**
 *
 * @returns Function that can be used to either reset selectedCoop, or select another one. If reset render will reset and let user switch
 */
const useSetSelectedCoop = (): ((id: number | null) => void) => {
    const dispatch = useDispatch();
    return useCallback(
        (id: number | null) => {
            if (id === null) {
                dispatch(reset());
            } else {
                dispatch(selectCoop(id));
            }
        },
        [dispatch],
    );
};

export { useSelectedCoop, useSetSelectedCoop };
