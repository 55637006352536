import React, { useState, ReactElement, useEffect, useContext, PropsWithChildren, useRef, ComponentProps } from 'react';
import {
    AsYouType,
    getCountries,
    getCountryCallingCode,
    PhoneNumber,
    isValidNumber,
    parsePhoneNumber,
    parsePhoneNumberWithError,
} from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { View, TextInput, StyleSheet, BackHandler, ViewStyle, StyleProp } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { getCountry } from 'react-native-localize';
import { Menu, MenuTrigger, MenuOptions, MenuOption } from 'react-native-popup-menu';
import _fonts from '_fonts';
import { isWeb, useThemeStyle, WH, WW } from '_utils';
import useGlobalState from '_utils/hooks/useGlobalState';
import { addBreadcrumb, captureException } from '_utils/Sentry';
import { screenMargin, smallestFontSize, smallestMargin } from '_utils/sizes';
import { Theme, ThemeContext } from '_utils/themeContext';
import HeimeText from 'Components/HeimeText';
import Icon from 'Components/Icon/Icon';
import InputLabel from 'Components/InputLabel';
import { useToggleBackdrop } from 'Components/MenuProvider';
import { ArrayElement } from 'types/Utility';
import { borderRadius, borderWidth, fontSize, useInputPropColors } from './formStyle';

const priorityCountries = ['no', 'se', 'dk'];
const userCountry = getCountry()?.toLocaleLowerCase();
const allCountryCodes = getCountries().map((country) => ({
    code: getCountryCallingCode(country),
    country: country.toLowerCase() as Lowercase<typeof country>,
}));
const countryCodes = [
    ...(userCountry ? allCountryCodes.filter((cc) => cc.country === userCountry) : []),
    ...priorityCountries
        .filter((val) => val !== userCountry)
        .map((pc) => allCountryCodes.find((cc) => cc.country === pc)!),
    ...allCountryCodes.filter((cc) => !priorityCountries.includes(cc.country)),
];

const removeSpacesFromString = (val: string) => val.replace(/\s/g, '');
const safeParsePhoneNumber = (country: string, national: string) => {
    try {
        return parsePhoneNumberWithError(`+${country}${national}`);
    } catch (e) {
        return null;
    }
};

const isValidCountryCode = (code: string) => countryCodes.some((cc) => cc.code === code);

const phoneTextProps = {
    keyboardType: 'phone-pad',
    textContentType: 'telephoneNumber',
} as const;

const PhoneNumberInput = ({
    testIDCountryCodeInput,
    label,
    placeholder,
    value,
    onChange,
    error,
    initialCountry,
    autoFocus,
    ...textProps
}: {
    testIDCountryCodeInput?: string;
    label?: string;
    placeholder?: string;
    value: string;
    onChange: (text: string) => void;
    error?: boolean;
    initialCountry?: string | null;
} & Pick<
    ComponentProps<typeof TextInput>,
    'autoFocus' | 'onSubmitEditing' | 'testID' | 'clearButtonMode' | 'returnKeyType'
>) => {
    const { selectedLanguage } = useGlobalState((state) => state.selectedLanguage);
    const parsedValue = value ? safeParsePhoneNumber(value, '') : null;

    const [showDropdown, setShowDropdown] = useState(false);
    const [countryCode, setCountryCode] = useState(
        parsedValue
            ? parsedValue.countryCallingCode
            : initialCountry === null
              ? ''
              : (initialCountry ??
                allCountryCodes.find(({ country }) => country === userCountry)?.code ??
                (selectedLanguage === 'no' ? '47' : '')),
    );
    const [nationalNumber, setNationalNumber] = useState(parsedValue ? parsedValue.nationalNumber : '');
    const nationalNumberInputRef = useRef<TextInput>(null);
    const countryInputRef = useRef<TextInput>(null);
    const styles = useThemeStyle(themedStyle);
    useToggleBackdrop(!showDropdown);

    useEffect(() => {
        if (value) {
            const parsed = safeParsePhoneNumber(value, '');
            if (parsed) {
                setCountryCode(parsed.countryCallingCode);
                setNationalNumber(parsed.nationalNumber);
            }
        }
    }, [value]);

    // TODO handle backspace on american number with )

    const setNumber = (number: PhoneNumber) => {
        setNationalNumber(number.nationalNumber);
        setCountryCode(number.countryCallingCode);
        onChange(number.number);
    };

    const handlePhoneNumberChange = (text: string) => {
        const cleanedText = removeSpacesFromString(text.replace(/^00/, '+'));

        const setCompletedNumber = (number: PhoneNumber) => {
            setNumber(number);
            // because we receive backspace event we focus input
            setTimeout(() => focusNationalNumber(), 0);
        };

        // If it starts with +47 we autocomplete
        if (cleanedText.startsWith('+')) {
            if (isValidNumber(cleanedText)) {
                setCompletedNumber(parsePhoneNumber(cleanedText));
                return;
            } else {
                addBreadcrumb(
                    'errorContext',
                    `Unrecognized number in phone input: ${text}, countryCode:${countryCode}`,
                );
                captureException(new Error('Unrecognized number in phone input V3'));
            }
        }

        const fullNumber = removeSpacesFromString(`${countryCode}${cleanedText}`);
        const asYouType = new AsYouType();
        asYouType.input(fullNumber);
        const number = asYouType.getNumber();
        if (number) {
            setNumber(number);
            // If we are possibly typing country code, lets try and set it
            if (fullNumber.length <= 3) {
                if (!isValidCountryCode(cleanedText)) {
                    // If current country code is not valid, lets assume user is typing country code
                    setCountryCode((countryCode ?? '') + (asYouType.getNumber()?.countryCallingCode ?? cleanedText));
                }
            }
        } else {
            setNationalNumber(text);
            onChange(fullNumber);
        }
    };

    const focusNationalNumber = () => nationalNumberInputRef.current?.focus();
    const focusCountryNumber = () => countryInputRef.current?.focus();

    const handleCountryCodeChange = (code: string) => {
        if (isValidNumber(code)) {
            // Probably autocomplete gave complete number
            setNumber(parsePhoneNumber(code));
            focusNationalNumber();
        } else {
            const fullNumber = `${code}${nationalNumber}`;
            const asYouType = new AsYouType();
            asYouType.input(fullNumber);
            const number = asYouType.getNumber();
            if (number) {
                setNumber(number);
                focusNationalNumber();
            } else {
                setCountryCode(code);
                onChange(removeSpacesFromString(`${code}${nationalNumber}`));
                if (isValidCountryCode(code)) {
                    focusNationalNumber();
                }
            }
        }
        setShowDropdown(false);
    };

    const handleToggleDropdown = () => {
        setShowDropdown(!showDropdown);
        if (!showDropdown) {
            // Opening dropdown
            focusCountryNumber();
        }
    };

    const phoneNumber = safeParsePhoneNumber(countryCode, nationalNumber);

    const nationalNumberProps = useBackspaceFocusShift(focusCountryNumber);

    const borderColor = error
        ? styles.borderWithError
        : phoneNumber?.isValid()
          ? styles.borderWithValue
          : styles.borderWithoutValue;
    const backgroundColor = error ? styles.errorBackground : null;
    const { t } = useTranslation();
    const themedTextProps = useInputPropColors();
    return (
        <View>
            {label ? <InputLabel>{label}</InputLabel> : null}
            <View style={[styles.inputContainer, borderColor, backgroundColor]}>
                <ContextMenu
                    onToggle={handleToggleDropdown}
                    actions={countryCodes.map((country) => ({
                        onPress: () => handleCountryCodeChange(country.code),
                        code: country.code,
                        countryName: t(`countryCode:${country.country}`),
                        selected: countryCode === country.code,
                    }))}
                    isOpen={showDropdown}
                    trigger={
                        <View style={[styles.countryCodeContainer, borderColor]}>
                            <HeimeText style={styles.countryCodeText}>+</HeimeText>
                            <TextInput
                                testID={testIDCountryCodeInput}
                                style={[
                                    styles.countryCodeText,
                                    styles.countryCodeInput,
                                    isWeb() ? styles.inputWeb : null,
                                ]}
                                value={countryCode}
                                onChangeText={handleCountryCodeChange}
                                {...themedTextProps}
                                autoComplete="tel-country-code"
                                {...phoneTextProps}
                                ref={countryInputRef}
                                placeholder="47"
                                returnKeyType="next"
                                onSubmitEditing={focusNationalNumber}
                                autoFocus={initialCountry === null && autoFocus}
                            />
                            <View
                                style={[
                                    styles.chevron,
                                    {
                                        transform: [{ rotate: showDropdown ? '-90deg' : '90deg' }],
                                    },
                                ]}
                            >
                                <Icon name="chevron" color="main" />
                            </View>
                        </View>
                    }
                />
                <TextInput
                    style={[styles.phoneNumberInput, isWeb() ? styles.inputWeb : null]}
                    value={
                        phoneNumber && phoneNumber.countryCallingCode === countryCode
                            ? new AsYouType(phoneNumber.country).input(phoneNumber.nationalNumber)
                            : nationalNumber
                    }
                    onChangeText={handlePhoneNumberChange}
                    autoComplete="tel"
                    returnKeyType="next"
                    placeholder={placeholder}
                    autoFocus={initialCountry !== null && autoFocus}
                    {...themedTextProps}
                    {...phoneTextProps}
                    ref={nationalNumberInputRef}
                    {...nationalNumberProps}
                    {...textProps}
                />
            </View>
        </View>
    );
};

const useBackspaceFocusShift = (onFocusShift: () => void) => {
    const [cursorPosition, setCursorPosition] = useState<number | null>(null);

    const onKeyPress: ComponentProps<typeof TextInput>['onKeyPress'] = (e) => {
        if (e.nativeEvent.key === 'Backspace' && cursorPosition === 0) {
            // This also happens on autocomplete, we handle that by refocusing the input in the component above
            onFocusShift();
        }
    };

    const onSelectionChange: ComponentProps<typeof TextInput>['onSelectionChange'] = (event) => {
        const { start } = event.nativeEvent.selection;
        setCursorPosition(start);
    };

    return { onKeyPress, onSelectionChange };
};

interface Layout {
    width: number;
    height: number;
    x: number;
    y: number;
    left?: number;
    top?: number;
}

type CustomRendererProps = PropsWithChildren<{
    style: StyleProp<ViewStyle>;
    layouts: {
        triggerLayout: Layout;
        optionsLayout: Layout;
        safeAreaLayout: Layout;
    };
}>;

interface ContextMenuProps {
    onToggle(): void;
    actions: { onPress(): void; code: string; countryName: string; selected: boolean }[];
    isOpen: boolean;
    trigger: ReactElement;
}
const ContextMenu = ({ onToggle, actions, isOpen, trigger }: ContextMenuProps): ReactElement => {
    const { theme } = useContext(ThemeContext);
    useEffect(() => {
        if (isOpen) {
            const handler = BackHandler.addEventListener('hardwareBackPress', () => {
                onToggle();
                return true;
            });
            return () => handler.remove();
        }
    }, [isOpen, onToggle]);

    return (
        <Menu
            opened={isOpen}
            renderer={({ style, children: innerChildren, layouts, ...rest }: CustomRendererProps) => {
                const top = layouts.triggerLayout.y;
                let optionsPosition = {
                    left: WW * 0.05,
                    top: layouts.triggerLayout.y + layouts.safeAreaLayout.y,
                    width: layouts.optionsLayout.width,
                    height: layouts.safeAreaLayout.height - top - screenMargin,
                    maxHeight: WH * 0.3,
                };

                return (
                    <View
                        {...rest}
                        style={[
                            style,
                            { ...optionsPosition },
                            {
                                position: 'absolute',
                                shadowColor: theme.black,
                                shadowOffset: { width: 0, height: 2 },
                                shadowOpacity: 0.25,
                            },
                        ]}
                    >
                        {innerChildren}
                    </View>
                );
            }}
            onBackdropPress={onToggle}
        >
            <MenuTrigger
                customStyles={{
                    triggerOuterWrapper: { marginTop: 'auto', marginBottom: 'auto' },
                }}
                onPress={onToggle}
            >
                {trigger}
            </MenuTrigger>
            <MenuOptions customStyles={{ optionsWrapper: { backgroundColor: theme.mainBackground } }}>
                <FlatList
                    data={actions}
                    keyboardShouldPersistTaps="always"
                    renderItem={({ item: button, index }: { item: ArrayElement<typeof actions>; index: number }) => (
                        <MenuOption
                            key={button.code}
                            onSelect={button.onPress}
                            children={
                                <View style={{ flexDirection: 'row', gap: smallestMargin, alignItems: 'center' }}>
                                    <HeimeText style={{ fontSize, color: theme.black }}>+{button.code}</HeimeText>
                                    <HeimeText style={{ fontSize: smallestFontSize, color: theme.mediumGrey }}>
                                        {button.countryName}
                                    </HeimeText>
                                    {button.selected && (
                                        <Icon style={{ marginLeft: 'auto' }} name="check" color="main" />
                                    )}
                                </View>
                            }
                            customStyles={{
                                optionText: {
                                    color: theme.main,
                                    fontSize: WW * 0.04,
                                },
                                optionWrapper: {
                                    paddingLeft: screenMargin,
                                    paddingRight: screenMargin,
                                    paddingTop: (screenMargin * 2) / 3,
                                    paddingBottom: (screenMargin * 2) / 3,
                                    borderBottomColor: theme.lightGrey,
                                    borderBottomWidth: index !== actions.length - 1 ? 1 : 0,
                                    minHeight: 40,
                                },
                            }}
                        />
                    )}
                    style={{ maxHeight: WH * 0.3, height: '100%' }}
                />
            </MenuOptions>
        </Menu>
    );
};

const themedStyle = (theme: Theme) =>
    StyleSheet.create({
        inputContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius,
            borderWidth,
        },
        borderWithValue: {
            borderColor: theme.main,
        },
        borderWithoutValue: {
            borderColor: theme.lightGrey,
        },
        borderWithError: {
            borderColor: theme.error,
        },
        errorBackground: {
            backgroundColor: theme.errorLight,
        },
        countryCodeContainer: {
            borderRightWidth: borderWidth,
            alignSelf: 'stretch',
            paddingVertical: smallestMargin,
            flexDirection: 'row',
            alignItems: 'center',
        },
        countryCodeText: {
            fontSize,
            paddingLeft: smallestMargin,
            fontFamily: _fonts.primaryFont,
            padding: 0,
        },
        countryCodeInput: {
            minWidth: fontSize * 2,
            maxWidth: isWeb() ? fontSize * 5 : undefined,
            paddingLeft: 0,
        },
        chevron: { marginRight: smallestMargin },
        phoneNumberInput: {
            flex: 1,
            padding: smallestMargin,
            fontSize,
            fontFamily: _fonts.primaryFont,
        },
        inputWeb: {
            outlineStyle: 'none',
        } as ViewStyle,
    });

export default PhoneNumberInput;
