import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';
import _fonts from '_fonts';
import { Theme, useThemeStyle, WH } from '_utils';
import { capitalizeFirstLetter } from '_utils/misc';
import { smallestMargin, subtitleFontSize, screenMargin } from '_utils/sizes';
import { FullWidthPictureCarousel, HeimeText, InfoMessage, ReadMore, SelectorChip } from 'Components';
import { Product, ProductHasPrice } from 'types/Category';
import { PriceDisplay } from './components';

const gradientLength = 100;

const ProductDetailsView = ({
    pictures,
    paymentStrategy,
    name,
    description,
    booking_time,
    max_booking,
    can_skip_payment,
    capacity,
    belowPicture,
}: Product & { belowPicture?: ReactElement }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    const hasPrice = ProductHasPrice({ paymentStrategy });
    return (
        <>
            {pictures.length > 0 && <FullWidthPictureCarousel pictures={pictures} />}
            {hasPrice && can_skip_payment ? (
                <InfoMessage
                    message={t('serviceExpanded:skipPaymentExplainer')}
                    containerStyle={themedStyle.skipPaymentExplainer}
                />
            ) : null}
            {belowPicture}
            <HeimeText variant="title" maxFontSizeMultiplier={2} style={themedStyle.title}>
                {name}
            </HeimeText>
            {hasPrice && paymentStrategy ? <PriceDisplay paymentStrategy={paymentStrategy} /> : null}

            {capacity > 1 || hasPrice || max_booking > 0 ? (
                <ScrollView
                    showsHorizontalScrollIndicator={false}
                    horizontal
                    contentContainerStyle={{ paddingLeft: screenMargin, paddingRight: screenMargin }}
                    bounces={false}
                    style={{ marginBottom: smallestMargin }}
                >
                    {capacity > 1 ? (
                        <SelectorChip
                            variant="GreyPrimary"
                            title={t('serviceExpanded:nAvailablePlural', { amount: capacity })}
                        />
                    ) : null}
                    {max_booking > 0 ? (
                        <SelectorChip
                            variant="GreyPrimary"
                            title={capitalizeFirstLetter(
                                booking_time
                                    ? max_booking % 24 === 0
                                        ? t('serviceExpanded:maxBooking_day', { count: max_booking / 24 })
                                        : t('serviceExpanded:maxBooking_hour', { count: max_booking })
                                    : t('serviceExpanded:maxBooking_day', { count: max_booking }),
                            )}
                        />
                    ) : null}
                    <SelectorChip
                        variant="GreyPrimary"
                        title={booking_time ? t('serviceExpanded:shortTerm') : t('serviceExpanded:longTerm')}
                    />
                </ScrollView>
            ) : null}
            <ReadMore
                maxFontSizeMultiplier={2}
                textStyle={themedStyle.description}
                containerStyle={themedStyle.descriptionContainer}
                numberOfLines={6}
                text={description}
            />
        </>
    );
};
const styles = (theme: Theme) =>
    StyleSheet.create({
        productLabel: {
            alignItems: 'center',
            flexDirection: 'row',
        },
        title: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            marginTop: smallestMargin,
            marginBottom: smallestMargin,
            textAlign: 'left',
            fontSize: WH * 0.03,
        },
        description: {
            fontFamily: _fonts.primaryFont,
            color: theme.secondaryText,
            fontSize: subtitleFontSize,
        },
        descriptionContainer: {
            marginLeft: WH * 0.02,
            marginRight: WH * 0.02,
            marginBottom: screenMargin,
        },
        skipPaymentExplainer: {
            marginTop: screenMargin,
            marginRight: screenMargin,
            marginLeft: screenMargin,
        },
        gradient: {
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            width: gradientLength,
            pointerEvents: 'none',
        },
        scroll_container: {
            flex: 1,
        },
    });

export default ProductDetailsView;
