import React, { ComponentProps, ReactElement } from 'react';
import { View, Text, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import _fonts from '../../../_fonts';
import { Theme, useThemeStyle, WW } from '../../../_utils';
import { screenMargin } from '../../../_utils/sizes';
import { Icon } from '../../../Components';

interface IconWithValueProps {
    hasValue: boolean;
    text: string;
    icon: ComponentProps<typeof Icon>['name'];
    style?: StyleProp<ViewStyle>;
}
const IconWithValue = ({ hasValue, text, icon, style }: IconWithValueProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View style={[themedStyle.valueWithIconContainer, style]}>
            <Icon name={icon} color={hasValue ? 'main' : 'secondaryText'} />
            <Text style={[themedStyle.contentDesc, themedStyle.textNextToIcon]}>{text}</Text>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        contentDesc: {
            fontFamily: _fonts.primaryFont,
            color: theme.secondaryText,
            fontSize: WW * 0.04,
        },
        valueWithIconContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
        },
        textNextToIcon: { marginLeft: screenMargin / 2 },
    });

export default IconWithValue;
