/* eslint-env browser */
import {
    DocumentDirectoryPathType,
    downloadFileType,
    existsType,
    readFileAndConvertToBase64Type,
} from './fileSystemExport';

export const readFileAndConvertToBase64: readFileAndConvertToBase64Type = (file) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        if (file && file instanceof Blob) {
            reader.readAsDataURL(file as unknown as Blob);
            reader.onload = function () {
                resolve((reader.result as string) ?? '');
            };
            reader.onerror = function (error) {
                reject(error);
            };
            return;
        }
        if (!file) {
            reject(new Error('File is not defined'));
            return;
        }
        const fileType = typeof file;
        if (fileType !== 'object') {
            reject(new Error('File is not a Blob, type is ' + typeof file));
            return;
        }
        reject(new Error('File is not a Blob, instance is: ' + Object.prototype.toString.call(file)));
    });
};

export const DocumentDirectoryPath: DocumentDirectoryPathType = '';

export const downloadFile: downloadFileType = () => {
    throw new Error('DownloadFile has not been implemented for Web, should not be used');
};

// we never store files on web, so this is always false
export const exists: existsType = () => Promise.resolve(false);
