import { z } from 'zod';
import { ApiImage, ApiImageSchema } from './Base';

const hourFormatSchema = z.string().regex(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Must be in HH:mm format');

export const ProductPaymentStrategySchema = z
    .union([
        z.object({
            type: z.union([
                z.literal('rate_per_night'),
                z.literal('rate_per_booking'),
                z.literal('rate_per_hour'),
                z.literal('rate_per_booking_capacity'),
                z.literal('rate_per_hour_per_capacity'),
                z.literal('rate_per_night_per_capacity'),
            ]),
            price: z.number(),
        }),
        z.object({
            type: z.literal('rate_per_hour_with_night_price'),
            nighttime_price: z.number(),
            daytime_price: z.number(),
            nighttime_start_hour: z.number(),
            nighttime_end_hour: z.number(),
        }),
    ])
    .nullable();

export const ProductSchema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    paymentStrategy: ProductPaymentStrategySchema,
    pictures: z.array(ApiImageSchema),
    for_booking: z.boolean(),
    booking_time: z.boolean(),
    booking_start: hourFormatSchema.nullable(),
    booking_end: hourFormatSchema.nullable(),
    can_skip_payment: z.boolean(),
    can_provide_booking_reason: z.boolean(),
    requires_booking_reason: z.boolean(),
    can_provide_booking_reason_if_skipping_payment: z.boolean(),
    requires_booking_reason_if_skipping_payment: z.boolean(),
    max_booking: z.number(),
    max_booked_per_booking: z.number().nullable(),
    capacity: z.number(),
    locks: z.array(z.object({ id: z.number() })).optional(),
    refundable: z.boolean(),
    refund_hours_before: z.number(),
    requires_license_plate: z.boolean(),
});

export type Product = z.infer<typeof ProductSchema>;

export const CategorySchema: z.ZodType<Category> = z.object({
    id: z.number(),
    name: z.string(),
    categories: z.array(z.lazy(() => CategorySchema)),
    products: z.array(ProductSchema),
    pictures: z.array(ApiImageSchema),
    external_link: z.string().nullable(),
});
export interface Category {
    id: number;
    name: string;
    categories: Category[];
    products: Product[];
    pictures: ApiImage[];
    external_link?: string | null;
}

export const ProductHasPrice = (product: Pick<Product, 'paymentStrategy'>): boolean => {
    if (!product.paymentStrategy) {
        return false;
    }
    if ('price' in product.paymentStrategy) {
        return product.paymentStrategy.price > 0;
    }
    return product.paymentStrategy.nighttime_price > 0 || product.paymentStrategy.daytime_price > 0;
};
