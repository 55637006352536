import React, { ComponentProps, PropsWithChildren, ReactElement } from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from '../../../_dependencies/safeArea';
import { Theme, useThemeStyle } from '../../../_utils';
import { Footer } from '../../../Components';
import OnboardingHeader from '../Components/OnboardingHeader';

interface OnboardingStepContainerProps {
    step: ComponentProps<typeof OnboardingHeader>['currentStep'];
    bottomSticky?: ReactElement;
    disableFooter?: boolean;
}

const OnboardingStepContainer = ({
    step,
    children,
    bottomSticky,
    disableFooter = false,
}: PropsWithChildren<OnboardingStepContainerProps>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <SafeAreaView style={themedStyle.full} edges={['top', 'right', 'bottom', 'left']}>
            {step === 0 ? null : <OnboardingHeader currentStep={step} isHideBack={step <= 1} />}
            {children}
            {bottomSticky}
            {step !== 0 && !disableFooter && <Footer />}
        </SafeAreaView>
    );
};
export default OnboardingStepContainer;

const styles = (theme: Theme) =>
    StyleSheet.create({
        full: {
            height: '100%',
            backgroundColor: theme.mainBackground,
            display: 'flex',
            flexGrow: 1,
        },
    });
