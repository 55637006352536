import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useContextMenuFocusEffect } from '_navigator';
import NeighborList from './NeighborList';
import { Theme, useThemeStyle } from '../../_utils';
import { HeaderWithNav, Tabs } from '../../Components';
const NeighborsAll = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    useContextMenuFocusEffect(['chatNew']);

    return (
        <View style={themedStyle.main}>
            <HeaderWithNav title={t('moreMenu:neighbors')} safeArea />
            <Tabs
                tabs={[
                    { title: t('neighbours:alphabetic').toUpperCase(), content: <NeighborList /> },
                    {
                        title: t('neighbours:apartment').toUpperCase(),
                        content: <NeighborList filter={'apartment'} />,
                    },
                ]}
                style={themedStyle.tabs}
            />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
        tabs: {
            flex: 1,
        },
    });

export default NeighborsAll;
