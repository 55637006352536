import React, { ReactElement, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet, Text } from 'react-native';
import * as z from 'zod';
import { Support, useCreateSupport, useEditSupport } from '../../_api/useSupports';
import { SafeAreaView } from '../../_dependencies/safeArea';
import _fonts from '../../_fonts';
import { useAppNavigation } from '../../_navigator';
import { Theme, useThemeStyle, useKeyboardIsVisible, WW, isTruthy, showToast } from '../../_utils';
import { screenMargin } from '../../_utils/sizes';
import { CheckBox, FormInput, HeaderWithNav, ImageSelector, PrimaryButton } from '../../Components';
import { fileSchema } from '../../types/Utility';

interface ReportNewProps {
    route: { params: { support?: Support } };
}

const schema = z.object({
    pictures: z.array(fileSchema),
    title: z.string().nonempty(),
    content: z.string().nonempty(),
    is_public: z.boolean(),
    isEditing: z.boolean(),
    id: z.onumber(),
});

type FormValues = z.infer<typeof schema>;

const mapToDefault = (initial: Support | undefined): FormValues => {
    if (initial) {
        return {
            title: initial.title,
            content: initial.content,
            pictures: initial.pictures.map((image, index) => ({ ...image, status: 'stale', index })),
            is_public: initial.is_public,
            isEditing: true,
            id: initial.id,
        };
    }
    return {
        title: '',
        content: '',
        pictures: [],
        isEditing: false,
        is_public: false,
    };
};

const ReportNew = ({ route }: ReportNewProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const keyboardIsVisible = useKeyboardIsVisible();

    const { isPending: isSupportCreating, mutate: createSupport } = useCreateSupport();
    const { isPending: isSupportUpdating, mutate: editSupport } = useEditSupport();

    const { formState, watch, setValue, ...methods } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: mapToDefault(route.params.support),
        resolver: zodResolver(schema),
    });
    const { isValid } = formState;
    const pictures = watch('pictures');
    const isEditing = watch('isEditing');
    const isPublic = watch('is_public');

    const handleSetImage = (img: FormValues['pictures']) => setValue('pictures', img);

    const handleSetPublic = (value: FormValues['is_public']) => setValue('is_public', value);

    const onError = () => {
        showToast({
            header: t('reportNew:error'),
            text: '',
            type: 'error',
        });
    };

    const onSubmit: SubmitHandler<FormValues> = (formValues) => {
        if (isEditing && formValues.id) {
            const supportId = formValues.id;
            editSupport([supportId, formValues], {
                onSuccess: () => {
                    navigate('ReportsAll', undefined);
                    navigate('ReportSelected', { supportId });
                },
                onError,
            });
        } else {
            createSupport(formValues, {
                onSuccess: (data) => {
                    navigate('ReportsAll', undefined);
                    navigate('ReportSelected', { supportId: data.success });
                },
                onError,
            });
        }
    };

    const buttonStatus = useMemo(() => {
        return isSupportCreating || isSupportUpdating ? 'loading' : isValid ? null : 'disabled';
    }, [isSupportCreating, isSupportUpdating, isValid]);

    return (
        <SafeAreaView
            style={themedStyle.main}
            edges={[keyboardIsVisible ? undefined : ('bottom' as const), 'left' as const, 'right' as const].filter(
                isTruthy,
            )}
        >
            <HeaderWithNav title={t(isEditing ? 'reportNew:header:edit' : 'reportNew:header:new')} />

            <FlatList
                contentContainerStyle={themedStyle.contentContainer}
                ListHeaderComponent={
                    <>
                        <FormProvider setValue={setValue} watch={watch} formState={formState} {...methods}>
                            <ImageSelector
                                images={pictures ?? []}
                                onChange={handleSetImage}
                                title={t('reportNew:image')}
                                optional={true}
                                titleStyles={themedStyle.imageSelectorTitle}
                            />
                            <FormInput
                                placeholder={t('reportNew:enterTitle')}
                                name="title"
                                label={t('reportNew:title')}
                                autoFocus
                            />
                            <FormInput
                                placeholder={t('reportNew:enterDesc')}
                                multiline
                                numberOfLines={6}
                                name="content"
                                label={t('reportNew:desc')}
                            />
                        </FormProvider>
                        <CheckBox
                            style={{ paddingTop: WW * 0.04, paddingBottom: WW * 0.02 }}
                            titleStyle={themedStyle.checkbox}
                            title={t('reportNew:isPublic')}
                            checked={isPublic}
                            onPress={() => handleSetPublic(!isPublic)}
                        />

                        <Text style={themedStyle.desc}>{t('reportNew:publicContent')}</Text>
                        <PrimaryButton
                            status={buttonStatus}
                            text={(isEditing ? t('reportNew:saveChanges') : t('reportNew:publish')).toUpperCase()}
                            onPress={methods.handleSubmit(onSubmit)}
                        />
                    </>
                }
                data={[]}
                renderItem={() => null}
                keyboardShouldPersistTaps="handled"
            />
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
        contentContainer: {
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            paddingBottom: screenMargin,
        },
        checkbox: {
            fontFamily: _fonts.primaryFont,
            color: theme.black,
            fontSize: WW * 0.04,
        },
        desc: {
            fontFamily: _fonts.primaryFont,
            color: theme.secondaryText,
            fontSize: WW * 0.04,
            marginBottom: screenMargin,
        },
        imageSelectorTitle: {
            fontSize: WW * 0.04,
        },
    });
export { ReportNew };
