import React, { ReactElement, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useGetCategories } from '_api/useCategories';
import { Theme, useThemeStyle } from '_utils';
import { useBottomSpacer } from '_utils/hooks';
import { Loader, QueryItemView, HeaderWithNav } from 'Components';
import { Category as CategoryType } from 'types/Category';
import ServicesList from './common/ServicesList';

interface CategoryProps {
    route: { params: { categoryId: number } };
}

const Category = ({ route }: CategoryProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const paddingBottom = useBottomSpacer();
    const { data, isLoading: isLoadingCategories, isError, error, refetch, isRefetching } = useGetCategories();

    const selectedCategory = useMemo((): CategoryType | null => {
        const findCategory = (curr: CategoryType | null, category: CategoryType): CategoryType | null => {
            if (curr) {
                return curr;
            }
            if (category.id === route.params.categoryId) {
                return category;
            }
            return category.categories.reduce(findCategory, null);
        };
        return data?.categories?.reduce(findCategory, null) ?? null;
    }, [data?.categories, route.params.categoryId]);

    const dataToShow = useMemo(() => {
        const categories = selectedCategory?.categories ?? [];
        const products = selectedCategory?.products ?? [];
        return [...categories, ...products];
    }, [selectedCategory?.categories, selectedCategory?.products]);

    if (isLoadingCategories) {
        return <Loader />;
    }

    if (isError) {
        throw error;
    }
    if (!selectedCategory) {
        throw new Error(`Could not find category: ${route.params.categoryId}`);
    }

    const handleRefresh = () => {
        refetch();
    };

    return (
        <>
            <HeaderWithNav title={selectedCategory.name} style={themedStyle.headerStyle} safeArea />
            <QueryItemView
                onRefresh={handleRefresh}
                isRefreshing={isRefetching}
                containerStyle={{ paddingBottom }}
                styled
                virtualized
            >
                <ServicesList items={dataToShow} />
            </QueryItemView>
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        headerStyle: {
            backgroundColor: theme.shadow,
        },
    });

export default Category;
