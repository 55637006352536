import { Dispatch } from 'react';
import createContextFactory from '_utils/createContextFactory';
import { ApiImage } from 'types/Base';
import type { Procedure, ProcedureInstance } from 'types/Procedures';

export type ProcedureState = {
    hasSkippedAction: boolean;
} & (
    | {
          screen: 'ProcedureStart';
      }
    | {
          screen: 'ProcedureAction';
          actionIndex: number;
          goBack(): void;
      }
);

type action =
    | { type: 'openProcedure'; goBack(): void }
    | { type: 'openProcedureAction'; questionIndex: number; goBack(): void }
    | { type: 'skipAction' };

function reducer(state: ProcedureState, action: action): ProcedureState {
    switch (action.type) {
        case 'openProcedure':
            return { ...state, screen: 'ProcedureStart' };
        case 'openProcedureAction': {
            return { ...state, screen: 'ProcedureAction', actionIndex: action.questionIndex, goBack: action.goBack };
        }
        case 'skipAction':
            return { ...state, hasSkippedAction: true };
    }
    return state;
}

const actionsCreator = (dispatch: Dispatch<action>) => ({
    openProcedure: (goBack: () => void) => dispatch({ type: 'openProcedure', goBack }),
    openProcedureAction: (questionIndex: number, goBack: () => void) =>
        dispatch({ type: 'openProcedureAction', questionIndex, goBack }),
    skipAction: () => dispatch({ type: 'skipAction' }),
});

const { Provider: ProcedureProvider, useContextHook: useProcedureContext } = createContextFactory<
    ProcedureState,
    action,
    typeof actionsCreator,
    {
        procedure: Procedure;
        procedureInstance: ProcedureInstance | null;
        productName: string;
        productImages: ApiImage[];
        bookingId: number;
    }
>(reducer, actionsCreator);

export { useProcedureContext, ProcedureProvider };
