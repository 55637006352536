import React, { PropsWithChildren, ReactElement } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import { View, StyleSheet } from 'react-native';
import { isReservationOnGoing, WW, useRerenderCountdown } from '_utils';
import { SelectorChip } from 'Components';
import { UnixTimeCode } from 'types/Utility';

interface ReservationChipsProps {
    start_at: UnixTimeCode;
    end_at: UnixTimeCode;
}

const ReservationChips = ({ start_at, end_at, children }: PropsWithChildren<ReservationChipsProps>): ReactElement => {
    const start = moment.unix(start_at);
    const end = moment.unix(end_at);
    const isSameDay = start.isSame(end, 'day');
    const isOngoing = isReservationOnGoing({ start_at, end_at });
    const chips = [];

    useRerenderCountdown(isOngoing ? end : start, isOngoing);

    if (isOngoing) {
        if (end.isSame(moment(), 'day')) {
            chips.push(
                <SelectorChip
                    key="ongoing"
                    variant="WhitePrimaryGrey"
                    containerStyle={styles.chipStyle}
                    title={`${t('reservations:until')} ${end.format('HH:mm')}`}
                />,
            );
        } else {
            const dateString = end.diff(moment(), 'year') <= 1 ? end.format('D. MMM') : end.format('D. MMM YYYY');
            chips.push(
                <SelectorChip
                    key="ongoingNextDay"
                    variant="WhitePrimaryGrey"
                    containerStyle={styles.chipStyle}
                    title={`${t('reservations:until')} ${dateString} ${end.format('HH:mm')}`}
                />,
            );
        }
    } else if (isSameDay) {
        if (!start.isSame(moment(), 'day')) {
            const dateString = start.diff(moment(), 'year') <= 1 ? start.format('D. MMM') : start.format('D. MMM YYYY');
            chips.push(
                <SelectorChip
                    key="sameday_not_today"
                    variant="WhitePrimaryGrey"
                    containerStyle={styles.chipStyle}
                    title={dateString}
                />,
            );
        } else {
            chips.push(
                <SelectorChip
                    variant="WhitePrimaryGrey"
                    containerStyle={styles.chipStyle}
                    title={t('reservations:today')}
                    key="futureSameDay"
                />,
            );
        }
        chips.push(
            <SelectorChip
                variant="WhitePrimaryGrey"
                title={`${start.format('HH:mm')}-${end.format('HH:mm')}`}
                containerStyle={styles.chipStyle}
                key="sameday"
            />,
        );
    } else {
        if (!start.isSame(moment(), 'day')) {
            const startString =
                start.diff(moment(), 'year') <= 1 ? start.format('D. MMM') : start.format('D. MMM YYYY');
            chips.push(
                <SelectorChip
                    key={'not_same_not_today'}
                    variant="WhitePrimaryGrey"
                    containerStyle={styles.chipStyle}
                    title={`${startString} ${start.format('HH:mm')}`}
                />,
            );
        } else {
            chips.push(
                <SelectorChip
                    key="not_same_today"
                    variant="WhitePrimaryGrey"
                    containerStyle={styles.chipStyle}
                    title={start.format('HH:mm')}
                />,
            );
        }
        const endString = end.diff(moment(), 'year') <= 1 ? end.format('D. MMM') : end.format('D. MMM YYYY');
        chips.push(
            <SelectorChip
                key="not_same_ends"
                variant="WhitePrimaryGrey"
                containerStyle={styles.chipStyle}
                title={`${endString} ${end.format('HH:mm')}`}
            />,
        );
    }
    if (isOngoing) {
        chips.push(
            <SelectorChip
                key="left"
                variant="WhitePrimaryGrey"
                containerStyle={styles.chipStyle}
                title={`${moment.duration(end.diff(moment().endOf('minute'))).humanize()} ${t('reservations:left')}`}
            />,
        );
    }

    return (
        <View style={styles.slotsContainer}>
            {chips}
            {children}
        </View>
    );
};

const styles = StyleSheet.create({
    slotsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxWidth: '100%',
    },
    chipStyle: {
        marginTop: WW * 0.02,
    },
});

export default ReservationChips;
