import {
    InfiniteData,
    QueryClient,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from '@tanstack/react-query';
import axios from 'axios';
import { useGetAccessTokenHeader } from '_utils/Axios';
import { FileLink } from 'types/Base';
import { getFileObjectFunc, useLaravelInfinteQuery } from './utility';
import { useSelectedCoop } from '../SelectedCoop';
import {
    FileUpload,
    isFileToBeDeleted,
    isFileToBeUploaded,
    LaravelPagingResponse,
    UnixTimeCode,
} from '../types/Utility';

export interface Survey {
    id: string;
    status: 'ACTIVE' | 'DRAFT' | 'ARCHIVED';
    creator_id: number;
    title: string;
    description: string;
    recipients_type: 'USERS' | 'APARTMENTS';
    published_at: UnixTimeCode | null;
    questions: {
        id: string;
        question: string;
        description: string;
        type: 'TEXT' | 'TEXTAREA' | 'CHECKBOX' | 'RADIO' | 'FILES' | 'NUMBER';
        attachments: FileLink[];
        options: { id: string; value: string }[];
        required: boolean;
    }[];
    responses: {
        id: string;
        user_id: number;
        apartment_id: number;
        status: 'DRAFT' | 'SENT';
        answers: {
            id: string;
            question_id: string;
            value: string;
            attachments: FileLink[];
            options: { id: string; value: string }[];
        }[];
    }[];
}

export const invalidateSurveyQueries = (queryClient: QueryClient): void => {
    queryClient.invalidateQueries({
        queryKey: ['surveys'],
    });
    queryClient.invalidateQueries({
        queryKey: ['survey'],
    });
};

const useSurveys = (): UseInfiniteQueryResult<InfiniteData<Survey[]>, string | Error> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    return useLaravelInfinteQuery(
        ['surveys', selectedCoopId],
        async () =>
            await axios.get<LaravelPagingResponse<Survey[]>>(`cooperatives/${selectedCoopId}/surveys`, {
                headers: { authorization: await getAuthHeader() },
            }),
        {
            gcTime: Infinity,
            staleTime: 1000 * 60 * 60 * 24 * 7,
        },
    );
};

const useSurvey = (id: string): UseQueryResult<Survey, string | Error> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: ['survey', selectedCoopId, id],

        queryFn: async () => {
            const authHeader = await getAuthHeader();
            const result = await axios.get<Survey>(`cooperatives/${selectedCoopId}/surveys/${id}`, {
                headers: { authorization: authHeader },
            });

            if (result.data) {
                return result.data;
            }
            throw new Error('Result returned with no data');
        },

        gcTime: Infinity,
        staleTime: 1000 * 60 * 5,

        initialData: () => {
            const relatedQueries = queryClient
                .getQueryCache()
                .getAll()
                .filter(
                    (item) =>
                        !item.isStale() && item.queryKey?.[0] === 'surveys' && item.queryKey?.[1] === selectedCoopId,
                );

            let result: Survey | undefined;
            relatedQueries?.forEach((query) =>
                (query.state.data as InfiniteData<LaravelPagingResponse<Survey[]>>)?.pages?.forEach(({ data }) =>
                    data?.forEach((survey: Survey) => {
                        if (id === survey.id) {
                            result = survey;
                        }
                    }),
                ),
            );
            return result;
        },
    });
};

interface AnswerQuestionBody {
    files: FileUpload[];
    value: string;
    apartment_id: null | number;
    options: string[];
}

const useAnswerQuestion = (
    survey: string,
    question: string,
): UseMutationResult<{ success: number }, string | Error, AnswerQuestionBody> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (answer: AnswerQuestionBody) => {
            const body = {
                ...answer,
                files: answer.files.filter(isFileToBeUploaded).map(getFileObjectFunc(survey)),
                removals: answer.files.filter(isFileToBeDeleted).map((file) => file.id),
            };
            const result = await axios.put<{ success: number }>(
                `cooperatives/${selectedCoopId}/surveys/${survey}/questions/${question}`,
                body,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Activity creation return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            invalidateSurveyQueries(queryClient);
        },
    });
};

const useMarkSurveySent = (
    survey: string,
): UseMutationResult<{ success: number }, string | Error, { apartment_id: number | null }> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ apartment_id }: { apartment_id: number | null }) => {
            const result = await axios.post<{ success: number }>(
                `cooperatives/${selectedCoopId}/surveys/${survey}/send`,
                { apartment_id },
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Activity creation return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            invalidateSurveyQueries(queryClient);
        },
    });
};

export { useSurvey, useSurveys, useAnswerQuestion, useMarkSurveySent };
