import React, { memo, ReactElement, useContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { smallestFontSize, smallestMargin } from '_utils/sizes';
import { TimeSelectorHeader } from './components';
import { TimeSelectorProps, useTimeSelectorHook } from './useTimeSelectorHook';
import { useThemeStyle, WW } from '../../../../_utils';
import { capitalizeFirstLetter } from '../../../../_utils/misc';
import { Theme, ThemeContext } from '../../../../_utils/themeContext';
import { HeimeText, Icon, Loader } from '../../../../Components';

const TimeSelector = (props: TimeSelectorProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    const {
        isInSelectedTime,
        onDatePress,
        onTimePress,
        isInSelectedDate,
        slots,
        selectingDate,
        isLoading,
        handleDateChange,
        clickedSeeMore,
        toggleSeeMore,
        onOpenBookedModal,
        modal,
    } = useTimeSelectorHook(props);

    return (
        <View style={themedStyle.container}>
            <TimeSelectorHeader
                onChangeDate={handleDateChange}
                value={moment(selectingDate)}
                isShortTerm={props.isShortTerm}
            />
            <View style={themedStyle.containerTimeWrapper}>
                <ScrollView>
                    {props.isShortTerm && moment().isBefore(moment(props.viewingDate), 'day') && !clickedSeeMore ? (
                        <TouchableOpacity style={themedStyle.moreButton} onPress={toggleSeeMore}>
                            <Icon name="chevron" style={themedStyle.moreButtonIcon} color="shadowText" />
                            <HeimeText style={themedStyle.moreButtonText}>{t('serviceExpanded:showEarlier')}</HeimeText>
                        </TouchableOpacity>
                    ) : null}
                    <View style={themedStyle.containerTime}>
                        {isLoading ? (
                            <View style={themedStyle.loaderContainer}>
                                <Loader bgColor="transparent" />
                            </View>
                        ) : null}
                        {slots.map((item, index) => (
                            <TimeSlot
                                key={item.startTime.toString()}
                                item={item}
                                index={index}
                                isInSelectedDate={isInSelectedDate}
                                isInSelectedTime={isInSelectedTime}
                                onDatePress={onDatePress}
                                onTimePress={onTimePress}
                                isShortTerm={props.isShortTerm}
                                handleOpenBookedModal={onOpenBookedModal}
                            />
                        ))}
                    </View>
                </ScrollView>
            </View>
            {modal}
        </View>
    );
};

interface TimeSlotProps {
    item: ReturnType<typeof useTimeSelectorHook>['slots'][number];
    isShortTerm: boolean;
    isInSelectedTime: ReturnType<typeof useTimeSelectorHook>['isInSelectedTime'];
    isInSelectedDate: ReturnType<typeof useTimeSelectorHook>['isInSelectedDate'];
    index: number;
    handleOpenBookedModal: ReturnType<typeof useTimeSelectorHook>['onOpenBookedModal'];
    onTimePress: ReturnType<typeof useTimeSelectorHook>['onTimePress'];
    onDatePress: ReturnType<typeof useTimeSelectorHook>['onDatePress'];
}
const TimeSlot = ({
    isShortTerm,
    item,
    isInSelectedDate,
    isInSelectedTime,
    index,
    handleOpenBookedModal,
    onTimePress,
    onDatePress,
}: TimeSlotProps) => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();

    const getTimeSlotString = (startTime: Date, endTime: Date) => {
        const startString = moment(startTime).isSame(moment().startOf('hour'))
            ? t('serviceExpanded:timeNow')
            : moment(startTime).format('HH:mm');

        return `${startString}-${moment(endTime).format('HH:mm')}`;
    };

    const getDateSlotString = (startTime: Date) => {
        return moment().isSame(startTime, 'day') ? t('serviceExpanded:today') : moment(startTime).format('D. MMM');
    };

    const isSelectedTime = isShortTerm ? isInSelectedTime(item.startTime) : isInSelectedDate(item.startTime);

    const isNonBusyPastSlot = isShortTerm
        ? moment(item.endTime).isBefore(moment()) && !item.isBusy
        : moment(item.endTime).endOf('day').isBefore(moment()) && !item.isBusy;

    const isNotFistItem = index % 3 !== 0;
    let backgroundColor = theme.mainBackground;
    let textColor = theme.darkGreen;
    const marginLeftItemValue = isNotFistItem ? 8 : 0;
    let opacityValue = item.isDisableSelect ? 0.3 : 1;
    let borderWidth = 1;
    if (isSelectedTime) {
        backgroundColor = theme.darkGreen;
        textColor = theme.mainBackground;
    } else if (isNonBusyPastSlot) {
        backgroundColor = 'transparent';
        opacityValue = 1;
        textColor = theme.secondaryLight;
        borderWidth = 0;
    } else if (item.isBusy) {
        backgroundColor = theme.lightGrey;
        textColor = theme.secondaryLight;
    } else if (item.isPossibleEndTime) {
        backgroundColor = theme.lightGreen;
        textColor = theme.darkGreen;
    }

    const string = isShortTerm ? getTimeSlotString(item.startTime, item.endTime) : getDateSlotString(item.startTime);

    const handlePress = () =>
        item.isBusy && !isSelectedTime
            ? isShortTerm
                ? handleOpenBookedModal({ from: item.startTime, to: item.endTime })
                : handleOpenBookedModal({
                      from: moment(item.startTime).startOf('day').toDate(),
                      to: moment(item.endTime).endOf('day').toDate(),
                  })
            : isShortTerm
              ? onTimePress(item.startTime, item.endTime)
              : onDatePress(item.startTime);
    return (
        <TouchableOpacity
            key={item.startTime.valueOf()}
            style={[
                themedStyle.timeWrapper,
                {
                    marginLeft: marginLeftItemValue,
                    backgroundColor: backgroundColor,
                    opacity: opacityValue,
                    borderTopWidth: borderWidth,
                    borderRightWidth: borderWidth,
                    borderBottomWidth: borderWidth,
                    borderLeftWidth: borderWidth,
                },
            ]}
            onPress={item.isDisableSelect ? undefined : handlePress}
            onLongPress={() => handleOpenBookedModal({ from: item.startTime, to: item.endTime })}
        >
            {isShortTerm ? null : (
                <Text
                    style={[
                        themedStyle.dayLabel,
                        {
                            color: textColor,
                            backgroundColor: isSelectedTime ? theme.darkGreen : undefined,
                        },
                    ]}
                >
                    {capitalizeFirstLetter(moment(item.startTime).format('dddd').slice(0, 3))}
                </Text>
            )}
            <Text style={[themedStyle.textTime, { color: textColor }]}>{string}</Text>
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: theme.shadow,
        },
        textTime: { fontSize: 14 },
        dayLabel: { fontSize: 10 },
        containerTime: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingBottom: 20,
        },
        containerTimeWrapper: { paddingLeft: WW * 0.06, paddingRight: WW * 0.06, flex: 1 },
        timeWrapper: {
            width: WW / 3 - WW * 0.06,
            height: 44,
            borderTopColor: theme.secondaryLight,
            borderRightColor: theme.secondaryLight,
            borderBottomColor: theme.secondaryLight,
            borderLeftColor: theme.secondaryLight,
            borderRadius: 22,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 12,
        },
        moreButton: {
            marginHorizontal: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            color: theme.darkGreen,
            minHeight: 40,
            marginBottom: -smallestMargin,
        },
        moreButtonIcon: { transform: [{ rotate: '270deg' }] },
        moreButtonText: { fontSize: smallestFontSize, color: theme.shadowText },
        loaderContainer: {
            position: 'absolute',
            backgroundColor: 'transparent',
            top: '45%',
            left: '45%',
            width: '10%',
            height: '10%',
            justifyContent: 'center',
            alignItems: 'center',
        },
    });
const memoComp = memo(TimeSelector);

export { memoComp as TimeSelector };
