import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { ApiImage } from 'types/Base';
import { CacheImage, Loader } from '..';
import { useJoinGroup } from '../../_api/useGroups';
import _fonts from '../../_fonts';
import Images from '../../_images';
import { Theme, useThemeStyle, WH, WW } from '../../_utils';
import ListItem from '../ListItem';

const GroupItem = ({
    onPress,
    isMember,
    image,
    name,
    membersLength,
    id,
    joinDirectly = true,
}: {
    onPress: () => void;
    isMember: boolean;
    image?: ApiImage;
    name: string;
    membersLength: number;
    id: number;
    joinDirectly?: boolean;
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { mutate: joinGroup, isPending: isJoining } = useJoinGroup();
    const profileImage = image ? image : (Images.boardSection as number);
    const handleActionPress = () => joinGroup(id);
    return (
        <ListItem
            onPress={onPress}
            avatar={<CacheImage source={profileImage} style={themedStyle.avatar} />}
            title={name}
            subtitle={
                membersLength === 1 ? t('group:members:singular') : `${membersLength} ${t('group:members:plural')}`
            }
            numberOfLines={null}
            actionButton={
                isJoining ? (
                    <Loader />
                ) : !isMember && joinDirectly ? (
                    <TouchableOpacity activeOpacity={1} style={themedStyle.button} onPress={handleActionPress}>
                        <Text numberOfLines={2} style={themedStyle.buttonText}>
                            {t('group:join')}
                        </Text>
                    </TouchableOpacity>
                ) : null
            }
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        button: {
            paddingTop: WH * 0.01,
            paddingBottom: WH * 0.01,
            paddingLeft: WH * 0.02,
            paddingRight: WH * 0.02,
            backgroundColor: theme.main,
            alignSelf: 'center',
            borderRadius: WH * 0.03,
        },
        buttonText: {
            color: theme.mainText,
            fontSize: WW * 0.04,
            fontFamily: _fonts.primaryFont,
        },
        avatar: {
            width: WW * 0.18,
            height: WW * 0.18,
            borderRadius: (WW * 0.18) / 2,
        },
    });

export { GroupItem };
