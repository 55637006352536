import { ReactElement } from 'react';
import { View } from 'react-native';
import { useBottomSpacer } from '_utils/hooks';

/**
 * For cases where adding a safezone at the bottom is best handled by inserting an element at the end
 */
const BottomSpacer = (): ReactElement => {
    const height = useBottomSpacer();

    return <View style={{ height }} />;
};

export default BottomSpacer;
