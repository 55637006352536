import React, { ReactElement } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { ApiImage, FileImage } from 'types/Base';
import { Member } from '../../../_api/useContactGroup';
import Images from '../../../_images';
import { Theme, useThemeStyle, WH, WW } from '../../../_utils';
import { CacheImage } from '../../../Components';

interface GroupImageProps {
    users: Member[];
}

const GroupImage = ({ users }: GroupImageProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const nUsers = users.length;
    const usersWithAvatar = users.filter((item) => Boolean(item.avatar));
    const usersWithNoAvatar = users.filter((item) => !item.avatar);
    const toDisplay = [...usersWithAvatar, ...usersWithNoAvatar].filter((_, i) => (nUsers === 4 ? i <= 3 : i < 3));

    return (
        <View style={themedStyle.container}>
            {toDisplay.map(({ avatar, id }, i) => {
                const containerStyle = [
                    themedStyle.groupAvatar,
                    nUsers === 2
                        ? [themedStyle.diagonalAvatar, i === 0 ? themedStyle.diagonalTop : themedStyle.diagonalBottom]
                        : nUsers === 3 && i === 2
                          ? { marginLeft: 0.025 * WH, marginRight: 0.025 * WH }
                          : undefined,
                ];
                if (avatar) {
                    return <CacheImage<FileImage | ApiImage> key={id} source={avatar} style={containerStyle} />;
                }

                return <CacheImage key={id} source={Images.defaultAvatar} style={containerStyle} />;
            })}
            {nUsers > 4 ? (
                <View style={[themedStyle.groupAvatar, themedStyle.groupCountView]}>
                    <Text style={themedStyle.groupCountText}>{'+' + `${nUsers - 3}`}</Text>
                </View>
            ) : null}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            width: WW * 0.15,
            height: WW * 0.15,
            marginRight: WW * 0.04,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
        groupAvatar: {
            width: WW * 0.07,
            height: WW * 0.07,
            borderRadius: WH / 2,
            backgroundColor: theme.lightGrey,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: WW * 0.01,
        },
        diagonalAvatar: {
            position: 'absolute',
            width: WW * 0.07,
            height: WW * 0.07,
        },
        diagonalTop: { left: 0, top: 0 },
        diagonalBottom: { bottom: 0, right: 0 },
        groupCountView: {
            backgroundColor: theme.main,
        },
        groupCountText: {
            alignSelf: 'center',
            color: theme.white,
            fontWeight: 'bold',
        },
    });

export default GroupImage;
