import React, { ReactElement, useEffect, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetApartmentGroups } from '_api/useApartmentGroups';
import { FormMultiSelect } from 'Components';

function ApartmentGroupsSelector({
    isCreatingNew,
    cooperative_group_id,
}: {
    isCreatingNew: boolean;
    cooperative_group_id: null | number;
}): ReactElement | null {
    const { t } = useTranslation();
    const { data: apartmentGroups, isLoading, refetch, isRefetching } = useGetApartmentGroups();

    const { field } = useController<{ apartment_groups: string[] }>({ name: 'apartment_groups' });

    const relevantGroups = useMemo(
        () =>
            apartmentGroups?.filter((group) =>
                cooperative_group_id === null
                    ? group.cooperative_groups.length === 0
                    : group.cooperative_groups.includes(cooperative_group_id),
            ),
        [apartmentGroups, cooperative_group_id],
    );

    useEffect(() => {
        if (typeof field.value === 'string') {
            throw new Error('field.value is string');
        }
        const selectedNonValidItems = field.value.filter((item) => relevantGroups?.find(({ id }) => id === item));

        if (selectedNonValidItems.length !== field.value.length) {
            field.onChange(selectedNonValidItems);
        }
    }, [relevantGroups, field.value, field]);

    if (!relevantGroups || relevantGroups?.length === 0) {
        return null;
    }

    return (
        <FormMultiSelect<{ apartment_groups: string[] }, 'apartment_groups'>
            placeholder={t('sharingNew:chooseApartmentGroups')}
            name="apartment_groups"
            label={t('sharingNew:apartment_groups')}
            items={relevantGroups.map(({ id, name }) => ({ id, text: name }))}
            initialGrey={isCreatingNew}
            isLoading={isLoading}
            onRefresh={refetch}
            isRefreshing={isRefetching}
        />
    );
}

export default ApartmentGroupsSelector;
