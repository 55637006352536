import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack';

export type ProcedureStackParams = {
    StartProcedure: { reservationId: string };
    ProcedureAction: { actionIndex: number; reservationId: string };
};

export type ProcedureScreenProps<ScreenName extends keyof ProcedureStackParams> = StackScreenProps<
    ProcedureStackParams,
    ScreenName
>;

type ProcedureNavigationProp = StackNavigationProp<ProcedureStackParams>;

export const useProcedureNavigation = (): ProcedureNavigationProp => useNavigation<ProcedureNavigationProp>();
