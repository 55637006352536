import React, { ComponentProps, ReactElement } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';
import FileSelector from './FileSelector';
import { FileUpload } from '../../types/Utility';

interface FormFileSelectorProps<FormType> extends Omit<ComponentProps<typeof FileSelector>, 'files' | 'onChange'> {
    name: Path<FormType>;
}

const FormFileSelector = <FormType extends FieldValues = Record<string, string>>({
    name,
    ...imageSelectorProps
}: FormFileSelectorProps<FormType>): ReactElement => {
    const { field } = useController<FormType>({ name });

    const handleChange = (values: FileUpload[]) => {
        field.onChange(values, { shouldValidate: true });
    };

    return <FileSelector {...imageSelectorProps} {...field} files={field.value} onChange={handleChange} />;
};

export default FormFileSelector;
