import { createContext, useContext, useState, useMemo, PropsWithChildren, ReactElement, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';

type ContextMenuItem =
    | 'activityNew'
    | 'groupNew'
    | 'noticeNew'
    | 'noticeByBoard'
    | 'helpfulNeighbour'
    | 'sharingNew'
    | 'reportsNew'
    | 'reservations'
    | 'chatNew';

interface ContextMenuContextType {
    highlightedItems: ContextMenuItem[];
    highlightItems: (items: ContextMenuItem[]) => void;
    clearItems: (items: ContextMenuItem[]) => void;
}

const ContextMenuContext = createContext<ContextMenuContextType | undefined>(undefined);

export const useContextMenu = (): ContextMenuContextType => {
    const context = useContext(ContextMenuContext);
    if (!context) {
        throw new Error('useContextMenu must be used within a ContextMenuProvider');
    }
    return context;
};

export const ContextMenuProvider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const [highlightedItems, setHighlightedItems] = useState<ContextMenuItem[]>([]);

    const highlightItems = (items: ContextMenuItem[]) => {
        setHighlightedItems((curr) => {
            const newItems = items.filter((item) => !curr.includes(item));
            if (newItems.length === 0) {
                return curr;
            }

            return [...curr, ...newItems];
        });
    };

    const clearItems = (items: ContextMenuItem[]) => {
        setHighlightedItems((arg) => arg.filter((item) => !items.includes(item)));
    };

    return (
        <ContextMenuContext.Provider
            value={useMemo(
                () => ({
                    highlightedItems,
                    highlightItems,
                    clearItems,
                }),
                [highlightedItems],
            )}
        >
            {children}
        </ContextMenuContext.Provider>
    );
};

export const useContextMenuFocusEffect = (items: ContextMenuItem[]): void => {
    const contextMenu = useContextMenu();
    const navigation = useNavigation();

    useEffect(() => {
        const cleanupFocus = navigation.addListener('focus', () => {
            contextMenu.highlightItems(items);
        });
        const cleanupBlur = navigation.addListener('blur', () => {
            contextMenu.clearItems(items);
        });
        return () => {
            cleanupFocus();
            cleanupBlur();
        };
        // We only want this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
