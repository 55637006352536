import React, { ReactElement } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { useAppNavigation } from '../_navigator';
import { Theme, useThemeStyle, WW } from '../_utils';

interface SmallHeaderProps {
    style?: StyleProp<ViewStyle>;
    titleStyles?: StyleProp<TextStyle>;
    onClose?: () => void;
    title: string;
}

const SmallHeader = ({ style, onClose, titleStyles, title }: SmallHeaderProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { goBack } = useAppNavigation();
    return (
        <View style={[themedStyle.main, style]}>
            <Text style={[themedStyle.title, titleStyles]}>{title}</Text>
            <TouchableOpacity onPress={() => (onClose ? onClose() : goBack())}>
                <Text style={themedStyle.closeText}>+</Text>
            </TouchableOpacity>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            paddingLeft: WW * 0.06,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.shadow,
        },
        title: {
            fontWeight: 'bold',
            color: theme.main,
        },
        closeText: {
            fontSize: WW * 0.08,
            width: WW * 0.15,
            height: WW * 0.15,
            lineHeight: WW * 0.15,
            paddingLeft: WW * 0.05,
            transform: [{ rotate: '45deg' }],
        },
    });

export default SmallHeader;
