import React, { ReactElement } from 'react';
import { t } from 'i18next';
import { StepHeader } from '../../../Components';

export interface OnboardingHeaderProps {
    currentStep: 0 | 1 | 2 | 3 | 4 | 5;
    isHideBack?: boolean;
}

const MAX_STEP = 5;
const OnboardingHeader = (props: OnboardingHeaderProps): ReactElement => {
    return (
        <StepHeader
            currentStep={props.currentStep}
            maxSteps={MAX_STEP}
            text={`${t('onboarding:stepHeader', { returnObjects: true })[props.currentStep]} ${t(
                `onboarding:${props.currentStep === 0 ? 'setupPin' : 'step'}`,
                { step: props.currentStep },
            )}`}
            isHideBack={props.isHideBack}
        />
    );
};

export default OnboardingHeader;
