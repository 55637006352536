import React, { ReactElement } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useCreateChat, useSendMessage } from '../../../../_api/useChats';
import { useAppNavigation } from '../../../../_navigator';
import { FormInput, Modal } from '../../../../Components';

const schema = z.object({
    message: z.string().nonempty(),
});

export type FormValues = z.infer<typeof schema>;

interface ContactAlertModal {
    onDismiss(): void;
    userId: number;
    messageId: number;
    header: string;
}

const ContactAlertModal = ({ onDismiss, userId, messageId, header }: ContactAlertModal): ReactElement => {
    const { t } = useTranslation();
    const { mutate: createChat, isPending: createChatLoader } = useCreateChat();
    const { mutate: sendMessage, isPending: isLoading } = useSendMessage();
    const { navigate } = useAppNavigation();
    const form = useForm<FormValues>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: { message: '' },
    });

    const hasError = !form.formState.isValid;

    const handleConfirmClick = (value: FormValues) => {
        createChat(
            { name: '', users: [userId] },
            {
                onSuccess: (result) => {
                    const chatId = result.success;
                    sendMessage(
                        [
                            chatId,
                            {
                                messageId: messageId,
                                text: value.message,
                                files: [],
                            },
                        ],
                        {
                            onError: () => {},
                            onSuccess: () => {
                                onDismiss();
                                navigate('ChatSelected', { chatId: `${chatId}` });
                            },
                        },
                    );
                },
                onError: () => {},
            },
        );
    };

    return (
        <Modal
            onDismiss={onDismiss}
            header={header}
            content={
                <FormProvider {...form}>
                    <FormInput
                        label={t('sharingSelected:contactNeighbor:messageLabel')}
                        name="message"
                        placeholder={t('sharingSelected:contactNeighbor:placeHolderMessage')}
                        autoFocus
                        multiline
                    />
                </FormProvider>
            }
            buttons={[
                {
                    isDisabled: hasError,
                    isLoading: isLoading || createChatLoader,
                    text: t('sharingSelected:contactNeighbor:button').toUpperCase(),
                    onPress: form.handleSubmit(handleConfirmClick),
                },
            ]}
        />
    );
};

export default ContactAlertModal;
