import { InfiniteData } from '@tanstack/react-query';
import i18next from 'i18next';
import moment from 'moment';
// Lets let it slip
// eslint-disable-next-line react-native/split-platform-components
import { Alert, PermissionsAndroid } from 'react-native';
import { isiOS } from './isPlatform';
import { LaravelPagingResponse, UnixTimeCode } from '../types/Utility';

type ProfileWithAddress = { fname: string; lname: string | null };
export const getUsernameFromProfile = (profile: ProfileWithAddress | null): string => {
    const firstName = profile?.fname;
    const lastName = profile?.lname ?? '';
    return getFullName(firstName, lastName);
};

export const getFullName = (firstName: string | undefined, lastName: string | undefined): string => {
    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    }
    return (firstName || lastName) ?? '';
};

export const capitalizeFirstLetter = (value: string): string => {
    if (!value) {
        return '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
};

export const getCameraPermissions = async (): Promise<boolean> => {
    if (!isiOS()) {
        try {
            const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.CAMERA, {
                title: i18next.t('global:permissions:camera_permission'),
                message: i18next.t('global:permissions:must_have_permission_camera'),
                buttonNeutral: i18next.t('global:ask_me_later'),
                buttonNegative: i18next.t('global:cancel'),
                buttonPositive: 'OK',
            });

            if (granted === PermissionsAndroid.RESULTS.GRANTED) {
                return true;
            } else {
                console.error('Camera permission denied');
                if (granted === 'never_ask_again') {
                    Alert.alert(
                        i18next.t('global:permissions:camera_permission'),
                        i18next.t('global:permissions:camera_settings_permission'),
                    );
                }
                return false;
            }
        } catch (err) {
            console.warn(err);
            return false;
        }
    } else {
        return true;
    }
};

export const flattenIniniteResult = <ItemType>(data: InfiniteData<ItemType[]> | undefined): ItemType[] =>
    (data?.pages ?? []).reduce((curr, page) => {
        if (page) {
            curr.push(...page);
        }
        return curr;
    }, [] as ItemType[]);

export const mapInfiniteDataItem = <ItemType>(
    data: InfiniteData<LaravelPagingResponse<ItemType[]>> | undefined,
    mapFunc: (item: ItemType) => ItemType,
): InfiniteData<LaravelPagingResponse<ItemType[]>> | undefined =>
    data
        ? {
              pageParams: data.pageParams,
              pages: data.pages.map((items) => ({ ...items, data: items.data.map(mapFunc) })),
          }
        : undefined;

export const parseNullableUnixTimeCodeToDate = <InputType>(
    unix: UnixTimeCode | InputType,
): Date | Exclude<InputType, number> =>
    unix ? moment.unix(unix as number).toDate() : (unix as unknown as Exclude<InputType, number>);

export const isTruthy = <T>(value: T | null | undefined): value is T => !!value;
