import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from '_dependencies/safeArea';
import { useContextMenuFocusEffect } from '_navigator';
import ActivitiesList from './ActivitiesList';
import { Theme, useThemeStyle } from '../../../_utils';
import { HeaderWithNav, Tabs } from '../../../Components';

const ActivitiesAll = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    useContextMenuFocusEffect(['activityNew']);

    return (
        <SafeAreaView style={themedStyle.main}>
            <HeaderWithNav title={t('activities:title')} />
            <Tabs
                tabs={[
                    { title: t('activities:tabOne'), content: <ActivitiesList /> },
                    {
                        title: t('activities:tabTwo'),
                        content: <ActivitiesList filter="futureOnlyAttending" />,
                    },
                    { title: t('activities:tabThree'), content: <ActivitiesList filter="previous" /> },
                ]}
                style={themedStyle.tabs}
            />
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: theme.mainBackground,
        },

        tabs: {
            flex: 1,
        },
    });

export default ActivitiesAll;
