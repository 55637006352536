import React, { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Text, StyleProp, TextStyle } from 'react-native';
import _fonts from '../_fonts';
import { Theme, useThemeStyle, WW } from '../_utils';
import { smallestFontSize } from '../_utils/sizes';

interface InputLabelProps {
    optional?: boolean;
    titleStyles?: StyleProp<TextStyle>;
    optionalText?: string;
}

const InputLabel = ({
    children,
    optional,
    titleStyles,
    optionalText,
}: PropsWithChildren<InputLabelProps>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    return (
        <View style={themedStyle.titleWrapper}>
            <Text style={[themedStyle.title, titleStyles]}>{children}</Text>
            {optional && (
                <Text style={[themedStyle.optional, titleStyles]}>{optionalText ?? t('global:optional')}</Text>
            )}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        titleWrapper: { flexDirection: 'row' },
        title: {
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.04,
            marginBottom: WW * 0.01,
            marginTop: WW * 0.03,
            color: theme.black,
        },
        optional: {
            fontFamily: _fonts.primaryFont,
            fontSize: smallestFontSize,
            color: theme.secondaryText,
            marginLeft: WW * 0.01,
            marginRight: WW * 0.01,
            marginTop: 'auto',
            marginBottom: WW * 0.01,
        },
    });

export default InputLabel;
