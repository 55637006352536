import React, { ComponentProps, ReactElement, useMemo } from 'react';
import i18next from 'i18next';
import { Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelectedCoopItem } from '../../../../_utils/hooks';
import { FullPageMenuComponent, HeaderWithNav, Icon } from '../../../../Components';
import { ArrayElement } from '../../../../types/Utility';
import { FormValues } from '../schema';

type IconProperty = ComponentProps<typeof Icon>['name'];
type MenuType = {
    icon: IconProperty;
    title: string;
    navigateTo: Path<FormValues>;
};

const MenuObjects: MenuType[] = [
    {
        icon: 'image',
        title: i18next.t('newActivity:moreMenu:addPicture'),
        navigateTo: 'pictures',
    },
    {
        icon: 'note',
        title: i18next.t('newActivity:moreMenu:addDescription'),
        navigateTo: 'description',
    },
    {
        icon: 'userPlus',
        title: i18next.t('newActivity:moreMenu:addArrangers'),
        navigateTo: 'arrangers',
    },
    {
        icon: 'users',
        title: i18next.t('newActivity:moreMenu:addMaxAttendees'),
        navigateTo: 'quantity',
    },
    {
        icon: 'userCircle',
        title: i18next.t('newActivity:moreMenu:addRelatedGroups'),
        navigateTo: 'user_groups',
    },
    {
        icon: 'bell',
        title: i18next.t('newActivity:moreMenu:alerts'),
        navigateTo: 'message_type',
    },
    {
        icon: 'clockCheck',
        title: i18next.t('newActivity:moreMenu:addLatestPossibleSignupTime'),
        navigateTo: 'last_confirm_at',
    },
    {
        icon: 'clockCross',
        title: i18next.t('newActivity:moreMenu:addLatestCancellationTime'),
        navigateTo: 'last_cancel_at',
    },
];

const getMapFunction =
    (editProperty: (property: Path<FormValues>) => void) =>
    ({
        navigateTo,
        ...rest
    }: MenuType): Extract<
        ArrayElement<ComponentProps<typeof FullPageMenuComponent>['items']>,
        { onPress?: () => void }
    > => ({
        ...rest,
        onPress: navigateTo ? () => editProperty(navigateTo) : undefined,
        children: [],
    });

const ActivityAddDetailMenu = ({
    onEditProperty,
    goBack,
}: {
    onEditProperty(property: Path<FormValues> | 'more'): void;
    goBack(): void;
}): ReactElement => {
    const { t } = useTranslation();
    const hasVippsAccount = (useSelectedCoopItem()?.payment_accounts.length ?? 0) > 0;

    const menuItems = useMemo(() => {
        let items = MenuObjects;
        if (hasVippsAccount) {
            items = [
                ...MenuObjects,
                { icon: 'dollarCircle', title: t('newActivity:moreMenu:addCost'), navigateTo: 'paymentStrategy.price' },
            ];
        }
        return items.map(getMapFunction(onEditProperty));
    }, [hasVippsAccount, onEditProperty, t]);

    return (
        <>
            <HeaderWithNav onPress={goBack} title={t('newActivity:change_details')} />
            <FullPageMenuComponent items={menuItems} />
        </>
    );
};

export default ActivityAddDetailMenu;
