import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Text } from 'react-native';
import { useOwnProfile } from '_api/useProfile';
import { MessageType } from 'types/message';
import { useGetMessages } from '../../../_api/useMessages';
import _fonts from '../../../_fonts';
import { Theme, useThemeStyle, WW } from '../../../_utils';
import { flattenIniniteResult } from '../../../_utils/misc';
import { HeaderWithNav, Loader } from '../../../Components';
import { useSharingContextMenuEffect } from '../common';
import MessageListView from '../common/MessageListView';

interface OwnSharingCategoryProps {
    route: { params: { type: MessageType.Products } };
}

const OwnSharingCategory = ({ route }: OwnSharingCategoryProps): ReactElement => {
    const filter = route.params.type;
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    useSharingContextMenuEffect([filter]);
    const { data, isLoading, fetchNextPage, isFetchingNextPage, refetch, isFetching } = useGetMessages([filter]);
    const ownId = useOwnProfile()?.data?.id;
    const messages = useMemo(() => {
        const mapped = flattenIniniteResult(data);
        return mapped.filter((message) => message.creator_id === ownId);
    }, [data, ownId]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <View style={themedStyle.main}>
            <HeaderWithNav
                title={filter === MessageType.Products ? t('sharingAll:myProducts') : t('sharingAll:myRequests')}
                safeArea
            />
            <View style={themedStyle.row}>
                <Text style={themedStyle.title}>
                    {filter === MessageType.Products ? t('sharingAll:yourProducts') : t('sharingAll:yourRequests')}
                </Text>
                <Text style={themedStyle.subTitle}>{`(${messages.length})`}</Text>
            </View>
            <MessageListView
                onRefresh={refetch}
                isRefreshing={isFetching}
                loadMore={fetchNextPage}
                isLoadingMore={isFetchingNextPage}
                data={messages}
                emptyText={t('sharingAll:noSearchResult')}
                showActionButton
                numColumns={2}
            />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
        row: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        title: {
            paddingLeft: WW * 0.04,
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.02,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.05,
            color: theme.black,
        },
        subTitle: {
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.05,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.02,
            color: theme.black,
        },
    });

export default OwnSharingCategory;
