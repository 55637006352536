import { useEffect } from 'react';
import { Platform } from 'react-native';
import { removeUserProperty, setUserProperty } from '_utils/Amplitude';
import { useAppNavigation } from '..';
import { isAndroid, isiOS, usePermissionStatus } from '../../_utils';

const useNavigateToAllowPush = (userIsOnboarding: boolean): void => {
    const notificationStatus = usePermissionStatus();
    const { navigate } = useAppNavigation();

    useEffect(() => {
        if (!userIsOnboarding && notificationStatus === 'denied') {
            if (isiOS() || (isAndroid() && parseInt(Platform.Version + '', 10) > 32)) {
                navigate('AllowPushAlertsStep', { isPartOfOnboarding: false });
            }
        }
        // We do only care about initial userIsOnboarding, navigate does not change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationStatus]);

    useEffect(() => {
        if (notificationStatus) {
            setUserProperty('push alerts', notificationStatus);
            return () => {
                removeUserProperty('push alerts');
            };
        }
    }, [notificationStatus]);
};

export default useNavigateToAllowPush;
