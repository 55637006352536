import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { useContextMenuFocusEffect } from '_navigator';
import { Theme, useThemeStyle } from '_utils';
import { GroupList, HeaderWithNav, Tabs } from 'Components';

const GroupsAll = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    useContextMenuFocusEffect(['groupNew']);
    return (
        <View style={themedStyle.main}>
            <HeaderWithNav title={t('group:header')} safeArea />
            <Tabs
                style={themedStyle.tabs}
                tabs={[
                    { title: t('group:all_groups'), content: <GroupList /> },
                    { title: t('group:my_groups'), content: <GroupList filter={'onlyOwnGroups'} /> },
                ]}
            />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
        tabs: {
            flex: 1,
        },
    });

export default GroupsAll;
