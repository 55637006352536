import React, { ReactElement, useContext, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Text } from 'react-native';
import { Activity, useCancelActivity, useGetActivity } from '_api/useActivities';
import { useGetSignedupUsers } from '_api/useActivitySignedUp';
import { useOwnProfile } from '_api/useProfile';
import _fonts from '_fonts';
import { useAppNavigation, useContextMenuFocusEffect } from '_navigator';
import { WW, WH, useThemeStyle, isAppError, showToast } from '_utils';
import { getUsernameFromProfile, parseNullableUnixTimeCodeToDate } from '_utils/misc';
import { captureException } from '_utils/Sentry';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { Theme, ThemeContext } from '_utils/themeContext';
import {
    Icon,
    Loader,
    SecondaryButton,
    UserListItem,
    HeaderWithNav,
    ContextMenu,
    FullWidthPictureCarousel,
    ReadMore,
    ChatActionButton,
    GroupList,
    QueryItemView,
    BottomSpacer,
    NotFoundErrorScreen,
} from 'Components';
import { AttendeesList, BookButton, CancelActivityModal, SendActivityAlertModal, SignUpSelfModal } from './components';
import { DateTimeExplainer, IconWithValue, OverviewLabel } from '../common';

interface ActivitySelecetdScreenProps {
    route: { params: { activityId?: string } };
}

const ActivityLoadedGate = ({ route }: ActivitySelecetdScreenProps): ReactElement | null => {
    const activityId = parseInt(route?.params?.activityId ?? '', 10);
    const { data, isLoading, isError, error } = useGetActivity(activityId ?? 0);

    // For preloading
    useGetSignedupUsers(activityId ?? 0, true);

    if (isError) {
        if (isAppError(error) && error.response?.status === 404) {
            return <NotFoundErrorScreen type="Activity" />;
        } else {
            throw error;
        }
    }

    if (isLoading) {
        return <Loader />;
    }

    if (!data) {
        return <NotFoundErrorScreen type="Activity" />;
    }
    return <ActivitySelectedScreen activity={data} />;
};

const ActivitySelectedScreen = ({ activity }: { activity: Activity }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const ownId = useOwnProfile()?.data?.id ?? 0;

    useContextMenuFocusEffect(['activityNew']);

    const { isRefetching, refetch } = useGetActivity(activity.id);
    const { isRefetching: isRefetchingSignedUpUsers, refetch: refetchSignedUp } = useGetSignedupUsers(
        activity.id,
        true,
    );
    const { data: profile } = useOwnProfile();

    const [modalOpen, setModalOpen] = useState<'cancel' | 'alert' | 'signUpSelf' | 'moreMenu' | false>(false);

    const { mutate: cancelActivity, isPending: isCancelling } = useCancelActivity();

    const handleMoreToggle = () => setModalOpen('moreMenu');
    const handleSignUpSelf = () => setModalOpen('signUpSelf');
    const handleDeleteClick = () => setModalOpen('cancel');
    const handleSendAlertClick = () => setModalOpen('alert');
    const handleModalDismiss = () => setModalOpen(false);

    const handleCancelConfirmClick = (refund: boolean) => {
        cancelActivity([activity.id, refund], {
            onSuccess: () => {
                setModalOpen(false);
                navigate('ActivitiesAll', undefined);
            },
            onError: (e) => {
                captureException(e);
                showToast({
                    header: t('activity:error'),
                    text: '',
                    type: 'error',
                });
            },
        });
    };
    const handleEdit = () => {
        navigate('ActivityNew', { activity });
    };
    const isArranger = activity.arrangers.find((arranger) => arranger.id === ownId);

    return (
        <>
            <HeaderWithNav
                action={
                    isArranger ? (
                        <ContextMenu
                            actions={[
                                {
                                    loading: isCancelling,
                                    text:
                                        activity.approved_total_sum > 0
                                            ? t('activityDetail:cancel_activity')
                                            : t('activityDetail:delete_activity'),
                                    onPress: handleDeleteClick,
                                    type: 'danger',
                                },
                                {
                                    text: t('activityDetail:send_out_alert'),
                                    onPress: handleSendAlertClick,
                                    type: 'normal',
                                },

                                {
                                    text: activity.approved
                                        ? t('activityDetail:change_own_signup')
                                        : t('activityDetail:signup_yourself'),
                                    onPress: handleSignUpSelf,
                                    type: 'normal',
                                },
                            ]}
                            onToggle={modalOpen === 'moreMenu' ? handleModalDismiss : handleMoreToggle}
                            isOpen={modalOpen === 'moreMenu'}
                            trigger={
                                <View style={themedStyle.moreButton}>
                                    <Icon name={'moreFilled'} color="black" />
                                </View>
                            }
                        />
                    ) : undefined
                }
                style={themedStyle.navHeader}
                title={t('activities:title')}
                titleLeft
                safeArea
            />
            <QueryItemView
                onRefresh={() => {
                    refetch();
                    refetchSignedUp();
                }}
                isRefreshing={isRefetching || isRefetchingSignedUpUsers}
                style={themedStyle.main}
                virtualized
            >
                {activity.approved && (
                    <View style={themedStyle.bookedContainer}>
                        <UserListItem
                            title={getUsernameFromProfile(profile ?? null)}
                            desc={t('activityDetail:you_booked', { count: activity.approved_sum ?? 0 })}
                            descColor={theme.secondaryText}
                            id={profile?.id ?? 0}
                            containerStyle={themedStyle.containerStyle}
                        />
                    </View>
                )}
                <FullWidthPictureCarousel
                    onEditPlaceholder={isArranger ? handleEdit : undefined}
                    pictures={activity.pictures}
                    showPlaceholder={Boolean(isArranger)}
                />
                <View style={themedStyle.textContainer}>
                    <OverviewLabel label={activity.title} size="big" />
                    <ReadMore
                        numberOfLines={10}
                        maxFontSizeMultiplier={2}
                        textStyle={themedStyle.contentDesc}
                        text={activity.description}
                    />
                    <DateTimeExplainer
                        start={moment.unix(activity.start_at).toDate()}
                        end={moment.unix(activity.end_at).toDate()}
                    />
                    {activity.last_confirm_at ? (
                        <IconWithValue
                            icon="clockCheck"
                            style={themedStyle.iconValueStyle}
                            hasValue
                            text={t('activityDetail:registrationDeadline', {
                                datetime: moment(
                                    parseNullableUnixTimeCodeToDate(activity.last_confirm_at) as Date,
                                ).format('DD. MMM  HH:mm'),
                            })}
                        />
                    ) : null}
                    {activity.last_cancel_at ? (
                        <IconWithValue
                            icon="clockCross"
                            style={themedStyle.iconValueStyle}
                            hasValue
                            text={t('activityDetail:cancellation', {
                                datetime: moment(
                                    parseNullableUnixTimeCodeToDate(activity.last_cancel_at) as Date,
                                ).format('DD. MMM  HH:mm'),
                            })}
                        />
                    ) : null}

                    {activity.location ? (
                        <>
                            <OverviewLabel label={t('activityDetail:location')} />
                            <IconWithValue
                                icon="location"
                                hasValue={Boolean(activity.location)}
                                text={activity.location}
                            />
                        </>
                    ) : null}

                    <Text style={themedStyle.contentSubTitle}>{t('activityDetail:contact')}</Text>
                    {activity.arrangers.map((arranger) => (
                        <UserListItem
                            key={arranger.id}
                            icon="award"
                            title={arranger.name}
                            desc={
                                arranger.id === ownId ? t('activityDetail:organizer') : t('activityDetail:nonOrganizer')
                            }
                            descColor={theme.error}
                            id={arranger.id}
                            actionButton={arranger.id === ownId ? undefined : <ChatActionButton userId={arranger.id} />}
                        />
                    ))}

                    <AttendeesList activity={activity} />
                    {(activity.user_groups?.length ?? 0) > 0 ? (
                        <>
                            <Text style={themedStyle.contentSubTitle}>{t('activityDetail:related_groups')}</Text>
                            <GroupList filter={activity.user_groups?.map(({ id }) => id) ?? []} groupScreen="Related" />
                        </>
                    ) : null}
                </View>
                {modalOpen === 'cancel' ? (
                    <CancelActivityModal
                        onDismiss={handleModalDismiss}
                        onCancel={handleCancelConfirmClick}
                        hasCost={activity.paymentStrategy.price !== 0}
                        hasAttendees={activity.approved_total_sum !== 0}
                    />
                ) : null}
                {modalOpen === 'alert' ? (
                    <SendActivityAlertModal activityId={activity.id ?? 0} onDismiss={handleModalDismiss} />
                ) : null}
                {modalOpen === 'signUpSelf' ? (
                    <SignUpSelfModal activity={activity} onDismiss={handleModalDismiss} />
                ) : null}
                <BottomSpacer />
            </QueryItemView>
            <View style={{ backgroundColor: theme.mainBackground }}>
                {isArranger ? (
                    <SecondaryButton
                        onPress={handleEdit}
                        style={themedStyle.bottomButton}
                        text={t('activityDetail:edit_activity').toUpperCase()}
                    />
                ) : (
                    <BookButton activity={activity} />
                )}
                <BottomSpacer />
            </View>
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        navHeader: { backgroundColor: theme.mainBackground },
        main: {
            backgroundColor: theme.mainBackground,
            paddingTop: screenMargin,
        },
        containerStyle: { paddingTop: 0, paddingBottom: 0 },
        moreButton: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            paddingTop: screenMargin / 2,
            paddingBottom: screenMargin / 2,
            minHeight: 40,
        },
        textContainer: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
        },
        bookedContainer: {
            backgroundColor: theme.shadow,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            marginBottom: WW * 0.01,
            marginLeft: screenMargin,
            marginRight: screenMargin,
        },
        contentDesc: {
            fontFamily: _fonts.primaryFont,
            color: theme.secondaryText,
            fontSize: WW * 0.04,
            lineHeight: WW * 0.04 * 1.5,
        },
        contentSubTitle: {
            marginTop: WH * 0.02,
            fontFamily: _fonts.primaryFontBold,
            color: theme.black,
            fontSize: WW * 0.045,
        },
        bottomButton: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            marginBottom: smallestMargin,
            marginTop: smallestMargin,
        },
        iconValueStyle: { marginTop: WW * 0.03 },
    });

export default ActivityLoadedGate;
