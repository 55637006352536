import { useGetUser } from '_api/useUsers';
import { useSelectedCoop } from '../../SelectedCoop';
import { ExternalUserProfile, isNonDeletedUser } from '../../types/User';

const useUserApartments = (): ((
    user: ReturnType<typeof useGetUser>,
) => NonNullable<ExternalUserProfile['cooperative_apartments']>) => {
    const selectedCoopId = useSelectedCoop();
    return (user) => {
        if (!isNonDeletedUser(user)) {
            return [];
        }
        return user?.cooperative_apartments?.filter((apartment) => apartment.cooperative_id === selectedCoopId) ?? [];
    };
};

export default useUserApartments;
