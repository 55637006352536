import { Edge } from 'react-native-safe-area-context';

const isEdge = (item: unknown): item is Edge => {
    if (typeof item !== 'string' || !item) {
        return false;
    }

    return ['top', 'right', 'bottom', 'left'].includes(item);
};

export default isEdge;
