import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Text } from 'react-native';
import { Message } from 'types/message';
import { FeedbackList, ProfileAvatar } from './Components';
import { useGetMessage } from '../../../../../_api/useMessages';
import _fonts from '../../../../../_fonts';
import { Theme, useThemeStyle, WH, WW } from '../../../../../_utils';
import { getUsernameFromProfile } from '../../../../../_utils/misc';
import { screenMargin } from '../../../../../_utils/sizes';
import { HeimeText, QueryItemView, Tabs } from '../../../../../Components';
import { ExternalUserProfile } from '../../../../../types/User';

interface HelperProfile {
    profile: ExternalUserProfile | null;
    apartments: string | undefined;
    message: Message;
}

const HelperProfile = ({ message, profile, apartments }: HelperProfile): ReactElement => {
    const { refetch, isRefetching } = useGetMessage(message.id);
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const rating = profile?.rating ?? 0;
    return (
        <View style={themedStyle.main}>
            <View style={themedStyle.centered}>
                {profile && (
                    <>
                        <ProfileAvatar profile={profile} />
                        <HeimeText maxFontSizeMultiplier={2} linkify style={themedStyle.nameStyle}>
                            {message.title}
                        </HeimeText>
                    </>
                )}
            </View>
            <View style={themedStyle.rowContainer}>
                <Text style={themedStyle.interest}>{profile ? getUsernameFromProfile(profile) : ''}</Text>
                {apartments ? (
                    <Text numberOfLines={1} style={themedStyle.aptStyle}>
                        {'  •  ' + apartments}
                    </Text>
                ) : null}
            </View>
            <View style={themedStyle.ratingView}>
                <Text numberOfLines={2} style={themedStyle.ratingText}>
                    {rating <= 0
                        ? t('sharingSelected:helperProfile:no_star')
                        : t('sharingSelected:helperProfile:stars', { count: profile?.rating ?? 0 })}
                </Text>
            </View>
            <Tabs
                tabs={[
                    {
                        title: t('sharingSelected:helperProfile:howCanIHelp').toUpperCase(),
                        content: (
                            <QueryItemView virtualized onRefresh={refetch} isRefreshing={isRefetching}>
                                <HeimeText maxFontSizeMultiplier={2} linkify style={themedStyle.helpText}>
                                    {message.content}
                                </HeimeText>
                            </QueryItemView>
                        ),
                    },
                    {
                        title: t('sharingSelected:helperProfile:feedback').toUpperCase(),
                        content: <FeedbackList userId={message.creator_id} />,
                    },
                ]}
                style={themedStyle.main}
            />
        </View>
    );
};
const styles = (theme: Theme) =>
    StyleSheet.create({
        centered: {
            alignItems: 'center',
            justifyContent: 'space-evenly',
        },
        main: {
            flex: 1,
            backgroundColor: theme.mainBackground,
        },
        ratingText: {
            fontFamily: _fonts.primaryFont,
            color: theme.secondary,
            fontSize: WW * 0.03,
        },
        interest: {
            textAlign: 'center',
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            color: theme.black,
        },
        ratingView: {
            alignSelf: 'center',
            marginTop: WW * 0.02,
            backgroundColor: theme.lightGrey,
            borderRadius: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
        },
        aptStyle: {
            textAlign: 'center',
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            color: theme.secondary,
        },
        nameStyle: {
            color: theme.darkGreen,
            marginTop: WH * 0.01,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.06,
            marginLeft: screenMargin,
            marginRight: screenMargin,
        },
        rowContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        helpText: {
            fontFamily: _fonts.primaryFont,
            fontSize: WH * 0.02,
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            color: theme.secondaryText,
        },
    });
export default HelperProfile;
