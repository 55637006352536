import React, { ComponentProps, ReactElement } from 'react';
import Toast, { ToastConfig as ToastConfigType } from 'react-native-toast-message';
import ToastMessage from 'Components/ToastMessage';

interface ShowOptions {
    type: ComponentProps<typeof ToastMessage>['type'];
    header: string;
    text: string;
    position?: 'top' | 'bottom';
    /**
     * If true, the toast will only be visible for 1 second
     * If false (default), the toast will be visible for 3 seconds
     */
    shortTime?: boolean;
    keyboardOffset?: number;
    visibilityTime?: number;
}

const showToast = ({
    type,
    header,
    text,
    position = 'top',
    shortTime,
    keyboardOffset,
    visibilityTime,
}: ShowOptions): void => {
    Toast.show({
        type,
        text1: header,
        text2: text,
        position,
        visibilityTime: visibilityTime ?? (shortTime ? 1000 : 3000),
        keyboardOffset,
    });
};

const ToastConfig: ToastConfigType = {
    success: ({ text1 = '', text2 = '' }) => <ToastMessage header={text1} text={text2} type="success" />,
    error: ({ text1 = '', text2 = '' }) => <ToastMessage header={text1} text={text2} type="error" />,
    info: ({ text1 = '', text2 = '' }) => <ToastMessage header={text1} text={text2} type="info" />,
};

const ToastContainer = (): ReactElement => <Toast config={ToastConfig} />;

export { ToastContainer, showToast };
