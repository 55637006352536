import React, { ReactElement } from 'react';
import { Text, StyleSheet, View, TouchableOpacity } from 'react-native';
import _fonts from '../../../_fonts';
import { useAppNavigation } from '../../../_navigator';
import { Theme, useThemeStyle, WW } from '../../../_utils';

interface HeaderWithActionsProps {
    title: string;
    action?: ReactElement | null;
}

const HeaderWithActions = ({ title, action }: HeaderWithActionsProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { goBack } = useAppNavigation();

    return (
        <View style={themedStyle.row}>
            <Text style={themedStyle.main}>{title}</Text>
            <TouchableOpacity onPress={() => goBack()} style={themedStyle.actionWrapper}>
                {action}
            </TouchableOpacity>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        row: { paddingTop: WW * 0.02, paddingBottom: WW * 0.02, flexDirection: 'row' },
        main: {
            color: theme.main,
            fontSize: WW * 0.04,
            fontFamily: _fonts.primaryFontBold,
            flex: 1,
            // Left icon and
            paddingLeft: WW * 0.05,
            paddingTop: WW * 0.012,
            paddingBottom: WW * 0.012,
        },
        actionWrapper: { alignItems: 'center', marginLeft: WW * 0.04, marginRight: WW * 0.04 },
    });

export default HeaderWithActions;
