import { ReactElement, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { screenMargin } from '_utils/sizes';
import { useLeaveGroup } from '../../../_api/useGroups';
import { isTruthy, WH } from '../../../_utils';
import { ThemeContext } from '../../../_utils/themeContext';
import { ContextMenu, ContextTrigger, Loader, UserListItem } from '../../../Components';
import { GenericCollectionItem } from '../../../types/Utility';

type GroupUser = {
    id: number;
    name: string;
    avatar?: string;
    menuOpened?: boolean;
};

interface MemberListProps {
    users: GroupUser[];
    admins: GenericCollectionItem[];
    ownId: number;
    groupId: number;
    showActionButton?: boolean;
    onSetAdmin?: (user: GroupUser) => void;
}

const Memberlist = ({
    users,
    admins,
    ownId,
    groupId,
    showActionButton = true,
    onSetAdmin,
}: MemberListProps): ReactElement => {
    const userIsAdmin = admins.map((admin) => admin.id).includes(ownId);
    const [userMenu, setUserMenu] = useState<number | null>(-1);

    return (
        <View style={styles.main}>
            {users.map((user) => {
                const showMenu = () => {
                    setUserMenu((curr) => (curr === user.id ? null : user.id));
                };
                const memberIsAdmin = admins.map((admin) => admin.id).includes(user.id);
                const isSelf = user.id === ownId;
                return (
                    <MemberListItem
                        key={user.id}
                        {...user}
                        menuOpened={userMenu === user.id}
                        groupId={groupId}
                        isSelf={isSelf}
                        memberIsAdmin={memberIsAdmin}
                        userIsAdmin={userIsAdmin}
                        showButton={showActionButton && (admins.length > 1 || !isSelf || !userIsAdmin)}
                        onMorePress={showMenu}
                        onSetAdmin={onSetAdmin}
                    />
                );
            })}
        </View>
    );
};

interface MemberListItemProp {
    id: number;
    groupId: number;
    name: string;
    isSelf: boolean;
    memberIsAdmin: boolean;
    userIsAdmin: boolean;
    showButton: boolean;
    menuOpened: boolean;
    onMorePress?: () => void;
    onSetAdmin?: (user: GroupUser) => void;
}

const MemberListItem = ({
    id,
    groupId,
    isSelf,
    memberIsAdmin,
    userIsAdmin,
    name,
    showButton,
    menuOpened,
    onMorePress,
    onSetAdmin,
}: MemberListItemProp) => {
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const { mutate: leaveGroup, isPending: isLeavingGroup } = useLeaveGroup();
    const subtitle = isSelf
        ? t('group:groupSelected:you')
        : memberIsAdmin
          ? t('group:groupSelected:admin')
          : t('group:groupSelected:member');

    const more = onMorePress ? (
        <ContextMenu
            onToggle={onMorePress}
            isOpen={menuOpened}
            trigger={<ContextTrigger />}
            actions={[
                isSelf
                    ? {
                          text: t('group:groupSelected:leave_group'),
                          onPress: () => {
                              leaveGroup(groupId);
                              onMorePress();
                          },
                      }
                    : userIsAdmin && onSetAdmin
                      ? {
                            text: t('group:groupSelected:set_admin', { name: name }),
                            onPress: () => {
                                onSetAdmin({ id: id, name: name });
                                onMorePress();
                            },
                        }
                      : undefined,
            ].filter(isTruthy)}
        />
    ) : undefined;

    return (
        <UserListItem
            title={name}
            desc={subtitle}
            descColor={memberIsAdmin && !isSelf ? theme.lightRed : undefined}
            id={id}
            icon={memberIsAdmin ? 'award' : undefined}
            actionButton={
                showButton ? (
                    isLeavingGroup ? (
                        <Loader />
                    ) : isSelf || (userIsAdmin && !memberIsAdmin) ? (
                        more
                    ) : undefined
                ) : undefined
            }
            disableProfileNavigate={!showButton}
        />
    );
};

const styles = StyleSheet.create({
    main: {
        flex: 1,
        marginBottom: WH * 0.06,
        marginLeft: screenMargin,
        marginRight: screenMargin,
    },
});

export default Memberlist;
