export const fetchBlob = () => {
    throw new Error('fetchBlob not implemented on web');
};

export const fetchWrap = () => {
    throw new Error('fetchWrap not implemented on web');
};

export const fetchBlobWithCache = () => {
    throw new Error('fetchBlobWithCache not implemented on web');
};
