import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useGetFiles } from '../../../_api/useChats';
import { WH } from '../../../_utils';
import { flattenIniniteResult } from '../../../_utils/misc';
import { Loader, MediaListView } from '../../../Components';

interface MediaListProps {
    type: 'media' | 'files';
    chatId: number;
}

const MediaList = ({ type, chatId }: MediaListProps): ReactElement => {
    const { t } = useTranslation();
    const { data, isLoading, ...rest } = useGetFiles(chatId);
    const files = useMemo(() => flattenIniniteResult(data), [data]);

    if (isLoading) {
        return (
            <View style={styles.loader}>
                <Loader />
            </View>
        );
    }

    return (
        <MediaListView
            type={type}
            files={files}
            emptyText={type === 'files' ? t('chatInfo:no_files_message') : t('chatInfo:no_media_message')}
            {...rest}
        />
    );
};

const styles = StyleSheet.create({
    list: { flex: 1 },
    loader: {
        height: '100%',
        paddingBottom: WH * 0.5,
    },
});

export default MediaList;
