import React, { ComponentProps, ReactElement } from 'react';
import { StyleSheet } from 'react-native';
import { Edge } from 'react-native-safe-area-context';
import { SafeAreaView } from '../_dependencies/safeArea';
import { isTruthy, Theme, useThemeStyle } from '../_utils';

interface OpinionatedSafeAreaProps extends Omit<ComponentProps<typeof SafeAreaView>, 'edges'> {
    isInModal?: boolean;
}

const OpinionatedSafeArea = ({ children, ...props }: OpinionatedSafeAreaProps): ReactElement => {
    const themeStyles = useThemeStyle(styles);

    const edges: Edge[] = (['bottom', 'left', 'right', undefined] as const).filter(isTruthy);

    return (
        <SafeAreaView style={[themeStyles.main, props.style]} edges={edges}>
            {children}
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            display: 'flex',
            height: '100%',
            backgroundColor: theme.mainBackground,
        },
    });

export default OpinionatedSafeArea;
