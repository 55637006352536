import React, { MutableRefObject, ReactElement, useEffect, useMemo, useRef, useContext } from 'react';
import { NativeSyntheticEvent, StyleSheet, TextInput, TextInputKeyPressEventData, View } from 'react-native';
import { Theme, useThemeStyle, WH, WW, useIsFocused } from '../_utils';
import { ThemeContext } from '../_utils/themeContext';

type statusType = 'loading' | 'error' | null;

interface PinInputProps {
    onCodeChange(newCode: string): void;
    code: string;
    status: statusType;
    pinStep?: 'CreatePin' | 'RepeatPin';
    isOtp?: boolean;
}

const PinInput = ({ onCodeChange, code, status, pinStep, isOtp }: PinInputProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const ref1 = useRef<TextInput | null>(null),
        ref2 = useRef<TextInput | null>(null),
        ref3 = useRef<TextInput | null>(null),
        ref4 = useRef<TextInput | null>(null);
    const inputReferences = useMemo(() => [ref1, ref2, ref3, ref4], []);

    useEffect(() => {
        if (status === 'error') {
            inputReferences[0].current?.focus();
        }
    }, [inputReferences, status]);

    useEffect(() => {
        if (pinStep) {
            inputReferences[0].current?.focus();
        }
    }, [inputReferences, pinStep]);

    const updatePin = (position: number, number: string) => {
        if (number) {
            const newCode = code.substr(0, position) + number + code.substr(position + number.length);

            onCodeChange(newCode);
            if (position + number.length < inputReferences.length) {
                inputReferences[position + number.length].current?.focus();
            }
        }
    };

    const handleKeyPress = (position: number) => {
        if (code.length >= position) {
            onCodeChange(code.slice(0, position));
        }

        if (position !== 0) {
            inputReferences[position - 1].current?.focus();
        }
    };

    return (
        <View style={themedStyle.pins}>
            {inputReferences.map((ref, index) => {
                const handleChange = (text: string) => updatePin(index, text);
                const handleBackspace = () => handleKeyPress(index);
                return (
                    <SingularInput
                        key={`${pinStep}-${index}`}
                        first={index === 0}
                        value={code[index]}
                        onChange={handleChange}
                        onBackspace={handleBackspace}
                        focusRef={ref}
                        status={status}
                        textType={isOtp ? 'oneTimeCode' : 'off'}
                        index={index}
                    />
                );
            })}
        </View>
    );
};

interface SingularInputProps {
    first: boolean;
    value: string;
    onChange(text: string): void;
    onBackspace(): void;
    focusRef: MutableRefObject<TextInput | null>;
    status: statusType;
    textType: 'oneTimeCode' | 'off';
    index?: number;
}
const SingularInput = ({
    first,
    value,
    onChange,
    onBackspace,
    focusRef,
    status,
    textType,
    index,
}: SingularInputProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { isFocused, handleFocus, handleBlur } = useIsFocused();
    const handleKeyPress = ({ nativeEvent: { key } }: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
        if (key === 'Backspace') {
            onBackspace();
        }
    };

    const loading = status === 'loading';
    const isError = status === 'error';

    const addInitialCode = first && textType === 'oneTimeCode';
    const textContentType = addInitialCode ? 'oneTimeCode' : 'none';
    const autoComplete = addInitialCode ? 'sms-otp' : 'off';

    return (
        <TextInput
            value={value}
            style={[
                themedStyle.input,
                value ? themedStyle.inputFilled : themedStyle.inputEmpty,
                isFocused && !loading && themedStyle.focused,
                isError && themedStyle.inputError,
                loading && themedStyle.inputLoading,
            ]}
            ref={focusRef}
            textAlign="center"
            selectTextOnFocus={true}
            onChangeText={onChange}
            onKeyPress={handleKeyPress}
            onFocus={handleFocus}
            onBlur={handleBlur}
            textContentType={textContentType}
            autoComplete={autoComplete}
            autoFocus={first}
            selectionColor={loading ? 'white' : theme.secondary}
            returnKeyType={undefined}
            keyboardType="number-pad"
            autoCorrect={false}
            testID={`pinInput-${index}`}
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        pins: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            paddingLeft: WW * 0.1,
            paddingRight: WW * 0.1,
        },
        input: {
            marginBottom: WH * 0.02,
            width: WW * 0.12,
            borderWidth: 1,
            fontSize: WH * 0.04,
            paddingTop: WH * 0.02,
            paddingBottom: WH * 0.02,
            color: theme.black,
            textAlign: 'center',
        },
        inputLoading: { opacity: 0.25 },
        inputError: { backgroundColor: theme.errorLight, borderColor: theme.error },
        inputFilled: { borderColor: theme.main },
        focused: {
            shadowOffset: { height: 4, width: 0 },
            shadowRadius: 4,
            shadowOpacity: 0.25,
            shadowColor: theme.black,
            backgroundColor: theme.mainBackground,
            borderColor: theme.secondary,
        },
        inputEmpty: { borderColor: theme.secondaryLight },
    });

export default PinInput;
