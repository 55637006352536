import React, { ReactElement, useContext, useEffect } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import Images from '_images';
import useAppNavigation from '_navigator/hooks/useAppNavigation';
import { Theme, useThemeStyle, WH, WW } from '_utils';
import { screenMargin } from '_utils/sizes';
import { ThemeContext } from '_utils/themeContext';
import CacheImage from './CacheImage';
import HeimeText from './HeimeText';

export interface OnboardingHeaderProps {
    currentStep: number;
    isHideBack?: boolean;
    maxSteps: number;
    text: string;
    bold?: boolean;
    onGoBack?: () => void;
    backgroundColor?: keyof Theme;
}

const StepHeader = (props: OnboardingHeaderProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { goBack } = useAppNavigation();
    const width = useSharedValue(0);

    useEffect(() => {
        width.value = withTiming((WW / props.maxSteps) * props.currentStep, {
            easing: Easing.inOut(Easing.ease),
        });
    }, [props.currentStep, props.maxSteps, width]);

    const widthStyle = useAnimatedStyle(() => ({
        width: width.value,
    }));

    return (
        <View
            style={[themedStyle.main, props.backgroundColor ? { backgroundColor: theme[props.backgroundColor] } : null]}
        >
            <View>
                <View style={themedStyle.containerHeader}>
                    {props.isHideBack ? (
                        <View style={themedStyle.buttonBack} />
                    ) : (
                        <TouchableOpacity style={themedStyle.buttonBack} onPress={props.onGoBack ?? (() => goBack())}>
                            <CacheImage resizeMode={'contain'} source={Images.chevron} style={themedStyle.arrow} />
                        </TouchableOpacity>
                    )}
                    <HeimeText style={[themedStyle.textHeader, themedStyle.boldText]}>{props.text}</HeimeText>
                </View>
                <View style={themedStyle.stepContainer}>
                    <Animated.View style={[themedStyle.step, widthStyle]} />
                </View>
            </View>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            width: WW,
            paddingBottom: screenMargin,
            backgroundColor: theme.white,
        },
        containerHeader: {
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 10,
        },
        buttonBack: {
            width: 40,
            alignItems: 'flex-end',
            height: 40,
            zIndex: 1,
            justifyContent: 'center',
            flexGrow: 0,
        },
        arrow: {
            width: 20,
            height: 20,
            tintColor: theme.main,
            marginRight: WH * 0.01,
            transform: [{ rotate: '180deg' }],
        },
        textHeader: {
            textAlign: 'center',
            color: theme.darkGreen,
            fontSize: 14,
            flex: 1,
        },
        boldText: {
            fontWeight: 'bold',
        },
        step: {
            height: 3,
            backgroundColor: theme.darkGreen,
        },
        stepContainer: {
            backgroundColor: theme.lightGreen,
            height: 3,
        },
    });

export default StepHeader;
