import { Fragment, ReactElement } from 'react';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import getMissingScreens from '_navigator/hooks/getMissingScreens';
import getParentsArray from '_navigator/hooks/getParentsArray';
import { getNonScreens } from '_navigator/navigationConfiguration/screenConfiguration';
import { NonScreen } from '_navigator/navigationConfiguration/types';
import { TabButton } from '.';

const navButtons = ({
    state,
    descriptors,
    navigation,
    resetNav,
}: BottomTabBarProps & { resetNav: (toReset?: string[] | undefined) => void }): ReactElement[] => {
    return state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label =
            options.tabBarLabel !== undefined
                ? options.tabBarLabel
                : options.title !== undefined
                  ? options.title
                  : route.name;

        const isFocused = state.index === index;

        const onPress = () => {
            const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
                canPreventDefault: true,
            });

            const screenName = route.name;
            const nonScreens = getNonScreens();
            const isNonScreen = (innerScreenName: string): innerScreenName is NonScreen =>
                innerScreenName in nonScreens;
            if (isNonScreen(screenName)) {
                const defaultRoute = nonScreens[screenName].defaultRoute;
                const missingScreens = getMissingScreens(state.routes ?? [], getParentsArray(defaultRoute, null));

                if (missingScreens.length > 0) {
                    navigation.navigate(screenName, { screen: defaultRoute });
                    event.preventDefault();
                }
            }
            const stacks = Object.entries(nonScreens)
                .filter(([name, item]) => item.parent === 'Main' && name !== screenName)
                .map(([name]) => name);
            // When pressing a tab we reset all the others
            resetNav(stacks);

            if (!isFocused && !event.defaultPrevented) {
                // The `merge: true` option makes sure that the params inside the tab screen are preserved
                navigation.navigate({ name: route.name, merge: true, params: {} });
            }
        };

        const onLongPress = () => {
            navigation.emit({
                type: 'tabLongPress',
                target: route.key,
            });
        };

        return (
            <Fragment key={label + ''}>
                <TabButton
                    options={options}
                    isFocused={isFocused}
                    onPress={onPress}
                    onLongPress={onLongPress}
                    routeName={route.name}
                    badgeCount={options.tabBarBadge ? parseInt(options.tabBarBadge + '', 10) : undefined}
                />
            </Fragment>
        );
    });
};

export default navButtons;
