import i18next from 'i18next';
import { ChatInfo } from '_api/useChats';

const getChatName = (data: ChatInfo, ownId: number, t: typeof i18next.t): string => {
    if (data.name) {
        return data.name;
    }
    const otherMembersName = data.users.filter(({ id }) => id !== ownId).map(({ name }) => name);

    if (otherMembersName.length === 1) {
        return otherMembersName[0] ?? '';
    } else {
    }

    const nNamesToShow = 3;
    if (otherMembersName.length > nNamesToShow + 1) {
        const usersToDisplay = otherMembersName.filter((usr, index) => index < nNamesToShow);
        return (
            usersToDisplay.join(', ') +
            ' ' +
            t('global:and') +
            t('global:xOthers', { count: otherMembersName.length - nNamesToShow })
        );
    }
    if (otherMembersName.length === 0) {
        return t('chat:onlyYou');
    }
    const usersExceptLast = otherMembersName.filter((usr, index) => index < otherMembersName.length - 1);
    return usersExceptLast.join(', ') + ' ' + t('global:and') + otherMembersName[otherMembersName.length - 1];
};

export default getChatName;
