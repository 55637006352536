import { PropsWithChildren, ReactElement, createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import { AppState } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useSettings, useRemoveSettings } from '_api/useSettings';
import { GlobalState } from '_redux';
import { registerAppStart, resetAppStarts } from '_redux/timeSync';
import { useTimeChangedEvent } from '_utils';
import { addBreadcrumb } from '_utils/Sentry';

const valuesIsSync = (current_time: string | undefined, dataUpdatedAt: number) =>
    current_time && Math.abs(moment(current_time).diff(moment(dataUpdatedAt), 'minute', true)) <= 1;

type TimeSyncContext = {
    status: null | 'changedClockNegative' | 'serverAndPhoneOutOfSync' | 'noDataToCompare';
    refetch(): void;
    isLoading: boolean;
};
const Context = createContext<TimeSyncContext | undefined>(undefined);

/**
 * This hook keeps the time in sync with the server.
 */
const TimeSyncProvider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    // If time is in sync with settings on every app startup get current time from app and check that it has not drifted negative
    // If it has call error and block locks
    // If settings and data_updated_at has more than 1 minute difference, call error and block locks
    // If no times and no settings, call error and block locks
    // If we receive a time changed event from the mobile, fetch new data, if we can't fetch new data, call error and block locks
    const remove = useRemoveSettings();
    const { data, dataUpdatedAt, isLoading, refetch } = useSettings();
    const dispatch = useDispatch();
    const isLoadedInPast = moment(dataUpdatedAt).isBefore(moment().add({ minute: 1 }));
    const appStartsError = useSelector((state: GlobalState) => state.timeSync.appStartsError);
    const hasChangedTimeNegative = appStartsError || (data?.current_time && !isLoadedInPast);
    const isInSync = valuesIsSync(data?.current_time, dataUpdatedAt);

    const handleFocus = useCallback(() => {
        if (isInSync && isLoadedInPast) {
            dispatch(registerAppStart(moment().unix()));
        }
    }, [dispatch, isInSync, isLoadedInPast]);

    useFocusEffect(handleFocus);
    useEffect(() => {
        const listener = AppState.addEventListener('change', () => {
            handleFocus();
        });
        return () => listener.remove();
    });

    useEffect(() => {
        const asyncFunc = async () => {
            if (hasChangedTimeNegative) {
                // We remove the query so user is forced to refetch
                dispatch(resetAppStarts());
                remove();
                refetch({ cancelRefetch: false });
            }
        };
        asyncFunc();
    }, [dispatch, hasChangedTimeNegative, refetch, remove]);

    useTimeChangedEvent(
        useCallback(() => {
            remove();
            refetch({ cancelRefetch: false });
        }, [refetch, remove]),
    );

    const status = useMemo((): TimeSyncContext['status'] => {
        if (!data?.current_time) {
            return 'noDataToCompare';
        }
        if (!isLoadedInPast) {
            return 'changedClockNegative';
        }
        if (hasChangedTimeNegative) {
            return 'changedClockNegative';
        }
        if (!isInSync) {
            return 'serverAndPhoneOutOfSync';
        }
        return null;
    }, [data, hasChangedTimeNegative, isInSync, isLoadedInPast]);

    useEffect(() => {
        if (status && status !== 'noDataToCompare') {
            // Adding breadcrumb and capturing exception to get more info about the error
            addBreadcrumb(
                'timeChanged',
                JSON.stringify({
                    appStartsError,
                    dataUpdatedAt,
                    currLocalTime: moment().add({ minute: 1 }),
                    hasChangedTimeNegative,
                    isInSync,
                    isLoadedInPast,
                    data,
                }),
            );
        }
    }, [appStartsError, data, dataUpdatedAt, hasChangedTimeNegative, isInSync, isLoadedInPast, status]);

    const value = useMemo(
        () => ({ refetch: () => refetch({ cancelRefetch: false }), status, isLoading }),
        [isLoading, refetch, status],
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useTimeSync = (): TimeSyncContext => {
    const context = useContext(Context);
    if (!context) {
        throw new Error('useTimeSync must be used within a TimeSyncProvider');
    }
    return context;
};

export { TimeSyncProvider, useTimeSync };
