import React, { PropsWithChildren, ReactElement, useReducer, useContext, createContext, useMemo } from 'react';

function createContextFactory<
    GlobalState,
    Action,
    ActionsCreator extends (dispatch: React.Dispatch<Action>) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [k in string]: (...arg0: any[]) => void;
    },
    Context,
>(reducer: (state: GlobalState, action: Action) => GlobalState, actionsCreator: ActionsCreator) {
    const Context = createContext<
        ({ state: GlobalState; dispatch: React.Dispatch<Action>; context: Context } & ReturnType<ActionsCreator>) | null
    >(null);

    const Provider = ({
        children,
        initialState,
        context,
    }: PropsWithChildren<{ initialState: GlobalState; context: Context }>): ReactElement => {
        const [state, dispatch] = useReducer(reducer, initialState);

        const actions = useMemo(() => actionsCreator(dispatch), [dispatch]);

        const value = useMemo(() => {
            return { ...actions, state, dispatch, context } as {
                state: GlobalState;
                dispatch: React.Dispatch<Action>;
                context: Context;
            } & ReturnType<ActionsCreator>;
        }, [actions, context, state]);

        return <Context.Provider value={value}>{children}</Context.Provider>;
    };

    const useContextHook = () => {
        const context = useContext(Context);
        if (!context) {
            throw new Error('useContextHook must be used within its Provider');
        }
        return context;
    };

    return { Provider, useContextHook };
}

export default createContextFactory;
