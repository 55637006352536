import { QueryClient } from '@tanstack/react-query';
import { invalidateActivitiesQuery } from '_api/useActivities';
import { invalidateChatQueries } from '_api/useChats';
import { invalidateGroupsQueries } from '_api/useGroups';
import { invalidateMessageQueries } from '_api/useMessages';
import { invalidateSupportsQueries } from '_api/useSupports';
import { invalidateSurveyQueries } from '_api/useSurveys';
import { Notification } from 'types/Notification';

const invalidateDataBasedOnNotification = (type: Notification['type'], queryClient: QueryClient): void => {
    switch (type) {
        case '24HoursBeforeActivity':
        case 'ActivityInvite':
        case 'ArrangerAdded':
        case 'ArrangerRemoved':
        case '24HoursBeforeRegistrationDeadline':
        case 'ActivityCustomNotify':
        case 'CancelledActivity':
        case 'ActivityInvitedUsers': {
            invalidateActivitiesQuery(queryClient);
            return;
        }
        case 'NewChatMessage': {
            invalidateChatQueries(queryClient);
            return;
        }
        case 'UserGroupCreated': {
            invalidateGroupsQueries(queryClient);
            return;
        }
        case 'SupportCreated':
        case 'SupportStatus':
        case 'SupportNoteCreated': {
            invalidateSupportsQueries(queryClient);
            return;
        }
        case 'NewSurvey':
        case 'ReminderSurvey': {
            invalidateSurveyQueries(queryClient);
            return;
        }
        case 'NewMessageComment':
        case 'MessageCreated': {
            invalidateMessageQueries(queryClient);
            return;
        }
        case 'LockLowBattery': {
            return;
        }
    }
};

export default invalidateDataBasedOnNotification;
