import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack';

export type SurveyStackParams = {
    SelectApartment: undefined;
    SurveyDetails: { apartmentId: string | null };
    SurveyAnswer: { questionId: string; apartmentId: string | null };
    SurveyComplete: { apartmentId: string | null };
};

export type SurveyScreenProps<ScreenName extends keyof SurveyStackParams> = StackScreenProps<
    SurveyStackParams,
    ScreenName
>;

type SurveyNavigationProp = StackNavigationProp<SurveyStackParams>;

export const useSurveyNavigation = (): SurveyNavigationProp => useNavigation<SurveyNavigationProp>();
