import { NavigationDestination } from '_navigator/navigationConfiguration/types';

const getMissingScreens = (routes: MountedRoute[], parents: NavigationDestination[]): NavigationDestination[] => {
    let currRoutes = routes;
    return parents.reduce<NavigationDestination[]>((curr, val) => {
        const route = currRoutes.find((r) => r.name === val);
        if (route) {
            currRoutes = route?.state?.routes ?? [];
            return curr;
        } else {
            curr.push(val);
            return curr;
        }
    }, []);
};

type MountedRoute = { name: string; state?: { routes?: MountedRoute[] } };

export default getMissingScreens;
