import { useMemo } from 'react';
import { useGetUser, useGetUsers } from '_api/useUsers';
import { flattenIniniteResult } from '_utils/misc';
import { useSelectedCoop } from 'SelectedCoop';
import { TenantRole } from 'types/Apartment';
import { ExternalUserProfile, isNonDeletedUser } from 'types/User';

type UsersByRole = Record<TenantRole, (ExternalUserProfile & { role: TenantRole })[]>;

const useGetRoommates = (
    userId: number,
): (NonNullable<ExternalUserProfile['cooperative_apartments']>[number] & {
    usersByRole: UsersByRole;
    own_role: TenantRole;
})[] => {
    const selectedCoopId = useSelectedCoop();
    const { data: users } = useGetUsers(true);
    const user = useGetUser(userId);

    return useMemo(() => {
        if (!isNonDeletedUser(user)) {
            return [];
        }

        const flattenedUsers = flattenIniniteResult(users);
        const ownApartments = (user?.cooperative_apartments ?? []).filter(
            (item) => item.cooperative_id === selectedCoopId,
        );

        const usersWithRoles = ownApartments.map((app) => {
            const usersInApartment = flattenedUsers.map((external) => {
                if (external.id === userId) {
                    return null;
                }
                const foundApartment = (external?.cooperative_apartments ?? []).find(
                    (externalApp) => externalApp.cooperative_id === selectedCoopId && app.id === externalApp.id,
                );
                if (!foundApartment) {
                    return null;
                }
                const foundTenant = foundApartment.tenants.find(({ user_id }) => user_id === external.id);
                if (!foundTenant) {
                    throw new Error('Unexpected error: User is not a tenant in the apartment');
                }

                return {
                    ...external,
                    role: foundTenant.tenant_role,
                    apartmentId: foundApartment.id,
                };
            });

            // Group users by role within each apartment
            const usersByRole = {} as UsersByRole;
            usersInApartment.forEach((apartmentUser) => {
                if (!apartmentUser) {
                    return;
                }

                const role = apartmentUser.role;
                if (!usersByRole[role]) {
                    usersByRole[role] = [];
                }
                usersByRole[role].push(apartmentUser);
            });
            const userTenant = app.tenants.find(({ user_id }) => user_id === userId);
            if (!userTenant) {
                throw new Error('Unexpected error: User is not a tenant in the apartment');
            }

            return {
                ...app,
                own_role: userTenant.tenant_role,
                usersByRole,
            };
        });

        return usersWithRoles.filter(({ usersByRole }) => {
            // Filter out apartments where no other users are present after grouping by role
            for (const neighbor in Object.values(usersByRole)) {
                if (neighbor.length > 0) {
                    return true;
                }
            }
            return false;
        });
    }, [user, userId, selectedCoopId, users]);
};
export default useGetRoommates;
