import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { useGetMessages } from '_api/useMessages';
import { useOwnProfile } from '_api/useProfile';
import _fonts from '_fonts';
import { useAppNavigation } from '_navigator';
import { Theme, useThemeStyle, WW } from '_utils';
import { flattenIniniteResult } from '_utils/misc';
import { screenMargin } from '_utils/sizes';
import { HeaderWithNav, MyThingsButton } from 'Components';
import { MessageType } from 'types/message';
import { MessageList, useSharingContextMenuEffect } from '../common';

interface SharingCategorySelectedProps {
    types: Exclude<MessageType, MessageType.Requests | MessageType.ByBoard>[];
    header: string;
    emptyText: string;
    showMyThings?: MessageType.Products;
}

const SharingCategorySelected = ({
    types,
    header,
    emptyText,
    showMyThings,
}: SharingCategorySelectedProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const { data: initialItems } = useGetMessages(types);
    const ownId = useOwnProfile()?.data?.id;
    useSharingContextMenuEffect(types);

    const ownMessages = useMemo(
        () =>
            flattenIniniteResult(initialItems).filter(
                (message) => message.type === showMyThings && message.creator_id === ownId,
            ),
        [initialItems, ownId, showMyThings],
    );

    return (
        <View style={themedStyle.main}>
            <HeaderWithNav title={header} safeArea />
            <MessageList
                top={
                    showMyThings === MessageType.Products ? (
                        <MyThingsButton
                            title={t('sharingAll:myProducts')}
                            message={
                                ownMessages.length === 1
                                    ? t('sharingAll:products:singular')
                                    : `${ownMessages.length} ${t('sharingAll:products:plural')}`
                            }
                            onPress={() => navigate('OwnSharingCategory', { type: showMyThings })}
                            icon="tag"
                        />
                    ) : undefined
                }
                emptyText={emptyText}
                messageTypes={types}
                numColumns={2}
            />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
        itemMargin: {
            marginRight: WW * 0.06,
            marginBottom: WW * 0.06,
        },
        listContainer: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            width: '100%',
            flex: 1,
        },
        emptyContainer: {
            paddingTop: WW * 0.05,
            paddingBottom: WW * 0.05,
            paddingLeft: WW * 0.05,
            paddingRight: WW * 0.05,
            backgroundColor: theme.chatMyMessageBubble,
            borderRadius: 15,
        },
        emptyText: {
            fontFamily: _fonts.primaryFont,
        },
    });

export default SharingCategorySelected;
