import moment from 'moment';

const fromNowFormat = (date: Date, withTime = false) => {
    const arg = moment(date);
    if (moment().diff(arg, 'days') < 8) {
        return arg.fromNow();
    }
    const timeString = withTime ? ' HH:mm' : '';
    const isSameYear = moment().diff(arg, 'year') !== 1;
    if (isSameYear) {
        return arg.format('D. MMMM' + timeString);
    }
    return arg.format('D. MMMM YYYY' + timeString);
};

export default fromNowFormat;
