import React, { ReactElement, useContext } from 'react';
import { View } from 'react-native';
import { ThemeContext } from '_utils/themeContext';
import icons from './IconFiles';
import { IconProps } from './props';

const Icon = ({ color, name, style, scale = 1 }: IconProps): ReactElement => {
    const IconComponent = icons[name];
    const { theme } = useContext(ThemeContext);

    const calcSize = scale * 24;
    const comp = <IconComponent fill={theme[color]} width={calcSize} height={calcSize} />;
    if (style) {
        return comp;
    }
    return <View style={style}>{comp}</View>;
};

export default Icon;
