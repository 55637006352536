import { useEffect, useMemo, useRef } from 'react';
import { useIsFocused } from '@react-navigation/native';
import {
    InfiniteData,
    MutationOptions,
    QueryClient,
    QueryKey,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { z } from 'zod';
import { addBreadcrumb } from '_utils/Sentry';
import { ApiImage } from 'types/Base';
import { ChatMessageSchema, ChatMessage, MessageFile } from 'types/Chat';
import { useOwnProfile } from './useProfile';
import { useLaravelInfinteQuery } from './utility';
import { getUrlExtension } from '../_utils';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { flattenIniniteResult } from '../_utils/misc';
import { useSelectedCoop } from '../SelectedCoop';
import { UserCollectionItemWithAvatar, UserCollectionItemWithAvatarSchema } from '../types/User';
import { FileToBeUploaded, GetFileToBeUploadedToApiFile, LaravelPagingResponse, UnixTimeCode } from '../types/Utility';

export interface Chat {
    id: number;
    name: string | null;
    user_group: RelatedGroup | null;
    creator_id: number;
    created_at: UnixTimeCode;
    users: UserCollectionItemWithAvatar[];
    not_read_messages_count: number;
    last_message: ChatMessage | null;
}

interface RelatedGroup {
    id: number;
    main_picture: ApiImage | null;
}
export interface ChatInfo {
    id: number;
    name: string | null;
    creator_id: number;
    user_group: RelatedGroup | null;
    users: UserCollectionItemWithAvatar[];
    created_at: UnixTimeCode;
}

export const getChatsAllQueryKey = (selectedCoopId: number): QueryKey => ['chats', selectedCoopId];

const invalidateChatMessage = (queryClient: QueryClient, selectedCoopId: number, chatId: number) => {
    queryClient.invalidateQueries({ queryKey: getChatsAllQueryKey(selectedCoopId) });
    queryClient.invalidateQueries({
        queryKey: ['chat', selectedCoopId, chatId],
    });
    queryClient.invalidateQueries({
        queryKey: ['chat', selectedCoopId, chatId, 'messages'],
    });
    queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'group' && query.queryKey[3] === 'files',
    });
};

const useGetChats = (): UseInfiniteQueryResult<InfiniteData<Chat[]>, string | Error> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();

    return useLaravelInfinteQuery(
        getChatsAllQueryKey(selectedCoopId),
        async ({ pageParam = 1 }) => {
            return await axios.get<LaravelPagingResponse<Chat[]>>(`cooperatives/${selectedCoopId}/chats`, {
                params: { page: pageParam },
                headers: { authorization: await getAuthHeader() },
            });
        },
        {
            gcTime: Infinity,
            staleTime: 1000 * 60 * 15,
        },
    );
};

export const invalidateChatQueries = (queryClient: QueryClient): void => {
    queryClient.invalidateQueries({
        queryKey: ['chat'],
    });
    queryClient.invalidateQueries({
        queryKey: ['chats'],
    });
    queryClient.invalidateQueries({
        queryKey: ['files'],
    });
};

const getChatQueryKey = (selectedCoopId: number, chatId: number) => ['chat', selectedCoopId, chatId];

const useGetChat = (chatId: number): UseQueryResult<ChatInfo, string | Error> => {
    const isFocused = useIsFocused();
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: getChatQueryKey(selectedCoopId, chatId),

        queryFn: async () => {
            const result = await axios.get<ChatInfo>(`cooperatives/${selectedCoopId}/chats/${chatId}`, {
                params: { no_chat_messages: 0 },
                headers: { authorization: await getAuthHeader() },
            });

            if (!result.data) {
                throw new Error('Result returned with no data');
            }
            return result.data;
        },

        gcTime: 1000 * 60 * 60,
        staleTime: 1000 * 60,
        enabled: isFocused,
        placeholderData: () => {
            const data = queryClient.getQueryData<InfiniteData<LaravelPagingResponse<Chat[]>>>(
                getChatsAllQueryKey(selectedCoopId),
            );
            const chat = data?.pages.reduce<Chat | undefined>(
                (curr, page) => curr ?? page.data.find(({ id }) => chatId === id),
                undefined,
            );
            if (chat) {
                return { ...chat };
            }
            return undefined;
        },
    });
};

const ChatMessageResponseSchema = z.object({
    messages: z.union([z.record(z.string(), z.array(ChatMessageSchema)), z.array(z.never())]),
    message_users: z.array(UserCollectionItemWithAvatarSchema),
});

type ChatMessagesResponse = z.infer<typeof ChatMessageResponseSchema>;

const useGetChatMessages = (
    chatId: number,
): UseInfiniteQueryResult<InfiniteData<ChatMessagesResponse>, string | Error> => {
    const isFocused = useIsFocused();
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();

    return useLaravelInfinteQuery(
        ['chat', selectedCoopId, chatId, 'messages'],
        async ({ pageParam }) =>
            await axios.get<LaravelPagingResponse<ChatMessagesResponse>>(
                `cooperatives/${selectedCoopId}/chats/${chatId}/messages`,
                {
                    params: { page: pageParam },
                    headers: { authorization: await getAuthHeader() },
                },
            ),

        {
            gcTime: 1000 * 60 * 60,
            staleTime: 1000 * 60,
            enabled: isFocused,
        },
        ChatMessageResponseSchema,
    );
};

const useLeaveChat = (): UseMutationResult<{ success: boolean }, string | Error, number> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (chatId: number) => {
            const result = await axios.delete<{ success: boolean }>(
                `cooperatives/${selectedCoopId}/chats/${chatId}/leave`,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Leave from chat return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: (_r, chatId) => {
            queryClient.resetQueries({ queryKey: getChatsAllQueryKey(selectedCoopId) });
            queryClient.invalidateQueries({ queryKey: getChatQueryKey(selectedCoopId, chatId) });
        },
    });
};

const useMakeAdmin = (): UseMutationResult<{ success: boolean }, string | Error, [number, number]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ([chatId, userId]) => {
            const result = await axios.patch<{ success: boolean }>(
                `cooperatives/${selectedCoopId}/chats/${chatId}/creator/${userId}`,
                undefined,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Leave from chat return unsuccessful result');
            }
            return result.data;
        },
        onSettled: (_r, _e, [chatId]) => {
            queryClient.invalidateQueries({ queryKey: getChatsAllQueryKey(selectedCoopId) });
            queryClient.invalidateQueries({ queryKey: getChatQueryKey(selectedCoopId, chatId) });
        },
    });
};

type Response = InfiniteData<LaravelPagingResponse<ChatMessagesResponse>>;

const useChatUpdate = (selectedCoopId: number) => {
    const queryClient = useQueryClient();
    const ref = useRef<Response | undefined>(undefined);

    return {
        update: (chatId: number, updateChat: (arg: Response) => Response) => {
            queryClient.setQueryData<Response | undefined>(['chat', selectedCoopId, chatId, 'messages'], (chatInfo) => {
                if (chatInfo) {
                    ref.current = JSON.parse(JSON.stringify(chatInfo));
                    return updateChat(chatInfo);
                }
                return chatInfo;
            });
        },
        reset: (chatId: number) => {
            queryClient.setQueryData<InfiniteData<LaravelPagingResponse<ChatMessagesResponse>> | undefined>(
                ['chat', selectedCoopId, chatId, 'messages'],
                () => (ref.current ? JSON.parse(JSON.stringify(ref.current)) : undefined),
            );
        },
    };
};

export interface MutateMessageBody {
    messageId?: number | null;
    text: string;
    files: FileToBeUploaded[];
}

const useSendMessage = (
    options?: Omit<
        MutationOptions<ChatMessage, string | Error, [number, MutateMessageBody]>,
        'mutationFn' | 'onMutate' | 'onSettled'
    >,
): UseMutationResult<ChatMessage, string | Error, [number, MutateMessageBody]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();
    const { data: ownProfile } = useOwnProfile();
    const { update, reset } = useChatUpdate(selectedCoopId);
    return useMutation({
        mutationFn: async ([chatId, message]: [number, MutateMessageBody]) => {
            const result = await axios.post<ChatMessage>(
                `cooperatives/${selectedCoopId}/chats/${chatId}`,
                {
                    ...message,
                    message_id: message.messageId ?? null,
                    files: message.files.map(({ name, uri }) => ({ name, data: uri })),
                },
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );
            if (!result.data) {
                throw new Error('message creation return unsuccessful result');
            }

            return result.data;
        },
        onMutate: ([chatId, message]) => {
            const newMessage: ChatMessage = {
                id: null,
                type: null,
                user: ownProfile?.id ?? 0,
                text: message.text,
                to_message: null,
                created_at: moment().unix(),
                edited: false,
                deleted: false,
                files: message.files.map(GetFileToBeUploadedToApiFile(ownProfile?.id ?? 0)),
            };
            const updateChat = (
                queryData: InfiniteData<LaravelPagingResponse<ChatMessagesResponse>>,
            ): InfiniteData<LaravelPagingResponse<ChatMessagesResponse>> => {
                // We know first page will contain message
                const [firstPage, ...restPages] = queryData.pages;
                const { data, ...restFirstPage } = firstPage;
                const { messages: messagesData, ...restData } = data;
                const messages = Array.isArray(messagesData) ? {} : messagesData;
                const newMessageDay = moment.unix(newMessage.created_at).format('DD.MM.YYYY');
                const day = (messages[newMessageDay] || []).slice();
                messages[newMessageDay] = [newMessage, ...day];

                return JSON.parse(
                    JSON.stringify({
                        pageParams: { ...queryData.pageParams },
                        pages: [{ ...restFirstPage, data: { ...restData, messages: { ...messages } } }, ...restPages],
                    }),
                );
            };

            update(chatId, updateChat);
        },
        onSettled: (_r, _e, [chatId]) => {
            invalidateChatMessage(queryClient, selectedCoopId, chatId);
        },
        onError: (_e, [chatId]) => {
            reset(chatId);
        },
        ...options,
    });
};

const useGetFiles = (chatId: number): UseInfiniteQueryResult<InfiniteData<MessageFile[]>, string | Error> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();

    return useLaravelInfinteQuery(
        ['files', selectedCoopId, chatId],
        async ({ pageParam = 1 }) => {
            return await axios.get<LaravelPagingResponse<MessageFile[]>>(
                `cooperatives/${selectedCoopId}/chats/${chatId}/files`,
                {
                    params: { page: pageParam },
                    headers: { authorization: await getAuthHeader() },
                },
            );
        },
        {
            gcTime: Infinity,
            staleTime: 1000 * 60,
        },
    );
};

const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
const useChatImages = (chatId: number): MessageFile[] => {
    const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useGetFiles(chatId);

    useEffect(() => {
        if (!isLoading && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage, isLoading]);

    return useMemo(
        () =>
            flattenIniniteResult(data).filter((file: MessageFile): boolean => {
                const ext = getUrlExtension(file.name);
                return imageExtensions.includes(ext);
            }),
        [data],
    );
};

interface NewChatDto {
    name: string | null;
    users: number[];
}

const useCreateChat = (): UseMutationResult<{ success: number }, string | Error, NewChatDto> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (dto: NewChatDto) => {
            addBreadcrumb('userAction', 'User attempted to create chat with body:' + JSON.stringify(dto));
            const result = await axios.post(`cooperatives/${selectedCoopId}/chats`, dto, {
                headers: { authorization: await getAuthHeader() },
            });

            if (!result.data) {
                throw new Error('message creation return unsuccessful result');
            }
            return result.data;
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['chats', selectedCoopId],
            });
            queryClient.invalidateQueries({
                queryKey: ['chat'],
            });
        },
    });
};

const useAddChatMembers = (): UseMutationResult<{ success: true }, string | Error, [number, number[]]> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ([chatId, users]) => {
            const promises = users.map(
                async (userId) =>
                    await axios.patch(`cooperatives/${selectedCoopId}/chats/${chatId}/users/${userId}`, undefined, {
                        headers: { authorization: await getAuthHeader() },
                    }),
            );
            const results = await Promise.all(promises);

            if (results.find((item) => !item.data)) {
                throw new Error('message creation return unsuccessful result');
            }

            return { success: true };
        },
        onSettled: (r, e, [chatId]) => {
            queryClient.invalidateQueries({
                queryKey: ['chats', selectedCoopId],
            });
            queryClient.invalidateQueries({ queryKey: getChatQueryKey(selectedCoopId, chatId) });
        },
    });
};

const useMarkChatRead = (): UseMutationResult<{ success: true }, string | Error, number> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (chatId) => {
            const results = await axios.patch(`cooperatives/${selectedCoopId}/chats/${chatId}/read`, undefined, {
                headers: { authorization: await getAuthHeader() },
            });

            if (!results.data.success) {
                throw new Error('Marking read return unsuccessful result');
            }

            return results.data;
        },
        onMutate: (chatId) => {
            queryClient.setQueryData<InfiniteData<LaravelPagingResponse<Chat[]>> | undefined>(
                getChatsAllQueryKey(selectedCoopId),
                (response) =>
                    response
                        ? {
                              ...response,
                              pages: response.pages.map((laravelResponse) => ({
                                  ...laravelResponse,
                                  data: laravelResponse.data.map((chat) =>
                                      chat.id !== chatId
                                          ? chat
                                          : {
                                                ...chat,
                                                not_read_messages_count: 0,
                                            },
                                  ),
                              })),
                          }
                        : response,
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: getChatsAllQueryKey(selectedCoopId) });
        },
    });
};

const useDeleteChatMessage = (): UseMutationResult<
    { success: true },
    string | Error,
    { chatId: number; messageId: number }
> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();
    const { update, reset } = useChatUpdate(selectedCoopId);

    return useMutation({
        mutationFn: async ({ chatId, messageId }) => {
            const results = await axios.delete(`cooperatives/${selectedCoopId}/chats/${chatId}/messages/${messageId}`, {
                headers: { authorization: await getAuthHeader() },
            });

            if (!results.data.success) {
                throw new Error('Deleting chat message returned unsuccessful result');
            }

            return results.data;
        },
        onMutate: ({ messageId, chatId }) => {
            const updateChat = (
                queryData: InfiniteData<LaravelPagingResponse<ChatMessagesResponse>>,
            ): InfiniteData<LaravelPagingResponse<ChatMessagesResponse>> => {
                return JSON.parse(
                    JSON.stringify({
                        pageParams: { ...queryData.pageParams },
                        pages: queryData.pages.map((page) => {
                            Object.values(page.data.messages).map((arg) => {
                                arg.map((message) => {
                                    if (message.id === messageId) {
                                        return { ...message, deleted: true };
                                    }
                                    return message;
                                });
                            });
                            return { ...page, data: { ...page.data, messages: page.data.messages } };
                        }),
                    }),
                );
            };
            update(chatId, updateChat);
        },
        onSettled: ({ chatId }) => {
            invalidateChatMessage(queryClient, selectedCoopId, chatId);
        },
        onError: (_e, { chatId }) => {
            reset(chatId);
        },
    });
};

const useEditChatMessage = (): UseMutationResult<
    { success: true },
    string | Error,
    { chatId: number; messageId: number; data: MutateMessageBody & { removeFiles: number[] } }
> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();
    const { update, reset } = useChatUpdate(selectedCoopId);

    return useMutation({
        mutationFn: async ({ chatId, messageId, data }) => {
            const results = await axios.patch(
                `cooperatives/${selectedCoopId}/chats/${chatId}/messages/${messageId}`,
                { ...data, files: data.files.map(({ name, uri }) => ({ name, data: uri })) },
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!results.data) {
                throw new Error('Editing message returned unsuccessful result');
            }

            return results.data;
        },
        onMutate: ({ messageId, chatId, data }) => {
            const updateChat = (
                queryData: InfiniteData<LaravelPagingResponse<ChatMessagesResponse>>,
            ): InfiniteData<LaravelPagingResponse<ChatMessagesResponse>> => {
                return JSON.parse(
                    JSON.stringify({
                        pageParams: { ...queryData.pageParams },
                        pages: queryData.pages.map((page) => {
                            Object.values(page.data.messages).map((arg) => {
                                arg.map((message): ChatMessage => {
                                    if (message.id === messageId) {
                                        const files = [
                                            ...message.files.filter((file) => !data.removeFiles.includes(file.id)),
                                            ...data.files.map(GetFileToBeUploadedToApiFile(message.user ?? 0)),
                                        ];
                                        return { ...message, ...data, files, edited: true };
                                    }
                                    return message;
                                });
                            });
                            return { ...page, data: { ...page.data, messages: page.data.messages } };
                        }),
                    }),
                );
            };
            update(chatId, updateChat);
        },
        onSettled: ({ chatId }) => {
            invalidateChatMessage(queryClient, selectedCoopId, chatId);
        },
        onError: (_e, { chatId }) => {
            reset(chatId);
        },
    });
};

export {
    useGetChats,
    useGetChat,
    useLeaveChat,
    useGetFiles,
    useSendMessage,
    useGetChatMessages,
    useCreateChat,
    useMakeAdmin,
    useAddChatMembers,
    useMarkChatRead,
    useChatImages,
    useDeleteChatMessage,
    useEditChatMessage,
};
