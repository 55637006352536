import React, { useState, useRef, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    StyleProp,
    StyleSheet,
    Text,
    TextStyle,
    View,
    TouchableOpacity,
    LayoutChangeEvent,
    ViewStyle,
} from 'react-native';
import HeimeText from './HeimeText';
import Icon from './Icon/Icon';
import _fonts from '../_fonts';
import { Theme, useThemeStyle, WH, WW } from '../_utils';

interface Props {
    numberOfLines: number;
    textStyle?: StyleProp<TextStyle>;
    containerStyle?: StyleProp<ViewStyle>;
    text: string;
    /**
     * Event handler when pressing read more, if returning false, the read more will not be toggled
     */
    onReadMorePress?: () => boolean;
    maxFontSizeMultiplier?: number;
}

const ReadMore = ({
    numberOfLines,
    text,
    textStyle,
    containerStyle,
    onReadMorePress,
    maxFontSizeMultiplier,
}: Props): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const [status, setMeasured] = useState<'initial' | 'checkLimitedHeight' | 'measured'>('initial');
    const refs = useRef<{ fullHeight?: number; limitHeight?: number }>({});
    const [showAllText, setShowAllText] = useState<boolean>(false);
    const [shouldShowReadMore, setShouldShowReadMore] = useState<boolean>(false);

    const handlePress = () => {
        if (onReadMorePress) {
            if (!onReadMorePress()) {
                return;
            }
        }
        setShowAllText((curr) => !curr);
    };

    const handleLayout = (event: LayoutChangeEvent) => {
        if (status === 'measured') {
            return;
        }
        if (status === 'initial') {
            refs.current.fullHeight = event.nativeEvent.layout.height;
            setMeasured('checkLimitedHeight');
            return;
        }
        if (status === 'checkLimitedHeight') {
            refs.current.limitHeight = event.nativeEvent.layout.height;
            if (refs.current.fullHeight && refs.current.limitHeight) {
                if (refs.current.fullHeight > refs.current.limitHeight) {
                    setShouldShowReadMore(true);
                }
            }
            setMeasured('measured');
        }
    };

    const maybeRenderReadMore = () => {
        if (shouldShowReadMore) {
            if (!showAllText) {
                return (
                    <TouchableOpacity onPress={handlePress} style={themedStyle.viewMoreWrapper}>
                        <Text style={themedStyle.viewMoreText}>{t('global:show_more')}</Text>
                        <Icon name="chevron" color="main" style={themedStyle.chevronDown} />
                    </TouchableOpacity>
                );
            } else {
                return (
                    <TouchableOpacity onPress={handlePress} style={themedStyle.viewMoreWrapper}>
                        <Text style={themedStyle.viewMoreText}>{t('global:show_less')}</Text>
                        <Icon name={'chevron'} color="main" style={themedStyle.chevronUp} />
                    </TouchableOpacity>
                );
            }
        }
        return null;
    };

    const calculatedNumberOfLines = useMemo(() => {
        if (status === 'initial') {
            return undefined;
        } else if (status === 'checkLimitedHeight') {
            return numberOfLines;
        } else {
            return showAllText || !shouldShowReadMore ? undefined : numberOfLines;
        }
    }, [status, showAllText, shouldShowReadMore, numberOfLines]);

    return (
        <View style={containerStyle}>
            <HeimeText
                numberOfLines={calculatedNumberOfLines}
                selectable
                linkify
                style={textStyle}
                onLayout={handleLayout}
                maxFontSizeMultiplier={maxFontSizeMultiplier}
            >
                {text}
            </HeimeText>
            {maybeRenderReadMore()}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        chevronUp: { marginLeft: WW * 0.02, transform: [{ rotate: '-90deg' }] },
        chevronDown: { marginLeft: WW * 0.02, transform: [{ rotate: '90deg' }] },
        viewMoreWrapper: {
            alignItems: 'center',
            marginTop: WH * 0.01,
            marginBottom: WH * 0.01,
            flexDirection: 'row',
        },
        viewMoreText: { fontFamily: _fonts.primaryFont, fontSize: WH * 0.025, color: theme.main },
    });

export default ReadMore;
