import { z } from 'zod';
import { ApiImageSchema, ApiImageSchemaNoId } from './Base';

export const ApartmentSchema = z.object({
    id: z.number(),
    project_name: z.string().nullable(),
    legal_name: z.string().nullable(),
    address: z.string().nullable(),
    type: z
        .union([
            z.literal('apartment'),
            z.literal('detached_house'),
            z.literal('semi_detached_house'),
            z.literal('room'),
        ])
        .nullable(),
    area: z.number().nullable(),
    living_area: z.number().nullable(),
    floor: z.number().nullable(),
    rooms: z.number().nullable(),
    parking_spot: z.string().nullable(),
    tenants: z.array(
        z.object({
            id: z.number(),
            tenant_role: z.union([
                z.literal('owner'),
                z.literal('livesWith'),
                z.literal('renting'),
                z.literal('other'),
            ]),
            fname: z.string(),
            lname: z.string().nullable(),
            phone: z.string(),
            email: z.string().nullable(),
            status: z.union([z.literal('active'), z.literal('notLoggedIn')]),
            avatar: ApiImageSchemaNoId.nullable(),
        }),
    ),
    picture: ApiImageSchema.nullable(),
    apartment_groups: z.array(z.object({ id: z.string(), name: z.string() })),
});

export type Apartment = z.infer<typeof ApartmentSchema>;

export type TenantRole = Apartment['tenants'][0]['tenant_role'];
