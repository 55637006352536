import { useCallback, useEffect, useState } from 'react';
import { useUpdateProfile } from '_api';
import { useAppNavigation } from '_navigator';

export type PinStep = 'CreatePin' | 'RepeatPin';

const usePinHook = (): {
    pinStep: PinStep;
    onSkipOrBack: () => void;
    onCodeChange: (code: string) => void;
    status: 'errorGeneric' | 'errorPin' | 'loading' | null;
    createPin: string;
    repeatPin: string;
    isLoading: boolean;
    buttonStatus: 'loading' | 'disabled' | null;
} => {
    const { mutate: updateProfile, isPending: isLoading } = useUpdateProfile();
    const [pinStep, setPinStep] = useState<PinStep>('CreatePin');
    const [createPin, setCreatePin] = useState<string>('');
    const [repeatPin, setRepeatPin] = useState<string>('');
    const [status, setStatus] = useState<'errorGeneric' | 'errorPin' | 'loading' | null>(null);
    const [buttonStatus, setButtonStatus] = useState<'loading' | 'disabled' | null>('disabled');
    const { goBack } = useAppNavigation();

    const onReset = useCallback(() => {
        setCreatePin('');
        setRepeatPin('');
        setStatus(null);
        setPinStep('CreatePin');
    }, [setRepeatPin, setCreatePin, setStatus]);

    const onSkipOrBack = useCallback(() => {
        if (pinStep === 'CreatePin') {
            goBack();
        } else {
            onReset();
        }
    }, [pinStep, goBack, onReset]);

    const onCheckCode = useCallback(() => {
        if (pinStep === 'CreatePin') {
            setPinStep('RepeatPin');
        } else {
            updateProfile({ own_code: repeatPin });
            goBack();
            onReset();
        }
    }, [pinStep, updateProfile, repeatPin, onReset, goBack]);

    useEffect(() => {
        if (pinStep === 'CreatePin') {
            if (createPin.length === 4) {
                onCheckCode();
            } else if (createPin.length > 0) {
                setButtonStatus('disabled');
            }
        } else {
            if (repeatPin.length === 4) {
                if (createPin === repeatPin) {
                    if (status?.startsWith('error')) {
                        setStatus(null);
                    }
                    onCheckCode();
                } else {
                    setStatus('errorPin');
                    setRepeatPin('');
                }
            } else if (createPin.length > 0) {
                setButtonStatus('disabled');
            }
        }
    }, [pinStep, createPin, repeatPin, onCheckCode, setStatus, setButtonStatus, status, setRepeatPin]);

    const onCodeChange = useCallback(
        (code: string) => {
            if (status?.startsWith('error')) {
                setStatus(null);
            }
            if (pinStep === 'CreatePin') {
                setCreatePin(code);
            } else {
                setRepeatPin(code);
            }
        },
        [pinStep, setCreatePin, setRepeatPin, setStatus, status],
    );

    return {
        pinStep,
        onSkipOrBack,
        onCodeChange,
        status,
        createPin,
        repeatPin,
        isLoading,
        buttonStatus,
    };
};
export { usePinHook };
