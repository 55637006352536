import React, { ReactElement } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Text } from 'react-native';
import { z } from 'zod';
import { showToast } from '_utils';
import { useSelectedCoopItem } from '_utils/hooks';
import { useCreateNote } from '../../../_api/useNotes';
import { FormInput, Modal } from '../../../Components';

const schema = z.object({
    message: z.string().min(1),
});

export type FormValues = z.infer<typeof schema>;

interface AddCommentModal {
    onDismiss(): void;
    supportId: number;
    isPublic: boolean;
    own: boolean;
    creatorFirstName: string;
}

const AddCommentModal = ({ onDismiss, supportId, isPublic, own, creatorFirstName }: AddCommentModal): ReactElement => {
    const { t } = useTranslation();
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';
    const { mutate: createNote, isPending } = useCreateNote();
    const form = useForm<FormValues>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: { message: '' },
    });

    const hasError = !form.formState.isValid;

    const handleConfirmClick = (value: FormValues) => {
        createNote([supportId, { content: value.message }], {
            onSuccess: () => {
                onDismiss();
            },
            onError: () => {
                showToast({
                    type: 'error',
                    header: t('reportSelected:commentModal:error'),
                    text: '',
                });
            },
        });
    };

    return (
        <Modal
            onDismiss={onDismiss}
            header={t('reportSelected:commentModal:addComment')}
            content={
                <FormProvider {...form}>
                    <Text>
                        {isPublic
                            ? t('reportSelected:commentModal:publicComment')
                            : own
                              ? t('reportSelected:commentModal:privateCommentOwn', {
                                    boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                                })
                              : t('reportSelected:commentModal:privateComment', {
                                    boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                                    user: creatorFirstName,
                                })}
                    </Text>
                    <FormInput
                        label={t('reportSelected:commentModal:message')}
                        name="message"
                        placeholder={t('reportSelected:commentModal:enterMessage')}
                        autoFocus
                        multiline
                    />
                </FormProvider>
            }
            buttons={[
                {
                    isLoading: isPending,
                    isDisabled: hasError,
                    text: t('reportSelected:commentModal:send').toUpperCase(),
                    onPress: form.handleSubmit(handleConfirmClick),
                },
            ]}
        />
    );
};

export default AddCommentModal;
