import React, { ReactElement, useContext } from 'react';
import { View } from 'react-native';
import Modal from '_dependencies/rnModal';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { ThemeContext } from '_utils/themeContext';
import { CloseButton } from 'Components';

const DividedImageModal = ({
    onDismiss,
    top,
    bottom,
    hideCloseButton = false,
}: {
    onDismiss: () => void;
    top: ReactElement;
    bottom: ReactElement;
    hideCloseButton?: boolean;
}) => {
    const { theme } = useContext(ThemeContext);

    return (
        <Modal
            onDismiss={onDismiss}
            onBackButtonPress={onDismiss}
            onBackdropPress={onDismiss}
            isVisible
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            avoidKeyboard
            backdropColor={theme.black}
        >
            <View
                style={{
                    backgroundColor: theme.mainBackground,
                    position: 'relative',
                }}
            >
                <View
                    style={{
                        backgroundColor: theme.lightGreen,
                        padding: screenMargin,
                        paddingBottom: screenMargin * 2,
                    }}
                >
                    {hideCloseButton ? null : (
                        <CloseButton
                            style={{
                                marginLeft: 'auto',
                                marginRight: -10,
                            }}
                            onPress={onDismiss}
                        />
                    )}
                    {top}
                </View>
                <View
                    style={{
                        flexDirection: 'column',
                        gap: smallestMargin,
                        padding: screenMargin,
                    }}
                >
                    {bottom}
                </View>
            </View>
        </Modal>
    );
};

export default DividedImageModal;
