import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from '@react-native-community/netinfo';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import {
    Query,
    QueryCache,
    QueryClient,
    QueryClientProvider,
    focusManager,
    onlineManager,
} from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { t } from 'i18next';
import { AppState, Platform } from 'react-native';
import type { AppStateStatus } from 'react-native';
import { isWeb, showToast } from '.';
import isAppError from './isAppError';
import isNetworkError from './isNetworkError';
import { captureException } from './Sentry';
import { APP_VERSION } from '../_constants';

const captureErrors = (e: unknown) => {
    if (isAppError(e) && isNetworkError(e)) {
        return;
    }
    captureException(e);
};

const showNetworkError = () => {
    showToast({
        header: t('global:networkError_header'),
        type: 'error',
        text: t('networkAlert:description'),
    });
};

const onError = (e: unknown) => {
    captureErrors(e);
    if (isAppError(e) && isNetworkError(e)) {
        showNetworkError();
    }
    if (isAppError(e) && e.response?.status === 429) {
        showToast({
            header: t('global:tooManyRequests_header'),
            type: 'error',
            text: t('global:tooManyRequests_text'),
        });
    }
};

const initializeReactQuery = async (): Promise<QueryClient> => {
    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError,
        }),
        defaultOptions: {
            queries: {
                retry: (count, error) => {
                    const msg = (error as Error)?.message ?? '';
                    if (isAppError(msg) && isNetworkError(msg)) {
                        return count < 100;
                    }
                    return count < 3;
                },
                retryDelay: (count) => Math.min(count * 1000, 15000),
                refetchInterval: (query: Query) =>
                    query.isActive()
                        ? // TypeScript is not telling us that there exist staleTime on the query
                          (query.options as { staleTime: number }).staleTime
                            ? (query.options as { staleTime: number }).staleTime
                            : false
                        : false,
            },
        },
    });

    const asyncStoragePersistor = createAsyncStoragePersister({ storage: AsyncStorage });

    const [, persistPromise] = persistQueryClient({
        queryClient,
        persister: asyncStoragePersistor,
        maxAge: 1000 * 60 * 60 * 24 * 365,
        // This resets async storage on version update, making sure we are not ending up in a scary situation
        buster: APP_VERSION,
    });
    await persistPromise;

    return queryClient;
};

const ReactQueryProvider = ({ children }: PropsWithChildren<unknown>): ReactElement | null => {
    const [queryClient, setQueryClient] = useState<null | QueryClient>(null);

    useEffect(() => {
        const asyncFunc = async () => {
            setQueryClient(await initializeReactQuery());
        };
        asyncFunc();
    }, []);

    function onAppStateChange(status: AppStateStatus) {
        if (Platform.OS !== 'web') {
            focusManager.setFocused(status === 'active');
        }
    }

    useEffect(() => {
        const subscription = AppState.addEventListener('change', onAppStateChange);

        return () => subscription.remove();
    }, []);

    useEffect(() => {
        if (queryClient && isWeb()) {
            if ((window.performance.getEntries()?.[0] as unknown as { type: string })?.type === 'reload') {
                queryClient.clear();
            }
        }
    }, [queryClient]);

    if (!queryClient) {
        // TODO we should have a loading screen here
        return null;
    }

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

NetInfo.configure({
    reachabilityLongTimeout: 60 * 1000,
    reachabilityShortTimeout: 500,
    reachabilityRequestTimeout: 15 * 1000,
});

onlineManager.setEventListener((setOnline) => {
    return NetInfo.addEventListener((state) => {
        if (!state.isConnected) {
            showNetworkError();
        }
        setOnline(!!state.isConnected);
    });
});

export { ReactQueryProvider };
