import React, { ReactElement, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { useGetReservations } from '_api/useReservations';
import { useAppNavigation, useContextMenuFocusEffect } from '_navigator';
import { Theme, useThemeStyle, WH, WW } from '_utils';
import { useBottomSpacer } from '_utils/hooks';
import { flattenIniniteResult } from '_utils/misc';
import { Loader, QueryView, SecondaryButton, HeaderWithNav } from 'Components';
import { OwnReservation } from 'types/Reservation';
import ReservationListItem from './ReservationListItem';

const ReservationsAll = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const { data, isLoading, isError, error, isFetchingNextPage, fetchNextPage, isRefetching, refetch } =
        useGetReservations();
    const paddingBottom = useBottomSpacer();
    useContextMenuFocusEffect(['reservations']);

    if (isError && !data) {
        throw error;
    }

    const reservations = useMemo(() => {
        if (!data) {
            return [];
        }
        const now = moment();

        return flattenIniniteResult(data)
            .filter((r) => moment.unix(r.end_at).isAfter(now))
            .sort((a, b) => a.start_at - b.start_at);
    }, [data]);

    type openType = 'default' | 'openLock' | 'checkIn';
    const handleReservationPress = (reservation: OwnReservation, type: openType) => {
        if (type === 'checkIn' && reservation.check_in && !reservation.has_checked_in) {
            navigate('Procedure', {
                bookingId: reservation.id,
                procedureId: reservation.check_in,
                product_id: reservation.booked.id + '',
            });
            return;
        }
        navigate('ReservationSelected', {
            id: reservation.id + '',
            initiallyUnlockLock: type === 'openLock',
        });
    };
    const handleGoToServicesPress = () => {
        navigate('Services', {});
    };

    const renderReservation = ({ item }: { item: OwnReservation }) => (
        <ReservationListItem
            key={item.id}
            data={item}
            onPress={(type: openType) => handleReservationPress(item, type)}
        />
    );
    return (
        <View style={themedStyle.main}>
            <HeaderWithNav style={themedStyle.navHeader} title={t('reservations:title')} safeArea />
            {isLoading ? (
                <Loader />
            ) : (
                <QueryView
                    data={reservations}
                    renderItem={renderReservation}
                    style={themedStyle.scroll}
                    containerStyle={{ paddingBottom }}
                    isLoadingMore={isFetchingNextPage}
                    loadMore={fetchNextPage}
                    isRefreshing={isRefetching}
                    onRefresh={refetch}
                    emptyList={
                        <View style={themedStyle.view}>
                            <Text style={themedStyle.emptyMessage}>{t('reservations:noReservations')}</Text>
                            <SecondaryButton
                                text="Gå til tjenester"
                                onPress={handleGoToServicesPress}
                                style={themedStyle.button}
                            />
                        </View>
                    }
                />
            )}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        navHeader: {
            backgroundColor: theme.mainBackground,
        },
        main: {
            height: '100%',
            backgroundColor: theme.mainBackground,
        },
        view: { display: 'flex', flexGrow: 1, height: WH * 0.6 },
        scroll: {
            paddingLeft: WW * 0.03,
            paddingRight: WW * 0.03,
            paddingTop: WW * 0.03,
            paddingEnd: WW * 0.03,
        },
        emptyMessage: {
            marginTop: WH * 0.05,
            marginBottom: WH * 0.05,
            fontWeight: 'bold',
            textAlign: 'center',
            flexGrow: 1,
        },
        button: {
            marginLeft: WH * 0.02,
            marginRight: WH * 0.02,
            marginTop: WH * 0.02,
            marginBottom: WH * 0.02,
        },
    });

export default ReservationsAll;
