import { ReactElement } from 'react';
import moment from 'moment';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { FormCheckbox, FormDateTimeInput, FormInput, Icon } from 'Components';

const defaultBirthday = moment('1980-01-01').toDate();
const today = moment().toDate();

const EditProfileInfo = function <
    FormValues extends {
        firstName: string;
        lastName: string;
        hidePhone: boolean;
        email: string;
        hideEmail: boolean;
        birthday: string;
        hideBirthday: boolean;
    },
>({ spaceContainer, center }: { spaceContainer: boolean; center?: ReactElement }) {
    const { watch } = useFormContext<FieldValues>();
    const { t } = useTranslation();

    const birthday = watch('birthday');
    return (
        <>
            <View style={[styles.containerName, spaceContainer ? styles.inputSpacer : null]}>
                <View style={styles.nameInput}>
                    <FormInput<FormValues>
                        name={'firstName' as Path<FormValues>}
                        label={t('profileEdit:first_name')}
                        placeholder={t('profileEdit:enterFirstName')}
                        type="nameGiven"
                    />
                </View>
                <View style={styles.nameInput}>
                    <FormInput<FormValues>
                        name={'lastName' as Path<FormValues>}
                        label={t('profileEdit:last_name')}
                        placeholder={t('profileEdit:enterSurname')}
                        type="familyName"
                    />
                </View>
            </View>
            {center}
            <FormCheckbox<FormValues>
                name={'hidePhone' as Path<FormValues>}
                label={t('profileEdit:hidePhoneFromProfile')}
            />
            <FormInput<FormValues>
                name={'email' as Path<FormValues>}
                label={t('profileEdit:email')}
                placeholder={t('profileEdit:enterEmail')}
                type="email"
                containerStyle={styles.inputSpacer}
            />
            <FormCheckbox label={t('profileEdit:hideEmailFromProfile')} name="hideEmail" />
            <FormDateTimeInput<FormValues>
                label={t('profileEdit:birthday')}
                name={'birthday' as Path<FormValues>}
                defaultValue={defaultBirthday}
                mode="date"
                maxDate={today}
                view="input"
                containerStyle={styles.inputSpacer}
                rightImage={
                    <Icon style={styles.icon} name="calendarDates" color={birthday ? 'darkGrey' : 'mediumGrey'} />
                }
            />
            <FormCheckbox name="hideBirthday" label={t('profileEdit:hideBirthdayFromProfile')} />
        </>
    );
};

const styles = StyleSheet.create({
    icon: {
        marginRight: smallestMargin,
        marginTop: smallestMargin,
        marginBottom: smallestMargin,
    },
    containerName: {
        flexDirection: 'row',
        alignContent: 'space-between',
        marginTop: screenMargin * 1.5,
        flex: 1,
        gap: screenMargin,
    },
    nameInput: { flexGrow: 1, flexBasis: '40%' },
    inputSpacer: { marginBottom: smallestMargin },
});

export default EditProfileInfo;
