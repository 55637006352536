import { useMemo } from 'react';
import { Group, useGetGroups } from '../../_api/useGroups';
import { useUserInUserCollection } from '../../_utils/hooks';
import { flattenIniniteResult } from '../../_utils/misc';

const useGetUserGroups = (userId: number): Group[] => {
    const { data } = useGetGroups();
    const getIsMember = useUserInUserCollection();
    return useMemo(
        () => flattenIniniteResult(data).filter((group) => getIsMember(group.users, userId)),
        [data, getIsMember, userId],
    );
};
export default useGetUserGroups;
