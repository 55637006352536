import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useGetGroups } from '_api/useGroups';
import { useAppNavigation } from '_navigator';
import { useBottomSpacer, useUserInUserCollection } from '_utils/hooks';
import { flattenIniniteResult } from '_utils/misc';
import { screenMargin } from '_utils/sizes';
import { Loader, QueryItemView, QueryView, EmptyList } from 'Components';
import { ArrayElement } from 'types/Utility';
import { GroupItem } from './GroupItem';

interface GroupListProps {
    filter?: 'onlyOwnGroups' | number[];
    groupScreen?: 'GroupSelectedOnboard' | 'GroupSelected' | 'Related';
}

const GroupList = ({ filter, groupScreen = 'GroupSelected' }: GroupListProps): ReactElement => {
    const { t } = useTranslation();
    const { push } = useAppNavigation();
    const getIsMember = useUserInUserCollection();
    const paddingBottom = useBottomSpacer();

    const { data, isLoading, fetchNextPage, isFetchingNextPage, refetch, isFetching, isError, error } = useGetGroups();

    const groups = useMemo(() => {
        const mapped = flattenIniniteResult(data).map((group) => {
            return { ...group, isMember: getIsMember(group.users) };
        });
        if (filter === 'onlyOwnGroups') {
            return mapped.filter(({ isMember }) => isMember);
        }
        if (Array.isArray(filter)) {
            return mapped.filter(({ id }) => filter.includes(id));
        }
        return mapped;
    }, [data, filter, getIsMember]);

    const renderItem = useCallback(
        ({ item, index }: { item: ArrayElement<typeof groups>; index: number }) => {
            const onPress = () => {
                push(groupScreen === 'GroupSelectedOnboard' ? 'GroupSelectedOnboard' : 'GroupSelected', {
                    groupId: item.id,
                    isOnboarding: groupScreen === 'GroupSelectedOnboard',
                });
            };

            return (
                <GroupItem
                    key={index}
                    onPress={onPress}
                    isMember={item.isMember}
                    image={item.pictures.length ? item.pictures[0] : undefined}
                    name={item.name}
                    membersLength={item.users.length}
                    id={item.id}
                    joinDirectly={groupScreen !== 'Related'}
                />
            );
        },
        [groupScreen, push],
    );

    if (isError) {
        throw error;
    }

    if (isLoading) {
        return <Loader />;
    }

    if (groups.length === 0) {
        return (
            <QueryItemView containerStyle={styles.empty} isRefreshing={isFetching} onRefresh={refetch}>
                <EmptyList message={filter === 'onlyOwnGroups' ? t('group:noGroups:own') : t('group:noGroups:all')} />
            </QueryItemView>
        );
    }

    return (
        <QueryView
            style={[styles.list, groupScreen !== 'Related' ? styles.listMargin : undefined]}
            data={groups}
            renderItem={renderItem}
            loadMore={fetchNextPage}
            isLoadingMore={isFetchingNextPage}
            onRefresh={refetch}
            isRefreshing={isFetching}
            containerStyle={{ paddingBottom }}
        />
    );
};

const styles = StyleSheet.create({
    list: { flex: 1 },
    listMargin: { paddingLeft: screenMargin, paddingRight: screenMargin },
    empty: {
        justifyContent: 'center',
        flexGrow: 1,
        marginLeft: screenMargin,
        marginRight: screenMargin,
        marginTop: screenMargin,
        marginBottom: screenMargin,
    },
});

export default GroupList;
