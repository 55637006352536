import { isWeb } from '_utils/isPlatform';
import { captureException } from '_utils/Sentry';
import { AVAILABLE_LANGUAGES } from '../_localization';

const APP_VERSION = '2.10.9';

const sources = (lang: keyof typeof AVAILABLE_LANGUAGES) => [
    `https://marin.dev.frontsoftware.no/heime/${lang}/api/v1/`,
    `https://heime.dev.frontsoftware.no/heime/${lang}/api/v1/`,
];

const API_URL = (lang: keyof typeof AVAILABLE_LANGUAGES): string => {
    if (__DEV__) {
        try {
            const env = require('../env');
            if (env?.API_URL) {
                return `${env.API_URL}/${lang}/api/v1/`;
            }
            console.error('You need to add API_URL in /src/env.ts');
        } catch (e) {
            captureException(e);
            console.error('You need to add the file /src/env.ts with an export of API_URL');
        }
    }

    if (!isWeb()) {
        return `https://app.heime.com/${lang}/api/v1/`;
    }

    return (
        sources(lang).find((item) => item.includes(window.location.host)) ??
        `${window.location.protocol}//${window.location.host}/${lang}/api/v1/`
    );
};

const SENTRY_URL = 'https://ec0b1b36b34446c390af280160202270@o1053966.ingest.sentry.io/6039008';
const TimeZone = 'Europe/Oslo';

// Back-end application expects this id for notifications
const HEIME_CHANNEL_ID = 'heime_notifications';

export { APP_VERSION, SENTRY_URL, API_URL, TimeZone, HEIME_CHANNEL_ID };
