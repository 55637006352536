import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { useOwnProfile } from '_api/useProfile';
import { useAppNavigation } from '_navigator';
import { screenMargin } from '_utils/sizes';
import { ChatActionButton, UserListItem } from 'Components';

const UserList = ({
    data,
}: {
    data: {
        id: number;
        title: string;
        desc: string;
    }[];
}): ReactElement => {
    const { data: profile } = useOwnProfile();
    const { navigate } = useAppNavigation();

    const handleNavigateToReservations = () => {
        navigate('ReservationsAll', undefined);
    };

    const renderItem = (item: { id: number; title: string; desc: string }) => {
        const isSelf = profile?.id === item.id;
        return (
            <UserListItem
                title={item.title}
                key={item.id}
                desc={item.desc}
                id={item.id}
                actionButton={isSelf ? undefined : <ChatActionButton userId={item.id} />}
                onPress={isSelf ? handleNavigateToReservations : undefined}
                numberOfLines={null}
            />
        );
    };

    return <View style={styles.list}>{data.map(renderItem)}</View>;
};

const styles = StyleSheet.create({
    list: {
        marginTop: screenMargin,
        marginBottom: screenMargin,
    },
});
export default UserList;
