import React, { ReactElement, useMemo, useState } from 'react';
import { decode } from 'html-entities';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, Text } from 'react-native';
import { Cooperative } from 'types/Cooperative';
import { InfoPage, useGetTermOfSale } from '../_api/useTermsOfSale';
import { SafeAreaView } from '../_dependencies/safeArea';
import _fonts from '../_fonts';
import { useAppNavigation } from '../_navigator';
import { Theme, useThemeStyle, WH, WW } from '../_utils';
import { useSelectedCoopItem } from '../_utils/hooks';
import { titleFontSize, screenMargin } from '../_utils/sizes';
import { Container, FullPageMenuComponent, HeimeText, Loader, PrimaryButton } from '../Components';
import HeaderWithNav from '../Components/HeaderWithNav';
import { ArrayElement } from '../types/Utility';

const TermsOfSale = (): ReactElement | null => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { goBack } = useAppNavigation();
    const unfilteredAccounts = useSelectedCoopItem()?.payment_accounts;
    const accounts = useMemo(() => {
        const returned: typeof unfilteredAccounts = [];
        (unfilteredAccounts ?? [])?.forEach((account) => {
            if (
                !returned.find(
                    (item) =>
                        item.id !== account.id &&
                        account.organization_name === item.organization_name &&
                        account.organization_number === item.organization_number,
                )
            ) {
                returned.push(account);
            }
        });
        return returned;
    }, [unfilteredAccounts]);

    const { data, isLoading, isError, error } = useGetTermOfSale();
    const [selectedAccount, setSelectedAccount] = useState<ArrayElement<Cooperative['payment_accounts']> | null>(null);

    if (isLoading) {
        return <Loader />;
    }
    if (isError) {
        throw error;
    }
    if (!data) {
        return null;
    }

    const getHandleSelectAccount = (account: ArrayElement<Cooperative['payment_accounts']>) => () =>
        setSelectedAccount(account);
    const handleResetAccount = () => setSelectedAccount(null);

    return (
        <SafeAreaView style={themedStyle.full} edges={['left', 'bottom', 'right']}>
            {accounts.length === 0 ? (
                <>
                    <HeaderWithNav title={t('userMenu:terms:title')} />
                    <HeimeText style={themedStyle.message}>{t('userMenu:terms:noAccounts')}</HeimeText>
                    <PrimaryButton bottomAction text={t('global:back')} onPress={() => goBack()} />
                </>
            ) : selectedAccount || accounts.length === 1 ? (
                <TermsOfSaleSelected
                    page={data}
                    paymentAccount={selectedAccount ?? accounts[0]}
                    onGoBack={accounts.length > 1 ? handleResetAccount : goBack}
                />
            ) : (
                <>
                    <HeaderWithNav title={t('userMenu:terms:title')} />
                    <FullPageMenuComponent
                        items={accounts.map((acc) => ({
                            id: acc.id,
                            title: acc.organization_name ?? '',
                            onPress: getHandleSelectAccount(acc),
                            children: [],
                        }))}
                    />
                    <PrimaryButton bottomAction text={t('global:back')} onPress={() => goBack()} />
                </>
            )}
        </SafeAreaView>
    );
};

interface TermsOfSaleSelectedProps {
    page: InfoPage;
    paymentAccount: ArrayElement<Cooperative['payment_accounts']>;
    onGoBack(): void;
}
const TermsOfSaleSelected = ({ page, paymentAccount, onGoBack }: TermsOfSaleSelectedProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    const content = useMemo(() => {
        if (page?.content && page.content.length !== 0) {
            let updatedContent = page.content
                .replace('[CoopName]', paymentAccount.organization_name ?? '')
                .replace('[CoopAddress]', paymentAccount.address ?? '')
                .replace('[CoopPostcode]', paymentAccount.postcode ?? '')
                .replace('[CoopCity]', paymentAccount.city ?? '')
                .replace('[coop orgnum]', paymentAccount.organization_number ?? '');

            return updatedContent;
        }
    }, [
        page.content,
        paymentAccount.address,
        paymentAccount.city,
        paymentAccount?.organization_name,
        paymentAccount.organization_number,
        paymentAccount.postcode,
    ]);

    return (
        <>
            <HeaderWithNav title={t('userMenu:terms:title')} onPress={onGoBack} />
            <ScrollView style={themedStyle.main}>
                <Container style={themedStyle.marginBottom}>
                    <Text style={themedStyle.contentTitle}>{decode(page?.title)}</Text>
                    <Text style={themedStyle.content}>{decode(content)}</Text>
                </Container>
            </ScrollView>
            <PrimaryButton bottomAction text={t('global:back')} onPress={() => onGoBack()} />
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        full: { height: '100%', display: 'flex', backgroundColor: theme.white },
        main: {
            flex: 1,
            paddingLeft: WW * 0.05,
            paddingRight: WW * 0.05,
        },
        message: {
            fontSize: titleFontSize,
            textAlign: 'center',
            marginTop: screenMargin * 3,
            marginBottom: screenMargin,
            marginRight: screenMargin,
            marginLeft: screenMargin,
            fontWeight: 'bold',
        },
        marginBottom: {
            marginBottom: WH * 0.04,
            backgroundColor: theme.white,
        },
        contentTitle: {
            fontFamily: _fonts.primaryFontBold,
            marginBottom: WH * 0.02,
            color: theme.darkGrey,
        },
        content: {
            fontFamily: _fonts.primaryFont,
            marginBottom: WH * 0.02,
            color: theme.darkGrey,
        },
    });

export default TermsOfSale;
