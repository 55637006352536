import React, { memo, ReactElement, useState } from 'react';
import Clipboard from '@react-native-clipboard/clipboard';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useDeleteChatMessage } from '_api/useChats';
import { useAppNavigation } from '_navigator';
import { showToast, Theme, useThemeStyle, WW } from '_utils';
import { screenMargin, smallestFontSize, smallestMargin } from '_utils/sizes';
import { HeimeText, Icon, MessageBubble } from 'Components';
import { ChatMessage } from 'types/Chat';
import { UserCollectionItemWithAvatar } from 'types/User';
import { AnnouncementMessage, DeleteMessageModal, MessageRating } from './components';

interface AttachmentBubbleProps {
    message: ChatMessage;
    sender: UserCollectionItemWithAvatar | null;
    isSelf: boolean;
    isOneToOne: boolean;
    onImageClick: (index: number) => void;
    setEditMessage: (message: ChatMessage) => void;
    chatId: number;
}

const MessageItem = ({
    message,
    sender,
    isSelf,
    isOneToOne,
    onImageClick,
    setEditMessage,
    chatId,
}: AttachmentBubbleProps): ReactElement => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { t } = useTranslation();
    const { mutate } = useDeleteChatMessage();

    if (!message.user) {
        return (
            <AnnouncementMessage
                key={message.id}
                type={
                    message.type === 'user_joined' ? 'positive' : message.type === 'user_left' ? 'negative' : 'neutral'
                }
                content={message.text}
            />
        );
    }

    if (message.user && message.type === 'user_rating') {
        return <MessageRating text={message.text} isOwn={isSelf} created_at={message.created_at} />;
    }

    const handleDelete = () => {
        if (!message.id) {
            return;
        }
        mutate(
            { messageId: message.id, chatId },
            {
                onSuccess: () => {
                    setDeleteModalOpen(false);
                    showToast({ header: t('chat:deleteMessageSuccess'), type: 'success', text: '' });
                    setDeleteModalOpen(false);
                },
                onError: () => {
                    showToast({ header: t('chat:deleteMessageError'), type: 'error', text: '' });
                },
            },
        );
    };

    return (
        <>
            {message.to_message ? <SharingMessageType {...message.to_message} /> : undefined}
            <MessageBubble
                author={sender}
                isSelf={isSelf}
                showAuthor={!isSelf && !isOneToOne}
                created_at={message.created_at}
                content={message.text}
                displayTimeMode="onlyHour"
                files={message.files}
                onImagePress={onImageClick}
                edited={message.edited}
                deleted={message.deleted}
                deletedMessage={t('chat:deleted_message')}
                contextActions={
                    isSelf
                        ? [
                              {
                                  text: t('chat:copy'),
                                  icon: 'copySheets',
                                  onPress: () => {
                                      Clipboard.setString(message.text);
                                      showToast({
                                          header: t('chat:copiedToClipboard'),
                                          text: '',
                                          type: 'success',
                                          position: 'bottom',
                                          shortTime: true,
                                      });
                                  },
                              },
                              {
                                  text: t('chat:edit'),
                                  icon: 'edit',
                                  onPress: () => {
                                      setEditMessage(message);
                                  },
                              },
                              {
                                  text: t('chat:delete'),
                                  variant: 'danger',
                                  icon: 'trash',
                                  onPress: () => {
                                      setDeleteModalOpen(true);
                                  },
                              },
                          ]
                        : []
                }
            />
            {deleteModalOpen ? (
                <DeleteMessageModal onDismiss={() => setDeleteModalOpen(false)} onDelete={handleDelete} />
            ) : null}
        </>
    );
};

const SharingMessageType = ({ id, type, title }: Exclude<ChatMessage['to_message'], null>) => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();

    if (type !== 0 && type !== 1 && type !== 2) {
        return null;
    }

    const typeSettings = {
        0: {
            text: t('chat:shareProducts'),
            icon: 'tag' as const,
        },
        1: {
            text: t('chat:askForHelp'),
            icon: 'wrench' as const,
        },
        2: {
            text: t('chat:helpRequest'),
            icon: 'questionNew' as const,
        },
    }[type];

    const handlePress = () => {
        navigate('SharingSelected', {
            messageId: id,
        });
    };

    return (
        <TouchableOpacity onPress={handlePress} style={themedStyle.othersRatingBubble}>
            <View style={themedStyle.othersIconWrapper}>
                <Icon name={typeSettings.icon} color="secondary" />
            </View>

            <View style={themedStyle.content}>
                <HeimeText style={themedStyle.othersTitle}>
                    {typeSettings.text}
                    <HeimeText style={themedStyle.text}>{title}</HeimeText>
                </HeimeText>
            </View>
            <Icon name="chevron" color="secondary" />
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        othersRatingBubble: {
            alignSelf: 'flex-start',
            borderRadius: screenMargin,
            paddingTop: smallestMargin,
            paddingBottom: smallestMargin,
            paddingLeft: smallestMargin,
            paddingRight: smallestMargin,
            marginTop: smallestMargin,
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: theme.mainBackground,
            borderWidth: 1,
            borderColor: theme.lightGrey,
        },
        othersIconWrapper: {
            borderRadius: WW * 0.2,
            paddingTop: smallestMargin,
            paddingBottom: smallestMargin,
            paddingLeft: smallestMargin,
            paddingRight: smallestMargin,
            backgroundColor: theme.lightMustard,
        },
        content: {
            flex: 1,
            paddingLeft: screenMargin,
        },
        othersTitle: {
            fontSize: smallestFontSize,
            color: theme.darkGreen,
        },
        text: {
            fontWeight: 'bold',
            fontSize: smallestFontSize,
            color: theme.black,
        },
        time: {
            fontSize: WW * 0.03,
            color: theme.secondaryLight,
        },
    });

export default memo(MessageItem);
