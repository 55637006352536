import { UseQueryResult, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';

const ApartmentGroupSchema = z.object({
    id: z.string(),
    name: z.string(),
    cooperative_groups: z.array(z.number()),
});

export type ApartmentGroup = z.infer<typeof ApartmentGroupSchema>;

const responseSchema = z.array(ApartmentGroupSchema);

const useGetApartmentGroups = (): UseQueryResult<ApartmentGroup[], string | Error> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();

    return useQuery({
        queryKey: ['apartment_groups', selectedCoopId],
        queryFn: async () => {
            const response = await axios.get(`cooperatives/${selectedCoopId}/apartmentgroups`, {
                headers: { authorization: await getAuthHeader() },
            });
            return responseSchema.parse(response.data);
        },
        gcTime: Infinity,
        staleTime: 1000 * 60 * 5,
    });
};

export { useGetApartmentGroups };
