import { useEffect } from 'react';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { ParamListBase, useNavigation } from '@react-navigation/native';
import { getNonScreens } from '_navigator/navigationConfiguration/screenConfiguration';
import getMissingScreens from './getMissingScreens';
import getParentsArray from './getParentsArray';
import useAppNavigation from './useAppNavigation';
import useResetNavigationStack from './useResetNavigationStack';
import navigationReference from '../navigationReference';

const useTabPressReset = (screenName: string): void => {
    const { replace } = useAppNavigation();
    const { addListener, navigate } = useNavigation<BottomTabNavigationProp<ParamListBase>>();

    const resetNav = useResetNavigationStack();

    useEffect(() => {
        const unsubscribe = addListener('tabPress', (e) => {
            const nonScreens = getNonScreens();
            const isNonScreen = (innerScreenName: string): innerScreenName is keyof typeof nonScreens =>
                innerScreenName in nonScreens;

            if (isNonScreen(screenName)) {
                const defaultRoute = nonScreens[screenName].defaultRoute;
                const missingScreens = getMissingScreens(
                    navigationReference.ref?.getRootState()?.routes ?? [],
                    getParentsArray(defaultRoute, null),
                );

                if (missingScreens.length > 0) {
                    navigate(screenName, { screen: defaultRoute });
                    e.preventDefault();
                }
            }
            const stacks = Object.entries(nonScreens)
                .filter(([name, item]) => item.parent === 'Main' && name !== screenName)
                .map(([name]) => name);
            // When pressing a tab we reset all the others
            resetNav(stacks);
        });

        return () => unsubscribe();
    }, [addListener, navigate, replace, resetNav, screenName]);
};

export default useTabPressReset;
