import React, { ReactElement } from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import _fonts from '../_fonts';
import { Theme, useThemeStyle, WW } from '../_utils';
import { screenMargin, subtitleFontSize } from '../_utils/sizes';

const SectionHeader = ({ title, style }: { title: string; style?: StyleProp<ViewStyle> }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View style={[themedStyle.headerView, style]}>
            <Text style={themedStyle.headerText}>{title.toUpperCase()}</Text>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        headerText: {
            fontFamily: _fonts.primaryFont,
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            color: theme.secondaryText,
        },
        headerView: {
            backgroundColor: theme.mediumBackground,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            paddingRight: screenMargin,
            paddingLeft: screenMargin,
        },
    });

export default SectionHeader;
