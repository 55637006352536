import React, { ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { ReactReduxContext } from 'react-redux';
import { AuthContext } from 'Auth/AuthContext';
import { ApiImage } from 'types/Base';
import CacheImage, { CacheImageProps } from './CacheImage';
import { useCoops } from '../_api/useCoops';
import Images from '../_images';
import { useGlobalState, WW } from '../_utils';

interface MainLogoProps {
    imageWidth?: number;
    imageHeight?: number;
}
const MainLogo = (props: MainLogoProps): ReactElement => {
    const store = useContext(ReactReduxContext);
    const auth = useContext(AuthContext);

    if (!store || !auth) {
        return <ActualImage {...props} logoImage={Images.logo} />;
    } else {
        return <DynamicLogo {...props} />;
    }
};

const DynamicLogo = (props: MainLogoProps) => {
    const selectedCoop = useGlobalState((state) => state.selectedCoop);
    const { data: cooperatives } = useCoops();

    const logoImage = useMemo((): CacheImageProps<ApiImage>['source'] => {
        if (selectedCoop) {
            const cooperative = cooperatives?.find((coop) => coop.id === selectedCoop);
            if (cooperative?.white_label_logo) {
                return { ...cooperative.white_label_logo, priority: 'high' };
            }
        }
        return Images.logo as number;
    }, [cooperatives, selectedCoop]);

    return <ActualImage {...props} key="logoImage" logoImage={logoImage} />;
};

const ActualImage = ({
    logoImage,
    imageWidth = WW * 0.6,
    imageHeight = 40,
}: { logoImage: number | ApiImage } & MainLogoProps) => {
    const [dimensions, setDimensions] = useState<{ width: number; height: number } | undefined>(undefined);
    const handleLoad = useCallback(
        ({ width, height }: { width: number; height: number }) => {
            const scale = imageHeight / height;
            let newWidth = imageWidth;
            let newHeight = imageHeight;
            if (width * scale > imageWidth) {
                const newScale = imageWidth / width;
                newWidth = Math.round(width * newScale);
                newHeight = Math.round(height * newScale);
            } else {
                newWidth = Math.round(width * scale);
                newHeight = Math.round(height * scale);
            }
            setDimensions((curr) => {
                if (curr && curr.width === newWidth && curr.height === newHeight) {
                    return curr;
                }
                return { width: newWidth, height: newHeight };
            });
        },
        [imageHeight, imageWidth],
    );

    return (
        <CacheImage
            onLoad={handleLoad}
            source={logoImage}
            resizeMode={'contain'}
            style={dimensions ? dimensions : styles.noShow}
        />
    );
};

const styles = StyleSheet.create({
    noShow: {
        opacity: 0,
    },
});

export default MainLogo;
