import { FileUpload, isFileToBeDeleted, isFileToBeUploaded } from '../types/Utility';

const fileIsImage = (file: FileUpload): boolean => {
    if (isFileToBeDeleted(file)) {
        return false;
    }

    if (file.name) {
        const fileExt = file.name.toLocaleLowerCase().split('.').pop() ?? '';
        return ['webp', 'jpg', 'jpeg', 'gif', 'png'].includes(fileExt);
    }

    if (isFileToBeUploaded(file)) {
        return file.uri.startsWith('data:image/');
    }

    return false;
};

export default fileIsImage;
