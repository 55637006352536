import React, { ReactElement, useContext, useState } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';
import { View, StyleSheet, Text } from 'react-native';
import { Menu, MenuTrigger, MenuOptions, MenuOption } from 'react-native-popup-menu';
import Icon from './Icon/Icon';
import InputLabel from './InputLabel';
import _fonts from '../_fonts';
import { useThemeStyle, WW } from '../_utils';
import { Theme, ThemeContext } from '../_utils/themeContext';

interface FormDropdownProps<Schema extends FieldValues, SchemaPath extends Path<Schema> & keyof Schema> {
    items: { id: Schema[SchemaPath]; text: string }[];
    name: SchemaPath;
    label: string;
    initialGrey?: boolean;
}
const FormDropdown = function <Schema extends FieldValues, SchemaPath extends Path<Schema> & keyof Schema>({
    items,
    name,
    label,
    initialGrey = false,
}: FormDropdownProps<Schema, SchemaPath>): ReactElement {
    const themedStyle = useThemeStyle(dropdownStyles);
    const { theme } = useContext(ThemeContext);
    const { field } = useController<Schema>({ name });
    const selectedItem = items.find((item) => item.id === field.value);
    const getOnChangeHandler = (id: Schema[SchemaPath]) => () => {
        setChanged(true);
        field.onChange(id);
    };
    const [changed, setChanged] = useState(false);

    return (
        <>
            <InputLabel>{label}</InputLabel>
            <Menu>
                <MenuTrigger>
                    <View
                        style={[
                            themedStyle.selected,
                            {
                                borderColor:
                                    field.value && (!initialGrey || changed) ? theme.main : theme.secondaryLight,
                            },
                        ]}
                    >
                        <Text style={themedStyle.selectedText}>{selectedItem?.text ?? ''}</Text>
                        <Icon style={themedStyle.chevron} name={'chevron'} color="black" />
                    </View>
                </MenuTrigger>
                <MenuOptions customStyles={{ optionsContainer: themedStyle.menu }}>
                    {items.map(({ id, text }, index) => (
                        <MenuOption
                            style={{
                                backgroundColor: id === field.value ? theme.lightGrey : undefined,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            key={index}
                            onSelect={getOnChangeHandler(id)}
                        >
                            <Text style={themedStyle.menuText}>{text}</Text>
                            {id === field.value ? <Icon name={'check'} color={'main'} /> : null}
                        </MenuOption>
                    ))}
                </MenuOptions>
            </Menu>
        </>
    );
};

const dropdownStyles = (theme: Theme) =>
    StyleSheet.create({
        selected: {
            flexDirection: 'row',
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            alignItems: 'center',
            borderWidth: 1.5,
            borderRadius: 4,
        },
        selectedText: {
            color: theme.darkGrey,
            flex: 1,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
        },
        chevron: { transform: [{ rotate: '90deg' }] },
        menu: {
            width: WW * 0.92,
            backgroundColor: theme.mainBackground,
        },
        menuText: {
            color: theme.black,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
        },
    });

export default FormDropdown;
