import React, { ComponentProps, PropsWithChildren } from 'react';
import { TouchableWithoutFeedback, View, StyleSheet } from 'react-native';
import { Menu, MenuOptions, MenuOption, MenuTrigger } from 'react-native-popup-menu';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { Theme, useThemeStyle } from '_utils';
import { smallestMargin, titleFontSize } from '_utils/sizes';
import HeimeText from 'Components/HeimeText';
import Icon from 'Components/Icon/Icon';
import CustomRenderer from './components/CustomRenderer';

interface ChatMessageWithMenuProps {
    contextActions: {
        text: string;
        onPress: () => void;
        variant?: 'danger';
        icon: ComponentProps<typeof Icon>['name'];
    }[];
    menuRef: React.RefObject<Menu>;
}

const ChatContextMenu = ({ contextActions, children, menuRef }: PropsWithChildren<ChatMessageWithMenuProps>) => {
    const themedStyle = useThemeStyle(style);
    const opacity = useSharedValue(0);
    const oppositeOpacity = useSharedValue(1);

    const handleVisible = () => {
        opacity.value = withTiming(1, { duration: 200 });
        oppositeOpacity.value = withTiming(0, { duration: 200 });
    };
    const handleInvisible = () => {
        opacity.value = withTiming(0, { duration: 200 });
        oppositeOpacity.value = withTiming(1, { duration: 0 });
    };

    const animatedStyle = useAnimatedStyle(() => {
        return {
            opacity: opacity.value,
        };
    });
    const opposite = useAnimatedStyle(() => {
        return {
            opacity: oppositeOpacity.value,
        };
    });

    if (contextActions.length === 0) {
        return children;
    }

    return (
        <Menu
            ref={menuRef}
            onOpen={handleVisible}
            onClose={handleInvisible}
            renderer={(props: ComponentProps<typeof CustomRenderer>) => (
                <CustomRenderer {...props} message={children} />
            )}
        >
            <MenuTrigger customStyles={{ TriggerTouchableComponent: TouchableWithoutFeedback }} triggerOnLongPress>
                <Animated.View style={opposite}>{children}</Animated.View>
            </MenuTrigger>
            <MenuOptions customStyles={themedStyle}>
                <View style={themedStyle.container}>
                    <Animated.View style={animatedStyle}>
                        {contextActions.map((action, index) => (
                            <MenuOption
                                style={[themedStyle.optionStyle, index !== 0 ? themedStyle.borderTop : undefined]}
                                key={index}
                                onSelect={action.onPress}
                            >
                                <HeimeText
                                    style={[
                                        action.variant === 'danger' ? themedStyle.dangerText : themedStyle.text,
                                        themedStyle.baseText,
                                    ]}
                                >
                                    {action.text}
                                </HeimeText>
                                <Icon name={action.icon} color={action.variant === 'danger' ? 'red' : 'main'} />
                            </MenuOption>
                        ))}
                    </Animated.View>
                </View>
            </MenuOptions>
        </Menu>
    );
};

const style = (theme: Theme) =>
    StyleSheet.create({
        optionsContainer: {
            backgroundColor: 'transparent',
        },
        optionWrapper: {
            backgroundColor: theme.white,
        },
        container: {
            alignItems: 'flex-end',
        },
        optionStyle: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: smallestMargin,
            justifyContent: 'space-between',
            padding: smallestMargin,
        },
        dangerText: {
            color: theme.red,
        },
        text: {
            color: theme.main,
        },
        baseText: {
            fontSize: titleFontSize,
        },
        borderTop: {
            borderTopWidth: 1,
            borderTopColor: theme.lightGrey,
            borderStyle: 'solid',
        },
    });

export default ChatContextMenu;
