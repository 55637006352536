import { useTranslation } from 'react-i18next';
import { PicturesProps } from './picturesProps';
import { NotSupported } from '../common';

const Pictures = ({ onSkip }: PicturesProps) => {
    const { t } = useTranslation();
    return <NotSupported onSkip={onSkip} actionText={t('procedure:pictures_not_supported')} />;
};

export default Pictures;
