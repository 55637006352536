import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Images from '_images';
import { Theme, WH, useThemeStyle } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { PrimaryButton, BackArrow, LanguageSelector, HeimeText, CacheImage } from 'Components';
import ContactModal from './ContactModal';
import Title from './Title';
import { useAuthNavigation, useAuthRoute } from '../AuthNavigation';

const ConfirmUser = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const navigation = useAuthNavigation();
    const { params } = useAuthRoute<'ConfirmUser'>();
    const [modalOpen, setModalOpen] = useState(false);

    const handleGoBack = () => {
        navigation.pop();
    };
    const handleConfirm = () => {
        navigation.navigate('CreateRegistration', { ...params });
    };
    const handleNotConfirmed = () => {
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            <View style={themedStyle.navWrapper}>
                <TouchableOpacity onPress={handleGoBack} style={themedStyle.navigationContainer}>
                    <BackArrow />
                    <HeimeText style={themedStyle.backText}>{t('enterPin:back')}</HeimeText>
                </TouchableOpacity>
                <LanguageSelector />
            </View>

            <View>
                <Title style={themedStyle.title} text={t('confirmUser:title')} />
                <HeimeText style={themedStyle.subTitle}>{t('confirmUser:subTitle')}</HeimeText>
            </View>
            <CacheImage source={Images.sharingWall} style={themedStyle.image} resizeMode="contain" />
            <View style={themedStyle.buttonContainer}>
                <PrimaryButton
                    style={themedStyle.button}
                    danger
                    onPress={handleNotConfirmed}
                    text={t('confirmUser:negative')}
                />
                <PrimaryButton style={themedStyle.button} onPress={handleConfirm} text={t('confirmUser:positive')} />
            </View>
            {modalOpen ? <ContactModal onDismiss={handleModalClose} /> : null}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        navWrapper: { flexDirection: 'row', alignItems: 'center' },
        navigationContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        backText: {
            fontSize: 16,
            letterSpacing: 1,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: theme.main,
        },
        title: {
            paddingTop: 24,
            paddingBottom: 24,
            paddingLeft: 24,
            paddingRight: 24,
            textAlign: 'left',
        },
        subTitle: {
            fontSize: subtitleFontSize,
            paddingLeft: 24,
            paddingRight: 24,
            color: theme.black,
        },
        image: {
            height: WH * 0.3,
        },
        buttonContainer: {
            flexDirection: 'row',
            gap: smallestMargin,
        },
        button: {
            flexBasis: '40%',
            flexGrow: 1,
        },
        modalText: {
            fontSize: subtitleFontSize,
        },
        supportInfoContainer: {
            alignItems: 'center',
            paddingTop: screenMargin,
            paddingBottom: screenMargin,
        },
        clickableText: {
            fontSize: subtitleFontSize,
            marginBottom: smallestMargin,
            color: theme.hyperText,
            fontWeight: 'bold',
        },
    });

export default ConfirmUser;
