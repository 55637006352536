import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, TouchableOpacity, ViewStyle, StyleSheet } from 'react-native';
import { Icon } from 'Components';

const CloseButton = ({ onPress, style }: { onPress: () => void; style?: StyleProp<ViewStyle> }): ReactElement => {
    const { t } = useTranslation();
    return (
        <TouchableOpacity onPress={onPress} style={[style, styles.touchable]} accessibilityLabel={t('global:close')}>
            <Icon name="cross" color="black" style={styles.icon} />
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    touchable: {
        width: 40,
        height: 40,
    },
    icon: {
        transform: [{ scale: 1.2 }],
    },
});

export default CloseButton;
