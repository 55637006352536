import React, { ReactElement, useCallback, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import LeaveChatModal from './LeaveChatModal';
import { useMakeAdmin } from '../../../_api/useChats';
import { useOwnProfile } from '../../../_api/useProfile';
import { WW } from '../../../_utils';
import { ThemeContext } from '../../../_utils/themeContext';
import { ContextMenu, ContextTrigger, QueryView, UserListItem } from '../../../Components';
import { UserCollectionItemWithAvatar } from '../../../types/User';
import { ArrayElement } from '../../../types/Utility';

interface MemberListProps {
    users: UserCollectionItemWithAvatar[];
    creator_id: number;
    chatId: number;
    isGroupRelated: boolean;
}

const MemberList = ({ users, creator_id, chatId, isGroupRelated }: MemberListProps): ReactElement => {
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const { data: profile } = useOwnProfile();
    const [leaveChatModalOpen, setLeaveChatModalOpen] = useState(false);
    const userIsAdmin = profile?.id === creator_id;

    const [isMoreMenuOpen, setMoreMenuOpen] = useState<number | false>(false);
    const getHandleMoreMenuToggle = useCallback(
        (userId: number) => () => setMoreMenuOpen(isMoreMenuOpen ? false : userId),
        [isMoreMenuOpen],
    );

    const { mutate: makeAdmin, isPending: isMakingAdmin } = useMakeAdmin();

    const renderItem = useCallback(
        ({ item }: { item: ArrayElement<typeof users>; index: number }) => {
            const isAdmin = item.id === creator_id;
            const isSelf = item.id === profile?.id ?? 0;
            return (
                <UserListItem
                    icon={isAdmin ? 'award' : undefined}
                    title={item.name}
                    desc={isAdmin ? (isSelf ? t('chatInfo:admin') : t('chatInfo:nonAdmin')) : ''}
                    descColor={isAdmin ? theme.error : theme.secondaryText}
                    id={item.id}
                    actionButton={
                        /*
                        isSelf && userIsAdmin -- no ContextMenu
                        isSelf && !userIsAdmin -- leave option available
                        !isSelf && userIsAdmin -- make admin available
                        !isSelf && !userIsAdmin -- no context menu
                        */
                        isSelf === userIsAdmin || isGroupRelated ? undefined : (
                            <ContextMenu
                                onToggle={getHandleMoreMenuToggle(item.id)}
                                isOpen={isMoreMenuOpen === item.id}
                                trigger={<ContextTrigger />}
                                actions={[
                                    isSelf
                                        ? {
                                              text: t('chatInfo:leaveChat'),
                                              onPress: () => {
                                                  setLeaveChatModalOpen(true);
                                              },
                                          }
                                        : {
                                              text: t('chatInfo:makeAdmin'),
                                              onPress: () => {
                                                  makeAdmin([chatId, item.id], {
                                                      onSuccess: () => getHandleMoreMenuToggle(item.id)(),
                                                  });
                                              },
                                              loading: isMakingAdmin,
                                          },
                                ]}
                            />
                        )
                    }
                    containerStyle={styles.itemContainer}
                />
            );
        },
        [
            chatId,
            creator_id,
            getHandleMoreMenuToggle,
            isGroupRelated,
            isMakingAdmin,
            isMoreMenuOpen,
            makeAdmin,
            profile?.id,
            t,
            theme.error,
            theme.secondaryText,
            userIsAdmin,
        ],
    );

    const handleLeaveClose = () => {
        setLeaveChatModalOpen(false);
    };

    return (
        <>
            <QueryView
                style={styles.list}
                data={users}
                renderItem={renderItem}
                loadMore={() => {}}
                isLoadingMore={false}
                onRefresh={() => {}}
                isRefreshing={false}
            />
            {leaveChatModalOpen ? <LeaveChatModal onClose={handleLeaveClose} chatId={chatId} /> : null}
        </>
    );
};

const styles = StyleSheet.create({
    list: { flex: 1, paddingTop: WW * 0.04, paddingBottom: WW * 0.04, paddingLeft: WW * 0.04, paddingRight: WW * 0.04 },
    itemContainer: { alignItems: 'center' },
});

export default MemberList;
