import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';
import { openURL, Theme, useThemeStyle, WH, WW } from '_utils';
import { HeimeText } from 'Components';

interface StaticFooterProps {
    style?: ViewStyle;
    phone: string;
    email: string;
}
const StaticFooter = ({ style, phone, email }: StaticFooterProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const call = () => openURL(`tel:${phone}`, t);
    const mail = () => openURL(`mailto:${email}`, t);

    return (
        <View style={[themedStyle.main, style]}>
            <TouchableOpacity style={themedStyle.phoneButton} onPress={call}>
                <HeimeText style={[themedStyle.text, themedStyle.labelText]}>{t('footer:label')}</HeimeText>
                <HeimeText selectable style={themedStyle.text}>
                    {phone}
                </HeimeText>
            </TouchableOpacity>

            <TouchableOpacity onPress={mail}>
                <HeimeText selectable style={themedStyle.text}>
                    {email}
                </HeimeText>
            </TouchableOpacity>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flexDirection: 'row',
            borderColor: theme.main,
            borderTopWidth: 0.5,
            paddingTop: WH * 0.015,
            paddingBottom: WH * 0.015,
            backgroundColor: theme.mainBackground,
            paddingLeft: WW * 0.05,
            paddingRight: WW * 0.05,
            justifyContent: 'space-around',
        },
        phoneButton: { flexDirection: 'row' },
        labelText: { marginRight: 5 },
        text: {
            color: theme.main,
            fontSize: WW * 0.027,
        },
    });

export default StaticFooter;
