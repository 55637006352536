import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import Icon from './Icon/Icon';

const BackArrow = (): ReactElement => {
    return (
        <View style={styles.icon}>
            <Icon name="arrow" color="main" />
        </View>
    );
};

const styles = StyleSheet.create({ icon: { transform: [{ rotate: '180deg' }, { scale: 1.5 }], marginRight: 12 } });

export default BackArrow;
