import React, { ReactElement, useMemo, memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import useNativeNotificationClickHandling from './hooks/useNativeNotificationClickHandling';
import useNavigateToAllowPush from './hooks/useNavigateToAllowPush';
import RenderConfiguration from './RenderConfiguration';
import { useOwnProfile } from '../_api/useProfile';
import { useRegisterUserContext, useSelectedCoopContext } from '../_utils/Tracking';
import { AppErrorScreen } from '../Components';
import SimpleLoadScreen from '../Screens/SimpleLoadScreen';

const Navigation = (): ReactElement => {
    const { isLoading, data: profile, error } = useOwnProfile();

    useNativeNotificationClickHandling();
    useSelectedCoopContext();
    useRegisterUserContext();

    const initialRoute = useMemo(() => {
        if (!profile?.gdpr) {
            return 'Onboarding';
        }
        return 'Main';
    }, [profile]);

    useNavigateToAllowPush(initialRoute === 'Onboarding');

    if (!isLoading && !profile) {
        throw error;
    }

    if (!profile || isLoading) {
        return <SimpleLoadScreen />;
    }

    return (
        <ErrorBoundary FallbackComponent={AppErrorScreen}>
            <RenderConfiguration initialRoute={initialRoute} />
        </ErrorBoundary>
    );
};

export default memo(Navigation);
