import { ProductReservation } from '_api/useReservations';
import { isBookingInSlot } from '_utils';
import { UnixTimeCode } from 'types/Utility';

const isSlotTaken = ({
    start,
    end,
    bookings,
    amount,
    capacity,
}: {
    start: UnixTimeCode;
    end: UnixTimeCode;
    bookings: ProductReservation[];
    amount: number;
    capacity: number;
}) =>
    amount + bookings.filter(isBookingInSlot(start, end)).reduce((acc, curr) => (curr.quantity ?? 1) + acc, 0) >
    capacity;

export default isSlotTaken;
