import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Icon, LanguageSelectorModal } from '.';
import { AVAILABLE_LANGUAGES } from '../_localization';
import { Theme, useThemeStyle, useGlobalState, WW } from '../_utils';

const LanguageSelector = (): ReactElement | null => {
    const { t } = useTranslation();
    const themedStyle = useThemeStyle(styles);
    const { selectedLanguage } = useGlobalState((state) => state.selectedLanguage);
    const [modalOpen, setModalOpen] = useState<'languageSelector' | false>(false);
    const handleOpenModal = () => setModalOpen('languageSelector');
    const handleCloseModal = () => setModalOpen(false);
    return (
        <>
            <TouchableOpacity
                accessibilityLabel={t('global:select_language')}
                activeOpacity={1}
                onPress={handleOpenModal}
                style={themedStyle.main}
            >
                <Icon name={AVAILABLE_LANGUAGES[selectedLanguage ?? 'no'].flag} color="accent" />
                <View style={themedStyle.chevron}>
                    <Icon name={'chevron'} color="black" />
                </View>
            </TouchableOpacity>
            {modalOpen === 'languageSelector' ? <LanguageSelectorModal onDismiss={handleCloseModal} /> : null}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flexDirection: 'row',
            alignSelf: 'flex-end',
            alignItems: 'center',
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            borderRadius: 5,
            elevation: 1,
            backgroundColor: theme.white,
        },
        chevron: { marginLeft: WW * 0.02, transform: [{ rotate: '90deg' }] },
    });

export default LanguageSelector;
