import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnixTimeCode } from '../types/Utility';

export type licensePlateState = {
    licensePlates: Array<{
        id: number;
        value: string;
        label: string;
        last_used_at: UnixTimeCode;
    }>;
};

const initialState: licensePlateState = { licensePlates: [] };

const sortPlates = (a: { last_used_at: number }, b: { last_used_at: number }) => b.last_used_at - a.last_used_at;

const licensePlateSlice = createSlice({
    name: 'licensePlate',
    initialState,
    reducers: {
        addPlate: (state, action: PayloadAction<{ value: string; id?: number; label?: string }>) => {
            state.licensePlates = [
                {
                    id: action.payload.id ?? Math.max(...state.licensePlates.map((plate) => plate.id), 0) + 1,
                    value: action.payload.value,
                    label: action.payload.label ?? '',
                    last_used_at: new Date().getTime(),
                },
                ...state.licensePlates,
            ];

            state.licensePlates.sort(sortPlates);
        },
        updatePlate: (state, action: PayloadAction<{ id: number; label: string; value: string }>) => {
            const foundPlate = state.licensePlates.find((plate) => plate.id === action.payload.id);
            if (foundPlate) {
                foundPlate.label = action.payload.label;
                foundPlate.value = action.payload.value;
            }
        },
        removePlate: (state, action: PayloadAction<{ id: number }>) => {
            state.licensePlates = state.licensePlates.filter((plate) => plate.id !== action.payload.id);
        },
        selectPlate: (state, action: PayloadAction<{ id: number }>) => {
            const foundPlate = state.licensePlates.find((plate) => plate.id === action.payload.id);
            if (foundPlate) {
                foundPlate.last_used_at = new Date().getTime();
            }
            state.licensePlates.sort(sortPlates);
        },
    },
});

export const { addPlate, updatePlate, removePlate, selectPlate } = licensePlateSlice.actions;

export default licensePlateSlice.reducer;
