import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateTitle, PageContainer } from './common';
import { HeaderWithNav, PrimaryButton, CooperativeGroupDropdown } from '../../../../Components';
import { FormValues } from '../schema';

const ActivityCooperativeGroup = ({ goBack }: { goBack(): void }): ReactElement => {
    const { t } = useTranslation();
    const { handleSubmit } = useFormContext<FormValues>();

    return (
        <>
            <HeaderWithNav onPress={goBack} title={t('newActivity:title_create_activity')} />
            <PageContainer>
                <CreateTitle>{t('newActivity:cooperative_group')}</CreateTitle>
                <CooperativeGroupDropdown isCreatingNew={false} />
            </PageContainer>
            <PrimaryButton
                text={t('newActivity:continue').toUpperCase()}
                onPress={handleSubmit(goBack)}
                bottomAction="modal"
            />
        </>
    );
};

export default ActivityCooperativeGroup;
