import React, { ReactElement, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { z } from 'zod';
import { MainHeader } from '_navigator/components';
import { RoutePropsSchema } from 'types/Utility';
import { ChatList } from './components/ChatList';
import { useGetChats } from '../../_api/useChats';
import { useOwnProfile } from '../../_api/useProfile';
import { useAppNavigation, useContextMenuFocusEffect } from '../../_navigator';
import { Theme, useThemeStyle, WH, WW } from '../../_utils';
import { flattenIniniteResult } from '../../_utils/misc';
import { screenMargin } from '../../_utils/sizes';
import { BottomSpacer, ExpandableSearchInput, HeaderWithNav, Loader, QueryItemView } from '../../Components';

const routeSchema = RoutePropsSchema(z.object({ showBackHeader: z.enum(['true', 'false']).optional() }));

const ChatAll = (props: z.infer<typeof routeSchema>): ReactElement => {
    const { route: { params: { showBackHeader } = {} } = {} } = props;
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    useContextMenuFocusEffect(['chatNew']);
    const [searchedText, setSearchedText] = useState<string>('');
    const { fetchNextPage, data, isLoading, isError, error, refetch, isRefetching, isFetchingNextPage } = useGetChats();
    const { data: profile } = useOwnProfile();
    const chats = useMemo(() => flattenIniniteResult(data), [data]);
    const selectChat = (selection: number) => {
        navigate('ChatSelected', { chatId: `${selection}` });
    };

    const filteredData = useMemo(() => {
        if (searchedText !== '') {
            return chats.filter((item) => {
                return (
                    item.name?.toLowerCase().includes(searchedText.toLowerCase()) ||
                    item.users
                        .filter(({ id }) => id !== profile?.id ?? 0)
                        .map(({ name }) => name)
                        .join(', ')
                        .toLowerCase()
                        .includes(searchedText.toLowerCase())
                );
            });
        } else {
            return chats;
        }
    }, [chats, profile?.id, searchedText]);

    const handleSearchTextChange = useCallback((newStr: string | undefined) => {
        setSearchedText(newStr ?? '');
    }, []);

    const insets = useSafeAreaInsets();

    if (isError) {
        throw error;
    }

    return (
        <QueryItemView
            virtualized
            style={[themedStyle.main, { paddingTop: insets.top }]}
            isRefreshing={isRefetching}
            onRefresh={refetch}
        >
            {showBackHeader === 'true' ? <HeaderWithNav title={t('chatAll:title')} /> : <MainHeader padBottom />}
            {isLoading ? (
                <View style={themedStyle.loader}>
                    <Loader bgColor="shadow" />
                </View>
            ) : (
                <>
                    {chats.length === 0 ? (
                        <Text style={themedStyle.noChatsText}>{t('chatAll:noChats')}</Text>
                    ) : (
                        <QueryItemView
                            virtualized
                            style={themedStyle.listView}
                            isRefreshing={isRefetching}
                            onRefresh={refetch}
                        >
                            <View style={themedStyle.searchContainer}>
                                <ExpandableSearchInput
                                    value={searchedText}
                                    onChange={handleSearchTextChange}
                                    buttonText={t('chatAll:searchPlaceholder')}
                                />
                            </View>
                            <ChatList
                                chats={filteredData}
                                onChatSelect={selectChat}
                                fetchNextPage={fetchNextPage}
                                isFetchingNextPage={isFetchingNextPage}
                            />
                            <BottomSpacer />
                        </QueryItemView>
                    )}
                </>
            )}
        </QueryItemView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.shadow,
            height: '100%',
            display: 'flex',
        },
        searchContainer: {
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
            marginLeft: screenMargin,
            marginRight: screenMargin,
        },
        listView: {
            flex: 1,
            backgroundColor: theme.mainBackground,
        },
        noChatsText: {
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            paddingTop: WH * 0.02,
            paddingBottom: WH * 0.02,
            textAlign: 'center',
        },
        loader: { height: WH * 0.8 },
    });

export { ChatAll };
