import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { smallestFontSize, smallestMargin } from '_utils/sizes';
import { HeimeText, Icon } from 'Components';
import { NoticeMessage } from 'types/message';

const ApartmentGroupsDisplay = ({ apartment_groups }: Pick<NoticeMessage, 'apartment_groups'>): ReactElement | null => {
    const { t } = useTranslation();
    const themedStyle = useThemeStyle(styles);
    if (apartment_groups.length === 0) {
        return null;
    }

    const rest = apartment_groups.slice(0, -1);
    const last = apartment_groups[apartment_groups.length - 1];
    const text =
        rest.length > 0 ? `${rest.map((group) => group.name).join(', ')} ${t('global:and')} ${last.name}` : last.name;
    return (
        <View style={themedStyle.container}>
            <Icon name="sharing" color="main" />
            <HeimeText style={themedStyle.text}>
                {t('sharingAll:onlyFor')}
                {text}
            </HeimeText>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: { flexDirection: 'row', alignItems: 'center', marginBottom: smallestMargin },
        text: { fontSize: smallestFontSize, color: theme.main, flex: 1 },
    });

export default ApartmentGroupsDisplay;
