import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import CacheImage from './CacheImage';
import StaticFooter from './Footer/StaticFooter';
import PrimaryButton from './PrimaryButton';
import SimpleHeader from './SimpleHeader';
import errorImage from '../../resources/images/sharingWall.png';
import { useStaticSettings } from '../_api/useSettings';
import { useThemeStyle, WH, WW, Theme, isAppError, isNetworkError } from '../_utils';
import { resetAsyncStorage } from '../_utils/asyncStorage';
import { addBreadcrumb, captureException } from '../_utils/Sentry';

interface GlobalErrorScreenProps {
    resetErrorBoundary(): void;
    error: Error;
}

const GlobalErrorScreen = ({ resetErrorBoundary, error }: GlobalErrorScreenProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    useEffect(() => {
        resetAsyncStorage();
    }, []);

    useEffect(() => {
        addBreadcrumb('errorScreen', 'Error captured by GlobalErrorScreen');
        captureException(error);
    }, [error]);

    const handleTryAgain = () => {
        addBreadcrumb('errorScreen', 'user clicked "try again"');
        resetErrorBoundary();
    };

    const settings = useStaticSettings();

    const isNetworkProblem = isAppError(error) && isNetworkError(error);

    return (
        <SafeAreaView style={themedStyle.safeView} edges={['bottom', 'left', 'right']}>
            <ScrollView
                alwaysBounceVertical={false}
                style={themedStyle.container}
                contentContainerStyle={themedStyle.contentContainer}
            >
                <SimpleHeader />
                <CacheImage resizeMode={'contain'} source={errorImage} style={themedStyle.errorImage} />
                <View style={themedStyle.textContainer}>
                    <Text style={themedStyle.mainText}>
                        {isNetworkProblem ? t('globalError:networkHeader') : t('globalError:header')}
                    </Text>
                    <Text style={themedStyle.subText}>
                        {isNetworkProblem ? t('globalError:subTextNetwork') : t('globalError:subText')}
                    </Text>
                </View>
                <PrimaryButton style={themedStyle.button} onPress={handleTryAgain} text={t('globalError:button')} />
            </ScrollView>
            <StaticFooter email={settings.mail} phone={settings.phone} />
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        safeView: {
            flex: 1,
            display: 'flex',
        },
        contentContainer: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        },
        errorImage: {
            width: '100%',
            height: WH * 0.3,
            minWidth: '100%',
            aspectRatio: 1,
        },

        textContainer: {
            flex: 1,
            paddingBottom: 0.3 * WW,
            maxWidth: 0.8 * WW,
            justifyContent: 'center',
        },
        mainText: {
            color: theme.black,
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 12,
        },
        subText: {
            color: theme.secondaryText,
            textAlign: 'center',
            fontSize: 16,
        },
        button: {
            marginTop: 16,
            marginBottom: 16,
            marginLeft: 16,
            marginRight: 16,
            width: WW - 16 * 2,
        },
    });

export default GlobalErrorScreen;
