import React, { useCallback, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteDevice } from '_api/useDeviceToken';
import useAppDispatch from '_utils/hooks/useAppDispatch';
import useGlobalState from '_utils/hooks/useGlobalState';
import { deleteToken } from '../_dependencies/firebase';
import reduxLogout from '../_redux/logout';

export const AuthContext = React.createContext<{ getAccessToken(): Promise<string>; logout(): void } | null>(null);

export const useGetAccessToken = (): (() => Promise<string>) => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('Could not find authContext');
    }
    return context.getAccessToken;
};

export const useLogoutWithoutContext = (): (() => void) => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const logout = useCallback(() => {
        reduxLogout(dispatch);

        deleteToken();
        queryClient.clear();
    }, [dispatch, queryClient]);

    return logout;
};

export const useLogout = (): (() => void) => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('Could not find authContext');
    }

    const device = useGlobalState((state) => state.deviceToken);
    const { mutate: deleteDevice } = useDeleteDevice();
    const innerLogout = useLogoutWithoutContext();

    const logout = useCallback(() => {
        if (device.device_id) {
            deleteDevice(device.device_id);
        }
        context.logout();
        innerLogout();
    }, [context, deleteDevice, device.device_id, innerLogout]);

    return logout;
};
