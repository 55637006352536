import React, { ReactElement } from 'react';
import { Text, StyleSheet } from 'react-native';
import _fonts from '../../../_fonts';
import { Theme, useThemeStyle } from '../../../_utils';
import { smallestFontSize } from '../../../_utils/sizes';

const MessageAuthor = ({ authorName }: { authorName: string }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <Text maxFontSizeMultiplier={2} style={themedStyle.userText}>
            {authorName}
        </Text>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        userText: {
            fontSize: smallestFontSize,
            alignSelf: 'flex-start',
            fontFamily: _fonts.primaryFontBold,
            color: theme.darkGreen,
        },
    });

export default MessageAuthor;
