import AsyncStorage from '@react-native-async-storage/async-storage';

const resetAsyncStorage = async (): Promise<void> => {
    AsyncStorage.clear().catch((storageError) => {
        // Supress these its not important
        console.warn('got errors when trying to clear storage', storageError);
    });
};

export { resetAsyncStorage };
