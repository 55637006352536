import React, { ReactElement } from 'react';
import moment from 'moment';
import { TouchableOpacity, View, Text, StyleSheet } from 'react-native';
import _fonts from '../../../_fonts';
import { Theme, useThemeStyle, WH, WW } from '../../../_utils';

interface MessageItemProps {
    onPress?: () => void;
    avatar?: ReactElement;
    name: string | null;
    lastMessage: string;
    timeStamp: number;
    unreadMessageCount: number;
}

const ChatItem = ({
    onPress,
    avatar,
    name,
    lastMessage,
    timeStamp,
    unreadMessageCount,
}: MessageItemProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const timeFormatter = (time: number) => {
        const isSameDate = moment.unix(time).isSame(moment(), 'date');
        if (isSameDate) {
            return moment.unix(time).fromNow();
        } else {
            return moment.unix(time).format('D MMMM');
        }
    };
    return (
        <TouchableOpacity>
            <TouchableOpacity style={themedStyle.main1} onPress={onPress}>
                {avatar}
                <View style={themedStyle.rowContainer}>
                    <View style={themedStyle.textContainer}>
                        <View style={themedStyle.nameTimeConatiner}>
                            <View style={themedStyle.nameContainer}>
                                <Text style={themedStyle.name} numberOfLines={3}>
                                    {name}
                                </Text>
                            </View>
                            <View style={themedStyle.timeContainer}>
                                <Text style={themedStyle.timeText} numberOfLines={1}>
                                    {timeFormatter(timeStamp)}
                                </Text>
                            </View>
                        </View>

                        <View style={themedStyle.nameTimeConatiner}>
                            <View style={themedStyle.rowContainer}>
                                <Text style={themedStyle.lastMessageText} numberOfLines={2}>
                                    {lastMessage}
                                </Text>
                            </View>
                            {unreadMessageCount > 0 && (
                                <View style={themedStyle.unreadMessageContainer}>
                                    <Text style={themedStyle.unreadMessageCount}>{unreadMessageCount}</Text>
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
            <View style={themedStyle.divider} />
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main1: {
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: WH * 0.02,
        },
        textContainer: {
            justifyContent: 'center',
        },
        name: {
            fontWeight: 'bold',
            marginBottom: WH * 0.005,
            fontSize: 14,
            fontFamily: _fonts.primaryFont,
            color: theme.black,
        },
        timeContainer: {
            flex: 1,
            alignItems: 'flex-end',
        },
        nameContainer: {
            flex: 2,
        },
        timeText: {
            fontSize: 10,
            marginTop: WH * 0.005,
            color: theme.greyMedium,
            fontFamily: _fonts.primaryFont,
        },
        nameTimeConatiner: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        rowContainer: {
            flex: 1,
        },
        unreadMessageContainer: {
            width: WW * 0.06,
            height: WW * 0.06,
            borderRadius: WH / 2,
            alignContent: 'center',
            justifyContent: 'center',
            backgroundColor: theme.lightGrey,
        },
        unreadMessageCount: {
            alignSelf: 'center',
            fontFamily: _fonts.primaryFont,
            fontWeight: 'bold',
            fontSize: 12,
            color: theme.darkGreen,
        },
        divider: {
            backgroundColor: theme.lightGrey,
            height: 1,
            marginBottom: WW * 0.04,
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
        },
        lastMessageText: {
            color: theme.graphite,
            fontSize: 12,
            fontFamily: _fonts.primaryFont,
        },
    });

export default ChatItem;
