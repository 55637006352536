import { Platform } from 'react-native';

export const isiOS = (): boolean => {
    return Platform.OS === 'ios';
};

export const isWeb = (): boolean => {
    return Platform.OS === 'web';
};

export const isAndroid = (): boolean => {
    return Platform.OS === 'android';
};
