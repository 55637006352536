import React, { ReactElement } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { z } from 'zod';
import { useOwnProfile } from '_api/useProfile';
import { useSurvey } from '_api/useSurveys';
import { SafeAreaView } from '_dependencies/safeArea';
import { isAppError, Theme, useThemeStyle } from '_utils';
import { HeaderWithNav, Loader, StepHeader } from 'Components';
import NotFoundErrorScreen from 'Components/NotFoundErrorScreen';
import { useSelectedCoop } from 'SelectedCoop';
import { RoutePropsSchema } from 'types/Utility';
import SelectApartment from './screens/SelectApartment';
import SurveyAnswer from './screens/SurveyAnswer';
import SurveyComplete from './screens/SurveyComplete';
import SurveyDetails from './screens/SurveyDetails';
import { SurveyProvider, useSurveyContext } from './SurveyContext';
import { SurveyStackParams } from './SurveyNavigation';

const routeSchema = RoutePropsSchema(z.object({ surveyId: z.string() }));

// Native stack does not update keyboard avoiding view
const Stack = createStackNavigator<SurveyStackParams>();

const SurveySelected = (props: z.infer<typeof routeSchema>): ReactElement => {
    const { surveyId } = routeSchema.parse(props).route.params;
    const { data, isError, isLoading, error } = useSurvey(surveyId ?? 'NO_ID');
    const themedStyle = useThemeStyle(styles);
    const cooperativeId = useSelectedCoop();
    const apartments =
        useOwnProfile().data?.cooperative_apartments?.filter((app) => app.cooperative_id === cooperativeId) ?? [];

    if (isError) {
        if (isAppError(error) && error.response?.status === 404) {
            return <NotFoundErrorScreen type="Survey" />;
        } else {
            throw error;
        }
    }

    if (isLoading) {
        return <Loader />;
    }

    if (!data) {
        return <NotFoundErrorScreen type="Survey" />;
    }

    const initialRouteName =
        apartments.length !== 1 && data.recipients_type === 'APARTMENTS' ? 'SelectApartment' : 'SurveyDetails';

    return (
        <SafeAreaView edges={['bottom', 'left', 'right']} style={themedStyle.main}>
            <SurveyProvider initialState={{ surveyId, screen: 'SurveySelected', goBack: () => {} }} context={undefined}>
                <SurveyHeader />
                <Stack.Navigator initialRouteName={initialRouteName} screenOptions={{ headerShown: false }}>
                    <Stack.Screen name="SelectApartment" component={SelectApartment} />
                    <Stack.Screen
                        name="SurveyDetails"
                        component={SurveyDetails}
                        initialParams={{
                            apartmentId:
                                data.recipients_type === 'USERS'
                                    ? null
                                    : apartments[0]?.id
                                      ? apartments[0].id + ''
                                      : null,
                        }}
                    />
                    <Stack.Screen name="SurveyAnswer" component={SurveyAnswer} />
                    <Stack.Screen name="SurveyComplete" component={SurveyComplete} />
                </Stack.Navigator>
            </SurveyProvider>
        </SafeAreaView>
    );
};

const SurveyHeader = (): ReactElement | null => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { state } = useSurveyContext();
    const { data } = useSurvey(state.surveyId);

    if (state.screen === 'SurveySelected' || state.screen === 'SelectApartment') {
        return (
            <HeaderWithNav
                style={themedStyle.header}
                title={t('surveys:title')}
                onPress={state.screen === 'SurveySelected' ? state.goBack : undefined}
            />
        );
    }

    if (!data) {
        return null;
    }
    if (state.screen === 'SurveyComplete') {
        return (
            <StepHeader
                currentStep={1}
                maxSteps={1}
                text={`${data?.title?.substring(0, 40)}${data?.title.length > 40 ? '...' : ''} - ${
                    data?.questions.length
                }/${data?.questions.length}`}
                bold
                onGoBack={state.goBack}
                backgroundColor="lightBackground"
            />
        );
    }

    return (
        <StepHeader
            currentStep={state.questionIndex + 1}
            maxSteps={(data?.questions.length ?? 0) + 1}
            text={`${data?.title?.substring(0, 40)}${data?.title.length > 40 ? '...' : ''} - ${
                state.questionIndex + 1
            }/${data?.questions.length}`}
            bold
            onGoBack={state.goBack}
            backgroundColor="lightBackground"
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        header: {
            backgroundColor: theme.lightBackground,
        },
        main: { height: '100%', backgroundColor: theme.lightBackground },
    });

export default SurveySelected;
