import React, { ReactElement, useState, useContext } from 'react';
import { Text, StyleSheet } from 'react-native';
// eslint-disable-next-line import/no-named-as-default
import Hyperlink from 'react-native-hyperlink';
import MessageAlertModal from './common/MessageAlertModal';
import _fonts from '../../../_fonts';
import { WW } from '../../../_utils';
import { ThemeContext } from '../../../_utils/themeContext';

const MessageText = ({
    text,
    isOwn,
    selectable,
}: {
    text: string;
    isOwn: boolean;
    selectable: boolean;
}): ReactElement => {
    const { theme } = useContext(ThemeContext);
    const [modalOpen, setModalOpen] = useState<string | false>(false);

    const handlePress = (url: string) => {
        setModalOpen(url);
    };

    const handleDismiss = () => {
        setModalOpen(false);
    };

    return (
        <>
            <Hyperlink onPress={handlePress} linkStyle={{ color: theme.secondaryLight }}>
                <Text
                    maxFontSizeMultiplier={2}
                    selectable={selectable}
                    style={[
                        styles.messageText,
                        { color: isOwn ? theme.ownChatMessageText : theme.otherChatMessageText },
                    ]}
                >
                    {text}
                </Text>
            </Hyperlink>
            {modalOpen ? <MessageAlertModal onDismiss={handleDismiss} link={modalOpen as string} /> : null}
        </>
    );
};

const styles = StyleSheet.create({
    messageText: {
        marginBottom: 4,
        fontSize: WW * 0.04,
        fontFamily: _fonts.primaryFont,
    },
});

export default MessageText;
