import React, { ReactElement, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import { Image, StyleSheet, Text, View, ScrollView } from 'react-native';
import { useBottomSpacer } from '_utils/hooks';
import { PrimaryButton } from '.';
import errorImage from '../../resources/images/sharingWall.png';
import { useAppNavigation } from '../_navigator';
import { Theme, useThemeStyle, WH, WW } from '../_utils';
import { addBreadcrumb } from '../_utils/Sentry';

interface NotFoundErrorScreenProps {
    type:
        | 'Activity'
        | 'Group'
        | 'Chat'
        | 'Product'
        | 'Profile'
        | 'Reservation'
        | 'Support'
        | 'MemberBenefit'
        | 'Message'
        | 'Survey';
}

const NotFoundErrorScreen = ({ type }: NotFoundErrorScreenProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { goBack } = useAppNavigation();
    const queryClient = useQueryClient();
    const paddingBottom = useBottomSpacer();

    useEffect(() => {
        switch (type) {
            case 'Activity':
                queryClient.invalidateQueries({
                    queryKey: ['activities'],
                });
                break;
            case 'Chat':
                queryClient.invalidateQueries({
                    queryKey: ['chats'],
                });
                break;
            case 'Group':
                queryClient.invalidateQueries({
                    queryKey: ['groups'],
                });
                break;
            case 'Product':
                queryClient.invalidateQueries({
                    queryKey: ['products'],
                });
                break;
            case 'Profile':
                queryClient.invalidateQueries({
                    queryKey: ['users'],
                });
                break;
            case 'Support':
                queryClient.invalidateQueries({
                    queryKey: ['supports'],
                });
                return () =>
                    queryClient.invalidateQueries({
                        queryKey: ['support'],
                    });
            case 'MemberBenefit':
                queryClient.invalidateQueries({
                    queryKey: ['memberBenefits'],
                });
                return () =>
                    queryClient.invalidateQueries({
                        queryKey: ['memberBenefit'],
                    });
            case 'Message':
                queryClient.invalidateQueries({
                    queryKey: ['messages'],
                });
                return () =>
                    queryClient.invalidateQueries({
                        queryKey: ['message'],
                    });
            case 'Survey':
                queryClient.invalidateQueries({
                    queryKey: ['surveys'],
                });
                return () =>
                    queryClient.invalidateQueries({
                        queryKey: ['survey'],
                    });
            default:
                break;
        }

        return () => {
            // We get infinite loading if we invalidate onMount (since we invalidate the failing query)
            switch (type) {
                case 'Activity':
                    queryClient.invalidateQueries({
                        queryKey: ['activity'],
                    });
                    break;
                case 'Chat':
                    queryClient.invalidateQueries({
                        queryKey: ['chat'],
                    });
                    break;
                case 'Group':
                    queryClient.invalidateQueries({
                        queryKey: ['group'],
                    });
                    break;
                case 'Product':
                    queryClient.invalidateQueries({
                        queryKey: ['product'],
                    });
                    break;
                case 'Profile':
                    queryClient.invalidateQueries({
                        queryKey: ['user'],
                    });
                    break;
                case 'Reservation':
                    queryClient.invalidateQueries({
                        queryKey: ['reservations'],
                    });
                    break;
                default:
                    break;
            }
        };
    }, [queryClient, type]);

    useEffect(() => {
        addBreadcrumb('errorScreen', 'Error captured by NotFoundErrorScreen');
    }, []);

    const handleGoBack = () => {
        addBreadcrumb('errorScreen', 'user clicked "try again" from 404 screen');
        goBack();
    };

    const t = i18next.getFixedT(null, null, `notFoundErrorScreen:${type}`);

    return (
        <ScrollView
            alwaysBounceVertical={false}
            style={themedStyle.container}
            contentContainerStyle={[themedStyle.contentContainer, { paddingBottom }]}
        >
            <Image source={errorImage} style={themedStyle.errorImage} />
            <View style={themedStyle.textContainer}>
                <Text style={themedStyle.mainText}>{t('header')}</Text>
                <Text style={themedStyle.subText}>{t('subtext')}</Text>
            </View>
            <PrimaryButton style={themedStyle.button} onPress={handleGoBack} text={t('button')} />
        </ScrollView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: theme.white,
        },
        contentContainer: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        },
        errorImage: {
            width: '100%',
            height: WH * 0.3,
            minWidth: '100%',
            resizeMode: 'contain',
            aspectRatio: 1,
        },

        textContainer: {
            flex: 1,
            paddingBottom: 0.3 * WW,
            maxWidth: 0.8 * WW,
            justifyContent: 'center',
        },
        mainText: {
            color: theme.black,
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 12,
        },
        subText: {
            color: theme.secondaryText,
            textAlign: 'center',
            fontSize: 16,
        },
        button: {
            marginBottom: 16,
            marginTop: 16,
            marginRight: 16,
            marginLeft: 16,
            width: WW - 16 * 2,
        },
    });

export default NotFoundErrorScreen;
