import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useTranslatedFormErrorMessage = (error: FieldError | undefined): null | string => {
    const { t } = useTranslation();

    if (!error) {
        return null;
    }

    if (error.type === 'too_big') {
        return t('globalError:too_big', { name: t(`globalError:${error.ref?.name as 'title'}`) });
    }

    return error?.message
        ? t(error.message as 'global:noLang') !== error.message
            ? t(error.message as 'global:noLang')
            : error.message
        : null;
};

export default useTranslatedFormErrorMessage;
