import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useSubscribeToMessage, useUnsubscribeToMessage } from '../../../../../../_api/useMessages';
import { useOwnProfile } from '../../../../../../_api/useProfile';
import { Theme, useThemeStyle, WW } from '../../../../../../_utils';
import { smallestFontSize } from '../../../../../../_utils/sizes';
import { PrimaryButton } from '../../../../../../Components';

interface SubscribeButtonProps {
    subscribers: number[];
    messageId: number;
}

const SubscribeButton = ({ subscribers, messageId }: SubscribeButtonProps): ReactElement => {
    const themeStyles = useThemeStyle(styles);
    const { t } = useTranslation();
    const { data: ownProfile } = useOwnProfile();
    const { mutate: subscribe } = useSubscribeToMessage();
    const { mutate: unsubscribe } = useUnsubscribeToMessage();
    const isSubscribed = subscribers.includes(ownProfile?.id ?? 0);

    const handleSubscribe = () => subscribe(messageId);
    const handleUnsubscribe = () => unsubscribe(messageId);

    return (
        <>
            {isSubscribed ? (
                <PrimaryButton
                    style={[themeStyles.followButton, themeStyles.subscribed]}
                    textStyle={themeStyles.textStyle}
                    text={t('sharingAll:unfollow')}
                    onPress={handleUnsubscribe}
                />
            ) : (
                <PrimaryButton
                    style={themeStyles.followButton}
                    textStyle={themeStyles.textStyle}
                    text={t('sharingAll:follow')}
                    onPress={handleSubscribe}
                />
            )}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        followButton: {
            paddingTop: WW * 0.02,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            paddingBottom: WW * 0.02,
            justifyContent: 'flex-start',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
        },
        textStyle: {
            fontSize: smallestFontSize,
        },
        subscribed: {
            backgroundColor: theme.greyMedium,
        },
    });

export default SubscribeButton;
