import type { AxiosError } from 'axios';
import moment from 'moment';
import { z, ZodSchema } from 'zod';
import { ApiImage, ApiImageObject } from './Base';
import { MessageFile } from './Chat';

export type UnixTimeCode = number;
export type Base64EncodedFile = string;
export type BlurHash = string;
export type Url = string;
export type Uuid = string;

const linksSchema = z.object({
    first: z.string(),
    last: z.string(),
    prev: z.string().nullable(),
    next: z.string().nullable(),
});

const metaSchema = z.object({
    current_page: z.number(),
    from: z.number().nullable(),
    last_page: z.number(),
    path: z.string(),
    per_page: z.number(),
    to: z.number().nullable(),
    total: z.number(),
});
const baseLaravelPagingResponseSchema = z.object({
    links: linksSchema.optional(),
    meta: metaSchema.optional(),
});

export interface LaravelPagingResponse<T> extends z.infer<typeof baseLaravelPagingResponseSchema> {
    data: T;
}

// Define a generic function to create a LaravelPagingResponse schema
export function createLaravelPagingResponseSchema<T>(dataSchema: z.ZodSchema<T, z.ZodTypeDef, unknown>) {
    return baseLaravelPagingResponseSchema.merge(
        z.object({
            data: dataSchema,
        }),
    );
}

export const ApiImageObjectToStaleFile = (image: ApiImageObject, index: number): StaleFile => ({
    ...image,
    status: 'stale',
    index,
});

export const GetFileToBeUploadedToApiFile =
    (userId: number) =>
    (file: FileToBeUploaded): MessageFile => ({
        ...file,
        sender_id: userId,
        id: Math.round(Math.random() * 1000),
        ext: file.name?.split('.').pop() ?? '',
        created_at: moment().unix(),
        original: file.uri,
        name: file?.name ?? file.index + '',
        last_modified: moment().unix(),
    });

export interface StaleFile extends ApiImageObject {
    status: 'stale';
    index: number;
    name?: string;
}

export const isStaleFile = (file: FileUpload): file is StaleFile => file.status === 'stale';

export interface FileToBeDeleted {
    status: 'deleted';
    id: number;
    index: number;
}

export const isFileToBeDeleted = (file: FileUpload): file is FileToBeDeleted => file.status === 'deleted';

export interface FileToBeUploaded {
    name: string;
    file?: File;
    uri: Base64EncodedFile;
    status: 'added';
    index: number;
    size?: number;
}

export const isFileToBeUploaded = (file: FileUpload): file is FileToBeUploaded => file.status === 'added';

export type FileUpload = StaleFile | FileToBeDeleted | FileToBeUploaded;

export const staleFileSchema = z.object({
    status: z.literal('stale'),
    id: z.number(),
    index: z.number(),
    name: z.string().optional(),
    original: z.string(),
    '64x0': z.string().optional(),
    '128x0': z.string().optional(),
    '512x0': z.string().optional(),
    '1024x0': z.string().optional(),
    '2048x0': z.string().optional(),
});

export const fileSchema = z.union([
    staleFileSchema,
    z.object({ status: z.literal('deleted'), id: z.number(), index: z.number() }),
    z.object({
        status: z.literal('added'),
        uri: z.string(),
        index: z.number(),
        name: z.string(),
        size: z.number().optional(),
    }),
]);

export const mapFileUploadsToApiImage = (files: FileUpload[]): ApiImage[] => {
    const staleFiles = files.filter(isStaleFile);
    const newFiles = files.filter(isFileToBeUploaded).map((file) => ({ original: file.uri, id: -1 }));
    return [...staleFiles, ...newFiles];
};

export const RoutePropsSchema = <T extends ZodSchema>(arg: T) => z.object({ route: z.object({ params: arg }) });

export const RoutePropNumberNullable = z
    .string()
    .nullable()
    .transform((val: string | null, ctx) => {
        if (val === null) {
            return null;
        }
        const parsed = parseInt(val, 10);
        if (isNaN(parsed)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Not a number',
            });
            return z.NEVER;
        }
        return parsed;
    });

export const RoutePropNumber = z.union([z.string(), z.number()]).transform((val: string | number, ctx) => {
    const parsed = typeof val === 'number' ? val : parseInt(val, 10);
    if (isNaN(parsed)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Not a number',
        });
        return z.NEVER;
    }
    return parsed;
});

export const RoutePropBoolean = z.union([z.string(), z.boolean()]).transform((val: string | boolean, ctx) => {
    const positiveValues = ['true', '1', 1, true];
    const negativeValues = ['false', '0', 0, false, ''];
    if (positiveValues.includes(val)) {
        return true;
    }
    if (negativeValues.includes(val)) {
        return false;
    }
    ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Not a boolean',
    });
    return z.NEVER;
});

export const GenericCollectionItemSchema = z.object({
    id: z.number(),
    name: z.string(),
});

export type GenericCollectionItem = z.infer<typeof GenericCollectionItemSchema>;

interface HeimeErrorResponse<DataType> {
    message: string;
    errors: Record<keyof DataType, string[]>;
}

export interface AppError<BodyType> extends AxiosError<HeimeErrorResponse<BodyType>, BodyType> {}

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
    ? ElementType
    : never;

export declare type ValueOf<T> = T[keyof T];

type SuccessfulResult = { success: true };
type UnsuccesfulResult = { success: boolean; errors: string[] };

export type ApiMutationResult = SuccessfulResult | UnsuccesfulResult;

export type NonNullablePickProperty<T, K extends keyof T> = {
    [P in K]: NonNullable<T[P]>;
};

export const LaravelBooleanSchema = z.union([z.literal(1), z.literal(0)]).transform((val) => val === 1);
