import React, { ReactElement, useContext } from 'react';
import { TouchableOpacity, View, Text, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { ApiImage, FileImage } from 'types/Base';
import _fonts from '../../../_fonts';
import Images from '../../../_images';
import { useThemeStyle, WH, WW } from '../../../_utils';
import { Theme, ThemeContext } from '../../../_utils/themeContext';
import { CacheImage, Icon, SelectorChip } from '../../../Components';

interface BoardListItemProps {
    onPress?: () => void;
    avatar: ApiImage | FileImage | null;
    title: string;
    actionButton?: ReactElement | null;
    containerStyle?: StyleProp<ViewStyle>;
    boardMember: boolean;
    roles: string;
    tags: string[];
}

const BoardListItem = ({
    onPress,
    avatar,
    title,
    containerStyle,
    actionButton,
    boardMember,
    roles,
    tags,
}: BoardListItemProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const avatar1 = (
        <View>
            <CacheImage source={avatar ?? Images.defaultAvatar} style={themedStyle.avatar} />
            {boardMember ? (
                <View style={themedStyle.adminIconContainer}>
                    <Icon name={'award'} color="lightRed" scale={1.5} />
                </View>
            ) : null}
        </View>
    );
    const content = (
        <>
            {avatar1}
            <View style={themedStyle.contentWrapper}>
                <Text numberOfLines={2} maxFontSizeMultiplier={2} style={themedStyle.title}>
                    {title}
                </Text>
                <Text style={themedStyle.roleText}>{roles}</Text>
                <View style={themedStyle.chipsWrapper}>
                    {tags.map((tag) => (
                        <SelectorChip variant="lightPrimary" key={tag} containerStyle={themedStyle.chip} title={tag} />
                    ))}
                </View>
            </View>
            {actionButton ? actionButton : undefined}
        </>
    );

    if (onPress) {
        return (
            <TouchableOpacity activeOpacity={1} onPress={onPress} style={[themedStyle.main, containerStyle]}>
                {content}
            </TouchableOpacity>
        );
    }
    return (
        <View
            style={[
                themedStyle.main,
                {
                    borderBottomColor: theme.lightGrey,
                    borderBottomWidth: 1,
                },
                containerStyle,
            ]}
        >
            {content}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: WH * 0.02,
            paddingBottom: WH * 0.02,
        },
        title: {
            fontSize: WW * 0.045,
            color: theme.main,
            fontFamily: _fonts.primaryFontBold,
        },
        roleText: {
            fontFamily: _fonts.primaryFont,
            color: theme.secondaryText,
            fontSize: WW * 0.04,
        },
        contentWrapper: {
            flex: 2,
            justifyContent: 'center',
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
        },
        adminIconContainer: {
            position: 'absolute',
            right: 0,
            bottom: 0,
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            backgroundColor: theme.lightGrey,
            borderRadius: (WW * 0.15) / 2,
        },
        avatar: {
            width: WW * 0.15,
            height: WW * 0.15,
            borderRadius: (WW * 0.15) / 2,
        },
        chipsWrapper: {
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        chip: {
            marginBottom: WH * 0.01,
            backgroundColor: theme.lightGreen,
            borderColor: theme.lightGreen,
        },
    });

export default BoardListItem;
