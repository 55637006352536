import React, { ComponentProps, ReactElement, useState, isValidElement } from 'react';
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import _fonts from '_fonts';
import { useThemeStyle, WH, WW, Theme } from '_utils';
import { BottomSpacer, Icon } from 'Components';

type IconProperty = ComponentProps<typeof Icon>['name'];
type MenuType = {
    id?: string;
    icon?: IconProperty;
    title: string;
    onPress?: () => void;
    children: MenuType[];
    rightNumber?: number;
};

interface FullPageMenuComponentProps {
    items: (MenuType | ReactElement)[];
}

const FullPageMenuComponent = ({ items }: FullPageMenuComponentProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const [opened, setOpened] = useState<string[]>([]);

    const handleOpen = (item: string) => setOpened((curr) => [...curr, item]);
    const handleClose = (item: string) => setOpened((curr) => curr.filter((other) => other !== item));

    return (
        <View style={themedStyle.container}>
            <ScrollView style={themedStyle.itemsContainer} alwaysBounceVertical={false}>
                {items.map((item) => {
                    if (isValidElement(item)) {
                        return item;
                    }
                    const { icon, title, children, onPress, rightNumber, id } = item as MenuType;
                    const isOpen = opened.includes(title);
                    const handleChevronPress = () => (isOpen ? handleClose(title) : handleOpen(title));
                    const handlePress = onPress ? onPress : handleChevronPress;

                    return (
                        <MenuItem
                            key={id ?? title}
                            icon={icon}
                            title={title}
                            children={children}
                            onPress={handlePress}
                            isOpen={isOpen}
                            rightNumber={rightNumber}
                        />
                    );
                })}
            </ScrollView>
            <BottomSpacer />
        </View>
    );
};

interface MenuItemProps {
    onPress?: () => void;
    icon?: IconProperty;
    title: string;
    children?: MenuType[];
    isOpen?: boolean;
    rightNumber?: number;
}

const MenuItem = ({ onPress, icon, title, children = [], isOpen, rightNumber }: MenuItemProps) => {
    const themedStyle = useThemeStyle(styles);
    return (
        <>
            <Pressable style={themedStyle.button} onPress={onPress}>
                <View style={themedStyle.itemContainer}>
                    {icon ? <Icon name={icon} color="main" /> : null}
                    <Text style={themedStyle.text}>{title}</Text>
                    {children.length > 0 ? (
                        <View style={isOpen ? themedStyle.chevronOpen : undefined}>
                            <Icon name="chevron" color="main" />
                        </View>
                    ) : rightNumber ? (
                        <View style={themedStyle.numberContainer}>
                            <Text style={themedStyle.numberStyle}>{rightNumber}</Text>
                        </View>
                    ) : null}
                </View>
            </Pressable>
            {isOpen ? (
                <View style={themedStyle.childrenContainer}>
                    {children.map(({ icon: childIcon, title: childTitle, onPress: childOnPress }) => {
                        return <MenuItem key={childTitle} onPress={childOnPress} icon={childIcon} title={childTitle} />;
                    })}
                </View>
            ) : undefined}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: theme.mainBackground,
            flex: 1,
        },
        button: { paddingLeft: 16, paddingRight: 16 },
        itemsContainer: {
            display: 'flex',
            flexDirection: 'column',
            borderTopColor: theme.mediumBackground,
        },
        itemContainer: {
            display: 'flex',
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderBottomColor: theme.mediumBackground,
            paddingTop: 24,
            paddingBottom: 24,
            alignItems: 'center',
        },
        text: {
            fontWeight: 'bold',
            fontFamily: _fonts.primaryFont,
            color: theme.main,
            fontSize: 16,
            flex: 1,
            marginLeft: 8,
        },
        chevronOpen: {
            transform: [{ rotate: '90deg' }],
        },
        childrenContainer: {
            paddingLeft: 24,
            paddingRight: 24,
            backgroundColor: theme.lightBackground,
            shadowColor: theme.black,
            shadowRadius: 5,
            shadowOpacity: 0.1,
            shadowOffset: { width: 0, height: 10 },
        },
        numberContainer: {
            width: WW * 0.06,
            height: WW * 0.06,
            borderRadius: WH / 2,
            alignContent: 'center',
            justifyContent: 'center',
            backgroundColor: theme.lightGrey,
        },
        numberStyle: {
            alignSelf: 'center',
            fontFamily: _fonts.primaryFont,
            fontWeight: 'bold',
            fontSize: 12,
            color: theme.darkGreen,
        },
    });

export default FullPageMenuComponent;
