import React, { ReactElement, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, FlatList, View } from 'react-native';
import { track } from '_utils/Amplitude';
import { useGetGroups } from '../../../_api/useGroups';
import _fonts from '../../../_fonts';
import { useAppNavigation } from '../../../_navigator';
import { Theme, useThemeStyle, useVisualRefetchState, WH, WW } from '../../../_utils';
import { useUserInUserCollection } from '../../../_utils/hooks';
import { flattenIniniteResult } from '../../../_utils/misc';
import { GroupList, PrimaryButton, SecondaryButton } from '../../../Components';
import OnboardingStepContainer from '../Components/OnboardingStepContainer';

const JoinGroups = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { data, isLoading, refetch, isFetching } = useGetGroups();
    const getIsMember = useUserInUserCollection();
    const { navigate } = useAppNavigation();
    const [isRefreshing, onRefresh] = useVisualRefetchState(isFetching, refetch);

    const groups = useMemo(() => flattenIniniteResult(data), [data]);

    const handleGoToNext = () => {
        track('JoinGroupsContinue', { nGroups: groups.filter((item) => getIsMember(item.users)) });
        navigate('StartScreen', undefined);
    };

    useEffect(() => {
        if (!isLoading && groups.length === 0) {
            handleGoToNext();
        }
    });

    return (
        <OnboardingStepContainer
            step={5}
            disableFooter
            bottomSticky={
                groups.find((item) => getIsMember(item.users)) === undefined ? (
                    <SecondaryButton
                        text={t('group:skip').toUpperCase()}
                        style={[themedStyle.secondaryButton, themedStyle.button]}
                        onPress={handleGoToNext}
                    />
                ) : (
                    <PrimaryButton
                        text={t('group:continue').toUpperCase()}
                        bottomAction="modal"
                        onPress={handleGoToNext}
                        style={themedStyle.button}
                    />
                )
            }
        >
            <View style={themedStyle.scrollContainer}>
                <FlatList
                    onRefresh={onRefresh}
                    refreshing={isRefreshing}
                    data={[{}]}
                    contentContainerStyle={themedStyle.content}
                    keyExtractor={() => ''}
                    renderItem={() => (
                        <View style={[themedStyle.content, themedStyle.contentContainer]}>
                            <Text style={themedStyle.title}>{t('group:title')}</Text>
                            <Text style={themedStyle.subTitle}>{t('group:subTitle')}</Text>
                            <GroupList groupScreen="GroupSelectedOnboard" />
                        </View>
                    )}
                />
            </View>
        </OnboardingStepContainer>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        scrollContainer: { flex: 1 },
        content: {
            display: 'flex',
        },
        contentContainer: { paddingLeft: 16, paddingRight: 16 },
        title: {
            marginTop: WH * 0.02,
            marginBottom: WH * 0.02,
            textAlign: 'center',
            fontFamily: _fonts.primaryFontBold,
            fontSize: WH * 0.025,
            color: theme.darkGray,
        },
        subTitle: {
            marginBottom: WW * 0.04,
            textAlign: 'center',
            fontFamily: _fonts.primaryFont,
            fontSize: WH * 0.02,
            color: theme.secondaryText,
        },
        secondaryButton: {
            marginLeft: 16,
            marginRight: 16,
            marginTop: 16,
            marginBottom: 16,
        },
        button: {
            flexShrink: 0,
        },
    });

export default JoinGroups;
