import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { useGetUser } from '_api/useUsers';
import _fonts from '_fonts';
import Images from '_images';
import { useThemeStyle, WW } from '_utils';
import { getUsernameFromProfile } from '_utils/misc';
import { screenMargin } from '_utils/sizes';
import { Theme, ThemeContext } from '_utils/themeContext';
import { CacheImage } from 'Components';
import { isNonDeletedUser } from 'types/User';

interface HelperItemProps {
    title: string;
    creator: number;
    isNew?: boolean;
    own?: boolean;
    onPress(): void;
}

const HelperItem = ({ title, creator, isNew = false, own = false, onPress }: HelperItemProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const user = useGetUser(creator);
    const rating = isNonDeletedUser(user) ? user.rating : 0;
    return (
        <TouchableOpacity onPress={onPress} style={themedStyle.main}>
            <CacheImage source={user?.avatar ?? Images.defaultAvatar} style={themedStyle.avatar} />
            <Text style={themedStyle.title}>{getUsernameFromProfile(user)}</Text>
            <View style={themedStyle.row}>
                <Text numberOfLines={2} style={themedStyle.subTitle}>
                    {title}
                </Text>
                {own && (
                    <>
                        <Text numberOfLines={2} style={themedStyle.dot}>
                            {t('sharingAll:dot')}
                        </Text>
                        <Text numberOfLines={2} style={themedStyle.ratingText}>
                            {t('sharingAll:you')}
                        </Text>
                    </>
                )}
            </View>

            <View
                style={[themedStyle.ratingContainer, { backgroundColor: own ? theme.mainBackground : theme.lightGrey }]}
            >
                <Text style={themedStyle.ratingText}>
                    {rating <= 0 ? t('sharingAll:no_star') : t('sharingAll:stars', { count: rating })}
                </Text>
            </View>
            {isNew && (
                <View style={themedStyle.newContainer}>
                    <Text style={themedStyle.newText}>{t('sharingAll:new')}</Text>
                </View>
            )}
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            width: WW / 2 - screenMargin * 1.5,
            minHeight: WW * 0.4,
            borderColor: theme.mediumBackground,
            borderTopWidth: 1,
            borderRightWidth: 1,
            borderBottomWidth: 1,
            borderLeftWidth: 1,
            justifyContent: 'center',
            paddingBottom: WW * 0.04,
            alignItems: 'center',
            paddingTop: WW * 0.02,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            backgroundColor: theme.mainBackground,
            marginTop: screenMargin,
        },
        avatar: {
            width: WW * 0.2,
            height: WW * 0.2,
            borderRadius: (WW * 0.2) / 2,
            overflow: 'hidden',
        },
        title: {
            fontFamily: _fonts.primaryFontBold,
            color: theme.black,
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            fontSize: WW * 0.04,
        },
        subTitle: {
            fontFamily: _fonts.primaryFont,
            color: theme.darkGreen,
            fontSize: WW * 0.03,
        },
        ratingContainer: {
            marginTop: WW * 0.02,
            borderRadius: WW * 0.04,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
        },
        ratingText: {
            fontFamily: _fonts.primaryFont,
            color: theme.secondary,
            fontSize: WW * 0.03,
        },
        dot: {
            fontFamily: _fonts.primaryFont,
            color: theme.secondaryLight,
            fontSize: WW * 0.03,
        },
        newContainer: {
            alignSelf: 'flex-start',
            position: 'absolute',
            top: 0,
            left: WW * 0.04,
            borderRadius: WW * 0.04,
            backgroundColor: theme.lightGreen,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
        },
        newText: {
            fontFamily: _fonts.primaryFont,
            color: theme.darkGreen,
            fontSize: WW * 0.03,
        },
        row: {
            flexDirection: 'row',
        },
    });

export default HelperItem;
