import React, { ReactElement } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';
import CheckBox from './CheckBox';

interface FormInputProps<FormType> {
    name: Path<FormType>;
    label: string;
}

const FormCheckbox = <FormType extends FieldValues = Record<string, string>>({
    name,
    label,
}: FormInputProps<FormType>): ReactElement => {
    const { field } = useController<FormType>({ name });

    const handlePress = () => {
        field.onChange(!field.value);
    };

    return <CheckBox onPress={handlePress} checked={field.value} title={label} />;
};

export default FormCheckbox;
