import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import Icon from './Icon/Icon';
import { useThemeStyle, WH, Theme } from '../_utils';

const ContextTrigger = ({ variant = 'grey' }: { variant?: 'light' | 'grey' }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    return (
        <View
            accessibilityLabel={t('global:more_choices')}
            style={[themedStyle.moreIcon, variant === 'light' ? themedStyle.lightIcon : themedStyle.greyIcon]}
        >
            <Icon name="moreFilled" color={variant === 'light' ? 'black' : 'secondaryText'} />
        </View>
    );
};
const styles = (theme: Theme) =>
    StyleSheet.create({
        lightIcon: {},
        greyIcon: {
            backgroundColor: theme.lightGrey,
        },
        moreIcon: {
            borderRadius: WH / 2,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 8,
        },
    });

export default ContextTrigger;
