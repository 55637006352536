import Clipboard from '@react-native-clipboard/clipboard';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { Theme, WH, showToast, useThemeStyle } from '_utils';
import { smallestMargin, subtitleFontSize, screenMargin, smallestFontSize, smallestMarginPixels } from '_utils/sizes';
import HeimeText from './HeimeText';
import Icon from './Icon/Icon';

const PinDisplay = ({ pin }: { pin: string }) => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const handleCopyPress = () => {
        Clipboard.setString(pin ?? '');
        showToast({
            header: t('expandedReservation:copied_pin_code'),
            text: '',
            type: 'success',
            position: 'bottom',
            shortTime: true,
        });
    };

    return (
        <TouchableOpacity
            style={themedStyle.pinContainer}
            onPress={handleCopyPress}
            accessibilityHint={t('expandedReservation:copy_pin_explainer')}
        >
            <View style={themedStyle.pinTextContainer}>
                <HeimeText style={themedStyle.pinExplainer}>{t('expandedReservation:pin_code')}:</HeimeText>
                <View style={themedStyle.pinDisplayContainer}>
                    <HeimeText style={themedStyle.pin}>{pin}</HeimeText>
                </View>
            </View>
            <Icon name="copySheets" color="main" style={themedStyle.pinCopy} />
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        pinContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: smallestMargin,
            backgroundColor: theme.mainBackground,
            borderColor: theme.main,
            borderWidth: 1,
            fontWeight: 'bold',
        },
        pinTextContainer: { color: theme.main, flexDirection: 'row', alignItems: 'center', gap: smallestMargin },
        pinExplainer: {
            fontSize: smallestFontSize,
        },
        pinDisplayContainer: {
            backgroundColor: theme.lightGrey,

            paddingVertical: smallestMarginPixels / 2,
            paddingHorizontal: smallestMargin,
            borderRadius: WH * 0.01,
            borderColor: 'transparent',
            borderWidth: 1,
        },
        pin: {
            fontSize: subtitleFontSize,
            color: theme.main,
            fontWeight: 'bold',
        },
        pinCopy: {
            position: 'absolute',
            right: screenMargin,
        },
    });

export default PinDisplay;
