import React, { ReactElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { z } from 'zod';
import { useFindReservation } from '_api/useReservations';
import { screenMargin } from '_utils/sizes';
import { RoutePropNumber, RoutePropsSchema } from 'types/Utility';
import { ProductBookingDetails } from './components';
import { SafeAreaView } from '../../_dependencies/safeArea';
import { useAppNavigation } from '../../_navigator';
import { Theme, useThemeStyle } from '../../_utils';
import { PrimaryButton, SecondaryButton, SmallHeader } from '../../Components';

const routeSchema = RoutePropsSchema(
    z.object({
        start: RoutePropNumber,
        end: RoutePropNumber,
        quantity: RoutePropNumber,
        product_id: RoutePropNumber,
        booking_id: RoutePropNumber,
    }),
);

const ReservationFinal = (props: z.infer<typeof routeSchema>): ReactElement => {
    const { product_id, start, end, quantity, booking_id } = routeSchema.parse(props).route.params;
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate, goBack } = useAppNavigation();
    const { reservation } = useFindReservation(booking_id);

    const handleComplete = () => {
        navigate('ReservationSelected', {
            id: booking_id,
            initiallyUnlockLock: false,
        });
    };
    const handleCheckInPress = () => {
        if (!reservation?.check_in) {
            return;
        }
        navigate('Procedure', {
            bookingId: booking_id,
            procedureId: reservation.check_in,
            product_id: reservation.booked.id + '',
        });
    };

    return (
        <SafeAreaView edges={['bottom', 'left', 'right']} style={themedStyle.full}>
            <SmallHeader onClose={goBack} title={t('transaction:bookingConfirmed')} />
            <ProductBookingDetails
                productId={product_id}
                show
                productName=""
                quantity={quantity}
                start={start}
                end={end}
            />
            {reservation?.check_in && moment.unix(start).isBefore(moment()) && !reservation.has_checked_in ? (
                <PrimaryButton
                    text={t('reservations:checkIn')}
                    onPress={handleCheckInPress}
                    style={themedStyle.backButton}
                />
            ) : (
                <SecondaryButton
                    style={themedStyle.backButton}
                    text={t('transaction:reservations')}
                    onPress={handleComplete}
                />
            )}
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        full: {
            alignItems: 'stretch',
            flex: 1,
            backgroundColor: theme.mainBackground,
            width: '100%',
        },
        backButton: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            marginTop: 'auto',
            marginBottom: screenMargin,
        },
    });

export default ReservationFinal;
