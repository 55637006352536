import React, { ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Linking, TouchableOpacity } from 'react-native';
import { Edge } from 'react-native-safe-area-context';
import { track } from '_utils/Amplitude';
import { useSelectedCoopItem } from '_utils/hooks';
import { SafeAreaView } from '../../../_dependencies/safeArea';
import Images from '../../../_images';
import { useAppNavigation } from '../../../_navigator';
import { isiOS, Theme, usePermissionStatus, useThemeStyle, WH, WW } from '../../../_utils';
import { permissions } from '../../../_utils/permission';
import { CacheImage, PrimaryButton, SecondaryButton, SimpleHeader } from '../../../Components';
import Footer from '../../../Components/Footer';
import useRegisterForNotifications from '../../../useRegisterForNotifications';
import OnboardingHeader from '../Components/OnboardingHeader';
import OnboardingTitles from '../Components/OnboardingTitles';

interface AllowPushAlertStepProps {
    route: { params?: { isPartOfOnboarding?: boolean } };
}

const AllowPushAlertsStep = ({ route }: AllowPushAlertStepProps): ReactElement => {
    const isPartOfOnboarding = route.params?.isPartOfOnboarding ?? true;
    const notificationStatus = usePermissionStatus();
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';
    const { navigate } = useAppNavigation();

    const onGoToNext = useCallback(() => {
        if (isPartOfOnboarding) {
            navigate('ProfileConfirmInfo', undefined);
        } else {
            navigate('StartScreen', undefined);
        }
    }, [isPartOfOnboarding, navigate]);

    const registerForNotifications = useRegisterForNotifications(false);

    const onSkip = useCallback(async () => {
        track('SkipPushAlerts');
        const status = await permissions.ask('notifications');
        if (status === 'granted') {
            registerForNotifications();
        }

        onGoToNext();
    }, [onGoToNext, registerForNotifications]);

    const onTurnOnNotification = useCallback(async () => {
        if (notificationStatus === 'blocked') {
            track('OpenSettings');
            Linking.openSettings();
            onGoToNext();
            return;
        }
        onSkip();
    }, [notificationStatus, onGoToNext, onSkip]);

    const subTitle =
        notificationStatus !== 'denied'
            ? t('onboarding:pleaseAllowNotification')
            : t('onboarding:notificationDesciptions', {
                  boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
              });

    const buttonTitle =
        notificationStatus !== 'denied' ? t('onboarding:changeSettings') : t('onboarding:turnOnNotification');

    useEffect(() => {
        track('PushAlertsStep', { notificationStatus });
    }, [notificationStatus]);

    return (
        <SafeAreaView
            style={themedStyle.container}
            edges={['bottom', 'left', 'right', isPartOfOnboarding ? 'top' : undefined].filter(Boolean) as Edge[]}
        >
            {isPartOfOnboarding ? <OnboardingHeader currentStep={2} /> : <SimpleHeader />}
            <OnboardingTitles title={t('onboarding:notificationHeader')} subTitle={subTitle} />
            <View style={themedStyle.main}>
                {isiOS() && notificationStatus === 'denied' ? (
                    <TouchableOpacity onPress={onTurnOnNotification}>
                        <CacheImage
                            resizeMode={'contain'}
                            style={themedStyle.notificationPopup}
                            source={Images.notificationPopupNo}
                        />
                        <CacheImage source={Images.pointing} style={themedStyle.pointing} />
                    </TouchableOpacity>
                ) : null}
            </View>
            <View>
                <SecondaryButton text={t('onboarding:skipForNow')} onPress={onSkip} style={themedStyle.skipButton} />
                <PrimaryButton text={buttonTitle} style={themedStyle.mainButton} onPress={onTurnOnNotification} />
                <Footer style={themedStyle.footer} />
            </View>
        </SafeAreaView>
    );
};
export default AllowPushAlertsStep;
const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: theme.white,
            height: WH,
        },
        main: {
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
        },
        notificationPopup: {
            alignSelf: 'center',
            justifyContent: 'center',
            maxHeight: WH * 0.3,
            height: (WW * 0.7) / 1.51,
            width: WW * 0.7,
            marginTop: WH * 0.12,
        },
        pointing: {
            width: 44,
            height: 44,
            alignSelf: 'flex-end',
            marginRight: WW * 0.1,
        },
        skipButton: { marginLeft: 16, marginBottom: 16, marginRight: 16 },
        mainButton: { marginLeft: 16, marginRight: 16 },
        footer: {
            marginTop: 16,
        },
    });
