import React, { ComponentProps, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { WH, WW } from '_utils';
import { screenMargin } from '_utils/sizes';
import { Loader, QueryView } from 'Components';
import { Message } from 'types/message';
import MessageListView from './MessageListView';
import useMarkMessagesRead from './useMarkMessagesRead';
import useSearchMessages from './useSearchMessages';

interface MessageListProps {
    rowActions?: ReactElement;
    top?: ReactElement;
    emptyText: string;
    messageTypes: Message['type'][];
    numColumns: ComponentProps<typeof QueryView>['numColumns'];
}

const MessageList = ({ top, rowActions, emptyText, messageTypes, numColumns }: MessageListProps): ReactElement => {
    const { t } = useTranslation();
    const [search, status, queryViewProps, messages, hasSearched] = useSearchMessages(messageTypes);
    const markMessages = useMarkMessagesRead();

    if (status === 'loading') {
        return <Loader />;
    }

    return (
        <MessageListView
            {...markMessages}
            {...queryViewProps}
            top={
                <>
                    {top}
                    <View
                        style={[
                            styles.buttonsContainer,
                            !numColumns || numColumns === 1 ? styles.buttonsContainerColumns : undefined,
                        ]}
                    >
                        {search}
                        {rowActions}
                    </View>
                </>
            }
            numColumns={numColumns}
            data={messages}
            isRefreshing={status === 'refetching'}
            isSearching={status === 'searching'}
            emptyText={hasSearched ? t('sharingAll:noSearchResult') : emptyText}
            showActionButton={!hasSearched}
        />
    );
};

const styles = StyleSheet.create({
    buttonsContainer: {
        gap: screenMargin,
        marginTop: WH * 0.01,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    buttonsContainerColumns: {
        marginRight: screenMargin,
        marginLeft: screenMargin,
    },
    verticalPadding: {
        paddingTop: WW * 0.02,
        paddingBottom: WW * 0.02,
    },
    containerStyleColumns: {
        paddingLeft: screenMargin,
        paddingRight: screenMargin,
    },
    columnWrapperStyle: {
        justifyContent: 'space-between',
        alignItems: 'stretch',
    },
    emptyContainer: { margin: screenMargin },
});

export default MessageList;
