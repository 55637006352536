import React, { ReactElement, useState, useMemo, useCallback, useContext } from 'react';
import moment, { MomentInput } from 'moment';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, StyleSheet, TextInput, Text } from 'react-native';
import { Activity } from '_api/useActivities';
import { useChangeSignUpForActivity, useSignUpForActivity } from '../../../../_api/useActivitySignedUp';
import _fonts from '../../../../_fonts';
import { useAppNavigation } from '../../../../_navigator';
import { WW, WH, useThemeStyle, isWeb, showToast, openURL } from '../../../../_utils';
import { parseNullableUnixTimeCodeToDate } from '../../../../_utils/misc';
import { screenMargin } from '../../../../_utils/sizes';
import { Theme, ThemeContext } from '../../../../_utils/themeContext';
import { Icon, PrimaryButton } from '../../../../Components';

interface BookButtonProps {
    activity: Activity;
    onComplete?: () => void;
}

const BookButton = ({ activity, onComplete }: BookButtonProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const { mutate: signUp, isPending: isSigningUp } = useSignUpForActivity();
    const { mutate: changeSignUp, isPending: isChangingSignup } = useChangeSignUpForActivity();

    const [ammount, setAmmount] = useState<string>(activity.approved_sum ? activity.approved_sum + '' : '1');

    const parsedAmount = parseInt(ammount, 10);
    const currAmount = isNaN(parsedAmount) ? 0 : parsedAmount;

    const cancellation = parseNullableUnixTimeCodeToDate(activity.last_cancel_at);
    const registration = parseNullableUnixTimeCodeToDate(activity.last_confirm_at);

    const isPast = (curr: MomentInput) => moment(curr).isBefore(moment());
    const activityIsEnded = isPast(moment.unix(activity.end_at));
    const activityIsStarted = isPast(moment.unix(activity.start_at));

    const canCancel = (!cancellation || !isPast(cancellation)) && !activityIsStarted && activity.approved_sum > 0;
    const registrationInFuture = (!registration || !isPast(registration)) && !activityIsEnded;

    const checkNumber = useCallback(
        (n: number) => {
            const currentSignedUp = activity.approved_sum;

            if (n < 0 || (currentSignedUp === 0 && n === 0) || isNaN(n)) {
                return 'invalid';
            }

            if (currentSignedUp === n) {
                return 'noChange';
            }

            if (n < currentSignedUp) {
                if (!canCancel) {
                    return 'cancellationTime';
                }
                if (activity.no_refund) {
                    return 'notRefunded';
                }
                return 'willBeRefunded';
            }

            if (n > currentSignedUp) {
                if (!registrationInFuture) {
                    return 'signupTime';
                }
                if (!activity.no_quantity && n - currentSignedUp > activity.quantity) {
                    if (!currentSignedUp && currAmount === 1) {
                        return 'noSpots';
                    }
                    return 'amount';
                }
                if (activity.paymentStrategy.price > 0) {
                    return 'buy';
                } else {
                    return 'signUp';
                }
            }
            throw new Error(`Unexpected state n:${n}, currentSignedUp:${currentSignedUp}`);
        },
        [
            activity.approved_sum,
            activity.no_quantity,
            activity.no_refund,
            activity.paymentStrategy.price,
            activity.quantity,
            canCancel,
            currAmount,
            registrationInFuture,
        ],
    );

    const validStatus = ['noChange', 'willBeRefunded', 'notRefunded', 'signUp', 'buy'];
    const canAddQty = validStatus.includes(checkNumber(currAmount + 1));
    const canRemoveQty = validStatus.includes(checkNumber(currAmount - 1));

    const handleAmountChange = (value: string) => {
        setAmmount(value);
    };
    const handleAmountDecrease = () => setAmmount(currAmount - 1 + '');
    const handleAmountIncrease = () => setAmmount(currAmount + 1 + '');

    const handleSignUp = async () => {
        const func = activity.approved ? changeSignUp : signUp;

        func([activity.id, currAmount], {
            onSuccess: ({ redirect, transaction_id }) => {
                if (transaction_id) {
                    navigate('Transaction', { transaction_id });
                }
                if (redirect) {
                    if (isWeb()) {
                        window.location.href = redirect;
                    } else {
                        openURL(redirect, t);
                    }
                }
                if (onComplete) {
                    onComplete();
                }
            },
            onError: () => {
                showToast({
                    header: t('activity:error'),
                    text: '',
                    type: 'error',
                });
            },
        });
    };

    const explanationMessage = useMemo(() => {
        const status = checkNumber(parsedAmount);
        const statusPlusOne = checkNumber(parsedAmount + 1);
        const statusMinus = checkNumber(parsedAmount - 1);

        if (activity.paymentStrategy.price > 0) {
            if (status === 'willBeRefunded') {
                return t('activityDetail:willRefund');
            }
            if (status === 'notRefunded') {
                return t('activityDetail:notRefunded');
            }
        }

        if (status === 'invalid') {
            return t('activityDetail:invalidNum');
        }

        if (status === 'cancellationTime') {
            return t('activityDetail:cantCancel');
        }
        if (status === 'signupTime') {
            return t('activityDetail:signUpTimePast');
        }
        if (status === 'amount') {
            return t('activityDetail:noSpotsToAdd');
        }
        if (status === 'noSpots') {
            return t('activityDetail:noSpots');
        }
        if (statusPlusOne === 'signupTime') {
            return t('activityDetail:signUpTimePast');
        }
        if (statusPlusOne === 'amount') {
            return t('activityDetail:noSpotsToAdd');
        }
        if (statusMinus === 'cancellationTime') {
            return t('activityDetail:cantCancel');
        }
    }, [activity.paymentStrategy.price, checkNumber, parsedAmount, t]);

    const buttonMessage = useMemo(() => {
        const status = checkNumber(currAmount);
        if (status === 'noChange') {
            return t('activityDetail:noChanges');
        }
        if (['willBeRefunded', 'notRefunded'].includes(status)) {
            return activity.paymentStrategy.price
                ? t('activityDetail:cancel_for', { count: activity.approved_sum - currAmount })
                : t('activityDetail:sign_off', { count: activity.approved_sum - currAmount });
        }

        return activity.paymentStrategy.price !== 0
            ? activity.approved
                ? t('activityDetail:buy_more_for', {
                      count: currAmount - activity.approved_sum,
                      totalAmount: activity.paymentStrategy.price * (currAmount - activity.approved_sum),
                  })
                : t('activityDetail:buy_for', {
                      count: currAmount - activity.approved_sum,
                      totalAmount: activity.paymentStrategy.price * (currAmount - activity.approved_sum),
                  })
            : activity.approved
              ? t('activityDetail:signup_more_for', { count: currAmount - activity.approved_sum })
              : t('activityDetail:signup_for', { count: currAmount - activity.approved_sum });
    }, [activity.approved, activity.approved_sum, activity.paymentStrategy.price, checkNumber, currAmount, t]);

    const canSignUp = (activity.no_quantity || currAmount <= activity.quantity) && currAmount !== activity.approved_sum;

    const canChangeAmount =
        (canCancel || registrationInFuture) && (activity.no_quantity || activity.quantity || activity.approved_sum);

    return (
        <>
            {explanationMessage ? <Text style={themedStyle.explainerText}>{explanationMessage}</Text> : null}
            {canChangeAmount ? (
                <View style={themedStyle.quantityWrapper}>
                    <AmmountButton onPress={handleAmountDecrease} type="minus" disabled={!canRemoveQty} />
                    <TextInput
                        onChangeText={handleAmountChange}
                        style={[themedStyle.amountText, { color: canSignUp ? theme.black : theme.secondaryLight }]}
                        value={ammount}
                        keyboardType="number-pad"
                    />
                    <AmmountButton onPress={handleAmountIncrease} type="plus" disabled={!canAddQty} />
                    <PrimaryButton
                        status={
                            isSigningUp || isChangingSignup
                                ? 'loading'
                                : (registrationInFuture && activity.approved_sum !== parsedAmount) ||
                                    (canCancel && activity.approved_sum > parsedAmount)
                                  ? null
                                  : 'disabled'
                        }
                        style={themedStyle.signUpButton}
                        text={buttonMessage.toUpperCase()}
                        onPress={handleSignUp}
                    />
                </View>
            ) : null}
        </>
    );
};

const AmmountButton = ({ onPress, type, disabled }: { onPress(): void; type: 'plus' | 'minus'; disabled: boolean }) => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    return (
        <TouchableOpacity
            activeOpacity={disabled ? 1 : 0}
            onPress={disabled ? undefined : onPress}
            style={[themedStyle.amountButton, { borderColor: disabled ? theme.shadow : theme.secondaryLight }]}
            accessibilityState={{ disabled }}
        >
            <Icon name={type} color={disabled ? 'secondaryLight' : 'black'} />
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        amountButton: {
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            paddingLeft: WW * 0.01,
            paddingRight: WW * 0.01,
            borderColor: theme.secondaryLight,
            borderRadius: 20,
            borderWidth: 1,
        },
        amountText: {
            textAlign: 'center',
            color: theme.black,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WH * 0.04,
            width: WW * 0.12,
        },
        signUpButton: { marginLeft: WW * 0.04, flex: 1 },
        quantityWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
        },
        explainerText: { textAlign: 'center', marginTop: WH * 0.02, marginBottom: WH * 0.02 },
    });

export default BookButton;
