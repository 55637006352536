import React, { ReactElement, useMemo } from 'react';
import { isValidNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { screenMargin, smallestFontSize, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { PrimaryButton, BackArrow, LanguageSelector, HeimeText } from 'Components';
import Title from './Title';
import { useAuthNavigation, useAuthRoute } from '../AuthNavigation';

const SelectNumber = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const navigation = useAuthNavigation();
    const { params } = useAuthRoute<'SelectNumber'>();

    const handleGoBack = () => {
        navigation.pop();
    };

    const matched = useMemo(() => {
        return [
            { sections: getSections(params.firstNumber, params.secondNumber), number: params.firstNumber },
            { sections: getSections(params.secondNumber, params.firstNumber), number: params.secondNumber },
        ];
    }, [params.firstNumber, params.secondNumber]);

    const getHandleSelectNumber = (number: string) => () => {
        navigation.navigate('ConfirmUser', { phone: number });
    };
    return (
        <>
            <View style={themedStyle.navWrapper}>
                <TouchableOpacity onPress={handleGoBack} style={themedStyle.navigationContainer}>
                    <BackArrow />
                    <HeimeText style={themedStyle.backText}>{t('enterPin:back')}</HeimeText>
                </TouchableOpacity>
                <LanguageSelector />
            </View>

            <Title style={themedStyle.title} text={t('selectNumber:title')} />
            <View style={themedStyle.textContainer}>
                {matched.map((strings, i) => (
                    <View key={i} style={themedStyle.numberContainer}>
                        <View style={themedStyle.sectionsContainer}>
                            {strings.sections.map((section) => (
                                <HeimeText
                                    key={section.section}
                                    style={[
                                        themedStyle.text,
                                        section.matches ? themedStyle.textMatches : themedStyle.textNotMatching,
                                    ]}
                                >
                                    {section.section}
                                </HeimeText>
                            ))}
                        </View>
                        <PrimaryButton
                            style={themedStyle.selectButton}
                            onPress={getHandleSelectNumber(strings.number)}
                            text={t('selectNumber:select')}
                        />
                    </View>
                ))}
            </View>
        </>
    );
};

// this function should return an tuple containing two arrays where that consist of sections of the phone numbers, the sections should be based on if they are matching the other number or not.
// example: ['+4733345678', '+4744445678'] should return [[{'+47', matches:true}, {'333', matches:false}, {'456', matches:true}, {"78": matches:true}], [{'+47', matches:true}, {'444', matches:false}, {'456', matches:true}, {"78": matches:true}]]
const getSections = (number: string, otherNumber: string): { section: string; matches: boolean }[] => {
    const sections: { section: string; matches: boolean }[] = [];

    let currentIndex = 0;
    if (isValidNumber(`+${number}`)) {
        const countryNum = parsePhoneNumber(`+${number}`).countryCallingCode;
        const otherCountryNum = isValidNumber(`+${otherNumber}`)
            ? parsePhoneNumber(`+${otherNumber}`).countryCallingCode
            : '';

        if (countryNum === otherCountryNum) {
            sections.push({ section: `+${countryNum}`, matches: true });
            currentIndex = countryNum.length;
        } else {
            if (otherCountryNum) {
                sections.push({ section: `+${countryNum}`, matches: false });
                otherNumber = otherNumber.slice(otherCountryNum.length);
                number = number.slice(countryNum.length);
            } else {
                const [str, index] = takeWhile(countryNum, otherCountryNum, (a, b) => a !== b);
                sections.push({ section: str, matches: false });
                currentIndex = index;
            }
        }
    }

    while (currentIndex < number.length && currentIndex < otherNumber.length) {
        const [equalResult, afterEqualIndex] = takeWhile(
            number.substring(currentIndex),
            otherNumber.substring(currentIndex),
            (a, b) => a === b,
        );

        if (equalResult) {
            sections.push({ section: equalResult, matches: true });
        }
        currentIndex += afterEqualIndex;

        if (currentIndex < number.length && currentIndex < otherNumber.length) {
            const [unequalResult, afterUnequalIndex] = takeWhile(
                number.substring(currentIndex),
                otherNumber.substring(currentIndex),
                (a, b) => a !== b,
            );
            sections.push({ section: unequalResult, matches: false });
            currentIndex += afterUnequalIndex;
        }
    }

    // Handle remaining sections of the first number
    if (currentIndex < number.length) {
        const currentSection = number.slice(currentIndex);
        sections.push({ section: currentSection, matches: false });
    }

    return sections;
};

const takeWhile = (a: string, b: string, predicate: (letterA: string, letterB: string) => boolean) => {
    let currentIndex = 0;
    let currentSection = '';

    while (
        currentIndex < a.length &&
        currentIndex < b.length &&
        predicate(a[currentIndex], b[currentIndex]) &&
        currentSection.length < 3
    ) {
        currentSection += a[currentIndex];
        currentIndex++;
    }

    return [currentSection, currentIndex] as const;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        navWrapper: { flexDirection: 'row', alignItems: 'center' },
        navigationContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        backText: {
            fontSize: 16,
            letterSpacing: 1,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: theme.main,
        },
        title: {
            paddingTop: 24,
            paddingBottom: 24,
            paddingLeft: 24,
            paddingRight: 24,
            marginBottom: 36,
            textAlign: 'left',
        },
        textContainer: {
            flexShrink: 0,
            flex: 1,
        },
        text: {
            flexShrink: 0,
            color: theme.black,
            marginHorizontal: smallestMargin,
        },
        numberContainer: {
            alignItems: 'center',
            marginBottom: screenMargin,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.lightGreen,
            padding: smallestMargin,
            margin: smallestMargin,
            flexDirection: 'row',
        },
        sectionsContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        selectButton: {
            flexGrow: 0,
            marginLeft: 'auto',
        },
        textMatches: {
            fontSize: smallestFontSize,
        },
        textNotMatching: {
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
        },
    });

export default SelectNumber;
