import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { screenMargin, subtitleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { Icon, HeimeText } from 'Components';
import ActivitySuggestionCta from './ActivitySuggestionCta';

const EmptyActivities = ({ filter }: { filter: 'future' | 'futureOnlyAttending' | 'previous' }) => {
    const { theme } = useThemeContext();
    const { t } = useTranslation();

    return (
        <View
            style={{
                width: '100%',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                gap: screenMargin,
            }}
        >
            <Icon name="calendar" color="main" scale={2} />
            <HeimeText
                style={{
                    fontSize: subtitleFontSize,
                    color: theme.black,
                    textAlign: 'center',
                }}
            >
                {t(`activities:empty_${filter}`)}
            </HeimeText>
            <View
                style={{
                    width: '100%',
                    paddingHorizontal: screenMargin,
                }}
            >
                {filter === 'future' ? <ActivitySuggestionCta /> : null}
            </View>
        </View>
    );
};

export default EmptyActivities;
