import React, { ReactElement, useState } from 'react';
import { NativeScrollEvent, NativeSyntheticEvent, ScrollView, StyleSheet, View } from 'react-native';
import { ApiImage } from 'types/Base';
import { Theme, useThemeStyle, WH, WW } from '../../../_utils';
import { CacheImage } from '../../../Components';

interface FullCarouselProps {
    images: ApiImage[];
    imageIndex: number;
    FooterComponent: (prop: { imageIndex: number }) => ReactElement;
    HeaderComponent: (prop: { imageIndex: number }) => ReactElement;
}

const FullCarousel = ({ images, imageIndex, FooterComponent, HeaderComponent }: FullCarouselProps): ReactElement => {
    const themeStyles = useThemeStyle(styles);
    const [index, setIndex] = useState(imageIndex);
    const scrollTo = (element: ScrollView) => {
        if (element) {
            element.scrollTo({ x: index * WW, y: 0, animated: false });
        }
    };

    const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
        setIndex(Math.min(Math.round(event.nativeEvent.contentOffset.x / WW), images.length - 1));
    };

    return (
        <View style={themeStyles.container}>
            <HeaderComponent imageIndex={index} />
            <ScrollView
                ref={scrollTo}
                onScroll={handleScroll}
                scrollEventThrottle={0}
                style={themeStyles.scrollContainer}
                contentContainerStyle={themeStyles.contentContainer}
                horizontal
                snapToAlignment="center"
            >
                {images.map((image, itemIndex) => (
                    <CacheImage resizeMode="contain" style={themeStyles.image} key={itemIndex} source={image} />
                ))}
            </ScrollView>
            <FooterComponent imageIndex={index} />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: { flexDirection: 'column', width: WW, height: WH, backgroundColor: theme.mainBackground },
        scrollContainer: { flexGrow: 1 },
        contentContainer: { display: 'flex', flexDirection: 'row', alignItems: 'stretch' },
        image: { width: WW, flexGrow: 1 },
    });

export default FullCarousel;
