import React, { ReactElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useOwnProfile } from '_api/useProfile';
import { Survey, useSurveys } from '_api/useSurveys';
import { useAppNavigation } from '_navigator';
import { Theme, useThemeStyle, useUpdatingFormattedTimeAgo } from '_utils';
import { useBottomSpacer } from '_utils/hooks';
import { flattenIniniteResult } from '_utils/misc';
import { smallestFontSize, smallestMargin, subtitleFontSize, titleFontSize } from '_utils/sizes';
import { EmptyMessage, HeaderWithNav, HeimeText, Icon, QueryView, ReadMore } from 'Components';
import { useSelectedCoop } from 'SelectedCoop';

const SurveysAll = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { data: surveys, fetchNextPage, isFetchingNextPage, refetch, isRefetching } = useSurveys();
    const paddingBottom = useBottomSpacer();

    return (
        <View style={themedStyle.main}>
            <HeaderWithNav title={t('surveys:title')} safeArea />
            <QueryView
                data={flattenIniniteResult(surveys)}
                renderItem={(arg) => <SurveyItem {...arg} />}
                loadMore={fetchNextPage}
                isLoadingMore={isFetchingNextPage}
                onRefresh={refetch}
                isRefreshing={isRefetching}
                emptyList={<EmptyMessage message={t('surveys:empty')} />}
                style={themedStyle.container}
                containerStyle={{ paddingBottom }}
            />
        </View>
    );
};

const SurveyItem = ({ item }: { item: Survey }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { navigate } = useAppNavigation();
    const publishedAgo = useUpdatingFormattedTimeAgo(moment.unix(item.published_at ?? 0).toDate());
    const selectedCoopId = useSelectedCoop();
    const { data: ownProfile } = useOwnProfile();
    const apartments =
        ownProfile?.cooperative_apartments?.filter(({ cooperative_id }) => cooperative_id === selectedCoopId) ?? [];

    const handlePress = () => navigate('SurveySelected', { surveyId: item.id });
    const handleReadMore = () => {
        handlePress();
        return false;
    };

    return (
        <TouchableOpacity
            onPress={handlePress}
            style={[
                themedStyle.itemContainer,
                item.status === 'ARCHIVED' ? themedStyle.itemContainerArchived : undefined,
            ]}
        >
            <View style={themedStyle.itemTextContainer}>
                <HeimeText maxFontSizeMultiplier={2} style={themedStyle.itemTitle}>
                    {item.title}
                </HeimeText>
                <ReadMore
                    numberOfLines={3}
                    onReadMorePress={handleReadMore}
                    textStyle={themedStyle.itemDescription}
                    text={item.description}
                    maxFontSizeMultiplier={2}
                />
                <HeimeText style={themedStyle.itemDate}>{publishedAgo}</HeimeText>
            </View>
            {item.responses.filter((r) => r.status === 'SENT').length > 0 ? (
                item.recipients_type === 'USERS' ||
                item.responses.filter((r) => r.status === 'SENT').length === apartments.length ? (
                    <Icon name="checkCircle" color="main" />
                ) : (
                    <HeimeText style={themedStyle.partlySubmitted}>
                        {item.responses.filter((r) => r.status === 'SENT').length} / {apartments.length}
                    </HeimeText>
                )
            ) : (
                <Icon name="chevron" color="main" />
            )}
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.lightBackground,
            width: '100%',
            flex: 1,
        },
        container: {
            backgroundColor: theme.lightBackground,
        },
        itemContainer: {
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            borderBottomColor: theme.lightGrey,
            borderBottomWidth: 1,
            paddingTop: smallestMargin,
            paddingRight: smallestMargin,
            paddingBottom: smallestMargin,
            paddingLeft: smallestMargin,
        },
        itemTextContainer: {
            flex: 1,
        },
        itemTitle: {
            fontSize: titleFontSize,
            color: theme.main,
            fontWeight: 'bold',
            marginBottom: smallestMargin,
        },
        itemDescription: {
            fontSize: subtitleFontSize,
            lineHeight: subtitleFontSize * 1.5,
            color: theme.black,
            marginBottom: smallestMargin,
        },
        itemDate: {
            fontSize: smallestFontSize,
            color: theme.darkGray,
        },
        partlySubmitted: {
            fontWeight: 'bold',
            fontSize: subtitleFontSize,
            color: theme.main,
        },
        itemContainerArchived: {
            backgroundColor: theme.secondaryLight,
        },
    });

export default SurveysAll;
