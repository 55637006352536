import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useGetApartment, useGetApartmentDocs } from '_api/useApartments';
import { useOwnProfile } from '_api/useProfile';
import Images from '_images';
import { useAppNavigation } from '_navigator';
import { Theme, useThemeStyle, WH, WW } from '_utils';
import { useSelectedCoopItem } from '_utils/hooks';
import {
    ContextMenu,
    HeaderWithNav,
    ContextTrigger,
    CacheImage,
    Tabs,
    Loader,
    QueryItemView,
    BottomSpacer,
} from 'Components';
import { ApartmentDocuments, ApartmentInfo, ApartmentTenants } from './components';
import useRefreshApartments from '../../useRefetchData';

const ApartmentSelected = ({
    apartmentId,
    onBackPress,
}: {
    apartmentId: number;
    onBackPress: () => void;
}): ReactElement | null => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const selectedCoop = useSelectedCoopItem();
    const { data: apartment, isLoading, isError, error } = useGetApartment(apartmentId);
    // Prefetching
    useGetApartmentDocs(apartmentId);
    const [isRefetching, refetch] = useRefreshApartments();
    const { data: ownProfile } = useOwnProfile();

    const { navigate } = useAppNavigation();

    const [contextMenuOpen, setContextMenuOpen] = useState(false);

    const handleContextMenuTrigger = () => setContextMenuOpen((curr) => !curr);

    if (isError) {
        throw error;
    }

    if (isLoading) {
        return <Loader />;
    }

    if (!apartment) {
        return null;
    }

    const handleEditInfoPress = () => {
        handleContextMenuTrigger();
        navigate('HomeEdit', { apartment });
    };

    const isOwner = Boolean(
        apartment.tenants.find(({ id, tenant_role: role }) => id === ownProfile?.id && role === 'owner'),
    );

    return (
        <>
            <HeaderWithNav
                onPress={onBackPress}
                style={themedStyle.navHeader}
                title={t('myHome:title')}
                action={
                    isOwner ? (
                        <ContextMenu
                            isOpen={contextMenuOpen}
                            onToggle={handleContextMenuTrigger}
                            actions={[
                                {
                                    text: t('myHome:editInfo'),
                                    onPress: handleEditInfoPress,
                                    type: 'normal',
                                },
                                {
                                    text: t('myHome:addTenant'),
                                    onPress: () => {
                                        handleContextMenuTrigger();
                                        navigate('TenantEdit', {
                                            apartmentId: apartment.id,
                                            userId: null,
                                        });
                                    },
                                    type: 'normal',
                                },
                            ]}
                            trigger={<ContextTrigger variant="light" />}
                        />
                    ) : undefined
                }
                safeArea
            />
            <QueryItemView
                keyboardShouldPersistTaps="handled"
                virtualized
                onRefresh={refetch}
                isRefreshing={isRefetching}
                style={themedStyle.main}
            >
                <CacheImage
                    resizeMode="contain"
                    style={themedStyle.image}
                    source={apartment.picture ?? selectedCoop?.default_apartment_image ?? Images.no_result}
                />
                <Tabs
                    tabs={[
                        {
                            title: t('myHome:info'),
                            content: <ApartmentInfo apartment={apartment} />,
                        },
                        {
                            title: t('myHome:documents'),
                            content: <ApartmentDocuments aptId={apartment.id} />,
                        },
                        {
                            title: t('myHome:tenants'),
                            content: <ApartmentTenants apartment={apartment} />,
                        },
                    ]}
                    style={themedStyle.tabs}
                />
                <BottomSpacer />
            </QueryItemView>
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        navHeader: {
            backgroundColor: theme.mainBackground,
        },
        main: {
            backgroundColor: theme.mainBackground,
            flex: 1,
        },
        image: {
            alignSelf: 'center',
            width: WW * 0.92,
            height: WH * 0.3,
        },
        tabs: {
            flex: 1,
        },
    });

export default ApartmentSelected;
