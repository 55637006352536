import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { useCreateChat } from '../_api/useChats';
import { SafeAreaView } from '../_dependencies/safeArea';
import { useAppNavigation } from '../_navigator';
import { Theme, useConfirmDiscard, useThemeStyle } from '../_utils';
import { screenMargin } from '../_utils/sizes';
import { PrimaryButton } from '../Components';
import ControlledInput from '../Components/ControlledInput';

interface ChatNewProps {
    route: { params: { members: number[] } };
}

const ChatNew = ({ route }: ChatNewProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const [chatName, setChatName] = useState('');
    const { mutate: createChat, isPending: isLoading } = useCreateChat();
    const { navigate, goBack: internalGoBack } = useAppNavigation();
    const handleChangeChatName = (newStr: string | undefined) => setChatName(newStr ?? '');
    const navigation = useNavigation();

    const { goBack, content, openModal } = useConfirmDiscard(Boolean(chatName) && !isLoading, false, internalGoBack);
    useEffect(() => {
        const unsubscribe = navigation.addListener('beforeRemove', (e) => {
            if (e.data.action.type === 'POP' && e.data.action.target && e.data.action.target !== e.target) {
                e.preventDefault();
                openModal();
            } else if (e.data.action.source?.startsWith('chatNewStack')) {
                // WE WANT TO GO BACK IN THIS STACK, not the one above us
                e.preventDefault();
                goBack();
            }
        });
        return () => unsubscribe();
    }, [goBack, navigation, openModal]);

    const handleCreateChat = () => {
        createChat(
            { users: route.params.members, name: chatName },
            {
                onSuccess: ({ success }) => {
                    navigate('ChatsAll', { showBackHeader: 'false' });
                    navigate('ChatSelected', { chatId: `${success}` });
                },
            },
        );
    };

    return (
        <SafeAreaView edges={['left', 'right', 'bottom']} style={themedStyle.safeArea}>
            <View style={themedStyle.container}>
                <ControlledInput
                    value={chatName}
                    onChange={handleChangeChatName}
                    label="Navn på chat"
                    optional
                    autoFocus
                />
            </View>
            <PrimaryButton
                status={isLoading ? 'loading' : chatName && chatName.length < 3 ? 'disabled' : null}
                bottomAction="modal"
                text={t('chatAll:createChat').toUpperCase()}
                onPress={handleCreateChat}
            />
            {content}
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            display: 'flex',
            flex: 1,
            backgroundColor: theme.mainBackground,
            marginTop: screenMargin,
            marginBottom: screenMargin,
            marginLeft: screenMargin,
            marginRight: screenMargin,
        },
        safeArea: { backgroundColor: theme.mainBackground, flex: 1 },
    });

export default ChatNew;
