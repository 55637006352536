import { useMemo } from 'react';
import { useUserData } from '_api/useUsers';

const useGetExampleNeighbors = (type: 'about_me' | 'avatar' | 'either') => {
    const data = useUserData();

    return useMemo(
        () =>
            data
                .filter((item) => {
                    switch (type) {
                        case 'about_me':
                            return item.about_me;
                        case 'avatar':
                            return item.avatar;
                        case 'either':
                            return item.about_me || item.avatar;
                    }
                })
                .sort((a, b) => {
                    let res = 0;
                    if (type !== 'avatar') {
                        res = (b.avatar ? 200 : 0) + b.about_me.length - ((a.avatar ? 200 : 0) + a.about_me.length);
                    }

                    if (res === 0) {
                        return b.id - a.id;
                    }
                    return res;
                }),
        [data, type],
    );
};

export default useGetExampleNeighbors;
