import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import { UnixTimeCode, Uuid } from '../types/Utility';

export type Transaction = BaseTransaction & (TransactionBooking | TransactionActivity);

interface BaseTransaction {
    id: Uuid;
    status: 'requested' | 'canceled' | 'paid' | 'aborted';
    redirect: string;
}

interface TransactionBooking {
    type: 'booking';
    product: {
        id: number;
        product_id: number;
        name: string;
        from: UnixTimeCode;
        to: UnixTimeCode;
        quantity: number;
    };
}

interface TransactionActivity {
    type: 'activity';
    product: {
        id: number;
        activity_id: number;
        name: string;
        quantity: number;
        start: UnixTimeCode;
    };
}

const useGetTransaction = (transactionId: string): UseQueryResult<Transaction, string | Error> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();

    return useQuery({
        queryKey: ['transaction', transactionId],

        queryFn: async () => {
            const result = await axios.get<Transaction>(
                `cooperatives/${selectedCoopId}/transactions/${transactionId}`,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );
            if (!result.data) {
                throw new Error('Result returned with no data');
            }
            return result.data;
        },

        gcTime: 1000 * 60 * 60,
        staleTime: 1000 * 60,
    });
};

export { useGetTransaction };
