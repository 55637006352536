import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useSelectedCoopItem } from '_utils/hooks';
import { MessageType, Message, isNoticeMessage } from 'types/message';
import { isNonDeletedUser } from 'types/User';
import ContactAlertModal from './components/ContactModal';
import DeleteSharingModal from './components/DeleteSharingModal';
import HelperProfile from './components/HelperProfile/HelperProfile';
import ProductDetail from './components/ProductDetail';
import RequestDetail from './components/RequestDetail/RequestDetail';
import SendStar from './components/SendStar';
import { useDeleteMessage, useGetMessage, useProlongMessage } from '../../../_api/useMessages';
import { useOwnProfile } from '../../../_api/useProfile';
import { useGetUser, useGetUsers } from '../../../_api/useUsers';
import { useAppNavigation } from '../../../_navigator';
import {
    isAppError,
    isTruthy,
    showToast,
    Theme,
    useCalculateMessageRead,
    useIsCoopAdmin,
    useThemeStyle,
    WH,
    WW,
} from '../../../_utils';
import useUserApartments from '../../../_utils/hooks/useUserApartments';
import { captureException } from '../../../_utils/Sentry';
import { screenMargin } from '../../../_utils/sizes';
import { ContextMenu, HeaderWithNav, Icon, Loader, OpinionatedSafeArea, PrimaryButton } from '../../../Components';
import NotFoundErrorScreen from '../../../Components/NotFoundErrorScreen';
import useReadMessage from '../useReadMessage';

const SharingLoadedGate = ({
    route,
}: {
    route: { params: { messageId: number; scrollToBottom?: 'true' | 'false' } };
}): ReactElement => {
    const messageId = route.params.messageId;
    const { data, isLoading, isError, error } = useGetMessage(messageId);

    //To preload users
    useGetUsers(false);

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        if (isAppError(error) && error.response?.status === 404) {
            return <NotFoundErrorScreen type="Message" />;
        } else {
            throw error;
        }
    }

    if (!data) {
        return <NotFoundErrorScreen type="Message" />;
    }

    return (
        <OpinionatedSafeArea>
            <SharingSelected message={data} scrollToBottom={route.params.scrollToBottom === 'true'} />
        </OpinionatedSafeArea>
    );
};

const SharingSelected = ({ message, scrollToBottom }: { message: Message; scrollToBottom: boolean }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';
    const { navigate, goBack } = useAppNavigation();
    const user = useGetUser(message.creator_id);
    const ownId = useOwnProfile().data?.id ?? 0;
    const isRead = useCalculateMessageRead();
    const isAdmin = useIsCoopAdmin();

    const [modalOpen, setModalOpen] = useState<'moreMenu' | 'delete' | 'contact' | false>(false);

    const { mutate: deleteMessage, isPending: isDeleting } = useDeleteMessage();
    const { mutate: changeMessageActive, isPending: isUpdatingMessageActive } = useProlongMessage();

    const markRead = useReadMessage();

    const getApartments = useUserApartments();

    const handleMoreToggle = () => setModalOpen('moreMenu');
    const handleDeleteClick = () => setModalOpen('delete');
    const handleContactModalOpen = () => setModalOpen('contact');
    const handleModalDismiss = () => setModalOpen(false);

    const handleNavigateToReport = () => navigate('ReportNew', {});

    const apartments = useMemo(() => {
        return getApartments(user)
            .map(({ name }) => name)
            .join(',');
    }, [getApartments, user]);

    const editMessage = () => {
        handleModalDismiss();
        navigate('SharingNew', {
            message,
            type: message.type,
        });
    };

    const renderMessageDetail = () => {
        switch (message.type) {
            case MessageType.Products:
                return <ProductDetail onEdit={editMessage} message={message} apartments={apartments} />;
            case MessageType.Helpers:
                return (
                    <HelperProfile
                        profile={isNonDeletedUser(user) ? user : null}
                        message={message}
                        apartments={apartments}
                    />
                );
            case MessageType.ByBoard:
            case MessageType.Requests:
                return <RequestDetail message={message} apartments={apartments} scrollToBottom={scrollToBottom} />;
        }
    };

    const handleDeleteConfirmClick = () => {
        deleteMessage(message.id, {
            onSuccess: () => {
                setModalOpen(false);
                goBack();
            },
            onError: (e) => {
                captureException(e);
                showToast({
                    header: t('sharingSelected:error'),
                    text: '',
                    type: 'error',
                });
            },
        });
    };

    useFocusEffect(
        useCallback(() => {
            markRead([message.id], isRead(message));
        }, [isRead, markRead, message]),
    );

    const prolongItem = isNoticeMessage(message)
        ? message.active_until && moment(message.active_until).isBefore(moment().add({ days: 3 }))
            ? {
                  loading: isUpdatingMessageActive,
                  text: moment(message.active_until).isAfter(moment())
                      ? t('sharingSelected:prolong')
                      : t('sharingSelected:reactivate'),
                  onPress: () =>
                      changeMessageActive([message.id, moment().add(1, 'month').toISOString()], {
                          onSuccess: () =>
                              showToast({
                                  header: moment(message.active_until).isAfter(moment())
                                      ? t('notifications:messageExpired:prolonged')
                                      : t('notifications:messageExpired:reactivated'),
                                  text: t('notifications:messageExpired:month_from_now'),
                                  type: 'success',
                              }),
                          onError: () =>
                              showToast({
                                  header: t('notifications:messageExpired:prolong_error'),
                                  text: '',
                                  type: 'error',
                              }),
                          onSettled: () => handleModalDismiss(),
                      }),
                  type: 'normal' as const,
              }
            : {
                  loading: isUpdatingMessageActive,
                  text: t('sharingSelected:deactivate'),
                  onPress: () =>
                      changeMessageActive([message.id, moment().toISOString()], {
                          onSuccess: () =>
                              showToast({
                                  header: t('sharingSelected:deactivated'),
                                  text: t('sharingSelected:deactivated_description'),
                                  type: 'success',
                                  visibilityTime: 5000,
                              }),
                          onError: () =>
                              showToast({
                                  header: t('sharingSelected:deactivated_failed'),
                                  text: '',
                                  type: 'error',
                              }),
                          onSettled: () => handleModalDismiss(),
                      }),
                  type: 'normal' as const,
              }
        : null;
    const isOwn = message.creator_id === ownId;

    return (
        <View style={themedStyle.full}>
            <HeaderWithNav
                action={
                    isOwn || (isAdmin && prolongItem) ? (
                        <ContextMenu
                            actions={
                                isOwn
                                    ? [
                                          {
                                              text: t('sharingSelected:editItem'),
                                              onPress: editMessage,
                                              type: 'normal' as const,
                                          },
                                          prolongItem,
                                          {
                                              loading: isDeleting,
                                              text: t('sharingSelected:deleteItem'),
                                              onPress: handleDeleteClick,
                                              type: 'danger' as const,
                                          },
                                      ].filter(isTruthy)
                                    : [prolongItem].filter(isTruthy)
                            }
                            onToggle={modalOpen === 'moreMenu' ? handleModalDismiss : handleMoreToggle}
                            isOpen={modalOpen === 'moreMenu'}
                            trigger={
                                <View style={themedStyle.moreButton}>
                                    <Icon name={'moreFilled'} color="black" />
                                </View>
                            }
                        />
                    ) : undefined
                }
                title={
                    message.type === MessageType.ByBoard
                        ? t('sharingSelected:messageType:ByBoard', {
                              boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                          })
                        : message.type === MessageType.Requests
                          ? t('sharingSelected:messageType:Requests')
                          : message.type === MessageType.Products
                            ? t('sharingSelected:messageType:Products')
                            : t('sharingSelected:messageType:Helpers')
                }
            />
            <View style={themedStyle.full}>{renderMessageDetail()}</View>
            {message.type === MessageType.Helpers && message.creator_id !== ownId ? (
                <SendStar creator={message.creator_id} />
            ) : null}
            {(isNoticeMessage(message) && message.allow_comments) ||
            message.creator_id === ownId ? null : message.type === MessageType.ByBoard ? (
                <PrimaryButton
                    style={themedStyle.buttonStyle}
                    onPress={handleNavigateToReport}
                    text={t('sharingSelected:messageTheBoard', {
                        boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                    })}
                    bottomAction
                />
            ) : (
                <PrimaryButton
                    style={themedStyle.buttonStyle}
                    onPress={handleContactModalOpen}
                    bottomAction="modal"
                    text={
                        message.type === MessageType.Products
                            ? t('sharingSelected:productDetails:button').toUpperCase()
                            : message.type === MessageType.Requests
                              ? t('sharingSelected:requestDetails:button').toUpperCase()
                              : t('sharingSelected:helperProfile:button', {
                                    name: user?.fname ?? '',
                                }).toUpperCase()
                    }
                />
            )}
            {modalOpen === 'contact' && (
                <ContactAlertModal
                    header={
                        message.type === MessageType.Products
                            ? t('sharingSelected:contactNeighbor:title')
                            : message.type === MessageType.Requests
                              ? t('sharingSelected:requestDetails:titleModal')
                              : t('sharingSelected:helperProfile:button', {
                                    name: user?.fname ?? '',
                                })
                    }
                    messageId={message.id}
                    userId={message.creator_id}
                    onDismiss={handleModalDismiss}
                />
            )}
            {modalOpen === 'delete' ? (
                <DeleteSharingModal onDismiss={handleModalDismiss} onDelete={handleDeleteConfirmClick} />
            ) : null}
            {modalOpen === 'contact' && (
                <ContactAlertModal
                    header={
                        message.type === MessageType.Products
                            ? t('sharingSelected:contactNeighbor:title')
                            : t('sharingSelected:helperProfile:button', {
                                  name: user?.fname ?? '',
                              })
                    }
                    messageId={message.id}
                    userId={message.creator_id}
                    onDismiss={handleModalDismiss}
                />
            )}
            {modalOpen === 'delete' ? (
                <DeleteSharingModal onDismiss={handleModalDismiss} onDelete={handleDeleteConfirmClick} />
            ) : null}
        </View>
    );
};
const styles = (theme: Theme) =>
    StyleSheet.create({
        full: {
            backgroundColor: theme.mainBackground,
            flex: 1,
        },
        moreButton: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            paddingTop: screenMargin / 2,
            paddingBottom: screenMargin / 2,
            minHeight: 40,
        },
        buttonStyle: {
            marginTop: WH * 0.02,
        },
        padding: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
        },
    });
export default SharingLoadedGate;
