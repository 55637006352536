import React, { ReactElement, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import calculateShortTermAvailableSlots, { Slot } from './calculateShortTermAvailableSlots';
import { SelectorChip, DateWithSlots } from '../../../../Components';
import { Reservation } from '../../../../types/Reservation';

interface ShortTermActiveBookingProps {
    day: Date;
    bookings: Reservation[];
    onDateSelect(): void;
    capacity: number;
}

const ShortTermActiveBookingItem = ({
    day,
    bookings,
    onDateSelect: handleDateSelect,
    capacity,
}: ShortTermActiveBookingProps): ReactElement => {
    const { t } = useTranslation();
    const date = useMemo(() => moment(day), [day]);

    const availability = useMemo(
        () => calculateShortTermAvailableSlots(bookings, capacity, date.toDate()),
        [bookings, date, capacity],
    );
    const handlePress = () => handleDateSelect();

    const hasCapacity = capacity > 1;
    // Remove slots that are less than 5 minutes
    const filteredSlots = availability.type === 'some' ? availability.slots : [];
    const minCapacity =
        availability.type === 'all'
            ? capacity
            : availability.type === 'none'
              ? 0
              : getMinCapacity(availability.slots, date.toDate());
    const maxCapacity =
        availability.type !== 'some'
            ? minCapacity
            : filteredSlots.reduce((min, { available_quantity }) => Math.max(min, available_quantity), -Infinity);

    return (
        <DateWithSlots
            disabled={availability.type === 'none'}
            date={date}
            title={
                availability.type === 'all'
                    ? t('serviceExpanded:availableAllDay')
                    : availability.type === 'some'
                      ? hasCapacity
                          ? minCapacity === maxCapacity
                              ? t('serviceExpanded:availableSomeCapacitySingle', { count: minCapacity })
                              : t('serviceExpanded:availableSomeCapacityMultiple', {
                                    min: minCapacity,
                                    max: maxCapacity,
                                })
                          : t('serviceExpanded:availableSome')
                      : t('serviceExpanded:notAvailable')
            }
            onPress={handlePress}
            slots={filteredSlots.map(({ start, end, available_quantity }) => {
                const isNow = moment().isSameOrAfter(moment.unix(start));
                const title = `${hasCapacity ? t('serviceExpanded:n_available', { count: available_quantity }) : ''}${
                    isNow ? t('serviceExpanded:timeNow') : moment.unix(start).add(1, 'seconds').format('HH:mm')
                } - ${moment.unix(end).add(1, 'seconds').format('HH:mm')}`;
                return (
                    <SelectorChip
                        key={start}
                        variant={isNow ? 'lightPrimary' : 'WhitePrimary'}
                        title={title}
                        onSelect={handlePress}
                    />
                );
            })}
        />
    );
};

const getMinCapacity = (slots: Slot[], date: Date) => {
    // First we check if there is a spot where there is not a defined slot
    let currentTime = moment(date).startOf('day').unix();
    let maxTimeWithoutSlot = 0;
    slots.forEach(({ start, end }) => {
        const diff = start - currentTime;
        if (diff > maxTimeWithoutSlot) {
            maxTimeWithoutSlot = diff;
        }
        currentTime = end;
    });
    maxTimeWithoutSlot = Math.max(maxTimeWithoutSlot, moment(date).endOf('day').unix() - currentTime);
    // IF there is missing an availability slot for more than 5 minutes we return 0
    if (maxTimeWithoutSlot > 5 * 60) {
        return 0;
    }
    return slots.reduce((min, { available_quantity }) => Math.min(min, available_quantity), Infinity);
};

export default ShortTermActiveBookingItem;
