import React, { ComponentProps, ReactElement, useContext } from 'react';
import { ActivityIndicator, StyleProp, StyleSheet, Text, TouchableHighlight, ViewStyle } from 'react-native';
import Icon from './Icon/Icon';
import _fonts from '../_fonts';
import { Theme, useThemeStyle, WH, WW } from '../_utils';
import { ThemeContext } from '../_utils/themeContext';

interface SecondaryButtonProps {
    status?: 'disabled' | 'loading' | null;
    onPress(): void;
    text: ReactElement | string;
    iconStyle?: StyleProp<ViewStyle>;
    style?: StyleProp<ViewStyle>;
    icon?: ComponentProps<typeof Icon>['name'];
    variant?: 'default' | 'danger';
}

const SecondaryButton = ({
    status,
    onPress,
    text,
    style,
    icon,
    iconStyle,
    variant,
}: SecondaryButtonProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const disabled = status === 'disabled';
    const isLoading = status === 'loading';
    return (
        <TouchableHighlight
            disabled={disabled}
            style={[
                themedStyle.main,
                style,
                disabled && themedStyle.disabled,
                status === 'loading' && themedStyle.loading,
                {
                    borderColor: disabled ? theme.secondaryLight : variant === 'danger' ? theme.red : theme.main,
                    borderStyle: 'solid',
                    borderTopWidth: 1,
                    borderBottomWidth: 1,
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                },
            ]}
            onPress={onPress}
            underlayColor={theme.main}
        >
            {status === 'loading' ? (
                <ActivityIndicator color={'#FFF'} />
            ) : (
                <>
                    {icon ? (
                        <Icon
                            name={icon}
                            style={[themedStyle.icon, iconStyle]}
                            color={disabled ? 'secondaryLight' : variant === 'danger' ? 'red' : 'main'}
                        />
                    ) : null}
                    <Text
                        style={[
                            themedStyle.text,
                            variant === 'danger' ? { color: theme.red } : undefined,
                            (disabled || isLoading) && themedStyle.textDisabled,
                        ]}
                    >
                        {text}
                    </Text>
                </>
            )}
        </TouchableHighlight>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            paddingRight: WH * 0.015 - 2,
            paddingLeft: WH * 0.015 - 2,
            paddingTop: WH * 0.015 - 2,
            paddingBottom: WH * 0.015 - 2,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        disabled: {
            backgroundColor: theme.shadow,
            borderWidth: 0,
        },
        loading: {
            backgroundColor: theme.secondaryText,
        },
        text: {
            color: theme.main,
            textAlign: 'center',
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.05,
        },
        textDisabled: {
            color: theme.secondaryLight,
        },
        icon: {
            marginRight: WH * 0.015,
        },
    });

export default SecondaryButton;
