import React, { ReactElement } from 'react';
import { ViewStyle } from 'react-native';
import { useStaticSettings } from '_api/useSettings';
import StaticFooter from './StaticFooter';
import { useSettings } from '../../_api';

interface FooterProps {
    style?: ViewStyle;
}
const Footer = ({ style }: FooterProps): ReactElement => {
    const { data } = useSettings();
    const staticSettings = useStaticSettings();
    const phone = data?.phone ? data?.phone : staticSettings.phone;
    const email = data?.mail ? data?.mail : staticSettings.mail;

    return <StaticFooter style={style} phone={phone} email={email} />;
};

export default Footer;
