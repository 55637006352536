import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { Member } from '../../../_api/useContactGroup';
import Images from '../../../_images';
import { Theme, useThemeStyle, WW } from '../../../_utils';
import { CacheImage } from '../../../Components';

interface ExternalUserAvatarProps {
    user: Member;
}

const ExternalUserAvatar = ({ user }: ExternalUserAvatarProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View style={themedStyle.avatarWrapper}>
            <CacheImage style={themedStyle.avatar} source={user.avatar ?? Images.defaultAvatar} />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        avatarWrapper: {
            borderRadius: (WW * 0.25) / 2,
            width: WW * 0.25,
            height: WW * 0.25,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: theme.lightGreen,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
        },
        avatar: {
            width: WW * 0.25,
            height: WW * 0.25,
            borderRadius: (WW * 0.25) / 2,
        },
    });

export default ExternalUserAvatar;
