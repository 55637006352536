import { useMemo, useState } from 'react';
import { InfiniteData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useUserApartments from './useUserApartments';
import { useOwnProfile } from '../../_api/useProfile';
import { ExternalUserProfile } from '../../types/User';
import { flattenIniniteResult, getUsernameFromProfile } from '../misc';

const useNeighbourSearch = (
    data: InfiniteData<ExternalUserProfile[]> | undefined,
    filter: 'alphabetic' | 'apartment',
    includeSelf: boolean,
): {
    filteredData: { title: string; data: (ExternalUserProfile & { name: string })[] }[];
    handleSearchTextChange(newStr: string): void;
    searchString: string;
} => {
    const { t } = useTranslation();
    const { data: ownProfile } = useOwnProfile();
    const [searchedText, setSearchedText] = useState('');
    const getApartments = useUserApartments();

    const neighborArray = useMemo(() => {
        const users = flattenIniniteResult(data)
            .filter(({ id }) => includeSelf || id !== ownProfile?.id)
            .map((u) => {
                return {
                    ...u,
                    name: getUsernameFromProfile(u),
                    cooperative_apartments: getApartments(u),
                };
            });

        const letterSet = Array.from(
            users.reduce((curr, item) => {
                if (filter === 'alphabetic') {
                    if (item.name) {
                        curr.add(item.name[0].toLocaleUpperCase());
                    } else {
                        curr.add(' ');
                    }
                } else {
                    item.cooperative_apartments.forEach((ap) => curr.add(ap.name));
                }
                return curr;
            }, new Set<string>()),
        );

        if (filter === 'alphabetic') {
            return letterSet.sort().map((ltr) => ({
                data: users
                    .filter((item) => item.name.charAt(0).toLocaleUpperCase() === ltr)
                    .sort((a, b) => (a.name < b.name ? -1 : a.name === b.name ? 0 : 1)),
                title: ltr,
            }));
        } else {
            return [...letterSet, '']
                .sort((a, b) => {
                    // We sort numerical then alphabetical, but undefined values last
                    if (a === b) {
                        return 0;
                    }
                    if (!a) {
                        return 1;
                    }
                    if (!b) {
                        return -1;
                    }

                    if (!Number.isNaN(+a)) {
                        if (!Number.isNaN(+b)) {
                            return +a - +b;
                        } else {
                            return -1;
                        }
                    } else if (!Number.isNaN(+b)) {
                        return 1;
                    }
                    return a.localeCompare(b);
                })
                .map((appCode) => ({
                    data: users.filter((user) =>
                        appCode
                            ? (user?.cooperative_apartments ?? []).find(
                                  ({ name }) => name.toLocaleUpperCase() === appCode.toLocaleUpperCase(),
                              ) !== undefined
                            : (user?.cooperative_apartments ?? []).length === 0,
                    ),
                    title: appCode ? appCode : t('neighbours:noApartment'),
                }));
        }
    }, [data, filter, getApartments, includeSelf, ownProfile?.id, t]);

    const filteredData = useMemo(() => {
        if (searchedText !== '') {
            return neighborArray
                .map(({ title, data: items }) => ({
                    title,
                    data: title.toLowerCase().includes(searchedText.toLowerCase())
                        ? items
                        : items.filter((user) => {
                              if (user.name.toLowerCase().includes(searchedText.toLowerCase())) {
                                  return true;
                              }
                              if (
                                  getApartments(user).some(
                                      (ap) =>
                                          ap.name.toLowerCase().includes(searchedText.toLowerCase()) ||
                                          ap.parking_spot?.toLowerCase().includes(searchedText.toLowerCase()),
                                  )
                              ) {
                                  return true;
                              }
                              return false;
                          }),
                }))
                .filter(({ data: items }) => items.length > 0);
        }
        return neighborArray;
    }, [getApartments, neighborArray, searchedText]);

    return useMemo(
        () => ({ filteredData, handleSearchTextChange: setSearchedText, searchString: searchedText }),
        [filteredData, searchedText],
    );
};

export default useNeighbourSearch;
