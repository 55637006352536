import { useCallback } from 'react';
import { usePatchDevice, useStoreDevice } from '_api/useDeviceToken';
import { setDevice } from '_redux/deviceToken';
import useAppDispatch from './hooks/useAppDispatch';
import useGlobalState from './hooks/useGlobalState';
import { isiOS } from './isPlatform';

const useDeviceTokenHandling = () => {
    const { mutate: storeDevice } = useStoreDevice();
    const { mutate: patchDevice } = usePatchDevice();
    const device = useGlobalState((state) => state.deviceToken);
    const dispatch = useAppDispatch();

    return useCallback(
        (arg: { device_token: string; locale: string }) => {
            const body = { ...arg, device_type: isiOS() ? ('ios' as const) : ('android' as const) };
            const onSuccess = (payload: Parameters<typeof setDevice>[0]) => {
                dispatch(setDevice(payload));
            };
            if (device.device_id) {
                if (device.device_token === body.device_token && device.locale === body.locale) {
                    return;
                }
                patchDevice([device.device_id, body], {
                    onSuccess: (data, variables) =>
                        onSuccess({
                            device_id: variables[0],
                            device_token: variables[1].device_token,
                            locale: variables[1].locale,
                        }),
                });
            } else {
                storeDevice(body, {
                    onSuccess: (data, variables) =>
                        onSuccess({
                            device_id: data.device_id,
                            device_token: variables.device_token,
                            locale: variables.locale,
                        }),
                });
            }
        },
        [device.device_id, device.device_token, device.locale, dispatch, patchDevice, storeDevice],
    );
};

export default useDeviceTokenHandling;
