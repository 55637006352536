import React, { ReactElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { useGetProduct } from '../../../_api/useCategories';
import { useThemeStyle, getRelatedDateString, WW, Theme } from '../../../_utils';
import { subtitleFontSize, screenMargin } from '../../../_utils/sizes';
import { HeimeText, FullWidthPictureCarousel, SelectorChip, SecondaryButton } from '../../../Components';
import { UnixTimeCode } from '../../../types/Utility';

interface ProductBookingDetailsProps {
    productId: number;
    show: boolean;
    productName: string;
    start: UnixTimeCode;
    end: UnixTimeCode;
    quantity: number;
    onNavigateToReservations?: () => void;
}

const ProductBookingDetails = ({
    productId,
    show,
    productName,
    start,
    end,
    quantity,
    onNavigateToReservations,
}: ProductBookingDetailsProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { data } = useGetProduct(productId);

    if (!show) {
        return <></>;
    }

    const startMoment = moment.unix(start);
    const [startString, endString] = getRelatedDateString(startMoment.toDate(), moment.unix(end).toDate());

    return (
        <>
            <ScrollView bounces={false}>
                <HeimeText style={themedStyle.infoTxt}>
                    <Text style={themedStyle.emphasis}>{data?.name ?? productName}</Text>
                    {t('transaction:has_been_reservedPost')}
                </HeimeText>
                {data?.pictures?.length ? <FullWidthPictureCarousel pictures={data?.pictures} /> : null}
                {data?.locks?.length ? (
                    <HeimeText style={themedStyle.infoTxt}>{t('transaction:explainLocks')}</HeimeText>
                ) : null}
                <View style={themedStyle.chipContainer}>
                    <SelectorChip title={startString} variant="WhitePrimary" containerStyle={themedStyle.chip} />
                    <SelectorChip title={endString} variant="WhitePrimary" containerStyle={themedStyle.chip} />
                    {quantity > 1 ? (
                        <SelectorChip
                            title={t('serviceExpanded:quantity', { quantity })}
                            variant="WhitePrimary"
                            containerStyle={themedStyle.chip}
                        />
                    ) : null}
                    <SelectorChip
                        title={
                            startMoment.isBefore(moment())
                                ? t('transaction:started')
                                : t('transaction:timeUntilStart', {
                                      formattedDuration: moment
                                          .duration(Math.abs(startMoment.diff(moment())))
                                          .humanize(),
                                  })
                        }
                        variant="PrimaryWhite"
                        containerStyle={themedStyle.chip}
                    />
                </View>
            </ScrollView>
            {onNavigateToReservations ? (
                <SecondaryButton
                    style={themedStyle.backButton}
                    text={t('transaction:reservations')}
                    onPress={onNavigateToReservations}
                />
            ) : null}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        explainer: {
            fontSize: subtitleFontSize,
            marginRight: screenMargin,
            marginLeft: screenMargin,
            textAlign: 'center',
        },
        emphasis: {
            fontWeight: 'bold',
            color: theme.black,
        },
        infoTxt: {
            fontSize: subtitleFontSize,
            color: theme.secondaryText,
            textAlign: 'center',
            marginTop: WW * 0.06,
            marginBottom: WW * 0.06,
            lineHeight: WW * 0.06,
        },
        chipContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: WW * 0.02,
        },
        chip: {
            marginLeft: 4,
            marginRight: 4,
            marginTop: 4,
            marginBottom: 4,
        },
        backButton: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            marginTop: 'auto',
            marginBottom: screenMargin,
        },
    });

export default ProductBookingDetails;
