import React, { ComponentProps, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TextInput } from 'react-native';
import { NoticeMessage } from 'types/message';
import { ApiImageObjectToStaleFile, ArrayElement } from 'types/Utility';
import { isWeb, WW } from '../../../../../../_utils';
import { screenMargin } from '../../../../../../_utils/sizes';
import { CommentList, MessageInput, QueryItemView } from '../../../../../../Components';

type CommentsWithInputProps = ComponentProps<typeof QueryItemView> & {
    onSend: ComponentProps<typeof MessageInput>['onSend'];
    comments: ComponentProps<typeof CommentList>['comments'];
    scrollToBottom?: boolean;
    showComments: boolean;
    messageId: number;
    editing: ArrayElement<NoticeMessage['comments']> | null;
    setEditing(comment: ArrayElement<NoticeMessage['comments']> | null): void;
};

const CommentsWithInput = ({
    onSend,
    comments,
    scrollToBottom,
    showComments,
    children,
    messageId,
    editing,
    setEditing,
    ...props
}: CommentsWithInputProps): ReactElement => {
    const [isFocused, setIsFocused] = useState(false);
    const [state, setState] = useState<ComponentProps<typeof MessageInput>['state']>({ text: '', attachments: [] });
    const [isActionSheetOpen, setActionSheetOpen] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (editing) {
            setState({ text: editing.content ?? '', attachments: editing.files.map(ApiImageObjectToStaleFile) });
        }
    }, [editing]);

    const scrollRef = useRef<{ scrollToEnd(): void }>();
    const inputRef = useRef<TextInput>(null);
    const scrollToEnd = () => {
        scrollRef.current?.scrollToEnd();
        setTimeout(() => {
            scrollRef.current?.scrollToEnd();
        }, 50);
    };

    const handleMessageInputBlur = () => {
        const func = () => {
            setIsFocused(false);
            scrollToEnd();
        };
        if (isWeb()) {
            setTimeout(func, 200);
        } else {
            func();
        }
    };

    useEffect(() => {
        if (isFocused) {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    }, [isFocused]);

    const handleMessageInputFocus = () => {
        setIsFocused(true);
        scrollToEnd();
    };

    const handleSend: ComponentProps<typeof MessageInput>['onSend'] = (arg) => {
        onSend(arg);
        setTimeout(() => scrollToEnd(), 150);
    };

    useFocusEffect(
        useCallback(() => {
            if (scrollToBottom) {
                scrollToEnd();
                setTimeout(() => scrollToEnd(), 400);
                setTimeout(() => scrollToEnd(), 800);
            }
        }, [scrollToBottom]),
    );

    const input = (
        <View style={style.commentInputContainer}>
            <MessageInput
                initialActionSheetOpen={isActionSheetOpen}
                onActionSheetOpen={setActionSheetOpen}
                setState={setState}
                state={state}
                onSend={handleSend}
                onBlur={handleMessageInputBlur}
                onFocus={handleMessageInputFocus}
                itemRef={inputRef}
                onEditingEnd={() => setEditing(null)}
                isEditing={editing !== null}
                isEditingText={t('sharingSelected:editing_comment')}
            />
        </View>
    );

    const showStickyBottom = isFocused || editing;

    return (
        <>
            <QueryItemView itemRef={scrollRef} virtualized keyboardShouldPersistTaps="handled" {...props}>
                {children}
                {showComments ? (
                    <CommentList
                        messageId={messageId}
                        bottomButton={showStickyBottom ? undefined : input}
                        comments={comments}
                        onEdit={setEditing}
                    />
                ) : null}
            </QueryItemView>
            {showStickyBottom ? (
                <View style={{ marginRight: screenMargin / 2, marginLeft: screenMargin / 2 }}>{input}</View>
            ) : null}
        </>
    );
};

const style = StyleSheet.create({
    commentInputContainer: { paddingTop: WW * 0.04 },
});

export default CommentsWithInput;
