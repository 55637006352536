import React, { ReactElement, useContext } from 'react';
import moment from 'moment';
import { StyleSheet, View, Text, StyleProp, ViewStyle } from 'react-native';
import { StatusType } from '_api/useSupports';
import { useGetUser } from '_api/useUsers';
import _fonts from '_fonts';
import Images from '_images';
import { fromNowFormat, useThemeStyle, WW } from '_utils';
import { getUsernameFromProfile } from '_utils/misc';
import { smallestMargin, screenMargin, titleFontSize, smallestFontSize } from '_utils/sizes';
import { Theme, ThemeContext } from '_utils/themeContext';
import { CacheImage, HeimeText, ListItem } from 'Components';
import { UnixTimeCode } from 'types/Utility';
import { ReportStatus } from '../../ReportSelected/components';

interface RequestItemProps {
    status: StatusType;
    title: string;
    content: string;
    creator: number;
    createdAt: UnixTimeCode;
    style?: StyleProp<ViewStyle>;
    id: number;
}

const ReportItem = ({ id, status, title, content, creator, style, createdAt }: RequestItemProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const user = useGetUser(creator);
    return (
        <View
            style={[
                themedStyle.main,
                {
                    borderColor: theme.greyMedium,
                    borderWidth: 1,
                },
                style,
            ]}
        >
            <View style={themedStyle.reportContainer}>
                <ReportStatus status={status} />
            </View>
            <Text maxFontSizeMultiplier={2} style={themedStyle.title}>
                {title}
            </Text>
            <Text maxFontSizeMultiplier={2} numberOfLines={3} style={themedStyle.content}>
                {content}
            </Text>
            <View style={themedStyle.bottomContainer}>
                <ListItem
                    containerStyle={themedStyle.userContainer}
                    containerWrapper={themedStyle.userContentWrapper}
                    avatar={<CacheImage source={user?.avatar ?? Images.defaultAvatar} style={themedStyle.avatar} />}
                    title={getUsernameFromProfile(user)}
                    titleStyle={themedStyle.userText}
                />
                <View
                    style={{
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: smallestMargin,
                    }}
                >
                    <HeimeText numberOfLines={1} style={themedStyle.timeString}>
                        {fromNowFormat(moment.unix(createdAt).toDate())}
                    </HeimeText>
                    <HeimeText numberOfLines={1} style={themedStyle.timeString}>
                        #{id}
                    </HeimeText>
                </View>
            </View>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flexGrow: 1,
            minHeight: WW * 0.4,
            marginLeft: screenMargin,
            marginRight: screenMargin,
            paddingTop: smallestMargin,
            paddingLeft: smallestMargin,
            paddingRight: smallestMargin,
            paddingBottom: smallestMargin,
            shadowColor: theme.black,
            shadowOffset: { height: 4, width: 2 },
            backgroundColor: theme.mainBackground,
            shadowOpacity: 0.1,
        },
        reportContainer: { flexDirection: 'row', justifyContent: 'flex-end' },
        userContentWrapper: {
            flex: 2,
            justifyContent: 'center',
            marginLeft: WW * 0.04,
            marginRight: WW * 0,
        },
        userContainer: {
            borderBottomWidth: 0,
            paddingBottom: 0,
            flexGrow: 1,
            flexShrink: 0,
            width: '100%',
        },
        userText: {
            fontSize: WW * 0.04,
            color: theme.darkGray,
            fontFamily: _fonts.primaryFontBold,
        },
        avatar: {
            width: WW * 0.12,
            height: WW * 0.12,
            borderRadius: (WW * 0.12) / 2,
            overflow: 'hidden',
        },
        title: {
            fontFamily: _fonts.primaryFontBold,
            color: theme.darkGreen,
            fontSize: titleFontSize,
        },
        content: {
            fontFamily: _fonts.primaryFont,
            color: theme.darkGreen,
            fontSize: smallestFontSize,
            flex: 1,
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
        },
        timeString: {
            color: theme.darkGray,
            fontSize: smallestFontSize,
        },
        bottomContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
    });

export default ReportItem;
