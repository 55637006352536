import { ReactElement, forwardRef, useImperativeHandle, useState } from 'react';
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import { StyleSheet, RefreshControl, Modal, ScrollView, Dimensions, View } from 'react-native';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { BottomSheetProps } from './types';
import { Theme, WW, useThemeStyle } from '../../_utils';

const BottomSheet = forwardRef<BottomSheetModalMethods, BottomSheetProps>(
    ({ top, content, bottom, onRefresh, isRefreshing }: BottomSheetProps, ref): ReactElement | null => {
        const themedStyle = useThemeStyle(styles);
        const [isOpen, setIsOpen] = useState(false);

        useImperativeHandle(
            ref,
            () => ({
                present: () => setIsOpen(true),
                dismiss: () => setIsOpen(false),
                snapToIndex: () => {},
                snapToPosition: () => {},
                expand: () => setIsOpen(true),
                collapse: () => setIsOpen(false),
                close: () => setIsOpen(false),
                forceClose: () => setIsOpen(false),
            }),
            [],
        );

        if (!isOpen) {
            return null;
        }
        const handleClose = () => setIsOpen(false);

        return (
            <Modal transparent onDismiss={handleClose} onRequestClose={handleClose}>
                <View style={themedStyle.nativeModal}>
                    <View style={themedStyle.cover} />
                    <View style={themedStyle.modal}>
                        {top}
                        <ScrollView
                            refreshControl={
                                isRefreshing ? (
                                    <RefreshControl onRefresh={onRefresh} refreshing={isRefreshing} />
                                ) : undefined
                            }
                            keyboardShouldPersistTaps="handled"
                            contentContainerStyle={themedStyle.contentContainer}
                        >
                            {content}
                        </ScrollView>
                        {bottom}
                    </View>
                </View>
            </Modal>
        );
    },
);

const styles = (theme: Theme) =>
    StyleSheet.create({
        bottomSheet: {
            paddingHorizontal: screenMargin,
            shadowColor: theme.black,
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,

            elevation: 5,
        },
        sheetBackground: { backgroundColor: theme.mainBackground },
        contentContainer: {
            gap: smallestMargin,
            paddingTop: smallestMargin,
        },
        nativeModal: {
            width: Dimensions.get('window').width,
            height: Dimensions.get('window').height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        cover: {
            width: Dimensions.get('window').width,
            height: Dimensions.get('window').height,
            backgroundColor: theme.black,
            opacity: 0.4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modal: {
            backgroundColor: theme.mainBackground,
            position: 'absolute',
            opacity: 1,
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 16,
            paddingBottom: 16,
            width: WW,
        },
    });

export default BottomSheet;
