import React, { ReactElement } from 'react';
import { Text, StyleSheet } from 'react-native';
import { smallestMargin } from '_utils/sizes';
import _fonts from '../../../../../_fonts';
import { WH, useThemeStyle, Theme } from '../../../../../_utils';

const CreateTitle = ({ children }: { children: string }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return <Text style={themedStyle.title}>{children}</Text>;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        title: {
            marginTop: smallestMargin,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WH * 0.035,
            color: theme.black,
        },
    });

export default CreateTitle;
