import React, { PropsWithChildren, ReactElement } from 'react';
import { View, StyleSheet } from 'react-native';
import { WW } from '../../../_utils';

interface BaseContainerProps {
    isMe: boolean;
}

const BaseContainer = ({ children, isMe }: PropsWithChildren<BaseContainerProps>): ReactElement => {
    return (
        <View style={[style.container, isMe ? style.ownMessageContainer : style.othersMessageContainer]}>
            {children}
        </View>
    );
};

const style = StyleSheet.create({
    container: {
        paddingTop: WW * 0.04,
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    ownMessageContainer: {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
    },
    othersMessageContainer: {
        justifyContent: 'flex-start',
    },
});

export default BaseContainer;
