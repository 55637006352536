import React, { ReactElement, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Easing, StyleSheet } from 'react-native';
import { WW } from '../../_utils';
import { WH } from '../../_utils/dimensions.web';
import { ThemeContext } from '../../_utils/themeContext';
import { MainLogo, Modal } from '../../Components';

interface MemberProofProps {
    onDismiss(): void;
}

const MemberProofModal = ({ onDismiss }: MemberProofProps): ReactElement => {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext);

    const fadeAnim = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        const sequence = Animated.sequence([
            Animated.timing(fadeAnim, { toValue: 1, useNativeDriver: false, duration: 3000, easing: Easing.ease }),
            Animated.timing(fadeAnim, { toValue: 0, useNativeDriver: false, duration: 3000, easing: Easing.ease }),
        ]);
        Animated.loop(sequence).start();
    });

    return (
        <Modal
            onDismiss={onDismiss}
            header={t('memberBenefit:memberProof')}
            content={
                <Animated.View
                    style={[
                        styles.container,
                        {
                            backgroundColor: fadeAnim.interpolate({
                                inputRange: [0, 1],
                                outputRange: [theme.secondary, theme.lightBackground],
                            }),
                        },
                    ]}
                >
                    <MainLogo imageWidth={WW * 0.65} imageHeight={WH * 0.3} />
                </Animated.View>
            }
        />
    );
};

const styles = StyleSheet.create({
    container: {
        height: WH * 0.3,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
    },
    scale: { transform: [{ scale: 1 }] },
});

export default MemberProofModal;
