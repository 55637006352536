import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { FileLink } from 'types/Base';
import { CommentItem } from './components';
import { useOwnProfile } from '../../_api/useProfile';
import { useAppNavigation } from '../../_navigator';
import { fileIsImage, Theme, useThemeStyle, WW } from '../../_utils';
import { subtitleFontSize } from '../../_utils/sizes';
import { UnixTimeCode } from '../../types/Utility';
import EmptyMessage from '../EmptyMessage';
import HeimeText from '../HeimeText';
import QueryView from '../QueryViews/QueryView';

interface CommentData {
    id: number | null;
    creator: number;
    content: string;
    created_at: UnixTimeCode;
    files: FileLink[];
    edited: boolean;
    deleted: boolean;
}

type CommentListProps = (CommentListEditableProps | CommentListNotEditableProps) & {
    comments: CommentData[];
    hasNextPage?: boolean;
    topButton?: ReactElement | undefined;
    bottomButton?: ReactElement | undefined;
    messageId: number;
};

interface CommentListEditableProps {
    editable: true;
    onEdit(comment: CommentData): void;
}

interface CommentListNotEditableProps {
    editable?: false;
    onEdit?(comment: CommentData): void;
}

type CommentWithId = Omit<CommentData, 'id'> & { id: number };

const CommentList = ({
    comments,
    hasNextPage,
    topButton,
    bottomButton,
    messageId,
    onEdit,
}: CommentListProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { data: ownProfile } = useOwnProfile();
    const { navigate } = useAppNavigation();

    const renderComments = ({ item }: { item: CommentWithId }) => {
        const handleImageClick = (index: number) => {
            navigate('ImagePreview', {
                images:
                    item.files
                        ?.filter((file) => fileIsImage({ ...file, status: 'stale', index }))
                        .map((file) => ({ ...file, sender_id: item.creator })) ?? [],
                selectedIndex: index,
            });
        };
        return (
            <CommentItem
                creator={item.creator}
                content={item.content}
                created_at={item.created_at}
                isSelf={ownProfile?.id === item.creator}
                files={item.files ?? []}
                onImageClick={handleImageClick}
                edited={item.edited}
                deleted={item.deleted}
                commentId={item.id}
                messageId={messageId}
                onEdit={onEdit ? () => onEdit(item) : undefined}
                editable={onEdit !== undefined}
            />
        );
    };

    return (
        <View style={themedStyle.list}>
            <HeimeText style={themedStyle.subTitle}>
                {t('global:comments')}
                <HeimeText style={themedStyle.noBold}>{` (${comments.length}${hasNextPage ? '+' : ''})`}</HeimeText>
            </HeimeText>
            {topButton}
            <QueryView
                data={comments.map((arg) => (arg.id ? arg : { ...arg, id: 0 }) as CommentWithId)}
                renderItem={renderComments}
                emptyList={<EmptyMessage message={t('global:noComments')} />}
            />
            {bottomButton}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        list: {
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
        },
        subTitle: {
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.05,
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            color: theme.black,
        },
        noBold: {
            fontWeight: 'normal',
        },
    });

export default CommentList;
