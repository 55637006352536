import { useEffect } from 'react';
import { NativeEventEmitter, NativeModules } from 'react-native';
import { addBreadcrumb } from '_utils/Sentry';

const { TimeChangedModule } = NativeModules;

const emitter = new NativeEventEmitter(TimeChangedModule);

const useTimeChangedEvent = (func: () => void): void => {
    useEffect(() => {
        const subscription = emitter.addListener('timechanged', () => {
            func();
            addBreadcrumb('timeChanged', 'Got the time changed event');
        });
        return () => subscription.remove();
    }, [func]);
};

export default useTimeChangedEvent;
