import React from 'react';
import { StyleSheet, View } from 'react-native';
import { smallestMargin } from '_utils/sizes';
import { PrimaryButton, SecondaryButton } from 'Components';

const BottomButtons = ({
    buttons,
    buttonsDirection = 'column',
}: {
    buttons: {
        type?: 'danger' | 'secondary';
        text: string;
        onPress(): void;
        isDisabled?: boolean;
        isLoading?: boolean;
    }[];
    buttonsDirection?: 'row' | 'column';
}) => {
    if (buttons.length === 0) {
        return null;
    }

    return (
        <View
            style={[
                styles.actionButtonContainer,
                buttonsDirection === 'column' ? styles.columnButtons : styles.rowButtons,
            ]}
        >
            {buttons.map(({ type, onPress, isLoading, text, isDisabled }) => {
                const props = {
                    key: text,
                    style: buttonsDirection === 'row' ? styles.colorButtons : undefined,
                    text,
                    onPress,
                    status: isLoading ? ('loading' as const) : isDisabled ? ('disabled' as const) : null,
                };

                if (type === 'secondary') {
                    return <SecondaryButton {...props} key={props.key} />;
                }

                return <PrimaryButton danger={type === 'danger'} {...props} key={props.key} />;
            })}
        </View>
    );
};

const styles = StyleSheet.create({
    columnButtons: {
        flexDirection: 'column',
    },
    rowButtons: {
        flexDirection: 'row',
    },
    actionButtonContainer: {
        flexDirection: 'row',
        width: '100%',
        paddingTop: 12,
        gap: smallestMargin,
    },
    colorButtons: {
        flex: 1,
    },
});

export default BottomButtons;
