import React, { ReactElement } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useThemeContext } from '_utils/themeContext';
import { CacheImage } from '.';
import _fonts from '../_fonts';
import Images from '../_images';
import { WW, WH } from '../_utils';

interface EmptyListProps {
    message: string;
}

const EmptyList = ({ message }: EmptyListProps): ReactElement => {
    const { theme } = useThemeContext();
    return (
        <View style={styles.noResultContainer}>
            <CacheImage resizeMode={'contain'} source={Images.no_result} style={styles.noResultImage} />
            <Text numberOfLines={2} style={[styles.noNotifications, { color: theme.black }]}>
                {message}
            </Text>
        </View>
    );
};

const styles = StyleSheet.create({
    noNotifications: {
        textAlign: 'center',
        fontFamily: _fonts.primaryFont,
        marginTop: WH * 0.02,
    },
    noResultContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noResultImage: { height: WW * 0.4, width: WW },
});

export default EmptyList;
