import { useMemo } from 'react';
import useSelectedCoopItem from './useSelectedCoopItem';
import { useOwnProfile } from '../../_api/useProfile';

const useIsCoopAdmin = (): boolean => {
    const { data: ownProfile } = useOwnProfile();
    const selectedCoop = useSelectedCoopItem();
    return useMemo(() => {
        return Boolean(
            ownProfile?.roles.find((role) => role.name === 'Super Admin') ||
                ownProfile?.cooperative_roles.find(
                    (role) => role.cooperative_id === selectedCoop?.id && role.name === 'Cooperative Admin',
                ),
        );
    }, [ownProfile?.cooperative_roles, ownProfile?.roles, selectedCoop?.id]);
};

export default useIsCoopAdmin;
