import React, { ComponentProps, ReactElement } from 'react';
import { Animated } from 'react-native';
import { Theme } from '_utils/themeContext';
import { Icon } from 'Components';

interface LockAnimationProp {
    scaleAnim: Animated.Value;
    icon: ComponentProps<typeof Icon>['name'];
    color: keyof Theme;
}

const LockAnimation = ({ scaleAnim, color, icon }: LockAnimationProp): ReactElement => {
    let transformStyle = {
        transform: [{ scale: scaleAnim.interpolate({ inputRange: [0, 1], outputRange: [1.3, 2] }) }],
    };

    return (
        <Animated.View style={transformStyle}>
            <Icon name={icon} color={color} />
        </Animated.View>
    );
};

export default LockAnimation;
