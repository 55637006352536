import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { FlashList } from '@shopify/flash-list';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useOwnProfile } from '_api/useProfile';
import { useGetUsers } from '_api/useUsers';
import Images from '_images';
import { Theme, useThemeStyle, WH, WW, isWeb } from '_utils';
import { flattenIniniteResult, getUsernameFromProfile } from '_utils/misc';
import { screenMargin, smallestMargin } from '_utils/sizes';
import {
    CheckBox,
    EmptyMessage,
    HeaderWithNav,
    PrimaryButton,
    HeimeText,
    ControlledInput,
    ListItem,
    CacheImage,
} from 'Components';
import { ExternalUserProfile } from 'types/User';

const ActivityArrangers = ({ goBack, goToPreview }: { goBack(): void; goToPreview(): void }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { data, isFetchingNextPage, hasNextPage, fetchNextPage } = useGetUsers(false, true);
    const { data: ownProfile } = useOwnProfile();
    const users = useMemo(
        () => flattenIniniteResult(data).filter((item) => item.id !== ownProfile?.id),
        [data, ownProfile?.id],
    );

    useEffect(() => {
        if (!isFetchingNextPage && hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

    return (
        <>
            <HeaderWithNav title={t('newActivity:arrangers')} onPress={goBack} />
            <View style={themedStyle.container}>
                <HeimeText style={themedStyle.title}>{t('newActivity:add_arranger')}</HeimeText>
                <HeimeText style={themedStyle.subtitle}>{t('newActivity:add_arrangers_explainer')}</HeimeText>
                <ItemSelector items={users} />
            </View>
            <PrimaryButton text={t('newActivity:continue').toUpperCase()} onPress={goToPreview} bottomAction="modal" />
        </>
    );
};

interface ItemSelectorProps {
    items: ExternalUserProfile[];
}

const ItemSelector = ({ items }: ItemSelectorProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { field } = useController({ name: 'arrangers' });
    const [search, setSearch] = useState('');

    const toggleUser = useCallback(
        (itemId: number) => {
            if (field.value.includes(itemId)) {
                field.onChange(field.value.filter((i: number) => i !== itemId));
            } else {
                field.onChange([...field.value, itemId]);
            }
        },
        [field],
    );

    const renderItem = useCallback(
        ({ item }: { item: ExternalUserProfile & { selected: true } }) => {
            const onPress = () => toggleUser(item.id);
            return (
                <ListItem
                    avatar={
                        <CacheImage
                            source={item?.avatar ? item.avatar : Images.defaultAvatar}
                            style={themedStyle.avatar}
                        />
                    }
                    onPress={onPress}
                    title={getUsernameFromProfile(item)}
                    actionButton={<CheckBox onPress={onPress} checked={field.value.includes(item.id)} />}
                    containerStyle={themedStyle.user}
                />
            );
        },
        [field.value, themedStyle.avatar, themedStyle.user, toggleUser],
    );

    const searchResults = useMemo(
        () =>
            (search
                ? items.filter((item) => getUsernameFromProfile(item).toLowerCase().includes(search.toLowerCase()))
                : items
            ).map((arg) => ({ ...arg, selected: field.value.includes(arg.id) })),
        [field.value, items, search],
    );

    return (
        <>
            <ControlledInput
                containerStyle={themedStyle.search}
                value={search}
                onChange={(arg) => setSearch(arg ?? '')}
                placeholder={t('newActivity:search_users')}
            />
            {searchResults.length === 0 ? (
                <View style={themedStyle.subtitle}>
                    <EmptyMessage
                        message={items.length === 0 ? t('newActivity:no_users') : t('newActivity:no_users_search')}
                    />
                </View>
            ) : null}
            <FlashList
                data={searchResults}
                renderItem={renderItem}
                keyExtractor={(item) => item.id.toString()}
                keyboardShouldPersistTaps="handled"
                estimatedItemSize={90}
            />
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        title: {
            marginTop: WW * 0.04,
            fontSize: WH * 0.035,
            color: theme.black,
            fontWeight: 'bold',
        },
        subtitle: {
            marginTop: WW * 0.04,
            fontSize: WH * 0.02,
            color: theme.secondaryText,
        },
        user: { paddingTop: smallestMargin, paddingBottom: smallestMargin },
        search: {
            marginTop: smallestMargin,
            marginBottom: smallestMargin,
        },
        avatar: {
            width: WW * 0.18,
            height: WW * 0.18,
            borderRadius: (WW * 0.18) / 2,
            overflow: 'hidden',
        },
        container: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            flexGrow: 1,
            flex: isWeb() ? 1 : undefined,
        },
    });

export default ActivityArrangers;
