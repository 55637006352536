import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { SafeAreaView } from '_dependencies/safeArea';
import { useAppNavigation } from '_navigator';
import { Theme, useThemeStyle } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { HeimeText, PrimaryButton, SecondaryButton, PinInput, SmallHeader } from 'Components';
import { usePinHook } from './usePinHook';

const CreatePin = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { onCodeChange, status, createPin, repeatPin, pinStep, onSkipOrBack, buttonStatus } = usePinHook();
    const { navigate } = useAppNavigation();
    const isError = ['errorGeneric', 'errorPin'].find((el) => el === status);

    return (
        <SafeAreaView edges={['left', 'right', 'bottom']} style={themedStyle.main}>
            <SmallHeader title={pinStep === 'CreatePin' ? t('onboarding:createPin') : t('onboarding:repeatPin')} />
            <HeimeText style={themedStyle.description}>
                {pinStep === 'CreatePin' ? t('onboarding:enterPinDesciption') : t('onboarding:repeatPinDesciption')}
            </HeimeText>
            <View style={themedStyle.containerPin}>
                <PinInput
                    status={isError ? 'error' : (status as 'loading' | null)}
                    code={pinStep === 'CreatePin' ? createPin : repeatPin}
                    pinStep={pinStep}
                    onCodeChange={onCodeChange}
                />
            </View>
            {isError ? (
                <HeimeText style={themedStyle.errorText}>
                    {status === 'errorGeneric' ? t('enterPin:error') : t('enterPin:wrongCode')}
                </HeimeText>
            ) : null}
            {pinStep === 'RepeatPin' ? (
                <SecondaryButton
                    style={themedStyle.continue}
                    text={t('global:goBack')}
                    onPress={() => navigate('OwnProfile', undefined)}
                />
            ) : null}

            <PrimaryButton
                status={buttonStatus}
                bottomAction="modal"
                text={t('onboarding:continue').toUpperCase()}
                onPress={onSkipOrBack}
            />
        </SafeAreaView>
    );
};
const styles = (theme: Theme) =>
    StyleSheet.create({
        main: { flex: 1, backgroundColor: theme.mainBackground },
        description: {
            marginTop: smallestMargin * 4,
            marginRight: screenMargin,
            marginLeft: screenMargin,
            textAlign: 'center',
            color: theme.darkGray,
            fontSize: subtitleFontSize,
        },
        containerPin: {
            justifyContent: 'center',
            flex: 1,
        },
        errorText: {
            color: theme.error,
            marginBottom: 12,
            textAlign: 'center',
        },
        continue: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            marginBottom: smallestMargin,
        },
    });
export default CreatePin;
