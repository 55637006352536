import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { TimeZone } from '_constants';
import { isReservationOnGoing } from '_utils';
import { captureException } from '_utils/Sentry';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { DateWithSlots, HeimeText, Icon, SelectorChip } from 'Components';
import { OwnReservation } from 'types/Reservation';

const ReservationDurationView = ({
    start_at,
    end_at,
    locksLength,
}: Pick<OwnReservation, 'end_at' | 'start_at'> & { locksLength: number }) => {
    const start = moment.unix(start_at);
    const end = moment.unix(end_at);

    const isSameDay = start.isSame(end, 'day');
    const { t } = useTranslation();
    const variant = useMemo(() => {
        if (isSameDay) {
            return 'sameDay';
        } else if (start.clone().add(1, 'day').isSame(end, 'day')) {
            return 'nextDay';
        } else if (start.clone().add(2, 'day').isSame(end, 'day')) {
            return 'threeDays';
        } else {
            return 'moreDays';
        }
    }, [isSameDay, start, end]);

    const isOngoing = isReservationOnGoing({ start_at, end_at });

    const baseDateWithSlotsProps = {
        showMonth: !start.isSame(moment(), 'month'),
        disablePadding: true,
        compact: false,
    };
    const baseChipProps = {
        containerStyle: { marginBottom: smallestMargin },
        variant: 'WhitePrimaryGrey' as const,
    };

    const getOngoingChip = () => (
        <SelectorChip
            {...baseChipProps}
            content={<Icon style={{ marginRight: smallestMargin }} name="clockSpinning" color="main" />}
            title={t('reservations:onGoing')}
            key="ongoing"
            variant="lightPrimary"
        />
    );

    const formatHours = (inp: moment.Moment) => inp.tz(TimeZone).format('HH:mm');

    const getEndsTodayChips = () => [
        <SelectorChip {...baseChipProps} title={`${t('expandedReservation:ends')} ${end.format('HH:mm')}`} key="end" />,
        <SelectorChip
            {...baseChipProps}
            title={`${moment.duration(end.diff(moment().endOf('minute'))).humanize()} ${t('reservations:left')}`}
            key="left"
        />,
    ];

    const getLocksChip = () =>
        locksLength > 0
            ? [
                  <SelectorChip
                      {...baseChipProps}
                      title={t('expandedReservation:lock', { count: locksLength })}
                      key="locks"
                  />,
              ]
            : [];

    if (variant === 'sameDay') {
        return (
            <DateWithSlots
                date={start}
                title={t('expandedReservation:bookingDetails')}
                slots={[
                    ...(isOngoing
                        ? [getOngoingChip(), ...getEndsTodayChips()]
                        : [
                              <SelectorChip
                                  {...baseChipProps}
                                  title={`${formatHours(start)}-${formatHours(end)}`}
                                  key="start-end"
                              />,
                          ]),
                    ...getLocksChip(),
                ]}
                {...baseDateWithSlotsProps}
                disablePadding={false}
            />
        );
    } else if (variant === 'nextDay' || variant === 'moreDays') {
        return (
            <>
                <DateWithSlots
                    date={start}
                    title={t('expandedReservation:starting')}
                    slots={[
                        ...(isOngoing ? [getOngoingChip()] : []),
                        <SelectorChip {...baseChipProps} title={formatHours(start)} key="start" />,
                        ...getLocksChip(),
                    ]}
                    {...baseDateWithSlotsProps}
                    borderBottom={variant !== 'moreDays'}
                    disablePadding={false}
                />
                {variant === 'moreDays' ? (
                    <MultiDays start={start.clone().add(1, 'day')} end={end.clone().subtract(1, 'day')} />
                ) : null}
                <DateWithSlots
                    date={end}
                    title={t('expandedReservation:ending')}
                    slots={[
                        ...(isOngoing
                            ? getEndsTodayChips()
                            : [<SelectorChip {...baseChipProps} title={formatHours(end)} key="end" />]),
                    ]}
                    {...baseDateWithSlotsProps}
                    showMonth={!end.isSame(moment(), 'month')}
                />
            </>
        );
    } else if (variant === 'threeDays') {
        const middleDay = start.clone().add(1, 'day');
        return (
            <>
                <DateWithSlots
                    date={start}
                    title={t('expandedReservation:starting')}
                    slots={[
                        ...(isOngoing ? [getOngoingChip()] : []),
                        <SelectorChip {...baseChipProps} title={formatHours(start)} key="start" />,
                        ...getLocksChip(),
                    ]}
                    borderBottom
                    {...baseDateWithSlotsProps}
                />
                <DateWithSlots
                    date={middleDay}
                    title={t('expandedReservation:booked')}
                    slots={[<SelectorChip {...baseChipProps} title={t('expandedReservation:all_day')} key="all_day" />]}
                    {...baseDateWithSlotsProps}
                    showMonth={!middleDay.isSame(moment(), 'month')}
                    borderBottom
                />
                <DateWithSlots
                    date={end}
                    title={t('expandedReservation:ending')}
                    slots={[
                        ...(isOngoing && moment().isSame(end, 'day')
                            ? getEndsTodayChips()
                            : [<SelectorChip {...baseChipProps} title={formatHours(end)} key="end" />]),
                    ]}
                    {...baseDateWithSlotsProps}
                    showMonth={!start.isSame(moment(), 'month')}
                    disablePadding={false}
                />
            </>
        );
    }
    captureException(new Error(`Unknown variant in ReservationDurationView, start: ${start_at}, end: ${end_at}`));
    return null;
};

const MultiDays = ({ start, end }: { start: moment.Moment; end: moment.Moment }) => {
    const { theme } = useThemeContext();
    let curr = start.clone();
    const numbers = [];
    while (!curr.isAfter(end, 'day')) {
        const clone = curr.clone();
        curr = curr.add(1, 'day');
        if (!clone.isSame(curr, 'month') || clone.isSame(end, 'day')) {
            numbers.push(clone.format('D MMMM'));
        } else {
            numbers.push(clone.format('D'));
        }
    }

    return (
        <View
            style={{
                marginLeft: screenMargin,
                paddingTop: screenMargin,
                paddingBottom: screenMargin,
                paddingLeft: screenMargin,
                borderColor: theme.mediumGrey,
                borderLeftWidth: 1,
            }}
        >
            <HeimeText variant="subtitle">{`+${numbers.join(', ')}`}</HeimeText>
        </View>
    );
};

export default ReservationDurationView;
