import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { useAuthNavigation } from 'Auth/AuthNavigation';
import { BackArrow, HeimeText, LanguageSelector } from 'Components';

const BackButton = (): ReactElement => {
    const navigation = useAuthNavigation();
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    const handleGoBack = () => {
        navigation.pop();
    };

    return (
        <View style={themedStyle.navWrapper}>
            <TouchableOpacity onPress={handleGoBack} style={themedStyle.navigationContainer}>
                <BackArrow />
                <HeimeText style={themedStyle.backText}>{t('enterPin:back')}</HeimeText>
            </TouchableOpacity>
            <LanguageSelector />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        navWrapper: { flexDirection: 'row', alignItems: 'center' },
        navigationContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        backText: {
            fontSize: 16,
            letterSpacing: 1,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: theme.main,
        },
    });

export default BackButton;
