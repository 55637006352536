import React, { ReactElement } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { CacheImage, PrimaryButton } from '.';
import _fonts from '../_fonts';
import Images from '../_images';
import { useThemeStyle, WH, WW, Theme } from '../_utils';

interface GenericSuccessScreenProps {
    message?: string;
    button?: { text: string; onPress(): void };
}

const GenericSuccessScreen = ({ message, button }: GenericSuccessScreenProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <>
            <View style={themedStyle.imageContainer}>
                <CacheImage resizeMode={'contain'} source={Images.sharingWall} style={themedStyle.errorImage} />
                <View style={themedStyle.textContainer}>
                    <Text style={themedStyle.mainText}>{message}</Text>
                </View>
            </View>

            {button ? (
                <PrimaryButton style={themedStyle.button} onPress={button.onPress} text={button.text.toUpperCase()} />
            ) : null}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        imageContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        errorImage: {
            height: WH * 0.3,
            aspectRatio: 1,
        },

        textContainer: {
            marginTop: WH * 0.02,
            marginBottom: WH * 0.02,
            maxWidth: 0.8 * WW,
            justifyContent: 'center',
        },
        mainText: {
            color: theme.black,
            textAlign: 'center',
            fontSize: WH * 0.03,
            fontFamily: _fonts.primaryFontBold,
        },
        button: {
            marginLeft: 16,
            marginRight: 16,
            marginTop: 16,
            marginBottom: 16,
            width: WW - 16 * 2,
        },
    });

export default GenericSuccessScreen;
