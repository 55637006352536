import React, { ReactElement, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import RNRestart from 'react-native-restart';
import { BottomSpacer, SecondaryButton } from '.';
import GenericErrorScreen from './GenericErrorScreen';
import { useAppNavigation } from '../_navigator';
import { Theme, useThemeStyle, isWeb, resetAsyncStorage, WW } from '../_utils';
import { addBreadcrumb, captureException } from '../_utils/Sentry';

interface NavigationErrorScreenProps {
    resetErrorBoundary(): void;
    error: Error;
}

const NavigationErrorScreen = ({ resetErrorBoundary, error }: NavigationErrorScreenProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { popToTop } = useAppNavigation();
    useEffect(() => {
        addBreadcrumb('errorScreen', 'Error captured by AppErrorScreen');
        captureException(error);
    }, [error]);

    const handleTryAgain = () => {
        addBreadcrumb('errorScreen', 'user clicked "try again"');

        queryClient
            .getQueryCache()
            .findAll(undefined)
            .filter((query) => query.state.error)
            .forEach((query) => query.reset());
        popToTop();
        resetErrorBoundary();
    };

    const handleReset = async () => {
        addBreadcrumb('errorScreen', 'user clicked "reset"');
        await resetAsyncStorage();
        if (isWeb()) {
            document.location.href = 'http://' + window.location.host;
        } else {
            RNRestart.Restart();
        }
    };

    return (
        <View style={themedStyle.container}>
            <GenericErrorScreen error={error} button={{ text: t('global:goBack'), onPress: handleTryAgain }} />
            <SecondaryButton style={themedStyle.restartButton} text={t('global:resetApp')} onPress={handleReset} />
            <BottomSpacer />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: { height: '100%', display: 'flex', backgroundColor: theme.mainBackground },
        restartButton: {
            marginBottom: 16,
            marginRight: 16,
            marginLeft: 16,
            marginTop: 0,
            width: WW - 16 * 2,
        },
    });

export default NavigationErrorScreen;
