import React, { ReactElement } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import _fonts from '_fonts';
import { Theme, useThemeStyle, WW } from '_utils';

interface AnnouncementMessageProps {
    type: 'positive' | 'neutral' | 'negative';
    content: string;
}

const AnnouncementMessage = ({ type, content }: AnnouncementMessageProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View
            style={[
                themedStyle.wrapper,
                type === 'positive'
                    ? themedStyle.positiveWrapper
                    : type === 'neutral'
                      ? themedStyle.neutralWrapper
                      : themedStyle.negativeWrapper,
            ]}
        >
            <Text
                style={[
                    themedStyle.text,
                    type === 'positive'
                        ? themedStyle.positiveText
                        : type === 'neutral'
                          ? themedStyle.neutralText
                          : themedStyle.negativeText,
                ]}
            >
                {content}
            </Text>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        wrapper: {
            alignSelf: 'center',
            maxWidth: WW * 0.7,
            borderRadius: 50,
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
        },
        text: {
            textAlign: 'center',
            paddingLeft: WW * 0.03,
            paddingRight: WW * 0.03,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            fontFamily: _fonts.primaryFontBold,
        },
        positiveWrapper: {
            backgroundColor: theme.lightGrey,
        },
        positiveText: {
            color: theme.secondary,
        },
        negativeWrapper: {
            backgroundColor: theme.transparent_red,
        },
        negativeText: {
            color: theme.red,
        },
        neutralWrapper: {
            backgroundColor: theme.lightGrey,
        },
        neutralText: {
            color: theme.mediumGrey,
        },
    });

export default AnnouncementMessage;
