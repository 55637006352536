import { ReactElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { useGetUser } from '_api/useUsers';
import _fonts from '_fonts';
import Images from '_images';
import { useThemeStyle, WH, WW, useNavigateToProfile, Theme, fromNowFormat } from '_utils';
import useUserApartments from '_utils/hooks/useUserApartments';
import { getUsernameFromProfile } from '_utils/misc';
import { CacheImage } from 'Components';
import { UnixTimeCode, GenericCollectionItem } from 'types/Utility';

interface FeedbackItemProps {
    creator: GenericCollectionItem;
    created_at: UnixTimeCode;
    text: string;
}

const FeedbackItem = ({ creator, created_at, text }: FeedbackItemProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const user = useGetUser(creator.id);
    const navigate = useNavigateToProfile();
    const getApartments = useUserApartments();
    const apt = getApartments(user).map((item) => item.name);
    return (
        <View style={themedStyle.main}>
            <TouchableOpacity onPress={() => navigate(creator.id)} style={themedStyle.row}>
                <View>
                    <CacheImage source={user?.avatar ?? Images.defaultAvatar} style={themedStyle.avatar} />
                </View>
                <View style={themedStyle.userItemStyle}>
                    <View style={themedStyle.row}>
                        <Text style={themedStyle.title}>{user ? getUsernameFromProfile(user) : creator.name}</Text>
                        {apt?.length! > 0 && (
                            <Text numberOfLines={1} style={themedStyle.aptStyle}>
                                {'  •  ' + apt}
                            </Text>
                        )}
                    </View>
                    <Text maxFontSizeMultiplier={2} style={themedStyle.desc}>
                        {fromNowFormat(moment.unix(created_at).toDate())}
                    </Text>
                </View>

                <View style={themedStyle.centered}>
                    <View style={themedStyle.ratingView}>
                        <Text numberOfLines={1} style={themedStyle.ratingText}>
                            {t('sharingSelected:helperProfile:stars', { count: 1 })}
                        </Text>
                    </View>
                </View>
            </TouchableOpacity>
            <Text style={themedStyle.feedback}>{text}</Text>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        avatar: {
            width: WW * 0.12,
            height: WW * 0.12,
            borderRadius: (WW * 0.12) / 2,
        },
        feedback: {
            fontFamily: _fonts.primaryFont,
            fontSize: WH * 0.02,
            marginTop: WH * 0.02,
            color: theme.secondaryText,
        },
        centered: {
            justifyContent: 'center',
            flex: 0,
        },
        userItemStyle: {
            flex: 1,
            justifyContent: 'center',
            marginLeft: WW * 0.02,
        },
        aptStyle: {
            textAlign: 'left',
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.03,
            alignSelf: 'center',
            color: theme.secondary,
        },
        ratingText: {
            fontFamily: _fonts.primaryFont,
            color: theme.secondary,
            fontSize: WW * 0.03,
        },
        ratingView: {
            backgroundColor: theme.lightGrey,
            borderRadius: WW * 0.04,
            paddingLeft: WW * 0.03,
            paddingRight: WW * 0.03,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
        },
        main: {
            alignItems: 'stretch',
            paddingTop: WH * 0.02,
            paddingBottom: WH * 0.02,
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
            borderBottomColor: theme.lightGrey,
            borderBottomWidth: 1,
        },
        row: {
            flexDirection: 'row',
        },
        title: {
            fontSize: WH * 0.02,
            color: theme.darkGreen,
            fontFamily: _fonts.primaryFontBold,
        },
        desc: {
            color: theme.secondaryText,
            fontSize: WW * 0.03,
            fontFamily: _fonts.primaryFont,
        },
    });

export default FeedbackItem;
