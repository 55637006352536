import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { useContextMenuFocusEffect } from '_navigator';
import { Theme, useThemeStyle } from '_utils';
import { HeaderWithNav } from 'Components';
import DocumentList from 'Components/DocumentList';
import { Folder } from 'types/Heime';

const FolderSelected = ({ route }: { route: { params: { document: Folder } } }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const document = route.params.document;
    useContextMenuFocusEffect(['reportsNew']);

    return (
        <View style={themedStyle.container}>
            <HeaderWithNav title={document.name} safeArea />
            <DocumentList data={document} searchedText={''} />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: theme.mainBackground,
        },
    });

export default FolderSelected;
