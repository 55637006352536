import * as amplitude from '@amplitude/analytics-react-native';
import { API_URL } from '_constants';
import { LaunchArguments } from '_dependencies/launchArguments';

interface PossibleLaunchArguments {
    disableSentryTracing?: boolean;
    environment?: string;
}

const args = LaunchArguments.value<PossibleLaunchArguments>();

let identity: amplitude.Identify | undefined;

const getIdentity = () => {
    if (identity) {
        return identity;
    }

    identity = new amplitude.Identify();
    return identity;
};

const environment =
    (args.environment ?? __DEV__)
        ? 'LOCAL_DEV'
        : API_URL('no')?.includes('dev.frontsoftware.no/heime')
          ? 'DEV'
          : 'Production';

export const initializeAmplitude = () => {
    amplitude.init(
        environment === 'Production' ? 'f74c951a22fd4c30db3e866cfd3243ff' : '89bef7b7b5c8553c4d1da6fa7213c473',
        undefined,
        {
            serverZone: 'EU',
            trackingOptions: {
                ipAddress: false,
            },
        },
    );
    setUserProperty('environment', environment);
};

export const setAmplitudeUserId = (id: string) => {
    amplitude.setUserId(`Heime-${environment}-${id}`);
};
export const removeAmplitudeUserId = () => {
    amplitude.setUserId(undefined);
};

export const setAmplitudeCooperative = (coop: { id: number; name: string }) => {
    setUserProperty('selectedcoop', coop);
};

export const removeAmplitudeCooperative = () => {
    removeUserProperty('selectedcoop');
};

const identify = (arg: amplitude.Identify) => {
    amplitude.identify(arg);
    return arg;
};

export const setUserProperty: amplitude.Identify['set'] = (...fnArgs) => {
    return identify(getIdentity().set(...fnArgs));
};

export const removeUserProperty: amplitude.Identify['unset'] = (...fnArgs) => {
    return identify(getIdentity().unset(...fnArgs));
};

export const track = amplitude.track;
