import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableHighlight } from 'react-native';
import useAppNavigation from '_navigator/hooks/useAppNavigation';
import { ApiImage } from 'types/Base';
import { useGetUser } from '../../../_api/useUsers';
import Images from '../../../_images';
import { Theme, useThemeStyle, WW } from '../../../_utils';
import { ThemeContext } from '../../../_utils/themeContext';
import CacheImage from '../../CacheImage';

const MessageText = ({
    avatarUrl,
    userId,
    senderName,
}: {
    avatarUrl?: Omit<ApiImage, 'id'> | null;
    userId: number | undefined;
    senderName: string;
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const user = useGetUser(userId);
    const { theme } = useContext(ThemeContext);
    const { navigate } = useAppNavigation();
    const handleUserPress = () => userId && navigate('Profile', { id: userId });
    const { t } = useTranslation();

    return (
        <TouchableHighlight
            onPress={user ? handleUserPress : undefined}
            underlayColor={theme.mainBackground}
            style={[themedStyle.avatarContainer, themedStyle.size]}
            accessibilityLabel={t('chat:from', { name: senderName })}
            accessibilityHint={t('chat:to_profile')}
        >
            <CacheImage source={user?.avatar ?? avatarUrl ?? Images.defaultAvatar} style={themedStyle.size} />
        </TouchableHighlight>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        avatarContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.lightGrey,
            borderRadius: (WW * 0.1) / 2,
            overflow: 'hidden',
        },
        size: {
            width: WW * 0.1,
            height: WW * 0.1,
        },
    });

export default MessageText;
