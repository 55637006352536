import moment from 'moment';
import useRerenderCountdown from './useRerenderCountdown';
import fromNowFormat from '../fromNowFormat';

/** function taking in a Date and formatting it, while rerendering when needed using momentjs */
const useUpdatingFormattedTimeAgo = (date: Date): string => {
    useRerenderCountdown(moment(date), false);

    return fromNowFormat(date);
};

export default useUpdatingFormattedTimeAgo;
