import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PendingRegistrationState = string | null;

const initialState: PendingRegistrationState = null;

export const pendingRegistrationSlice = createSlice({
    name: 'pendingRegistration',
    initialState: initialState as PendingRegistrationState,
    reducers: {
        setRegistration: (state, action: PayloadAction<string>) => {
            return action.payload;
        },
        reset: () => {
            return null;
        },
    },
});

export const { setRegistration, reset } = pendingRegistrationSlice.actions;

export default pendingRegistrationSlice.reducer;
