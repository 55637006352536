import React, { ReactElement } from 'react';
import { Text, StyleSheet, View, StyleProp, ViewStyle } from 'react-native';
import _fonts from '../../../_fonts';
import { Theme, useThemeStyle, WW } from '../../../_utils';
import { screenMargin } from '../../../_utils/sizes';

interface EmptyListProps {
    title: string;
    style?: StyleProp<ViewStyle>;
}

const EmptyList = ({ title, style }: EmptyListProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View style={[themedStyle.main, style]}>
            <Text style={themedStyle.text}>{title}</Text>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            paddingTop: screenMargin,
            paddingBottom: screenMargin,
            paddingLeft: screenMargin * 2,
            paddingRight: screenMargin * 2,
            marginLeft: screenMargin,
            marginRight: screenMargin,
            marginTop: screenMargin,
            marginBottom: screenMargin,
            alignSelf: 'center',
            justifyContent: 'center',
            backgroundColor: theme.lightGreen,
            borderRadius: 40,
        },
        text: {
            alignSelf: 'center',
            color: theme.darkGreen,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
        },
    });

export default EmptyList;
