import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import ExternalUserAvatar from './ExternalUserAvatar';
import { Member } from '../../../_api/useContactGroup';
import _fonts from '../../../_fonts';
import { openURL, useThemeStyle, WH, WW } from '../../../_utils';
import { smallestFontSize } from '../../../_utils/sizes';
import { Theme } from '../../../_utils/themeContext';
import { Icon, Modal, SelectorChip } from '../../../Components';

interface NonUserModalProps {
    onDismiss(): void;
    user?: Member;
}

const NonUserModal = ({ onDismiss, user }: NonUserModalProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    if (!user) {
        return <View />;
    }

    return (
        <Modal
            onDismiss={onDismiss}
            header={''}
            content={
                <>
                    <View style={themedStyle.main}>
                        <ExternalUserAvatar user={user} />
                        <Text style={themedStyle.nameText}>{user.fname + ' ' + user.lname}</Text>
                        <Text style={themedStyle.roleText}>{user.roles}</Text>
                        <View style={themedStyle.row}>
                            {user.phone ? (
                                <Text
                                    style={[
                                        themedStyle.detailsText,
                                        user.mail ? themedStyle.detailsTextLeft : undefined,
                                    ]}
                                    selectable
                                >
                                    {user.phone}
                                </Text>
                            ) : null}
                            {user.phone && user.mail ? <Text style={themedStyle.detailsText}>{'  •  '}</Text> : null}
                            {user.mail ? (
                                <Text
                                    selectable
                                    style={[
                                        themedStyle.detailsText,
                                        user.phone ? themedStyle.detailsTextRight : undefined,
                                    ]}
                                >
                                    {user.mail}
                                </Text>
                            ) : null}
                        </View>
                        <View style={themedStyle.row}>
                            {user.phone ? (
                                <View
                                    style={[
                                        themedStyle.actionWrapper,
                                        user.mail ? themedStyle.actionWrapperLeft : undefined,
                                    ]}
                                >
                                    <View style={themedStyle.center}>
                                        <TouchableOpacity
                                            onPress={() => openURL(`tel:${user.phone}`, t)}
                                            style={themedStyle.actionButton}
                                        >
                                            <Icon name="call" scale={2} color="white" />
                                        </TouchableOpacity>
                                        <Text style={themedStyle.actionText}>{t('board:call')}</Text>
                                    </View>
                                </View>
                            ) : null}

                            {user.mail ? (
                                <View
                                    style={[
                                        themedStyle.actionWrapper,
                                        user.phone ? themedStyle.actionWrapperRight : undefined,
                                    ]}
                                >
                                    <View style={themedStyle.center}>
                                        <TouchableOpacity
                                            onPress={() => {
                                                openURL(`mailto:${user.mail}`, t);
                                            }}
                                            style={themedStyle.actionButton}
                                        >
                                            <Icon name="mail" color="white" />
                                        </TouchableOpacity>

                                        <Text style={themedStyle.actionText}>{t('board:mail')}</Text>
                                    </View>
                                </View>
                            ) : null}
                        </View>
                    </View>
                    {user.tags.length > 0 ? (
                        <>
                            <Text style={themedStyle.titleText}>{t('board:boardResponsibility')}</Text>
                            <View style={themedStyle.chipsWrapper}>
                                {user.tags.map((tag) => {
                                    return (
                                        <SelectorChip
                                            variant="lightPrimary"
                                            key={tag}
                                            containerStyle={themedStyle.chip}
                                            title={tag}
                                        />
                                    );
                                })}
                            </View>
                        </>
                    ) : null}
                </>
            }
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: { justifyContent: 'center', alignItems: 'center' },
        row: {
            flexDirection: 'row',
            display: 'flex',
        },
        nameText: {
            fontFamily: _fonts.primaryFontBold,
            color: theme.black,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            fontSize: WW * 0.055,
        },
        roleText: {
            fontFamily: _fonts.primaryFont,
            color: theme.secondaryText,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            fontSize: WW * 0.045,
        },
        detailsText: {
            color: theme.darkGreen,
        },
        detailsTextLeft: {
            flex: 1,
            textAlign: 'right',
        },
        detailsTextRight: {
            flex: 1,
        },
        actionWrapper: {
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
        },
        actionWrapperLeft: {
            alignItems: 'flex-end',
        },
        center: { alignItems: 'center' },
        actionWrapperRight: {
            alignItems: 'flex-start',
        },
        actionButton: {
            paddingTop: WW * 0.03,
            paddingBottom: WW * 0.03,
            paddingLeft: WW * 0.03,
            paddingRight: WW * 0.03,
            borderRadius: WW * 0.2,
            backgroundColor: theme.main,
            minWidth: 24 + WW * 0.06,
            minHeight: 24 + WW * 0.06,
            alignItems: 'center',
            justifyContent: 'center',
        },
        actionText: {
            fontFamily: _fonts.primaryFont,
            color: theme.black,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            fontSize: smallestFontSize,
        },
        titleText: {
            fontFamily: _fonts.primaryFontBold,
            color: theme.black,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            fontSize: WW * 0.05,
        },
        chipsWrapper: {
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        chip: {
            marginBottom: WH * 0.01,
            backgroundColor: theme.lightGreen,
            borderColor: theme.lightGreen,
        },
    });

export default NonUserModal;
