import React, { ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { Activity } from '_api/useActivities';
import { useGetSignedupUsers } from '../../../../_api/useActivitySignedUp';
import _fonts from '../../../../_fonts';
import { WW, WH, useThemeStyle } from '../../../../_utils';
import { flattenIniniteResult, getUsernameFromProfile } from '../../../../_utils/misc';
import { screenMargin } from '../../../../_utils/sizes';
import { Theme, ThemeContext } from '../../../../_utils/themeContext';
import { Loader, UserListItem } from '../../../../Components';

interface AttendeesListProps {
    activity: Activity;
}

const AttendeesList = ({ activity }: AttendeesListProps): ReactElement | null => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const { data: signedUpUsers, isLoading, isError } = useGetSignedupUsers(activity.id, true);
    const flattenSignedUpUsers = useMemo(
        () => flattenIniniteResult(signedUpUsers).filter(({ approved_sum }) => approved_sum > 0),
        [signedUpUsers],
    );

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <Text>{t('activityDetail:errorLoadingAttendees')}</Text>;
    }

    if (flattenSignedUpUsers.length === 0 && activity.no_quantity) {
        return null;
    }

    return (
        <View style={themedStyle.bottomMargin}>
            <View style={themedStyle.row}>
                {activity.no_quantity ? (
                    <Text style={themedStyle.contentSubTitle}>
                        {t('activityDetail:attendees', { count: activity.approved_total_sum })}
                    </Text>
                ) : (
                    <>
                        <Text style={themedStyle.contentSubTitle}>{t('activityDetail:attendeesTitle')}</Text>
                        <Text style={themedStyle.attendeesCount}>{' ('}</Text>
                        <Text style={[themedStyle.attendeesCount, { color: theme.main }]}>
                            {activity.paymentStrategy.price
                                ? t('activityDetail:tickets', { count: activity.approved_total_sum ?? 0 })
                                : (activity.approved_total_sum ?? 0)}
                            {activity.not_approved_quantity > 0
                                ? ` + ${activity.not_approved_quantity} ${t('activityDetail:paying_now')}`
                                : ''}
                        </Text>
                        <Text style={themedStyle.attendeesCount}>
                            {` / ${activity.quantity + activity.approved_total_sum + activity.not_approved_quantity})`}
                        </Text>
                    </>
                )}
            </View>

            {flattenSignedUpUsers?.map((u) => (
                <UserListItem
                    key={u.id}
                    title={getUsernameFromProfile(u)}
                    desc={
                        activity.paymentStrategy.price
                            ? t('activityDetail:tickets', { count: u.approved_sum ?? 0 })
                            : t('activityDetail:signup', { quantity: u.approved_sum ?? 0 })
                    }
                    descColor={theme.secondaryText}
                    id={u.id}
                />
            ))}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        row: { flexDirection: 'row' },
        bottomMargin: { marginBottom: screenMargin * 2 },
        contentSubTitle: {
            marginTop: WH * 0.02,
            fontFamily: _fonts.primaryFontBold,
            color: theme.black,
            fontSize: WW * 0.045,
        },
        attendeesCount: {
            marginTop: WH * 0.02,
            fontFamily: _fonts.primaryFont,
            color: theme.secondaryText,
            fontSize: WW * 0.045,
        },
    });

export default AttendeesList;
