import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { screenMargin } from '_utils/sizes';
import { PrimaryButton, HeimeText } from 'Components';
import { useSettings } from '../../_api';
import { useAppNavigation, useContextMenuFocusEffect } from '../../_navigator';
import { openURL, Theme, useThemeStyle, WH, WW } from '../../_utils';
import { useBottomSpacer, useSelectedCoopItem } from '../../_utils/hooks';
import HeaderWithNav from '../../Components/HeaderWithNav';

const Contact = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const supportInfo = useSettings();
    const coopName = useSelectedCoopItem()?.name ?? '';
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';
    const { navigate } = useAppNavigation();

    useContextMenuFocusEffect(['reportsNew']);

    const handleMailClick = () => {
        openURL(`mailto:${supportInfo.data?.mail}?subject=Heime - ${coopName}`, t);
    };

    const handlePhoneClick = () => {
        openURL(`tel:${supportInfo.data?.phone}`, t);
    };

    const handleBoardPress = () => {
        navigate('Board', undefined);
    };

    const paddingBottom = useBottomSpacer();

    return (
        <>
            <HeaderWithNav style={themedStyle.header} title={t('contacts:title')} safeArea />
            <ScrollView
                bounces={false}
                contentContainerStyle={{ paddingBottom, paddingTop: screenMargin }}
                style={themedStyle.main}
            >
                <View style={themedStyle.center}>
                    <View style={[themedStyle.container, themedStyle.mainContainer]}>
                        <HeimeText maxFontSizeMultiplier={2} style={themedStyle.title}>
                            {t('contacts:messageTitle', {
                                boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                            })}
                        </HeimeText>
                        <PrimaryButton
                            style={themedStyle.boardButton}
                            text={t('contacts:boardButton', {
                                boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                            })}
                            onPress={handleBoardPress}
                        />
                        <HeimeText maxFontSizeMultiplier={2}>{t('contacts:messageLineOne')}</HeimeText>
                        <HeimeText maxFontSizeMultiplier={2}>{t('contacts:messageLineTwo')}</HeimeText>
                        <View style={themedStyle.container}>
                            <View style={themedStyle.infoContainer}>
                                <HeimeText maxFontSizeMultiplier={2} style={themedStyle.title}>
                                    {t('contacts:emailTitle')}{' '}
                                </HeimeText>
                                <TouchableOpacity onPress={handleMailClick} style={themedStyle.container}>
                                    <HeimeText maxFontSizeMultiplier={2} selectable style={themedStyle.hyperText}>
                                        {supportInfo.data?.mail}
                                    </HeimeText>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={themedStyle.container}>
                            <HeimeText maxFontSizeMultiplier={2}>{t('contacts:support')}</HeimeText>
                            <View style={themedStyle.infoContainer}>
                                <HeimeText maxFontSizeMultiplier={2} style={themedStyle.title}>
                                    {t('contacts:phoneTitle')}{' '}
                                </HeimeText>
                                <TouchableOpacity onPress={handlePhoneClick} style={themedStyle.container}>
                                    <HeimeText maxFontSizeMultiplier={2} selectable style={themedStyle.hyperText}>
                                        {supportInfo.data?.phone}
                                    </HeimeText>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.white,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
        },
        header: {
            backgroundColor: theme.shadow,
        },
        infoContainer: { flexDirection: 'row', flexWrap: 'wrap' },
        hyperText: {
            color: theme.hyperText,
            textDecorationLine: 'underline',
        },
        container: {
            display: 'flex',
            marginBottom: screenMargin,
        },
        mainContainer: {
            flexShrink: 0,
            backgroundColor: theme.shadow,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            paddingTop: WH * 0.02,
        },
        title: {
            fontWeight: 'bold',
        },
        boardButton: {
            marginBottom: WH * 0.02,
        },
        center: { display: 'flex', justifyContent: 'center', flexDirection: 'column', flex: 1 },
    });

export default Contact;
