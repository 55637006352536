import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FeedbackItem } from '.';
import { useGetRatings } from '../../../../../../_api/useRatings';
import { flattenIniniteResult } from '../../../../../../_utils/misc';
import { EmptyList, Loader, QueryItemView, QueryView } from '../../../../../../Components';
import { ArrayElement } from '../../../../../../types/Utility';

const FeedbackList = ({ userId }: { userId: number }): ReactElement => {
    const { t } = useTranslation();
    const { data, isLoading, fetchNextPage, isFetchingNextPage, refetch, isFetching } = useGetRatings(
        undefined,
        userId,
    );

    const ratings = useMemo(() => {
        const mapped = flattenIniniteResult(data);
        return mapped;
    }, [data]);

    const renderItem = ({ item }: { item: ArrayElement<typeof ratings>; index: number }) => {
        return <FeedbackItem created_at={item.created_at} creator={item.creator} text={item.text} />;
    };

    if (isLoading) {
        return <Loader />;
    }

    if (!ratings.length) {
        return (
            <QueryItemView onRefresh={refetch} isRefreshing={isFetching}>
                <EmptyList message={t('sharingSelected:helperProfile:noFeedback')} />
            </QueryItemView>
        );
    }

    return (
        <QueryView
            data={ratings}
            renderItem={renderItem}
            loadMore={fetchNextPage}
            isLoadingMore={isFetchingNextPage}
            onRefresh={refetch}
            isRefreshing={isFetching}
        />
    );
};
export default FeedbackList;
