import React, { ReactElement } from 'react';
import Icon from './Icon';
import { getUrlExtension } from '../../_utils';

interface FileIconProps {
    fileName: string;
}

const FileIcon = ({ fileName }: FileIconProps): ReactElement => {
    const fileFormat = getUrlExtension(fileName);
    const icon = fileFormat
        ? fileFormat === 'txt'
            ? 'txtFile'
            : fileFormat === 'pdf'
              ? 'pdfFile'
              : fileFormat === 'xml'
                ? 'xmlFile'
                : fileFormat === 'zip'
                  ? 'zipFile'
                  : 'docFile'
        : 'coloredFolder';
    return <Icon name={icon} color="black" />;
};

export default FileIcon;
