import { useTranslation } from 'react-i18next';
import { HeimeText, Modal } from 'Components';

const SkipActionModal = ({ onClose, onSkip }: { onClose(): void; onSkip(): void }) => {
    const { t } = useTranslation();
    return (
        <Modal
            header={t('procedure:skip_confirm')}
            onDismiss={onClose}
            content={<HeimeText variant="subtitle">{t('procedure:skip_confirm_text')}</HeimeText>}
            buttonsDirection="column"
            buttons={[
                {
                    text: t('procedure:skip_confirm_yes'),
                    onPress: onSkip,
                },
                {
                    text: t('procedure:skip_confirm_no'),
                    onPress: onClose,
                    type: 'secondary',
                },
            ]}
        />
    );
};

export default SkipActionModal;
