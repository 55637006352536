import Clipboard from '@react-native-clipboard/clipboard';
import { t as tImport } from 'i18next';
import mdurl from 'mdurl';
import { Linking, Platform } from 'react-native';
import { isAndroid, isiOS, showToast } from '_utils';
import { captureException } from './Sentry';

const otherSchemes = ['tel:', 'mailto:', 'telprompt:'];
const regex = new RegExp(otherSchemes.join('|'), 'g');

const openURL = async (link: string, t: typeof tImport, copyOnError = true): Promise<boolean> => {
    const urlObject = mdurl.parse(link);

    urlObject.protocol = (urlObject.protocol ?? 'http://').toLowerCase();
    const isOtherScheme = otherSchemes.includes(urlObject.protocol);
    const normalizedURL = isOtherScheme ? mdurl.encode(link) : mdurl.format(urlObject);

    // Ios starts returning false for canOpenURL after a few calls, so we just assume it's supported to avoid having to think about this. Android apiLevel 30 and above also needs us to specify the scheme in the manifest. That does not make sense for us to do.
    const supported =
        isiOS() || (isAndroid() && parseInt(Platform.Version + '', 10) >= 30)
            ? true
            : await Linking.canOpenURL(normalizedURL);

    if (supported) {
        try {
            await Linking.openURL(normalizedURL);
            return true;
        } catch (error) {
            captureException(error);
        }
    }
    captureException(new Error(`Linking.openURL: ${normalizedURL} is not supported`));
    if (!copyOnError) {
        return false;
    }

    const showableUrl = isOtherScheme ? link.replace(regex, '') : normalizedURL;
    showToast({
        type: 'error',
        header: isOtherScheme ? t('global:open_contact_error_header') : t('global:open_url_error_header'),
        text: isOtherScheme
            ? t('global:open_contact_error_text', {
                  url: showableUrl,
              })
            : t('global:open_url_error_text', {
                  url: showableUrl,
              }),
        visibilityTime: 10000,
        position: 'bottom',
    });
    Clipboard.setString(showableUrl);
    return false;
};

export { openURL };
