import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import { CacheImage, PrimaryButton } from '.';
import errorImage from '../../resources/images/sharingWall.png';
import { useThemeStyle, WH, WW, Theme, isAppError, isNetworkError } from '../_utils';

interface GlobalErrorScreenProps {
    header?: ReactElement;
    button?: { text: string; onPress(): void };
    error: Error;
}

const GenericErrorScreen = ({ header, button, error }: GlobalErrorScreenProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    const isNetworkProblem = isAppError(error) && isNetworkError(error);
    return (
        <ScrollView
            alwaysBounceVertical={false}
            style={themedStyle.container}
            contentContainerStyle={themedStyle.contentContainer}
        >
            {header}
            <CacheImage resizeMode={'contain'} source={errorImage} style={themedStyle.errorImage} />
            <View style={themedStyle.textContainer}>
                <Text style={themedStyle.mainText}>
                    {isNetworkProblem ? t('globalError:networkHeader') : t('globalError:header')}
                </Text>
                <Text style={themedStyle.subText}>
                    {isNetworkProblem ? t('globalError:subTextNetwork') : t('globalError:subText')}
                </Text>
            </View>
            {button ? <PrimaryButton style={themedStyle.button} onPress={button.onPress} text={button.text} /> : null}
        </ScrollView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            height: '100%',
            flex: 1,
        },
        contentContainer: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        },
        errorImage: {
            width: '100%',
            height: WH * 0.3,
            minWidth: '100%',
            aspectRatio: 1,
        },

        textContainer: {
            flex: 1,
            paddingBottom: 0.3 * WW,
            maxWidth: 0.8 * WW,
            justifyContent: 'center',
        },
        mainText: {
            color: theme.black,
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 12,
        },
        subText: {
            color: theme.secondaryText,
            textAlign: 'center',
            fontSize: 16,
        },
        button: {
            marginBottom: 16,
            marginLeft: 16,
            marginRight: 16,
            marginTop: 16,
            width: WW - 16 * 2,
        },
    });

export default GenericErrorScreen;
