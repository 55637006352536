import React, { ReactElement, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { screenMargin, subtitleFontSize } from '_utils/sizes';
import { HeimeText, Modal } from 'Components';
import { Product } from 'types/Category';
import { UnixTimeCode } from 'types/Utility';

type CancelReservationModalProps = {
    onDismiss(): void;
    onCancel(): void;
    start_time: UnixTimeCode;
    isShortTerm: boolean;
    hasPaid: boolean;
} & Pick<Product, 'refundable' | 'refund_hours_before'>;

const CancelReservationModal = ({
    onDismiss,
    onCancel,
    refundable,
    refund_hours_before,
    start_time,
    isShortTerm,
    hasPaid,
}: CancelReservationModalProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const [isCancelling, setIsCancelling] = useState(false);

    const handleCancel = async () => {
        setIsCancelling(true);
        onCancel();
    };

    const reservationIsRefundable = refundable
        ? refund_hours_before === 0
            ? true
            : moment.unix(start_time).diff(moment(), 'hours') >= refund_hours_before
              ? true
              : false
        : false;

    return (
        <Modal
            onDismiss={onDismiss}
            header={t('expandedReservation:cancelModal:header')}
            content={
                hasPaid ? (
                    <HeimeText style={themedStyle.description}>
                        {reservationIsRefundable
                            ? t('expandedReservation:cancelModal:refundable')
                            : refundable
                              ? isShortTerm
                                  ? t('expandedReservation:cancelModal:nonRefundableHours', {
                                        hours: t('expandedReservation:cancelModal:hours', {
                                            count: refund_hours_before,
                                        }),
                                    })
                                  : refund_hours_before % 24 !== 0
                                    ? t('expandedReservation:cancelModal:nonRefundableDaysHours', {
                                          days: t('expandedReservation:cancelModal:days', {
                                              count: Math.floor(refund_hours_before / 24),
                                          }),
                                          hours: t('expandedReservation:cancelModal:hours', {
                                              count: refund_hours_before % 24,
                                          }),
                                      })
                                    : t('expandedReservation:cancelModal:nonRefundableDays', {
                                          days: t('expandedReservation:cancelModal:days', {
                                              count: Math.floor(refund_hours_before / 24),
                                          }),
                                      })
                              : t('expandedReservation:cancelModal:nonRefundable')}
                    </HeimeText>
                ) : undefined
            }
            buttons={[
                { text: t('expandedReservation:cancelModal:back'), onPress: onDismiss },
                {
                    text: t('expandedReservation:cancelModal:approve'),
                    onPress: handleCancel,
                    type: 'danger',
                    isLoading: isCancelling,
                },
            ]}
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        description: {
            fontSize: subtitleFontSize,
            color: theme.secondaryText,
            textAlign: 'center',
            marginBottom: screenMargin,
        },
    });

export default CancelReservationModal;
