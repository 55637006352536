import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { Survey } from '_api/useSurveys';
import { Theme, useThemeStyle } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { CheckBox, HeimeText, RadioButton } from 'Components';

const OptionsView = ({
    type,
    options,
    selectedOptions,
    onChange,
}: {
    type: Survey['questions'][number]['type'];
    options: { id: string; value: string }[];
    selectedOptions: string[];
    onChange(values: string[]): void;
}): ReactElement | null => {
    const themedStyle = useThemeStyle(styles);

    switch (type) {
        case 'CHECKBOX':
            return (
                <View style={themedStyle.optionsContainer}>
                    {options.map(({ id, value: optionValue }) => {
                        const checked = selectedOptions.includes(id);
                        const handlePress = () => {
                            if (checked) {
                                onChange(selectedOptions.filter((selValue) => selValue !== id));
                            } else {
                                onChange([...selectedOptions, id]);
                            }
                        };
                        return (
                            <CheckBox
                                key={id}
                                title={optionValue}
                                checked={checked}
                                titleStyle={themedStyle.optionTitle}
                                style={themedStyle.option}
                                onPress={handlePress}
                            />
                        );
                    })}
                </View>
            );
        case 'RADIO':
            return (
                <View style={themedStyle.optionsContainer}>
                    {options.map(({ id, value: optionValue }) => {
                        const checked = selectedOptions.includes(id);
                        const handlePress = () => {
                            onChange([id]);
                        };
                        return (
                            <RadioButton
                                key={id}
                                title={optionValue}
                                onPress={handlePress}
                                checked={checked}
                                style={themedStyle.option}
                            />
                        );
                    })}
                </View>
            );
        default:
            return (
                <View style={themedStyle.optionsContainer}>
                    {options.map(({ id, value: optionValue }) => (
                        <HeimeText key={id} style={themedStyle.option}>
                            {optionValue}
                        </HeimeText>
                    ))}
                </View>
            );
    }
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        optionsContainer: { marginTop: screenMargin },
        optionTitle: { fontSize: subtitleFontSize, color: theme.black },
        option: { marginBottom: smallestMargin },
    });

export default OptionsView;
