import React, { ReactElement, useMemo, useContext, useEffect } from 'react';
import moment from 'moment';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import { UnixTimeCode } from 'types/Utility';
import LongTermActiveBookingItem from './LongTermActiveBookingItem';
import ShortTermActiveBookingItem from './ShortTermActiveBookingItem';
import { useGetProductReservations } from '../../../../_api/useReservations';
import { useThemeStyle } from '../../../../_utils';
import { capitalizeFirstLetter, flattenIniniteResult } from '../../../../_utils/misc';
import { subtitleFontSize, screenMargin } from '../../../../_utils/sizes';
import { Theme, ThemeContext } from '../../../../_utils/themeContext';
import { Loader, QuerySectionedView } from '../../../../Components';
import { Reservation } from '../../../../types/Reservation';

interface ActiveBookingsViewProps {
    productId: number;
    capacity: number;
    isShortTerm: boolean;
    checkinTime: string;
    onDateSelect(date: Date | null): void;
    loadReservationsFrom: UnixTimeCode;
    onRefresh(): void;
    isRefreshing: boolean;
    children?: ReactElement;
}

const ActiveBookingsView = ({
    productId,
    capacity,
    isShortTerm,
    checkinTime,
    onDateSelect,
    loadReservationsFrom,
    children,
    onRefresh,
    isRefreshing,
}: ActiveBookingsViewProps): ReactElement => {
    const themeStyles = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const maxDate = useMemo(() => moment().startOf('day').add({ months: 18 }), []);
    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        refetch: refetchProductReservations,
        isRefetching: isRefetchingProductReservations,
    } = useGetProductReservations(productId, moment.unix(loadReservationsFrom));

    const sectionedProductBookings = useMemo(() => {
        const flattenedBookings = flattenIniniteResult(data);
        return Array.from({ length: Math.abs(moment().diff(maxDate, 'months', false)) }).map((_, i) => {
            const month = moment().add({ months: i });
            const nDays = moment(month).isSame(moment(), 'month')
                ? month.daysInMonth() - moment().date() + 1
                : month.daysInMonth();

            const startMonth = moment(month).startOf('month');
            const endMonth = moment(month).endOf('month');
            const bookingInMonth = flattenedBookings.filter(
                ({ start_at, end_at }) =>
                    (moment.unix(start_at).isBefore(startMonth) && moment.unix(end_at).isAfter(endMonth)) ||
                    moment.unix(start_at).isBetween(startMonth, endMonth) ||
                    moment.unix(end_at).isBetween(startMonth, endMonth),
            );

            return {
                title: month.format(),
                data: Array.from({ length: nDays }).map((_d, dayI) => {
                    const day = month.isSame(moment(), 'month')
                        ? moment(month).set({ date: dayI + moment().date() })
                        : moment(month).set({ date: dayI + 1 });
                    const start = moment(day).isSame(moment(), 'date') ? moment() : moment(day).startOf('date');
                    const end = moment(day).endOf('date');

                    return {
                        day: day.toDate(),
                        id: day.unix(),
                        bookings: bookingInMonth.filter(
                            ({ start_at, end_at }) =>
                                (moment.unix(start_at).isBefore(start) && moment.unix(end_at).isAfter(end)) ||
                                moment.unix(start_at).isBetween(start, end) ||
                                moment.unix(end_at).isBetween(start, end),
                        ) as Reservation[],
                    };
                }),
                id: month.unix(),
            };
        });
    }, [data, maxDate]);

    const renderTitle = ({ title }: { title: string }) => {
        const month = moment(title);

        const text = moment().isSame(month, 'year') ? month.format('MMMM') : month.format('MMMM YYYY');
        return (
            <View key={title} style={themeStyles.monthRow}>
                <Text style={themeStyles.monthText}>{capitalizeFirstLetter(text)}</Text>
            </View>
        );
    };

    const renderItem = ({ item }: { item: { day: Date; bookings: Reservation[] } }) => {
        const handleDateSelect = () => onDateSelect(item.day);
        if (isShortTerm) {
            return (
                <ShortTermActiveBookingItem
                    key={item.day.getTime()}
                    day={item.day}
                    bookings={item.bookings}
                    onDateSelect={handleDateSelect}
                    capacity={capacity}
                />
            );
        }
        const parsedCheckin = checkinTime.split(':');

        return (
            <LongTermActiveBookingItem
                key={item.day.getTime()}
                day={item.day}
                bookings={item.bookings}
                onDateSelect={handleDateSelect}
                capacity={capacity}
                checkInTime={moment(item.day)
                    .set({
                        hours: parseInt(parsedCheckin[0], 10),
                        minutes: parseInt(parsedCheckin[1], 10),
                        second: 0,
                        millisecond: 0,
                    })
                    .toDate()}
            />
        );
    };

    useEffect(() => {
        if (!isFetchingNextPage) {
            if (hasNextPage) {
                fetchNextPage();
            }
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

    const footerComponent = useMemo(() => {
        if (isFetchingNextPage) {
            return <ActivityIndicator color={theme.black} />;
        } else {
            return <View />;
        }
    }, [isFetchingNextPage, theme.black]);

    const isRefreshingAny = isRefreshing || isRefetchingProductReservations;
    const refetch = () => {
        onRefresh();
        refetchProductReservations();
    };

    return (
        <QuerySectionedView<{
            day: Date;
            id: number;
            bookings: Reservation[];
        }>
            top={children}
            sections={sectionedProductBookings}
            renderItem={renderItem}
            header={renderTitle}
            emptyComponent={isLoading ? <Loader /> : undefined}
            isLoadingMore={isFetchingNextPage}
            bottom={footerComponent}
            onRefresh={refetch}
            isRefreshing={isRefreshingAny}
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        monthText: {
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
        },
        monthRow: {
            backgroundColor: theme.mainBackground,
        },
    });

export default ActiveBookingsView;
