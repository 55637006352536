import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useGetAccessTokenHeader } from '../_utils/Axios';

interface InfoDTO {
    title?: string;
    content?: string;
    id: number;
}

const useGDPRInfo = (): UseQueryResult<InfoDTO, string | Error | AxiosError> => {
    const getAuthHeader = useGetAccessTokenHeader();

    return useQuery({
        queryKey: ['GDPRInfo'],

        queryFn: async () => {
            const result = await axios.get<InfoDTO>('info/gdpr', {
                headers: { authorization: await getAuthHeader() },
            });
            if (!result.data) {
                throw new Error('Result returned with no data');
            }
            return result.data;
        },

        gcTime: Infinity,
        staleTime: 1000 * 60 * 60 * 24 * 7,
    });
};

export { useGDPRInfo };
