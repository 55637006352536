import {
    AppRegistry,
    Text,
    Platform,
    TextInput,
    UIManager,
    TouchableOpacity,
    TouchableHighlight,
    LogBox,
} from 'react-native';
import App from './App';
import { name as appName } from './app.json';
import { LaunchArguments } from './src/_dependencies/launchArguments';
import { isWeb, isAndroid } from './src/_utils';
import { initializeAmplitude } from './src/_utils/Amplitude';
import { initializeAxios } from './src/_utils/Axios';
import { initializeSentry, withSentry } from './src/_utils/Sentry';
Text.defaultProps = {
    ...Text.defaultProps,
    maxFontSizeMultiplier: 1.2,
};

TextInput.defaultProps = {
    ...TextInput.defaultProps,
    // ANDROID BUG MAKING KEYBOARD UNRESPONSIVE
    autoCorrect: !isAndroid() || Platform.Version < 31,
    // keyboardType: isAndroid() && Platform.Version > 30 ? 'visible-password' : 'default',
    returnKeyType: isAndroid() && Platform.Version > 30 ? 'none' : 'default',
};

TouchableOpacity.defaultProps = {
    accessibilityRole: 'button',
    ...TouchableOpacity.defaultProps,
};

TouchableHighlight.defaultProps = {
    accessibilityRole: 'button',
    ...TouchableHighlight.defaultProps,
};

if (LaunchArguments.value().disableLogBox) {
    LogBox.ignoreAllLogs();
}

if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
}

initializeSentry();
initializeAxios();
initializeAmplitude();

AppRegistry.registerComponent(appName, () => withSentry(App));
if (isWeb()) {
    AppRegistry.runApplication(appName, {
        rootTag: document.getElementById('root'),
    });
}
