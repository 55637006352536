import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { z } from 'zod';
import ReportStatus from './ReportStatus';
import { StatusType, useSetSupportStatus } from '../../../_api/useSupports';
import _fonts from '../../../_fonts';
import { enumToArray, showToast, Theme, useThemeStyle, WW } from '../../../_utils';
import { subtitleFontSize } from '../../../_utils/sizes';
import { Modal, RadioButton } from '../../../Components';

const schema = z.object({
    message: z.string(),
});

export type FormValues = z.infer<typeof schema>;

interface ChangeStatusModalProps {
    onDismiss(): void;
    supportId: number;
    currentStatus: StatusType;
}

const ChangeStatusModal = ({ onDismiss, supportId, currentStatus }: ChangeStatusModalProps): ReactElement => {
    const { t } = useTranslation();
    const { mutate: editStatus, isPending } = useSetSupportStatus();
    const themedStyle = useThemeStyle(styles);
    const [status, setStatus] = useState<StatusType>(currentStatus);

    const handleConfirmClick = () => {
        editStatus([supportId, { status }], {
            onSuccess: () => {
                onDismiss();
            },
            onError: () => {
                showToast({
                    type: 'error',
                    header: t('reportSelected:changeStatusModal:errorUpdating'),
                    text: '',
                });
            },
        });
    };

    return (
        <Modal
            onDismiss={onDismiss}
            header={t('reportSelected:changeStatusModal:changeStatus')}
            content={
                <>
                    {enumToArray(StatusType).map((statusType) => (
                        <RadioButton
                            key={statusType}
                            style={themedStyle.check}
                            checked={StatusType[statusType] === status}
                            onPress={() => setStatus(StatusType[statusType])}
                        >
                            <ReportStatus large circle={false} status={StatusType[statusType]} />
                        </RadioButton>
                    ))}
                </>
            }
            buttons={[
                {
                    isLoading: isPending,
                    text: t('reportSelected:changeStatusModal:save').toUpperCase(),
                    onPress: handleConfirmClick,
                },
            ]}
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            paddingLeft: WW * 0.08,
            paddingRight: WW * 0.08,
        },
        titleText: {
            color: theme.darkGray,
            flex: 1,
            fontFamily: _fonts.primaryFont,
            fontSize: subtitleFontSize,
        },
        iconContainer: {
            paddingRight: WW * 0.02,
            paddingLeft: WW * 0.02,
        },
        check: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            backgroundColor: theme.mainBackground,
        },
    });

export default ChangeStatusModal;
