import { z } from 'zod';
import { FileImageSchema } from './Base';

const BaseAction = z.object({
    id: z.string(),
});

const SimpleAction = z.object({
    type: z.union([z.literal('OPEN_DOOR'), z.literal('APPROVE_CLEANING')]),
});

const InformationAction = z.object({
    type: z.literal('INFORMATION'),
    description: z.string().nullable(),
});

const ChecklistAction = z.object({
    type: z.literal('CHECKLIST'),
    description: z.string().nullable(),
    options: z.array(z.object({ id: z.string(), value: z.string() })),
});

const PicturesAction = z.object({
    type: z.literal('PICTURES'),
    options: z.array(z.object({ id: z.string(), value: z.string() })),
});

export const ProcedureSchema = z.object({
    id: z.string(),
    type: z.union([z.literal('check_in'), z.literal('check_out')]),
    actions: z.array(
        z.union([
            BaseAction.merge(SimpleAction),
            BaseAction.merge(InformationAction),
            BaseAction.merge(ChecklistAction),
            BaseAction.merge(PicturesAction),
        ]),
    ),
});

export type Procedure = z.infer<typeof ProcedureSchema>;

export const ProcedureInstanceSchema = z.object({
    id: z.string(),
    booking_id: z.number(),
    status: z.union([z.literal('STARTED'), z.literal('COMPLETED')]),

    answers: z.array(
        z.object({
            id: z.string(),
            action_id: z.string(),
            value: z.string().nullable(),
            options: z.array(
                z.object({
                    id: z.string(),
                    procedure_instance_action_answer_id: z.string(),
                    procedure_action_options_id: z.string(),
                    file: FileImageSchema.nullable(),
                }),
            ),
        }),
    ),
});

export type ProcedureInstance = z.infer<typeof ProcedureInstanceSchema>;
