import { useSelectedCoopItem } from '_utils/hooks';

// We got some feedback from the customer her, so we are doing this to be nice to them
const useForceVariant = () => {
    const selectedCoop = useSelectedCoopItem();

    // Note these are production IDs
    return (
        [29, 35, 36].includes(selectedCoop?.id ?? 0) ||
        selectedCoop?.cooperative_groups.some((group) => group.type === 'coopGroup' && group.id === 5)
    );
};

export default useForceVariant;
