import React, { ReactElement, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, ScrollView } from 'react-native';
import { useAppNavigation } from '_navigator';
import { isWeb, Theme, useThemeStyle, WH } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize, subtitleLineHeight, titleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { FullWidthPictureCarousel, HeimeText, Icon, PrimaryButton, SecondaryButton } from 'Components';
import { useProcedureContext } from '../ProcedureContext';
import { useProcedureNavigation } from '../ProcedureNavigation';

const StartProcedure = (): ReactElement => {
    const { openProcedure, context } = useProcedureContext();
    const { t } = useTranslation();

    //const { t } = useTranslation();
    const themedStyle = useThemeStyle(styles);
    const { goBack } = useAppNavigation();
    const { navigate } = useProcedureNavigation();

    useFocusEffect(
        useCallback(() => {
            openProcedure(goBack);
        }, [goBack, openProcedure]),
    );

    const handleStart = () => {
        navigate('ProcedureAction', { actionIndex: 0, reservationId: 1 + '' });
    };

    const isCheckin = context.procedure.type === 'check_in';

    return (
        <View style={themedStyle.main}>
            <ScrollView
                bounces={false}
                style={{ flex: 1 }}
                contentContainerStyle={{ flexGrow: 1, justifyContent: 'flex-start' }}
            >
                <View style={{ flex: isWeb() ? undefined : 0 }}>
                    <FullWidthPictureCarousel
                        disablePicturePress
                        pictures={context.productImages}
                        height={WH * (isCheckin ? 0.3 : 0.15)}
                    />
                </View>
                <View
                    style={{
                        marginTop: smallestMargin,
                        paddingLeft: screenMargin,
                        paddingRight: screenMargin,
                        flex: 1,
                    }}
                >
                    <HeimeText maxFontSizeMultiplier={2} style={themedStyle.itemTitle}>
                        {t(isCheckin ? 'procedure:start_check_in_title' : 'procedure:start_check_out_title', {
                            name: context.productName,
                        })}
                    </HeimeText>
                    <HeimeText maxFontSizeMultiplier={2} style={themedStyle.itemDescription}>
                        {t('procedure:start_procedure_explainer', { steps: context.procedure.actions.length })}
                    </HeimeText>
                    {!isCheckin ? (
                        <>
                            <HeimeText maxFontSizeMultiplier={2} style={themedStyle.itemDescription}>
                                {t('procedure:start_procedure_check_out_explainer')}
                            </HeimeText>
                            <View style={{ flex: 1 }} />
                            <WarningMessage description={t('procedure:start_procedure_check_out_warning')} />
                        </>
                    ) : null}
                </View>
            </ScrollView>
            <View style={themedStyle.buttonContainer}>
                <PrimaryButton
                    text={isCheckin ? t('procedure:start_check_in_action') : t('procedure:start_check_out_action')}
                    onPress={handleStart}
                />
                <SecondaryButton text={t('procedure:later')} onPress={goBack} />
            </View>
        </View>
    );
};

const WarningMessage = ({ description }: { description: string }) => {
    const { theme } = useThemeContext();

    return (
        <View
            style={{
                width: '100%',
                borderWidth: 1,
                borderColor: theme.secondaryLight,
                backgroundColor: theme.mediumBackground,
                borderRadius: 10,
                paddingRight: smallestMargin,
                paddingLeft: smallestMargin,
                paddingTop: smallestMargin,
                paddingBottom: smallestMargin,
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: smallestMargin,
                marginBottom: smallestMargin,
                marginTop: smallestMargin,
                alignItems: 'center',
            }}
        >
            <Icon name="alertTriangle" color="darkGray" style={{ width: 24, height: 24 }} />
            <HeimeText
                style={{
                    fontSize: subtitleFontSize,
                    color: theme.darkGray,
                    flex: 1,
                }}
            >
                {description}
            </HeimeText>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.lightBackground,
            width: '100%',
            flex: 1,
            display: 'flex',
            paddingBottom: screenMargin,
        },
        itemTitle: {
            fontSize: titleFontSize,
            color: theme.main,
            fontWeight: 'bold',
            marginBottom: smallestMargin,
        },
        itemDescription: {
            fontSize: subtitleFontSize,
            lineHeight: subtitleLineHeight,
            color: theme.black,
            marginBottom: smallestMargin,
        },
        buttonContainer: {
            justifyContent: 'flex-end',
            marginBottom: screenMargin,
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            gap: smallestMargin,
        },
    });

export default StartProcedure;
