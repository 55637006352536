import { useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { useGetProductReservations } from '_api/useReservations';
import { isBookingInSlot } from '_utils';
import { flattenIniniteResult } from '_utils/misc';
import { BookedModal } from './components';

const useBookedModal = (productId: number, reservationsFromDate: Moment) => {
    const [bookedModalOpen, setBookedModalOpen] = useState<null | { from: Date; to: Date }>(null);

    const { data: bookings } = useGetProductReservations(productId, reservationsFromDate);

    const open = (arg: { from: Date; to: Date } | null) => {
        setBookedModalOpen(arg);
    };

    const bookedModalReservations = useMemo(() => {
        if (!bookedModalOpen) {
            return null;
        }

        return flattenIniniteResult(bookings).filter(
            isBookingInSlot(moment(bookedModalOpen.from).unix(), moment(bookedModalOpen.to).unix()),
        );
    }, [bookedModalOpen, bookings]);
    const close = () => open(null);

    return [
        bookedModalReservations !== null ? <BookedModal onDismiss={close} bookings={bookedModalReservations} /> : null,
        open,
    ] as const;
};

export default useBookedModal;
