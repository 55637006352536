import { useIsFocused } from '@react-navigation/native';
import { InfiniteData, UseInfiniteQueryResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { ApiImage, FileImage } from 'types/Base';
import { useLaravelInfinteQuery } from './utility';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import { LaravelPagingResponse } from '../types/Utility';

export interface ContactGroup {
    id: number;
    name: string;
    members: Member[];
}

export interface Member {
    type: 'user' | 'external';
    id: number;
    roles: string;
    fname: string;
    lname: string;
    phone: string;
    mail: string;
    avatar: ApiImage | FileImage | null;
    tags: string[];
}

const useGetContactGroups = (): UseInfiniteQueryResult<InfiniteData<ContactGroup[]>, string | Error> => {
    const isFocused = useIsFocused();
    const selectedCoopId = useSelectedCoop();
    return useLaravelInfinteQuery(
        ['contact_groups', selectedCoopId],
        async ({ pageParam = 1, getAuthHeader }) =>
            await axios.get<LaravelPagingResponse<ContactGroup[]>>(
                `cooperatives/${selectedCoopId}/contact_user_groups`,
                {
                    headers: { authorization: await getAuthHeader() },
                    params: { page: pageParam },
                },
            ),
        {
            gcTime: Infinity,
            staleTime: 1000 * 60 * 5,
            enabled: isFocused,
        },
    );
};

const useGetContactGroup = (groupId: number): UseQueryResult<ContactGroup, string | Error> => {
    const isFocused = useIsFocused();
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: ['contact_group', selectedCoopId, groupId],

        queryFn: async () => {
            const result = await axios.get<ContactGroup>(
                `cooperatives/${selectedCoopId}/contact_user_groups/${groupId}`,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data) {
                throw new Error('Result returned with no data');
            }

            return result.data;
        },

        gcTime: 1000 * 60 * 60,
        staleTime: 1000 * 60 * 1,

        initialData: () => {
            const relatedQueries = queryClient
                .getQueryCache()
                .getAll()
                .filter(
                    (item) =>
                        !item.isStale() &&
                        item.queryKey?.[0] === 'contact_groups' &&
                        item.queryKey?.[1] === selectedCoopId,
                );

            let result: ContactGroup | undefined;
            relatedQueries?.forEach((query) =>
                (query.state.data as InfiniteData<LaravelPagingResponse<ContactGroup[]>>)?.pages?.forEach(({ data }) =>
                    data?.forEach((group: ContactGroup) => {
                        if (groupId === group.id) {
                            result = group;
                        }
                    }),
                ),
            );
            return result;
        },

        initialDataUpdatedAt: moment().subtract({ minutes: 2 }).valueOf(),
        enabled: isFocused,
    });
};

export { useGetContactGroups, useGetContactGroup };
