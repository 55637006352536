import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import MainLogo from './MainLogo';
import { Theme, useThemeStyle } from '../_utils';

const SimpleHeader = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const insets = useSafeAreaInsets();
    return (
        <View style={[themedStyle.header, insets.top ? { paddingTop: insets.top } : undefined]}>
            <MainLogo />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        header: {
            backgroundColor: theme.shadow,
            paddingLeft: 24,
            paddingRight: 10,
            paddingBottom: 10,
            paddingTop: 10,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexGrow: 0,
        },
    });

export default SimpleHeader;
