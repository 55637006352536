import {
    InfiniteData,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { Moment } from 'moment';
import { invalidateUsersQuery } from './useUsers';
import { useLaravelInfinteQuery } from './utility';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import { LaravelPagingResponse, UnixTimeCode, GenericCollectionItem } from '../types/Utility';

export interface Rating {
    id: number;
    created_at: UnixTimeCode;
    own: boolean;
    creator: GenericCollectionItem;
    user: GenericCollectionItem;
    text: string;
}

const useGetRatings = (
    creator?: number,
    for_user?: number,
    from?: Moment,
    to?: Moment,
): UseInfiniteQueryResult<InfiniteData<Rating[]>, string | Error> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();

    return useLaravelInfinteQuery(
        ['ratings', selectedCoopId, for_user, creator],
        async ({ pageParam = 1 }) => {
            return await axios.get<LaravelPagingResponse<Rating[]>>(`cooperatives/${selectedCoopId}/ratings`, {
                headers: { authorization: await getAuthHeader() },
                params: {
                    page: pageParam,
                    creator: creator,
                    for_user: for_user,
                    from: from ? from.unix() : undefined,
                    to: to ? to.unix() : undefined,
                },
            });
        },
        {
            gcTime: 1000 * 60 * 60 * 24 * 7,
            staleTime: 1000 * 60,
        },
    );
};

interface MutateRatingBody {
    text?: string;
    user_id: number;
}

const useCreateRating = (): UseMutationResult<{ success: number }, string | Error, MutateRatingBody> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (rating: MutateRatingBody) => {
            const result = await axios.post<{ success: number }>(`cooperatives/${selectedCoopId}/ratings`, rating, {
                headers: { authorization: await getAuthHeader() },
            });

            if (!result.data.success) {
                throw new Error('rating creation return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['ratings'],
            });
            invalidateUsersQuery(queryClient);
        },
    });
};

const useDeleteRating = (): UseMutationResult<{ success: boolean }, string | Error, number> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (ratingId: number) => {
            const result = await axios.delete<{ success: boolean }>(
                `cooperatives/${selectedCoopId}/ratings/${ratingId}`,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('rating deletetation return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['ratings'],
            });
            invalidateUsersQuery(queryClient);
        },
    });
};

export { useGetRatings, useCreateRating, useDeleteRating };
