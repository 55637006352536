import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, StyleSheet, TouchableHighlight } from 'react-native';
import _fonts from '_fonts';
import { useAppNavigation } from '_navigator';
import { useThemeStyle, WH } from '_utils';
import { screenMargin, subtitleFontSize } from '_utils/sizes';
import { Theme, ThemeContext } from '_utils/themeContext';
import { Icon } from 'Components';

interface GoToGroupProps {
    groupId: number;
}

const GoToGroup = ({ groupId }: GoToGroupProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();

    const handlePress = () => {
        navigate('GroupSelected', { groupId });
    };

    return (
        <TouchableHighlight onPress={handlePress} style={themedStyle.touchableHiglight} underlayColor={theme.main}>
            <>
                <Text style={themedStyle.text}>{t('chat:goToGroup')}</Text>
                <Icon name="rightArrow" color="black" />
            </>
        </TouchableHighlight>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        touchableHiglight: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            paddingTop: WH * 0.01,
            paddingBottom: WH * 0.01,
            backgroundColor: theme.lightGreen,
        },
        text: {
            fontFamily: _fonts.primaryFont,
            fontSize: subtitleFontSize,
        },
    });

export default GoToGroup;
