import { ReactElement } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { useAppNavigation } from '_navigator';
import { ScreenName, ScreenComponentProp } from '_navigator/navigationConfiguration/types';
import { Theme, useThemeStyle } from '_utils';
import { smallestMargin } from '_utils/sizes';
import { UnreadBadge } from 'Components';
import HeimeText from 'Components/HeimeText';
import Icon from 'Components/Icon/Icon';
import { IconNames } from 'types/icons';

interface SmallNavButtonProps<Key extends ScreenName> {
    title: string;
    navigation: Key;
    params: ScreenComponentProp<Key>;
    icon: IconNames;
    unreadCount?: number;
}

const SmallNavButton = <Key extends ScreenName>({
    title,
    navigation,
    icon,
    params,
    unreadCount,
}: SmallNavButtonProps<Key>): ReactElement => {
    const { navigate } = useAppNavigation();
    const themedStyle = useThemeStyle(styles);

    return (
        <TouchableOpacity style={themedStyle.container} onPress={() => navigate(navigation, params)}>
            <View style={themedStyle.button}>
                <Icon name={icon} color="main" />
                {unreadCount ? <UnreadBadge variant="small" top={0} right={0} count={unreadCount} /> : null}
            </View>
            <HeimeText style={themedStyle.text}>{title}</HeimeText>
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: 72,
            height: 72,
            alignItems: 'center',
        },
        button: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 55,
            height: 55,
            backgroundColor: theme.mainBackground,
            borderRadius: 12,
            marginBottom: smallestMargin,
        },
        text: {
            color: theme.black,
            textAlign: 'center',
            fontSize: 12,
            width: '100%',
        },
    });

export default SmallNavButton;
