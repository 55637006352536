import React, { ComponentProps, ReactElement, useMemo } from 'react';
import { View, StyleSheet, Text, TouchableWithoutFeedback } from 'react-native';
import { ApiImage } from 'types/Base';
import useUpdatedFormatedTimeAgo from './useUpdatedFormatedTimeAgo';
import { useCoops } from '../../_api/useCoops';
import _fonts from '../../_fonts';
import Images from '../../_images';
import { Theme, useGlobalState, useThemeStyle, WH, WW } from '../../_utils';
import { smallestFontSize, subtitleFontSize } from '../../_utils/sizes';
import { CacheImage, HeimeText, PrimaryButton } from '../../Components';
import { CacheImageProps } from '../../Components/CacheImage';
import { UnixTimeCode } from '../../types/Utility';

interface NotificationItemProps {
    image?: ApiImage;
    title: string;
    content?: string;
    createdAt: UnixTimeCode;
    button?: Omit<ComponentProps<typeof PrimaryButton>, 'style'>;
    /**
     * If true, the title and content will not be limited to 2 lines
     */
    noLimit?: boolean;
    circularImage?: boolean;
    isSeen: boolean;
}

const NotificationItem = ({
    image,
    title: title,
    content,
    createdAt,
    button,
    noLimit,
    circularImage,
    isSeen,
}: NotificationItemProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const timeString = useUpdatedFormatedTimeAgo(createdAt);

    return (
        <TouchableWithoutFeedback onPress={button?.onPress}>
            <View style={[themedStyle.notificationContainer, !isSeen ? themedStyle.yellowBackground : undefined]}>
                {image ? (
                    <CacheImage
                        source={image}
                        resizeMode={circularImage ? undefined : 'contain'}
                        style={[themedStyle.avatar, circularImage ? themedStyle.imageCircular : undefined]}
                    />
                ) : (
                    <DefaultImage />
                )}
                <View style={themedStyle.dataContainer}>
                    <HeimeText numberOfLines={noLimit ? undefined : 2} style={themedStyle.notifTitle}>
                        {title}
                    </HeimeText>
                    {content ? (
                        <Text numberOfLines={noLimit ? undefined : 2} style={themedStyle.notifBody}>
                            {content}
                        </Text>
                    ) : null}
                    <Text style={themedStyle.notifTime}>{timeString}</Text>
                    {button ? <PrimaryButton style={themedStyle.marginVertical} {...button} /> : null}
                </View>
            </View>
        </TouchableWithoutFeedback>
    );
};

const DefaultImage = () => {
    const themedStyle = useThemeStyle(styles);
    const selectedCoop = useGlobalState((state) => state.selectedCoop);
    const { data: cooperatives } = useCoops();

    const logoImage = useMemo((): CacheImageProps<ApiImage>['source'] => {
        if (selectedCoop) {
            const cooperative = cooperatives?.find((coop) => coop.id === selectedCoop);
            if (cooperative?.white_label_logo) {
                return { ...cooperative.white_label_logo, priority: 'high' };
            }
        }
        return Images.logo as number;
    }, [cooperatives, selectedCoop]);

    return (
        <View style={themedStyle.defaultImageContainer}>
            <CacheImage resizeMode={'contain'} source={logoImage} style={themedStyle.defaultImage} />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        notificationContainer: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            borderBottomColor: theme.accent,
            paddingTop: WW * 0.05,
            paddingBottom: WW * 0.05,
            paddingLeft: WW * 0.05,
            paddingRight: WW * 0.05,
        },
        dataContainer: { flex: 1, marginLeft: WH * 0.02, flexDirection: 'column' },
        notifTitle: { fontSize: subtitleFontSize, fontWeight: 'bold' },
        notifBody: {
            fontFamily: _fonts.primaryFont,
            fontSize: smallestFontSize,
            color: theme.secondaryText,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
        },
        notifTime: {
            fontFamily: _fonts.primaryFont,
            fontSize: smallestFontSize,
            color: theme.secondaryText,
            alignSelf: 'flex-end',
        },
        avatar: {
            backgroundColor: theme.mainBackground,
            width: WW * 0.2,
            height: WW * 0.2,
        },
        imageCircular: {
            borderRadius: (WW * 0.2) / 2,
            width: WW * 0.2,
            height: WW * 0.2,
            borderWidth: 1,
            borderColor: theme.shadow,
        },
        defaultImage: {
            width: WW * 0.2 - 20,
            height: WW * 0.2 - 20,
            marginLeft: 10,
            marginRight: 10,
            marginTop: 10,
            marginBottom: 10,
        },
        defaultImageContainer: {
            borderRadius: (WW * 0.2) / 2,

            backgroundColor: theme.mainBackground,
            borderWidth: 1,
            borderColor: theme.shadow,
        },
        marginVertical: { marginTop: WW * 0.01, marginBottom: WW * 0.01 },
        yellowBackground: {
            backgroundColor: theme.shadow,
        },
    });

export default NotificationItem;
