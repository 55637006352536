import React, { ReactElement, useState } from 'react';
import { StyleSheet, TouchableWithoutFeedback, Alert } from 'react-native';
import { API_URL, APP_VERSION } from '../../../_constants';
import Images from '../../../_images';
import { Theme, useThemeStyle, WW } from '../../../_utils';
import { CacheImage } from '../../../Components';
import { ExternalUserProfile } from '../../../types/User';
interface ProfileAvatarProps {
    profile: Pick<ExternalUserProfile, 'avatar'>;
}

const ProfileAvatar = ({ profile }: ProfileAvatarProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const [counter, setCounter] = useState(1);

    const count = () => {
        setCounter(counter + 1);
        if (counter % 5 === 0) {
            Alert.alert(`Version is: ${APP_VERSION}. The server url is: ${API_URL}`);
        }
    };

    return (
        <TouchableWithoutFeedback style={themedStyle.avatarWrapper} onPress={count}>
            <CacheImage style={themedStyle.avatar} source={profile.avatar ?? Images.defaultAvatar} />
        </TouchableWithoutFeedback>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        avatarWrapper: {
            borderRadius: (WW * 0.25) / 2,
            width: WW * 0.25,
            height: WW * 0.25,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: theme.lightGreen,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
        },
        avatar: {
            width: WW * 0.25,
            height: WW * 0.25,
            borderRadius: (WW * 0.25) / 2,
            overflow: 'hidden',
        },
    });

export default ProfileAvatar;
