import { PropsWithChildren, useEffect } from 'react';
import { StyleProp, View, ViewStyle, StyleSheet } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { isWeb } from '_utils';
import { screenMargin } from '_utils/sizes';

type CustomRendererProps = PropsWithChildren<{
    style: StyleProp<ViewStyle>;
    message: PropsWithChildren<unknown>['children'];
    layouts: {
        triggerLayout: Layout;
        optionsLayout: Layout;
        safeAreaLayout: Layout;
    };
}>;

const CustomRenderer = ({ style, children, message, layouts, ...rest }: CustomRendererProps) => {
    const newLayouts = getLayouts(layouts);
    const { optionsPosition, triggerPosition } = calculateMenuPosition(newLayouts);

    // Initialize Animated.ValueXY with the initial trigger position
    const triggerPositionX = useSharedValue(newLayouts.triggerLayout.x);
    const triggerPositionY = useSharedValue(newLayouts.triggerLayout.y);

    useEffect(() => {
        triggerPositionX.value = withTiming(triggerPosition.left, { duration: 300 });
        triggerPositionY.value = withTiming(triggerPosition.top, { duration: 300 });
    }, [triggerPosition.left, triggerPosition.top, triggerPositionX, triggerPositionY]);

    const animatedTriggerStyle = useAnimatedStyle(() => {
        return {
            transform: [{ translateX: triggerPositionX.value }, { translateY: triggerPositionY.value }],
        };
    });

    return (
        <>
            <Animated.View
                style={[
                    {
                        width: triggerPosition.width,
                        height: triggerPosition.height,
                    },
                    RendererStyle.absolute,
                    animatedTriggerStyle,
                ]}
            >
                {message}
            </Animated.View>
            <View {...rest} style={[style, { ...optionsPosition }, RendererStyle.absolute]}>
                {children}
            </View>
        </>
    );
};

const RendererStyle = StyleSheet.create({
    absolute: {
        position: 'absolute',
    },
});

const getLayouts = (layouts: CustomRendererProps['layouts']): CustomRendererProps['layouts'] => {
    if (!isWeb()) {
        return layouts;
    }

    const negativeY = layouts.triggerLayout.y - (layouts.safeAreaLayout.top ?? 0) < (layouts.safeAreaLayout.top ?? 0);

    return {
        triggerLayout: {
            ...layouts.triggerLayout,
            x: layouts.triggerLayout.x - (layouts.safeAreaLayout.left ?? 0),
            y: negativeY
                ? (layouts.safeAreaLayout.top ?? 0)
                : layouts.triggerLayout.y - (layouts.safeAreaLayout.top ?? 0),
        },
        optionsLayout: {
            ...layouts.optionsLayout,
            x: layouts.optionsLayout.x - (layouts.safeAreaLayout.left ?? 0),
            y: negativeY
                ? (layouts.safeAreaLayout?.top ?? 0) + layouts.triggerLayout.height + spacing
                : layouts.optionsLayout.y - (layouts.safeAreaLayout.top ?? 0),
        },
        safeAreaLayout: layouts.safeAreaLayout,
    };
};

interface Layout {
    width: number;
    height: number;
    x: number;
    y: number;
    left?: number;
    top?: number;
}

const spacing = screenMargin;
const calculateMenuPosition = ({ triggerLayout, optionsLayout, safeAreaLayout }: CustomRendererProps['layouts']) => {
    let optionsPosition = { left: 0, top: 0 };

    // Horizontally, center the options based on the trigger's position
    optionsPosition.left = Math.max(
        triggerLayout.x + (triggerLayout.width - optionsLayout.width) / 2,
        safeAreaLayout.x,
    );

    // Ensure the menu doesn't overflow the window boundaries
    optionsPosition.left = Math.min(
        optionsPosition.left,
        (safeAreaLayout.left ?? 0) + safeAreaLayout.width - optionsLayout.width,
    );

    // Prefer to show the menu below the trigger
    const spaceBelow = safeAreaLayout.height - (triggerLayout.y + triggerLayout.height);
    const spaceAbove = triggerLayout.y - safeAreaLayout.y;

    if (optionsLayout.height + spacing <= spaceBelow) {
        // Fits below
        optionsPosition.top = triggerLayout.y + triggerLayout.height + spacing;
    } else if (optionsLayout.height + spacing <= spaceAbove) {
        // Fits above
        optionsPosition.top = triggerLayout.y - optionsLayout.height - spacing;
    } else {
        optionsPosition.top = safeAreaLayout.height - optionsLayout.height + spacing;
        return {
            optionsPosition,
            triggerPosition: {
                left: triggerLayout.x,
                top: safeAreaLayout.height - optionsLayout.height - spacing - triggerLayout.height,
                width: triggerLayout.width,
                height: triggerLayout.height,
            },
        };
    }

    return {
        optionsPosition,
        triggerPosition: {
            left: triggerLayout.x,
            top: triggerLayout.y,
            width: triggerLayout.width,
            height: triggerLayout.height,
        },
    };
};

export default CustomRenderer;
