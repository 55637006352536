import { z } from 'zod';
import { FileLinkSchema } from './Base';

export const ChatMessageFileSchema = FileLinkSchema.merge(
    z.object({
        sender_id: z.number().nullable().optional(),
        created_at: z.number().nullable().optional(),
    }),
);

export const ChatMessageSchema = z.object({
    id: z.number().nullable(),
    type: z.union([
        z.literal('activity_create'),
        z.literal('user_joined'),
        z.literal('user_added'),
        z.literal('user_left'),
        z.literal('user_rating'),
        z.literal(null),
    ]),
    user: z.number().nullable(),
    text: z.string(),
    files: z.array(ChatMessageFileSchema),
    created_at: z.number(),
    edited: z.boolean(),
    deleted: z.boolean(),
    to_message: z
        .object({
            id: z.number(),
            type: z.union([z.literal(0), z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
            title: z.string(),
        })
        .nullable(),
});

export type ChatMessage = z.infer<typeof ChatMessageSchema>;

export type MessageFile = z.infer<typeof ChatMessageFileSchema>;
