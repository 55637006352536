import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, ScrollView } from 'react-native';
import { ScreenName } from '_navigator/navigationConfiguration/types';
import { WW, isWeb, isiOS, useThemeStyle } from '_utils';
import { useSelectedCoopItem } from '_utils/hooks';
import { smallestMargin } from '_utils/sizes';
import SmallNavButton from './SmallNavButton';
import { SmallButton } from '../types';

function getGap() {
    const margin = WW * 0.02;
    const scrollWidth = WW - margin * 2;
    const buttonWidth = 72;

    const totalPossibleButtons = scrollWidth / buttonWidth;
    const oneLess = Math.floor(totalPossibleButtons - 1) + 0.5;

    const widthTotalPossible = totalPossibleButtons * buttonWidth;
    const widthOnesLess = oneLess * buttonWidth;

    const gap = (widthTotalPossible - widthOnesLess) / oneLess;

    return gap;
}

const SmallButtonNavigation = ({ buttons }: { buttons: SmallButton<ScreenName>[] }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';
    const gap = useMemo(getGap, []);

    return (
        <View style={themedStyle.container}>
            <ScrollView
                contentContainerStyle={[themedStyle.scroll, { gap }]}
                horizontal
                showsHorizontalScrollIndicator={isWeb()}
                decelerationRate="fast"
                snapToInterval={isiOS() ? WW * 0.8 : undefined}
                snapToAlignment={isiOS() ? 'center' : undefined}
            >
                {buttons.map(({ destination, icon, text, params, unreadCount }) => (
                    <SmallNavButton
                        title={t(`smallButtonNav:${text}`, {
                            boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                        })}
                        navigation={destination}
                        params={params}
                        icon={icon}
                        key={text}
                        unreadCount={unreadCount}
                    />
                ))}
            </ScrollView>
        </View>
    );
};

export default SmallButtonNavigation;

const styles = () =>
    StyleSheet.create({
        container: {
            height: 105,
            display: 'flex',
            marginTop: 20,
        },
        scroll: {
            paddingHorizontal: smallestMargin,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
        },
    });
