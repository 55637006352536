import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize, titleFontSize } from '_utils/sizes';
import { HeimeText } from 'Components';

export interface OnboardingTitlesProps {
    title: string;
    subTitle?: string;
}

const OnboardingTitles = (props: OnboardingTitlesProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View>
            <HeimeText maxFontSizeMultiplier={3} style={themedStyle.title}>
                {props.title}
            </HeimeText>
            {props?.subTitle && (
                <HeimeText maxFontSizeMultiplier={3} style={themedStyle.subTitle}>
                    {props.subTitle}
                </HeimeText>
            )}
        </View>
    );
};

export default OnboardingTitles;

const styles = (theme: Theme) =>
    StyleSheet.create({
        title: {
            color: theme.darkGrey,
            fontWeight: 'bold',
            fontSize: titleFontSize,
            textAlign: 'center',
            marginTop: screenMargin,
        },
        subTitle: {
            color: theme.secondaryText,
            fontSize: subtitleFontSize,
            textAlign: 'center',
            marginTop: smallestMargin,
            marginLeft: screenMargin,
            marginRight: screenMargin,
        },
    });
