import React, { ReactElement, useContext } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import _fonts from '../../../../_fonts';
import { useThemeStyle, WW } from '../../../../_utils';
import { smallestFontSize } from '../../../../_utils/sizes';
import { Theme, ThemeContext } from '../../../../_utils/themeContext';

const SharingCategoryItem = ({
    title,
    desc,
    icon,
    selected = false,
    onPress,
}: {
    title: string;
    desc: string;
    icon: ReactElement | null;
    selected?: boolean;
    onPress: () => void;
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    return (
        <TouchableOpacity
            onPress={onPress}
            style={[
                selected ? themedStyle.mainSelected : themedStyle.main,
                selected
                    ? {
                          borderTopWidth: 2,
                          borderLeftWidth: 2,
                          borderRightWidth: 2,
                          borderBottomWidth: 2,
                          borderColor: theme.darkGreen,
                      }
                    : {
                          borderWidth: 1,
                          borderColor: theme.mediumBackground,
                      },
            ]}
        >
            <View style={themedStyle.iconWrapper}>{icon}</View>
            <View style={themedStyle.content}>
                <Text style={themedStyle.title}>{title}</Text>
                <Text style={themedStyle.subTitle}>{desc}</Text>
            </View>
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: WW * 0.02,
        },
        mainSelected: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: WW * 0.02,
        },
        content: {
            paddingLeft: WW * 0.04,
            flex: 1,
        },
        iconWrapper: {
            paddingTop: WW * 0.03,
            paddingBottom: WW * 0.03,
            paddingLeft: WW * 0.03,
            paddingRight: WW * 0.03,
            borderRadius: WW * 3,
            backgroundColor: theme.lightGreen,
        },
        title: {
            fontFamily: _fonts.primaryFontBold,
            color: theme.darkGreen,
            fontSize: WW * 0.04,
        },
        subTitle: {
            fontFamily: _fonts.primaryFont,
            color: theme.secondaryText,
            fontSize: smallestFontSize,
        },
    });

export default SharingCategoryItem;
