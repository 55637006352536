import { useEffect, useMemo, useRef } from 'react';
import {
    InfiniteData,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQueryClient,
} from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import { z } from 'zod';
import { flattenIniniteResult } from '_utils/misc';
import { addBreadcrumb, captureException } from '_utils/Sentry';
import { useLaravelInfinteQuery } from './utility';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import { OwnReservation, OwnReservationSchema, Reservation } from '../types/Reservation';
import {
    ApiMutationResult,
    AppError,
    LaravelPagingResponse,
    UnixTimeCode,
    GenericCollectionItem,
} from '../types/Utility';

interface AbortReservationParams {
    productId: number;
    bookingId: number;
}

export interface PostReservationBody {
    end_at: UnixTimeCode;
    start_at: UnixTimeCode;
    skip_payment: boolean;
    reason: string;
    quantity: number;
}

export interface ReserveResult {
    success?: {
        booking_id: number;
        cart_product_id: number;
        redirect?: string;
        transaction_id?: string;
    };
    errors?: string[];
}
export interface ProductReservation extends Reservation {
    users: (GenericCollectionItem | undefined)[];
    status: 'confirm' | 'in_cart' | 'change_in_cart';
}

export const invalidateReservationQueries = (queryClient: ReturnType<typeof useQueryClient>): void => {
    queryClient.invalidateQueries({
        queryKey: ['reservations'],
    });
};

const responseSchema = z.array(OwnReservationSchema);
const useGetReservations = (): UseInfiniteQueryResult<InfiniteData<OwnReservation[]>, string | Error> => {
    const selectedCoopId = useSelectedCoop();

    return useLaravelInfinteQuery(
        ['reservations', selectedCoopId],
        async ({ pageParam = 1, getAuthHeader }) =>
            await axios.get(`cooperatives/${selectedCoopId}/reservations`, {
                params: { page: pageParam },
                headers: { authorization: await getAuthHeader() },
            }),
        {
            gcTime: Infinity,
            staleTime: 1000 * 60 * 60,
            enabled: true,
        },
        responseSchema,
    );
};

const useFindReservation = (bookingId: number) => {
    const hasRefetched = useRef(false);
    const { data: reservations, hasNextPage, fetchNextPage, ...rest } = useGetReservations();

    const foundReservation = useMemo(
        () => flattenIniniteResult(reservations).find((item) => item.id === bookingId),
        [bookingId, reservations],
    );

    useEffect(() => {
        if (!foundReservation && !rest.isLoading && !rest.isFetchingNextPage) {
            if (hasNextPage) {
                fetchNextPage();
            } else if (hasRefetched.current === false) {
                rest.refetch();
                hasRefetched.current = true;
            }
        }
    }, [fetchNextPage, foundReservation, hasNextPage, rest, rest.isFetchingNextPage, rest.isLoading]);

    return { reservation: foundReservation, ...rest };
};

const useGetProductReservations = (
    productId: number,
    from = moment().startOf('month').subtract({ year: 1 }),
): UseInfiniteQueryResult<InfiniteData<ProductReservation[]>, string | Error> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useLaravelInfinteQuery(
        ['reservations', selectedCoopId, productId, from.unix()],
        async ({ pageParam }) => {
            const result = await axios.get<LaravelPagingResponse<ProductReservation[]>>(
                `/cooperatives/${selectedCoopId}/products/${productId}/bookings`,
                { headers: { authorization: await getAuthHeader() }, params: { from: from.unix(), page: pageParam } },
            );
            if (!result.data.data) {
                throw new Error('Result returned with falsy data');
            }
            return result;
        },
        {
            gcTime: 1000 * 60 * 60,
            staleTime: 1000 * 60 * 1,
            initialData: () => {
                const relatedQueries = queryClient
                    .getQueryCache()
                    .getAll()
                    .filter(
                        (item) =>
                            !item.isStale() &&
                            item.queryKey?.[0] === 'reservations' &&
                            item.queryKey?.[1] === selectedCoopId &&
                            item.queryKey?.[2] === productId,
                    );

                if (relatedQueries.length !== 0) {
                    let result: ProductReservation[] = [];
                    relatedQueries?.forEach((query) =>
                        (query.state.data as InfiniteData<LaravelPagingResponse<ProductReservation[]>>)?.pages?.forEach(
                            ({ data }) =>
                                data?.forEach((reservation: ProductReservation) => {
                                    if (moment.unix(reservation.end_at).isAfter(from)) {
                                        if (result.find((item) => item.id === reservation.id)) {
                                            return;
                                        }
                                        result.push(reservation);
                                    }
                                }),
                        ),
                    );
                    return { pages: [{ data: result }], pageParams: [] };
                }

                return undefined;
            },
            initialDataUpdatedAt: 0,
        },
    );
};

interface PatchReservation {
    start_at: UnixTimeCode;
    end_at: UnixTimeCode;
    reason: string;
    skip_payment: boolean;
    productId: number;
    bookingId: number;
    quantity: number;
}

const useEditReservation = (): UseMutationResult<ReserveResult, string | Error, PatchReservation> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (booking: PatchReservation) => {
            const result = await axios.patch<PatchReservation, AxiosResponse<ReserveResult>>(
                `cooperatives/${selectedCoopId}/products/${booking.productId}/bookings/${booking.bookingId}`,
                booking,
                { headers: { authorization: await getAuthHeader() } },
            );
            if (!result.data.success) {
                throw new Error('Result returned with success == false');
            }
            return result.data;
        },
        onSettled: () => invalidateReservationQueries(queryClient),
        onError: (error, variables) => {
            addBreadcrumb(
                'userAction',
                `tried editing start_at:${variables.start_at}, end_at:${variables.end_at}, reason:${variables.reason}, skip_payment:${variables.skip_payment}`,
            );
            captureException(error);
        },
    });
};

const useAbortReservation = (): UseMutationResult<void, string | Error, AbortReservationParams> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (params: AbortReservationParams) => {
            const result = await axios.delete<ApiMutationResult>(
                `cooperatives/${selectedCoopId}/products/${params.productId}/bookings/${params.bookingId}`,
                { headers: { authorization: await getAuthHeader() } },
            );
            if (!result.data.success) {
                throw result?.data.errors;
            }
        },
        onSettled: () => invalidateReservationQueries(queryClient),
    });
};

const useReserveProduct = (): UseMutationResult<
    ReserveResult,
    string | Error | AppError<PostReservationBody>,
    [number, PostReservationBody]
> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ([productId, data]) => {
            const result = await axios.post<ReserveResult>(
                `cooperatives/${selectedCoopId}/products/${productId}/bookings`,
                { ...data },
                { headers: { authorization: await getAuthHeader() } },
            );
            if (!result.data.success) {
                throw new Error('Result returned with success == false');
            }
            return result.data;
        },
        onSettled: (res, err, [productId]) => {
            queryClient.invalidateQueries({
                queryKey: ['reservations', selectedCoopId, productId],
            });
            invalidateReservationQueries(queryClient);
        },
        onError: (error, [_, variables]) => {
            addBreadcrumb(
                'userAction',
                `tried editing start_at:${variables.start_at}, end_at:${variables.end_at}, reason:${variables.reason}, skip_payment:${variables.skip_payment}`,
            );
            captureException(error);
        },
    });
};

export {
    useGetReservations,
    useAbortReservation,
    useEditReservation,
    useReserveProduct,
    useGetProductReservations,
    useFindReservation,
};
