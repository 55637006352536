import { ReactElement, useMemo } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { z } from 'zod';
import { useGetProduct } from '_api/useCategories';
import { useProcedure } from '_api/useProcedures';
import { useFindReservation } from '_api/useReservations';
import { SafeAreaView } from '_dependencies/safeArea';
import { Theme, useThemeStyle } from '_utils';
import { HeaderWithNav, Loader, StepHeader } from 'Components';
import { Procedure } from 'types/Procedures';
import { RoutePropNumber, RoutePropsSchema } from 'types/Utility';
import { ProcedureProvider, useProcedureContext } from './ProcedureContext';
import { ProcedureStackParams } from './ProcedureNavigation';
import { ProcedureAction, StartProcedure, TimeValidator } from './screens';

const Stack = createStackNavigator<ProcedureStackParams>();

const baseRouteSchema = z.object({
    bookingId: RoutePropNumber,
    procedureId: z.string(),
});

const RouteSchema = RoutePropsSchema(
    z.union([
        baseRouteSchema.merge(
            z.object({
                product_id: z.string(),
            }),
        ),
        baseRouteSchema.merge(
            z.object({
                // TODO remove this when we always get it from the API
                product_name: z.string(),
            }),
        ),
    ]),
);

type ProcedureProps = z.infer<typeof RouteSchema>;

const ProcedureView = (props: ProcedureProps) => {
    const themedStyle = useThemeStyle(styles);
    const { bookingId, procedureId, ...rest } = RouteSchema.parse(props).route.params;
    const { data: product } = useGetProduct('product_id' in rest ? Number(rest.product_id) : 0);

    const { data: procedureResponse, isLoading, isError, error } = useProcedure(bookingId, procedureId);

    const context = useMemo(
        () => ({
            bookingId,
            procedure: procedureResponse?.procedure as Procedure,
            procedureInstance: procedureResponse?.procedure_instance ?? null,
            productName: product?.name ?? ('product_name' in rest ? rest.product_name : ''),
            productImages: product?.pictures ?? [],
        }),
        [
            bookingId,
            procedureResponse?.procedure,
            procedureResponse?.procedure_instance,
            product?.name,
            product?.pictures,
            rest,
        ],
    );
    const { reservation } = useFindReservation(bookingId);

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        throw error;
    }
    if (!procedureResponse || !context.procedure) {
        throw new Error('Procedure is falsy');
    }

    return (
        <SafeAreaView edges={['bottom', 'left', 'right']} style={themedStyle.main}>
            <ProcedureProvider
                initialState={{
                    screen: 'ProcedureStart',
                    hasSkippedAction: false,
                }}
                context={context}
            >
                <ProcedureHeader type={procedureResponse.procedure.type} />
                <TimeValidator timestamp={reservation?.end_at ?? moment().add(20, 'minutes').endOf('hour').unix()}>
                    <Stack.Navigator initialRouteName={'StartProcedure'} screenOptions={{ headerShown: false }}>
                        <Stack.Screen name="StartProcedure" component={StartProcedure} />
                        <Stack.Screen name="ProcedureAction" component={ProcedureAction} />
                    </Stack.Navigator>
                </TimeValidator>
            </ProcedureProvider>
        </SafeAreaView>
    );
};

const ProcedureHeader = ({ type }: Pick<Procedure, 'type'>): ReactElement | null => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { state, context } = useProcedureContext();

    if (state.screen === 'ProcedureStart') {
        return (
            <HeaderWithNav
                style={themedStyle.header}
                title={type === 'check_in' ? t('procedure:check_in_start') : t('procedure:check_out_start')}
            />
        );
    }

    const humanIndex = state.actionIndex + 1;
    const nSteps = context.procedure.actions.length;
    return (
        <StepHeader
            currentStep={humanIndex}
            maxSteps={nSteps}
            text={t(type === 'check_in' ? 'procedure:check_in_header' : 'procedure:check_out_header', {
                index: humanIndex,
                steps: nSteps,
            })}
            bold
            onGoBack={state.goBack}
            backgroundColor="lightBackground"
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        header: {
            backgroundColor: theme.lightBackground,
        },
        main: { height: '100%', backgroundColor: theme.lightBackground },
    });

export default ProcedureView;
