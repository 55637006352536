import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { RouteParams } from '_navigator';
import { Theme, useThemeStyle } from '_utils';
import { useSelectedCoopItem } from '_utils/hooks';
import { HeaderWithNav, Tabs } from 'Components';
import { MessageType } from 'types/message';
import { SettingsModal } from './components';
import { MessageList, useSharingContextMenuEffect } from '../common';

interface SharingCategorySelectedProps {
    showBackHeader: 'true' | 'false';
}

const SharingCategorySelected = ({ route }: RouteParams<SharingCategorySelectedProps>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    useSharingContextMenuEffect([MessageType.Requests, MessageType.ByBoard]);
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';

    const commonProps = {
        rowActions: <SettingsModal />,
        numColumns: 1,
    };

    return (
        <View style={themedStyle.main}>
            {route.params.showBackHeader && <HeaderWithNav title={t('home:notices')} safeArea />}
            <Tabs
                style={themedStyle.main}
                tabs={[
                    {
                        title: t('sharingAll:all'),
                        content: (
                            <MessageList
                                {...commonProps}
                                emptyText={t('sharingAll:noMessageType:Requests')}
                                messageTypes={[MessageType.Requests, MessageType.ByBoard]}
                            />
                        ),
                    },
                    {
                        title: t('sharingAll:fromBoard', {
                            boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                        }),
                        content: (
                            <MessageList
                                {...commonProps}
                                emptyText={t('sharingAll:noMessageType:ByBoard', {
                                    boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                                })}
                                messageTypes={[MessageType.ByBoard]}
                            />
                        ),
                    },
                ]}
            />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
    });

export default SharingCategorySelected;
