import React, { ReactElement, memo } from 'react';
import { useOwnProfile } from '_api/useProfile';
import { useCalculateMessageRead } from '_utils';
import { Message, MessageType } from 'types/message';
import HelperItem from './components/HelperItem';
import ProductItem from './components/ProductItem';
import RequestItem from './components/RequestItem';

interface MessageItemProps {
    item: Message;
    onPress: () => void;
}

const MessageItem = ({ item, onPress }: MessageItemProps): ReactElement | null => {
    const messageIsRead = useCalculateMessageRead();
    const cat = MessageType[item.type];
    const ownId = useOwnProfile()?.data?.id;

    switch (item.type) {
        case MessageType.ByBoard:
        case MessageType.Requests: {
            return (
                <RequestItem
                    isNew={!messageIsRead(item)}
                    created={item.created_at}
                    fromBoard={cat === 'ByBoard'}
                    title={item.title}
                    content={item.content ?? ''}
                    creator={item.creator_id}
                    onPress={onPress}
                    commentsLength={item.comments.filter((comment) => !comment.deleted).length}
                    showComments={item.allow_comments}
                    attachmentsLength={item.files.length}
                    apartment_groups={item.apartment_groups}
                />
            );
        }
        case MessageType.Products: {
            return (
                <ProductItem
                    isNew={!messageIsRead(item)}
                    title={item.title}
                    picture={item.pictures.length ? item.pictures[0] : null}
                    creator={item.creator_id}
                    onPress={onPress}
                />
            );
        }
        case MessageType.Helpers: {
            return (
                <HelperItem
                    isNew={!messageIsRead(item)}
                    own={item.creator_id === ownId}
                    title={item.title}
                    creator={item.creator_id}
                    onPress={onPress}
                />
            );
        }
        default:
            return null;
    }
};

export default memo(MessageItem);
