import React, { PropsWithChildren, ReactElement, useRef } from 'react';
import { LinkingOptions, NavigationContainer, NavigationContainerRef, ParamListBase } from '@react-navigation/native';
import useTrackNavigation from './hooks/useTrackNavigation';
import refObject from './navigationReference';
import { ContextMenuProvider } from './PlusMenuContext';
import { registerNavigation } from '../_utils/Sentry';

const linking: LinkingOptions<ParamListBase> = {
    prefixes: [
        'heime://',
        'https://app.heime.com',
        'http://app.heime.com',
        'http://heime.dev.frontsoftware.no/heime',
        'https://heime.dev.frontsoftware.no/heime',
    ],
};

const HeimeNavigationContainer = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const navRef = useRef<NavigationContainerRef<ParamListBase>>(null);

    const { onReady, onStateChange } = useTrackNavigation(navRef);

    const handleIsReady = () => {
        refObject.ref = navRef.current;
        registerNavigation(navRef.current as NavigationContainerRef<ParamListBase>);
        onReady();
    };

    return (
        <NavigationContainer
            documentTitle={{ formatter: () => 'Heime' }}
            linking={linking}
            onReady={handleIsReady}
            ref={navRef}
            onStateChange={onStateChange}
        >
            <ContextMenuProvider>{children}</ContextMenuProvider>
        </NavigationContainer>
    );
};

export default HeimeNavigationContainer;
