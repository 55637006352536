import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import { useOwnProfile } from '_api/useProfile';
import { askActivityNewAbort, askActivitySuggestionAbort } from '_redux/modals';
import { getFullName, showToast } from '_utils';
import { track } from '_utils/Amplitude';
import { captureUserFeedback } from '_utils/Sentry';
import { HeimeText, ControlledInput, CheckBox, Modal } from 'Components';
import useAppDispatch from './useAppDispatch';
import useGlobalState from './useGlobalState';

const useSurveyAfterNavigation = (
    variant: 'activityNew' | 'activitySuggestion',
    blockNavigation: boolean,
    onConfirm: () => void,
) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { activityNewAbortQuestionAskedAt, activitySuggestionAskedAt } = useGlobalState(
        (state) => state.addProfileModal,
    );
    const appDispatch = useAppDispatch();
    const { addListener } = useNavigation();
    const variantItems = useMemo(
        () =>
            variant === 'activityNew'
                ? {
                      time: activityNewAbortQuestionAskedAt,
                      action: askActivityNewAbort,
                      randomization: (): boolean => true,
                  }
                : {
                      time: activitySuggestionAskedAt,
                      action: askActivitySuggestionAbort,
                      randomization: () => Math.random() < 0.2,
                  },
        [activityNewAbortQuestionAskedAt, activitySuggestionAskedAt, variant],
    );

    const openModal = useCallback(() => setIsModalOpen(true), []);
    useEffect(() => {
        const unsubscribe = addListener('beforeRemove', (e) => {
            if (blockNavigation && !isModalOpen) {
                const { time, action, randomization } = variantItems;
                if ((!time || moment.unix(time).isBefore(moment().subtract(1, 'month'))) && randomization()) {
                    appDispatch(action(moment().unix()));
                    e.preventDefault();
                    openModal();
                    track('Showing survey after navigation', { variant });
                }
                return;
            }
        });
        return () => unsubscribe();
    }, [addListener, openModal, isModalOpen, blockNavigation, variantItems, appDispatch, variant]);

    const handleConfirm = () => {
        onConfirm();
        setIsModalOpen(false);
    };

    return {
        content: isModalOpen ? (
            <SurveyModal variant={variant} onDismiss={() => setIsModalOpen(false)} onContinue={handleConfirm} />
        ) : null,
    };
};

interface SurveyModalProps {
    variant: 'activityNew' | 'activitySuggestion';
    onDismiss(): void;
    onContinue(): void;
}

const SurveyModal = ({ onDismiss, onContinue, variant }: SurveyModalProps) => {
    const { t } = useTranslation();
    const [feedback, setFeedback] = useState('');
    const [allowContact, setAllowContact] = useState(false);
    const ownProfile = useOwnProfile().data;

    const handleDismiss = () => {
        onDismiss();
        track('User feedback dismissed for survey after navigation', { variant });
    };

    const handleSubmitFeedback = () => {
        captureUserFeedback(feedback, getFullName(ownProfile?.fname, ownProfile?.lname), allowContact);
        setFeedback('');
        showToast({
            type: 'success',
            header: t('rateUs:feedback_success'),
            text: '',
        });
        onContinue();
        track('User feedback submitted for survey after navigation', { variant });
    };
    const onCancel = () => {
        onDismiss();
        onContinue();
        track('User feedback cancelled for survey after navigation', { variant });
    };

    return (
        <Modal
            onDismiss={handleDismiss}
            header={t(`activity:survey_${variant}:header`)}
            content={
                <ScrollView bounces={false} keyboardShouldPersistTaps="handled">
                    <HeimeText variant="subtitle">{t(`activity:survey_${variant}:content`)}</HeimeText>
                    <ControlledInput
                        value={feedback}
                        onChange={(arg) => setFeedback(arg ?? '')}
                        label={t(`activity:survey_${variant}:survey_label`)}
                        placeholder={t(`activity:survey_${variant}:survey_placeholder`)}
                        multiline
                        numberOfLines={4}
                        autoFocus
                    />
                    <CheckBox
                        checked={allowContact}
                        onPress={() => setAllowContact(!allowContact)}
                        title={t(`activity:survey_${variant}:allow_contact`)}
                    />
                </ScrollView>
            }
            buttonsDirection="column"
            buttons={[
                {
                    text: t(`activity:survey_${variant}:submit`),
                    onPress: handleSubmitFeedback,
                    isDisabled: !feedback,
                },
                {
                    text: t(`activity:survey_${variant}:cancel`),
                    type: 'secondary',
                    onPress: onCancel,
                },
            ]}
        />
    );
};

export default useSurveyAfterNavigation;
