import { PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { smallestMarginPixels } from '_utils/sizes';
import { HeimeText } from 'Components';

const ErrorText = ({ children }: PropsWithChildren<unknown>) => {
    const themedStyle = useThemeStyle(styles);
    return <HeimeText style={themedStyle.error}>{children}</HeimeText>;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        error: {
            marginBottom: smallestMarginPixels * 2,
            justifyContent: 'center',
            textAlign: 'center',
            fontWeight: 'bold',
            color: theme.red,
        },
    });

export default ErrorText;
