import React, { ReactElement, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View, FlatList } from 'react-native';
import * as z from 'zod';
import { findInitialCooperativeGroupId } from 'types/Cooperative';
import { Group, useCreateGroup, useEditGroup } from '../../_api/useGroups';
import { SafeAreaView } from '../../_dependencies/safeArea';
import _fonts from '../../_fonts';
import { useAppNavigation } from '../../_navigator';
import { Theme, useThemeStyle, useKeyboardIsVisible, WW, useConfirmDiscard, isEdge, showToast } from '../../_utils';
import { useSelectedCoopItem } from '../../_utils/hooks';
import { CooperativeGroupDropdown, FormInput, ImageSelector, PrimaryButton } from '../../Components';
import { fileSchema } from '../../types/Utility';
import { HeaderWithActions } from '../GroupSelected/components';

export interface NewGroupProps {
    route: { params: { group?: Group } };
}

const schema = z.object({
    name: z.string().nonempty(),
    description: z.string().nonempty(),
    pictures: z.array(fileSchema),
    isEditing: z.boolean(),
    id: z.onumber(),
    cooperative_group_id: z.number().nullable(),
});

type FormValues = z.infer<typeof schema>;

const mapToDefault = (initial: Group | undefined, initialCooperativeGroup: number | null): FormValues => {
    if (initial) {
        return {
            name: initial.name,
            description: initial.description,
            pictures: initial.pictures.map((img, index) => ({ ...img, status: 'stale', index })),
            isEditing: true,
            id: initial.id,
            cooperative_group_id: initial.cooperative_group_id,
        };
    }
    return {
        name: '',
        description: '',
        pictures: [],
        isEditing: false,
        cooperative_group_id: initialCooperativeGroup ?? null,
    };
};

const NewGroup = ({ ...props }: NewGroupProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate, goBack: internalGoBack } = useAppNavigation();
    const keyboardIsVisible = useKeyboardIsVisible();

    const { isPending: isGroupCreating, mutate: createGroup } = useCreateGroup();
    const { isPending: isGroupUpdating, mutate: editGroup } = useEditGroup();

    const cooperativeGroups = useSelectedCoopItem()?.cooperative_groups ?? [];
    const { formState, watch, setValue, ...methods } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: mapToDefault(props.route.params.group, findInitialCooperativeGroupId(cooperativeGroups)),
        resolver: zodResolver(schema),
    });
    const { isValid } = formState;
    const pictures = watch('pictures');
    const isEditing = watch('isEditing');

    const { content } = useConfirmDiscard(
        formState.isDirty && !isGroupCreating && !isGroupUpdating,
        isEditing,
        internalGoBack,
    );

    const handleSetImage = (img: FormValues['pictures']) => setValue('pictures', img);

    const onError = () => {
        showToast({
            header: t('group:createGroup:updateError'),

            text: '',
            type: 'error',
        });
    };

    const onSubmit: SubmitHandler<FormValues> = (formValues) => {
        if (isEditing && formValues.id) {
            const groupId = formValues.id;
            editGroup([groupId, formValues], {
                onSuccess: () => {
                    navigate('GroupSelected', { groupId: groupId, isOnboarding: false });
                },
                onError,
            });
        } else {
            createGroup(formValues, {
                onSuccess: (data) => {
                    navigate('GroupSelected', { groupId: data.success, isOnboarding: false });
                },
                onError,
            });
        }
    };

    const buttonStatus = useMemo(() => {
        return isGroupCreating || isGroupUpdating ? 'loading' : isValid ? null : 'disabled';
    }, [isGroupCreating, isGroupUpdating, isValid]);

    return (
        <SafeAreaView
            style={themedStyle.main}
            edges={[keyboardIsVisible ? undefined : 'bottom', 'left', 'right'].filter(isEdge)}
        >
            <HeaderWithActions
                title={(isEditing
                    ? t('group:createGroup:title_edit')
                    : t('group:createGroup:title_create')
                ).toUpperCase()}
                action={<Text style={themedStyle.closeIcon}>{'+'}</Text>}
            />
            <FlatList
                ListHeaderComponent={
                    <View style={themedStyle.content}>
                        <ImageSelector
                            images={pictures}
                            onChange={handleSetImage}
                            title={t('group:createGroup:image')}
                            titleStyles={themedStyle.topLabel}
                        />
                        <FormProvider setValue={setValue} watch={watch} formState={formState} {...methods}>
                            <FormInput name="name" label={t('group:createGroup:title')} autoFocus />
                            <CooperativeGroupDropdown isCreatingNew={!isEditing} />
                            <FormInput
                                multiline
                                numberOfLines={6}
                                name="description"
                                label={t('group:createGroup:description')}
                            />
                        </FormProvider>
                    </View>
                }
                data={[]}
                renderItem={() => null}
            />
            <PrimaryButton
                status={buttonStatus}
                text={(isEditing
                    ? t('group:createGroup:button_edit')
                    : t('group:createGroup:button_create')
                ).toUpperCase()}
                onPress={methods.handleSubmit(onSubmit)}
                bottomAction
            />
            {content}
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
        content: {
            flex: 1,
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
        },
        topLabel: {
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.04,
            textAlign: 'left',
            color: theme.black,
        },
        closeIcon: {
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            paddingTop: WW * 0.02,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            transform: [{ rotate: '45deg' }, { scale: 2 }],
            color: theme.darkGrey,
        },
    });

export { NewGroup };
