import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, StyleSheet, TouchableOpacity, View } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import Images from '_images';
import { Theme, getFullName, isWeb, useGetExampleNeighbors, useThemeStyle } from '_utils';
import { track } from '_utils/Amplitude';
import { screenMargin, smallestFontSize, smallestMargin, smallestMarginPixels, subtitleFontSize } from '_utils/sizes';
import { CacheImage, FormInput, HeimeText, Icon } from 'Components';
import { ExternalUserProfile } from 'types/User';

const AboutMe = ({ autoFocus }: { autoFocus?: boolean }) => {
    const { t } = useTranslation();
    const themedStyle = useThemeStyle(styles);
    const neighborsWithAboutMe = useGetExampleNeighbors('about_me');
    const [accordionOpen, setAccordionOpen] = useState<'tips' | 'examples' | null>(null);

    const handleTipsChange = (open: boolean) => {
        setAccordionOpen(open ? 'tips' : null);
        track('profile_edit_about_me_tips', { open });
    };
    const handleExamplesChange = (open: boolean) => {
        setAccordionOpen(open ? 'examples' : null);
        track('profile_edit_about_me_examples', { open });
    };

    return (
        <View style={themedStyle.container}>
            <FormInput
                name="aboutMe"
                label={t('profileEdit:about_me')}
                placeholder={t('profileEdit:aboutMePlaceHolder')}
                multiline
                autoCorrect
                autoFocus={autoFocus}
                autoCapitalize="sentences"
                containerStyle={themedStyle.input}
                numberOfLines={5}
            />

            <Accordion open={accordionOpen === 'tips'} onChange={handleTipsChange} title={t('profileEdit:aboutMeTips')}>
                {new Array(6).fill(0).map((_, index) => (
                    <Tip
                        key={index}
                        title={t(`profileEdit:about_me_tip_${index as 1 | 2 | 3 | 4 | 5 | 6}`)}
                        description={t(`profileEdit:about_me_tip_description_${index as 1 | 2 | 3 | 4 | 5 | 6}`)}
                    />
                ))}
            </Accordion>
            <View style={themedStyle.divider} />
            <Accordion
                open={accordionOpen === 'examples'}
                onChange={handleExamplesChange}
                title={t('profileEdit:aboutMeExamples')}
            >
                {neighborsWithAboutMe.map((neighbour) => (
                    <NeighborExample
                        key={neighbour.id}
                        title={getFullName(neighbour.fname, neighbour.lname)}
                        description={neighbour.about_me}
                        avatar={neighbour.avatar}
                    />
                ))}
            </Accordion>
        </View>
    );
};

const animationDuration = 300;

const Accordion = ({
    title,
    children,
    onChange,
    open,
}: PropsWithChildren<{
    title: string;
    onChange(open: boolean): void;
    open: boolean;
}>) => {
    const themedStyle = useThemeStyle(styles);
    const [contentHeight, setContentHeight] = useState(0);
    const animatedHeight = useSharedValue(0);
    const contentRef = useRef<View>(null);

    const animatedStyles = useAnimatedStyle(() => {
        return {
            height: animatedHeight.value,
            overflow: 'hidden',
        };
    });

    const toggleAccordion = () => {
        onChange(!open);
    };

    const onContentLayout = (event: LayoutChangeEvent) => {
        // Measure the content height only once
        const { height } = event.nativeEvent.layout;
        if (Math.abs(contentHeight - height) > 10) {
            setContentHeight(height);
        }
        if (open) {
            animatedHeight.value = height; // If initially open, set to full height
        }
    };

    useEffect(() => {
        if (open) {
            animatedHeight.value = withTiming(contentHeight, { duration: animationDuration });
        } else {
            animatedHeight.value = withTiming(0, { duration: animationDuration });
        }
    }, [animatedHeight, contentHeight, open]);

    return (
        <View>
            <TouchableOpacity style={themedStyle.header} onPress={toggleAccordion}>
                <HeimeText style={themedStyle.headerText}>{title}</HeimeText>
                <Chevron open={open} />
            </TouchableOpacity>
            <Animated.View style={animatedStyles}>
                <View
                    onLayout={onContentLayout}
                    ref={contentRef}
                    style={[contentHeight === 0 ? themedStyle.absolute : {}, themedStyle.chevronContainer]}
                >
                    {children}
                </View>
            </Animated.View>
        </View>
    );
};

const openRotation = -90;
const closedRotation = 90;
const Chevron = ({ open }: { open: boolean }) => {
    const rotation = useSharedValue(open ? openRotation : closedRotation);

    const animatedStyles = useAnimatedStyle(() => {
        return {
            transform: [{ rotate: `${rotation.value}deg` }],
        };
    });

    useEffect(() => {
        if (open) {
            rotation.value = withTiming(openRotation, { duration: animationDuration });
        } else {
            rotation.value = withTiming(closedRotation, { duration: animationDuration });
        }
    }, [rotation, open]);

    return (
        <Animated.View style={animatedStyles}>
            <Icon name="chevron" color="main" />
        </Animated.View>
    );
};
const Tip = ({ title, description }: { title: string; description: string }) => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View style={themedStyle.tipContainer}>
            <Icon name="checkboxCircle" color="main" />
            <View style={themedStyle.flex}>
                <HeimeText style={themedStyle.tipTitle}>{title}</HeimeText>
                <HeimeText style={themedStyle.tipDescription}>{description}</HeimeText>
            </View>
        </View>
    );
};

const NeighborExample = ({
    title,
    description,
    avatar,
}: {
    title: string;
    description: string;
    avatar: ExternalUserProfile['avatar'];
}) => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View style={themedStyle.exampleContainer}>
            <View style={themedStyle.exampleTitle}>
                <View style={themedStyle.avatar}>
                    <CacheImage source={avatar ?? Images.defaultAvatar} style={themedStyle.avatar} />
                </View>
                <HeimeText style={themedStyle.tipTitle}>{title}</HeimeText>
            </View>
            <HeimeText style={themedStyle.tipDescription}>{description}</HeimeText>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            gap: smallestMargin,
            marginBottom: screenMargin,
        },
        input: {
            marginBottom: smallestMargin,
        },

        header: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        headerText: {
            fontSize: subtitleFontSize,
            color: theme.main,
            fontWeight: 'bold',
        },
        absolute: { position: 'absolute' },
        chevronContainer: {
            paddingTop: smallestMargin,
            gap: smallestMargin,
        },
        flex: { flex: 1 },
        tipContainer: {
            flexDirection: 'row',
            gap: smallestMargin,
        },
        tipTitle: {
            fontWeight: 'bold',
            color: theme.main,
            fontSize: smallestFontSize,
            marginBottom: smallestMargin,
        },
        tipDescription: {
            color: theme.darkGray,
            fontSize: smallestFontSize,
            marginBottom: smallestMarginPixels,
        },
        divider: {
            height: 1,
            backgroundColor: theme.lightGrey,
            marginVertical: smallestMargin,
        },
        avatar: {
            width: smallestFontSize,
            height: smallestFontSize,
            borderRadius: isWeb() ? ('50%' as unknown as number) : smallestFontSize / 2,
            overflow: 'hidden',
        },
        exampleTitle: {
            gap: smallestMargin,
            flexDirection: 'row',
        },
        exampleContainer: {
            borderBottomWidth: 1,
            borderBottomColor: theme.lightGrey,
            marginBottom: smallestMarginPixels,
        },
    });

export default AboutMe;
