import React, { ComponentProps, ReactElement, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { useAddMessageComment, useEditMessageComment, useGetMessage } from '_api/useMessages';
import { useGetUser } from '_api/useUsers';
import { fileIsImage, fromNowFormat, Theme, useThemeStyle, WH, WW, showToast } from '_utils';
import { useSelectedCoopItem } from '_utils/hooks';
import { capitalizeFirstLetter, getUsernameFromProfile } from '_utils/misc';
import { smallestFontSize, smallestMargin, subtitleFontSize, titleFontSize } from '_utils/sizes';
import { AttachmentsView, HeimeText, Icon, MessageInput, UserListItem } from 'Components';
import { ApartmentGroupsDisplay } from 'Screens/Sharing/common';
import { FileLink } from 'types/Base';
import { MessageType, NoticeMessage } from 'types/message';
import { ArrayElement, FileUpload, isFileToBeUploaded } from 'types/Utility';
import { CommentsWithInput, SubscribeButton, ExpiredInfo } from './Components';

interface RequestDetail {
    message: NoticeMessage;
    apartments: string | undefined;
    scrollToBottom: boolean;
}
type messageCommentWithId = Omit<ArrayElement<NoticeMessage['comments']>, 'id'> & { id: number };

const RequestDetail = ({ message, apartments, scrollToBottom }: RequestDetail): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { refetch, isRefetching } = useGetMessage(message.id);
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';
    const { mutate: addComment } = useAddMessageComment();
    const { mutate: updateComment } = useEditMessageComment();
    const [editing, setEditing] = useState<messageCommentWithId | null>(null);

    const { files, pictures } = useMemo(() => {
        const toFileUpload = (file: FileLink): FileUpload => ({
            ...file,
            status: 'added',
            index: 0,
            uri: file.original,
        });
        return {
            files: message.files.filter((file) => !fileIsImage(toFileUpload(file))),
            pictures: message.files.filter((file) => fileIsImage(toFileUpload(file))),
        };
    }, [message.files]);

    const handleSendComment: ComponentProps<typeof MessageInput>['onSend'] = (arg) => {
        if (editing) {
            updateComment(
                {
                    commentId: editing.id,
                    messageId: message.id,
                    data: {
                        ...arg,
                        files: arg.files.filter(isFileToBeUploaded),
                        removeFiles: editing.files
                            .filter(
                                (file) =>
                                    !editing.files.find((existing) => 'id' in existing && existing.id !== file.id),
                            )
                            .map((file) => file.id),
                    },
                },
                {
                    onError: () => {
                        showToast({
                            header: t('global:error_saving_header'),
                            text: t('global:error_saving_description'),
                            type: 'error',
                        });
                    },
                },
            );
            setEditing(null);
        } else {
            addComment([message.id, { ...arg, files: arg.files.filter(isFileToBeUploaded) }]);
        }
    };
    const creator = useGetUser(message.creator_id);
    const initialShowExpired = useMemo(
        () => message.active_until && moment(message.active_until).isBefore(moment()),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <CommentsWithInput
            comments={message.comments.map((comm) => ({ ...comm, content: comm.content ?? '' }))}
            onSend={handleSendComment}
            onRefresh={refetch}
            isRefreshing={isRefetching}
            scrollToBottom={scrollToBottom}
            showComments={message.allow_comments}
            messageId={message.id}
            editing={editing}
            setEditing={(arg) => (arg?.id ? setEditing(arg as messageCommentWithId) : setEditing(null))}
        >
            <View style={themedStyle.content}>
                <ApartmentGroupsDisplay apartment_groups={message.apartment_groups} />
                <HeimeText maxFontSizeMultiplier={2} style={themedStyle.requestTitle}>
                    {message.title}
                </HeimeText>
                <View style={themedStyle.subContainer}>
                    <HeimeText style={themedStyle.timeText}>
                        {fromNowFormat(moment.unix(message.created_at).toDate())}
                    </HeimeText>
                    {message.allow_comments ? (
                        <SubscribeButton subscribers={message.subscribers} messageId={message.id} />
                    ) : null}
                </View>
                <HeimeText maxFontSizeMultiplier={2} linkify style={themedStyle.requestDescription}>
                    {message.content}
                </HeimeText>
                {files.length + pictures.length > 0 ? (
                    <>
                        <HeimeText style={themedStyle.attachmentTitle}>{t('sharingSelected:attachments')}</HeimeText>
                        <AttachmentsView
                            files={files}
                            pictures={pictures.map((img) => ({ ...img, sender_id: message.creator_id }))}
                            size="small"
                        />
                    </>
                ) : null}
                <HeimeText style={themedStyle.byText}>{t('sharingAll:created')}:</HeimeText>
                {message.type === MessageType.ByBoard ? (
                    <View style={themedStyle.boardRow}>
                        <View style={themedStyle.fromBoardContainer}>
                            <Icon name="board" color="main" />
                            <HeimeText style={themedStyle.fromBoardText}>
                                {capitalizeFirstLetter(t(`typeSpecific:${coopType}:boardNounDefiniteArticle`))}
                            </HeimeText>
                        </View>
                        <HeimeText style={{ marginLeft: smallestMargin }}>
                            {t('sharingAll:fromBoardBy')} {getUsernameFromProfile(creator)}
                        </HeimeText>
                    </View>
                ) : (
                    <UserListItem
                        containerStyle={themedStyle.creatorContainer}
                        noBottomBorder
                        key={message.creator_id}
                        id={message.creator_id}
                        desc={apartments}
                    />
                )}
                {message.active_until && moment(message.active_until).isBefore(moment()) ? (
                    <Animated.View entering={initialShowExpired ? undefined : FadeIn} exiting={FadeOut}>
                        <ExpiredInfo
                            title={t('sharingSelected:requestDetails:expired')}
                            description={t('sharingSelected:requestDetails:expiredDescription')}
                            style={themedStyle.info}
                        />
                    </Animated.View>
                ) : null}
            </View>
        </CommentsWithInput>
    );
};
const styles = (theme: Theme) =>
    StyleSheet.create({
        content: {
            paddingTop: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            borderBottomColor: theme.mediumBackground,
            borderBottomWidth: 8,
            marginBottom: WH * 0.02 - smallestMargin,
        },
        requestTitle: {
            fontWeight: 'bold',
            fontSize: titleFontSize,
            marginBottom: smallestMargin,
            color: theme.black,
        },
        timeText: {
            color: theme.greyMedium,
            fontSize: smallestFontSize,
        },
        creatorContainer: { paddingTop: smallestMargin / 2 },
        requestDescription: {
            fontSize: subtitleFontSize,
            lineHeight: subtitleFontSize * 1.5,
            color: theme.black,
            marginTop: smallestMargin,
        },
        byText: {
            color: theme.greyMedium,
            fontSize: smallestFontSize,
            marginTop: smallestMargin,
        },
        fromBoardContainer: {
            alignItems: 'center',
            alignSelf: 'flex-start',
            flexDirection: 'row',
            borderRadius: WW * 0.04,
            backgroundColor: theme.lightGreen,
            paddingLeft: smallestMargin,
            paddingRight: smallestMargin,
            marginTop: smallestMargin,
            marginBottom: smallestMargin,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
        },
        fromBoardText: {
            color: theme.darkGreen,
            fontSize: smallestFontSize,
            marginLeft: smallestMargin,
        },
        attachmentTitle: {
            fontWeight: 'bold',
            marginTop: smallestMargin,
        },
        boardRow: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        subContainer: { justifyContent: 'space-between', flexDirection: 'row', alignItems: 'flex-start' },
        info: {
            marginBottom: smallestMargin,
        },
    });

export default RequestDetail;
