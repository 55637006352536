import React, { ReactElement, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useGetReservations } from '_api/useReservations';
import { useAppNavigation } from '_navigator';
import { isAppError, Theme, useThemeStyle, WW } from '_utils';
import { flattenIniniteResult } from '_utils/misc';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import {
    Loader,
    PrimaryButton,
    SecondaryButton,
    HeaderWithNav,
    NotFoundErrorScreen,
    BottomSpacer,
    HeimeText,
    Icon,
} from 'Components';
import { ProductHasPrice } from 'types/Category';
import { ActiveBookingsView, ProductDetailsView } from './components';
import { useProductDetailHook } from './useProductDetailHook';

export interface ProductDetailProps {
    route: { params: { categoryId?: number; productId: number } };
}
const ProductDetail = (props: ProductDetailProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const { isLoadingDetails, product, refresh, isRefreshing, onReserve, isError, error, loadReservationsFrom } =
        useProductDetailHook(props);
    const { data: ownBookings } = useGetReservations();
    const ownBookingsOnThis = useMemo(
        () =>
            flattenIniniteResult(ownBookings).filter((b) => b.booked.id === product?.id && b.end_at > moment().unix()),
        [ownBookings, product?.id],
    );
    if (isError) {
        if (isAppError(error) && error.response?.status === 404) {
            return <NotFoundErrorScreen type="Product" />;
        } else {
            throw error;
        }
    }

    const handleDatePress = (date: Date) => onReserve(date, true);

    const canSkipPayment = product ? product?.can_skip_payment && ProductHasPrice(product) : false;
    const goToBookings = () =>
        ownBookingsOnThis.length > 1
            ? navigate('ReservationsAll', undefined)
            : navigate('ReservationSelected', {
                  id: ownBookingsOnThis[0].id,
              });

    return (
        <View style={themedStyle.main}>
            <HeaderWithNav
                style={themedStyle.header}
                title={t('navigation:reservations').toLocaleUpperCase()}
                safeArea
            />
            {isLoadingDetails || !product ? (
                <Loader />
            ) : (
                <>
                    <ActiveBookingsView
                        productId={props.route.params.productId}
                        capacity={product.capacity}
                        isShortTerm={product.booking_time}
                        checkinTime={product.booking_start ?? ''}
                        onDateSelect={handleDatePress}
                        loadReservationsFrom={loadReservationsFrom}
                        onRefresh={refresh}
                        isRefreshing={isRefreshing}
                    >
                        <ProductDetailsView
                            {...product}
                            belowPicture={
                                ownBookingsOnThis.length > 0 ? (
                                    <HasBookingMessage productName={product.name} onPress={goToBookings} />
                                ) : undefined
                            }
                        />
                    </ActiveBookingsView>
                    <View style={themedStyle.buttonContainer}>
                        {canSkipPayment ? (
                            <SecondaryButton
                                style={[themedStyle.button, themedStyle.leftButton]}
                                text={t('serviceExpanded:skipPayment')}
                                onPress={() => onReserve(null, false)}
                            />
                        ) : null}

                        <PrimaryButton
                            style={themedStyle.button}
                            text={
                                canSkipPayment ? t('serviceExpanded:paymentReservation') : t('serviceExpanded:reserve')
                            }
                            onPress={() => onReserve(null, true)}
                        />
                    </View>
                    <BottomSpacer />
                </>
            )}
        </View>
    );
};

const HasBookingMessage = ({ productName, onPress }: { productName: string; onPress(): void }) => {
    const { t } = useTranslation();
    const { theme } = useThemeContext();

    return (
        <TouchableOpacity
            onPress={onPress}
            style={{
                margin: screenMargin,
                borderWidth: 1,
                borderColor: theme.main,
                backgroundColor: theme.lightGreen,
                borderRadius: 10,
                paddingRight: smallestMargin,
                paddingLeft: smallestMargin,
                paddingTop: smallestMargin,
                paddingBottom: smallestMargin,
                gap: smallestMargin,
            }}
        >
            <HeimeText
                style={{
                    fontSize: subtitleFontSize,
                    color: theme.main,
                }}
            >
                {t('serviceExpanded:hasBooking', { product: productName })}
            </HeimeText>
            <View style={{ flexDirection: 'row', gap: smallestMargin }}>
                <HeimeText
                    style={{
                        fontSize: subtitleFontSize,
                        fontWeight: 'bold',
                        color: theme.main,
                    }}
                >
                    {t('serviceExpanded:booking_details')}
                </HeimeText>
                <Icon name="chevron" color="main" />
            </View>
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.mainBackground,
            flex: 1,
            display: 'flex',
        },
        header: {
            backgroundColor: theme.mainBackground,
        },
        buttonContainer: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        button: {
            flex: 1,
            flexBasis: '40%',
            width: WW - screenMargin * 2,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        leftButton: { marginRight: screenMargin },
    });

export { ProductDetail };
