import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import Animated, { FadeIn } from 'react-native-reanimated';
import { Theme, WH, WW, useThemeStyle } from '_utils';
import { HeimeText, Icon } from 'Components';
import { BottomButtons, ContentDisplay } from './common';

const ApproveCleaning = ({
    onSkip,
    onContinue,
    isContinuing,
}: {
    onSkip(): void;
    onContinue(): void;
    isContinuing: boolean;
}) => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { setValue, watch } = useFormContext();
    const value = watch('value');
    const [isDisapproved, setIsDisapproved] = useState<boolean>(value ? value === 'false' || value === false : false);

    const noValue = value === null || value === '';

    const approve = () => {
        setValue('value', true);
        setIsDisapproved(false);
        onContinue();
    };

    const disapprove = () => {
        setValue('value', false);

        setIsDisapproved(true);
    };

    const handleSkip = () => {
        if (!noValue) {
            onContinue();
            return;
        }
        onSkip();
    };

    return (
        <ContentDisplay
            content={
                <ScrollView bounces={false}>
                    <HeimeText variant="title" maxFontSizeMultiplier={2}>
                        {t('procedure:approve_cleaning_header')}
                    </HeimeText>
                    <HeimeText variant="subtitle" maxFontSizeMultiplier={2} style={themedStyle.subtitle}>
                        {t('procedure:approve_cleaning_text')}
                    </HeimeText>
                    <View style={themedStyle.buttonContainer}>
                        <TouchableOpacity onPress={approve} style={[themedStyle.button, themedStyle.positive]}>
                            <Icon name="thumbsUp" color="green" />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={disapprove} style={[themedStyle.button, themedStyle.negative]}>
                            <Icon name="thumbsDown" color="red" />
                        </TouchableOpacity>
                    </View>
                    {isDisapproved ? (
                        <Animated.View entering={FadeIn}>
                            <HeimeText
                                maxFontSizeMultiplier={2}
                                variant="subtitle"
                                style={[{ textAlign: 'center' }, themedStyle.subtitle]}
                            >
                                {t('procedure:approve_cleaning_disapproved')}
                            </HeimeText>
                        </Animated.View>
                    ) : null}
                </ScrollView>
            }
            buttons={
                <BottomButtons
                    buttons={
                        isDisapproved
                            ? [
                                  {
                                      text: t('procedure:continue'),
                                      onPress: onContinue,
                                      isLoading: isContinuing,
                                  },
                              ]
                            : !noValue
                              ? [
                                    {
                                        text: t('procedure:continue'),
                                        onPress: handleSkip,
                                        isLoading: isContinuing,
                                    },
                                ]
                              : [
                                    {
                                        type: 'secondary',
                                        text: t('procedure:skip'),
                                        onPress: handleSkip,
                                        isLoading: isContinuing,
                                    },
                                ]
                    }
                />
            }
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        buttonContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            gap: (WW * 0.17) / 2,
            marginTop: WH * 0.1,
        },
        button: {
            borderRadius: WH * 0.5,
            width: WW * 0.17,
            height: WW * 0.17,
            borderWidth: 3,
            justifyContent: 'center',
            alignItems: 'center',
        },
        positive: {
            borderColor: theme.green,
        },
        negative: {
            borderColor: theme.red,
        },
        subtitle: {
            color: theme.darkGray,
        },
    });

export default ApproveCleaning;
