import React, { ReactElement, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useOwnProfile } from '_api/useProfile';
import { useSurvey } from '_api/useSurveys';
import { Theme, useThemeStyle } from '_utils';
import { useSelectedCoopItem } from '_utils/hooks';
import { screenMargin, titleFontSize } from '_utils/sizes';
import { EmptyMessage, HeimeText, Icon, ListItem, QueryView } from 'Components';
import { useSelectedCoop } from 'SelectedCoop';
import { useSurveyContext } from '../SurveyContext';
import { useSurveyNavigation } from '../SurveyNavigation';

const SelectApartment = (): ReactElement => {
    const { t } = useTranslation();
    const themedStyle = useThemeStyle(styles);
    const selectedCoopId = useSelectedCoop();
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';
    const {
        state: { surveyId },
    } = useSurveyContext();
    const { data: profile, refetch: refetchProfile, isRefetching: isRefetchingProfile } = useOwnProfile();
    const { data: survey, refetch: refetchSurvey, isRefetching: isRefetchingSurvey } = useSurvey(surveyId);
    const { push } = useSurveyNavigation();
    const { openSelectApartment } = useSurveyContext();

    useFocusEffect(
        useCallback(() => {
            openSelectApartment();
        }, [openSelectApartment]),
    );

    const apartments =
        (profile?.cooperative_apartments &&
            profile?.cooperative_apartments.filter(({ cooperative_id }) => cooperative_id === selectedCoopId)) ??
        [];

    const handleSelectApartment = (apartmentId: number) => {
        push('SurveyDetails', { apartmentId: apartmentId + '' });
    };

    const refetch = () => {
        refetchProfile();
        refetchSurvey();
    };

    const isRefetching = isRefetchingProfile || isRefetchingSurvey;

    return (
        <QueryView
            style={themedStyle.main}
            top={<HeimeText style={themedStyle.headerQuestion}>{t('surveys:selectApartment')}</HeimeText>}
            data={apartments}
            renderItem={({ item }) => (
                <ListItem
                    onPress={() => handleSelectApartment(item.id)}
                    title={item.name}
                    titleStyle={themedStyle.itemTitle}
                    actionButton={
                        survey?.responses.find(
                            (response) => response.status === 'SENT' && response.apartment_id === item.id,
                        ) ? (
                            <Icon name="checkCircle" color="main" />
                        ) : survey?.responses.find(
                              (response) => response.status === 'DRAFT' && response.apartment_id === item.id,
                          ) ? (
                            <Icon name="editPencil" color="main" />
                        ) : null
                    }
                />
            )}
            emptyList={
                <View style={themedStyle.messageContainer}>
                    <EmptyMessage
                        message={t('surveys:noApartment', {
                            boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                        })}
                    />
                </View>
            }
            onRefresh={refetch}
            isRefreshing={isRefetching}
        />
    );
};
const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.lightBackground,
            width: '100%',
            flex: 1,
            display: 'flex',
            paddingRight: screenMargin,
            paddingLeft: screenMargin,
            paddingBottom: screenMargin,
        },
        itemTitle: { color: theme.main },
        headerQuestion: {
            fontSize: titleFontSize,
            fontWeight: 'bold',
            marginTop: screenMargin,
        },
        messageContainer: {
            paddingRight: screenMargin,
            paddingLeft: screenMargin,
            height: '100%',
            flex: 1,
            marginTop: '20%',
        },
    });

export default SelectApartment;
