import React, { ReactElement, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Theme, WH, WW, useThemeStyle } from '_utils';
import { capitalizeFirstLetter } from '_utils/misc';
import { smallestMargin } from '_utils/sizes';
import Icon from 'Components/Icon/Icon';
import ModalDatePicker from './ModalDatePicker';

interface TimeSelectorHeaderProps {
    value: Moment;
    onChangeDate(newDate: Moment, step: boolean, skipAnimation: boolean): void;
    isShortTerm: boolean;
}

const TimeSelectorHeader = ({ value, onChangeDate, isShortTerm }: TimeSelectorHeaderProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const [showCalendar, setShowCalendar] = useState(false);

    const handleNextPress = () => {
        if (isShortTerm) {
            onChangeDate(moment(value).add({ day: 1 }), true, false);
        } else {
            onChangeDate(moment(value).add({ month: 1 }), true, false);
        }
    };

    const handlePreviousPress = () => {
        if (isShortTerm) {
            onChangeDate(moment(value).add({ day: -1 }), true, false);
        } else {
            onChangeDate(moment(value).add({ month: -1 }), true, false);
        }
    };

    const handleChangeDate = (item: Date) => onChangeDate(moment(item), false, showCalendar);

    const handleToggleCalendar = () => setShowCalendar((curr) => !curr);
    const handleCloseCalendar = () => setShowCalendar(false);

    const minDate = useMemo(() => moment().startOf('day').subtract(1, 'year').toDate(), []);
    const disablePrevious = value.isSame(minDate, isShortTerm ? 'day' : 'month');
    const header = useMemo(() => {
        const differentYear = !moment().isSame(value, 'year');

        return `${isShortTerm ? value.format('DD. ') : ''} ${capitalizeFirstLetter(value.format('MMMM'))} ${
            differentYear ? value.format('YYYY') : ''
        }`;
    }, [isShortTerm, value]);

    return (
        <>
            <View style={themedStyle.navigation}>
                <TouchableOpacity
                    onPress={disablePrevious ? undefined : handlePreviousPress}
                    style={[
                        themedStyle.button,
                        themedStyle.rotate,
                        disablePrevious ? themedStyle.disabledButton : undefined,
                    ]}
                >
                    <View style={themedStyle.buttonColor}>
                        <Icon name="chevron" color="main" />
                    </View>
                </TouchableOpacity>
                <TouchableOpacity style={themedStyle.middleButton} onPress={handleToggleCalendar}>
                    <View style={[themedStyle.buttonColor, themedStyle.middleButtonContainer]}>
                        <Text style={themedStyle.title}>{header}</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={handleNextPress} style={themedStyle.button}>
                    <View style={themedStyle.buttonColor}>
                        <Icon name="chevron" color="main" />
                    </View>
                </TouchableOpacity>
            </View>
            {showCalendar ? (
                <ModalDatePicker
                    value={value}
                    minimumDate={minDate}
                    onChange={handleChangeDate}
                    onClose={handleCloseCalendar}
                    display={isShortTerm ? 'calendar' : 'spinner'}
                />
            ) : null}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        navigation: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: WW * 0.06,
            paddingRight: WW * 0.06,
            marginTop: WH * 0.02,
        },
        disabledButton: {
            opacity: 0.3,
        },
        middleButton: {
            flexGrow: 1,
            alignItems: 'center',
        },
        middleButtonContainer: {
            padding: smallestMargin,
        },
        buttonColor: {
            backgroundColor: theme.mainBackground,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: theme.secondaryLight,
            padding: 2,
        },
        rotate: { transform: [{ rotate: '180deg' }] },
        button: { paddingTop: 8, paddingBottom: 8, paddingLeft: 8, paddingRight: 8 },
        title: { fontWeight: 'bold' },
    });

export default TimeSelectorHeader;
