import React, { PropsWithChildren, ReactElement } from 'react';
import { StyleSheet } from 'react-native';
import HeimeText from './HeimeText';
import { Theme, useThemeStyle } from '../_utils';

const ErrorMessage = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return <HeimeText style={themedStyle.text}>{children}</HeimeText>;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        text: {
            marginBottom: 16,
            justifyContent: 'center',
            textAlign: 'center',
            color: theme.red,
        },
    });
export default ErrorMessage;
