import React, { ReactElement, useContext } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { Svg, Path } from 'react-native-svg';
import { WW, isiOS } from '_utils';
import { smallestMarginPixels } from '_utils/sizes';
import { ThemeContext } from '_utils/themeContext';
import { buttonHeight, buttonWidth, svgHeight } from './constants';

const cFunc = ({
    startingDX,
    startingDY,
    endingFuncX,
    endingFuncY,
    endingPosX,
    endingPosY,
}: {
    startingDX: number;
    startingDY: number;
    endingFuncX: number;
    endingFuncY: number;
    endingPosX: number;
    endingPosY: number;
}) => {
    return `c ${startingDX} ${startingDY} ${endingFuncX} ${endingFuncY} ${endingPosX} ${endingPosY}`;
};

const straightWidth = 2 * buttonWidth - smallestMarginPixels * 3,
    firstStartDX = smallestMarginPixels * 0.75,
    firstStartDY = 0,
    firstEndDX = smallestMarginPixels * 2.25,
    firstEndDY = smallestMarginPixels / 4,
    firstEndingPosX = smallestMarginPixels * 3,
    firstEndingPosY = smallestMarginPixels * 2.5,
    secondStartDX = smallestMarginPixels * 0.75,
    secondStartDY = buttonHeight / 2 - smallestMarginPixels * 2,
    secondEndDX = buttonWidth / 2 - smallestMarginPixels * 2,
    secondEndDY = buttonHeight / 2 - smallestMarginPixels,
    secondEndingPosX = buttonWidth / 2,
    secondEndingPosY = buttonHeight / 2 - smallestMarginPixels;

const d = [
    'M 0 0',
    `h ${straightWidth}`,
    cFunc({
        startingDX: firstStartDX,
        startingDY: firstStartDY,
        endingFuncX: firstEndDX,
        endingFuncY: firstEndDY,
        endingPosX: firstEndingPosX,
        endingPosY: firstEndingPosY,
    }),
    cFunc({
        startingDX: secondStartDX,
        startingDY: secondStartDY,
        endingFuncX: secondEndDX,
        endingFuncY: secondEndDY,
        endingPosX: secondEndingPosX,
        endingPosY: secondEndingPosY,
    }),
    cFunc({
        startingDX: secondEndingPosX - secondEndDX,
        startingDY: -secondEndingPosY + secondEndDY,
        endingFuncX: secondEndingPosX - secondStartDX,
        endingFuncY: -secondEndingPosY + secondStartDY,
        endingPosX: secondEndingPosX,
        endingPosY: -secondEndingPosY,
    }),
    cFunc({
        startingDX: firstEndingPosX - firstEndDX,
        startingDY: -firstEndingPosY + firstEndDY,
        endingFuncX: firstEndingPosX - firstStartDX,
        endingFuncY: -firstEndingPosY + firstStartDY,
        endingPosX: firstEndingPosX,
        endingPosY: -firstEndingPosY,
    }),
    `h ${straightWidth}`,
    `v ${svgHeight}`,
    `h -${WW}`,
    'Z',
].join(' ');

const SvgBackground = ({ style }: { style?: StyleProp<ViewStyle> }): ReactElement => {
    const { theme } = useContext(ThemeContext);
    return (
        <Svg
            width={WW}
            height={svgHeight}
            style={[styles.svg, style]}
            viewBox={`0 0 ${WW} ${svgHeight}`}
            fill="none"
            stroke={isiOS() ? 'none' : theme.lightGrey}
        >
            <Path d={d} fill={theme.mainBackground} />
        </Svg>
    );
};

const styles = StyleSheet.create({
    svg: {
        position: 'absolute',
        backgroundColor: 'transparent',
        filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))',
    } as ViewStyle,
});

export default SvgBackground;
