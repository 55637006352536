import { FileToBeUploaded, FileUpload, isFileToBeUploaded, isStaleFile } from '../../types/Utility';

const useFileState = (
    files: FileUpload[],
    onChange: (newFiles: FileUpload[]) => void,
): {
    onAdd: (newFiles: Array<FileToBeUploaded | { path: string; contentType: string }>) => void;
    onDelete: (itemToDelete: FileUpload) => void;
} => {
    const onAdd = (addedImages: Array<FileToBeUploaded | { path: string; contentType: string }>) => {
        const initIndex = files.length > 0 ? Math.max(...files.map(({ index }) => index)) + 1 : 0;
        const newImages = [
            ...files,
            ...addedImages.map((img, num) => ({
                ...img,
                index: initIndex + num,
            })),
        ];
        if (isFileUploadArray(newImages)) {
            onChange(newImages);
        } else {
            throw new Error('newImages is not a FileUpload[], this is unsupported');
        }
    };

    const onDelete = (itemToDelete: FileUpload) => {
        if (isFileToBeUploaded(itemToDelete)) {
            onChange(files.filter((file) => !isFileToBeUploaded(file) || file.uri !== itemToDelete.uri));
        } else if (isStaleFile(itemToDelete)) {
            onChange(
                files.map((file) =>
                    !isStaleFile(file) || file.id !== itemToDelete.id ? file : { ...file, status: 'deleted' },
                ),
            );
        }
    };

    return { onAdd, onDelete };
};
type PathContentType = { path: string; contentType: string };

const isFileUploadArray = (array: Array<FileUpload | PathContentType>): array is FileUpload[] => {
    return array.every(isFileUpload);
};

const isFileUpload = (file: FileUpload | PathContentType): file is FileUpload => {
    return ['stale', 'deleted', 'added'].includes((file as FileUpload).status);
};

export default useFileState;
