import { ReactElement, Fragment, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { getUsernameFromProfile } from '_utils/misc';
import { LabelValue, UserListItem } from 'Components';
import { TenantRole } from 'types/Apartment';
import useGetRoommates from './useGetRoommates';

const ApartmentNeighbors = ({
    profileId,
    labelStyle,
    onNavigateToUser,
}: {
    profileId: number;
    labelStyle: ComponentProps<typeof LabelValue>['style'];
    onNavigateToUser(id: number): void;
}): ReactElement | null => {
    const { t } = useTranslation();
    const apartmentsAndRoommates = useGetRoommates(profileId);
    if (apartmentsAndRoommates.length === 0) {
        return null;
    }

    const hasMultipleApartments = apartmentsAndRoommates.length > 1;

    const getLabel = (ownRole: TenantRole, role: TenantRole, code: string) => {
        switch (ownRole) {
            case 'owner':
                switch (role) {
                    case 'owner':
                        return hasMultipleApartments
                            ? t('profile:owningApartmentWith', { code })
                            : t('profile:owningWith');
                    case 'livesWith':
                        return hasMultipleApartments
                            ? t('profile:livingApartmentWith', { code })
                            : t('profile:livingWith');
                    case 'renting':
                        return hasMultipleApartments
                            ? t('profile:rentingApartmentTo', { code })
                            : t('profile:rentingTo');
                    case 'other':
                        return hasMultipleApartments ? t('profile:otherApartment', { code }) : t('profile:other');
                    default:
                        return 'unknown';
                }
            case 'livesWith':
                switch (role) {
                    case 'owner':
                    case 'renting':
                    case 'livesWith':
                        return hasMultipleApartments
                            ? t('profile:livingApartmentWith', { code })
                            : t('profile:livingWith');
                    case 'other':
                        return hasMultipleApartments ? t('profile:otherApartment', { code }) : t('profile:other');
                    default:
                        return 'unknown';
                }
            case 'renting':
                switch (role) {
                    case 'owner':
                        return hasMultipleApartments
                            ? t('profile:rentingApartmentFrom', { code })
                            : t('profile:rentingFrom');
                    case 'livesWith':
                        return hasMultipleApartments
                            ? t('profile:livingApartmentWith', { code })
                            : t('profile:livingWith');
                    case 'renting':
                        return hasMultipleApartments
                            ? t('profile:rentingApartmentWith', { code })
                            : t('profile:rentingWith');
                    case 'other':
                        return hasMultipleApartments ? t('profile:otherApartment', { code }) : t('profile:other');
                    default:
                        return 'unknown';
                }
            case 'other':
                return hasMultipleApartments ? t('profile:otherApartment', { code }) : t('profile:other');
        }
    };

    return (
        <>
            {apartmentsAndRoommates.map(({ name, id, own_role, usersByRole }) =>
                Object.entries(usersByRole).map(([usersRole, users]) => (
                    <Fragment key={`${id}-${usersRole}`}>
                        <LabelValue
                            label={getLabel(own_role, usersRole as TenantRole, name)}
                            value={`(${users.length})`}
                            style={labelStyle}
                            switched={true}
                        />
                        {users.map((item) => {
                            const handleNavigateNeighborhood = () => {
                                onNavigateToUser(item.id);
                            };
                            return (
                                <UserListItem
                                    key={item.id}
                                    title={getUsernameFromProfile(item)}
                                    id={item.id}
                                    desc={''}
                                    onPress={handleNavigateNeighborhood}
                                />
                            );
                        })}
                    </Fragment>
                )),
            )}
        </>
    );
};

export default ApartmentNeighbors;
