import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios from 'axios';
import { useGetAccessTokenHeader } from '../_utils/Axios';

let storingDevice = false;

const useStoreDevice = (): UseMutationResult<
    { success: string; device_id: string },
    string | Error,
    { device_token: string; locale: string; device_type: 'ios' | 'android' }
> => {
    const getAuthHeader = useGetAccessTokenHeader();

    return useMutation({
        mutationFn: async (body) => {
            if (storingDevice) {
                return;
            }
            storingDevice = true;
            const result = await axios.post('/device_token', body, {
                headers: {
                    authorization: await getAuthHeader(),
                },
            });
            if (!result.data.success) {
                throw new Error('Storing device returned unsuccessful result');
            }
            storingDevice = false;
            return result.data;
        },
        retry: 3,
    });
};

const usePatchDevice = (): UseMutationResult<
    { success: string },
    string | Error,
    [string, { device_token: string; locale: string; device_type: 'ios' | 'android' }]
> => {
    const getAuthHeader = useGetAccessTokenHeader();

    return useMutation({
        mutationFn: async ([id, body]) => {
            const result = await axios.patch(`/device_token/${id}`, body, {
                headers: {
                    authorization: await getAuthHeader(),
                },
            });
            if (!result.data.success) {
                throw new Error('Patching device returned unsuccessful result');
            }

            return result.data;
        },
        retry: 3,
    });
};

const useDeleteDevice = (): UseMutationResult<{ success: boolean }, string | Error, string> => {
    const getAuthHeader = useGetAccessTokenHeader();

    return useMutation({
        mutationFn: async (id) => {
            const result = await axios.delete(`/device_token/${id}`, {
                headers: {
                    authorization: await getAuthHeader(),
                },
            });
            if (!result.data.success) {
                throw new Error('Deleting device returned unsuccessful result');
            }

            return result.data;
        },
    });
};

export { useStoreDevice, usePatchDevice, useDeleteDevice };
