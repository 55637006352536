import { z } from 'zod';
import { ApiImageSchema } from './Base';
import { ProductPaymentStrategySchema } from './Category';
import { ArrayElement, LaravelBooleanSchema } from './Utility';

const ReservationSchema = z.object({
    id: z.number(),
    reason: z.string().nullable(),
    start_at: z.number(),
    end_at: z.number(),
    skip_payment: z.boolean(),
    quantity: z.number(),
});

const BaseLockSchema = z.object({
    id: z.number(),
    name: z.string(),
    mac: z.string(),
    battery_percent: z.number().nullable(),
});

const DanalockSchema = BaseLockSchema.merge(
    z.object({
        type: z.union([z.literal('danalock'), z.literal('danalockRelay')]),
        token: z.string().nullable(),
        pin: z.string().nullable(),
        has_pin: z.boolean(),
        has_bridge: z.boolean(),
        open_remote: z.boolean(),
        secret_key: z.string().nullable(),
    }),
);

const MasterLockSchema = BaseLockSchema.merge(
    z.object({
        type: z.union([z.literal('masterLock5441'), z.literal('masterLock4401'), z.literal('masterLock4400')]),
        firmwareVersion: z.number(),
        secret_key: z.string(),
    }),
);
const OmniLockSchema = BaseLockSchema.merge(
    z.object({
        type: z.union([z.literal('cable'), z.literal('cabinet')]),
    }),
);

// We are not expanding the one above, because we are not doing so in the backend
export const OwnReservationSchema = z.object({
    id: z.number(),
    reason: z.string().nullable(),
    start_at: z.number(),
    end_at: z.number(),
    skip_payment: LaravelBooleanSchema,
    quantity: z.number(),
    booked: z.object({
        id: z.number(),
        name: z.string(),
        capacity: z.number(),
        picture: ApiImageSchema.nullable(),
        locks: z.array(z.union([DanalockSchema, MasterLockSchema, OmniLockSchema])),
        paymentStrategy: ProductPaymentStrategySchema,
        refundable: LaravelBooleanSchema,
        refund_hours_before: z.number().nullable(),
        booking_time: z.boolean(),
    }),
    check_in: z.string().nullable(),
    has_checked_in: z.boolean(),
    check_out: z.string().nullable(),
    has_checked_out: z.boolean(),
    previous_users: z.object({ last_booking_end: z.number(), users: z.array(z.number()) }).nullable(),
});

export type OwnReservation = z.infer<typeof OwnReservationSchema>;

export type Lock = ArrayElement<OwnReservation['booked']['locks']>;

export type Reservation = z.infer<typeof ReservationSchema>;
