export default {
    enterPhone: {
        title: 'Skriv inn ditt telefonnummer',
        subText: 'Vi sender deg en kode på sms hvis du ikke har satt egen kode i Heime',
        inputPlaceholder: 'Skriv inn telefonnummer',
        errorPhoneNotFound:
            'Beklager, telefonnummeret du skrev inn er ikke koblet til et boligselskap i Heime. Ta kontakt med oss',
        errorGeneric: 'Beklager det oppsto en feil, prøv igjen',
        errorNoNetwork: 'Fikk nettverksfeil, har du internetttilkobling?',
        button: 'Gå videre',
        repeatNumber: 'Gjenta telefonnummer',
        repeatNumberSubText:
            "Vi fant ikke ditt telefonnummer i vårt system. Vennligst skriv inn ditt telefonnummer på nytt for å bekrefte at '{{enteredNumber}}' er ditt telefonnummer.",
    },
    enterPin: {
        back: 'TILBAKE',
        titlePin: 'Vennligst skriv inn din heime pinkode',
        titleSMS: 'Skriv inn koden du mottok på sms',
        subTextPin: 'Glemt pin kode?',
        subTextSMS: 'Ikke motatt sms?',
        sendMeSms: 'Send meg sms',
        clickableTextSMS: 'Send på ny',
        wrongCodeSentSMS: 'Du skrev inn feil kode flere ganger, vi har sendt deg en sms med ny kode.',
        wrongCode: 'Du skrev inn feil kode, prøv igjen.',
        error: 'Det oppsto en feil',
        button: 'Fortsett',
        noNetwork: 'Fikk nettverksfeil, har du internetttilkobling?',
    },
    selectNumber: {
        title: 'Du skrev to ulike telefonnummer, velg det som er riktig',
        select: 'Velg',
    },
    confirmUser: {
        title: 'Bor eller eier du bolig i et Heime nabolag?',
        subTitle: "Hvis du burde ha tilgang til Heime, trykk på 'Bekreft'",
        negative: 'Nei',
        positive: 'Bekreft',
        modalTitle: 'Ta kontakt',
        modalText:
            'Kun brukere knyttet til aktive nabolag, får tilgang til Heime. Er du interessert i å ta i bruk Heime, kan du ta kontakt med oss via kanalene nedenfor.',
    },
    createRegistration: {
        title: 'Fyll inn din informasjon',
        subtitle:
            'Vi trenger denne informasjonen om deg for å kunne koble deg til ditt nabolag, og kunne verifisere at du skal ha tilgang. Ved å sende inn denne informasjonen godtar du at vi lagrer og behandler den.',
        firstNameLabel: 'Fornavn',
        lastNameLabel: 'Etternavn',
        phoneLabel: 'Telefonnummer',
        emailLabel: 'Epost',
        projectLabel: 'Nabolag',
        apartmentLabel: 'Addresse / Bolignummer',
        send: 'Send inn',
        project_error: 'Du må beskrive nabolaget',
        apartment_error: 'Du må beskrive boligen',
        projectPlaceholder: 'Navnet på salgsprosjektet, sameiet, borettslaget eller adressen',
        apartmentPlaceholder: 'Gatenavn husnummer h-nummer',
        createError: 'Klarte ikke registrere deg',
        createErrorText: 'Det oppsto en feil, prøv igjen senere. Går det ikke, ta kontakt med Heime',
        updateError: 'Klarte ikke oppdatere registreringen',
        updateErrorText: 'Det oppsto en feil, prøv igjen senere. Går det ikke, ta kontakt med Heime',
    },
    registrationSelected: {
        status: 'Status:',
        waiting: 'Venter',
        approved: 'Godkjent',
        rejected: 'Avvist',
        titlePending: 'Din registering er mottatt',
        subTextPending:
            'Din registrering vil bli behandlet av styret/administratorer i ditt nabolag. Du vil motta en sms når det har skjedd en oppdatering. Dette kan ta opptil 5 virkedager. Dette gjøres for å sikre at kun dine nabober får tilgang til nabolaget',
        titleApproved: 'Din registrering er godkjent',
        subTextApproved: "Du kan nå logge inn i Heime og få tilgang til ditt nabolag's innhold",
        titleRejected: 'Din registrering ble ikke godkjent',
        subTextRejected:
            'Din registering for {{neighborhood}}, {{apartment}} ble ikke godkjent, ta kontakt med styret/administratorer eller Heime om dette er feil',
        update: 'Oppdater din informasjon',
        cancel: 'Avbryt registrering',
        confirmCancel: 'Er du sikker på at du vil avbryte registreringen?',
        proceed: 'Gå videre',
        contact: 'Kontakt oss',
        tryAgain: 'Prøv igjen',
    },
    chooseCoop: {
        title: 'Velg et nabolag',
        noCoops: 'Du er ikke koblet til noen nabolag i Heime, kontakt support.',
    },
    home: {
        notices: 'Oppslag',
        activities: 'Aktiviteter',
        services: 'Reservasjoner',
        sharing: 'Nabodeling',
        next: 'Hva skjer fremover?',
        noActivities: 'Det er ikke planlagt noen aktiviteter.',
        createActivitySuggestion: 'Hva med å opprette en?',
    },
    neighbor: {
        sharingForum: 'Ting til utlån',
        chat: 'Chat',
        myNeighbors: 'Mine naboer',
        resourcePersons: 'Hjelpsomme nabo',
        title: 'Nabodeling',
    },
    profile: {
        title: 'Min profil',
        titleNotOwnProfile: 'Brukerprofil',
        phone: 'Telefon:',
        email: 'Epost:',
        groups: 'Grupper',
        groupMembers: 'Medlemmer',
        birthDate: 'Alder og bursdag:',
        edit: 'Rediger profil',
        setUpPin: 'Sett opp PIN',
        sendFeedback: 'Gi stjerne',
        back: 'Chat med naboen',
        chat: 'Chat',
        message: 'Chat',
        call: 'Ring',
        about_me: 'Om meg:',
        updateInfoModal: {
            namesDescription: 'Har allerede beskrevet seg selv til naboene',
            title: 'La naboene bli bedre kjent med deg',
            description: 'Last opp et bilde og skriv litt om deg selv',
            namesDescription_no_about: 'Har allerede beskrevet seg selv til naboene',
            description_no_about: 'Skriv litt om deg selv, slik at naboene kan huske noe ved deg',
            namesDescription_no_avatar: 'Har allerede lastet opp et bilde',
            title_no_avatar: 'Gi postkassen et ansikt',
            description_no_avatar: 'Last opp et bilde av deg selv. Du kan også velge et bilde fra Facebook',
            checkbox: 'Spør meg aldri igjen',
            continue: 'Legg til info',
            back: 'Senere',
            and_more: ' og flere ',
        },
        owningApartmentWith: 'Eier {{code}} sammen med:',
        owningWith: 'Eier sammen med:',
        rentingApartmentFrom: 'Leier {{code}} av:',
        rentingFrom: 'Leier av:',
        rentingApartmentTo: 'Leier {{code}} til:',
        rentingTo: 'Leier til:',
        livingApartmentWith: 'Bor i {{code}} sammen med:',
        livingWith: 'Bor sammen med:',
        otherApartment: 'Koblet til bolig {{code}}:',
        other: 'Koblet til bolig:',
        rentingApartmentWith: 'Leier {{code}} sammen med:',
        rentingWith: 'Leier sammen med:',
        profileCompletion: 'Fullfør din profil',
        profileCompletionDescription: 'Fyll ut resten av detaljene så naboene kan bli bedre kjent med deg',
        profileCompletionModalHeader: 'Fullfør din profil',
        profileCompletionModalDescription: 'Fyll ut resten av detaljene så naboene kan bli bedre kjent med deg',
        profileCompletionModalAvatar: 'Legg til profilbilde',
        profileCompletionModalAboutMe: 'Skriv litt om deg selv',
        profileCompletionModalEmail: 'Legg til epost',
        profileCompletionModalBirthday: 'Legg til fødselsdag',
        profileCompletionModalJoinGroup: 'Bli med i en gruppe',
        profileCompletionModalButton: 'Lukk',
        parkingSpots: 'Parkeringsplass:',
        parkingSpots_plural: 'Parkeringsplasser:',
        deletedUser_explainer: 'Denne brukeren er ikke lenger i nabolaget',
        deletedUser_explainer_description:
            "Detaljene om denne brukeren er det kun tilgjengelig for nabolagsadministratorer som deg. Andre naboer ser bare denne brukeren som '{{name}}'",
    },
    myHome: {
        title: 'Min bolig',
        noResult: 'Du er ikke koblet til en bolig i dette nabolaget',
        info: 'Info',
        documents: 'Dokumenter',
        editInfo: 'Rediger info',
        project_name: 'Bolignavn:',
        legal_name: 'Seksjonsnummer / Andelsnummer:',
        address: 'Adresse:',
        type: 'Boligtype:',
        apartment: 'Leilighet',
        detached_house: 'Enebolig',
        semi_detached_house: 'Rekkehus',
        room: 'Rom',
        floor: 'Etasje: ',
        area: 'Areal: ',
        living_area: 'Primærareal:',
        bedrooms: 'Antall soverom:',
        area_value: '{{area}} m²',
        parking_spot: 'Parkeringsplass:',
        apartment_groups: 'Medlem av disse bolig-gruppene:',
        tenants: 'Brukere',
        addTenant: 'Legg til bruker',
        role_owner: 'Eier',
        role_livesWith: 'Bor med',
        role_renting: 'Leietaker',
        role_other: 'Annet',
        editUser: 'Rediger bruker',
        removeUser: 'Fjern bruker',
        removeTenantConfirmHeader: 'Er du sikker på at du vil fjerne brukeren?',
        removeTenantConfirm: 'Fjern',
        removeTenantCancel: 'Avbryt',
        removeTenantConfirmText: 'Ved å fjerne brukeren fra boligen vil brukeren ikke lenger ha tilgang til nabolaget.',
        removeTenantConfirmText2: 'Ved å fjerne brukeren fra boligen vil brukeren miste tilgang til boligen i Heime.',
    },
    editHome: {
        title: 'Rediger info',
        save: 'Lagre endringer',
        codeExists: '{{param}} eksisterer allerede, for å bytte bolig ta kontakt med {{coopType}}',
    },
    tenantEdit: {
        addUser: 'Legg til bruker',
        addExplainer: 'Start med å skrive inn telefonnummeret til brukeren',
        phone: 'Telefonnummer',
        phonePlaceholder: 'Skriv inn telefonnummer',
        continue: 'Fortsett',
        role: 'Rolle',
        owner: 'Eier',
        livesWith: 'Bor med',
        renting: 'Leietaker',
        other: 'Annet',
        addDetails: 'Legg til mer info',
        notify: 'Vil du varsle brukeren?',
        notifyNone: 'Nei',
        notifySms: 'Ja, med sms',
        notifyEmail: 'Ja, med epost',
        phoneTooLong: 'Telefonnummeret er for langt',
    },
    navigation: {
        home: 'Hjem',
        groups: 'Grupper',
        chat: 'Chat',
        more: 'Mer',
        bellExplainer: 'Åpne notifikasjoner',
        profileExplainer: 'Åpne din profil',
        plusExplainer: 'Trykk her for å opprette noe',
        reservations: 'Reservasjoner',
        go_home: 'Gå tilbake til hjemskjerm',
        go_back: 'Gå tilbake',
    },
    moreMenu: {
        myProfile: 'Min profil',
        myHome: 'Min bolig',
        services: {
            title: 'Reservasjoner',
            doReservation: 'Gjør en reservasjon',
            yourReservations: 'Dine reservasjoner',
        },
        resourcePersons: 'Hjelpsom nabo',
        neighbors: 'Naboer',
        board: {
            title: '{{boardNounCapitalized}}',
            members: 'Kontakter',
            documents: 'Dokumenter',
            messageToBoard: 'Meldinger til {{boardNoun}}',
        },
        terms: 'Salgsbetingelser',
        settings: 'Innstillinger',
        helpfulNeighbors: 'Hjelpsomme nabo',
        Products: 'Ting til utlån',
        contact: 'Support',
        selectCoop: 'Bytt nabolag',
        logOut: 'Logg ut',
        memberBenefits: 'Beboeravtaler',
        surveys: 'Spørreundersøkelser',
    },
    footer: {
        label: 'Support:',
    },
    contacts: {
        title: 'KONTAKT OSS',
        phoneTitle: 'Telefonnummer:',
        emailTitle: 'E-post:',
        messageTitle:
            'Dersom du lurer på noe som har med nabolaget å gjøre, så anbefaler vi at du tar kontakt med {{boardNoun}} i ditt nabolag.',
        messageLineOne: 'Har du funnet en feil, eller har andre forslag til hva vi kan gjøre?\n',
        messageLineTwo:
            'Vi vil i såfall veldig gjerne høre fra deg! Du kan da sende en epost til oss ved å trykke på epostadressen.\n',
        boardButton: 'Se info om {{boardNoun}}',
        support: 'Åpningstider telefon: \nMan-Fre 09:00 - 16:00',
    },
    activities: {
        tabOne: 'kommende',
        tabTwo: 'påmeldt',
        tabThree: 'Tidligere',
        signedUp: 'Påmeldt',
        add_activity: 'OPPRETT AKTIVITET',
        title: 'Aktiviteter',
        signed_up: 'Påmeldt',
        not_signed_up: 'Ikke påmeldt',
        empty_future:
            'Det er ingen planlagte aktiviteter ennå.\nOpprett en aktivitet eller registrer din interesse blant forslagene',
        empty_futureOnlyAttending: 'Du er ikke påmeldt til noen aktiviteter.',
        empty_previous: 'Det er ingen tidligere aktiviteter.',
        empty_button_suggestion: 'Se forslag',
        empty_button_create: 'Opprett aktivitet',
    },
    activity: {
        signedUp: 'Påmelding fullført',
        signUpSelf: 'Meld på deg selv',
        Kr: 'KR',
        cancelModal: {
            header: 'Er du sikker på at du vil avlyse aktiviteten?',
            header_plural: 'Er du sikker på at du vil slette aktiviteten?',
            description: 'Deltakere vil motta en varsel om at aktiviteten er avlyst',
            checkbox: 'Refunder deltakere',
            back: 'NEI, GÅ TILBAKE',
            approve: 'JA, AVLYS',
            approve_plural: 'JA, SLETT',
        },
        error: 'Det skjedde en feil',
        suggestions: {
            press_here_title: 'Forslag til aktiviteter',
            press_here_description: 'Trykk her for å se og melde interesse for aktiviteter',
            press_here_title_other_neighborhood: 'Få inspirasjon fra andre nabolag',
            press_here_description_other_neighborhood: 'Trykk her for å se populære aktiviteter',
            images_banner_cta: 'Se forslag',
            images_banner_top: 'Finn din neste aktivitet',
            images_banner_title: 'Trykk her for aktiviteter',
            images_banner_cta_other_neighborhood: 'Se aktiviteter',
            images_banner_top_other_neighborhood: 'Inspirasjon til aktiviteter',
            images_banner_title_other_neighborhood: 'Trykk her for å se aktiviteter fra andre nabolag',
            direct_press_create: 'Opprett ny aktivitet',
            header: 'Forslag til aktiviteter',
            subtitle: 'Hvilken aktivitet kunne du tenke deg å delta på?',
            template_example: 'Eksempel på beskrivelse',
            coffee_title: '☕ Kaffeprat',
            coffee_description: 'Bli med på en hyggelig kaffeprat i fellesområdet, hjemme eller ute i en park.',
            coffee_arrange_description:
                'Får å arrangere en kaffeprat, holder det å ha et sted å møtes. Dere kan bestemme om noen også skal sette på en kaffekanne, og om naboene tar med egen kopp. Varighet kan være alt fra en halvtime til at naboene kan komme innom i løpet av en 3 timers periode.',
            coffee_arrange_description_example:
                'La oss møtes for en hyggelig drøs 👨 💬  👩 Setter meg i fellesrommet. Ta med deg en termos kaffe og godt humør! ☕ 🫖 ',
            coffee_arrange_location_example: 'Fellesrommet',
            christmas_lighting_title: '🎄 Julegrantenning',
            christmas_lighting_description:
                'Opplev julemagien sammen i nabolaget. Samles rundt juletreet og nyt stemningen.',
            christmas_lighting_arrange_description:
                'For å arrangere en julegrantenning kan dere enten kjøpe inn juletre, spørre boligselskapet om midler, eller lage et spleiselag. Det trengs at noen henger opp lys og setter opp treet. Hvis dere ønsker kan man også ta med julekaker og gløgg. Barna kan også være forsangere for julesanger og på rundt treet etter at lyset er slått på.',
            christmas_lighting_arrange_description_example:
                'La oss få i gang julemagien! Vi trenger lys 💡 musikk 🎶 og godt humør! 🥳 Noen må få ordnet lys og tre 🎄 Andre kan ordne med julekaker og gløgg. Gi beskjed til undertegnede hva du fikser. Vel møtt! 😌',
            christmas_lighting_arrange_location_example: 'Mellom blokkene',
            bbq_title: '🔥 Grillfest',
            bbq_description: 'Det blir fyr i grillen og god stemning. Ta med noe godt å spise og drikke.',
            bbq_arrange_description:
                'For å arrangere en grillfest trenger dere en grill. Dere kan lage et spleiselag for å kjøpe inn mat og drikke, eller at naboene tar med egen mat. En høyttaler med litt musikk hjelper også!',
            bbq_arrange_description_example:
                'Hei! Jeg griller i dag 🍖 noen interessert i å hive seg med på det? Jeg har en helt ålreit grill. Ta med deg det du selv ønsker å grille 🥩🌽🌶️',
            bbq_arrange_location_example: 'Gårdsplass',
            quiz_title: '🧠 Quiz',
            quiz_description: 'Test din kunnskap med naboene dine. Lag lagnavn og sett i gang konkurransen.',
            quiz_arrange_description:
                'For å arrangere en quiz trenger dere noen spørsmål. Du kan søke etter en quiz på internett, eller lage din egen, det er fint å ha en quizmaster. Det er anbefalt å dele opp deltakerne i grupper, og gi dem oppgaven om å lage et lagnavn.',
            quiz_arrange_description_example:
                'La oss møtes for en hyggelig quiz. Jeg ordner med quiz. Ta med deg det du ønsker av snacks',
            quiz_arrange_location_example: 'Fellesstuen',
            sport_title: "⚽ Sport på tv'en",
            sport_description: 'Samles foran tv-skjermen for å se en idrettsbegivenhet sammen med naboene.',
            sport_arrange_description:
                'For å arrangere en sportskveld trenger dere en tv, en sportskanal og et arrangement å se. Kanskje en lokal fotballkamp, premier league, friidrett eller noe annet?',
            sport_arrange_description_example:
                'Jeg skal se litt sport i kveld. Jeg går ned 15 min før og får satt på riktig kanal. Ta med deg det du ønsker av snacks og bli med!',
            sport_arrange_location_example: 'Tv-rommet',
            common_dinner_title: '🍽️ Fellesmiddag',
            common_dinner_description: 'Lag middag sammen og nyt et spleiselag som skaper god stemning.',
            common_dinner_arrange_description:
                'For å arrangere en fellesmiddag kan dere lage en plan for hva som skal serveres og hvem som tar med hva. Det kan være en 3-retters middag, en tapas kveld eller en grillfest. Dere kan lage et spleiselag for å kjøpe inn mat og drikke, eller så kan naboene ta med hver sin ingrediens.',
            common_dinner_arrange_description_example:
                'Bli med på en hyggelig fellesmiddag. Jeg ordner med middag, men tar gjerne imot hjelp. Ta med deg det du ønsker å drikke selv',
            common_dinner_arrange_location_example: 'Fellesstuen',
            knitting_title: '🧶 Strikking',
            knitting_description: 'Møt for å strikke sammen. Ta med strikketøyet og kos deg med andre.',
            knitting_arrange_description:
                'For å arrangere et strikking trenger dere en plass å sitte å strikke. Dere kan lage et spleiselag for å kjøpe inn kaffe og te, eller så tar naboene med det de ønsker selv.',
            knitting_arrange_description_example:
                'Hei dere! Jeg trenger inspirasjon til neste strikkeprosjekt 🧶 så la oss møtes for en hyggelig strikkesesjon. Ta med strikketøyet også tar vi det derifra',
            knitting_arrange_location_example: 'Fellesrommet',
            walk_title: '👟  Felles gåtur',
            walk_description: 'Gå en tur i nabolaget sammen med naboene. Få frisk luft og godt selskap.',
            walk_arrange_description:
                'For å arrangere en felles gåtur trenger dere en rute å gå, og en tid å møtes. Husk å tenk på tempo på turen og opplys om dette. Det er gøy å planlegge en pause på en kafé eller en park på veien.',
            walk_arrange_description_example:
                'Inviterer til en trivelig fottur med naboene i nærområdet. Kle deg etter været også rusler vi sammen ☁️☀️☔',
            walk_arrange_location_example: 'Ved hovedutgangen',
            bathing_title: '🏊 Bading',
            bathing_description: 'Hopp i vannet. En helårsaktivitet for noen, en sommeraktivitet for andre.',
            bathing_arrange_description:
                'For å arrangere bading trenger dere et sted å bade, og en tid å møtes. Kan også kombineres med badstu om det finnes i nærheten.',
            bathing_arrange_description_example: 'La oss møtes for et hyggelig bad.',
            bathing_arrange_location_example: 'Ved kaien',
            party_title: '🎉 Nabolagsfest',
            party_description: 'Feir sammen med naboene, med eller uten en spesiell anledning.',
            party_arrange_description:
                'For å arrangere en nabolagsfest trenger dere et sted å være, og en tid å møtes. Dere kan lage et spleiselag for å kjøpe inn mat og drikke, eller arrangere at naboene tar med egen mat. Det er mulig med musikk og underholdning. Det kan være greit å samle en liten gjeng som tar ansvar for rydding til slutt.',
            party_arrange_description_example:
                'La oss samles 💃🕺 Det er lenge siden jeg så mange av dere, og vil derfor invitere alle til å treffes. Kom som du er, med det du ønsker 🥤🍻🍷🍿 Så samles vi i godt lag. PS: vi må rydde etter oss, og alle bidrag mot det blir høyt verdsatt 🙇',
            party_arrange_location_example: 'Fellesrommet',
            community_effort_title: '🤝 Dugnad',
            community_effort_description: 'Få noe gjort i nabolaget. Samle en gjeng og ta et tak sammen.',
            community_effort_arrange_description:
                'For å arrangere en dugnad trenger dere en arbeidsliste og oppmøtetidspunkt. Det er en god ide å slå dette sammen med litt hygge før/etter/under dugnaden',
            community_effort_arrange_description_example:
                'Hei alle sammen! Deler av fellesområdet hadde hatt godt av litt kjærlighet ❤️✨ Jeg kommer til å rake løv på parkeringsplassen 🍂 Gjerne meld om du kunne tenke deg å hjelpe meg.',
            community_effort_arrange_location_example: 'Felleshagen',
            workout_title: '💪 Trening eller idrett',
            workout_description: 'Tren sammen med naboene, det er alltid fint å ha noen som drar hverandre med',
            workout_arrange_description:
                'Finn en idrett eller treningsøkt dere kan gjøre sammen, enten ute eller inne. Beskriv gjerne nivået, eller spesifiser at alle kan komme som de er.  Noen forslag er å løfte vekter, spille fotball eller volleyball, spille tennis eller frisbee, eller å ha en felles yoga-økt.',
            workout_arrange_description_example:
                'La oss møtes for en treningsøkt 💪 Er det noen her som har et bra opplegg de kunne tenke seg å dele? 💪 Ellers har jeg funnet noe jeg kan følge, som tilsynelatende kan justeres etter nivå. 🧑‍🎓 Jeg tar gjerne imot hjelp til å få det til. 🙇',
            workout_arrange_location_example: 'Mellom blokkene',
            boardgames_title: '🎲 Brettspillkveld',
            boardgames_description: 'Spill gamle klassikere eller helt nye spill.',
            boardgames_arrange_description:
                'Foreslå dine egne brettspill eller etterspør naboene sine. Sett av noen timer en kveld og finn et egnet sted å gjennomføre.',
            boardgames_arrange_description_example:
                'Hei! Jeg vil spille brettspill, kunne du tenke deg å være med? 🎲 Jeg stiller med Trivial Pursuit. Har du alternativer så er de absolutt velkomne 🥳 ',
            boardgames_arrange_location_example: 'Fellesrommet',
            other_title: '💡 Annet',
            other_description: 'Har du en annen idé til en aktivitet? Trykk her.',
            other_arrange_description:
                'Du kan legge ut alle typer aktiviteter i Heime. Om du er usikker på interessen blant naboene kan du lufte interessen med en interessegruppe eller et oppslag.',
            interest: 'Jeg er interessert i å delta',
            interested_ask_neighbors: 'Spør naboer om interesse',
            arrange: 'Jeg vil arrangere dette',
            interested: 'Din interesse er notert',
            one_interested: '{{fname}} er interessert',
            two_interested: '{{fname1}} og {{fname2}} er interessert',
            three_or_four_interested: '{{names}} og {{fname}} er interessert',
            many_interested: '{{fname}} og {{count}} andre er interessert',
        },
        survey_activityNew: {
            header: 'Hvorfor fullførte du ikke aktiviteten?',
            content: 'Hjelp oss å forbedre prosessen. Det du har skrevet i aktiviteten vil ikke bli lagret.',
            survey_label: 'Tilbakemelding',
            survey_placeholder: 'Hva var uforståelig, vanskelig eller hvorfor avbrøt du?',
            allow_contact: 'Jeg kan kontaktes for oppfølging',
            submit: 'Send tilbakemelding',
            cancel: 'Hopp over, gå ut',
        },
        survey_activitySuggestion: {
            header: 'Hvorfor er du ikke interessert i aktiviteten?',
            content: 'Vi vil gjerne ha tilbakemelding for å kunne forbedre aktivitetene i ditt nabolag.',
            survey_label: 'Tilbakemelding',
            survey_placeholder: 'Beskriv hvorfor du ikke ønsker å melde interesse',
            allow_contact: 'Jeg kan kontaktes for oppfølging',
            submit: 'Send tilbakemelding',
            cancel: 'Hopp over',
        },
    },
    activityDetail: {
        you_booked: 'Du har {{count}} billett',
        you_booked_plural: 'Du har {{count}} billetter',
        activity_details: 'Aktivitetsdetaljer',
        press_to_add: 'Trykk her for å legge til en beskrivelse',
        related_groups: 'Relaterte grupper',
        date: 'Dato',
        start_end_time: 'Start- og sluttid',
        location: 'Sted',
        no_location: 'Ikke noe sted',
        cost: 'Pris',
        alert: 'Varsling',
        organizer: 'Deg • Arrangør',
        nonOrganizer: 'Arrangør',
        attendeesTitle: 'Deltakere',
        attendees: '{{count}} Deltaker',
        attendees_plural: '{{count}} Deltakere',
        tickets: '{{count}} billett',
        tickets_plural: '{{count}} billetter',
        signup: 'Meldt på {{quantity}} stk',
        no_cost: 'Gratis',
        kr: 'KR',
        noticeOptions: {
            0: 'Ingen',
            1: 'Alle naboer',
            3: 'Kun naboer som har meldt seg på',
            4: 'Kun naboer som ikke har meldt seg på',
            5: 'Alle medlemmer i valgte grupper',
        },
        quantity: 'Maks antall deltakere',
        quantityExplainer: 'Maks {{count}} deltaker',
        quantityExplainer_plural: 'Maks {{count}} deltakere',
        no_quantity: 'Ingen begrensning',
        cancellation: 'Kanselleringsfrist: {{ datetime }}',
        no_cancellation: 'Ingen kanselleringsfrist',
        registrationDeadline: 'Påmeldingsfrist: {{ datetime }}',
        no_registrationDeadline: 'Ingen påmeldingsfrist',
        contact: 'Arrangør',
        edit_activity: 'Rediger aktivitet',
        buy_for: 'Kjøp {{count}} billett ({{totalAmount}} kr)',
        buy_for_plural: 'Kjøp {{count}} billetter ({{totalAmount}} kr)',
        buy_more_for: 'Kjøp {{count}} billett ({{totalAmount}} kr) til',
        buy_more_for_plural: 'Kjøp {{count}} mer billetter ({{totalAmount}} kr) til',
        signup_for: 'Meld på {{count}} stk.',
        signup_more_for: 'Meld på {{count}} stk. til',
        cancel_for: 'Avbestill {{count}} billett',
        cancel_for_plural: 'Avbestill {{count}} billetter',
        sign_off: 'Meld av {{count}} stk',
        noChanges: 'Ingen endringer',
        cantCancel: 'Kanselleringsfristen er gått ut',
        noSpots: 'Det er ikke flere plasser på denne aktiviteten',
        noSpotsToAdd: 'Det er ikke plass til å melde på flere',
        signUpTimePast: 'Påmeldingsfristen er gått ut',
        invalidNum: 'Valgt nummer er ugyldig',
        notRefunded: 'Du vil ikke bli refundert for avbestilte billetter',
        willRefund: 'Du vil bli refundert for bilettene ved avbestilling',
        sentAlertModal: {
            send_alert: 'Send ut varsel til deltakere',
            title: 'Tittel',
            message: 'Beskjed',
            send: 'Send',
            continue: 'Fortsett',
            success: 'Varsel ble sendt ut til deltakere',
        },
        delete_activity: 'Slett aktiviteten',
        cancel_activity: 'Avlys aktiviteten',
        send_out_alert: 'Send ut varsel til påmeldte',
        signup_yourself: 'Meld på deg selv',
        change_own_signup: 'Endre egen påmelding',
        cancel: 'Avbryt',
        publish: 'Publiser',
        publishEdit: 'Lagre',
        start_time: 'Starttid',
        end_time: 'Sluttid',
        no_groups: 'Ingen grupper',
        errorLoadingAttendees: 'Det oppsto en feil ved lasting av deltakere',
        addDetails: 'Legg til detaljer',
        paying_now: 'Betales nå',
        arrangers: 'Arrangør',
        arrangers_you_and: 'Du og {{count}} annen',
        arrangers_you_and_plural: 'Du og {{count}} andre',
        add_arranger: 'Legg til medarrangør',
    },
    newActivity: {
        title_create_activity: 'Opprett en aktivitet',
        preview: 'Forhåndsvisning av ny aktivitet',
        previewEdit: 'Forhåndsvisning av endringer',
        change_details: 'Endre detaljer',
        imageTitle: 'Legg til bilde',
        moreMenu: {
            addPicture: 'Rediger bilde(r)',
            addDescription: 'Rediger beskrivelse',
            addMaxAttendees: 'Sett opp maks antall deltakere',
            addCost: 'Legg til pris for deltakelse',
            addRelatedGroups: 'Legg til relatert gruppe(r)',
            addLatestPossibleSignupTime: 'Sett opp påmeldingsfrist',
            addLatestCancellationTime: 'Sett opp kanselleringsfrist',
            alerts: 'Bestem varsling til naboer',
            addArrangers: 'Legg til medarrangører',
        },
        receive_alert: 'Velg hvem som skal få varsel',
        send_out_alert: 'Vi sender ut et varsel ved opprettelse, og 24 timer før start / påmeldingsfrist.',
        groups: 'Grupper',
        about_activity: 'Beskriv aktiviteten',
        title: 'Navn',
        enter_title: 'Gi aktiviteten en beskrivende tittel',
        location: 'Sted',
        enter_location: 'Skriv oppmøtested',
        time: 'Tidspunkt for aktiviteten',
        start: 'Start',
        end: 'Slutt',
        enter_description: 'Skriv en beskrivelse, ta gjerne med praktisk informasjon. F.eks betaling og oppmøte',
        description: 'Beskrivelse',
        add_group: 'Legg til grupper',
        add_group_explainer:
            'Det vil komme melding i gruppechat om aktiviteten, og du vil ha muligheten til å kun varsle gruppemedlemmer',
        when_will_activity: 'Tidspunkter for aktivitet',
        choose_start: 'Starttidspunkt for aktiviteten',
        choose_end: 'Slutttidspunkt for aktiviteten',
        signuptime: 'Av og påmeldingsfrist',
        enableSignUpTime: 'Har av og påmeldingsfrist',
        registration: 'Påmeldingsfrist',
        cancellation: 'Avmeldingsfrist',
        cancellationSame: 'Samme som påmeldingsfrist',
        choose_location: 'Beskriv lokasjonen for aktiviteten',
        add_photo: 'Legg til bilder',
        first_image_will: 'Det første bildet du legger til vil bli hovedbilde',
        image: 'Bilder',
        indicate_cost: 'Legg inn pris og kapasitet',
        explainNoCost: 'Ditt nabolag er ikke registrert i vipps og du kan dermed ikke sette en pris på arrangementet',
        paid_event: 'Deltakelse koster penger',
        price: 'Pris (kr)',
        priceMVA: 'Totalpris (kr) (inkl. mva)',
        mva: 'MVA (%)',
        bookKeepingAccount: 'Bokføringskonto',
        tax_rate: 'Vippskonto',
        attendees_limit: 'Maks antall deltakere',
        enter_zero: 'Ved å ikke skrive noe vil det kunne være ubegrenset antall deltakere',
        paymentExplainer: 'Pengene for deltakelse vil bli overført til konto valgt ovenfor',
        refundCancellations: 'Refunder deltakere som kanseller sin billett',
        continue: 'Gå til forhåndsvisning',
        see_preview: 'Se forhåndsvisning',
        cooperative_group_id: 'Tilgjengelig for',
        noCooperativeGroup: 'Kun',
        cooperative_group: 'Bestem om aktiviteten skal være tilgjenelig for flere nabolag eller kun ditt eget',
        no_groups: 'Du er ikke medlem av noen grupper',
        arrangers: 'Arrangører',
        add_arranger: 'Legg til arrangører',
        add_arrangers_explainer:
            'Legg til noen som kan hjelpe deg med å arrangere aktiviteten. De vil kunne redigere og avlyse aktiviteten samt sende melding til påmeldte.',
        no_users: 'Ingen brukere i dette nabolaget',
        no_users_search: 'Ingen brukere funnet',
        search_users: 'Søk etter brukere',
        activity_about_description:
            'Ved å gå til forhåndsvisning kan du endre flere innstillinger og legge til bilder.',
        confirm_template: 'Vil du bruke malen?',
        confirm_template_content: 'Ved å bruke en mal, overskrives det du allerede har skrevet i aktiviteten',
        confirm_template_cancel: 'Avbryt',
        confirm_template_confirm: 'Ja',
    },
    chatAll: {
        createNewChat: 'Opprett ny chat',
        searchPlaceholder: 'Søk etter chat',
        selectMembers: 'Opprett ny chat',
        selectMembersSelected: 'Opprett chat ({{count}})',
        createChat: 'Opprett chat',
        noChats: 'Du har ingen chatter',
        sentByYou: 'Du',
        sentMessage: 'sendte en melding',
        title: 'Chatter',
    },
    sharingAll: {
        noSearchResult: 'Ingen treff',
        viewAll: 'se alle',
        messageType: {
            2: 'Oppslag',
            0: 'Gjenstander',
            1: 'Hjelpsomme naboer',
        },
        noMessageType: {
            Requests: 'Ingen oppslag her nå. Oppslag til naboer vil dukke opp her.',
            ByBoard: 'Ingen oppslag fra {{boardNoun}} her. Oppslag fra {{boardNoun}} vil bli arkivert her.',
            Products: 'Ingen gjenstander her ennå. Her vil ting til deling dukke opp, hva med å legge ut noe selv?',
            Helpers: 'Ingen hjelpsomme naboer her enda. Melder du deg som hjelpsomme nabo dukker du opp her.',
            neighborSharing:
                'Ingen naboer som deler her enda. Melder du deg som hjelpsomme nabo eller legger ut en gjenstand til utlån dukker det opp her.',
        },
        neighborSharingTitle: 'Naboer som deler',
        fromBoard: 'Fra {{boardNoun}}',
        new: 'Ny',
        stars: '⭐️ {{count}} stjerner',
        no_star: '⭐️ Gi stjerne',
        postRequest: 'Opprett nytt oppslag',
        postProduct: 'Legg ut en gjenstand',
        offerHelp: 'Tilby din hjelp',
        myProducts: 'Mine gjenstander',
        myRequests: 'Mine meldinger',
        yourProducts: 'Dine gjenstander',
        yourRequests: 'Dine meldinger',
        requests: { plural: 'Meldinger', singular: 'En melding' },
        products: { plural: 'Gjenstander', singular: 'En gjenstand' },
        searchResults: 'Søkeresultater',
        you: 'Du',
        dot: ' • ',
        errorSaving_header: 'Det skjedde en feil',
        errorSaving_description: 'Klarte ikke lagre innstillingene, prøv igjen senere',
        save: 'Lagre',
        userSettings: 'Varslingsinnstillinger',
        userSettingsShort: 'Varslinger',
        settingsAllNotifications: 'Motta varsling om viktige oppslag',
        settingsOnlyBoard: 'Motta kun varsling om viktige oppslag fra {{boardNoun}}',
        settingsNoneNotifications: 'Ikke motta varsler om oppslag',
        follow: 'Følg',
        unfollow: 'Ikke følg',
        comment: '1 kommentar',
        comments: '{{count}} kommentarer',
        attachment: '1 vedlegg',
        attachments: '{{count}} vedlegg',
        created: 'Opprettet av',
        fromBoardBy: 'ved',
        all: 'Aktive',
        onlyFor: 'Kun synlig for ',
        open_settings: 'Åpne innstillinger for varsler på oppslag',
    },
    sharingNew: {
        newMessage: 'Lag ny melding',
        itemTitle: 'Tittel',
        itemText: 'Melding',
        imageSelector: 'Legg til bilde',
        sendButton: 'send melding',
        titleEdit: 'Rediger element',
        title: 'Legg til ny element',
        chooseType: 'Velg type',
        messageCategory: {
            0: {
                title: 'Delbar gjenstand',
                desc: 'Noe du kan låne bort, f.eks en stige, symaskin, skøyter eller sukker!',
            },
            1: {
                title: 'Bli en hjelpsom nabo',
                desc: 'Fortell naboene dine hva du kan hjelpe dem med',
            },
            2: {
                title: 'Oppslag',
                desc: 'Legg ut et oppslag til naboene dine, du kan f.eks be om hjelp eller bare informere dem om noe',
            },
            3: {
                title: 'Oppslag fra {{boardNoun}}',
                desc: 'Legg ut et oppslag til naboene dine, oppslaget vil bli markert som "fra {{boardNoun}}"',
            },
            4: {
                title: 'Samling',
                desc: 'Hvis du skal sette deg i fellesområdet, er det hyggelig å invitere naboer',
            },
        },
        addProduct: {
            title: 'Beskriv gjenstanden',
            image: 'Bilde',
            textTitle: 'Tittel',
            enterTitle: 'Skriv en beskrivende tittel',
            textDesc: 'Beskrivelse',
            enterDesc:
                'Beskriv gjenstanden og hvordan den kan lånes. For eksempel: Står lett tilgjengelig i boden eller om du må kontaktes først',
        },
        requestHelp: {
            title: 'Oppslag',
            textTitle: 'Tittel',
            enterTitle: 'Skriv inn tittel',
            textDesc: 'Beskrivelse',
            enterDesc: 'Skriv inn beskrivelse',
            send_notification: 'Send varsel til naboer',
            sent_notification: 'Varsel sendt ut',
            no_notification: 'Ingen varsel sendt',
            noAccess: 'Varsling ikke tilgjengelig: Dette kan du kun gjøre 1 gang i døgnet',
            allow_comments: 'Tillat kommentarer på oppslaget',
            attachments: 'Vedlegg',
            never_expires: 'Permanent oppslag',
            never_expires_description: 'Oppslaget vil automatisk bli fornyet hver 30. dag, slik at det ikke forsvinner',
        },
        helpNeighbour: {
            title: 'Fortell hva du kan hjelpe med',
            textTitle: 'Tittel',
            enterTitle: 'Beskriv kort med stikkord hva du kan hjelpe med',
            textDesc: 'Forklar hvorfor og hvordan du kan hjelpe',
            enterDesc: 'Skriv mer utfyllende. Skriv foreksempel erfaring eller eksempler på hjelp du kan gi.',
            textLimit: '({{count}} / 40)',
        },
        activity: {
            explainer: 'En samling passer bedre som en aktivitet, vil du opprette en?',
            action: 'Gå til opprett aktivitet',
        },
        publish: 'publiser',
        saveError: 'Klarte ikke lagre',
        publishError: 'Klarte ikke opprette',
        publishErrorNotification:
            'Du kan ikke opprette flere oppslag med varsling. Du kan kun opprette ett oppslag med varsling i døgnet.',
        chooseApartmentGroups: 'Velg boliger',
        chooseApartmentGroupsDesc: 'Ved å velge ingen boliger vil oppslaget bli synlig for alle i nabolaget',
        apartment_groups: 'Begrens til boliger',
    },
    profileEdit: {
        title: 'Rediger profil',
        submit: 'Lagre endringer',
        continue: 'Gå videre',
        public: 'Offentlig',
        first_name: 'Fornavn',
        last_name: 'Etternavn',
        email: 'E-post',
        phone: 'Telefonnummer',
        own_code: 'Personlig PIN',
        birthday: 'Fødselsdag',
        about_me: 'Litt om meg',
        enterPhone: 'Skriv inn telefonnummer',
        enterFirstName: 'Skriv inn Fornavn',
        enterSurname: 'Skriv inn etternavn',
        enterEmail: 'Skriv inn e-post',
        hideEmailFromProfile: 'Skjul e-post fra min profil',
        hideBirthdayFromProfile: 'Skjul fødselsdag fra min profil',
        hidePhoneFromProfile: 'Skjul telefonnummer fra min profil',
        aboutMePlaceHolder:
            'F. eks: Jobber i butikk. Glad i å være ute, men lite som slår ullteppe og et glass vin. Kan ett korttriks.',
        updateMobileModal: {
            header: 'Er du sikker på at du har oppgitt riktig telefonnummer',
            description: 'Hvis du gjør en feil, vil du ikke kunne logge deg på applikasjonen',
            back: 'Nei, tilbake',
            submit: 'Ja sikkert',
        },
        upload_image: 'Last opp et profilbilde',
        delete_image: 'Fjern profilbilde',
        facebookLoginErrorHeader: 'Klarte ikke logge inn med Facebook',
        facebookLoginCancelledHeader: 'Innlogging med Facebook ble avbrutt',
        facebookLoginErrorDescription: 'Ønsker du å laste opp bilde kan du trykke på samme knapp igjen',
        facebookPictureDownloadErrorHeader: 'Klarte ikke laste ned profilbilde fra Facebook',
        facebook_no_picture: 'Fant ikke noe profilbilde på facebook',
        facebookTrackingPermissionErrorHeader: 'Fikk ikke riktig tillatelser for å hente profilbilde fra Facebook',
        facebookTrackingPermissionErrorDescription:
            'Gå til innstillinger og gi tillatelse for Heime under "personvern" => "sporing" for å laste opp profilbilde fra Facebook',
        uploading_file: 'Vent til bildet er lastet opp',
        change_picture: 'Endre profilbilde',
        about_me_title: 'Legg til "om meg"',
        aboutMeTips: 'Tips til hva du kan skrive',
        aboutMeExamples: 'Eksempler fra naboene dine',
        about_me_tip_0: 'Vær deg selv',
        about_me_tip_description_0:
            'Skriv det du ønsker selv, det er ikke noe rett eller feil måte å skrive "om meg" seksjonen på',
        about_me_tip_1: 'Hva jobber du med?',
        about_me_tip_description_1:
            'Mange skriver hva de jobber med i det daglige, eller kanskje noe de har jobbet med tidligere?',
        about_me_tip_2: 'Har du alltid bodd i området?',
        about_me_tip_description_2:
            'Du kan skrive hvor du har bodd tidligere eller hvor du kommer fra, kanskje har du flyttet hit nylig?',
        about_me_tip_3: 'Har du noen hobbyer eller interesser?',
        about_me_tip_description_3:
            'Skriv gjerne hva du liker å gjøre, kanskje er det noen i nabolaget med de samme interessene?',
        about_me_tip_4: 'Introduser deg selv',
        about_me_tip_description_4: 'Kanskje har du et kallenavn, eller noe som gjør at du er lett å huske?',
        about_me_tip_5: 'Vanskelig å skrive?',
        about_me_tip_description_5: 'Det er ikke alltid like lett, men stikkord holder i massevis',
        about_me_tip_6: 'Hvem kan se det som står her?',
        about_me_tip_description_6: 'Det er kun naboene dine som kan se hva du skriver om deg selv',
    },
    reports: {
        header: 'Meldinger',
        search: 'Søk etter melding',
        reportToBoard: 'Send inn ny melding',
        noReports: 'Ingen meldinger her ennå.',
        noSearchResult: 'Ingen treff på søket ditt',
        yoursAndPublic: 'Dine og offentlige',
        open: 'Åpne',
        closed: 'Lukkede',
        disabled: 'Denne funksjonaliteten er avskrudd av styret. De skal kontaktes via mail på ',
    },
    reportSelected: {
        reportDetails: 'Melding',
        error: 'En feil oppstod',
        editReport: 'Rediger melding',
        deleteReport: 'Slett melding',
        comments: 'Kommentarer',
        addComment: 'Legg til en kommentar',
        statusTypeNew: 'Ny',
        statusTypeProcessing: 'Under arbeid',
        statusTypeDone: 'Løst',
        statusTypeWaiting: 'Venter',
        commentModal: {
            addComment: 'Legg til en kommentar',
            message: 'Melding',
            enterMessage: 'Skriv inn melding',
            send: 'Sende',
            error: 'En feil oppstod under lagring av kommentar',
            publicComment: 'Kommentaren vil vises for alle naboer siden meldingen er satt som "offentlig"',
            privateComment:
                'Kommentaren vil kun bli synlig for {{user}} og andre i {{boardNoun}}, de vil også motta varsel om kommentaren',
            privateCommentOwn:
                'Kommentaren vil kun bli synlig for deg og {{boardNoun}}, {{boardNoun}} vil også motta varsel om kommentaren',
        },
        changeStatusModal: {
            errorUpdating: 'Klarte ikke oppdatere status',
            changeStatus: 'Endre status',
            save: 'Lagre',
        },
        noComments: 'Ingen kommentarer her ennå.',
        changeStatus: 'Endre status',
    },
    reportNew: {
        header: { edit: 'Rediger melding', new: 'Ny melding' },
        image: 'Bilde',
        title: 'Tittel',
        error: 'en feil oppstod',
        enterTitle: 'Skriv inn tittel',
        desc: 'Beskrivelse',
        enterDesc: 'Skriv inn beskrivelse',
        isPublic: 'er synlig for naboene',
        publicContent: 'Ved å gjøre rapporten synlig for naboene, vil andre brukere av appen kunne se rapporten din.',
        publish: 'Send inn',
        saveChanges: 'Lagre endringer',
    },
    transaction: {
        waiting: 'Venter på bekreftelse',
        toVipps: 'Gå til vipps for å avbryte',
        pay: 'Betal i vipps',
        title: 'Status på betaling',
        cancelled: 'Betalingen ble avbrutt',
        cancelledExplainerBooking:
            'Du har ikke blitt trukket penger, reservasjonen din er heller ikke bekreftet. Du kan prøve igjen.',
        cancelledExplainerActivity:
            'Du har ikke blitt trukket penger, påmeldingen din er ikke bekreftet. Du kan prøve igjen.',
        back: 'Gå tilbake',
        success: 'Betalingen ble gjennomført',
        timeUntilStart: 'Om {{formattedDuration}}',
        started: 'Allerede begynt',
        has_been_reservedPost: ' har blitt reservert',
        reservations: 'Gå til reservasjon',
        explainLocks:
            'Produktet du har reservert låses opp ved hjelp av Heime appen, for å låse opp går du til reservasjonen under "Dine reservasjoner" etter den har startet og trykker "lås opp"',
        has_been_signedup: 'Du er påmeldt til ',
        quantity_pre: 'Du har ',
        quantity_post_single: ' billett',
        quantity_post_multiple: ' billetter',
        toActivity: 'Gå til aktiviteten',
        bookingConfirmed: 'Reservasjonen er bekreftet',
    },
    board: {
        title: 'Kontakter',
        search: 'Søk',
        members: '{{count}} Medlem',
        members_plural: '{{count}} Medlemmer',
        call: 'Anrop',
        mail: 'E-post',
        boardResponsibility: 'Ansvar:',
        reportToBoard: 'Send inn melding til {{boardNoun}}',
        noResult: 'Ingen treff',
        noContent: 'Ingen kontakter',
    },
    documents: {
        title: 'Dokumenter',
        search: 'søk',
        folder: 'Mappe',
        files: '{{count}} fil',
        files_plural: '{{count}} filer',
        notFound: 'Ingen treff',
        noResult: 'Det er ingen dokumenter',
        error: 'Klarte ikke laste ned filen',
    },
    neighbours: {
        alphabetic: 'alfabetisk',
        apartment: 'adresse',
        searchPlaceholder: 'Søk etter navn',
        noUserFound: 'Ingen bruker funnet',
        noApartment: 'Ingen adresse',
    },
    reservations: {
        title: 'Dine reservasjoner',
        back: 'Tilbake',
        noReservations:
            'Du har ingen reservasjoner registrert, hvis du ønsker å leie noe så kan du trykke på knappen nederst',
        services: 'Tjenester',
        today: 'I dag',
        selectTimeRange: 'VELG TID',
        clearDates: 'NULLSTILL',
        reserver: 'RESERVER',
        booked: 'Bestilt:',
        total: 'Total:',
        errorUpdate: 'Klarte ikke oppdatere reservasjonen',
        errorCreate: 'Klarte ikke opprette reservasjonen',
        alreadyReserved_title: 'Allerede reservert',
        alreadyReserved_description: 'Produktet er allerede reservert i dette tidsrommet',
        successUpdate: 'Reservasjonen ble oppdatert',
        started: 'Begynt',
        openLock: 'Åpne låsen',
        locks: { singular: 'Tilgang via app', plural: 'Tilgang via app' },
        until: 'Frem til',
        left: 'igjen',
        onGoing: 'Begynt',
        quantity: '{{quantity}} stk.',
        checkIn: 'Sjekk inn',
    },
    services: {
        title: 'Reservasjoner',
        myReservations: 'Dine reservasjoner',
        oneOngoing: 'En aktiv reservasjon',
        ongoingReservations: 'aktive reservasjoner',
        oneReservation: 'En reservasjon',
        reservations: 'reservasjoner',
        empty: 'Det finnes ingen tjenester eller noe å reservere her',
    },
    expandedReservation: {
        unlock: 'Lås opp',
        canUnlockIn: 'Kan åpnes om ',
        remote_unlock: 'Fjernåpne låsen',
        expired: 'Reservasjonen har utløpt',
        reservation_has_locks: 'Produktet har en lås',
        cancel: 'Kanseller',
        errorCancel: 'Klarte ikke kansellere reservasjonen',
        change: 'Endre',
        lockModal: {
            lockUnlocked: 'Låsen er åpen',
            lockboxUnlocked: 'Nøkkelboksen er låst opp, du kan nå åpne den',
            get_keys: 'Ta ut nøklene, og lukk nøkkelboksen',
            leave_keys: 'Legg tilbake nøklene, og lukk nøkkelboksen',
            lockOpened: 'lås åpen',
            lock: 'Trykk for å låse',
            attemptingConnect: 'Leter etter låsen',
            lockConnecting: 'Leter etter låsen',
            noConnect: 'Klarte ikke å finne låsen, er den i nærheten?',
            requestingUnlock: 'Åpner låsen',
            unlocking: 'Låser opp',
            retry: 'Prøv igjen',
            unlockFailed: 'Klarte ikke åpne låsen',
            unlocked: 'Låsen er åpen',
            lockingIn: 'Låses om ',
            sec: 'sek',
            lockClosed: 'Låsen er lukket',
            pinClosedCanClose: 'Koblet fra, verifiser at låsen er lukket',
            unlockAgain: 'Åpne igjen',
            lockDisconnected: 'Låsen er koblet fra',
            closeLock: 'Lukk låsen for å fortsette',
            noPermission: 'Trenger tilgang til bluetooth og lokasjon',
            openSettings: 'Åpne innstillinger',
            close: 'Avbryt',
            noBluetooth: 'Bluetooth er ikke aktivert',
            noWeb: 'Last ned appen for å låse opp låsen',
            pressLock: 'Vekk låsen, ved å trykke på knappen',
            wakeLock: 'Vekk låsen for å fortsette',
            noConnectRememberToWake: 'Klarte ikke finne låsen, har du vekket den?',
            noBluetoothPermission: 'Trenger tilgang til bluetooth for å åpne låsen',
            noLocationPermission: 'Trenger tilgang til lokasjon for å åpne låsen',
            pressLockExplainer:
                'Du må trykke på knappen på låsen som henger på veggen eller i nærheten av det du har reservert. Hvis ikke klarer ikke appen å snakke med den.',
            closeFailed: 'Klarte ikke å lukke låsen',
            closing: 'Lukker låsen',
            lockClosing: 'Låser...',
            waiting: 'Venter på låsen',
            remote_unlock: 'Du kan låse opp låsen uansett hvor du er',
            remote_unlocking: 'Har bedt låsen om å låse opp',
            lockDone: 'Lås åpnet',
            missingSecretKeyOrTokenHeader: 'Mangler informasjon for å åpne låsen',
            missingSecretKeyOrTokenText:
                'Koble telefonen til internett, trykk prøv igjen, deretter vent et minutt før du prøver igjen.',
        },
        bluetoothDenied: 'Heime trenger tilatelse til å bruke bluetooth for å koble til låsen',
        bluetoothDisabledAndroid:
            'You have to manually turn on your bluetooth connection, click this text to open settings',
        bluetoothDisabledIOS: 'You have to manually turn on your bluetooth connection',
        noWeb: 'Lås kan ikke åpnes fra web, du må laste ned appen',

        cancelModal: {
            header: 'Er du sikker på at du vil kansellere reservasjonen?',
            refundable: 'Du vil bli refundert for reservasjonen',
            hours: '{{count}} time',
            hours_plural: '{{count}} timer',
            days: '{{count}} døgn',
            days_plural: '{{count}} døgn',
            nonRefundableHours:
                'Du vil ikke bli refundert for reservasjonen, fordi den starter om mindre enn {{hours}}',
            nonRefundableDaysHours:
                'Du vil ikke bli refundert for reservasjonen, fordi den starter om mindre enn {{days}} og {{hours}}',
            nonRefundableDays: 'Du vil ikke bli refundert for reservasjonen, fordi den starter om mindre enn {{days}}',
            nonRefundable: 'Du vil ikke bli refundert for reservasjonen',
            back: 'GÅ TILBAKE',
            approve: 'KANSELLER',
        },

        okNoCancel: 'OK',
        noCancel:
            'Du kan ikke kansellere reservasjonen da den allerede har startet. Du kan fremdeles endre den til å avslutte tidligere',
        cannotCancel: 'Kan ikke kansellere',
        productInfo: 'Se mer informasjon',
        bookingDetails: 'Start og slutt',
        starting: 'Reservasjonen starter',
        ending: 'Reservasjonen slutter',
        ends: 'Til',
        successCancelHeader: 'Reservasjonen er kansellert',
        changedClockNegative:
            'Klokken på telefonen din viser noe annet enn vår klokke, still klokken riktig og gjør en omstart av appen for å kunne åpne låsen',
        serverAndPhoneOutOfSync:
            'Klokken på telefonen din viser noe annet enn vår klokke. Still klokken og gjør en omstart av appen for å kunne åpne låsen',
        noDataToCompare:
            'På grunn av manglende internet kan vi ikke sjekke tidspunkt for reservasjon. Koble til internet og prøv igjen',
        tryAgain: 'Prøv igjen',
        more_options: 'Flere valg',
        pin_code: 'Pinkode',
        copied_pin_code: 'Pinkode kopiert',
        copy_pin_explainer: 'Trykk for å kopiere pin koden',
        reason: 'Anledning',
        quantity: 'Antall - {{count}} stykk',
        previous_visitor: 'Forrige bruker',
        previous_visitor_action: 'Trykk for forklaring',
        previous_visitor_info:
            'Hvis det er noe som ikke er som det skal ved produktet, kan du kontakte personen som hadde produktet før deg, og koordinere hvordan feilen kan rettes',
        checkOut: 'Sjekk ut',
        checkIn: 'Sjekk inn',
        booked: 'Reservert',
        all_day: 'Hele dagen',
        lock: '1 lås',
        lock_plural: '{{count}} låser',
    },
    serviceExpanded: {
        nAvailablePlural: 'Antall: {{amount}}',
        shortTerm: 'Timebasert',
        longTerm: 'Døgnbasert',
        price_type: {
            rate_per_booking: '',
            rate_per_night: '',
            rate_per_hour: '',
            rate_per_booking_capacity: '',
            rate_per_hour_per_capacity: 'per time',
            rate_per_night_per_capacity: 'per natt',
            rate_per_hour_with_night_price:
                'Mellom {{nighttime_end_hour}}-{{nighttime_start_hour}}: {{daytime_price}} kr, resten av døgnet: {{nighttime_price}} kr',
        },
        price_type_short: {
            rate_per_booking: 'reservasjon',
            rate_per_night: 'natt',
            rate_per_hour: 'time',
            rate_per_booking_capacity: 'stykk',
            rate_per_hour_per_capacity: 'stykk',
            rate_per_night_per_capacity: 'stykk',
        },
        maxBooking_hour: 'maks en time',
        maxBooking_hour_plural: 'maks {{count}} timer',
        maxBooking_day: 'maks en dag',
        maxBooking_day_plural: 'maks {{count}} dager',
        hours: '1 time',
        hours_plural: '{{count}} timer',
        days: '1 dag',
        days_plural: '{{count}} dager',
        noImage: 'bilde ikke valgt',
        name: 'Navn',
        description: 'Beskrivelse',
        reserve: 'Reserver',
        chooseStart: 'Velg dato for innsjekk',
        chooseEnd: 'Velg dato for utsjekk',
        update: 'Oppdater',
        price: 'Pris',
        kr: 'kr',
        ammount: 'Antall',
        i_want_this_one: 'Denne vil jeg ha',
        max_booking_period: 'Bestillingsperiode',
        who_booked: 'Hvem har reservert i denne perioden?',
        close: 'Lukk',
        availableAllDay: 'Tilgjengelig hele dagen',
        availableSome: 'Ledige tider',
        notAvailable: 'Ikke tilgjengelig',
        availableCount: '{{count}} stk. tilgjengelig ',
        availableSomeCapacityMultiple: 'Ledige tider ({{min}} - {{max}} stk.)',
        availableSomeCapacitySingle: 'Ledige tider ({{count}} stk.)',
        timeNow: 'Nå',
        n_available: '{{count}} stk. ',
        available: 'Tilgjengelig',
        checkin: 'Innsjekk',
        checkout: 'Utsjekk',
        selectAmount: 'Hvor mange vil du reservere?',
        reserveTime: 'Velg tid',
        reserveDays: 'Velg datoer',
        reserveDescriptionTime:
            'Velg en eller flere perioder du vil reservere. Trykk på første periode deretter siste periode. ',
        reserveDescriptionDays: 'Velg først dato for innsjekk, velg så dato for utsjekk. Se tider ovenfor. ',
        maxBookingStart: 'Du kan reservere for ',
        durationLabel: 'Bestilt:',
        sumLabel: 'Totalt:',
        clear: 'NULLSTILL',
        today: 'I dag',
        clickOnBusy: 'Du kan trykke på et opptatt tidspunkt for å se hvem som har booket',
        maxBookedPerBooking: 'Det kan reserveres maks {{count}} stykk per reservasjon. ',
        skipPaymentExplainer:
            'Det er mulig å reservere dette produktet uten å betale hvis du ikke trenger produktet for deg selv',
        skipPayment: 'Felles reservasjon',
        paymentReservation: 'Privat reservasjon',
        isSkippingPayment: 'Tilgjengelig for naboer',
        reserveReasonModalHeader: 'Angi grunn',
        reserveReasonModalLabel: 'Grunn for reservasjon',
        reserveReasonModalButton: 'Reserver',
        reserveReasonModalTooLong: 'Grunnen kan ikke være lengre enn 255 tegn',
        reserveLicensePlateModalHeader: 'Skriv inn registreringsnummer',
        reserveLicensePlateModalText: 'Skriv inn registrernummeret til kjøretøyet som skal parkeres',
        reserveLicensePlateModalButton: 'Bekreft',
        reserveLicensePlateModalDropdown: 'Se flere registreringsnummer',
        reserveLicensePlateModalManage: 'Rediger lagrede registreringsnummer',
        reserveLicensePlateModalCreateNew: 'Legg til nytt registreringsnummer',
        reserveLicensePlateModalTooLong: 'Registreringsnummeret kan ikke være lengre enn 20 tegn',
        showEarlier: 'Tidligere tidspunkt',
        localTime: 'Lokal tid',
        quantityLabel: 'Antall',
        pieces: '{{count}} stk',
        quantity: 'Antall {{quantity}}',
        open_explainer: 'Trykk for å få en forklaring',
        not_confirmed: 'Ikke betalt',
        change_not_confirmed: 'Endring er ikke betalt',
        timeBasedPricing: '. Merk at prisen kan variere avhengig av tidspunktet du reserverer for',
        hasBooking: 'Du har allerede en reservasjon av {{product}}',
        booking_details: 'Se reservasjon',
    },
    notifications: {
        title: 'Varslinger',
        ago: 'siden',
        newChatMessage: {
            title: '{{name}} har sendt deg en melding',
            buttonText: 'Gå til chat',
        },
        activityInvite: { buttonText: 'Gå til aktivitet', content: 'Det er blitt opprettet en ny aktivitet' },
        tfHoursBeforeActivity: {
            title: 'Påminnelse om {{name}}',
            content: 'Det er mindre enn 24 timer til aktiviteten',
            buttonText: 'Gå til aktivitet',
        },
        tfHoursBeforeSignUpTerm: {
            title: 'Påminnelse om {{name}}',
            content: 'Det er mindre enn 24 timer til påmeldingsfristen går ut',
            buttonText: 'Gå til aktivitet',
        },
        activityInvitedUsers: { buttonText: 'Gå til aktivitet' },
        activityCustomNotify: {
            title: 'Melding relatert til {{name}}',
            content: 'Melding fra arrangør: {{name}}',
            buttonText: 'Gå til aktivitet',
        },
        cancelledActivity: { title: '{{name}} er avlyst' },
        supportCreated: {
            title: 'Ny melding til {{boardNoun}}, fra: {{name}}',
            buttonText: 'Gå til melding',
        },
        supportStatus: {
            title: '{{boardNounCapitalized}} har endret statusen på meldingen "{{title}}" til "{{name}}"',
            buttonText: 'Gå til melding',
        },
        userGroupCreated: { buttonText: 'Gå til gruppen' },
        lowBattery: {
            title: 'Lås "{{lockName}}" har lavt batteri ({{percentage}} %)',
            content: 'Låsen hører til {{productsName}}.',
            contentcable: 'Den kan lades via en USB-kabel med Micro-B-kontakt.',
            contentcabinet: 'Batteriet er av type CR123A, kan kjøpes på jernvarehandel.',
            contentmasterLock5441: 'Den bruker ett CR123A batteri. Batteriene fås kjøpt på jernvarehandler',
            contentmasterLock4401: 'Den bruker ett CR2 batteri. Batteriene fås kjøpt på jernvarehandler',
            contentmasterLock4400: 'Den bruker ett CR2 batteri. Batteriene fås kjøpt på jernvarehandler',
            contentdanalock: 'Den bruker 4 stk CR123 batterier, og kan kjøpes på jernvarehandel.',
            contentPost: 'For å oppdatere batteristatusen må du åpne låsen med Heime-appen.',
        },
        newMessageComment: {
            title: 'Ny kommentar på oppslaget "{{title}}"',
            buttonText: 'Gå til oppslag',
            contentFile: 'En fil',
            contentImage: 'Et bilde',
        },
        newSurvey: {
            title: 'Ny undersøkelse publisert: "{{title}}"',
            buttonText: 'Gå til undersøkelsen',
        },
        reminderSurvey: {
            title: 'Påminnelse om undersøkelsen: "{{title}}"',
            buttonText: 'Gå til undersøkelsen',
        },
        unhandledCooperativeUserRegistration: {
            title: 'Det er en ny registrering som venter på tilgang til Heime',
            title_plural: 'Det er {{count}} nye registreringer som venter på tilgang til Heime',
            buttonText: 'Åpne adminpanelet',
        },
        arrangerAdded: {
            title: 'Du er blitt lagt til som arrangør på "{{title}}"',
            buttonText: 'Gå til aktivitet',
        },
        arrangerRemoved: {
            title: 'Du er blitt fjernet som arrangør på "{{title}}"',
            buttonText: 'Gå til aktivitet',
        },
        activityRateUs: {
            title: 'Takk for deltakelsen, vil du vurdere Heime?',
            content: '',
            buttonText: 'Vurder Heime',
        },
        messageExpired: {
            prolonged: 'Oppslag er blitt forlenget',
            reactivated: 'Oppslag er blitt satt som aktiv',
            month_from_now: 'Oppslaget vil bli deaktivert om en måned',
            prolong_error: 'Klarte ikke å forlenge oppslaget',
            title_no_date: 'Oppslaget er satt som permanent',
            description_no_date: 'Oppslaget "{{title}}" utgår aldri, dette kan bli endret ved å redigere oppslaget',
            title_expired: 'Ditt oppslag er ikke aktivt',
            title_expired_author: 'Ditt oppslag er markert ikke aktiv av {{actor_name}}',
            description_expired: 'Ditt oppslag "{{title}}" er ikke aktivt. Du kan aktivere det igjen',
            title_expires_future: 'Ditt oppslag vil bli deaktivert om {{timeString}}',
            title_expires_future_author:
                'Ditt oppslag er satt til å bli deaktivert om {{timeString}} av {{actor_name}}',
            description_prolong: 'Ditt oppslag "{{title}}" blir deaktivert. Du kan forlenge dette med 1 måned',
            description_no_prolong: 'Ditt oppslag "{{title}}" blir deaktivert. Vi varsler deg her når dette skjer',
            seeMessage: 'Gå til oppslag',
            prolong: 'Forleng',
            reactivate: 'Aktiver',
        },
        bookingNotification: {
            buttonText: 'Gå til reservasjon',
            start_future_title: 'Din reservasjon av {{name}} starter om {{timeString}}',
            start_past_title: 'Din reservasjon av {{name}} startet for {{timeString}} siden',
            end_future_title: 'Din reservasjon av {{name}} slutter om {{timeString}}',
            end_past_title: 'Din reservasjon av {{name}} sluttet for {{timeString}} siden',
            buttonText_start_procedure: 'Start innsjekk',
            buttonText_end_procedure: 'Start utsjekk',
            start_future_procedure_title: '{{name}} er klar til å sjekke inn  om {{timeString}}',
            start_past_procedure_title: '{{name}} er klar til innsjekk',
            end_future_procedure_title: 'Du må være ferdig sjekket ut {{name}} om {{timeString}}',
        },
        others: '{{others}} og {{last}}',
        no_notifications_found: 'Du har ingen varsler',
    },
    chat: {
        messageModal: {
            header: 'Denne lenken er fra en annen bruker og er kanskje ikke trygg. Vil du fortsatt gå til den?',
            back: 'NEI, TILBAKE',
            approve: 'JA, BESØK',
        },
        empty_message: 'Ingen meldinger her ennå. Skriv en for å starte chatten.',
        today: 'I dag',
        add_file: 'Legg til fil',
        add_image: 'Legg til bilde',
        goToGroup: 'Besøk gruppen',
        shareProducts: 'Del Gjenstand: ',
        askForHelp: 'Be om hjelp: ',
        helpRequest: 'Oppslag: ',
        starReceived: 'Stjerne mottatt fra: ',
        starSended: 'Stjerne sendt til: ',
        addAll: 'TIL ALLE',
        removeAll: 'NULLSTILL',
        search: 'Søk etter nabo',
        onlyYou: 'Bare deg',
        too_many_users_header: 'Veldig mange brukere i chatten',
        too_many_users_text:
            'Det er svært mange naboer i denne chatten; for å unngå mye varsler anbefaler vi at meldingen du sender heller legges ut som oppslag. Der kan du laste opp filer og bilder samt motta kommentarer fra andre naboer. Oppslag finner du ved gå tilbake for så å trykke på knappen i midten nederst',
        too_many_users_button: 'OK',
        pressInfo: 'Trykk her for info',
        add_attachment: 'Legg til vedlegg',
        write_message: 'Skriv chatmelding her',
        send_message: 'Send chatmelding og eventuelt vedlegg',
        from: 'Fra :senderName',
        to_profile: 'Gå til avsenders profil',
        deleteMessageSuccess: 'Meldingen ble slettet',
        deleteMessageError: 'Klarte ikke å slette meldingen',
        deleteModal: {
            header: 'Er du sikker på at du vil slette meldingen?',
            back: 'Gå tilbake',
            approve: 'Slett',
        },
        edited: '(Redigert) ',
        deleted_message: 'Slettet melding',
        edit: 'Rediger',
        delete: 'Slett',
        copy: 'Kopier',
        copiedToClipboard: 'Melding kopiert',
        editing_message: 'Rediger melding',
        stop_editing: 'Stopp med redigering av melding',
    },
    chatInfo: {
        members: 'Medlemmer',
        media: 'Bilder',
        files: 'Filer',
        no_media_message: 'Ingen bilder her ennå. Bilder sendt i chatten vil bli listet opp her.',
        no_files_message: 'Ingen filer her ennå. Filer sendt i chatten vil bli listet opp her.',
        nMembers: '{{count}} medlem',
        nMembers_plural: '{{count}} medlemmer',
        of: '{{current}} av {{length}}',
        admin: 'Du • Admin',
        nonAdmin: 'Admin',
        you: 'Du',
        leaveChat: 'Forlat chat',
        makeAdmin: 'Gjør til admin',
        leaveModal: {
            header: 'Er du sikker på at du vil forlate chat?',
            back: 'NEI, GÅ TILBAKE',
            approve: 'JA, FORLAT',
        },
        addMembers: 'Legg til bruker',
        addMembers_plural: 'Legg til brukere ({{count}})',
        addMember: 'Legg til bruker',
        noUsers: 'Fant ingen brukere',
        noUsersChat: 'Ingen brukere å chatte med',
        noUsersToAdd: 'Ingen brukere å legge til',
    },
    global: {
        resetApp: 'Restart appen',
        swipe_down_to_fetch_data: 'Dra ned skjermen for å hente siste',
        warning: 'Advarsel',
        are_you_sure_delete_activity: 'Er du sikker på at du vil slette aktiviteten?',
        yes: 'Ja',
        no: 'Nei',
        cancel: 'Avbryt',
        ask_me_later: 'Spør meg senere',
        permissions: {
            camera_permission: 'Kamera tilgang',
            must_have_permission_camera: 'Appen må ha tilgang til kameraet',
            camera_settings_permission:
                'For å kunne laste opp et bilde trenger Heime tilgang til kameraet. Dersom du har valgt nei til dette tidligere så gjøres dette i telefonens innstillinger',
            bluetoothLocationTitle: 'Tilgang til Bluetooth og lokasjon',
            bluetoothLocationDescription:
                'Tilgangene brukes kun til å låse opp låsen, ved å låse opp låsen kan vi i teorien vite at du er ved den på åpningstidsupunktet.',
        },
        back: 'Tilbake',
        task_failed_header: 'Det oppsto en feil',
        task_failed_description: 'Feilen er blitt lagret, prøv gjerne igjen',
        goBack: 'Gå tilbake',
        confirm: 'Bekreft',
        confirmWithLabel: 'Bekreft {{label}}',
        show_more: 'Vis mer',
        show_less: 'Se mindre',
        optional: ' (Valgfri)',
        cameraPermission: {
            title: 'Tilgang til kamera',
            message: 'Appen trenger tilgang for å la deg få laste opp ønskede bilder',
            neutral: 'Spør senere',
            positive: 'Ok',
            negative: 'Ikke tillat',
        },
        selectImage: 'Velg bilde',
        and: 'og ',
        xOthers: 'en til',
        xOthers_plural: '{{count}} andre',
        enLang: 'English',
        noLang: 'Norsk',
        cancelModal: {
            cancelChanges: 'Er du sikker på at du vil forkaste endringene?',
            cancelCreation: 'Er du sikker på at du vil forkaste?',
            content: 'Det du har skrevet vil ikke bli lagret',
            contentEdited: 'Det du har endret vil ikke bli lagret',
            back: 'NEI, GÅ TILBAKE',
            confirmCancel: 'JA, FORKAST',
        },
        search: 'Søk',
        comments: 'Kommentarer',
        noComments: 'Ingen kommentarer',
        attachFile: 'Trykk her for å legge til fil eller bilde',
        add_photo: 'Velg et bilde',
        take_photo: 'Ta et bilde',
        add_file: 'Velg en fil',
        choose: 'Velg',
        crop_image: 'Rediger bilde',
        add_facebook: 'Hent profilbilde fra facebook',
        remove_photo: 'Fjern bilde',
        clock: 'kl',
        addImage: 'Trykk her for å legge til bilde',
        networkError_header: 'Nettverksfeil',
        networkError_description: 'Klarte ikke å sende, prøv igjen',
        error_sending_header: 'Det oppsto en feil ved sending',
        error_sending_description: 'Prøv igjen',
        error_saving_header: 'Klarte ikke å lagre endringene',
        error_saving_description: 'Prøv igjen',
        tooManyRequests_header: 'Låst ute av serveren',
        tooManyRequests_text: 'Du har gjort for mange forespørsler, vent 5 minutter før du prøver igjen',
        noSearchResult: 'Ingen treff på søket ditt',
        close: 'Lukk',
        loading: 'Laster inn informasjon',
        more_choices: 'Trykk for flere valg',
        select_language: 'Velg språk',
        upload_failed: 'Klarte ikke å laste opp bilde',
        permission_denied: 'Du må gi tilgang til Heime for å kunne velge et bilde',
        open_settings: 'Åpne innstillinger',
        deleted_user_with_apartment_owner: 'Tidligere eier av {{apartment}}',
        deleted_user_with_apartment_renting: 'Tidligere leietaker av {{apartment}}',
        deleted_user_with_apartment_other: 'Tidligere bruker i {{apartment}}',
        deleted_user_with_apartment_livesWith: 'Tidligere beboer i {{apartment}}',
        deleted_user_no_apartment: 'Fjernet bruker',
        open_admin_error: 'Klarte ikke å åpne admin panelet direkte',
        open_admin_error_text:
            'Gå til app.heime.com/admin i nettleseren din for å håndtere forespørselen. Lenken er også lagt på utklippstavlen din',
        open_url_error_header: 'Klarte ikke å åpne lenken',
        open_url_error_text: 'Gå til {{url}} i nettleseren din, lenken er også lagt på utklippstavlen din',
        open_contact_error_header: 'Klarte ikke å åpne for deg',
        open_contact_error_text: 'Bruk {{url}} for å kontakte, dette er også lagt på utklippstavlen din',
    },
    globalError: {
        header: 'Det skjedde en feil',
        networkHeader: 'Nettverksfeil, sjekk internetttilkoblingen din',
        subText: 'Beklager, vi har notert oss feilen, vennligst prøv igjen. Hvis det ikke hjelper, ta kontakt.',
        subTextNetwork: '',
        button: 'Prøv igjen',
        too_big: '{{name}} kan ikke være så lang',
        title: 'Tittel',
        phone: 'Telefonnummeret',
    },
    networkAlert: {
        header: 'Ingen internettforbindelse',
        description:
            'Det virker ikke som du har internett, Heime trenger internett for å fungere. Sørg for at Wi-Fi eller mobildata er slått på, og prøv på nytt.',
    },
    updateApp: {
        android: 'Play',
        ios: 'App',
        header: 'Ny versjon av Heime',
        description_recommended:
            'Vi anbefaler at du oppdaterer appen din for å unngå feil. For å oppdatere, gå til "{{store}} Store" og deretter "Oppdater".',
        description_force_soon:
            'Hvis du ikke oppdaterer vil du etterhvert bli tvunget til å oppdatere når den gamle versjonen slutter å virke.',
        description_force:
            'Din versjon av Heime-appen er så gammel at den sannsynligvis ikke vil fungere, så du må oppdatere den. For å oppdatere, gå til "{{store}} Store" og deretter "Oppdater".',
        goToButton: 'Gå til {{store}} Store',
        later: 'Senere',
    },
    onboarding: {
        setupPin: 'Sett opp PIN-kode',
        step: 'Steg {{step}}',
        description: 'Beskriv hvorfor du kan/vil hjelpe:',
        descriptionPlaceholder:
            'Her kan du skrive litt om hva du kan hjelpe med og hvorfor: F.eks. Jeg har en bil og er trygg på kjæledyr',
        helpfulNeighbourHeader: 'Hva kan du hjelpe naboene dine med?',
        helpfulNeighbourDesciptions: 'Trykk på de tingene du kunne tenkt deg å hjulpet en nabo med.',
        stepHeader: [
            '',
            'Personvernerklæring.',
            'Motta varsler.',
            'Bekreft Info',
            'Beskriv deg selv',
            'Bli med i en gruppe.',
        ],
        privacyPolicyHeader: 'Personvernerklæring',
        privacyPolicyDesciptions: 'Vennligst les igjennom og godkjenn personvernerklæring vår',
        privacyPolicyContent:
            'Heime er en tjenesteleverandør som jobber for å skape gode nabolag. For å gjøre dette bruker vi noen personopplysninger om deg.\n\nHovedsaklig brukes de for å fortelle om deg til naboene dine. Du vil alltid ha mulighet til å redigere dette samt bestemme hvor mye naboene dine ser.\n\nHeime selger ikke dine data.',
        privacyPolicyAgree: 'Før du tar Heime i bruk, må du godkjenne',
        privacyPolicyLowerCase: 'Heimes personvernerklæring',
        privacyPolicyAgreePost: 'Trykk gå videre for å godkjenne',
        continue: 'Gå videre',
        notificationHeader: 'Motta varsler',
        notificationDesciptions:
            'Ved å skru på varsler, vil du motta varsler om viktige meldinger fra {{boardNoun}}, nye aktiviteter og chatmeldinger.', //test this Kristian
        turnOnNotification: 'TILLAT VARSLER',
        changeSettings: 'ENDRE INNSTILLINGENE',
        profileCreation: 'Fortell naboene dine hvem du er',
        pleaseAllowNotification: 'Vennligst skru på varslinger for Heime i systeminnstillingene dine',
        skipForNow: 'HOPP OVER',
        downloadAccountPhoto: 'Last opp profilbilde',
        photoAdded: 'Bilde lastet opp',
        enterFirstName: 'Fyll inn fornavn',
        firstName: 'Fornavn',
        enterSurname: 'Fyll inn etternavn',
        lastName: 'Etternavn',
        enterEmail: 'Fyll in epost',
        email: 'Epost',
        phone: 'Telefon',
        birthday: 'Bursdagsdato',
        hideFromProfile: 'Ikke vis til naboer',
        aboutMePlaceHolder:
            'F. eks: Jobber i butikk. Glad i å være ute, men lite som slår ullteppe og et glass vin. Kan ett korttriks.',
        aboutMe: 'Om meg',
        lastName_error: 'Du må skrive inn etternavn',
        firstName_error: 'Du må skrive inn fornavn',
        phone_error: 'Du må skrive inn telefonnummer',
        invalidEmail: 'Emailadressen du skrev inn er ikke gyldig',
        email_error: 'Du må skrive inn epost',
        createPin: 'Opprett egen kode',
        repeatPin: 'Gjenta koden',
        enterPinDesciption: 'For å raskere logge inn kan du opprette egen kode i Heime. Skriv inn ønsket kode',
        repeatPinDesciption: 'Skriv inn den samme koden på ny, for å bekrefte den',
        phone_length: 'Telefonnummeret kan ikke være så langt',

        profile_confirm_title: 'Bekreft din kontaktinformasjon',
        profile_confirm_subtitle:
            'Informasjonen brukes til å vise din kontaktinfo til {{boardNoun}}. Du kan velge om dette også skal vises til naboene dine.',
        createProfile: 'Opprett profil',
        upload_photo: 'Velg et bilde',
        upload_facebook: 'Bruk bilde fra Facebook',
        add_photo: 'Gi postkassen et ansikt, så kan du være med å kjenne naboene dine',
        describe_yourself: 'Fortell litt om deg selv, tekst og bilde vises kun til naboer i ditt boligselskap', //eeh
        upload_new_photo: 'Last opp nytt bilde',
    },
    group: {
        header: 'Grupper',
        title: 'Bli med i noen grupper',
        subTitle:
            'For noen aktiveteter blir kun gruppemedlemmer varslet. Meld deg inn i de gruppene du synes virker interessante',
        join: 'Bli med',
        members: { plural: 'Medlemmer', singular: 'Ett medlem' },
        continue: 'Fortsett',
        skip: 'Hopp over',
        create_group: 'Lag gruppe',
        my_groups: 'Mine grupper',
        all_groups: 'Alle grupper',
        noGroups: {
            own: 'Du er ikke medlem av noen grupper',
            all: 'Det finnes ingen grupper',
            allWithRequest: 'Det finnes ingen grupper, du burde lage en!',
        },
        adminModal: {
            header: 'Er du sikker på at du vil utnevne «{{user}}» som administrator?',
            yes: 'Ja',
            no: 'Nei',
        },
        groupSelected: {
            title: 'Grupper',
            open_chat: 'Åpne Chat',
            create_activity: 'Opprett aktivitet',
            join_group: 'Bli med i gruppe',
            admin: 'Admin',
            member: 'Medlem',
            members: 'Medlemmer',
            you: 'Deg',
            set_admin: 'Angi {{name}} som administrator',
            leave_group: 'Forlat gruppen',
            add_image: 'Legg til bilde',
            add_file: 'Last opp et dokument',
            images: 'Bilder',
            no_images: 'Det er ikke delt noen bilder i denne gruppen enda.',
            files: 'Filer',
            no_files: 'Det er ikke delt noen filer i denne gruppen enda.',
            add_files: 'Last opp filer',
            files_description:
                'Bilder og filer lastet opp her vil også bli sendt i chatten. De vil kun være synlig for gruppens medlemmer',
            upload: 'Last opp',
        },
        createGroup: {
            title_create: 'Lag gruppe',
            title_edit: 'Rediger gruppeinformasjon',
            button_create: 'Lag gruppe',
            button_edit: 'Lagre endringer',
            image: 'Bilde',
            title: 'Tittel',
            description: 'Beskrivelse',
            created: 'Gruppe opprettet',
            updated: 'Gruppe oppdatert',
            updateError: 'Det oppstod en feil under redigering',
            createError: 'Det oppstod en feil under oppretting',
            alertError: 'Det oppsto en feil under sending av varsel',
            fillAll: 'Vennligst fyll ut alle feltene',
        },
    },
    sharingSelected: {
        editItem: 'Rediger',
        deleteItem: 'Fjern',
        prolong: 'Forleng aktiv periode',
        reactivate: 'Aktiver oppslag på nytt',
        deactivate: 'Deaktiver oppslag',
        deactivated: 'Oppslaget er deaktivert',
        deactivated_description:
            'Oppslaget dukker ikke lenger opp for naboer i oppslagslisten. Men vil fortsatt produsere varsler om nye kommentarer. Og kunne søkes etter',
        deactivated_failed: 'Klarte ikke deaktivere oppslaget',
        error: 'Det oppsto en feil',
        messageTheBoard: 'Kontakt {{boardNoun}}',
        deleteModal: {
            header: 'Er du sikker på at du vil slette denne?',
            back: 'NEI, TILBAKE',
            approve: 'JA, SLETT',
        },
        messageType: {
            Helpers: 'Hjelpsom nabo',
            Products: 'Gjenstand',
            Requests: 'Oppslag',
            ByBoard: 'Oppslag fra {{boardNoun}}',
        },
        productDetails: {
            button: 'Ta kontakt med naboen',
        },
        requestDetails: {
            titleModal: 'Skriv en chatmelding til naboen',
            button: 'Ta kontakt med naboen',
            expired: 'Oppslaget er utløpt og ikke lenger aktivt',
            expiredDescription:
                'Oppretteren av oppslaget har muligheten til å forlenge oppslagets varighet, men alle interesserte vil fortsatt motta varsler om nye kommentarer.',
        },
        contactNeighbor: {
            title: 'Ta kontakt med naboen',
            button: 'Send melding',
            messageLabel: 'Beskjed',
            placeHolderMessage: 'Skriv inn melding',
        },
        helperProfile: {
            stars: '⭐️ {{count}} stjerne',
            stars_plural: '⭐️ {{count}} stjerner',
            no_star: '⭐️ Gi stjerne',
            button: 'Be {{name}} om hjelp',
            sendStar: 'Send en stjerne',
            willBeAvailable: 'Vil bli mulig om {{count}} en time',
            willBeAvailable_plural: 'Vil bli mulig om {{count}} timer',
            howCanIHelp: 'Hvordan kan jeg hjelpe',
            feedback: 'Tilbakemelding',
            noFeedback: 'Ingen tilbakemelding funnet',
            comment: 'Kommentar',
        },
        attachments: 'Vedlegg',
        deleteCommentSuccess: 'Kommentaren ble slettet',
        deleteCommentError: 'Klarte ikke å slette kommentaren',
        deleteCommentModal: {
            header: 'Er du sikker på at du vil slette kommentaren?',
            back: 'Gå tilbake',
            approve: 'Slett',
        },
        edited: '(Redigert) ',
        deleted_comment: 'Slettet kommentar',
        edit: 'Rediger',
        delete: 'Slett',
        copy: 'Kopier',
        copiedToClipboard: 'Kommentar kopiert',
        editing_comment: 'Rediger kommentar',
        stop_editing: 'Stopp med redigering av kommentar',
    },
    userMenu: {
        terms: { title: 'Salgsbetingelser', noAccounts: 'Ingen betalingskontoer er koblet til ditt nabolag' },
        privacy: 'Personvernerklæring',
        language: 'Språk',
    },
    languageSelectModal: {
        header: 'Velg ditt språk',
        cancel: 'Avbryt',
        submit: 'Lagre',
    },
    notFoundErrorScreen: {
        Activity: {
            header: 'Fant ikke aktiviteten',
            subtext: 'Enten er den blitt avlyst/slettet eller så er det en feil hos oss',
            button: 'Gå tilbake',
        },
        Group: {
            header: 'Fant ikke gruppen',
            subtext: 'Enten er den blitt slettet eller så er det en feil hos oss',
            button: 'Gå tilbake',
        },
        Chat: {
            header: 'Fant ikke chatten',
            subtext: 'Enten er du ikke med i den lengre eller så er det en feil hos oss',
            button: 'Gå tilbake',
        },
        Product: {
            header: 'Fant ikke produktet',
            subtext: 'Enten er den blitt slettet eller så er det en feil hos oss',
            button: 'Gå tilbake',
        },
        Profile: {
            header: 'Fant ikke brukeren',
            subtext: 'Enten er den blitt slettet eller så er det en feil hos oss',
            button: 'Gå tilbake',
        },
        Reservation: {
            header: 'Fant ikke reservasjonen',
            subtext: 'Enten er den blitt kansellert, er i fortiden, eller så er det en feil hos oss',
            button: 'Gå tilbake',
        },
        Support: {
            header: 'Fant ikke meldingen',
            subtext: 'Enten er den blitt slettet, eller så er det en feil hos oss',
            button: 'Gå tilbake',
        },
        MemberBenefit: {
            header: 'Fant ikke avtalen',
            subtext: 'Den er blitt fjernet',
            button: 'Gå tilbake',
        },
        Message: {
            header: 'Fant ikke noe',
            subtext: 'Enten er den utgått eller blitt fjernet',
            button: 'Gå tilbake',
        },
        Survey: {
            header: 'Fant ikke undersøkelsen',
            subtext: 'Den er enten blitt lukket eller fjernet',
            button: 'Gå tilbake',
        },
    },
    memberBenefits: {
        empty: 'Det er ikke registrert noen beboeravtaler',
        noResult: 'Fant ingen beboeravtaler med dette navnet',
        url: 'Nettside',
    },
    memberBenefit: {
        title: 'Beboeravtale',
        promo_code: 'Rabattkode',
        visit_website: 'Besøk nettsiden',
        showProof: 'Vis medlemsbevis',
        memberProof: 'Medlemsbevis',
        copied: 'Rabbattkode kopiert',
    },
    surveys: {
        title: 'Spørreundersøkelser',
        empty: 'Det er ikke registrert noen spørreundersøkelser',
        reciptientApartment:
            'Undersøkelsen kan kun besvares 1 gang per bolig. Eventuelle brukere koblet til samme bolig vil kunne se og redigere dine svar',
        reciptientUsers: 'Undersøkelsen kan kun besvares 1 gang per bruker.',
        questions: '{{questions}} spørsmål ({{requiredQuestions}} påkrevd)',
        addResponse: 'Svar på undersøkelsen',
        continueResponding: 'Fortsett svar',
        question: 'Spørsmål',
        nextQuestion: 'Gå videre',
        noMoreQuestions:
            "Du har svart på alle spørsmålene. Nå kan du enten se igjennom svarene dine eller sende inn undersøkelsen. Du kan også gå tilbake og endre på svarene dine. For å sende inn undersøkelsen trykker du på 'Send inn svar'",
        finishLater: 'Fullfør senere',
        sendSurvey: 'Send inn svar',
        answerError: 'Det oppsto en feil ved lagring av svaret',
        started: 'Det er allerede startet på et svar, du kan fortsatte på det.',
        noAnswer: 'Ingen svar',
        sentIn: 'Dine svar er allerede sendt inn',
        selectApartment: 'Velg bolig som du ønsker å svare på undersøkelsen for',
        noApartment:
            'Du er ikke koblet til noen bolig, du kan ikke svare på denne undersøkelsen. Er dette en feil kan du ta kontakt med {{boardNoun}} eller Heime',
        archived:
            'Undersøkelsen er arkivert og kan ikke besvares lengre. Hvis du har sendt inn svar kan du se dem under',
    },
    plusMenu: {
        recommended: 'Anbefalt',
        other: 'Andre',
        activityNew: {
            title: 'Opprett aktivitet',
            description: 'Samle naboer og gjør noe sammen. Noen forslag er kaffetreff, dugnad eller grillfest.',
        },
        groupNew: {
            title: 'Lag en gruppe',
            description:
                'Finn din interesse eller del dine erfaringer i grupper. Her får dere egen chat for diskusjon, deling og planlegging av aktivitet.',
        },
        noticeNew: {
            title: 'Del et oppslag',
            description: 'Oppslag kan være alt i fra informasjon til naboer, eller et spørsmål du trenger svar på.',
        },
        noticeByBoard: {
            title: 'Del et oppslag fra {{boardNoun}}',
            description: 'Marker et oppslag som det er fra {{boardNoun}}.',
        },
        helpfulNeighbour: {
            title: 'Bli en hjelpsom nabo',
            description:
                'Gi et uforpliktende tilbud om å hjelpe naboer. Noen forslag er hundepass, barnevakt, IT-hjelp eller håndverk.',
        },
        sharingNew: {
            title: 'Del en gjenstand',
            description:
                'Vi har alle noe i boden som kan glede andre. Ved å ta bilde og legge det ut kan naboene kontakte deg for å låne det.',
        },
        reportsNew: {
            title: 'Send inn melding til {{boardNoun}}',
            description:
                'Her kan du sende meldinger inn til {{boardNoun}}, og de varsles. Du og dem vil kunne kommentere på meldingen, og {{boardNoun}} kan oppdatere meldingens status.',
        },
        reservations: {
            title: 'Gjør en reservasjon',
            description: 'Ønsker du å reservere noe, kan du gjøre det her.',
        },
        chatNew: {
            title: 'Chat privat med nabo',
            description:
                'Her kan du selv velge naboer du vil snakke med og dele bilder. Meldingene som sendes her er helt private.',
        },
    },
    smallButtonNav: {
        board: 'Kontakter',
        documents: 'Filer',
        myHouse: 'Min bolig',
        neighbours: 'Naboer',
        groups: 'Grupper',
        myProfile: 'Min profil',
        memberBenefits: 'Beboer-avtaler',
        reports: 'Meldinger til {{boardNoun}}',
        surveys: 'Spørreundersøkelser',
        chat: 'Chat',
    },
    typeSpecific: {
        joint_ownership: {
            coopTypeDefiniteArticle: 'boligselskapet',
            boardNounDefiniteArticle: 'styret',
        },
        rental_project: {
            coopTypeDefiniteArticle: 'utleieprosjektet',
            boardNounDefiniteArticle: 'utleier',
        },
        suburban_housing: {
            coopTypeDefiniteArticle: 'velforeningen',
            boardNounDefiniteArticle: 'styret',
        },
    },
    managePlates: {
        header: 'Rediger lagrede registreringsnummer',
        save: 'Lagre',
        noPlates: 'Ingen registreringsnummer lagret',
        deleted_confirmation: 'Registreringsnummeret er slettet',
        plate: 'Registreringsnummer',
        name: 'Navn',
        edit: 'Rediger',
        delete: 'Slett',
        undo: 'Angre',
    },
    rateUs: {
        header: 'Vurder Heime',
        title: 'Vurder Heime',
        subtitle: 'Gi oss en vurdering, det hjelper oss å bli bedre. Velg mellom 1-5 stjerner',
        cancel: 'Senere',
        rate: 'Vurder nå',
        submit: 'Send inn',
        goToStore_ios: 'Gå til App Store',
        goToStore_android: 'Gå til Play Store',
        feedback_title: 'Gi oss din tilbakemelding',
        feedback_subtitle: 'Hva kan vi gjøre bedre? Vi setter pris på din tilbakemelding',
        feedback_label: 'Tilbakemelding',
        feedback_placeholder: 'Skriv din tilbakemelding her',
        feedback_success: 'Takk for din tilbakemelding',
    },
    procedure: {
        check_in_start: 'Innsjekk',
        check_out_start: 'Utsjekk',
        start_check_in_title: '{{name}} er satt opp med en insjekksprosess',
        start_check_out_title: '{{name}} er satt opp med en utsjekksprosess',
        start_procedure_explainer:
            'Det er obligatorisk og består av {{steps}} steg. Dette gjøres for å sikre ansvaret mellom de som har brukt produktet. Du er avhengig av å ha en internettilkobling gjennom hele prosessen',
        start_procedure_check_out_explainer: 'Du må fullføre utsjekksprosessen før reservasjonen er ferdig',
        start_procedure_check_out_warning:
            'Når utsjekken er gjennomført, vil du ikke ha tilgang til reservasjonen lenger.',
        start_check_in_action: 'Start insjekk',
        start_check_out_action: 'Start utsjekk',
        later: 'Senere',
        continue: 'Fortsett',
        skip: 'Hopp over',
        check_in_header: 'Innsjekk  - {{index}} av {{steps}}',
        check_out_header: 'Utsjekk - {{index}} av {{steps}}',
        completion_error: 'Klarte ikke å fullføre innsjekkingen. Prøv igjen.',
        answer_error: 'Klarte ikke å lagre svaret ditt. Prøv igjen.',
        approve_cleaning_header: 'Godkjenn forrige reservasjon',
        approve_cleaning_text:
            'Det er kun nå du har mulighet til å godkjenne forrige reservasjon. Dersom du ikke godkjenner nå, blir det notert. Du kan etter innsjekk finne kontaktinformasjonen til den som reserverte før deg. \n Ved godkjenning vil du være ansvarlig for rydding og vask etter din reservasjon.',
        approve_cleaning_disapproved:
            'Det er notert at du ikke godkjenner vasken som har vært. Det anbefales at du tar kontakt med den som reserverte før deg, og avtaler en løsning. \nFor å se hvem dette var,fullfører du innsjekkingsprosessen, og ser under "mine reservasjoner". Det kan også være lurt å ta noen bilder som dokumentasjon',
        checklist_header: 'Vennligst bekreft at alle punktene er gjennomført',
        information_header: 'Vennligst bekreft å ha lest informasjonen under',
        open_door_check_in_masterLock5441: 'Åpne boksen, og ta ut nøkkelen',
        open_door_check_in_other: 'Åpne låsen',
        open_door_check_out_open_to_close: 'Åpne låsen, for å deretter verifisere at den er lukket',
        open_door_check_out_danalock_relay: 'Åpne låsen',
        open_door_check_out_masterLock5441: 'Åpne boksen, og legg nøkkelen tilbake',
        open_door_check_out_danalock: 'Lukk låsen',
        open_door_check_in_permission_text_both:
            'Heime trenger tilgang til Bluetooth og din lokasjon for å åpne låsen. Trykk lås opp under for å kunne gi tilgang og låse opp.',
        open_door_check_out_permission_text_both:
            'Heime trenger tilgang til Bluetooth og din lokasjon for å åpne og lukke låsen. Trykk lås opp under for å kunne gi tilgang og låse opp.',
        open_door_check_in_unlock: 'Lås opp',
        open_door_check_in_permission_text_both_settings:
            'Heime trenger tilgang til Bluetooth og din lokasjon for å åpne låsen. Trykk under for å endre innstillingene',
        open_door_check_out_permission_text_both_settings:
            'Heime trenger tilgang til Bluetooth og din lokasjon for å åpne / lukke låsen. Trykk under for å endre innstillingene',
        open_door_open_settings: 'Endre innstillinger',
        open_door_check_in_permission_text_ble:
            'Heime trenger tilgang til Bluetooth for å åpne låsen. Trykk under for å endre innstillingene',
        open_door_check_out_permission_text_ble:
            'Heime trenger tilgang til Bluetooth for å åpne / lukke låsen. Trykk under for å endre innstillingene',
        open_door_check_in_permission_text_location:
            'Heime trenger tilgang til din lokasjon for å åpne låsen. Trykk under for å endre innstillingene',
        open_door_check_out_permission_text_location:
            'Heime trenger tilgang til lokasjon for å åpne / lukke låsen. Trykk under for å endre innstillingene',
        open_door_bluetooth_disabled: 'Bluetooth er avslått. Trykk under for å skru på Bluetooth',
        open_door_enable_bluetooth: 'Skru på Bluetooth',
        open_door_check_in_select_lock: 'Velg lås som skal åpnes opp',
        open_door_check_out_select_lock: 'Velg lås som skal åpnes / lukkes',
        open_door_check_in_open_lock: 'Åpne låsen',
        open_door_check_in_unlock_press: 'Trykk på knappen under for å åpne opp',
        open_door_check_in_get_key_press: 'For å få nøkkelen, lås opp nøkkelboksen',
        open_door_check_out_unlock_press: 'Trykk på knappen under for å åpne opp',
        open_door_check_out_open_lock: 'Åpne låsen',
        open_door_check_out_lock_press: 'Trykk på knappen under for å låse',
        open_door_check_out_close_lock: 'Lås',
        open_door_check_out_return_key_press: 'For å legge tilbake nøkkelen, trykk på knappen under',
        open_door_not_supported: 'For å åpne døren',
        pictures_not_supported: 'For å ta bilder',
        pictures_give_camera_access: 'Gi tilgang',
        pictures_open_settings: 'Åpne innstillinger',
        pictures_camera_access: 'Heime trenger tilgang til kamera',
        pictures_camera_access_denied: 'Heime har ikke tilgang til ditt kamera',
        pictures_camera_access_explanation:
            "I dette steget skal du ta bilder av produktet. Da trenger vi tilgang til ditt kamera. Trykk gi tilgang og deretter 'tillat'",
        pictures_camera_access_denied_explanation:
            'For å utføre dette steget, trenger vi tilgang til ditt kamera. Gå i innstillinger på telefonen og gi Heime tilgang til kamera',
        pictures_flash_off: 'Slå av blits',
        pictures_flash_on: 'Slå på blits',
        pictures_take_picture: 'Ta bilde',
        pictures_no_camera: 'Det ser ikke ut som denne enheten har et kamera',
        pictures_uploading: 'Laster opp bildet',
        pictures_approve_or_retake: 'Godkjenn bildet, eller ta et nytt',
        pictures_approve: 'Godkjenn',
        pictures_n_taken: 'Bilder tatt',
        skip_confirm: 'Er du sikker på at du vil hoppe over?',
        skip_confirm_text:
            'Ved å hoppe over, fraskriver du eventuelt ansvar den forrige brukeren hadde, og du er ansvarlig for hvordan produktet ser ut etter bookingen',
        skip_confirm_yes: 'Ja, hopp over',
        skip_confirm_no: 'Nei, gå tilbake',
        not_supported: 'Denne funksjonaliteten støttes ikke her',
        not_supported_use_mobile: 'vennligst bruk mobilappen',
        reservation_ends: 'Reservasjonen slutter om {{time}}',
        reservation_ends_explainer: 'Du vil ikke kunne fullføre etter dette tidspunktet',
        reservation_ended: 'Reservasjonen er avsluttet',
        reservation_ended_explainer:
            'Det betyr at du ikke får muligheten til å fullføre prosessen. For å få fornyet tilgang må du reservere på ny, eller ta kontakt med den som har reservert etter deg.',
        go_back: 'Gå tilbake',
        completion_success_check_in: 'Du er sjekket inn',
        completion_success_text_check_in: 'Svarene dine er notert, og du kan ta produktet i bruk',
        completion_success_check_out: 'Du er sjekket ut',
        completion_success_text_check_out: 'Svarene dine er notert, du kan nå anse reservasjonen din som avsluttet',
    },
    countryCode: {
        af: 'Afghanistan',
        eg: 'Egypt',
        ax: 'Åland',
        al: 'Albania',
        dz: 'Algeria',
        as: 'Amerikansk Samoa',
        vi: 'De amerikanske Jomfruøyer',
        um: 'USAs ytre småøyer',
        ad: 'Andorra',
        ao: 'Angola',
        ai: 'Anguilla',
        aq: 'Antarktis',
        ag: 'Antigua og Barbuda',
        gq: 'Ekvatorial-Guinea',
        ar: 'Argentina',
        am: 'Armenia',
        aw: 'Aruba',
        az: 'Aserbajdsjan',
        et: 'Etiopia',
        au: 'Australia',
        bs: 'Bahamas',
        bh: 'Bahrain',
        bd: 'Bangladesh',
        bb: 'Barbados',
        by: 'Hviterussland',
        be: 'Belgia',
        bz: 'Belize',
        bj: 'Benin',
        bm: 'Bermuda',
        bt: 'Bhutan',
        bo: 'Bolivia',
        bq: 'Bonaire, Sint Eustatius og Saba',
        ba: 'Bosnia-Hercegovina',
        bw: 'Botswana',
        br: 'Brasil',
        vg: 'De britiske Jomfruøyer',
        io: 'Det britiske territoriet i Indiahavet',
        bn: 'Brunei',
        bg: 'Bulgaria',
        bf: 'Burkina Faso',
        bi: 'Burundi',
        cv: 'Kapp Verde',
        cl: 'Chile',
        cn: 'Kina',
        ck: 'Cookøyene',
        cr: 'Costa Rica',
        ci: 'Elfenbenskysten',
        cw: 'Curaçao',
        dk: 'Danmark',
        de: 'Tyskland',
        dm: 'Dominica',
        do: 'Den dominikanske republikk',
        dj: 'Djibouti',
        ec: 'Ecuador',
        sv: 'El Salvador',
        er: 'Eritrea',
        ee: 'Estland',
        fk: 'Falklandsøyene',
        fo: 'Færøyene',
        fj: 'Fiji',
        fi: 'Finland',
        fr: 'Frankrike',
        gf: 'Fransk Guyana',
        pf: 'Fransk Polynesia',
        tf: 'De franske sørterritorier',
        ga: 'Gabon',
        gm: 'Gambia',
        ge: 'Georgia',
        gh: 'Ghana',
        gi: 'Gibraltar',
        gd: 'Grenada',
        gr: 'Hellas',
        gl: 'Grønland',
        gp: 'Guadeloupe',
        gu: 'Guam',
        gt: 'Guatemala',
        gg: 'Guernsey',
        gn: 'Guinea',
        gw: 'Guinea-Bissau',
        gy: 'Guyana',
        ht: 'Haiti',
        hn: 'Honduras',
        in: 'India',
        id: 'Indonesia',
        iq: 'Irak',
        ir: 'Iran',
        ie: 'Irland',
        is: 'Island',
        im: 'Man',
        il: 'Israel',
        it: 'Italia',
        jm: 'Jamaica',
        jp: 'Japan',
        ye: 'Jemen',
        je: 'Jersey',
        jo: 'Jordan',
        ky: 'Caymanøyene',
        kh: 'Kambodsja',
        cm: 'Kamerun',
        ca: 'Canada',
        kz: 'Kasakhstan',
        qa: 'Qatar',
        ke: 'Kenya',
        kg: 'Kirgisistan',
        ki: 'Kiribati',
        cc: 'Kokosøyene',
        co: 'Colombia',
        km: 'Komorene',
        cg: 'Kongo-Brazzaville',
        cd: 'Kongo-Kinshasa',
        xk: 'Kosovo',
        hr: 'Kroatia',
        cu: 'Cuba',
        kw: 'Kuwait',
        la: 'Laos',
        ls: 'Lesotho',
        lv: 'Latvia',
        lb: 'Libanon',
        lr: 'Liberia',
        ly: 'Libya',
        li: 'Liechtenstein',
        lt: 'Litauen',
        lu: 'Luxembourg',
        mg: 'Madagaskar',
        mw: 'Malawi',
        my: 'Malaysia',
        mv: 'Maldivene',
        ml: 'Mali',
        mt: 'Malta',
        ma: 'Marokko',
        mh: 'Marshalløyene',
        mq: 'Martinique',
        mr: 'Mauritania',
        mu: 'Mauritius',
        yt: 'Mayotte',
        mx: 'Mexico',
        fm: 'Mikronesiaføderasjonen',
        mc: 'Monaco',
        mn: 'Mongolia',
        me: 'Montenegro',
        ms: 'Montserrat',
        mz: 'Mosambik',
        mm: 'Myanmar (Burma)',
        na: 'Namibia',
        nr: 'Nauru',
        np: 'Nepal',
        nc: 'Ny-Caledonia',
        nz: 'New Zealand',
        ni: 'Nicaragua',
        nl: 'Nederland',
        ne: 'Niger',
        ng: 'Nigeria',
        nu: 'Niue',
        kp: 'Nord-Korea',
        mp: 'Nord-Marianene',
        mk: 'Nord-Makedonia',
        nf: 'Norfolkøya',
        no: 'Norge',
        om: 'Oman',
        at: 'Østerrike',
        pk: 'Pakistan',
        ps: 'De palestinske territoriene',
        pw: 'Palau',
        pa: 'Panama',
        pg: 'Papua Ny-Guinea',
        py: 'Paraguay',
        pe: 'Peru',
        ph: 'Filippinene',
        pn: 'Pitcairnøyene',
        pl: 'Polen',
        pt: 'Portugal',
        pr: 'Puerto Rico',
        md: 'Moldova',
        re: 'Réunion',
        rw: 'Rwanda',
        ro: 'Romania',
        ru: 'Russland',
        sb: 'Salomonøyene',
        zm: 'Zambia',
        ws: 'Samoa',
        sm: 'San Marino',
        st: 'São Tomé og Príncipe',
        sa: 'Saudi-Arabia',
        se: 'Sverige',
        ch: 'Sveits',
        sn: 'Senegal',
        rs: 'Serbia',
        sc: 'Seychellene',
        sl: 'Sierra Leone',
        zw: 'Zimbabwe',
        sg: 'Singapore',
        sx: 'Sint Maarten',
        sk: 'Slovakia',
        si: 'Slovenia',
        so: 'Somalia',
        hk: 'Hongkong SAR Kina',
        mo: 'Macao SAR Kina',
        es: 'Spania',
        sj: 'Svalbard og Jan Mayen',
        lk: 'Sri Lanka',
        bl: 'Saint-Barthélemy',
        sh: 'St. Helena',
        kn: 'Saint Kitts og Nevis',
        lc: 'Saint Lucia',
        mf: 'Saint-Martin',
        pm: 'Saint-Pierre og Miquelon',
        vc: 'Saint Vincent og Grenadinene',
        za: 'Sør-Afrika',
        sd: 'Sudan',
        gs: 'Sør-Georgia og Sør-Sandwichøyene',
        kr: 'Sør-Korea',
        ss: 'Sør-Sudan',
        sr: 'Surinam',
        sz: 'Eswatini',
        sy: 'Syria',
        tj: 'Tadsjikistan',
        tw: 'Taiwan',
        tz: 'Tanzania',
        th: 'Thailand',
        tl: 'Øst-Timor',
        tg: 'Togo',
        tk: 'Tokelau',
        to: 'Tonga',
        tt: 'Trinidad og Tobago',
        td: 'Tsjad',
        cz: 'Tsjekkia',
        tn: 'Tunisia',
        tr: 'Tyrkia',
        tm: 'Turkmenistan',
        tc: 'Turks- og Caicosøyene',
        tv: 'Tuvalu',
        ug: 'Uganda',
        ua: 'Ukraina',
        hu: 'Ungarn',
        uy: 'Uruguay',
        uz: 'Usbekistan',
        vu: 'Vanuatu',
        va: 'Vatikanstaten',
        ve: 'Venezuela',
        ae: 'De forente arabiske emirater',
        us: 'USA',
        gb: 'Storbritannia',
        vn: 'Vietnam',
        wf: 'Wallis og Futuna',
        cx: 'Christmasøya',
        eh: 'Vest-Sahara',
        cf: 'Den sentralafrikanske republikk',
        cy: 'Kypros',
        ac: 'Ascension',
        ta: 'Tristan da Cunha',
    },
};
