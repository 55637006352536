import React, { ReactElement, useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { ChatInfoAvatar, LeaveChatModal, MediaList, MemberList, Tabs } from './components';
import Background from './components/LinearGradient';
import { ChatInfo, useGetChat, useGetFiles } from '../../_api/useChats';
import { useOwnProfile } from '../../_api/useProfile';
import { SafeAreaView } from '../../_dependencies/safeArea';
import _fonts from '../../_fonts';
import Images from '../../_images';
import { useAppNavigation } from '../../_navigator';
import { Theme, useThemeStyle, getChatName, WH, WW } from '../../_utils';
import { smallestFontSize } from '../../_utils/sizes';
import { ThemeContext } from '../../_utils/themeContext';
import { ContextMenu, HeaderWithNav, ContextTrigger, Loader, CacheImage, QueryItemView } from '../../Components';

interface ChatInfoScreenProps {
    route: { params: { chatId?: number } };
}

const ChatInfoLoadedGate = ({ route }: ChatInfoScreenProps): ReactElement | null => {
    const chatId = route?.params?.chatId;
    if (!chatId) {
        throw new Error('Received no chatId in route params');
    }

    const { data, isLoading, isError, error } = useGetChat(chatId);
    // Prefetching
    useGetFiles(chatId);

    if (isError) {
        throw error;
    }

    if (isLoading) {
        return <Loader />;
    }

    if (!data) {
        return null;
    }

    return <ChatInfoScreen chatInfo={data} />;
};

const ChatInfoScreen = ({ chatInfo }: { chatInfo: ChatInfo }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const { data: profile } = useOwnProfile();
    const { isFetching, refetch } = useGetChat(chatInfo.id);
    const { navigate } = useAppNavigation();

    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [showLeaveModal, setShowLeaveModal] = useState(false);

    const title = getChatName(chatInfo, profile?.id ?? 0, t);

    const subTitle =
        chatInfo.user_group || chatInfo.users.length > 2
            ? t('chatInfo:nMembers', { count: chatInfo.users.length })
            : '';

    const tabs = useMemo(() => {
        const arr = [];
        if (chatInfo.users.length > 2 || chatInfo.user_group !== null) {
            arr.push({
                title: t('chatInfo:members'),
                content: (
                    <MemberList
                        creator_id={chatInfo.creator_id}
                        users={chatInfo.users}
                        chatId={chatInfo.id}
                        isGroupRelated={Boolean(chatInfo.user_group)}
                    />
                ),
            });
        }
        arr.push({
            title: t('chatInfo:media'),
            content: <MediaList type={'media'} chatId={chatInfo.id} />,
        });
        arr.push({
            title: t('chatInfo:files'),
            content: <MediaList type={'files'} chatId={chatInfo.id} />,
        });

        return arr;
    }, [chatInfo.users, chatInfo.user_group, chatInfo.id, chatInfo.creator_id, t]);

    const handleContextMenuTrigger = () => setContextMenuOpen((curr) => !curr);
    const handleAddMemberPress = () => {
        handleContextMenuTrigger();
        navigate('ChatNewMembers', {
            type: 'add',
            alreadyJoined: chatInfo.users.map(({ id }) => id),
            chatId: chatInfo.id,
        });
    };

    const handleLeaveChatPress = () => setShowLeaveModal(true);
    const handleCloseLeaveModal = () => setShowLeaveModal(false);

    const top = useMemo(() => {
        if (chatInfo.user_group) {
            const handleGoToGroup = () => navigate('GroupSelected', { groupId: chatInfo.user_group?.id as number });
            return (
                <View style={themedStyle.groupImage}>
                    <Background>
                        <TouchableOpacity onPress={handleGoToGroup} style={themedStyle.groupAvatar}>
                            <CacheImage
                                resizeMode={chatInfo.user_group.main_picture ? 'cover' : 'contain'}
                                style={themedStyle.groupAvatar}
                                source={chatInfo.user_group.main_picture ?? Images.boardSection}
                            />
                        </TouchableOpacity>
                    </Background>
                    <View style={themedStyle.absolute}>
                        <Text style={[themedStyle.title, { color: theme.mainText }]}>{title}</Text>
                        <Text style={[themedStyle.subTitle, { color: theme.mainText }]}>{subTitle}</Text>
                    </View>
                </View>
            );
        }

        const content = (
            <>
                <ChatInfoAvatar userGroupPicture={null} users={chatInfo.users} />
                <Text style={themedStyle.title}>{title}</Text>
                <Text style={themedStyle.subTitle}>{subTitle}</Text>
            </>
        );
        const otherUsers = chatInfo.users.filter(({ id }) => id !== profile?.id);

        if (otherUsers.length === 1) {
            const handleNavigateToUser = () => navigate('Profile', { id: otherUsers[0].id });
            return (
                <TouchableOpacity onPress={handleNavigateToUser} style={themedStyle.topContent}>
                    {content}
                </TouchableOpacity>
            );
        }
        return <View style={themedStyle.topContent}>{content}</View>;
    }, [
        chatInfo.user_group,
        chatInfo.users,
        navigate,
        profile?.id,
        subTitle,
        theme.mainText,
        themedStyle.absolute,
        themedStyle.groupAvatar,
        themedStyle.groupImage,
        themedStyle.subTitle,
        themedStyle.title,
        themedStyle.topContent,
        title,
    ]);

    const isAdmin = chatInfo.creator_id === profile?.id;

    return (
        <SafeAreaView style={themedStyle.full} edges={['bottom', 'left', 'right']}>
            <HeaderWithNav
                style={themedStyle.navHeader}
                title={''}
                action={
                    chatInfo.user_group ? undefined : !isAdmin ? (
                        chatInfo.users.length > 2 ? (
                            <ContextMenu
                                isOpen={contextMenuOpen}
                                onToggle={handleContextMenuTrigger}
                                actions={[
                                    { text: t('chatInfo:leaveChat'), onPress: handleLeaveChatPress, type: 'danger' },
                                ]}
                                trigger={<ContextTrigger variant="light" />}
                            />
                        ) : undefined
                    ) : (
                        <ContextMenu
                            isOpen={contextMenuOpen}
                            onToggle={handleContextMenuTrigger}
                            actions={[
                                {
                                    text: t('chatInfo:addMember'),
                                    onPress: handleAddMemberPress,
                                    type: 'normal',
                                },
                            ]}
                            trigger={<ContextTrigger variant="light" />}
                        />
                    )
                }
            />
            <QueryItemView virtualized onRefresh={refetch} isRefreshing={isFetching} style={themedStyle.main}>
                {top}
                <Tabs tabs={tabs} />
            </QueryItemView>
            {showLeaveModal && <LeaveChatModal onClose={handleCloseLeaveModal} chatId={chatInfo.id} />}
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        full: {
            height: '100%',
            backgroundColor: theme.mainBackground,
        },
        navHeader: { backgroundColor: theme.mainBackground },
        main: {
            backgroundColor: theme.mainBackground,
            flex: 1,
        },
        topContent: { justifyContent: 'center', alignItems: 'center' },
        groupAvatar: { width: WW, height: WH * 0.3 },
        absolute: {
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            paddingTop: WW * 0.03,
            paddingBottom: WW * 0.03,
            position: 'absolute',
            bottom: 0,
            left: 0,
        },
        title: {
            marginTop: WW * 0.04,
            color: theme.black,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.06,
        },
        subTitle: {
            color: theme.secondaryLight,
            fontFamily: _fonts.primaryFont,
            marginBottom: WW * 0.04,
            fontSize: smallestFontSize,
        },
        groupImage: { position: 'relative' },
    });

export default ChatInfoLoadedGate;
