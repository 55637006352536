import { useState, useCallback, useEffect } from 'react';
import { AppState, AppStateStatus } from 'react-native';
import { permissions } from '../permission';

type returnType = Awaited<ReturnType<typeof permissions.checkBluetooth>>;

const useBluetoothPermissionStatus = (): returnType | null => {
    const [permissionStatus, setPermissionStatus] = useState<returnType | null>(null);

    const onCheckPermission = useCallback(async () => {
        const status = await permissions.checkBluetooth();

        setPermissionStatus(status);
    }, []);

    useEffect(() => {
        onCheckPermission();
    });

    useEffect(() => {
        const listener = AppState.addEventListener('change', (state: AppStateStatus) => {
            if (state === 'active') {
                onCheckPermission();
            }
        });

        return () => {
            listener.remove();
        };
    });

    return permissionStatus;
};

export default useBluetoothPermissionStatus;
