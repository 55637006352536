import React, { ReactElement, useMemo, useContext } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, SubmitHandler, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Menu, MenuOption, MenuOptions, MenuTrigger } from 'react-native-popup-menu';
import * as z from 'zod';
import { useSelectedCoopItem } from '_utils/hooks';
import { optionalNumberInput } from '_utils/zod';
import { Apartment } from 'types/Apartment';
import { useEditApartment } from '../../_api/useApartments';
import { SafeAreaView } from '../../_dependencies/safeArea';
import _fonts from '../../_fonts';
import { useAppNavigation } from '../../_navigator';
import {
    Theme,
    useConfirmDiscard,
    useThemeStyle,
    WW,
    isAppError,
    showToast,
    isNetworkError,
    useApartmentColumnOrder,
} from '../../_utils';
import { ThemeContext } from '../../_utils/themeContext';
import { FormInput, HeaderWithNav, Icon, PrimaryButton } from '../../Components';

interface HomeEditProps {
    route: { params: { apartment: Apartment } };
}

const staticFormSchema = z.object({
    legal_name: z.string(),
    project_name: z.string().max(255).min(1),
    address: z.string(),
    type: z.enum(['apartment', 'detached_house', 'semi_detached_house', 'room']),
    floor: optionalNumberInput,
    area: optionalNumberInput,
    living_area: optionalNumberInput,
    rooms: optionalNumberInput,
    parking_spot: z.string().max(255),
});

const generateFormSchema = (order: ReturnType<typeof useApartmentColumnOrder>) =>
    staticFormSchema.merge(
        z.object({
            [order[0]]: z.string().max(255).min(1),
        }),
    );

type FormValues = Required<z.infer<typeof staticFormSchema>>;

const mapToDefault = (initial: Apartment): FormValues => {
    return {
        project_name: initial.project_name ?? '',
        legal_name: initial.legal_name ?? '',
        address: initial.address ?? '',
        type: initial.type ?? 'apartment',
        floor: initial.floor ?? 0,
        area: initial.area ?? 0,
        living_area: initial.living_area ?? 0,
        rooms: initial.rooms ?? 0,
        parking_spot: initial.parking_spot ?? '',
    };
};

function defaultIfFalsy<T>(value: '' | number | undefined, defaultVal: T) {
    if (value) {
        return value;
    }
    return defaultVal;
}

const HomeEdit = ({ route }: HomeEditProps): ReactElement => {
    const selectedCoop = useSelectedCoopItem();
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { goBack: internalGoBack } = useAppNavigation();
    const { isPending: isLoading, mutate: editApartment } = useEditApartment();

    const order = useApartmentColumnOrder(selectedCoop?.apartment_display ?? 'project_name');
    const { formState, watch, setValue, setError, ...methods } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: mapToDefault(route.params.apartment),
        resolver: zodResolver(useMemo(() => generateFormSchema(order), [order])),
    });

    const { isValid } = formState;

    const { content } = useConfirmDiscard(formState.isDirty && !isLoading, true, internalGoBack);

    const buttonStatus = useMemo(() => {
        return isLoading ? 'loading' : isValid ? null : 'disabled';
    }, [isLoading, isValid]);

    const onSubmit: SubmitHandler<FormValues> = (formValues) => {
        const apartmentId = route.params.apartment.id;
        editApartment(
            [
                apartmentId,
                {
                    ...formValues,
                    area: defaultIfFalsy(formValues.area, 0),
                    living_area: defaultIfFalsy(formValues.living_area, 0),
                    floor: defaultIfFalsy(formValues.floor, 0),
                    rooms: defaultIfFalsy(formValues.rooms, 0),
                },
            ],
            {
                onSuccess: () => {
                    internalGoBack();
                },
                onError: (error) => {
                    Keyboard.dismiss();
                    if (isAppError(error)) {
                        if (isNetworkError(error)) {
                            return;
                        }

                        const duplicateNames = order.filter(
                            (key) => error.response?.data.errors && key in error.response?.data.errors,
                        );
                        if (duplicateNames.length > 0) {
                            duplicateNames.forEach((key) => {
                                const message = t('editHome:codeExists', {
                                    param: t(`myHome:${key}`),
                                    coopType: t(
                                        `typeSpecific:${selectedCoop?.type ?? 'joint_ownership'}:coopTypeDefiniteArticle`,
                                    ),
                                });
                                showToast({ header: message, text: '', type: 'error' });
                                setError(key, { message, type: 'manual' });
                            });
                            return;
                        }
                    }
                    showToast({
                        header: t('global:error_saving_header'),
                        text: t('global:error_saving_description'),
                        type: 'error',
                    });
                },
            },
        );
    };

    return (
        <SafeAreaView style={themedStyle.full} edges={['bottom', 'left', 'right']}>
            <HeaderWithNav style={themedStyle.navHeader} title={t('editHome:title')} />
            <ScrollView style={themedStyle.content} keyboardShouldPersistTaps="always">
                <FormProvider setValue={setValue} watch={watch} formState={formState} setError={setError} {...methods}>
                    {order.map((key) => (
                        <FormInput key={key} name={key} label={t(`myHome:${key}`)} />
                    ))}
                    <DropdownSelector
                        items={[
                            { name: t('myHome:detached_house'), id: 'detached_house' },
                            { name: t('myHome:semi_detached_house'), id: 'semi_detached_house' },
                            { name: t('myHome:apartment'), id: 'apartment' },
                            { name: t('myHome:room'), id: 'room' },
                        ]}
                    />
                    <FormInput type="number" name="floor" label={t('myHome:floor')} />
                    <FormInput type="number" name="area" label={t('myHome:area')} suffix="m²" />
                    <FormInput type="number" name="living_area" label={t('myHome:living_area')} suffix="m²" />
                    <FormInput type="number" name="rooms" label={t('myHome:bedrooms')} />
                    <FormInput name="parking_spot" label={t('myHome:parking_spot')} />
                </FormProvider>
            </ScrollView>
            <PrimaryButton
                status={buttonStatus}
                text={t('editHome:save')}
                onPress={methods.handleSubmit(onSubmit)}
                bottomAction
            />
            {content}
        </SafeAreaView>
    );
};

interface DropdownSelectorProps {
    items: { id: 'apartment' | 'detached_house' | 'semi_detached_house' | 'room'; name: string }[];
}
const DropdownSelector = ({ items }: DropdownSelectorProps) => {
    const themedStyle = useThemeStyle(styles);
    const themeddropdownStyles = useThemeStyle(dropdownStyles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const { field } = useController({ name: 'type' });
    const selectedItem = items.find((item) => item.id === field.value);
    const getOnChangeHandler = (id: string) => () => field.onChange(id);

    return (
        <>
            <Text style={themeddropdownStyles.label}>{t('myHome:type')}</Text>
            <Menu>
                <MenuTrigger>
                    <View
                        style={[
                            themeddropdownStyles.selected,
                            {
                                borderColor: field.value ? theme.main : theme.secondaryLight,
                            },
                        ]}
                    >
                        <Text style={themeddropdownStyles.selectedText}>{selectedItem?.name ?? ''}</Text>
                        <Icon style={themeddropdownStyles.chevron} name={'chevron'} color="black" />
                    </View>
                </MenuTrigger>
                <MenuOptions customStyles={{ optionsContainer: themeddropdownStyles.menu }}>
                    {items?.map(({ id, name }) => (
                        <MenuOption key={id} onSelect={getOnChangeHandler(id)}>
                            <Text style={themedStyle.menuText}>{name}</Text>
                        </MenuOption>
                    ))}
                </MenuOptions>
            </Menu>
        </>
    );
};

const dropdownStyles = (theme: Theme) =>
    StyleSheet.create({
        selected: {
            flexDirection: 'row',
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            alignItems: 'center',
            borderWidth: 1.5,
            borderRadius: 4,
        },
        selectedText: {
            flex: 1,
            fontFamily: _fonts.primaryFont,
        },
        chevron: { transform: [{ rotate: '90deg' }] },
        label: {
            color: theme.black,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.04,
            marginBottom: WW * 0.01,
            marginTop: WW * 0.03,
        },
        menu: {
            width: WW * 0.92,
            marginTop: WW * 0.1,
            backgroundColor: theme.mainBackground,
        },
    });

const styles = (theme: Theme) =>
    StyleSheet.create({
        full: {
            height: '100%',
            backgroundColor: theme.mainBackground,
        },
        navHeader: {
            backgroundColor: theme.mainBackground,
        },
        content: {
            flex: 1,
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
        },
        menuText: {
            color: theme.black,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
        },
    });

export default HomeEdit;
