import React, { PropsWithChildren, ReactElement, useEffect, useMemo } from 'react';
import useRefreshToken from './useRefreshToken';
import { loggedInState } from '../../_redux/settings';
import { useGlobalState } from '../../_utils';
import { init } from '../../_utils/socket';
import { AuthContext } from '../AuthContext';

interface LoggedInGate {
    state: loggedInState;
    onRefreshToken(result: loggedInState): void;
    onLogout(): void;
}

const LoggedInGate = ({ state, children, onRefreshToken, onLogout }: PropsWithChildren<LoggedInGate>): ReactElement => {
    const selectedLang = useGlobalState((globalState) => globalState.selectedLanguage.selectedLanguage);

    const getAccessToken = useRefreshToken(state, onRefreshToken);

    useEffect(() => {
        init(state.access_token, selectedLang ?? 'no');
    }, [selectedLang, state.access_token]);

    const context = useMemo(() => ({ getAccessToken, logout: onLogout }), [onLogout, getAccessToken]);

    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export default LoggedInGate;
