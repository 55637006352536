import React, { ReactElement, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { z } from 'zod';
import { Survey, useSurvey } from '_api/useSurveys';
import { useAppNavigation } from '_navigator';
import { Theme, useThemeStyle, useUpdatingFormattedTimeAgo } from '_utils';
import { screenMargin, smallestFontSize, smallestMargin, subtitleFontSize, titleFontSize } from '_utils/sizes';
import { EmptyMessage, HeimeText, Icon, PrimaryButton, QueryItemView, SecondaryButton } from 'Components';
import { RoutePropNumberNullable, RoutePropsSchema } from 'types/Utility';
import { AnswerOverview } from '../components';
import { useSurveyContext } from '../SurveyContext';
import { SurveyScreenProps, useSurveyNavigation } from '../SurveyNavigation';

const propsSchema = RoutePropsSchema(
    z.object({
        apartmentId: RoutePropNumberNullable,
    }),
);

const SurveySelected = (props: SurveyScreenProps<'SurveyDetails'>): ReactElement => {
    const {
        route: {
            params: { apartmentId },
        },
    } = propsSchema.parse(props);
    const {
        state: { surveyId },
        openSurveySelected,
    } = useSurveyContext();

    const { t } = useTranslation();
    const themedStyle = useThemeStyle(styles);
    const { data, refetch, isRefetching } = useSurvey(surveyId);
    const { canGoBack: canGoBackSurvey, goBack: goBackSurvey } = useSurveyNavigation();
    const { goBack } = useAppNavigation();

    if (!data) {
        throw new Error('Unexpected null data');
    }

    const handleGoBack = useCallback(() => {
        if (canGoBackSurvey()) {
            goBackSurvey();
        } else {
            goBack();
        }
    }, [canGoBackSurvey, goBack, goBackSurvey]);

    useFocusEffect(
        useCallback(() => {
            openSurveySelected(handleGoBack);
        }, [handleGoBack, openSurveySelected]),
    );

    const noApartmentForApartmentSurvey = data.recipients_type === 'APARTMENTS' && apartmentId === null;

    return (
        <View style={themedStyle.main}>
            <QueryItemView onRefresh={refetch} isRefreshing={isRefetching} style={themedStyle.container}>
                {noApartmentForApartmentSurvey ? (
                    <View style={themedStyle.messageContainer}>
                        <EmptyMessage message={t('surveys:noApartment')} />
                    </View>
                ) : (
                    <SurveyDetails apartmentId={apartmentId} item={data} />
                )}
            </QueryItemView>
            {data.status !== 'ACTIVE' ? null : noApartmentForApartmentSurvey ? (
                <SecondaryButton text={t('global:back')} style={themedStyle.bottomSecondary} onPress={handleGoBack} />
            ) : (
                <SurveyButton item={data} apartmentId={apartmentId} />
            )}
        </View>
    );
};

const SurveyDetails = ({ item, apartmentId }: { item: Survey; apartmentId: number | null }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const publishedAgo = useUpdatingFormattedTimeAgo(moment.unix(item.published_at ?? 0).toDate());
    const { navigate } = useSurveyNavigation();

    const handleAnswerSurvey = () => {
        if (item.questions.length > 0) {
            navigate('SurveyAnswer', {
                questionId: item.questions[0].id,
                apartmentId: apartmentId ? apartmentId + '' : null,
            });
        }
    };

    return (
        <>
            <HeimeText maxFontSizeMultiplier={2} style={themedStyle.itemTitle}>
                {item.title}
            </HeimeText>
            {item.description ? (
                <HeimeText maxFontSizeMultiplier={2} linkify style={themedStyle.itemDescription}>
                    {item.description}
                </HeimeText>
            ) : null}
            <HeimeText style={themedStyle.itemDate}>{publishedAgo}</HeimeText>
            <View style={themedStyle.flex}>
                <Icon name="question" color="main" />
                <HeimeText maxFontSizeMultiplier={2} style={themedStyle.questionExplainer}>
                    {t('surveys:questions', {
                        questions: item.questions.length,
                        requiredQuestions: item.questions.filter((q) => q.required).length,
                    })}
                </HeimeText>
            </View>
            <HeimeText maxFontSizeMultiplier={2} style={themedStyle.itemExplainer}>
                {item.recipients_type === 'APARTMENTS'
                    ? t('surveys:reciptientApartment')
                    : t('surveys:reciptientUsers')}
            </HeimeText>
            {item.status === 'ARCHIVED' ? (
                <HeimeText maxFontSizeMultiplier={2} style={themedStyle.itemExplainer}>
                    {t('surveys:archived')}
                </HeimeText>
            ) : null}

            {item.responses
                .filter((response) => apartmentId === null || response.apartment_id === apartmentId)
                .map((r) => (
                    <React.Fragment key={r.id}>
                        {item.status !== 'ACTIVE' ? null : r.status === 'DRAFT' ? (
                            <HeimeText maxFontSizeMultiplier={2} style={themedStyle.itemExplainer}>
                                {t('surveys:started')}
                            </HeimeText>
                        ) : (
                            <HeimeText maxFontSizeMultiplier={2} style={themedStyle.itemExplainer}>
                                {t('surveys:sentIn')}
                            </HeimeText>
                        )}
                        <AnswerOverview questions={item.questions} answers={r.answers} />
                        {r.status === 'DRAFT' && item.status === 'ACTIVE' ? (
                            <SecondaryButton
                                style={themedStyle.bottomButton}
                                key={r.id}
                                text={t('surveys:continueResponding')}
                                onPress={handleAnswerSurvey}
                            />
                        ) : null}
                    </React.Fragment>
                ))}
        </>
    );
};

const SurveyButton = ({ item, apartmentId }: { item: Survey; apartmentId: number | null }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate } = useSurveyNavigation();

    const handleAnswerSurvey = () => {
        if (item.questions.length > 0) {
            navigate('SurveyAnswer', {
                questionId: item.questions[0].id,
                apartmentId: apartmentId ? apartmentId + '' : null,
            });
        }
    };

    return (
        <>
            {item.responses.filter((response) => apartmentId === null || apartmentId === response.apartment_id).length >
            0 ? null : (
                <PrimaryButton
                    text={t('surveys:addResponse')}
                    onPress={handleAnswerSurvey}
                    bottomAction
                    style={themedStyle.bottomAction}
                />
            )}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.lightBackground,
            width: '100%',
            flex: 1,
            display: 'flex',
        },
        container: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            paddingBottom: screenMargin,
            flex: 1,
        },
        itemTitle: {
            fontSize: titleFontSize,
            color: theme.main,
            fontWeight: 'bold',
            marginBottom: smallestMargin,
        },
        itemDescription: {
            fontSize: subtitleFontSize,
            lineHeight: subtitleFontSize * 1.5,
            color: theme.black,
            marginBottom: smallestMargin,
        },
        itemDate: {
            fontSize: smallestFontSize,
            color: theme.darkGray,
        },
        itemExplainer: {
            fontSize: subtitleFontSize,
            marginTop: smallestMargin,
            color: theme.darkGray,
        },
        flex: {
            flexDirection: 'row',
            marginTop: screenMargin,
            alignItems: 'center',
        },
        questionExplainer: {
            fontSize: subtitleFontSize,
            marginLeft: smallestMargin,
            color: theme.main,
        },
        bottomButton: {
            marginTop: 'auto',
            marginBottom: screenMargin,
        },
        bottomAction: {
            marginTop: 0,
        },
        headerQuestion: {
            fontSize: titleFontSize,
            fontWeight: 'bold',
            marginTop: screenMargin,
        },
        messageContainer: {
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-around',
            minHeight: '100%',
        },
        bottomSecondary: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            marginBottom: screenMargin,
        },
    });

export default SurveySelected;
