import { ReactElement } from 'react';
import { View, StyleSheet } from 'react-native';
import { screenMargin } from '_utils/sizes';

const ContentDisplay = ({ content, buttons }: { content: ReactElement; buttons: ReactElement }) => {
    return (
        <View style={styles.container}>
            <View style={styles.contentContainer}>{content}</View>
            <View style={styles.bottom}>{buttons}</View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        gap: screenMargin,
    },
    bottom: {
        marginTop: 'auto',
    },
    contentContainer: {
        flex: 1,
        justifyContent: 'center',
        gap: screenMargin,
    },
});

export default ContentDisplay;
