import { View, StyleSheet, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { smallestFontSize, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { HeimeText } from 'Components';

const InformationBubble = ({
    title,
    description,
    content,
    onPress,
    style: styleProp,
}: {
    title: string;
    description: string;
    content?: React.ReactNode;
    onPress?: () => void;
    style?: StyleProp<ViewStyle>;
}) => {
    const themedStyle = useThemeStyle(style);

    const ContainerElement: React.ElementType = onPress ? TouchableOpacity : View;

    return (
        <ContainerElement onPress={onPress} style={[themedStyle.container, styleProp]}>
            <View style={themedStyle.textContainer}>
                <HeimeText style={themedStyle.header}>{title}</HeimeText>
                <HeimeText style={themedStyle.description}>{description}</HeimeText>
            </View>
            {content}
        </ContainerElement>
    );
};

const style = (theme: Theme) =>
    StyleSheet.create({
        container: {
            width: '100%',
            borderWidth: 1,
            borderColor: theme.lightGrey,
            borderRadius: 10,
            paddingRight: smallestMargin,
            paddingLeft: smallestMargin,
            paddingTop: smallestMargin,
            paddingBottom: smallestMargin,
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: smallestMargin,
            marginTop: smallestMargin,
        },
        textContainer: {
            gap: smallestMargin,
            marginBottom: smallestMargin,
            marginTop: smallestMargin,
            flex: 1,
        },
        header: {
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            color: theme.black,
        },
        description: {
            fontSize: smallestFontSize,
            color: theme.darkGray,
        },
    });

export default InformationBubble;
