import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { PageContainer } from './common';
import { Group, useGetGroups } from '../../../../_api/useGroups';
import { useOwnProfile } from '../../../../_api/useProfile';
import _fonts from '../../../../_fonts';
import Images from '../../../../_images';
import { Theme, useThemeStyle, WH, WW } from '../../../../_utils';
import { flattenIniniteResult } from '../../../../_utils/misc';
import { CacheImage, CheckBox, EmptyMessage, HeaderWithNav, ListItem, PrimaryButton } from '../../../../Components';

const ActivityGroups = ({ goBack, goToPreview }: { goBack(): void; goToPreview(): void }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { data, isFetchingNextPage, hasNextPage, fetchNextPage } = useGetGroups();
    const { data: ownProfile } = useOwnProfile();

    useEffect(() => {
        if (!isFetchingNextPage && hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

    const groups = useMemo(
        () =>
            flattenIniniteResult(data).filter(
                (item) => item.users.find((member) => member.id === ownProfile?.id) !== undefined,
            ),
        [data, ownProfile?.id],
    );

    return (
        <>
            <HeaderWithNav title={t('newActivity:groups')} onPress={goBack} />
            <PageContainer>
                <Text style={themedStyle.title}>{t('newActivity:add_group')}</Text>
                <Text style={themedStyle.subtitle}>{t('newActivity:add_group_explainer')}</Text>
                <ItemSelector items={groups} />
            </PageContainer>
            <PrimaryButton text={t('newActivity:continue').toUpperCase()} onPress={goToPreview} bottomAction="modal" />
        </>
    );
};

interface ItemSelectorProps {
    items: Group[];
}

const ItemSelector = ({ items }: ItemSelectorProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { field } = useController({ name: 'user_groups' });

    const toggleInterest = useCallback(
        (itemId: number) => {
            if (field.value.includes(itemId)) {
                field.onChange(field.value.filter((i: number) => i !== itemId));
            } else {
                field.onChange([...field.value, itemId]);
            }
        },
        [field],
    );

    const renderItem = useCallback(
        (item: Group) => {
            const onPress = () => toggleInterest(item.id);
            const membersLength = item.users.length;
            const image = item.pictures.length ? item.pictures[0] : undefined;
            const profileImage = image ?? Images.boardSection;
            return (
                <ListItem
                    subtitle={
                        membersLength === 1
                            ? t('group:members:singular')
                            : `${membersLength} ${t('group:members:plural')}`
                    }
                    avatar={<CacheImage source={profileImage} style={themedStyle.avatar} />}
                    actionButton={<CheckBox onPress={onPress} checked={field.value.includes(item.id)} />}
                    title={item.name}
                    onPress={onPress}
                    key={item.id}
                />
            );
        },
        [field.value, t, themedStyle.avatar, toggleInterest],
    );
    if (items.length === 0) {
        return (
            <View style={themedStyle.subtitle}>
                <EmptyMessage message={t('newActivity:no_groups')} />
            </View>
        );
    }

    return <>{items.map(renderItem)}</>;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        title: {
            marginTop: WW * 0.04,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WH * 0.035,
            color: theme.black,
        },
        subtitle: {
            marginTop: WW * 0.04,
            fontSize: WH * 0.02,
            color: theme.secondaryText,
        },
        avatar: {
            width: WW * 0.18,
            height: WW * 0.18,
            borderRadius: (WW * 0.18) / 2,
        },
    });

export default ActivityGroups;
