import { UnixTimeCode } from 'types/Utility';
import { Reservation } from '../types/Reservation';

const isBookingInSlot =
    (startSlot: UnixTimeCode, endSlot: UnixTimeCode) =>
    ({ start_at, end_at }: Pick<Reservation, 'start_at' | 'end_at'>): boolean => {
        // Starts before slots and ends after
        if (start_at <= startSlot && end_at >= endSlot) {
            return true;
        }
        // starts in slot
        if (start_at >= startSlot && start_at <= endSlot) {
            return true;
        }
        // Ends in slot
        if (end_at >= startSlot && end_at <= endSlot) {
            return true;
        }
        return false;
    };

export default isBookingInSlot;
