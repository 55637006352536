import React, { ComponentProps, ReactElement } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';
import ControlledInput from './ControlledInput';
import ErrorMessage from './ErrorMessage';
import useTranslatedFormErrorMessage from '../_utils/hooks/useTranslatedFormErrorMessage';

interface FormInputProps<FormType> extends Omit<ComponentProps<typeof ControlledInput>, 'value' | 'onChange'> {
    name: Path<FormType>;
    numberAsString?: boolean;
}

const FormInput = <FormType extends FieldValues = Record<string, string>>({
    name,
    numberAsString = false,
    ...textProps
}: FormInputProps<FormType>): ReactElement => {
    const { field, fieldState } = useController<FormType>({ name });
    const { error } = fieldState;
    const errorString = useTranslatedFormErrorMessage(error);

    const handleChange = (value: string) => {
        if (textProps.type === 'number' && !numberAsString) {
            const parsed = parseInt(value, 10);
            if (value && !isNaN(parsed)) {
                field.onChange(parsed);
            } else {
                field.onChange('');
            }
        } else {
            field.onChange(value, { shouldValidate: true });
        }
    };

    return (
        <>
            <ControlledInput
                {...textProps}
                {...field}
                value={field.value !== null && field.value !== undefined ? field.value + '' : ''}
                onChange={handleChange}
            />
            {errorString ? <ErrorMessage>{errorString}</ErrorMessage> : null}
        </>
    );
};

export default FormInput;
