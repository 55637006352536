import { ReactElement, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { StyleSheet } from 'react-native';
import { useGetActivities } from '_api/useActivities';
import { usePrefetchCategories } from '_api/useCategories';
import { MainHeader } from '_navigator/components';
import { WH, useThemeStyle } from '_utils';
import { flattenIniniteResult } from '_utils/misc';
import { useSelectedCoop } from 'SelectedCoop';
import { useLargeButtons, useSmallButtons } from './buttons';
import { SmallButtonNavigation, LargeButtonNavigation, ActivitiesPreview, ColoredBackground } from './components';

const Home = (): ReactElement => {
    const selectedCoopId = useSelectedCoop();
    const largeButtons = useLargeButtons(selectedCoopId);
    const smallButtons = useSmallButtons();
    const themedStyle = useThemeStyle(styles);

    const queryClient = useQueryClient();
    const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage, isFetching } = useGetActivities(
        moment().startOf('day'),
        undefined,
    );

    useEffect(() => {
        if (!isFetchingNextPage && hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

    usePrefetchCategories(selectedCoopId);

    const filteredActivities = useMemo(
        () => flattenIniniteResult(data).filter(({ end_at }) => moment.unix(end_at).isAfter(moment())),
        [data],
    );

    const handleRefetch = () => queryClient.invalidateQueries();

    return (
        <ColoredBackground onRefresh={handleRefetch} isRefreshing={isFetching} style={themedStyle.container}>
            <MainHeader />
            <SmallButtonNavigation buttons={smallButtons} />
            <LargeButtonNavigation cards={largeButtons} />
            <ActivitiesPreview
                filteredActivities={filteredActivities}
                isFetchingNextPage={isFetchingNextPage}
                isLoading={isLoading}
            />
        </ColoredBackground>
    );
};

export default Home;

const styles = () =>
    StyleSheet.create({
        container: { minHeight: WH },
    });
