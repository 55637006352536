import { useCallback } from 'react';
import { useImageSelectorType } from './useImageSelectorExport';
import DocumentPicker from '../../_dependencies/documentPicker.web';
import { readFileAndConvertToBase64 } from '../../_dependencies/fileSystem.web';
import { FileToBeUploaded } from '../../types/Utility';

const useImageSelector: useImageSelectorType = (
    onAddImages: (images: FileToBeUploaded[]) => void,
    onClose: () => void,
) => {
    const onSelect = useCallback(() => {
        (async () => {
            const file = await DocumentPicker.pickSingle({ type: ['.png,.jpg,.jpeg,.webp,.gif,.ico'] });

            const base64 = await readFileAndConvertToBase64(file);
            onAddImages([
                {
                    uri: base64,
                    file: file as unknown as File,
                    name: file.name ?? 'file' + Math.round(Math.random() * 1000000),
                    index: 0,
                    size: file.size ?? undefined,
                    status: 'added',
                },
            ]);

            onClose();
        })();
    }, [onAddImages, onClose]);

    return [onSelect, null];
};

export default useImageSelector;
