import Clipboard from '@react-native-clipboard/clipboard';
import { TouchableWithoutFeedback, View, StyleSheet } from 'react-native';
import { Theme, showToast, useThemeStyle } from '_utils';
import { titleFontSize, subtitleFontSize, smallestMargin } from '_utils/sizes';
import HeimeText from './HeimeText';
import Icon from './Icon/Icon';

const PromoDisplay = ({
    pin,
    copiedString,
    description,
}: {
    pin: string;
    copiedString: string;
    description: string;
}) => {
    const themedStyle = useThemeStyle(styles);

    const handleCopyPress = () => {
        Clipboard.setString(pin ?? '');
        showToast({
            header: copiedString,
            text: '',
            type: 'success',
            position: 'bottom',
            shortTime: true,
        });
    };

    return (
        <View style={themedStyle.promoContainer}>
            <HeimeText style={themedStyle.descriptor}>{description}</HeimeText>
            <TouchableWithoutFeedback onPress={handleCopyPress}>
                <View style={themedStyle.promoCode}>
                    <HeimeText style={themedStyle.promoCodeText}>{pin}</HeimeText>
                    <Icon name="copySheets" color="lightBackground" />
                </View>
            </TouchableWithoutFeedback>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        promoContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            borderColor: theme.main,
            backgroundColor: theme.mainBackground,

            borderWidth: 1,
        },
        descriptor: {
            fontSize: subtitleFontSize,
            color: theme.main,
            paddingHorizontal: smallestMargin,
        },
        promoCode: {
            padding: smallestMargin,
            backgroundColor: theme.main,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
        },
        promoCodeText: {
            fontSize: titleFontSize,
            color: theme.white,
            fontWeight: 'bold',
            marginRight: smallestMargin,
        },
    });

export default PromoDisplay;
