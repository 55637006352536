import React, { ReactElement } from 'react';
import { StyleSheet } from 'react-native';
import { useAppNavigation } from '_navigator';
import { ScreenName } from '_navigator/navigationConfiguration/types';
import { Theme, useThemeStyle, WW } from '_utils';
import { screenMargin, smallestMargin, smallestMarginPixels } from '_utils/sizes';
import { MainActionContainer, CardButton } from 'Components';
import { Card } from '../types';

interface LargeButtonNavigationProps<key extends ScreenName> {
    cards: Card<key>[];
}

const LargeButtonNavigation = <key extends ScreenName>({ cards }: LargeButtonNavigationProps<key>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const navigation = useAppNavigation();

    return (
        <MainActionContainer style={themedStyle.cardsWrapper}>
            {cards.map((card) => {
                if ('svg' in card) {
                    return (
                        <CardButton
                            key={card.title}
                            style={themedStyle.card}
                            svg={card.svg}
                            label={card.title}
                            onPress={() => navigation.navigate(card.screen, card.params)}
                            unreadCount={card.unreadCount}
                        />
                    );
                }
                return (
                    <CardButton
                        key={card.title}
                        style={themedStyle.card}
                        icon={card.icon}
                        label={card.title}
                        onPress={() => navigation.navigate(card.screen, card.params)}
                        unreadCount={card.unreadCount}
                    />
                );
            })}
        </MainActionContainer>
    );
};

const height = (WW - screenMargin * 2 - smallestMarginPixels) / 2;

const styles = (theme: Theme) =>
    StyleSheet.create({
        cardsWrapper: {
            flex: 1,
            backgroundColor: theme.shadow,
            marginHorizontal: screenMargin,
            marginBottom: smallestMarginPixels * 2,
            gap: smallestMargin,
        },
        card: {
            flexGrow: 0.5,
            height: height,
        },
    });

export default LargeButtonNavigation;
