import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, StyleSheet, View, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useAppNavigation from '_navigator/hooks/useAppNavigation';
import { Theme, useThemeStyle, WW } from '../../_utils';
import BackArrow from '../BackArrow';

interface HeaderWithNavProps {
    title: string;
    titleLeft?: boolean;
    onPress?: () => void;
    style?: StyleProp<ViewStyle>;
    action?: ReactElement;
    safeArea?: boolean;
}

const ActionView = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return <View style={themedStyle.action} />;
};

const HeaderWithNav = ({
    title,
    titleLeft,
    onPress,
    style,
    action = <ActionView />,
    safeArea = false,
}: HeaderWithNavProps): ReactElement => {
    const { t } = useTranslation();
    const themedStyle = useThemeStyle(styles);
    const { goBack } = useAppNavigation();
    const insets = useSafeAreaInsets();

    const handleOnPress = onPress ?? (() => goBack(undefined));

    return (
        <View style={[themedStyle.row, style, safeArea ? { paddingTop: insets.top } : undefined]}>
            <TouchableOpacity
                hitSlop={{ left: 20, right: 20, bottom: 20, top: 20 }}
                onPress={handleOnPress}
                style={themedStyle.button}
                accessibilityLabel={t('navigation:go_back')}
            >
                <BackArrow />
                {titleLeft ? (
                    <Text role="heading" style={themedStyle.titleLeft}>
                        {title}
                    </Text>
                ) : null}
            </TouchableOpacity>
            {titleLeft ? null : (
                <Text role="heading" style={[themedStyle.titleCenter, action ? null : themedStyle.titleNoAction]}>
                    {title}
                </Text>
            )}
            {action}
        </View>
    );
};

const HEADER_MIN_HEIGHT = 50;

const styles = (theme: Theme) =>
    StyleSheet.create({
        row: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexShrink: 0,
            minHeight: HEADER_MIN_HEIGHT,
        },
        titleCenter: {
            color: theme.main,
            fontSize: WW * 0.04,
            fontWeight: 'bold',
            textAlign: 'center',
            flex: 1,
        },
        titleNoAction: {
            // Left icon and
            paddingTop: WW * 0.012,
            paddingBottom: WW * 0.012,
        },

        titleLeft: {
            color: theme.main,
            fontSize: WW * 0.04,
            fontWeight: 'bold',
            // Left icon and
            paddingLeft: WW * 0.05,
            paddingTop: WW * 0.012,
            paddingBottom: WW * 0.012,
        },
        button: {
            marginLeft: WW * 0.05,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%',
            minHeight: HEADER_MIN_HEIGHT,
        },
        arrow: {
            width: WW * 0.08,
            height: WW * 0.08,
            tintColor: theme.main,
        },
        action: { width: WW * 0.08 + WW * 0.05 },
    });

export default HeaderWithNav;
