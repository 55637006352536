import React, { ReactElement } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Edge, useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import { useCreateRegistration, useUpdateRegistration } from '_api/useRegistrations';
import { SafeAreaView } from '_dependencies/safeArea';
import { setRegistration } from '_redux/pendingRegistration';
import { Theme, isAndroid, showToast, useThemeStyle } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import {
    PrimaryButton,
    BackArrow,
    LanguageSelector,
    HeimeText,
    FormInput,
    Footer,
    ErrorMessage,
    PhoneNumberInput,
} from 'Components';
import MenuProvider from 'Components/MenuProvider';
import Title from './Title';
import { useAuthNavigation, useAuthRoute } from '../AuthNavigation';

const schema = z.object({
    fname: z.string().nonempty('onboarding:firstName_error').max(255),
    lname: z.string().nonempty('onboarding:lastName_error').max(255),
    email: z.union([z.string().email('onboarding:invalidEmail'), z.string().length(0)]).nullable(),
    phone: z.string().nonempty('onboarding:phone_error').max(24),
    cooperative_guess: z.string().nonempty('createRegistration:project_error').max(1023),
    apartment_guess: z.string().nonempty('createRegistration:apartment_error').max(1023),
});

export type FormValues = z.infer<typeof schema>;

const CreateRegistration = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const navigation = useAuthNavigation();
    const { params } = useAuthRoute<'CreateRegistration' | 'RegistrationEdit'>();
    const dispatch = useDispatch();
    const isEditing = 'registrationId' in params;

    const formValues = useForm<FormValues>({
        mode: 'onChange',
        criteriaMode: 'all',
        defaultValues: isEditing
            ? params.data
            : {
                  fname: '',
                  lname: '',
                  email: '',
                  apartment_guess: '',
                  cooperative_guess: '',
                  phone: params.phone,
              },
        resolver: zodResolver(schema),
    });

    const handleGoBack = () => {
        navigation.pop();
    };

    const { mutate: create, isPending: isCreating } = useCreateRegistration();
    const { mutate: update, isPending: isUpdating } = useUpdateRegistration(
        isEditing ? params.registrationId : 'justCreating',
    );

    const handleSubmit = (values: FormValues) => {
        const data = {
            ...values,
            email: values.email ?? '',
            phone: !isEditing ? params.phone : (values.phone ?? ''),
        };
        const onSuccess = () => navigation.replace('RegistrationSelected');
        if (isEditing) {
            update(data, {
                onSuccess,
                onError: () => {
                    showToast({
                        header: t('createRegistration:updateError'),
                        text: t('createRegistration:updateErrorText'),
                        type: 'error',
                    });
                },
            });
        } else {
            create(data, {
                onSuccess: (arg) => {
                    dispatch(setRegistration(arg));
                    setTimeout(() => onSuccess(), 300);
                },
                onError: () => {
                    showToast({
                        header: t('createRegistration:createError'),
                        text: t('createRegistration:createErrorText'),
                        type: 'error',
                    });
                },
            });
        }
    };

    const errorMessage = Object.values(formValues.formState.errors).reduce(
        (curr, val) => (curr ? curr : (val.message ?? '')),
        '',
    );

    const insets = useSafeAreaInsets();

    return (
        <SafeAreaView
            style={themedStyle.container}
            edges={['bottom', 'left', 'right', isEditing ? undefined : 'top'].filter(Boolean) as Edge[]}
        >
            <GestureHandlerRootView style={themedStyle.container}>
                <MenuProvider>
                    <KeyboardAvoidingView
                        style={themedStyle.flexGrow}
                        behavior="padding"
                        keyboardVerticalOffset={insets.bottom}
                        enabled={!isAndroid()}
                    >
                        <View style={themedStyle.navWrapper}>
                            <TouchableOpacity onPress={handleGoBack} style={themedStyle.navigationContainer}>
                                <BackArrow />
                                <HeimeText style={themedStyle.backText}>{t('enterPin:back')}</HeimeText>
                            </TouchableOpacity>
                            <LanguageSelector />
                        </View>
                        <ScrollView
                            bounces={false}
                            style={themedStyle.flex}
                            contentContainerStyle={themedStyle.containerStyle}
                            keyboardShouldPersistTaps="handled"
                        >
                            <Title style={themedStyle.title} text={t('createRegistration:title')} />
                            <HeimeText style={themedStyle.subTitle}>{t('createRegistration:subtitle')}</HeimeText>
                            <FormProvider {...formValues}>
                                <View>
                                    <View style={themedStyle.flexRow}>
                                        <View style={themedStyle.halfSize}>
                                            <FormInput
                                                name="fname"
                                                autoComplete="given-name"
                                                label={t('createRegistration:firstNameLabel')}
                                            />
                                        </View>
                                        <View style={themedStyle.halfSize}>
                                            <FormInput
                                                name="lname"
                                                autoComplete="family-name"
                                                label={t('createRegistration:lastNameLabel')}
                                            />
                                        </View>
                                    </View>
                                    {isEditing ? (
                                        <Controller
                                            name="phone"
                                            render={({ field }) => (
                                                <PhoneNumberInput
                                                    label={t('createRegistration:phoneLabel')}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    ) : null}
                                    <FormInput
                                        name="email"
                                        keyboardType="email-address"
                                        autoComplete="email"
                                        optional
                                        optionalText=""
                                        label={t('createRegistration:emailLabel')}
                                    />
                                    <FormInput
                                        name="cooperative_guess"
                                        multiline
                                        numberOfLines={2}
                                        autoComplete="street-address"
                                        placeholder={t('createRegistration:projectPlaceholder')}
                                        label={t('createRegistration:projectLabel')}
                                    />
                                    <FormInput
                                        name="apartment_guess"
                                        placeholder={t('createRegistration:apartmentPlaceholder')}
                                        label={t('createRegistration:apartmentLabel')}
                                    />
                                </View>
                                <View style={themedStyle.grower} />
                                {errorMessage ? (
                                    <ErrorMessage>{t(errorMessage as 'global:noLang')}</ErrorMessage>
                                ) : null}
                            </FormProvider>
                        </ScrollView>
                        <PrimaryButton
                            status={
                                !formValues.formState.isValid || errorMessage
                                    ? 'disabled'
                                    : isCreating || isUpdating
                                      ? 'loading'
                                      : null
                            }
                            style={themedStyle.button}
                            onPress={formValues.handleSubmit(handleSubmit)}
                            text={t('createRegistration:send')}
                            bottomAction
                        />
                    </KeyboardAvoidingView>
                    <Footer />
                </MenuProvider>
            </GestureHandlerRootView>
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            height: '100%',
            backgroundColor: theme.mainBackground,
        },
        flexGrow: { flexGrow: 1 },
        navWrapper: { flexDirection: 'row', alignItems: 'center', paddingHorizontal: screenMargin },
        navigationContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        containerStyle: {
            paddingHorizontal: screenMargin,
            flexGrow: 1,
        },
        backText: {
            fontSize: 16,
            letterSpacing: 1,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: theme.main,
        },
        title: {
            paddingTop: smallestMargin,
            paddingBottom: smallestMargin,
            textAlign: 'center',
        },
        subTitle: {
            fontSize: subtitleFontSize,
            color: theme.black,
        },
        flexRow: {
            flexDirection: 'row',
            gap: smallestMargin,
            width: '100%',
        },
        halfSize: {
            flexBasis: '40%',
            flexGrow: 1,
        },
        flex: {
            flex: 1,
        },
        grower: { flexGrow: 1, minHeight: screenMargin },
        button: { marginBottom: smallestMargin },
    });

export default CreateRegistration;
