import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { openURL } from '../../../../_utils';
import Modal from '../../../Modal/Modal';

interface MessageAlertModal {
    onDismiss(): void;
    link: string;
}

const MessageAlertModal = ({ onDismiss, link }: MessageAlertModal): ReactElement => {
    const { t } = useTranslation();
    const handleConfirm = () => {
        openURL(link, t);
        onDismiss();
    };

    return (
        <Modal
            onDismiss={onDismiss}
            header={t('chat:messageModal:header')}
            content={<View />}
            buttons={[
                { text: t('chat:messageModal:back'), onPress: onDismiss },
                {
                    text: t('chat:messageModal:approve'),
                    onPress: handleConfirm,
                    type: 'danger',
                },
            ]}
        />
    );
};

export default MessageAlertModal;
