import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useGetDocuments } from '_api/useCoops';
import { useContextMenuFocusEffect } from '_navigator';
import { useThemeStyle, WW } from '_utils';
import { useBottomSpacer } from '_utils/hooks';
import { screenMargin } from '_utils/sizes';
import { Theme } from '_utils/themeContext';
import { ExpandableSearchInput, HeaderWithNav, Loader, QueryItemView } from 'Components';
import DocumentList from 'Components/DocumentList';

const Documents = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const paddingBottom = useBottomSpacer();
    useContextMenuFocusEffect(['reportsNew']);
    const { data, isLoading, isError, error, refetch, isFetching } = useGetDocuments();

    const [searchedText, setSearchedText] = useState('');

    if (isError) {
        throw error;
    }

    return (
        <View style={themedStyle.container}>
            <HeaderWithNav title={t('documents:title')} safeArea />
            {isLoading ? (
                <Loader />
            ) : (
                <QueryItemView
                    keyboardShouldPersistTaps="handled"
                    virtualized
                    onRefresh={refetch}
                    isRefreshing={isFetching}
                    containerStyle={{ paddingBottom }}
                >
                    <View style={themedStyle.searchInput}>
                        <ExpandableSearchInput
                            onChange={setSearchedText}
                            value={searchedText}
                            buttonText={t('documents:search')}
                        />
                    </View>
                    <DocumentList data={data} searchedText={searchedText} />
                </QueryItemView>
            )}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flexGrow: 1,
            backgroundColor: theme.mainBackground,
            maxHeight: '100%',
        },
        iconStyle: {
            marginLeft: WW * 0.01,
            marginRight: WW * 0.01,
        },
        searchInput: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            marginTop: screenMargin,
        },
    });

export default Documents;
