import React, { ReactElement, StrictMode, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { DimensionValue, Platform, View } from 'react-native';
import Orientation from 'react-native-orientation-locker';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import SplashScreen from 'react-native-splash-screen';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { isWeb } from '_utils/isPlatform';
import { ToastContainer } from '_utils/toast';
import _colors from './src/_colors';
import { LanguageProvider } from './src/_localization';
import { getReduxSetup } from './src/_redux';
import { ReactQueryProvider } from './src/_utils/reactQuery';
import /* webpackPrefetch: true */ AppContainer from './src/AppContainer';
import 'moment/locale/nb';
import { Auth } from './src/Auth';
import GlobalErrorScreen from './src/Components/GlobalErrorScreen';

const App = (): ReactElement => {
    const reduxSetup = useMemo(() => getReduxSetup(), []);

    useEffect(() => {
        if (Platform.OS !== 'web') {
            SplashScreen.hide();
            Orientation.lockToPortrait();
        }
    }, []);

    const content = (
        <View style={styles.container}>
            <SafeAreaProvider style={styles.safeArea}>
                <ErrorBoundary FallbackComponent={GlobalErrorScreen}>
                    <Provider store={reduxSetup.store}>
                        <PersistGate persistor={reduxSetup.persistor} loading={null}>
                            <LanguageProvider>
                                <ReactQueryProvider>
                                    <Auth>
                                        <AppContainer />
                                    </Auth>
                                </ReactQueryProvider>
                            </LanguageProvider>
                        </PersistGate>
                    </Provider>
                </ErrorBoundary>
                <ToastContainer />
            </SafeAreaProvider>
        </View>
    );

    // React navigation is double animating in strict mode on web
    return !isWeb() ? <StrictMode>{content}</StrictMode> : content;
};
const styles = Platform.select({
    default: {
        safeArea: { backgroundColor: _colors.lightBackground },
        container: {
            height: '100%' as const,
            width: '100%' as const,
        },
    },
    web: {
        safeArea: {
            height: '100vh' as DimensionValue,
            width: '100vw' as DimensionValue,
            maxWidth: '50rem' as DimensionValue,
            maxHeight: '70rem' as DimensionValue,
            shadowColor: _colors.black,
            shadowRadius: 100,
            flex: undefined,
            position: 'relative' as const,
            backgroundColor: _colors.white,
            overflow: 'hidden' as const,
        },
        container: {
            height: '100vh' as DimensionValue,
            width: '100vw' as DimensionValue,
            display: 'flex' as const,
            alignItems: 'center' as const,
            justifyContent: 'center' as const,
            backgroundColor: _colors.accent,
        },
    },
});

export default App;
