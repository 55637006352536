import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSettings } from '_api';
import { Theme, openURL, useThemeStyle } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { HeimeText, Modal } from 'Components';

const ContactModal = ({ onDismiss }: { onDismiss(): void }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { data } = useSettings();

    const call = () => openURL(`tel:${data?.phone}`, t);
    const mail = () => openURL(`mailto:${data?.mail}`, t);
    const website = () => openURL('https://www.heime.com', t);

    return (
        <Modal
            onDismiss={onDismiss}
            header={t('confirmUser:modalTitle')}
            content={
                <>
                    <HeimeText style={themedStyle.modalText}>{t('confirmUser:modalText')}</HeimeText>
                    <View style={themedStyle.supportInfoContainer}>
                        <TouchableOpacity onPress={call}>
                            <HeimeText style={themedStyle.clickableText} selectable>
                                {data?.phone}
                            </HeimeText>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={mail}>
                            <HeimeText style={themedStyle.clickableText} selectable>
                                {data?.mail}
                            </HeimeText>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={website}>
                            <HeimeText style={themedStyle.clickableText} selectable>
                                www.Heime.com
                            </HeimeText>
                        </TouchableOpacity>
                    </View>
                </>
            }
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        modalText: {
            fontSize: subtitleFontSize,
        },
        supportInfoContainer: {
            alignItems: 'center',
            paddingTop: screenMargin,
            paddingBottom: screenMargin,
        },
        clickableText: {
            fontSize: subtitleFontSize,
            marginBottom: smallestMargin,
            color: theme.hyperText,
            fontWeight: 'bold',
        },
    });

export default ContactModal;
