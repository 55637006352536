import React, { ReactElement } from 'react';
import { TouchableOpacity, StyleSheet, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { WW, WH, useThemeStyle, Theme } from '_utils';
import { ApiImage } from 'types/Base';
import { CacheImage, HeimeText, UnreadBadge } from '.';

interface CardButtonPropsBase {
    style?: StyleProp<ViewStyle>;
    labelStyles?: StyleProp<TextStyle>;
    unreadCount?: number;
    onPress(): void;
    label: string;
}

interface CardButtonPropsImage extends CardButtonPropsBase {
    icon?: ApiImage | number;
}

interface CardButtonPropsSvg extends CardButtonPropsBase {
    svg?: React.FC<SvgProps>;
}

type CardButtonProps = CardButtonPropsImage | CardButtonPropsSvg;

const CardButton = (props: CardButtonProps): ReactElement => {
    const { style, labelStyles, onPress, label } = props;

    let Svg: React.FC<SvgProps> | undefined;

    if ('svg' in props) {
        Svg = props.svg;
    }

    const themedStyle = useThemeStyle(styles);
    return (
        <TouchableOpacity style={[themedStyle.main, style]} onPress={onPress}>
            {'icon' in props && props.icon ? (
                <CacheImage resizeMode={'contain'} source={props.icon} style={themedStyle.icon} />
            ) : Svg ? (
                <Svg style={themedStyle.icon} />
            ) : null}
            <HeimeText style={[themedStyle.label, labelStyles]}>{label}</HeimeText>
            {props.unreadCount ? <UnreadBadge count={props.unreadCount} variant="large" /> : undefined}
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            minHeight: WH * 0.02,
            width: WW * 0.4,
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 2,
            borderColor: theme.secondaryLight,
            backgroundColor: theme.mainBackground,
            shadowColor: theme.shadow,
            borderRadius: 20,
        },
        icon: {
            width: WW * 0.4 * 0.6,
            height: WW * 0.4 * 0.6,
            marginTop: WH * 0.02,
            marginBottom: WH * 0.01,
        },
        label: {
            color: theme.main,
            fontWeight: 'bold',
            paddingLeft: 5,
            paddingRight: 5,
            textAlign: 'center',
            marginBottom: WH * 0.02,
            fontSize: 14,
        },
    });

export default CardButton;
