import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack';
import { z } from 'zod';
import { RoutePropsSchema } from 'types/Utility';

export const EnterPhonePropsSchema = RoutePropsSchema(
    z.object({
        apartmentId: z.number(),
    }),
);

export const TenantRoleSchema = z.union([
    z.literal('owner'),
    z.literal('livesWith'),
    z.literal('renting'),
    z.literal('other'),
]);

const UserSchema = z.object({
    id: z.number(),
    fname: z.string(),
    lname: z.string(),
    email: z.string().nullable(),
    has_email: z.boolean(),
    phone: z.string(),
    tenant_role: TenantRoleSchema,
    status: z.union([z.literal('active'), z.literal('notLoggedIn')]),
    avatar: z.any(),
});

export const EditDetailsPropsSchema = RoutePropsSchema(
    z.union([
        z.object({
            phone: z.string().nonempty(),
            type: z.literal('createUser'),
            apartmentId: z.number(),
        }),
        z.object({
            type: z.literal('editUser'),
            apartmentId: z.number(),
            user: UserSchema,
        }),
        z.object({
            type: z.literal('addUser'),
            apartmentId: z.number(),
            user: UserSchema,
        }),
    ]),
);

export type TenantEditStackParams = {
    EnterPhone: z.infer<typeof EnterPhonePropsSchema>['route']['params'];
    EditDetails: z.infer<typeof EditDetailsPropsSchema>['route']['params'];
};

export type TenantEditScreenProps<ScreenName extends keyof TenantEditStackParams> = StackScreenProps<
    TenantEditStackParams,
    ScreenName
>;

type TenantEditNavigationProp = StackNavigationProp<TenantEditStackParams>;

export const useTenantEdit = (): TenantEditNavigationProp => useNavigation<TenantEditNavigationProp>();
