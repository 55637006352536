import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../_utils/misc';
import { UnixTimeCode } from '../../types/Utility';

const useUpdatedFormatedTimeAgo = (timeDate: UnixTimeCode): string => {
    const { t } = useTranslation();
    const [, setRerenderNum] = useState(1);
    const momentDiff = moment.duration(moment().diff(moment.unix(timeDate), 'milliseconds'));

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout> | null = null;
        if (momentDiff.days() > 0) {
            return () => {};
        }
        if (momentDiff.hours() !== 0) {
            const msTilIncreaseHour =
                (60 - momentDiff.minutes()) * 1000 * 60 +
                (60 - momentDiff.seconds()) * 1000 * 60 +
                1000 -
                momentDiff.milliseconds();
            timeout = setTimeout(() => {
                setRerenderNum((curr) => curr + 1);
            }, msTilIncreaseHour);
        } else if (momentDiff.minutes() !== 0) {
            const msTilIncreaseMinute =
                (60 - momentDiff.minutes()) * 1000 * 60 +
                (60 - momentDiff.seconds()) * 1000 * 60 +
                1000 -
                momentDiff.milliseconds();
            timeout = setTimeout(() => {
                setRerenderNum((curr) => curr + 1);
            }, msTilIncreaseMinute);
        } else if (momentDiff.seconds() !== 0) {
            const msTilIncreaseSecond = 1000 - momentDiff.milliseconds();
            timeout = setTimeout(() => {
                setRerenderNum((curr) => curr + 1);
            }, msTilIncreaseSecond);
        } else {
            timeout = setTimeout(() => {
                setRerenderNum((curr) => curr + 1);
            });
        }

        return () => {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
        };
    }, [momentDiff]);

    return `${capitalizeFirstLetter(momentDiff.humanize())} ${t('notifications:ago')}`;
};

export default useUpdatedFormatedTimeAgo;
