import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { showToast } from '_utils';
import { useSelectedCoopItem } from '_utils/hooks';
import { useOwnProfile, useUpdateProfile } from '../../../../_api/useProfile';
import { screenMargin } from '../../../../_utils/sizes';
import { IconButton, Modal, RadioButton } from '../../../../Components';

const SettingsModal = (): ReactElement => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';
    const { data: ownProfile } = useOwnProfile();
    const [settings, setSettings] = useState(
        ownProfile?.stop_board_messages_notification
            ? 'none'
            : ownProfile?.stop_neighbor_messages_notification
              ? 'board'
              : 'all',
    );

    const { mutate, isPending: isLoading } = useUpdateProfile();

    const handleSave = () => {
        mutate(
            {
                stop_board_messages_notification: settings === 'none',
                stop_neighbor_messages_notification: settings !== 'all',
            },
            {
                onError: () =>
                    showToast({
                        header: t('sharingAll:errorSaving_header'),
                        text: t('sharingAll:errorSaving_description'),
                        type: 'error',
                    }),
                onSuccess: () => setIsOpen(false),
            },
        );
    };

    return (
        <>
            <View style={{ width: screenMargin }} />
            <IconButton
                text={t('sharingAll:userSettingsShort')}
                accessibilityHint={t('sharingAll:open_settings')}
                icon="settings"
                onPress={() => setIsOpen(true)}
            />
            {isOpen ? (
                <Modal
                    onDismiss={() => setIsOpen(false)}
                    header={t('sharingAll:userSettings')}
                    content={
                        <>
                            <RadioButton
                                style={styles.radioButton}
                                checked={settings === 'all'}
                                onPress={() => setSettings('all')}
                                title={t('sharingAll:settingsAllNotifications')}
                            />
                            <RadioButton
                                style={styles.radioButton}
                                checked={settings === 'board'}
                                onPress={() => setSettings('board')}
                                title={t('sharingAll:settingsOnlyBoard', {
                                    boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                                })}
                            />
                            <RadioButton
                                style={styles.radioButton}
                                checked={settings === 'none'}
                                onPress={() => setSettings('none')}
                                title={t('sharingAll:settingsNoneNotifications')}
                            />
                        </>
                    }
                    buttons={[{ text: t('sharingAll:save'), onPress: handleSave, isLoading }]}
                />
            ) : null}
        </>
    );
};

const styles = StyleSheet.create({ spacer: { width: screenMargin }, radioButton: { marginBottom: 20 } });

export default SettingsModal;
