import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, TouchableOpacity, Text, AppState, RefreshControl } from 'react-native';
import { useGetChat, useGetChats, useGetChatMessages, useChatImages } from '_api/useChats';
import { useOwnProfile } from '_api/useProfile';
import { useGetUsers } from '_api/useUsers';
import { useAppNavigation } from '_navigator';
import { isAndroid, isAppError, Theme, useThemeStyle, useVisualRefetchState, WW } from '_utils';
import getChatName from '_utils/getChatName';
import { flattenIniniteResult } from '_utils/misc';
import { addBreadcrumb, captureException } from '_utils/Sentry';
import { screenMargin, smallestFontSize, subtitleFontSize } from '_utils/sizes';
import { SOCKET } from '_utils/socket';
import { BackArrow, HeimeText, Loader, Modal, OpinionatedSafeArea } from 'Components';
import NotFoundErrorScreen from 'Components/NotFoundErrorScreen';
import { ChatMessage, MessageFile } from 'types/Chat';
import { ActionImage, ChatMessageInput, GoToGroup, MessageItem } from './components';
import useChatRead from './useReadChat';
import _fonts from '../../_fonts';

interface ChatSelectedScreenProps {
    route: { params: { chatId: string } };
}

const ChatLoadedGate = ({ route }: ChatSelectedScreenProps): ReactElement | null => {
    const chatId = parseInt(route?.params?.chatId, 10);
    if (!chatId) {
        throw new Error('Received no chatId in route params');
    }

    const themedStyle = useThemeStyle(styles);
    const { navigate, goBack } = useAppNavigation();
    const { data, isLoading, isError, error, refetch: refetchChatInfo } = useGetChat(chatId);
    const { refetch: refetchChats } = useGetChats();
    const { isLoading: isLoadingMessages, refetch: refetchMessages } = useGetChatMessages(chatId);
    const { data: profile } = useOwnProfile();
    const { t } = useTranslation();

    const handleGoToInfo = () => {
        navigate('ChatInfo', { chatId });
    };
    const chatTitle = useMemo(() => {
        if (!data) {
            return '';
        }
        return getChatName(data, profile?.id ?? 0, t);
    }, [data, profile?.id, t]);

    const handleRefetch = useCallback(async () => {
        await Promise.all([refetchChats(), refetchChatInfo(), refetchMessages()]);
    }, [refetchChats, refetchChatInfo, refetchMessages]);

    if (isError) {
        if (isAppError(error) && error.response?.status === 404) {
            return <NotFoundErrorScreen type="Chat" />;
        } else {
            throw error;
        }
    }

    if (data) {
        return (
            <OpinionatedSafeArea style={themedStyle.main}>
                <TouchableOpacity onPress={handleGoToInfo} accessible={false} style={themedStyle.row}>
                    <TouchableOpacity
                        accessibilityLabel={t('global:goBack')}
                        onPress={() => goBack()}
                        style={themedStyle.button}
                    >
                        <BackArrow />
                    </TouchableOpacity>
                    <View style={themedStyle.titleContainer}>
                        <HeimeText numberOfLines={3} style={themedStyle.titleCenter}>
                            {chatTitle}
                        </HeimeText>
                        <HeimeText style={themedStyle.titleSubtext}>{t('chat:pressInfo')}</HeimeText>
                    </View>
                    <ActionImage userId={profile?.id ?? 0} chatInfo={data} />
                </TouchableOpacity>
                {data.user_group ? <GoToGroup groupId={data.user_group.id} /> : undefined}
                {isLoading || isLoadingMessages ? (
                    <Loader />
                ) : (
                    <ChatSelectedScreen chatId={chatId} refetch={handleRefetch} />
                )}
            </OpinionatedSafeArea>
        );
    }

    if (isLoading) {
        return <Loader />;
    }

    return null;
};

const ChatSelectedScreen = ({ refetch, chatId }: { refetch(): Promise<void>; chatId: number }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isRefetching } = useGetChatMessages(chatId);
    const { data: chatInfo } = useGetChat(chatId);
    const { data: chats } = useGetChats();
    const {
        data: allUsers,
        fetchNextPage: fetchMoreUsers,
        isFetchingNextPage: isFetchingMoreUsers,
        hasNextPage: hasMoreUsersToFetch,
    } = useGetUsers(false, false);
    const { data: profile } = useOwnProfile();
    const { navigate } = useAppNavigation();
    const markRead = useChatRead(chatId);
    const images = useChatImages(chatId);
    const [isTooManyUsersModalOpen, setTooManyUsersModalOpen] = useState(false);
    const [editing, setEditing] = useState<(Omit<ChatMessage, 'id'> & { id: number }) | null>(null);
    const [refreshing, handleRefresh] = useVisualRefetchState(isRefetching, refetch);

    useEffect(() => {
        if (hasMoreUsersToFetch && !isFetchingMoreUsers) {
            fetchMoreUsers();
        }
    }, [hasMoreUsersToFetch, isFetchingMoreUsers, fetchMoreUsers]);

    // memo below is not recognizing change so we need to force it...
    const nMessages = data?.pages.reduce(
        (prev, curr) => prev + Object.values(curr.messages).reduce((count, dayValue) => count + dayValue.length, 0),
        0,
    );

    const [allData, users] = useMemo(() => {
        if (nMessages) {
            // Only here for forcing in dep array
        }
        const { messages, message_users } = (data?.pages ?? []).reduce(
            (curr, page) => {
                Object.entries(page.messages).forEach(([key, value]) => {
                    curr.messages = Array.isArray(curr.messages) ? {} : curr.messages;
                    if (curr.messages[key]) {
                        curr.messages[key] = [...[...value].reverse(), ...curr.messages[key]];
                    } else {
                        curr.messages[key] = [...value].reverse();
                    }
                });
                curr.message_users.push(...page.message_users);
                return curr;
            },
            { messages: {}, message_users: [] },
        );
        const userIds: number[] = [];

        return [
            Object.entries(messages)
                .map(([key, value]) => ({ title: key, data: value.reverse() }))
                .sort((a, b) => moment(b.title, 'DD.MM.YYYY').unix() - moment(a.title, 'DD.MM.YYYY').unix())
                .reduce((acc, curr) => [...acc, ...curr.data, curr.title], [] as Array<ChatMessage | string>),
            message_users.filter((item) => {
                if (userIds.includes(item.id)) {
                    return false;
                }
                userIds.push(item.id);
                return true;
            }),
        ];
    }, [data?.pages, nMessages]);

    const joinChatSocket = useCallback(() => {
        SOCKET?.join(`chat.${chatId}`)
            .here(() => {
                addBreadcrumb('socketConection', `Connnected to chatchannel: chat.${chatId}`);
            })
            .listen('.Marinar\\Chat\\Events\\ChatMessageEvent', async (message: ChatMessage) => {
                addBreadcrumb(
                    'socketMessage',
                    `Received message ".Marinar\\Chat\\Events\\ChatMessageEvent" in chatchannel: chat.${chatId}, message: ${JSON.stringify(
                        message,
                    )}`,
                );
                await refetch();
                markRead();
            })
            .error((e: unknown) => {
                if (e instanceof Error) {
                    captureException(e);
                    console.error(e);
                } else {
                    const err = new Error('Received unknown error from socket');
                    err.cause = e as Error;
                    captureException(e);
                    console.error(e);
                }
            });
        // Rejoin if socket changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatId, markRead, refetch, SOCKET]);

    const leaveChatSocket = useCallback(() => {
        SOCKET?.leave(`chat.${chatId}`);
    }, [chatId]);

    useEffect(() => {
        const listener = AppState.addEventListener('change', (status) => {
            switch (status) {
                case 'active': {
                    joinChatSocket();
                    return;
                }
                case 'background': {
                    leaveChatSocket();
                }
            }
        });
        return () => {
            listener.remove();
        };
    });

    useFocusEffect(
        useCallback(() => {
            joinChatSocket();

            return leaveChatSocket;
        }, [joinChatSocket, leaveChatSocket]),
    );

    const onEndReached = useCallback(() => {
        if (!isFetchingNextPage && hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

    useFocusEffect(
        useCallback(() => {
            const unread = flattenIniniteResult(chats).find(
                ({ id, not_read_messages_count }) => id === chatId && not_read_messages_count > 0,
            );

            if (unread) {
                markRead();
            }
        }, [chats, chatId, markRead]),
    );

    const handleChatMessageFocus = useCallback(() => {
        const allCooperativeUsers = flattenIniniteResult(allUsers).map(({ id }) => id);
        const usersInThisCoopAndChat = chatInfo?.users.filter(({ id }) => allCooperativeUsers.includes(id)) ?? [];
        const isEnoughUsers = usersInThisCoopAndChat.length > allCooperativeUsers.length * 0.8;
        const isGroupChat = chatInfo?.user_group !== null;
        if (allCooperativeUsers.length > 5 && isEnoughUsers && !isGroupChat) {
            setTooManyUsersModalOpen(true);
        }
    }, [allUsers, chatInfo?.user_group, chatInfo?.users]);

    const handleCloseTooManyUsersModal = () => setTooManyUsersModalOpen(false);
    const handleImagePress = useCallback(
        (file: MessageFile) => {
            const index = images.findIndex((img) => img.id === file.id);
            if (index !== -1) {
                navigate('ImagePreview', { images, selectedIndex: index });
            } else {
                navigate('ImagePreview', { images: [file], selectedIndex: 0 });
            }
        },
        [images, navigate],
    );

    const isOneToOne =
        new Set([...users.map(({ id }) => id), ...(chatInfo?.users.map(({ id }) => id) ?? [])]).size <= 2;

    return (
        <>
            <FlashList
                onEndReached={onEndReached}
                onEndReachedThreshold={0.1}
                contentContainerStyle={themedStyle.scrollContainer}
                data={allData}
                onRefresh={!refreshing ? handleRefresh : undefined}
                refreshing={refreshing}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
                renderItem={useCallback(
                    ({ item }: { item: ChatMessage | string }) => {
                        if (typeof item === 'string') {
                            return (
                                <View style={themedStyle.dateContainer}>
                                    <Text style={themedStyle.groupDay}>
                                        {moment().format('DD.MM.YYYY') === item ? t('chat:today') : item}
                                    </Text>
                                </View>
                            );
                        }
                        const sender = users.find((usr) => usr.id === item.user);
                        const handleChatMessageImageClick = (index: number) => {
                            handleImagePress(item.files[index]);
                        };
                        return (
                            <MessageItem
                                message={item}
                                sender={sender ?? null}
                                isSelf={item.user === profile?.id}
                                isOneToOne={isOneToOne}
                                onImageClick={handleChatMessageImageClick}
                                setEditMessage={() => {
                                    if (item.id) {
                                        setEditing(item as Omit<ChatMessage, 'id'> & { id: number });
                                    }
                                }}
                                chatId={chatId}
                            />
                        );
                    },
                    [
                        chatId,
                        handleImagePress,
                        isOneToOne,
                        profile?.id,
                        t,
                        themedStyle.dateContainer,
                        themedStyle.groupDay,
                        users,
                    ],
                )}
                inverted
                ListEmptyComponent={useMemo(
                    () => (
                        <View style={themedStyle.emptyContainer}>
                            <Text style={themedStyle.emptyText}>{t('chat:empty_message')}</Text>
                        </View>
                    ),
                    [t, themedStyle.emptyContainer, themedStyle.emptyText],
                )}
                estimatedItemSize={100}
            />
            {isTooManyUsersModalOpen ? (
                <Modal
                    onDismiss={handleCloseTooManyUsersModal}
                    header={t('chat:too_many_users_header')}
                    content={
                        <HeimeText style={themedStyle.manyUsersExplainer}>{t('chat:too_many_users_text')}</HeimeText>
                    }
                    buttons={[
                        {
                            type: 'secondary',
                            text: t('chat:too_many_users_button'),
                            onPress: handleCloseTooManyUsersModal,
                        },
                    ]}
                />
            ) : null}
            <ChatMessageInput
                chatId={chatId}
                onFocus={handleChatMessageFocus}
                editing={editing}
                onEditingEnd={() => {
                    setEditing(null);
                }}
            />
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
        scrollContainer: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            paddingBottom: screenMargin,
        },
        emptyContainer: {
            paddingTop: WW * 0.12,
            paddingBottom: WW * 0.12,
            paddingLeft: WW * 0.12,
            paddingRight: WW * 0.12,
            marginLeft: WW * 0.1,
            marginRight: WW * 0.1,
            marginTop: WW * 0.1,
            marginBottom: WW * 0.1,
            alignSelf: 'center',
            justifyContent: 'center',
            backgroundColor: theme.lightGreen,
            borderRadius: 40,
            transform: [{ scaleY: -1 }, { scaleX: isAndroid() ? -1 : 1 }],
        },
        emptyText: {
            alignSelf: 'center',
            textAlign: 'center',
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            color: theme.darkGreen,
        },
        manyUsersExplainer: {
            fontSize: subtitleFontSize,
            textAlign: 'center',
            marginTop: WW * 0.05,
            marginBottom: WW * 0.05,
        },
        row: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
        titleContainer: {
            // Left icon and
            paddingTop: WW * 0.012,
            paddingBottom: WW * 0.012,
            flex: 1,
        },
        titleCenter: {
            color: theme.main,
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            textAlign: 'center',
            paddingBottom: 0,
        },
        titleSubtext: {
            color: theme.secondaryText,
            fontSize: smallestFontSize,
            textAlign: 'center',
        },
        button: {
            paddingLeft: WW * 0.05,
            paddingRight: WW * 0.05,
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        arrow: {
            width: WW * 0.08,
            height: WW * 0.08,
            tintColor: theme.main,
        },
        dateContainer: {
            borderRadius: 50,
            backgroundColor: theme.lightGrey,
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            paddingLeft: WW * 0.01,
            paddingRight: WW * 0.01,
            marginTop: WW * 0.02,
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
        },
        groupDay: {
            textAlign: 'center',
            color: theme.secondaryText,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            fontFamily: _fonts.primaryFont,
        },
    });

export default ChatLoadedGate;
