import React, { ComponentProps, ReactElement } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';
import Toggle from './Toggle';

interface FormInputProps<FormType> {
    name: Path<FormType>;
}

const FormToggle = <FormType extends FieldValues = Record<string, string>>({
    name,
    ...rest
}: FormInputProps<FormType> & Omit<ComponentProps<typeof Toggle>, 'value' | 'onChange'>): ReactElement => {
    const { field } = useController<FormType>({ name });

    return <Toggle onChange={field.onChange} value={field.value} {...rest} />;
};

export default FormToggle;
