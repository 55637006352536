import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Activity } from '_api/useActivities';
import BookButton from './Bookbutton';
import { screenMargin } from '../../../../_utils/sizes';
import { Modal } from '../../../../Components';

interface SignUpSelfModalProps {
    onDismiss(): void;
    activity: Activity;
}

const SignUpSelfModal = ({ onDismiss, activity }: SignUpSelfModalProps): ReactElement => {
    const { t } = useTranslation();
    return (
        <Modal
            onDismiss={onDismiss}
            header={t('activity:signUpSelf')}
            content={<BookButton activity={activity} onComplete={onDismiss} />}
            style={{ margin: screenMargin }}
        />
    );
};

export default SignUpSelfModal;
