import { useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import getMissingScreens from '_navigator/hooks/getMissingScreens';
import getParentsArray from '_navigator/hooks/getParentsArray';
import navigationReference from '_navigator/navigationReference';
import { getAllScreens, getAllStaticScreens, getNonScreens } from '../navigationConfiguration/screenConfiguration';
import type {
    NavigationDestination,
    NonScreen,
    ScreenComponentProp,
    ScreenName,
} from '../navigationConfiguration/types';

type navigateOptions<Key extends NavigationDestination> = Key extends ScreenName
    ? ScreenComponentProp<Key> extends undefined
        ? undefined
        : ScreenComponentProp<Key>
    : {
          screen: Key;
          params: Key extends ScreenName ? ScreenComponentProp<Key> : navigateOptions<NavigationDestination>;
      };

type navigateFunction<Key extends NavigationDestination> = (screen: Key, params: navigateOptions<Key>) => void;

const useAppNavigation = (): {
    navigate: navigateFunction<NavigationDestination>;
    goBack: (n?: number) => void;
    push: navigateFunction<NavigationDestination>;
    pop: () => void;
    popToTop: () => void;
    replace: navigateFunction<NavigationDestination>;
    canGoBack: () => boolean;
} => {
    const {
        navigate: internalNavigate,
        push: internalPush,
        pop,
        popToTop,
        replace: internalReplace,
        canGoBack,
        goBack,
        // No benefit typing this
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = useNavigation<any>();

    const handleNavigate = useCallback(
        (func: typeof internalNavigate | typeof internalPush): navigateFunction<NavigationDestination> =>
            (screen, params): void => {
                const allScreens = getAllScreens();
                const nonScreens = getNonScreens();

                const foundScreens = allScreens.filter(([name]) => name === screen);
                const foundNonScreens = Object.entries(nonScreens).filter(([name]) => name === screen) as [
                    keyof typeof nonScreens,
                    { parent: NonScreen | null },
                ][];
                if (foundScreens.length < 1 && foundNonScreens.length < 1) {
                    throw new Error(`Could not find specified screen:${screen}`);
                }

                // All possible paths to the screen
                const missingScreensMatrix = [...foundScreens, ...foundNonScreens].map(([name, settings]) => {
                    const parents = getParentsArray(name, settings.parent);
                    return getMissingScreens(navigationReference.ref?.getRootState()?.routes ?? [], parents);
                });

                // We select the shortest and first path
                let missingScreens = missingScreensMatrix.reduce(
                    (curr, val) => (curr.length > val.length ? val : curr),
                    missingScreensMatrix[0],
                );

                if (missingScreens.length <= 1) {
                    if (screen in getAllStaticScreens()) {
                        func(screen, params);
                    } else {
                        // Does this happen?
                        func(screen, params);
                    }
                    return;
                }

                const topMissingScreen = missingScreens[0];

                // The first not existing route is the one we want to throw, the rest is the path below;
                const buildParams: { screen?: undefined | NavigationDestination; params?: undefined | {} } = {};
                let curr = buildParams;
                missingScreens.slice(1).forEach((val, i) => {
                    curr.screen = val;
                    if (i === missingScreens.length - 2) {
                        curr.params = params;
                    } else {
                        curr.params = {};
                        curr = curr.params;
                    }
                });
                return func(topMissingScreen, buildParams);
            },
        [],
    );

    const navigate = useMemo(() => handleNavigate(internalNavigate), [handleNavigate, internalNavigate]);
    const push = useMemo(() => handleNavigate(internalPush), [handleNavigate, internalPush]);
    const replace = useMemo(() => handleNavigate(internalReplace), [handleNavigate, internalReplace]);

    return { navigate, goBack, push, pop, popToTop, replace, canGoBack };
};

export default useAppNavigation;
