import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SelectedCoopState = number | null;

const initialState: SelectedCoopState = null;

export const selectedCoopSlice = createSlice({
    name: 'selectedCoop',
    initialState: initialState as SelectedCoopState,
    reducers: {
        selectCoop: (state, action: PayloadAction<number>) => {
            return action.payload;
        },
        reset: () => {
            return null;
        },
    },
});

export const { selectCoop, reset } = selectedCoopSlice.actions;

export default selectedCoopSlice.reducer;
