import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AVAILABLE_LANGUAGES } from '../_localization/localization';

type SelectedLanguageState = { selectedLanguage: keyof typeof AVAILABLE_LANGUAGES | null };

const initialState: SelectedLanguageState = { selectedLanguage: null };

const selectedLanguageSlice = createSlice({
    name: 'selectedLanguage',
    initialState,
    reducers: {
        selectLanguage: (state, action: PayloadAction<keyof typeof AVAILABLE_LANGUAGES>) => {
            state.selectedLanguage = action.payload;
        },
    },
});

export const { selectLanguage } = selectedLanguageSlice.actions;

export default selectedLanguageSlice.reducer;
