import moment from 'moment';
import { UnixTimeCode } from 'types/Utility';

type Booking = {
    start_at: UnixTimeCode;
    end_at: UnixTimeCode;
    quantity: number;
};

type BookingEvent = {
    time: UnixTimeCode;
    quantityChange: number;
};

export type Slot = { start: UnixTimeCode; end: UnixTimeCode; available_quantity: number };
type result =
    | {
          type: 'some';
          slots: Slot[];
      }
    | {
          type: 'all';
      }
    | {
          type: 'none';
      };

function calculateShortTermAvailableSlots(bookings: Booking[], maxCapacity: number, date: Date): result {
    let events: BookingEvent[] = [];
    bookings.forEach((booking) => {
        events.push({ time: booking.start_at, quantityChange: -booking.quantity });
        events.push({ time: booking.end_at, quantityChange: booking.quantity });
    });
    events.sort((a, b) => a.time - b.time);

    let slots: Slot[] = [];
    let currentCapacity = maxCapacity;
    const earliestTime = moment(date).isSame(moment(), 'day') ? moment().unix() : moment(date).startOf('day').unix();
    const latestTime = moment(date).endOf('day').unix();
    let lastTime: UnixTimeCode | null = null;

    if (events.length !== 0 && events[0].time > earliestTime) {
        lastTime = earliestTime;
    }
    events.forEach((event) => {
        if (lastTime === null) {
            lastTime = event.time;
        } else {
            slots.push({
                start: lastTime,
                end: event.time,
                available_quantity: currentCapacity,
            });
            lastTime = event.time;
        }

        currentCapacity += event.quantityChange;
    });
    if (lastTime !== null && lastTime < latestTime) {
        slots.push({
            start: lastTime,
            end: latestTime,
            available_quantity: currentCapacity,
        });
    }

    slots = slots
        .filter(
            (slot) =>
                slot.end > earliestTime &&
                slot.start <= latestTime &&
                slot.available_quantity > 0 &&
                slot.start !== slot.end,
        )
        .map((slot) => ({
            ...slot,
            start: Math.max(slot.start, earliestTime),
            end: Math.min(slot.end, latestTime),
        }))
        .filter((item) => item.end - item.start > 60);

    if (bookings.length && slots.length) {
        return {
            type: 'some',
            slots: slots,
        };
    } else if (bookings.length === 0) {
        return {
            type: 'all',
        };
    } else {
        return {
            type: 'none',
        };
    }
}

export default calculateShortTermAvailableSlots;
