import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { screenMargin, subtitleFontSize, subtitleLineHeight } from '_utils/sizes';
import { HeimeText, Modal } from 'Components';

const ExplainerModal = ({
    onClose,
    is_long_term,
    has_max_booking,
    max_booking,
    max_booked_per_booking,
    hasTimeBasedPricing,
}: {
    onClose(): void;
    is_long_term: boolean;
    has_max_booking: boolean;
    max_booking: number;
    max_booked_per_booking: number | null;
    hasTimeBasedPricing: boolean;
}): ReactElement => {
    const { t } = useTranslation();
    const themedStyle = useThemeStyle(styles);
    return (
        <Modal
            header={is_long_term ? t('serviceExpanded:reserveDays') : t('serviceExpanded:reserveTime')}
            content={
                <View style={themedStyle.container}>
                    <HeimeText style={themedStyle.description}>
                        {is_long_term
                            ? t('serviceExpanded:reserveDescriptionDays')
                            : t('serviceExpanded:reserveDescriptionTime')}
                        {has_max_booking ? (
                            <HeimeText>
                                {t('serviceExpanded:maxBookingStart')}
                                <HeimeText style={themedStyle.decriptionHighlight}>
                                    {is_long_term
                                        ? t('serviceExpanded:maxBooking_day', {
                                              count: max_booking,
                                          })
                                        : t('serviceExpanded:maxBooking_hour', {
                                              count: max_booking,
                                          })}
                                    {'. '}
                                </HeimeText>
                            </HeimeText>
                        ) : null}
                        {max_booked_per_booking
                            ? t('serviceExpanded:maxBookedPerBooking', { count: max_booked_per_booking })
                            : null}
                        {t('serviceExpanded:clickOnBusy')}
                        {hasTimeBasedPricing ? t('serviceExpanded:timeBasedPricing') : null}
                    </HeimeText>
                </View>
            }
            buttons={[
                {
                    text: t('serviceExpanded:close'),
                    onPress: onClose,
                },
            ]}
            onDismiss={onClose}
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            marginBottom: screenMargin / 2,
        },
        description: { fontSize: subtitleFontSize, lineHeight: subtitleLineHeight, color: theme.secondaryText },
        decriptionHighlight: { color: theme.main },
    });

export default ExplainerModal;
