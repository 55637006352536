import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApartmentList, ApartmentSelected } from './components';
import useRefreshApartments from './useRefetchData';
import { useApartmentQueries } from '../../_api/useApartments';
import { useOwnProfile } from '../../_api/useProfile';
import { useAppNavigation } from '../../_navigator';
import { HeaderWithNav, Loader } from '../../Components';
import { useSelectedCoop } from '../../SelectedCoop';

const MyHome = (): ReactElement => {
    const { goBack } = useAppNavigation();
    const { data: profile } = useOwnProfile();
    const selectedCoop = useSelectedCoop();
    const userApartments = useMemo(
        () => profile?.cooperative_apartments?.filter(({ cooperative_id }) => selectedCoop === cooperative_id) ?? [],
        [profile?.cooperative_apartments, selectedCoop],
    );
    const { t } = useTranslation();

    const { isLoading } = useApartmentQueries(userApartments.map(({ id }) => id));

    const [isRefetching, handleRefetch] = useRefreshApartments();

    const handleBackPress = () => {
        if (userApartments.length === 1) {
            goBack();
        } else {
            if (apartmentId) {
                setApartmentId(null);
            } else {
                goBack();
            }
        }
    };

    useEffect(() => {
        if (userApartments.length === 1) {
            setApartmentId(userApartments[0].id);
        }
    }, [userApartments]);

    const handleOnPress = (aptId: number) => {
        setApartmentId(aptId);
    };

    const [apartmentId, setApartmentId] = useState<number | null>();

    if (isLoading) {
        return (
            <>
                <HeaderWithNav title={t('myHome:title')} safeArea />
                <Loader />
            </>
        );
    }

    const content = () => {
        if (userApartments.length === 1) {
            return <ApartmentSelected onBackPress={handleBackPress} apartmentId={userApartments[0].id} />;
        } else {
            if (apartmentId) {
                return <ApartmentSelected onBackPress={handleBackPress} apartmentId={apartmentId} />;
            }
            return (
                <ApartmentList
                    isLoading={isRefetching}
                    refetch={handleRefetch}
                    onBackPress={handleBackPress}
                    onPress={handleOnPress}
                    apartments={userApartments}
                />
            );
        }
    };

    return <>{content()}</>;
};

export default MyHome;
