import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../Components';

interface SetAdminModalProps {
    onDismiss(): void;
    onSetAdmin(user: { id: number; name: string }): Promise<void>;
    user: { id: number; name: string };
    isSetting?: boolean;
}

const SetAdminModal = ({ onDismiss, onSetAdmin, user, isSetting }: SetAdminModalProps): ReactElement => {
    const { t } = useTranslation();
    const handleSetAdmin = async () => {
        onSetAdmin(user);
    };

    return (
        <Modal
            onDismiss={onDismiss}
            header={t('group:adminModal:header', { user: user.name })}
            buttons={[
                { text: t('group:adminModal:yes'), onPress: handleSetAdmin, isLoading: isSetting },
                {
                    text: t('group:adminModal:no'),
                    onPress: onDismiss,
                    type: 'danger',
                },
            ]}
        />
    );
};

export default SetAdminModal;
