import React, { ReactElement } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { smallestMargin } from '_utils/sizes';
import _fonts from '../../../_fonts';
import { Theme, useThemeStyle, WW } from '../../../_utils';

interface BubbleProps {
    attachment?: ReactElement;
    senderAvatar?: boolean;
    author?: ReactElement;
    content?: ReactElement;
    isMe: boolean;
    time: string;
}

const Bubble = ({ senderAvatar, attachment, author, content, time, isMe }: BubbleProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const timeText = (
        <Text
            style={[
                themedStyle.timeText,
                isMe ? themedStyle.timeMe : themedStyle.timeOther,
                content ? undefined : themedStyle.timeNoContent,
            ]}
            numberOfLines={1}
        >
            {time}
        </Text>
    );

    return (
        <View
            style={[
                themedStyle.bubble,
                isMe
                    ? [themedStyle.myMessageBubble, { marginRight: senderAvatar ? smallestMargin : 0 }]
                    : [themedStyle.otherMessageBubble, { marginLeft: senderAvatar ? smallestMargin : 0 }],
            ]}
        >
            {attachment}
            {content ? (
                <View style={themedStyle.contentContainer}>
                    {author}
                    {content}
                    {timeText}
                </View>
            ) : (
                timeText
            )}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        bubble: {
            overflow: 'hidden',
            maxWidth: WW * 0.75,
            borderRadius: 24,
            minWidth: WW * 0.2,
        },
        contentContainer: {
            marginLeft: WW * 0.03,
            marginRight: WW * 0.03,
            marginTop: WW * 0.03,
            marginBottom: WW * 0.03,
            paddingBottom: WW * 0.04,
        },
        myMessageBubble: {
            borderBottomRightRadius: 0,
            backgroundColor: theme.myChatMessageBubble,
        },
        otherMessageBubble: {
            borderBottomLeftRadius: 0,
            backgroundColor: theme.mainBackground,
            borderColor: theme.lightGrey,
            borderWidth: 1,
            marginLeft: WW * 0.03,
        },
        timeText: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            fontSize: WW * 0.03,
            alignSelf: 'flex-end',
            fontFamily: _fonts.primaryFont,
        },
        timeNoContent: {
            right: WW * 0.03,
            bottom: WW * 0.03,
        },
        timeMe: {
            color: theme.secondaryLight,
            right: 0,
        },
        timeOther: { color: theme.secondaryText },
    });

export default Bubble;
