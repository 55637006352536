import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormDropdown from './FormDropdown';
import { useSelectedCoopItem } from '../_utils/hooks';

const CooperativeGroupDropdown = ({ isCreatingNew }: { isCreatingNew: boolean }): ReactElement | null => {
    const { t } = useTranslation();
    const selectedCoop = useSelectedCoopItem();
    const cooperativeGroupItems = useMemo(
        () =>
            (selectedCoop?.cooperative_groups ?? []).map((value) =>
                value.type === 'coopGroup'
                    ? { id: value.id, text: value.name }
                    : {
                          id: null,
                          text: `${t('newActivity:noCooperativeGroup')} ${selectedCoop?.name ?? ''}`,
                      },
            ),
        [selectedCoop?.cooperative_groups, selectedCoop?.name, t],
    );

    if (selectedCoop?.cooperative_groups.length === 1) {
        return null;
    }

    return (
        <FormDropdown<{ cooperative_group_id: number | null }, 'cooperative_group_id'>
            name="cooperative_group_id"
            label={t('newActivity:cooperative_group_id')}
            items={cooperativeGroupItems}
            initialGrey={isCreatingNew}
        />
    );
};

export default CooperativeGroupDropdown;
