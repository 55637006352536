import { useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment, MomentInput } from 'moment';
import { LayoutAnimation } from 'react-native';
import { useGetProductReservations } from '_api/useReservations';
import { TimeZone } from '_constants';
import { flattenIniniteResult } from '_utils/misc';
import useBookedModal from 'Screens/ProductReserve/useBookedModal';
import { OwnReservation } from 'types/Reservation';
import isSlotTaken from '../isSlotTaken';

export interface TimeSelectorProps {
    isShortTerm: boolean;
    productId: number;
    onSelectionChange(from: Date | null, to: Date | null): void;
    onChangeViewingDate(newDate: Date, isStep: boolean, skipAnimation: boolean): void;
    viewingDate: Date;
    maxBooking: number;
    selection: { from: Date | null; to: Date | null };
    bookingToEdit?: OwnReservation;
    productSettings: { checkinHour: number; checkinMinute: number; checkoutHour: number; checkoutMinute: number };
    haveGoneBack: boolean;
    loadReservationsFrom: Moment;
    capacity: number;
    amount: number;
    defaultShowEarlyHours: boolean;
}

interface Slot {
    startTime: Date;
    endTime: Date;
    isBusy: boolean;
    isPossibleEndTime: boolean;
    isDisableSelect: boolean;
}

interface ProductSettings {
    checkinHour: number;
    checkinMinute: number;
    checkoutHour: number;
    checkoutMinute: number;
}

const setToCheckinTime = (date: MomentInput, settings: ProductSettings) => {
    return moment(date)
        .tz(TimeZone)
        .set({ hour: settings.checkinHour, minute: settings.checkinMinute, second: 0, millisecond: 0 })
        .unix();
};

const setToCheckoutTime = (date: MomentInput, settings: ProductSettings) => {
    return moment(date)
        .tz(TimeZone)
        .set({
            hour: settings.checkoutHour,
            minute: settings.checkoutMinute,
            second: 0,
            millisecond: 0,
        })
        .unix();
};

const useTimeSelectorHook = (props: TimeSelectorProps) => {
    const { startTime, endTime } = useMemo(() => {
        const { from, to } = props.selection;
        return { startTime: from ? moment(from) : null, endTime: to ? moment(to) : null };
    }, [props.selection]);
    const handleDateChange = (newDate: Moment, step: boolean, skipAnimation: boolean) =>
        props.onChangeViewingDate(newDate.toDate(), step, skipAnimation);
    const [clickedSeeMore, setClickedSeeMore] = useState(props.defaultShowEarlyHours);

    const handleSelectionChange = useCallback(
        (start: Moment | null, end: Moment | null) => {
            props.onSelectionChange(start ? start.toDate() : null, end ? end.toDate() : null);
        },
        [props],
    );

    const {
        data: existingBookingsData,
        isLoading,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
    } = useGetProductReservations(props.productId, props.loadReservationsFrom);

    const existingBookings = useMemo(() => {
        const flattened = flattenIniniteResult(existingBookingsData).filter(
            (item) => item.id !== props.bookingToEdit?.id,
        );
        if (!props.isShortTerm) {
            return flattened;
        }
        const viewDateUnix = moment(props.viewingDate).startOf('day').unix();
        return flattened.filter((item) => item.end_at >= viewDateUnix);
    }, [existingBookingsData, props.bookingToEdit?.id, props.isShortTerm, props.viewingDate]);

    useEffect(() => {
        if (!isFetchingNextPage && hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

    const isActualLoading = isLoading && !existingBookingsData;

    const getTimeSlots = useCallback((): Slot[] => {
        if (!props.isShortTerm) {
            return [];
        }
        const currentMoment = moment();

        let slotList: Slot[] = [];
        const start = moment(props.viewingDate).startOf('day');
        for (let i = 0; i < 24; i++) {
            let isBusy = false;
            const timeValue = moment(start).add({ hours: i });
            const end = moment(timeValue).endOf('hour');
            const now = moment();
            if (existingBookings.length > 0) {
                isBusy = isSlotTaken({
                    start: timeValue.unix(),
                    end: end.unix(),
                    bookings: existingBookings,
                    amount: props.amount,
                    // We show old slots as taken as long as they have a booking
                    capacity: end.isBefore(now) ? 1 : props.capacity,
                });
            }

            slotList.push({
                startTime: timeValue.toDate(),
                endTime: end.toDate(),
                isBusy,
                isDisableSelect: isActualLoading || (!isBusy && moment().isAfter(end)),
                isPossibleEndTime: false,
            });
        }

        if (!props.haveGoneBack) {
            slotList = slotList.filter(({ endTime: slotEnd }) => moment(slotEnd).isAfter(currentMoment));
        }

        const startingTime = clickedSeeMore
            ? moment(start)
            : moment(props.viewingDate).set({ hour: 6 }).startOf('hour');

        if (moment(props.viewingDate).isAfter(currentMoment, 'day')) {
            slotList = slotList.filter(({ endTime: slotEnd }) => moment(slotEnd).isAfter(startingTime));
        }

        if (startTime === null) {
            return slotList;
        }

        let disable = startTime.isBefore(start)
            ? isSlotTaken({
                  start: startTime.unix(),
                  end: start.unix(),
                  bookings: existingBookings,
                  amount: props.amount,
                  capacity: props.capacity,
              })
            : false;
        return slotList.map((item) => {
            if (moment(item.endTime).isBefore(startTime)) {
                return item;
            }
            if (
                disable ||
                isSlotTaken({
                    start: item.startTime.valueOf() / 1000,
                    end: item.endTime.valueOf() / 1000,
                    bookings: existingBookings,
                    amount: props.amount,
                    capacity: props.capacity,
                }) ||
                (props.maxBooking && Math.ceil(moment(item.endTime).diff(startTime, 'hours', true)) > props.maxBooking)
            ) {
                disable = true;
                return { ...item, isDisableSelect: true };
            }
            return { ...item, isPossibleEndTime: true };
        });
    }, [
        props.isShortTerm,
        props.viewingDate,
        props.haveGoneBack,
        props.amount,
        props.capacity,
        props.maxBooking,
        clickedSeeMore,
        startTime,
        existingBookings,
        isActualLoading,
    ]);

    const getDateSlots = useCallback((): Slot[] => {
        if (props.isShortTerm) {
            return [];
        }
        const startingDate =
            moment().isSame(props.viewingDate, 'month') && !props.haveGoneBack
                ? moment().tz(TimeZone).startOf('day')
                : moment(props.viewingDate).startOf('month');

        const lastDay = startingDate.clone().endOf('month');
        const days = [];
        let currDay = startingDate.clone();

        let nLoops = 0;
        while (currDay.isSameOrBefore(lastDay) && nLoops++ < 31) {
            days.push(currDay.toDate());
            currDay = currDay.add({ day: 1 });
        }
        let disable = false;
        return days.map((date) => {
            const slotTimes = { startTime: date, endTime: date, isDisableSelect: isActualLoading };
            const now = moment();
            if (!startTime || startTime.isAfter(date, 'day')) {
                // Option if its possible to be a checkin day
                const checkinToday = setToCheckinTime(date, props.productSettings);
                const checkoutTomorrow = setToCheckoutTime(moment(date).add({ day: 1 }), props.productSettings);

                const isBusy = isSlotTaken({
                    start: checkinToday,
                    end: checkoutTomorrow,
                    bookings: existingBookings,
                    amount: props.amount,
                    capacity: checkoutTomorrow < now.unix() ? 1 : props.capacity,
                });

                return {
                    ...slotTimes,
                    isBusy,
                    isDisableSelect:
                        slotTimes.isDisableSelect || (!isBusy && moment().isAfter(moment(date).endOf('D'))),
                    isPossibleEndTime: false,
                };
            }
            const checkoutDayBefore = setToCheckoutTime(moment(date).subtract({ day: 1 }), props.productSettings);
            const checkoutOnDay = setToCheckoutTime(moment(date), props.productSettings);
            const isBusy = isSlotTaken({
                start: Math.max(checkoutDayBefore, startTime.unix()),
                end: checkoutOnDay,
                bookings: existingBookings,
                amount: props.amount,
                capacity: checkoutOnDay < now.unix() ? 1 : props.capacity,
            });

            if (isBusy) {
                return {
                    ...slotTimes,
                    isBusy,
                    isPossibleEndTime: false,
                };
            }
            disable =
                disable ||
                isSlotTaken({
                    start: startTime.unix(),
                    end: checkoutOnDay,
                    bookings: existingBookings,
                    amount: props.amount,
                    capacity: props.capacity,
                }) ||
                (Boolean(props.maxBooking) &&
                    Math.ceil(Math.abs(moment.unix(checkoutOnDay).diff(startTime, 'days', true))) > props.maxBooking);

            return { ...slotTimes, isBusy, isDisableSelect: disable, isPossibleEndTime: !disable };
        });
    }, [
        props.isShortTerm,
        props.viewingDate,
        props.haveGoneBack,
        props.productSettings,
        props.amount,
        props.capacity,
        props.maxBooking,
        isActualLoading,
        startTime,
        existingBookings,
    ]);

    const slots = useMemo(
        () => (props.isShortTerm ? getTimeSlots() : getDateSlots()),
        [getDateSlots, getTimeSlots, props.isShortTerm],
    );

    const onTimePress = useCallback(
        (from: Date, to: Date) => {
            const currentTimeStart = moment(from);
            const currentTimeEnd = moment(to);

            if (startTime?.isBefore(moment()) && props.bookingToEdit) {
                // If existing reservation has started, we can only edit endTime (and only set it to a future time)
                if (currentTimeEnd?.isAfter(moment())) {
                    handleSelectionChange(startTime, currentTimeEnd);
                }
            } else if (startTime === null || currentTimeStart.isBefore(startTime)) {
                // No startSlot or Clicked before current startSlot, lets start and end to that slot
                handleSelectionChange(currentTimeStart, currentTimeEnd);
            } else if (startTime.isSame(currentTimeStart)) {
                // Clicked startTime, lets reset
                handleSelectionChange(null, null);
            } else if (endTime?.isSame(currentTimeEnd)) {
                // Clicked endtime again, lets move startTime to this slot aswell
                handleSelectionChange(currentTimeStart, currentTimeEnd);
            } else {
                // change endTime
                handleSelectionChange(startTime, currentTimeEnd);
            }
        },
        [endTime, handleSelectionChange, props.bookingToEdit, startTime],
    );

    const onDatePress = useCallback(
        (dateSelected: Date) => {
            const currDateCheckin = setToCheckinTime(moment(dateSelected), props.productSettings);
            const currDateCheckout = setToCheckoutTime(moment(dateSelected), props.productSettings);

            if (startTime?.isBefore(moment()) && props.bookingToEdit) {
                // If existing reservation has started, we can only edit endTime (and only set it to a future time)
                if (currDateCheckout && currDateCheckout > moment().unix()) {
                    handleSelectionChange(startTime, moment.unix(currDateCheckout));
                }
            } else if (startTime === null || currDateCheckin < startTime.unix()) {
                // No starttime or clicked start time before current one
                handleSelectionChange(moment.unix(currDateCheckin), null);
            } else if (
                startTime.isSame(moment.unix(currDateCheckin)) ||
                endTime?.isSame(moment.unix(currDateCheckout))
            ) {
                // Clicked startTime or endTime, lets reset
                handleSelectionChange(null, null);
            } else {
                handleSelectionChange(startTime, moment.unix(currDateCheckout));
            }
        },
        [props.productSettings, props.bookingToEdit, startTime, endTime, handleSelectionChange],
    );

    const [bookedModal, openBookedModal] = useBookedModal(props.productId, props.loadReservationsFrom);

    const isInSelectedTime = useCallback(
        (time: Date) => {
            const currentTime = moment(time);
            if (startTime !== null && endTime !== null) {
                if (currentTime.isBetween(startTime, endTime, 'minute', '[]')) {
                    return true;
                }
            }

            return false;
        },
        [startTime, endTime],
    );

    const isInSelectedDate = useCallback(
        (dateSelected: Date) => {
            if (startTime && !endTime) {
                return moment(dateSelected).isSame(startTime, 'day');
            }
            if (startTime && endTime) {
                return (
                    moment(dateSelected).isSameOrAfter(startTime, 'day') &&
                    moment(dateSelected).isSameOrBefore(moment(endTime).endOf('day'), 'day')
                );
            }
            return false;
        },
        [startTime, endTime],
    );

    const toggleSeeMore = () => {
        setClickedSeeMore((item) => !item);
        LayoutAnimation.configureNext(
            LayoutAnimation.create(150, LayoutAnimation.Types.easeInEaseOut, LayoutAnimation.Properties.scaleY),
        );
    };

    return {
        isInSelectedTime,
        isInSelectedDate,
        onDatePress,
        onTimePress,
        isLoading,
        slots,
        selectingDate: props.viewingDate,
        handleDateChange,
        onOpenBookedModal: openBookedModal,
        modal: bookedModal,
        clickedSeeMore,
        toggleSeeMore,
    };
};

export { useTimeSelectorHook };
