import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { suggestions } from '_api/useActivityInterest';
import { useAppNavigation } from '_navigator';
import { track } from '_utils/Amplitude';
import { captureException } from '_utils/Sentry';
import { screenMargin } from '_utils/sizes';
import { PrimaryButton, SecondaryButton } from 'Components';
import { PressHere, ImagesBanner, DirectPress } from 'Screens/Home/components/ActivitiesPreview/variants';
import useForceVariant from 'Screens/Home/components/ActivitiesPreview/variants/useForceVariant';
import { ArrayElement } from 'types/Utility';

const variants = ['press_here', 'images_banner', 'direct_press', 'primary_button'];
let variant = variants[Math.floor(Math.random() * variants.length)];
let emoji = variant === 'direct_press' && Math.random() < 0.5;

const ActivitySuggestionCta = () => {
    const { navigate } = useAppNavigation();
    const { t } = useTranslation();
    const forceVariant = useForceVariant();
    if (forceVariant) {
        variant = 'press_here';
        emoji = false;
    }

    const goToActivitySuggestions = (suggestion?: ArrayElement<typeof suggestions>) => {
        if (suggestion) {
            track('Pressed activities all suggestions CTA', { variant, suggestion, emoji });
            navigate('ActivitySuggestionSelected', { suggestion });
        } else {
            track('Pressed activities all suggestions CTA', { variant, emoji });
            navigate('ActivitySuggestions', undefined);
        }
    };

    const handleCreateActivity = () => {
        track('Pressed direct press create activity CTA', { variant: 'direct_press' });
        navigate('ActivityNew', undefined);
    };

    useEffect(() => {
        track('Showing activity suggestions CTA', { variant, emoji });
    }, []);

    switch (variant) {
        case 'press_here':
            return <PressHere onPress={() => goToActivitySuggestions()} />;
        case 'images_banner':
            return <ImagesBanner onPress={() => goToActivitySuggestions()} />;
        case 'direct_press':
            return <DirectPress onPress={goToActivitySuggestions} emoji={emoji} />;
        case 'primary_button':
            return (
                <View
                    style={{
                        gap: screenMargin,
                    }}
                >
                    <PrimaryButton
                        onPress={() => goToActivitySuggestions()}
                        text={t('activities:empty_button_suggestion')}
                    />
                    <SecondaryButton onPress={handleCreateActivity} text={t('activities:empty_button_create')} />
                </View>
            );
    }
    captureException(new Error(`Unknown variant: ${variant}`));
    return null;
};

export default ActivitySuggestionCta;
