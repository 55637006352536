import React, { ReactElement, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isBookingInSlot } from '../../../../_utils';
import { DateWithSlots } from '../../../../Components';
import { Reservation } from '../../../../types/Reservation';

interface LongTermActiveBookingProps {
    day: Date;
    bookings: Reservation[];
    onDateSelect(): void;
    capacity: number;
    checkInTime: Date;
}

const LongTermActiveBookingItem = ({
    day,
    bookings,
    onDateSelect: handleDateSelect,
    capacity,
    checkInTime,
}: LongTermActiveBookingProps): ReactElement => {
    const { t } = useTranslation();
    const date = useMemo(() => moment(day), [day]);

    const usedQuantity = useMemo(
        () =>
            bookings
                .filter(isBookingInSlot(moment(checkInTime).unix(), moment(day).endOf('day').unix()))
                .reduce((prev, curr) => prev + (curr.quantity ?? 1), 0),
        [bookings, checkInTime, day],
    );

    const handlePress = () => handleDateSelect();

    const isAvailable = usedQuantity < capacity;

    return (
        <DateWithSlots
            disabled={!isAvailable}
            date={date}
            title={
                isAvailable
                    ? capacity > 1
                        ? t('serviceExpanded:availableCount', { count: capacity - usedQuantity })
                        : t('serviceExpanded:available')
                    : t('serviceExpanded:notAvailable')
            }
            onPress={handlePress}
            slots={[]}
        />
    );
};

export default LongTermActiveBookingItem;
