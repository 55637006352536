import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { useGetNotes } from '../../../_api/useNotes';
import { WH } from '../../../_utils';
import { flattenIniniteResult } from '../../../_utils/misc';
import { Loader, SecondaryButton, CommentList } from '../../../Components';

interface SupportCommentsProps {
    supportId: number;
    showCommentButton: boolean;
    onAddCommentPress(): void;
}

const SupportComments = ({
    supportId,
    showCommentButton: showCommentButton,
    onAddCommentPress,
}: SupportCommentsProps): ReactElement => {
    const { t } = useTranslation();
    const { data, isLoading, isError, error, hasNextPage } = useGetNotes(supportId);

    const comments = flattenIniniteResult(data);

    if (isError) {
        throw error;
    }

    if (isLoading) {
        return (
            <View style={styles.loaderContainer}>
                <Loader bgColor="mainBackground" />
            </View>
        );
    }

    return (
        <CommentList
            hasNextPage={hasNextPage ?? false}
            topButton={
                showCommentButton && comments.length > 0 ? (
                    <SecondaryButton onPress={onAddCommentPress} text={t('reportSelected:addComment').toUpperCase()} />
                ) : undefined
            }
            bottomButton={
                showCommentButton && comments.length === 0 ? (
                    <SecondaryButton onPress={onAddCommentPress} text={t('reportSelected:addComment').toUpperCase()} />
                ) : undefined
            }
            comments={comments.map((comment) => ({
                ...comment,
                files: [...comment.files, ...comment.pictures],
                creator: comment.creator.id,
                edited: false,
                deleted: false,
            }))}
            messageId={supportId}
            editable={false}
        />
    );
};

const styles = StyleSheet.create({ loaderContainer: { marginTop: WH * 0.1 } });

export default SupportComments;
