import React, { ReactElement, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useOwnProfile } from '../../../../_api/useProfile';
import { useGetRatings } from '../../../../_api/useRatings';
import { flattenIniniteResult } from '../../../../_utils/misc';
import { screenMargin } from '../../../../_utils/sizes';
import { SecondaryButton, SendFeedbackModal } from '../../../../Components';

interface SendStarProps {
    creator: number;
}

const SendStar = ({ creator }: SendStarProps): ReactElement => {
    const { data: profile } = useOwnProfile();
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState<'sendStar' | false>(false);
    const { data: ratings } = useGetRatings(profile?.id ?? 0);
    useGetRatings(undefined, creator);

    const handleStarModalOpen = () => setModalOpen('sendStar');
    const handleModalDismiss = () => setModalOpen(false);

    const availableAfter = useMemo(() => {
        const moments = flattenIniniteResult(ratings)
            .filter(({ own }) => own)
            .map(({ created_at }) => moment.unix(created_at));

        if (moments.length === 0) {
            return -Infinity;
        }

        const latest = moment.max(moments);
        const diff = moment().diff(latest, 'h');
        return 24 - diff;
    }, [ratings]);

    return (
        <>
            <SecondaryButton
                status={availableAfter <= 0 ? null : 'disabled'}
                onPress={handleStarModalOpen}
                text={
                    availableAfter <= 0
                        ? t('sharingSelected:helperProfile:sendStar').toUpperCase()
                        : t('sharingSelected:helperProfile:willBeAvailable', {
                              count: availableAfter,
                          }).toUpperCase()
                }
                style={styles.button}
            />
            {modalOpen === 'sendStar' && (
                <SendFeedbackModal
                    header={t('sharingSelected:helperProfile:sendStar')}
                    userId={creator}
                    onDismiss={handleModalDismiss}
                />
            )}
        </>
    );
};

const styles = StyleSheet.create({
    button: { marginLeft: screenMargin, marginRight: screenMargin },
});

export default SendStar;
