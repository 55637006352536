import React, { ReactElement, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { ContactGroup, useGetContactGroups } from '_api/useContactGroup';
import { useAppNavigation, useContextMenuFocusEffect } from '_navigator';
import { Theme, useThemeStyle, WW } from '_utils';
import { useBottomSpacer, useSelectedCoopItem } from '_utils/hooks';
import { flattenIniniteResult, isTruthy } from '_utils/misc';
import { screenMargin } from '_utils/sizes';
import { ExpandableSearchInput, HeaderWithNav, Loader, QueryItemView, SecondaryButton } from 'Components';
import { BoardList } from './components';

const Board = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const paddingBottom = useBottomSpacer();
    const { data, isLoading, isError, error, refetch, isRefetching } = useGetContactGroups();
    useContextMenuFocusEffect(['reportsNew']);
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';

    const [opened, setOpened] = useState<Record<string, boolean>>({});
    const [searchedText, setSearchedText] = useState<string>('');

    const boardMembers = useMemo((): (ContactGroup & { expanded?: boolean })[] => {
        const mapped = flattenIniniteResult(data);
        if (searchedText) {
            return mapped
                .map((item) => {
                    if (item.name?.toLowerCase().includes(searchedText.toLowerCase())) {
                        return item;
                    }
                    const children = item.members.filter((member) =>
                        [member.fname, member.lname, ...member.tags, member.mail, member.phone, member.roles].some(
                            (arg) => arg?.includes(searchedText),
                        ),
                    );
                    if (children.length > 0) {
                        return { ...item, members: children, expanded: true };
                    }
                    return null;
                })
                .filter(isTruthy);
        }
        return mapped;
    }, [data, searchedText]);

    const handleSearchTextChange = useCallback((newStr: string) => setSearchedText(newStr), []);

    const onExpandCollapse = (id: number) => {
        setOpened((val) => {
            const index = `${id}`;
            const value = val[index]
                ? false
                : val[index] === false
                  ? true
                  : boardMembers.find((item) => item.id === id)?.expanded
                    ? false
                    : true;
            return { ...val, [index]: value };
        });
    };

    if (isError) {
        throw error;
    }

    return (
        <View style={themedStyle.main}>
            <HeaderWithNav title={t('board:title')} safeArea />
            {isLoading ? (
                <Loader />
            ) : (
                <QueryItemView
                    onRefresh={refetch}
                    isRefreshing={isRefetching}
                    style={themedStyle.main}
                    containerStyle={{ paddingBottom }}
                    keyboardShouldPersistTaps="handled"
                    virtualized
                >
                    <View style={themedStyle.searchContainer}>
                        <ExpandableSearchInput
                            value={searchedText}
                            onChange={handleSearchTextChange}
                            buttonText={t('board:search')}
                        />
                    </View>
                    <BoardList
                        onExpandCollapse={onExpandCollapse}
                        emptyString={searchedText ? t('board:noResult') : t('board:noContent')}
                        groups={boardMembers}
                        opened={opened}
                    />
                    <SecondaryButton
                        style={{ margin: screenMargin }}
                        text={t('board:reportToBoard', {
                            boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                        }).toUpperCase()}
                        onPress={() => {
                            navigate('ReportsAll', undefined);
                        }}
                    />
                </QueryItemView>
            )}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
        searchContainer: {
            flex: 0,
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            marginLeft: screenMargin,
            marginRight: screenMargin,
        },
    });

export { Board };
