import React, { PropsWithChildren, ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { WH, WW } from '_utils';
import { Theme, ThemeContext } from '_utils/themeContext';
import { Icon, Modal } from 'Components';
import { Lock } from 'types/Reservation';

interface LockModalProps {
    lock: Lock;
    onClose(): void;
}

const LockModal = ({ onClose }: LockModalProps): ReactElement => {
    const { t } = useTranslation();
    return (
        <Modal
            hideCloseButton
            onDismiss={onClose}
            header={t('expandedReservation:lockModal:noWeb')}
            content={
                <LockRing color="red">
                    <Icon name="lockSlash" color="red" scale={2} />
                </LockRing>
            }
            buttons={[{ text: t('expandedReservation:lockModal:close'), type: 'secondary', onPress: onClose }]}
        />
    );
};

interface LockRingProps {
    color: keyof Theme;
}
const LockRing = ({ children, color }: PropsWithChildren<LockRingProps>) => {
    const { theme } = useContext(ThemeContext);

    return (
        <View style={styles.ringContainer}>
            <View
                style={[
                    styles.lockContainer,
                    {
                        borderTopColor: theme[color],
                        borderRightColor: theme[color],
                        borderBottomColor: theme[color],
                        borderLeftColor: theme[color],
                    },
                ]}
            >
                {children}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    lockContainer: {
        paddingTop: WW * 0.15,
        paddingRight: WW * 0.15,
        paddingBottom: WW * 0.15,
        paddingLeft: WW * 0.15,
        borderTopEndRadius: WH,
        borderTopStartRadius: WH,
        borderBottomEndRadius: WH,
        borderBottomStartRadius: WH,
        borderTopWidth: 2,
        borderRightWidth: 2,
        borderBottomWidth: 2,
        borderLeftWidth: 2,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: WW * 0.02,
    },
    ringContainer: { alignItems: 'center', justifyContent: 'center', flex: 1 },
});

export default LockModal;
