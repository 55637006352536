import React, { ReactElement, useState, PropsWithChildren } from 'react';
import i18next from 'i18next';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useSelectedCoopItem } from '_utils/hooks';
import { ExpiredInfo } from 'Screens/Sharing/SharingSelected/components/RequestDetail/Components';
import { ApartmentNeighbors, ProfileAvatar, ProfileCompletion } from './Components';
import useGetUserGroups from './useGetUserGroups';
import { useGetGroups } from '../../_api/useGroups';
import { useOwnProfile } from '../../_api/useProfile';
import { useGetUser, useGetUsers } from '../../_api/useUsers';
import _fonts from '../../_fonts';
import { useAppNavigation, useContextMenuFocusEffect } from '../../_navigator';
import { Theme, useThemeStyle, useChatWithNeighbour, WH, WW, openURL, useIsCoopAdmin } from '../../_utils';
import { getUsernameFromProfile } from '../../_utils/misc';
import { screenMargin, subtitleFontSize, smallestMargin } from '../../_utils/sizes';
import {
    HeaderWithNav,
    PrimaryButton,
    Icon,
    Loader,
    LabelValue,
    QueryItemView,
    ContextMenu,
    GroupList,
    SelectorChip,
    SendFeedbackModal,
    BottomSpacer,
    HeimeText,
} from '../../Components';
import NotFoundErrorScreen from '../../Components/NotFoundErrorScreen';
import { DeletedUser, ExternalUserProfile, getDeletedUserFallbackName, isNonDeletedUser } from '../../types/User';

const getNavTitle = (isOwnProfile: boolean) =>
    isOwnProfile ? i18next.t('profile:title').toUpperCase() : i18next.t('profile:titleNotOwnProfile').toUpperCase();

const ProfileLoadedGate = ({ route }: { route: { params?: { id: number; isModal?: boolean } } }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const {
        data: ownProfile,
        isLoading: isLoadingOwnProfile,
        error: errorOwnProfile,
        isError: isErrorOwnProfile,
    } = useOwnProfile();
    const id = route.params?.id ?? ownProfile?.id ?? 0;
    const isOwnProfile = id === ownProfile?.id;
    useContextMenuFocusEffect(['chatNew']);
    // Preloading
    useGetGroups();

    const { isLoading, isFetchingNextPage, error, isError } = useGetUsers(false);
    const profile = useGetUser(id);
    if ((!isOwnProfile && (isLoading || (!profile && isFetchingNextPage))) || (isOwnProfile && isLoadingOwnProfile)) {
        return (
            <>
                <HeaderWithNav
                    title={getNavTitle(ownProfile?.id === id)}
                    style={themedStyle.navHeader}
                    action={
                        <View style={themedStyle.moreButton}>
                            <Icon name={'moreFilled'} color="black" />
                        </View>
                    }
                />
                <Loader />
            </>
        );
    }

    if (isError || isErrorOwnProfile) {
        throw error ?? errorOwnProfile;
    }

    const profileValue = isOwnProfile ? ownProfile : profile;

    if (!profileValue) {
        return <NotFoundErrorScreen type="Profile" />;
    }

    if (isNonDeletedUser(profileValue)) {
        return (
            <ProfileScreen
                profile={profileValue}
                isOwnProfile={id === (ownProfile?.id ?? 0)}
                isModal={route.params?.isModal ?? false}
            />
        );
    }

    return <DeletedProfileScreen profile={profileValue} isModal={route.params?.isModal ?? false} />;
};

const ProfileScreen = ({
    profile,
    isOwnProfile,
    isModal,
}: {
    profile: ExternalUserProfile;
    isOwnProfile: boolean;
    isModal: boolean;
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate, push } = useAppNavigation();
    const cooperative = useSelectedCoopItem();
    const birthDate = moment.unix(profile.birthday!);
    const isAdmin = useIsCoopAdmin();

    const apartments =
        profile.cooperative_apartments
            // We show all apartments for other users!
            ?.filter(({ cooperative_id }) => !isOwnProfile || cooperative_id === cooperative?.id)
            .map((a) => `${a.name}${a.cooperative_id !== cooperative?.id ? ` (${a.cooperative_name})` : ''}`) ?? [];
    const parkingSpots =
        profile.cooperative_apartments
            ?.filter(
                ({ cooperative_id, parking_spot }) =>
                    (!isOwnProfile || cooperative_id === cooperative?.id) && parking_spot,
            )
            .map((a) => `${a.parking_spot}${a.cooperative_id !== cooperative?.id ? ` (${a.cooperative_name})` : ''}`) ??
        [];

    const tags = profile.tags?.filter(({ cooperative_id }) => cooperative_id === cooperative?.id) ?? [];

    const { isFetchingNextPage } = useGetGroups();
    const { isFetchingNextPage: isFetchingNextUserPage } = useGetUsers(true);

    const [modalOpen, setModalOpen] = useState<'moreMenu' | 'sendFeedback' | false>(false);
    const { isLoading: isCreatingChat, onChat } = useChatWithNeighbour();

    const handleMoreToggle = () => setModalOpen('moreMenu');
    const handleSendFeedbackClick = () => setModalOpen('sendFeedback');
    const handleModalDismiss = () => setModalOpen(false);

    const handleChatWithNeighbour = () => onChat(profile.id);

    const userGroups = useGetUserGroups(profile.id);

    const handleEditProfile = () => {
        handleModalDismiss();
        navigate('ProfileEdit', undefined);
    };

    const handleEditPin = () => {
        handleModalDismiss();
        navigate('CreatePin', undefined);
    };

    const handleNavigateToUser = (id: number) => {
        if (isOwnProfile) {
            // this makes sure the modal is closed when navigating to a neighbour from your profile modal
            navigate('Profile', { id });
        } else {
            push('Profile', { id });
        }
    };

    const showPhone = (!profile.hide_phone || isAdmin) && profile.phone;
    const showEmail = (!profile.hide_mail || isAdmin) && profile.email;

    return (
        <>
            <Header
                contextMenu={
                    <ContextMenu
                        actions={
                            isOwnProfile
                                ? [
                                      {
                                          text: t('profile:edit'),
                                          onPress: handleEditProfile,
                                          type: 'normal',
                                      },
                                      {
                                          text: t('profile:setUpPin'),
                                          onPress: handleEditPin,
                                          type: 'normal',
                                      },
                                  ]
                                : [
                                      {
                                          text: t('profile:sendFeedback'),
                                          onPress: handleSendFeedbackClick,
                                          type: 'normal',
                                      },
                                  ]
                        }
                        onToggle={modalOpen === 'moreMenu' ? handleModalDismiss : handleMoreToggle}
                        isOpen={modalOpen === 'moreMenu'}
                        trigger={
                            <View style={themedStyle.moreButton}>
                                <Icon name={'moreFilled'} color="black" />
                            </View>
                        }
                    />
                }
                isOwnProfile={isOwnProfile}
                isModal={isModal}
            />

            <QueryContainer>
                <View style={themedStyle.centered}>
                    <ProfileAvatar profile={profile} />

                    <Text style={themedStyle.titleText} maxFontSizeMultiplier={2} selectable>
                        {getUsernameFromProfile(profile)}
                    </Text>
                    <Subtitle
                        phone={
                            showPhone
                                ? isValidPhoneNumber(`+${profile.phone!}`)
                                    ? parsePhoneNumber(`+${profile.phone!}`).formatInternational()
                                    : (profile.phone ?? null)
                                : null
                        }
                        secondText={
                            apartments.length > 0
                                ? apartments.join(', ')
                                : profile.cooperative_name !== cooperative?.name && !isOwnProfile
                                  ? profile.cooperative_name
                                  : null
                        }
                    />

                    {isOwnProfile ? (
                        <ProfileCompletion joinedGroup={userGroups.length > 0} />
                    ) : (
                        <View style={themedStyle.rowContainer}>
                            {showPhone ? <CallButton profile={profile} /> : null}

                            <TouchableOpacity onPress={handleChatWithNeighbour} style={themedStyle.containerCenter}>
                                {isCreatingChat ? (
                                    <Loader />
                                ) : (
                                    <View style={themedStyle.callContainer}>
                                        <Icon name="message" color="white" />
                                    </View>
                                )}
                                <Text style={themedStyle.callText}>{t('profile:message')}</Text>
                            </TouchableOpacity>
                        </View>
                    )}

                    <View style={themedStyle.fullWidth}>
                        {profile.about_me ? (
                            <LabelValue
                                style={themedStyle.labelStyle}
                                orientation={'vertical'}
                                label={t('profile:about_me')}
                                value={profile.about_me}
                                switched
                            />
                        ) : null}

                        {showEmail ? <EmailLabel profile={profile} /> : null}

                        {profile.birthday && !profile.hide_birthday ? (
                            <LabelValue
                                orientation={'vertical'}
                                label={t('profile:birthDate')}
                                value={`${Math.floor(moment().diff(birthDate, 'year', true))} år, ${birthDate.format(
                                    'DD. MMMM',
                                )}`}
                                style={themedStyle.labelStyle}
                                switched
                            />
                        ) : null}
                        {parkingSpots.length ? (
                            <LabelValue
                                orientation="vertical"
                                label={t('profile:parkingSpots', { count: parkingSpots.length })}
                                value={parkingSpots.join(', ')}
                                style={themedStyle.labelStyle}
                                switched
                            />
                        ) : null}

                        {tags.length ? (
                            <View>
                                <Text maxFontSizeMultiplier={2} style={themedStyle.titleText}>
                                    {t('board:boardResponsibility')}
                                </Text>
                                <View style={themedStyle.chipsWrapper}>
                                    {tags.map((tag) => {
                                        return (
                                            <SelectorChip
                                                variant="lightPrimary"
                                                key={tag.tag}
                                                containerStyle={themedStyle.chip}
                                                title={tag.tag}
                                                maxFontSizeMultiplier={2}
                                            />
                                        );
                                    })}
                                </View>
                            </View>
                        ) : null}
                    </View>
                </View>
                <ApartmentNeighbors
                    onNavigateToUser={handleNavigateToUser}
                    profileId={profile.id}
                    labelStyle={themedStyle.labelStyle}
                />
                {isFetchingNextUserPage ? <Loader /> : null}

                {userGroups.length !== 0 && (
                    <>
                        <LabelValue
                            label={t('profile:groups')}
                            value={`(${userGroups.length})`}
                            style={themedStyle.labelStyle}
                            switched={true}
                        />
                        <GroupList filter={userGroups.map(({ id }) => id)} groupScreen="Related" />
                        {isFetchingNextPage ? <Loader /> : null}
                    </>
                )}
            </QueryContainer>
            <View style={themedStyle.buttonContainer}>
                {isOwnProfile ? (
                    <PrimaryButton bottomAction text={t('profile:edit')} onPress={handleEditProfile} />
                ) : (
                    <PrimaryButton
                        bottomAction
                        text={t('profile:back').toUpperCase()}
                        onPress={handleChatWithNeighbour}
                        status={isCreatingChat ? 'loading' : undefined}
                    />
                )}
                {isModal ? <View style={themedStyle.paddingBottom} /> : <BottomSpacer />}
            </View>
            {modalOpen === 'sendFeedback' && (
                <SendFeedbackModal
                    header={t('profile:sendFeedback')}
                    userId={profile.id}
                    onDismiss={handleModalDismiss}
                />
            )}
        </>
    );
};

const DeletedProfileScreen = ({ profile, isModal }: { profile: DeletedUser; isModal: boolean }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const isAdmin = useIsCoopAdmin();
    const { t } = useTranslation();

    const showPhone = isAdmin && profile.phone;
    const showEmail = isAdmin && profile.email;

    return (
        <>
            <Header isOwnProfile={false} isModal={isModal} />

            <QueryContainer>
                <View style={themedStyle.centered}>
                    <ProfileAvatar profile={{ avatar: null }} />
                    <Text style={themedStyle.titleText} maxFontSizeMultiplier={2} selectable>
                        {getUsernameFromProfile(profile)}
                    </Text>
                    <Subtitle phone={showPhone ? profile.phone! : null} secondText={profile.apartment_name} />

                    <View style={themedStyle.rowContainer}>{showPhone ? <CallButton profile={profile} /> : null}</View>
                    <View style={themedStyle.fullWidth}>{showEmail ? <EmailLabel profile={profile} /> : null}</View>
                    {isAdmin ? (
                        <ExpiredInfo
                            title={t('profile:deletedUser_explainer')}
                            description={t('profile:deletedUser_explainer_description', {
                                name: getDeletedUserFallbackName(profile, t),
                            })}
                        />
                    ) : null}
                </View>
            </QueryContainer>
            <View style={themedStyle.buttonContainer}>
                {isModal ? <View style={themedStyle.paddingBottom} /> : <BottomSpacer />}
            </View>
        </>
    );
};

const Header = ({
    isOwnProfile,
    isModal,
    contextMenu,
}: {
    isOwnProfile: boolean;
    isModal: boolean;
    contextMenu?: ReactElement;
}) => {
    const themedStyle = useThemeStyle(styles);
    return (
        <HeaderWithNav
            style={themedStyle.navHeader}
            title={getNavTitle(isOwnProfile)}
            safeArea={!isModal}
            action={contextMenu}
        />
    );
};

const QueryContainer = ({ children }: PropsWithChildren<unknown>) => {
    const { refetch, isRefetching } = useGetGroups();
    const { refetch: refetchOwnProfile, isRefetching: isRefetchingOwnProfile } = useOwnProfile();
    const { isRefetching: isRefetchingUser, refetch: refetchUser } = useGetUsers(true);
    const themedStyle = useThemeStyle(styles);

    return (
        <QueryItemView
            style={themedStyle.main}
            isRefreshing={isRefetching || isRefetchingUser || isRefetchingOwnProfile}
            onRefresh={() => {
                refetch();
                refetchOwnProfile();
                refetchUser();
            }}
            virtualized
        >
            {children}
        </QueryItemView>
    );
};

const Subtitle = ({ phone, secondText }: { phone: string | null; secondText: string | null }) => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View style={[themedStyle.rowContainer, { flexWrap: 'wrap', gap: smallestMargin }]}>
            <HeimeText variant="subtitle" style={themedStyle.phnAptText} selectable>
                {phone ? phone : ''}
            </HeimeText>
            {secondText ? (
                <HeimeText variant="subtitle" selectable style={themedStyle.phnAptText}>
                    {(phone ? '  •  ' : '') + secondText}
                </HeimeText>
            ) : null}
        </View>
    );
};

const CallButton = ({ profile }: { profile: Pick<Exclude<ReturnType<typeof useGetUser>, null>, 'phone'> }) => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    return profile.phone ? (
        <TouchableOpacity style={themedStyle.containerCenter} onPress={() => openURL(`tel:+${profile.phone}`, t)}>
            <View style={themedStyle.callContainer}>
                <Icon name="call" color="white" />
            </View>
            <Text style={themedStyle.callText}>{t('profile:call')}</Text>
        </TouchableOpacity>
    ) : null;
};

const EmailLabel = ({ profile }: { profile: Pick<Exclude<ReturnType<typeof useGetUser>, null>, 'email'> }) => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    return profile.email ? (
        <LabelValue
            orientation={'vertical'}
            label={t('profile:email')}
            value={profile.email!}
            style={themedStyle.labelStyle}
            switched
        />
    ) : null;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        navHeader: { backgroundColor: theme.mainBackground },
        main: {
            backgroundColor: theme.mainBackground,
            paddingTop: WH * 0.02,
            paddingBottom: WH * 0.02,
            paddingLeft: WH * 0.02,
            paddingRight: WH * 0.02,
        },
        moreButton: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            paddingTop: screenMargin / 2,
            paddingBottom: screenMargin / 2,
            minHeight: 40,
        },
        buttonContainer: {
            backgroundColor: theme.mainBackground,
        },
        centered: {
            alignItems: 'center',
            justifyContent: 'space-evenly',
        },
        fullWidth: {
            width: '100%',
            marginTop: WH * 0.02,
        },
        paddingBottom: {
            paddingBottom: screenMargin,
        },
        callContainer: {
            borderRadius: (WW * 0.1) / 2,
            width: WW * 0.1,
            height: WW * 0.1,
            backgroundColor: theme.darkGreen,
            alignItems: 'center',
            justifyContent: 'center',
        },
        labelStyle: {
            marginTop: WH * 0.01,
        },
        phnAptText: {
            color: theme.darkGreen,
        },
        titleText: {
            color: theme.darkGray,
            marginTop: WH * 0.01,
            fontFamily: _fonts.primaryFontBold,
            fontSize: subtitleFontSize,
        },
        callText: {
            fontSize: WW * 0.03,
            marginTop: WW * 0.01,
            fontFamily: _fonts.primaryFont,
            color: theme.darkGreen,
        },
        rowContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },

        containerCenter: {
            alignItems: 'center',
            paddingLeft: WH * 0.02,
            paddingRight: WH * 0.02,
            marginTop: WH * 0.02,
        },
        chipsWrapper: {
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        chip: {
            marginBottom: WH * 0.01,
            backgroundColor: theme.lightGreen,
            borderColor: theme.lightGreen,
        },
    });

export default ProfileLoadedGate;
