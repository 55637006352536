import React, { ReactElement } from 'react';
import { StyleSheet, Text, View, Pressable, ScrollView } from 'react-native';
import { screenMargin } from '_utils/sizes';
import SecondaryButton from 'Components/SecondaryButton';
import { ModalProps } from './types';
import { isWeb, Theme, useThemeStyle, WW } from '../../_utils';
import { WH } from '../../_utils/dimensions.web';
import PrimaryButton from '../PrimaryButton';

const ModalContent = ({
    onDismiss,
    header,
    content,
    buttons = [],
    hideCloseButton = false,
    buttonsDirection = 'row',
}: Omit<ModalProps, 'style'>): ReactElement => {
    const themedStyle = useThemeStyle(styles);

    return (
        <View style={themedStyle.content}>
            {hideCloseButton ? null : (
                <View style={themedStyle.buttonContainer}>
                    <Pressable onPress={onDismiss}>
                        <Text style={themedStyle.button}>+</Text>
                    </Pressable>
                </View>
            )}
            <ScrollView keyboardShouldPersistTaps="always" bounces={false}>
                <Text style={[themedStyle.title, hideCloseButton ? null : themedStyle.titleWithCloseButton]}>
                    {header}
                </Text>
                {content}
                {buttons.length === 0 ? null : (
                    <View
                        style={[
                            themedStyle.actionButtonContainer,
                            buttonsDirection === 'column' ? themedStyle.columnButtons : themedStyle.rowButtons,
                        ]}
                    >
                        {buttons.map(({ type, onPress, isLoading, text, isDisabled }, i) => {
                            const props = {
                                key: text,
                                style: [
                                    themedStyle.colorButtons,
                                    i !== buttons.length - 1
                                        ? buttonsDirection === 'column'
                                            ? themedStyle.firstButtonColumn
                                            : themedStyle.firstButtonRow
                                        : null,
                                ],
                                text,
                                onPress: onPress,
                                status: isLoading ? ('loading' as const) : isDisabled ? ('disabled' as const) : null,
                            };

                            if (type === 'secondary') {
                                return <SecondaryButton {...props} key={props.key} />;
                            }

                            return <PrimaryButton danger={type === 'danger'} {...props} key={props.key} />;
                        })}
                    </View>
                )}
            </ScrollView>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        content: {
            padding: WW * 0.05,
            backgroundColor: theme.mainBackground,
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'flex-end',
            zIndex: 299,
            position: isWeb() ? 'absolute' : undefined,
            right: isWeb() ? screenMargin : undefined,
            margin: -WW * 0.05,
            marginRight: (-WW * 0.05) / 2,
        },
        columnButtons: {
            flexDirection: 'column',
        },
        rowButtons: {
            flexDirection: 'row',
        },
        button: {
            fontSize: 32,
            width: 32,
            height: 32,
            lineHeight: 32,
            marginTop: WW * 0.01,
            marginBottom: WW * 0.01,
            marginLeft: WW * 0.01,
            marginRight: WW * 0.01,
            fontWeight: 'normal',
            transform: [{ rotate: '45deg' }],
            textAlign: 'center',
            textAlignVertical: 'center',
            color: theme.black,
        },
        title: {
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 12,
            color: theme.black,
        },
        titleWithCloseButton: {
            marginRight: 32,
        },
        actionButtonContainer: {
            flexDirection: 'row',
            width: '100%',
            paddingTop: 12,
        },
        firstButtonRow: {
            marginRight: WH * 0.02,
        },
        firstButtonColumn: {
            marginBottom: WH * 0.02,
        },
        colorButtons: {
            flex: 1,
        },
    });

export default ModalContent;
