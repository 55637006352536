import React, { ReactElement } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, FlatList } from 'react-native';
import _fonts from '../../../../_fonts';
import { Theme, useThemeStyle, WH, WW } from '../../../../_utils';
import { HeaderWithNav, ImageSelector, PrimaryButton } from '../../../../Components';

const ActivityPictures = ({ goBack, goToPreview }: { goBack(): void; goToPreview(): void }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { field } = useController({ name: 'pictures' });

    const handleChange = (images: typeof field.value) => {
        field.onChange(images);
    };

    return (
        <>
            <HeaderWithNav title={t('newActivity:add_photo')} onPress={goBack} />
            <FlatList
                contentContainerStyle={themedStyle.content}
                data={[{}]}
                ListHeaderComponent={
                    <>
                        <Text style={themedStyle.title}>{t('newActivity:add_photo')}</Text>
                        <Text style={themedStyle.text}>{t('newActivity:first_image_will')}</Text>
                    </>
                }
                renderItem={() => {
                    return (
                        <ImageSelector
                            images={field.value}
                            title={t('newActivity:image')}
                            onChange={handleChange}
                            openInitially={field.value.length === 0}
                        />
                    );
                }}
                keyExtractor={() => 'image_selector'}
            />
            <PrimaryButton text={t('newActivity:continue').toUpperCase()} onPress={goToPreview} bottomAction="modal" />
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        content: {
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
        },
        title: {
            marginTop: WW * 0.04,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WH * 0.035,
            color: theme.black,
        },
        text: {
            color: theme.main,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
        },
    });

export default ActivityPictures;
