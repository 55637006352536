import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { Survey } from '_api/useSurveys';
import { fileIsImage, Theme, useThemeStyle } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { AttachmentsView, HeimeText } from 'Components';
import { ArrayElement } from 'types/Utility';
import OptionsView from './OptionsView';

const AnswerOverview = ({
    questions,
    answers,
}: {
    questions: Survey['questions'];
    answers: ArrayElement<Survey['responses']>['answers'];
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    return (
        <View style={themedStyle.answersContainer}>
            {questions.map((question) => {
                const answer = answers.find((a) => a.question_id === question.id);
                const options = [
                    ...question.options,
                    ...(answer?.options.filter((item) => !question.options.find((option) => option.id === item.id)) ??
                        []),
                ];
                const files =
                    answer?.attachments.filter((file, index) => !fileIsImage({ ...file, status: 'stale', index })) ??
                    [];
                const pictures =
                    answer?.attachments.filter((file, index) => fileIsImage({ ...file, status: 'stale', index })) ?? [];

                return (
                    <View key={question.id}>
                        <HeimeText maxFontSizeMultiplier={2} style={themedStyle.questionText}>
                            {question.question}
                        </HeimeText>
                        {answer ? (
                            <>
                                {answer.value ? (
                                    <HeimeText maxFontSizeMultiplier={2} style={themedStyle.answerText}>
                                        {answer.value}
                                    </HeimeText>
                                ) : null}
                                <AttachmentsView pictures={pictures} files={files} size={'large'} />
                                {answer.options.length > 0 || question.options.length ? (
                                    <OptionsView
                                        type={question.type}
                                        selectedOptions={answer.options.map((item) => item.id)}
                                        options={options}
                                        onChange={() => {}}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <HeimeText maxFontSizeMultiplier={2} style={[themedStyle.answerText, themedStyle.noAnswer]}>
                                {t('surveys:noAnswer')}
                            </HeimeText>
                        )}
                    </View>
                );
            })}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        answersContainer: {
            marginTop: screenMargin,
            marginBottom: screenMargin,
            flexDirection: 'column',
        },
        questionText: {
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            marginTop: smallestMargin * 2,
        },
        answerText: {
            fontSize: subtitleFontSize,
            marginTop: smallestMargin,
        },
        noAnswer: {
            color: theme.darkGray,
        },
    });

export default AnswerOverview;
