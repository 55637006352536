import React, { ReactElement, useContext } from 'react';
import { View, FlatList, Text, StyleSheet } from 'react-native';
import { ApiImage } from 'types/Base';
import { useOwnProfile } from '../../../_api/useProfile';
import _fonts from '../../../_fonts';
import Images from '../../../_images';
import { useThemeStyle, WW } from '../../../_utils';
import { Theme, ThemeContext } from '../../../_utils/themeContext';
import { CacheImage } from '../../../Components';
import { UserCollectionItemWithAvatar } from '../../../types/User';

interface ChatInfoAvatarProps {
    userGroupPicture: ApiImage | null;
    users: UserCollectionItemWithAvatar[];
}

const ChatInfoAvatar = ({ users }: ChatInfoAvatarProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { data: ownProfile } = useOwnProfile();

    // TODO PARSE NAME SELF NOT RELY ON NO_AVATAR
    let groupAvatars = users
        .filter(({ id }) => id !== (ownProfile?.id ?? 0))
        .map(({ avatar, no_avatar }) => avatar ?? no_avatar ?? 'null');

    return (
        <View style={themedStyle.groupImageContainer}>
            <FlatList
                contentContainerStyle={themedStyle.imageContainer}
                numColumns={2}
                columnWrapperStyle={themedStyle.imageWrapper}
                data={groupAvatars}
                keyExtractor={(_item, index) => index.toString()}
                renderItem={({ item, index }) => {
                    return groupAvatars.length > 4 && index === 3 ? (
                        <View style={[themedStyle.groupWrapper, { backgroundColor: theme.main }]}>
                            <Text style={themedStyle.smallText}>{`+${groupAvatars.length - 3}`}</Text>
                        </View>
                    ) : groupAvatars.length > 4 && index >= 3 ? null : (
                        <>
                            {groupAvatars.length <= 1 ? (
                                <View style={themedStyle.header}>
                                    {!isApiImage(item) && item !== 'null' ? (
                                        <Text style={themedStyle.noAvatar}>{item}</Text>
                                    ) : (
                                        <CacheImage
                                            source={item !== 'null' ? item : Images.boardSection}
                                            style={themedStyle.avatar}
                                        />
                                    )}
                                </View>
                            ) : (
                                <View style={themedStyle.groupWrapper}>
                                    {!isApiImage(item) && item !== 'null' ? (
                                        <Text style={[themedStyle.smallText, { color: theme.main }]}>{item}</Text>
                                    ) : (
                                        <CacheImage
                                            source={item !== 'null' ? item : Images.boardSection}
                                            style={themedStyle.groupImage}
                                        />
                                    )}
                                </View>
                            )}
                        </>
                    );
                }}
            />
        </View>
    );
};

const isApiImage = (image: Omit<ApiImage, 'id'> | string): image is Omit<ApiImage, 'id'> =>
    (image as ApiImage)?.original !== undefined;

const styles = (theme: Theme) =>
    StyleSheet.create({
        header: {
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
            width: WW * 0.25,
            height: WW * 0.25,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.lightGrey,
            borderRadius: (WW * 0.25) / 2,
            overflow: 'hidden',
        },
        groupImageContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
            width: WW * 0.25,
            height: WW * 0.25,
        },
        imageContainer: {
            flex: 1,
            justifyContent: 'center',
        },
        imageWrapper: {
            justifyContent: 'space-around',
        },
        groupWrapper: {
            marginLeft: WW * 0.003,
            marginRight: WW * 0.003,
            marginTop: WW * 0.003,
            marginBottom: WW * 0.003,
            width: WW * 0.12,
            height: WW * 0.12,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.lightGrey,
            borderRadius: (WW * 0.12) / 2,
            overflow: 'hidden',
        },
        groupImage: {
            width: WW * 0.12,
            height: WW * 0.12,
            borderRadius: (WW * 0.12) / 2,
            overflow: 'hidden',
        },
        noAvatar: {
            color: theme.main,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.1,
        },
        smallText: {
            color: theme.mainText,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.04,
        },
        avatar: {
            backgroundColor: theme.lightGrey,
            width: WW * 0.25,
            height: WW * 0.25,
            borderRadius: (WW * 0.25) / 2,
            overflow: 'hidden',
        },
    });

export default ChatInfoAvatar;
