import { FileLink } from 'types/Base';
import { File } from '../types/Heime';

function encodeLastLine(url: string): string {
    const split = url.split('/');
    if (split.length <= 1) {
        return url;
    }
    return split.splice(0, split.length - 1).join('/') + '/' + encodeURIComponent(split[split.length - 1]);
}

const mapFromMessageFileToHeimeFile = (item: FileLink): File => ({
    name: item.name,
    url: encodeLastLine(item.original),
    last_modified: item.last_modified,
    size: '',
});

export default mapFromMessageFileToHeimeFile;
