import { ReactElement } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';
import InputLabel from './InputLabel';
import RadioButton from './RadioButton';

interface FormInputProps<FormType> {
    name: Path<FormType>;
    label?: string;
    options: {
        label: string;
        value: string;
    }[];
}

const FormRadio = <FormType extends FieldValues = Record<string, string>>({
    name,
    label,
    options,
}: FormInputProps<FormType>): ReactElement => {
    const { field } = useController<FormType>({ name });

    const getHandlePress = (val: string) => () => {
        field.onChange(val);
    };

    return (
        <>
            {label ? <InputLabel>{label}</InputLabel> : null}
            {options.map(({ value, label: optionLabel }) => (
                <RadioButton
                    key={value}
                    onPress={getHandlePress(value)}
                    checked={field.value === value}
                    title={optionLabel}
                />
            ))}
        </>
    );
};

export default FormRadio;
