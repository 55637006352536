import { useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { AppState } from 'react-native';
import useTimeChangedEvent from './useTimeChangedEvent';

/**
 * Triggers rerenders based on duration until a given date time (if countdown enabled will rerender for each countdown value either each hour, minute or second). Also triggers rerenders on time change events and app state changes.
 * @param timer date time until which to rerender
 * @param countdownToStart if true will rerender for each countdown value either each hour, minute or second
 */
const useRerenderCountdown = (timer: Moment, countdownToStart: boolean): void => {
    const [, setRerenderCount] = useState(0);
    useEffect(() => {
        let timeout = Math.abs(timer.diff(moment()));
        if (countdownToStart) {
            const diff = timer.diff(moment());
            // HOURS LEFT
            if (diff > 1000 * 60 * 60) {
                timeout = (diff % (1000 * 60 * 60)) + 1;
            } else if (diff > 1000 * 60) {
                // MINUTES LEFT
                timeout = (diff % (1000 * 60)) + 1;
            } else {
                // SECONDS LEFT
                timeout = diff % 1000;
            }
        }

        if (timeout >= 0) {
            const func = setTimeout(() => setRerenderCount((c) => c + 1), timeout);
            return () => clearTimeout(func);
        }
    }, [countdownToStart, timer]);

    useTimeChangedEvent(useCallback(() => setRerenderCount((c) => c + 1), []));

    useEffect(() => {
        const listener = AppState.addEventListener('change', () => {
            setRerenderCount((c) => c + 1);
        });
        return () => listener.remove();
    });
};

export default useRerenderCountdown;
