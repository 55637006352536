import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useAddChatMembers } from '../_api/useChats';
import { useGetUsers } from '../_api/useUsers';
import { SafeAreaView } from '../_dependencies/safeArea';
import { useAppNavigation } from '../_navigator';
import {
    Theme,
    useThemeStyle,
    useChatWithNeighbour,
    useIsFocused,
    useNeighbourSearch,
    WH,
    WW,
    useConfirmDiscard,
} from '../_utils';
import { screenMargin } from '../_utils/sizes';
import {
    CheckBox,
    EmptyList,
    Icon,
    Loader,
    PrimaryButton,
    QuerySectionedView,
    SecondaryButton,
    SectionHeader,
    UserListItem,
} from '../Components';
import ControlledInput from '../Components/ControlledInput';
import { ExternalUserProfile } from '../types/User';

interface AddParams {
    type: 'add';
    alreadyJoined: number[];
    chatId: number;
}
interface ChatNewMembersProps {
    route: { params: { type: 'create'; alreadyJoined?: undefined; chatId?: undefined } | AddParams };
}

const ChatNewMembers = ({ route }: ChatNewMembersProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { data, isLoading, isError, error, refetch, isRefetching, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useGetUsers(false, true);

    const { type, alreadyJoined, chatId } = route.params;

    const { filteredData, handleSearchTextChange, searchString } = useNeighbourSearch(data, 'alphabetic', false);
    const [usersState, setSelectedUsers] = useState<Record<number, boolean>>({});
    const { onChat, isLoading: isLoadingNeighborChat } = useChatWithNeighbour();
    const { mutate: addUsers, isPending: isAddingUsers } = useAddChatMembers();
    const { navigate, goBack: internalGoBack } = useAppNavigation();
    const { content } = useConfirmDiscard(
        Object.keys(usersState).length > 0 && !isAddingUsers && !isLoadingNeighborChat,
        type === 'add',
        internalGoBack,
    );
    const { handleBlur, handleFocus } = useIsFocused();
    if (isError) {
        throw error;
    }

    const handleContinueClick = () => {
        const selectedUsers = Object.entries(usersState)
            .filter(([_, isSelected]) => isSelected)
            .map(([item]) => parseInt(item, 10));
        if (type === 'add') {
            addUsers([chatId, selectedUsers], {
                onSuccess: () => {
                    internalGoBack();
                },
            });
        } else {
            if (selectedUsers.length === 1) {
                onChat(selectedUsers[0], () => {
                    navigate('ChatsAll', { showBackHeader: 'false' });
                });
            } else {
                navigate('ChatsAll', { showBackHeader: 'false' });
                navigate('ChatNewName', { members: selectedUsers });
            }
        }
    };

    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

    const getHandleSelectUser = (userId: number) => () => {
        setSelectedUsers((currentUsers) => {
            if (currentUsers[userId]) {
                delete currentUsers[userId];
            } else {
                currentUsers[userId] = true;
            }
            return { ...currentUsers };
        });
    };

    const filteredSections = useMemo(() => {
        if (type === 'create') {
            return filteredData;
        }
        return filteredData
            .map((item) => ({
                ...item,
                data: item.data.filter((user) => !alreadyJoined?.includes(user.id)),
            }))
            .filter((item) => item.data.length > 0);
    }, [alreadyJoined, filteredData, type]);

    const nSelected = Object.values(usersState).filter(Boolean).length;

    const handleRemoveAll = () => {
        setSelectedUsers({});
    };

    const renderItem = useCallback(
        ({ item }: { item: ExternalUserProfile & { name: string } }) => {
            return (
                <TouchableOpacity onPress={getHandleSelectUser(item.id)}>
                    <UserListItem
                        title={item.name}
                        id={item.id}
                        actionButton={<CheckBox onPress={getHandleSelectUser(item.id)} checked={usersState[item.id]} />}
                        containerStyle={{
                            marginLeft: screenMargin,
                            marginRight: screenMargin,
                        }}
                        disableProfileNavigate
                    />
                </TouchableOpacity>
            );
        },
        [usersState],
    );

    return (
        <SafeAreaView edges={['left', 'bottom', 'right']} style={themedStyle.container}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <View style={themedStyle.topContainer}>
                        <ControlledInput
                            leftImage={<Icon name="search" color="secondaryText" />}
                            onChange={handleSearchTextChange}
                            value={searchString}
                            containerStyle={themedStyle.inputContainer}
                            placeholder={t('chat:search')}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                        {nSelected !== 0 ? (
                            <SecondaryButton
                                style={themedStyle.allButton}
                                text={t('chat:removeAll')}
                                onPress={handleRemoveAll}
                            />
                        ) : null}
                    </View>
                    <QuerySectionedView
                        sections={filteredSections}
                        renderItem={renderItem}
                        onRefresh={refetch}
                        isRefreshing={isRefetching}
                        header={({ title }) => <SectionHeader title={title} />}
                        containerStyle={{ paddingTop: WH * 0.02 }}
                        keyboardShouldPersistTaps="handled"
                        emptyComponent={
                            <EmptyList
                                message={
                                    searchString
                                        ? t('chatInfo:noUsers')
                                        : type === 'create'
                                          ? t('chatInfo:noUsersChat')
                                          : t('chatInfo:noUsersToAdd')
                                }
                            />
                        }
                    />
                    <PrimaryButton
                        onPress={handleContinueClick}
                        text={
                            type === 'add'
                                ? t('chatInfo:addMembers', { count: nSelected })
                                : nSelected
                                  ? t('chatAll:selectMembersSelected', { count: nSelected })
                                  : t('chatAll:selectMembers')
                        }
                        bottomAction
                        status={
                            isLoadingNeighborChat || isAddingUsers ? 'loading' : nSelected === 0 ? 'disabled' : null
                        }
                    />
                </>
            )}
            {content}
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: { display: 'flex', flex: 1, backgroundColor: theme.mainBackground, height: '100%' },
        inputContainer: { flex: 1, flexGrow: 1, marginRight: WW * 0.05 },
        topContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: screenMargin,
            marginRight: screenMargin,
        },
        allButton: { paddingTop: 0, paddingBottom: 0, borderRadius: 5 },
    });

export default ChatNewMembers;
