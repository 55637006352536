import { RESULTS } from 'react-native-permissions';
import { ValueOf } from '../types/Utility';

const check = async (): Promise<ValueOf<typeof RESULTS>> => {
    return Promise.resolve('unavailable');
};

const ask = async (): Promise<ValueOf<typeof RESULTS>> => {
    return Promise.resolve('unavailable');
};

export const permissions = { check, ask };
