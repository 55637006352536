import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleProp, StyleSheet, TextInput, View, ViewStyle } from 'react-native';
import { Menu, MenuTrigger, MenuOptions, MenuOption } from 'react-native-popup-menu';
import _fonts from '_fonts';
import { useAppNavigation } from '_navigator';
import { addPlate, selectPlate } from '_redux/licensePlate';
import { Theme, WW, isWeb, useAppDispatch, useGlobalState, useThemeStyle } from '_utils';
import {
    screenMargin,
    smallestFontSize,
    smallestMargin,
    smallestMarginPixels,
    subtitleFontSize,
    titleFontSize,
} from '_utils/sizes';
import { ErrorMessage, HeimeText, Icon, Modal } from 'Components';

interface LicensePlateModalProps {
    reason: string;
    setReason(reason: string): void;
    onComplete(): void;
    onClose(): void;
}

const LicensePlateModal = ({ reason, setReason, onComplete, onClose }: LicensePlateModalProps): ReactElement => {
    const { t } = useTranslation();
    const themedStyle = useThemeStyle(style);
    const licensePlates = useGlobalState((state) => state.licensePlate.licensePlates);

    const dispatch = useAppDispatch();
    const [selected, setSelected] = useState<number | undefined>(
        licensePlates.length > 0 ? licensePlates[0].id : undefined,
    );

    useEffect(() => {
        if (selected) {
            setReason(licensePlates.find((plate) => plate.id === selected)?.value || '');
        }
    }, [licensePlates, selected, setReason]);

    const handleComplete = () => {
        onComplete();
        onClose();
        if (!selected || licensePlates.find((plate) => plate.id === selected)?.value !== reason) {
            dispatch(addPlate({ value: reason }));
        } else {
            dispatch(selectPlate({ id: selected }));
        }
    };

    const handleSelectPlate = (id: number) => {
        setSelected(id);
    };

    const selectedPlate = licensePlates.find((plate) => plate.value === reason);

    const isTooLongLicensePlate = reason.length > 20;

    return (
        <Modal
            onDismiss={onClose}
            header={t('serviceExpanded:reserveLicensePlateModalHeader')}
            content={
                <>
                    <HeimeText style={themedStyle.explainer}>
                        {t('serviceExpanded:reserveLicensePlateModalText')}
                    </HeimeText>
                    <LicensePlateDropdown onCloseModal={onClose} onSelect={handleSelectPlate} items={licensePlates}>
                        <View style={themedStyle.inputContainer}>
                            <View style={themedStyle.blue} />
                            <TextInput style={themedStyle.textInput} onChangeText={setReason} value={reason} />
                            <View
                                accessibilityLabel={t('serviceExpanded:reserveLicensePlateModalDropdown')}
                                style={themedStyle.dropdownButton}
                            >
                                <Icon name="chevron" color={licensePlates.length > 0 ? 'darkGray' : 'mediumGrey'} />
                            </View>
                        </View>
                    </LicensePlateDropdown>
                    {selectedPlate?.label ? (
                        <HeimeText style={themedStyle.labelExplainer}>{selectedPlate.label}</HeimeText>
                    ) : null}
                    {isTooLongLicensePlate ? (
                        <ErrorMessage>{t('serviceExpanded:reserveLicensePlateModalTooLong')}</ErrorMessage>
                    ) : null}
                </>
            }
            buttons={[
                {
                    text: t('serviceExpanded:reserveLicensePlateModalButton'),
                    onPress: handleComplete,
                    isDisabled: !reason || isTooLongLicensePlate,
                },
            ]}
        />
    );
};

const LicensePlateDropdown = ({
    children,
    items,
    onSelect,
    onCloseModal,
}: PropsWithChildren<{
    items: { id: number; value: string; label: string }[];
    onSelect(id: number): void;
    onCloseModal(): void;
}>) => {
    const themedStyle = useThemeStyle(style);
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();
    const [height, setHeight] = useState<number | undefined>();

    const getOnChangeHandler = (id: number) => () => onSelect(id);
    const handleManage = () => {
        onCloseModal();
        navigate('ManageLicensePlates', undefined);
    };

    return (
        <Menu
            renderer={({ style, children: innerChildren, layouts, ...rest }: CustomRendererProps) => {
                let optionsPosition = {
                    left: WW * 0.05,
                    top: isWeb() ? 0 : layouts.triggerLayout.y + screenMargin,
                    width: layouts.triggerLayout.width,
                    height: layouts.safeAreaLayout.height - layouts.triggerLayout.y + screenMargin,
                };
                setTimeout(() => {
                    setHeight(optionsPosition.height);
                }, 0);

                return (
                    <View {...rest} style={[style, { ...optionsPosition }, themedStyle.absolute]}>
                        {innerChildren}
                    </View>
                );
            }}
        >
            <MenuTrigger disabled={items.length === 0}>{children}</MenuTrigger>
            <MenuOptions>
                <FlatList
                    data={items}
                    renderItem={({ item: { id, value, label } }) => (
                        <MenuOption style={themedStyle.option} key={id} onSelect={getOnChangeHandler(id)}>
                            <HeimeText style={themedStyle.menuText}>{value}</HeimeText>
                            <HeimeText numberOfLines={1} style={themedStyle.labelText}>
                                {label}
                            </HeimeText>
                        </MenuOption>
                    )}
                    contentContainerStyle={themedStyle.menuContent}
                    style={[themedStyle.menu, { height: height ? height - screenMargin * 2 : height }]}
                    bounces={false}
                    keyboardShouldPersistTaps="handled"
                    ListFooterComponent={() => (
                        <MenuOption style={themedStyle.manageOption} onSelect={handleManage}>
                            <Icon name="editPencil" color="main" />
                            <HeimeText style={themedStyle.manageText}>
                                {t('serviceExpanded:reserveLicensePlateModalManage')}
                            </HeimeText>
                        </MenuOption>
                    )}
                />
            </MenuOptions>
        </Menu>
    );
};

const blueWidth = screenMargin * 2;

const style = (theme: Theme) =>
    StyleSheet.create({
        explainer: {
            fontSize: subtitleFontSize,
            color: theme.secondaryText,
            textAlign: 'center',
            marginBottom: smallestMarginPixels * 2,
        },
        leftMargin: {
            marginLeft: smallestMargin,
        },
        inputContainer: {
            borderWidth: 1,
            borderColor: theme.black,
            borderRadius: 10,
            borderStyle: 'solid',
            minHeight: 50,
            overflow: 'hidden',
            flexDirection: 'row',
            alignItems: 'center',
        },
        blue: {
            width: blueWidth,
            backgroundColor: '#2F80ED',
            height: '100%',
        },
        textInput: {
            fontFamily: _fonts.primaryFont,
            fontSize: titleFontSize,
            color: theme.black,
            flexGrow: 1,
            paddingLeft: smallestMargin,
            textAlign: 'center',
            fontWeight: 'bold',
            flexShrink: 1,
        },
        dropdownButton: {
            width: blueWidth,
            justifyContent: 'center',
            alignItems: 'center',
            transform: [{ rotate: '90deg' }],
        },
        menu: { maxHeight: '100%' },
        menuContent: {
            backgroundColor: theme.white,
        },
        menuText: {
            fontSize: titleFontSize,
            color: theme.black,
            flexWrap: 'nowrap',
        },
        row: {
            flexDirection: 'row',
            width: '100%',
            backgroundColor: 'blue',
            flexGrow: 1,
        },
        absolute: {
            position: 'absolute',
            shadowColor: theme.black,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
        },
        option: {
            alignItems: 'center',
            paddingLeft: smallestMargin,
            paddingRight: smallestMargin,
            paddingTop: smallestMargin,
            paddingBottom: smallestMargin,
            flexWrap: 'nowrap',
            flexDirection: 'row',
            overflow: 'hidden',
            borderBottomWidth: 1,
            borderBottomColor: theme.lightGrey,
            borderStyle: 'solid',
        },
        manageOption: {
            backgroundColor: theme.lightGreen,
            flexDirection: 'row',
            paddingLeft: smallestMargin,
            paddingRight: smallestMargin,
            paddingTop: smallestMargin,
            paddingBottom: smallestMargin,
            alignItems: 'center',
        },
        manageText: {
            color: theme.main,
            fontWeight: 'bold',
            fontSize: subtitleFontSize,
            paddingLeft: smallestMargin,
        },
        labelText: {
            fontSize: subtitleFontSize,
            color: theme.darkGray,
            paddingLeft: smallestMargin,
            flexShrink: 1,
        },
        labelExplainer: {
            fontSize: smallestFontSize,
            color: theme.darkGray,
            marginBottom: smallestMargin,
        },
    });

type CustomRendererProps = PropsWithChildren<{
    style: StyleProp<ViewStyle>;
    layouts: {
        triggerLayout: Layout;
        optionsLayout: Layout;
        safeAreaLayout: Layout;
    };
}>;

interface Layout {
    width: number;
    height: number;
    x: number;
    y: number;
    left?: number;
    top?: number;
}

export default LicensePlateModal;
