import { useEffect } from 'react';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { isiOS } from '../../../../../_utils';
import { FormValues } from '../../schema';

const useTimeSync = (): void => {
    const { watch, setValue } = useFormContext<FormValues>();
    const [start, end, cancellationDeadline, registrationDeadline] = watch([
        'start_at',
        'end_at',
        'last_cancel_at',
        'last_confirm_at',
    ]);

    useEffect(() => {
        if (start && end) {
            if (moment(end).isBefore(start)) {
                if (isiOS()) {
                    setValue('end_at', null);
                } else {
                    // Because timepicker on android does not support minimumDate we change it up a bit
                    setValue('end_at', start);
                }
            }

            if (cancellationDeadline) {
                if (moment(cancellationDeadline).isAfter(end)) {
                    setValue('last_cancel_at', end);
                }
            }

            if (registrationDeadline) {
                if (moment(registrationDeadline).isAfter(end)) {
                    setValue('last_confirm_at', end);
                }
            }
        }
    }, [cancellationDeadline, end, registrationDeadline, setValue, start]);
};

export default useTimeSync;
