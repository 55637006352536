import { useContext, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import Animated, { Easing, useAnimatedProps, useSharedValue, withTiming } from 'react-native-reanimated';
import { Circle, Svg } from 'react-native-svg';
import { ThemeContext } from '_utils/themeContext';

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

const UploadProgress = ({ progress, avatarSize }: { progress: number; avatarSize: number }) => {
    const animatedValue = useSharedValue(0);
    const { theme } = useContext(ThemeContext);

    const STROKE_WIDTH = avatarSize * 0.1;
    const RADIUS = avatarSize * 0.45;
    const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

    useEffect(() => {
        animatedValue.value = withTiming(progress, {
            duration: 300,
            easing: Easing.out(Easing.ease),
        });
    }, [progress, animatedValue]);

    const animatedProps = useAnimatedProps(() => ({
        strokeDashoffset: CIRCUMFERENCE * (1 - animatedValue.value),
    }));

    return (
        <View style={styles.container}>
            <Svg height={avatarSize} width={avatarSize}>
                <Circle
                    cx={avatarSize / 2}
                    cy={avatarSize / 2}
                    r={RADIUS}
                    fill="none"
                    stroke={theme.mediumGrey}
                    strokeWidth={STROKE_WIDTH}
                    strokeOpacity={0.3}
                />
                <AnimatedCircle
                    cx={avatarSize / 2}
                    cy={avatarSize / 2}
                    r={RADIUS}
                    fill="none"
                    stroke={theme.main}
                    strokeWidth={STROKE_WIDTH}
                    strokeDasharray={CIRCUMFERENCE}
                    animatedProps={animatedProps}
                    opacity={0.8}
                />
            </Svg>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
    },
});

export default UploadProgress;
