import React, { ReactElement } from 'react';
import { View, StyleSheet } from 'react-native';
import { Theme, WW, useThemeStyle } from '_utils';
import { smallestMargin, subtitleFontSize } from '_utils/sizes';
import { HeimeText } from 'Components';

const DeletedMessage = ({ message, leftMargin }: { message: string; leftMargin: boolean }): ReactElement => {
    const themedStyle = useThemeStyle(style);
    return (
        <View style={[themedStyle.container, leftMargin ? themedStyle.leftMargin : undefined]}>
            <HeimeText style={themedStyle.text}>{message}</HeimeText>
        </View>
    );
};

const style = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            backgroundColor: theme.lightGrey,
            borderRadius: WW * 0.04,
            paddingLeft: smallestMargin,
            paddingRight: smallestMargin,
            paddingBottom: smallestMargin,
            paddingTop: smallestMargin,
        },
        text: {
            fontSize: subtitleFontSize,
            color: theme.darkGrey,
        },
        leftMargin: {
            marginLeft: smallestMargin,
        },
    });

export default DeletedMessage;
