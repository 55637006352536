import React, { ReactElement } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import moment from 'moment';
import { FieldError, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, FlatList } from 'react-native';
import * as z from 'zod';
import { findInitialCooperativeGroupId } from 'types/Cooperative';
import { Message, MessageSchema, MessageType, isNoticeMessage } from 'types/message';
import { AddProduct, RequestHelp, HelpNeighbour, SharingCategoryItem } from './components';
import { useCreateMessage, useEditMessage } from '../../../_api/useMessages';
import { SafeAreaView } from '../../../_dependencies/safeArea';
import _fonts from '../../../_fonts';
import { useAppNavigation } from '../../../_navigator';
import {
    Theme,
    useThemeStyle,
    useKeyboardIsVisible,
    WW,
    useIsCoopAdmin,
    useConfirmDiscard,
    isTruthy,
    useTranslatedFormErrorMessage,
    showToast,
    isWeb,
} from '../../../_utils';
import { useSelectedCoopItem } from '../../../_utils/hooks';
import { ErrorMessage, HeaderWithNav, Icon } from '../../../Components';
import { fileSchema, RoutePropsSchema, ValueOf } from '../../../types/Utility';

const RouteSchema = RoutePropsSchema(
    z.object({
        type: z.nativeEnum(MessageType),
        message: MessageSchema.optional(),
    }),
);

type SharingNewProps = z.infer<typeof RouteSchema>;

const schema = z.object({
    title: z.string().min(1).max(255),
    content: z.string().min(0),
    pictures: z.array(fileSchema).optional(),
    files: z.array(fileSchema).optional(),
    type: z.number(),
    isEditing: z.boolean(),
    id: z.onumber(),
    allow_comments: z.boolean(),
    cooperative_group_id: z.number().nullable(),
    send_notification: z.boolean(),
    apartment_groups: z.array(z.string()),
    never_expires: z.boolean(),
});

export type FormValues = z.infer<typeof schema>;

const mapToDefault = (
    initial: Message | undefined,
    initialType: MessageType,
    initialCooperativeGroup: number | null,
): FormValues => {
    const parsedInitial = initial ? MessageSchema.safeParse(initial) : null;
    if (parsedInitial && parsedInitial.success) {
        const message = parsedInitial.data;
        return {
            title: message.title,
            content: message.content ?? '',
            pictures: isNoticeMessage(message)
                ? []
                : message.pictures.map((image, index) => ({ ...image, status: 'stale', index })),
            files: isNoticeMessage(message)
                ? message.files.map((file, index) => ({ ...file, index, status: 'stale' }))
                : [],
            type: message.type,
            isEditing: true,
            id: message.id,
            cooperative_group_id: message.cooperative_group_id,
            send_notification: isNoticeMessage(message) && (message.send_notification ? true : false),
            allow_comments: isNoticeMessage(message) && message.allow_comments ? true : false,
            apartment_groups: isNoticeMessage(message) ? message.apartment_groups.map(({ id }) => id) : [],
            never_expires: isNoticeMessage(message) ? message.active_until === null : true,
        };
    }

    return {
        title: '',
        content: '',
        pictures: [],
        files: [],
        type: initialType,
        isEditing: false,
        cooperative_group_id: initialCooperativeGroup ?? null,
        send_notification: false,
        allow_comments: [MessageType.Requests, MessageType.ByBoard].includes(initialType),
        apartment_groups: [],
        never_expires: ![MessageType.Requests, MessageType.ByBoard].includes(initialType),
    };
};

const SharingNew = ({ route }: SharingNewProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate, goBack: internalGoBack } = useAppNavigation();
    const keyboardIsVisible = useKeyboardIsVisible();
    const selectedCoop = useSelectedCoopItem();

    const { isPending: isMessageCreating, mutate: createMessage } = useCreateMessage();
    const { isPending: isMessageUpdating, mutate: editMessage } = useEditMessage();
    const formValues = useForm({
        mode: 'onChange',
        criteriaMode: 'all',
        defaultValues: mapToDefault(
            route.params?.message,
            parseInt(route.params.type + '', 10),
            findInitialCooperativeGroupId(selectedCoop?.cooperative_groups ?? []),
        ),
        resolver: zodResolver(schema),
    });
    const { watch, setValue, formState } = formValues;
    const { errors } = formState;
    const [type, isEditing] = watch(['type', 'isEditing']);
    const { content } = useConfirmDiscard(formValues.formState.isDirty, isEditing, internalGoBack);

    const handleCategoryChange = (category: number) => {
        setValue('type', category);
    };

    const shouldShowBoard = useIsCoopAdmin();

    const handlePublish = (values: FormValues) => {
        const navigateTo = (messageId: number) => {
            formValues.reset();
            setTimeout(
                () => {
                    internalGoBack();
                    navigate('SharingSelected', { messageId });
                },
                isWeb() ? 50 : 0,
            );
        };
        const monthFromNow = moment().add({ month: 1 }).toDate().toISOString();
        if (route.params?.message) {
            editMessage(
                [
                    route.params?.message.id,
                    {
                        ...values,
                        active_until: values.never_expires
                            ? null
                            : 'active_until' in route.params.message && route.params.message.active_until
                              ? new Date(route.params.message.active_until).toISOString()
                              : monthFromNow,
                    },
                ],
                {
                    onSuccess: () => {
                        navigateTo(values.id as number);
                    },
                    onError: () => {
                        showToast({ header: t('sharingNew:saveError'), text: '', type: 'error', position: 'bottom' });
                    },
                },
            );
        } else {
            createMessage(
                { ...values, active_until: values.never_expires ? null : monthFromNow },
                {
                    onSuccess: ({ success }) => {
                        navigateTo(success);
                    },
                    onError: (error) => {
                        if (typeof error !== 'string' && 'response' in error && error.response?.data.message) {
                            if (
                                error.response.data.message === 'You have already sent a message in the last 24 hours'
                            ) {
                                showToast({
                                    header: t('sharingNew:publishError'),
                                    text: t('sharingNew:publishErrorNotification'),
                                    type: 'error',
                                });
                                return;
                            }
                        }
                        showToast({
                            header: t('sharingNew:publishError'),
                            text: '',
                            type: 'error',
                            position: 'bottom',
                        });
                    },
                },
            );
        }
    };

    const renderAddItem = () => {
        switch (type) {
            case MessageType.Requests:
            case MessageType.ByBoard: {
                return (
                    <RequestHelp
                        title={
                            t('sharingNew:messageCategory', {
                                boardNoun: t(
                                    `typeSpecific:${selectedCoop?.type ?? 'joint_ownership'}:boardNounDefiniteArticle`,
                                ),
                                returnObjects: true,
                            })?.[type ?? 1]?.title
                        }
                        isLoading={isMessageCreating || isMessageUpdating}
                        onSubmit={handlePublish}
                    />
                );
            }
            case MessageType.Products: {
                return <AddProduct isLoading={isMessageCreating || isMessageUpdating} onSubmit={handlePublish} />;
            }
            case MessageType.Helpers: {
                return <HelpNeighbour isLoading={isMessageCreating || isMessageUpdating} onSubmit={handlePublish} />;
            }
        }
        return null;
    };

    const isFieldError = (field: ValueOf<typeof errors>): field is FieldError => isTruthy(field) && 'type' in field;
    const errorMessage = useTranslatedFormErrorMessage(Object.values(errors).find(isFieldError));

    return (
        <SafeAreaView
            style={themedStyle.main}
            edges={[keyboardIsVisible ? undefined : ('bottom' as const), 'left' as const, 'right' as const].filter(
                isTruthy,
            )}
        >
            <HeaderWithNav title={isEditing ? t('sharingNew:titleEdit') : t('sharingNew:title')} />
            <FormProvider {...formValues}>
                <FlatList
                    contentContainerStyle={themedStyle.contentContainer}
                    data={[{}]}
                    ListHeaderComponent={
                        shouldShowBoard && [2, 3].includes(type ?? 0) ? (
                            <>
                                <Text style={themedStyle.title}>{t('sharingNew:chooseType')}</Text>
                                <SharingCategorySelector
                                    value={type}
                                    onChange={handleCategoryChange}
                                    shouldShowBoard={shouldShowBoard}
                                />
                            </>
                        ) : null
                    }
                    renderItem={renderAddItem}
                    keyExtractor={() => 'add_new_message'}
                    keyboardShouldPersistTaps="always"
                />
                {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
            </FormProvider>
            {content}
        </SafeAreaView>
    );
};

interface SharingCategorySelectorProps {
    onChange(newType: number): void;
    value: number;
    shouldShowBoard?: boolean;
}
const SharingCategorySelector = ({ onChange, value, shouldShowBoard }: SharingCategorySelectorProps): ReactElement => {
    const { t } = useTranslation();
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';

    const values = t('sharingNew:messageCategory', {
        returnObjects: true,
        boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
    });

    const keys = Object.entries(values);
    return (
        <>
            {keys.map(([category, { title, desc }]) => {
                const type = parseInt(category, 10);
                if (type === 3 && !shouldShowBoard) {
                    return null;
                }
                if (type === 4 && shouldShowBoard) {
                    return null;
                }

                if (![2, 3, 4].includes(value) && type !== value) {
                    return null;
                } else if ([2, 3, 4].includes(value) && ![2, 3, 4].includes(type)) {
                    return null;
                }

                return (
                    <SharingCategoryItem
                        key={category}
                        onPress={() => {
                            onChange(type);
                        }}
                        selected={type === value}
                        title={title}
                        desc={desc}
                        icon={
                            <Icon
                                name={
                                    type === 3
                                        ? 'users'
                                        : type === 2
                                          ? 'questionNew'
                                          : type === 0
                                            ? 'shoppingBag'
                                            : type === 4
                                              ? 'activity'
                                              : 'wrench'
                                }
                                color="darkGreen"
                            />
                        }
                    />
                );
            })}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
        contentContainer: {
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
        },
        title: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            fontFamily: _fonts.primaryFontBold,
            color: theme.black,
            fontSize: WW * 0.05,
        },
    });

export { SharingNew };
