import React from 'react';
import { ScrollView, StyleSheet, TouchableHighlight } from 'react-native';
import { useAppNavigation } from '_navigator';
import { ApiImage } from 'types/Base';
import CacheImage from './CacheImage';
import ImagePlaceholder from './ImagePlaceholder';
import { WH, WW } from '../_utils';
import { screenMargin } from '../_utils/sizes';

interface FullWidthPictureCarouselProps {
    pictures: ApiImage[];
    disablePicturePress?: boolean;
    onEditPlaceholder?: () => void;
    showPlaceholder?: boolean;
    height?: number;
}

const FullCarousel = ({
    pictures,
    onEditPlaceholder,
    disablePicturePress,
    showPlaceholder,
    height = WH * 0.3,
}: FullWidthPictureCarouselProps) => {
    const { push } = useAppNavigation();
    if (pictures.length === 0) {
        if (showPlaceholder) {
            return <ImagePlaceholder onPress={onEditPlaceholder} />;
        }
        return null;
    }

    return (
        <ScrollView
            style={[styles.container, { height }]}
            contentContainerStyle={styles.contentContainer}
            horizontal
            snapToAlignment="center"
        >
            {pictures.map((image, index) => (
                <TouchableHighlight
                    key={index}
                    onPress={() =>
                        !disablePicturePress &&
                        push('ImagePreview', {
                            images: pictures.map((p) => ({ ...p, ext: '', name: '', id: 0, last_modified: 0 })),
                            selectedIndex: index,
                        })
                    }
                    underlayColor="transparent"
                    style={styles.image}
                >
                    <CacheImage resizeMode="contain" style={styles.image} source={image as ApiImage} />
                </TouchableHighlight>
            ))}
        </ScrollView>
    );
};
const styles = StyleSheet.create({
    container: { width: WW },
    contentContainer: { display: 'flex', flexDirection: 'row', alignItems: 'stretch' },
    image: { width: WW - 2 * screenMargin, height: '100%' },
});

export default FullCarousel;
