import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Text, TouchableOpacity, StyleProp, TextStyle, ViewStyle, FlatList } from 'react-native';
import _fonts from '_fonts';
import { isTruthy, Theme, useImageSelector, useThemeStyle, WH, WW } from '_utils';
import { smallestFontSize, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { CacheImage, Icon, HeimeText } from 'Components';
import { FileUpload, mapFileUploadsToApiImage } from 'types/Utility';
import FileActionSheet from './FileActionsheet';
import useFileState from './useFileState';

interface ImageSelectorProps {
    images: FileUpload[];
    onChange: (images: FileUpload[]) => void;
    title?: string;
    optional?: boolean;
    titleStyles?: StyleProp<TextStyle>;
    style?: StyleProp<ViewStyle>;
    openInitially?: boolean;
}

const ImageSelector = ({
    images,
    onChange,
    style,
    title,
    optional = false,
    titleStyles,
    openInitially = false,
}: ImageSelectorProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { onAdd, onDelete } = useFileState(images, onChange);
    const [actionSheetOpen, setActionSheetOpen] = useState(openInitially);
    const handleCloseActionSheet = () => setActionSheetOpen(false);
    const [onSelect, permission] = useImageSelector(onAdd, handleCloseActionSheet, true);

    const handleOpenActionSheet = () => setActionSheetOpen(true);

    const renderImage = (item: FileUpload, size: 'big' | 'small') => (
        <View style={themedStyle.imageContainer}>
            <CacheImage
                resizeMode={'contain'}
                key={item.index}
                style={size === 'big' ? themedStyle.imageBig : themedStyle.imageSmall}
                source={mapFileUploadsToApiImage([item])[0]}
            />
            <TouchableOpacity onPress={() => onDelete(item)} style={themedStyle.removeIcon}>
                <Icon name="trash" color="error" />
            </TouchableOpacity>
        </View>
    );

    const renderAddImage = (size: 'big' | 'small') => (
        <View style={themedStyle.imageContainer}>
            <TouchableOpacity
                activeOpacity={1}
                onPress={handleOpenActionSheet}
                style={[themedStyle.avatarWrapper, size === 'big' ? themedStyle.imageBig : themedStyle.imageSmall]}
            >
                <View style={themedStyle.addIconWrapper}>
                    <Icon name="addImage" color="secondaryLight" />
                </View>
                {size === 'big' ? <HeimeText style={themedStyle.addText}>{t('global:addImage')}</HeimeText> : null}
            </TouchableOpacity>
        </View>
    );

    const nonDeletedImages = useMemo(() => images.filter(({ status }) => status !== 'deleted'), [images]);

    return (
        <View style={style}>
            {title && (
                <View style={themedStyle.titleWrapper}>
                    <Text style={[themedStyle.title, titleStyles]}>{title}</Text>
                    {optional && <Text style={[themedStyle.optional, titleStyles]}>{t('global:optional')}</Text>}
                </View>
            )}
            {nonDeletedImages.length > 0 ? renderImage(nonDeletedImages[0], 'big') : renderAddImage('big')}
            <FlatList
                numColumns={3}
                data={[
                    ...[...nonDeletedImages].slice(1),
                    nonDeletedImages.length > 0 ? { type: 'addImage' as const } : null,
                ].filter(isTruthy)}
                renderItem={({ item }: { item: FileUpload | { type: 'addImage' } }) => {
                    if ((item as { type: string }).type === 'addImage') {
                        return renderAddImage('small');
                    }

                    return renderImage(item as FileUpload, 'small');
                }}
                keyExtractor={(item) => (item as FileUpload).index + ''}
            />
            {actionSheetOpen ? (
                <FileActionSheet
                    actions={[
                        {
                            type: 'Library' as const,
                            onSelect: () => onSelect({ cropping: false, type: 'library' }),
                        },
                        { type: 'Camera' as const, onSelect: () => onSelect({ cropping: false, type: 'camera' }) },
                    ]}
                    onClose={handleCloseActionSheet}
                />
            ) : null}
            {permission}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        titleWrapper: { flexDirection: 'row' },
        title: {
            fontFamily: _fonts.primaryFontBold,
            fontSize: smallestFontSize,
            marginTop: WH * 0.01,
            marginBottom: WH * 0.01,
            color: theme.black,
        },
        optional: {
            fontFamily: _fonts.primaryFont,
            fontSize: smallestFontSize,
            color: theme.secondaryText,
            marginLeft: WW * 0.01,
            marginRight: WW * 0.01,
            marginTop: WH * 0.01,
            marginBottom: WH * 0.01,
        },
        imageSmall: {
            width: WW * 0.28,
            height: WW * 0.28,
            marginTop: WW * 0.04,
            marginRight: WW * 0.04,
        },
        imageBig: {
            width: WW * 0.92,
            height: WW * 0.7,
        },
        avatarWrapper: {
            justifyContent: 'center',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.mediumBackground,
        },
        addIconWrapper: {
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            backgroundColor: theme.mainBackground,
            borderRadius: 50,
        },
        addText: {
            fontSize: subtitleFontSize,
            color: theme.black,
            marginTop: smallestMargin,
        },
        removeIcon: {
            position: 'absolute',
            right: WW * 0.05,
            top: WW * 0.05,
            borderRadius: 50,
            backgroundColor: theme.mainBackground,
            paddingTop: WW * 0.015,
            paddingBottom: WW * 0.015,
            paddingLeft: WW * 0.015,
            paddingRight: WW * 0.015,
        },
        imageContainer: {
            display: 'flex',
            alignItems: 'center',
        },
    });

export default ImageSelector;
