import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { smallestMargin, subtitleFontSize, subtitleLineHeight } from '_utils/sizes';
import { CheckBox, HeimeText } from 'Components';
import { Procedure } from 'types/Procedures';
import { BottomButtons, ContentDisplay } from './common';

type ChecklistAction = Extract<Procedure['actions'][number], { type: 'CHECKLIST' }>;

const Checklist = ({
    onSkip,
    onContinue,
    action,
    isContinuing,
}: {
    onSkip(): void;
    onContinue(): void;
    action: ChecklistAction;
    isContinuing: boolean;
}) => {
    const { watch, setValue } = useFormContext();
    const { t } = useTranslation();
    const values = watch('options');
    const allChecked = !action.options.find((check, index) => !values[index]);

    return (
        <ContentDisplay
            content={
                <ScrollView bounces={false} contentContainerStyle={{ gap: smallestMargin }}>
                    <HeimeText variant="title">{t('procedure:checklist_header')}</HeimeText>
                    <HeimeText linkify variant="subtitle">
                        {action.description}
                    </HeimeText>
                    <View style={{ gap: smallestMargin }}>
                        {action.options.map((check, index) => (
                            <CheckBox
                                checked={Boolean(values[index])}
                                title={check.value}
                                titleStyle={{ fontSize: subtitleFontSize, lineHeight: subtitleLineHeight }}
                                onPress={() => {
                                    setValue(`options.${index}`, !values[index]);
                                }}
                                key={check.id}
                            />
                        ))}
                    </View>
                </ScrollView>
            }
            buttons={
                <BottomButtons
                    buttons={[
                        {
                            text: t('procedure:continue'),
                            onPress: onContinue,
                            isDisabled: !allChecked,
                            isLoading: isContinuing,
                        },
                        {
                            type: 'secondary',
                            text: t('procedure:skip'),
                            onPress: onSkip,
                            isLoading: isContinuing,
                        },
                    ]}
                />
            }
        />
    );
};

export default Checklist;
