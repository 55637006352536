import React, { ReactElement, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import SVG, { Defs, Rect, LinearGradient, Stop } from 'react-native-svg';
import { ThemeContext } from '_utils/themeContext';

const FROM_COLOR = 'rgba(255, 255, 255, 255)';

interface LinearGradientProps {
    children: ReactElement;
}

const Gradient = ({ children }: LinearGradientProps): ReactElement => {
    const { theme } = useContext(ThemeContext);
    return (
        <View style={styles.main}>
            {children}
            <SVG height="100%" width="100%" style={StyleSheet.absoluteFillObject}>
                <Defs>
                    <LinearGradient id="grad" x1="0%" y1="70%" x2="0%" y2="100%">
                        <Stop offset="0" stopOpacity={0} stopColor={FROM_COLOR} />
                        <Stop offset="1" stopColor={theme.main} />
                    </LinearGradient>
                </Defs>
                <Rect width="100%" height="100%" fill="url(#grad)" />
            </SVG>
        </View>
    );
};

const styles = StyleSheet.create({
    main: { flex: 1 },
});

export default Gradient;
