import {
    cancelAllNotificationsType,
    cancelNotificationType,
    getDisplayedNotificationsType,
    setBadgeCountType,
} from './notifeeExport';

export const setBadgeCount: setBadgeCountType = () => {};

export const cancelAllNotifications: cancelAllNotificationsType = () => {};

export const cancelNotification: cancelNotificationType = () => {};

export const getDisplayedNotifications: getDisplayedNotificationsType = () => Promise.resolve([]);
