import React, { ReactElement, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import { Menu, MenuOptions, MenuTrigger, renderers } from 'react-native-popup-menu';
import { useOwnProfile } from '_api/useProfile';
import { useGetUser } from '_api/useUsers';
import { WW, useRerenderCountdown, openURL, useChatWithNeighbour } from '_utils';
import { screenMargin, smallestFontSize, smallestMargin, smallestMarginPixels, subtitleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { HeimeText, Icon, LabelValue, Loader, UserListItem } from 'Components';
import { OwnReservation } from 'types/Reservation';
import { isNonDeletedUser } from 'types/User';
import ReservationDurationView from './components/ReservationDurationView';

const ReservationDetail = ({
    start_at,
    end_at,
    reason,
    booked,
    quantity,
    previous_users,
}: Pick<OwnReservation, 'end_at' | 'start_at' | 'reason' | 'booked' | 'quantity' | 'previous_users'>): ReactElement => {
    const { t } = useTranslation();
    const start = moment.unix(start_at);

    const { theme } = useThemeContext();

    useRerenderCountdown(start, true);

    return (
        <>
            {booked.capacity > 1 ? (
                <HeimeText variant="subtitle">{t('expandedReservation:quantity', { count: quantity })}</HeimeText>
            ) : null}
            <ReservationDurationView start_at={start_at} end_at={end_at} locksLength={(booked.locks ?? []).length} />
            {previous_users && previous_users.users.length > 0 ? (
                <View>
                    <View style={{ flexDirection: 'row', gap: smallestMargin }}>
                        <HeimeText style={{ fontWeight: 'bold', color: theme.black, fontSize: subtitleFontSize }}>
                            {t('expandedReservation:previous_visitor')}
                        </HeimeText>
                        <InformationBubble />
                    </View>
                    {previous_users.users.map((user) => (
                        <PreviousUser key={user} userId={user} />
                    ))}
                </View>
            ) : null}
            {reason ? (
                <>
                    <Divider />
                    <LabelValue
                        switched
                        orientation={'vertical'}
                        label={t('expandedReservation:reason')}
                        value={reason}
                        labelStyle={{ marginBottom: smallestMargin }}
                        style={{ marginBottom: smallestMargin }}
                    />
                </>
            ) : null}
        </>
    );
};

const Divider = () => {
    const { theme } = useThemeContext();
    return (
        <View
            style={{ width: '100%', borderWidth: 1, borderColor: theme.secondaryLight, marginBottom: smallestMargin }}
        />
    );
};

const PreviousUser = ({ userId }: { userId: number }) => {
    const user = useGetUser(userId);
    const { data: profile } = useOwnProfile();
    const { t } = useTranslation();
    const { isLoading, onChat } = useChatWithNeighbour();
    const { theme } = useThemeContext();

    const handleChatWithNeighbour = () => onChat(userId);
    return (
        <UserListItem
            id={userId}
            actionButton={
                user && isNonDeletedUser(user) && userId !== profile?.id ? (
                    <View style={{ flexDirection: 'row', gap: smallestMargin, alignItems: 'center' }}>
                        {user?.hide_phone || !user?.phone ? null : (
                            <TouchableOpacity
                                accessibilityLabel={t('profile:call')}
                                style={{
                                    backgroundColor: theme.main,
                                    borderRadius: WW * 0.5,
                                    padding: smallestMarginPixels * 1.5,
                                }}
                                onPress={() => openURL(`tel:${user.phone}`, t)}
                            >
                                <Icon scale={1.2} name="call" color="white" />
                            </TouchableOpacity>
                        )}

                        <TouchableOpacity
                            accessibilityLabel={t('profile:message')}
                            onPress={handleChatWithNeighbour}
                            style={{
                                backgroundColor: theme.main,
                                borderRadius: WW * 0.5,
                                padding: smallestMarginPixels * 1.5,
                            }}
                        >
                            {isLoading ? (
                                <Loader bgColor="transparent" />
                            ) : (
                                <Icon scale={1.2} name="message" color="white" />
                            )}
                        </TouchableOpacity>
                    </View>
                ) : undefined
            }
        />
    );
};

const InformationBubble = () => {
    const [isOpen, setIsOpen] = useState(false);
    const onToggle = () => setIsOpen((prev) => !prev);
    const { t } = useTranslation();
    const { theme } = useThemeContext();

    return (
        <Menu
            renderer={renderers.Popover}
            rendererProps={{
                placement: 'auto',
                preferredPlacement: 'top',
                anchorStyle: { backgroundColor: theme.main },
            }}
            opened={isOpen}
            onBackdropPress={onToggle}
        >
            <MenuTrigger onPress={onToggle}>
                <TouchableOpacity
                    onPress={onToggle}
                    accessibilityLabel={t('expandedReservation:previous_visitor_action')}
                    hitSlop={16}
                >
                    <Icon name="question" color="main" />
                </TouchableOpacity>
            </MenuTrigger>
            <MenuOptions
                customStyles={{
                    optionsContainer: {
                        backgroundColor: theme.main,
                        borderRadius: screenMargin,
                        padding: smallestMargin,
                    },
                }}
            >
                <View style={{ flexDirection: 'row' }}>
                    <HeimeText
                        style={{
                            fontSize: smallestFontSize,
                            lineHeight: smallestFontSize * 1.5,
                            color: theme.white,
                            flexShrink: 1,
                        }}
                        maxFontSizeMultiplier={4}
                    >
                        {t('expandedReservation:previous_visitor_info')}
                    </HeimeText>
                    <TouchableOpacity
                        accessibilityLabel={t('global:close')}
                        onPress={onToggle}
                        style={{ marginLeft: 'auto', marginTop: -smallestMargin }}
                    >
                        <Icon name="cross" color="white" scale={0.6} />
                    </TouchableOpacity>
                </View>
            </MenuOptions>
        </Menu>
    );
};

export default ReservationDetail;
