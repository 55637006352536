import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import { changeIcon, getIcon } from 'react-native-change-icon';
import ChooseCoop from './ChooseCoop';
import { useSetSelectedCoop } from './hooks';
import { useCoops } from '../_api/useCoops';
import { isiOS, isWeb, useGlobalState } from '../_utils';
import { captureException } from '../_utils/Sentry';
import SimpleLoadScreen from '../Screens/SimpleLoadScreen';

const SelectedCoopGate = ({ children }: PropsWithChildren<unknown>): ReactElement | null => {
    const selectedCoop = useGlobalState((state) => state.selectedCoop);

    const { data, isLoading, error } = useCoops();

    const selectCoop = useSetSelectedCoop();

    useEffect(() => {
        if (isLoading || !data) {
            return;
        }
        if (data.length === 1) {
            selectCoop(data[0].id);
        }
    }, [data, isLoading, selectCoop]);

    useEffect(() => {
        if (selectedCoop) {
            const selectedCoopItem = data?.find(({ id }) => id === selectedCoop);
            if (selectedCoopItem) {
                if (!isWeb()) {
                    getIcon().then((existing) => {
                        let newIcon: string | null = 'default';

                        switch (selectedCoopItem.white_label) {
                            case 'bakkekontakt':
                                newIcon = isiOS() ? 'Bakke' : 'bakke';
                                break;
                            case 'madlavest':
                                newIcon = isiOS() ? 'Madlavest' : 'madlavest';
                                break;
                            case 'leangen':
                                newIcon = isiOS() ? 'Leangen' : 'leangen';
                                break;
                            case 'vervet':
                                newIcon = isiOS() ? 'Vervet' : 'vervet';
                                break;
                            case 'fyrstikkbakken':
                                newIcon = 'Fyrstikkbakken';
                                break;
                            default:
                                if (existing !== 'default') {
                                    newIcon = isiOS() ? null : 'heime';
                                } else {
                                    return;
                                }
                                break;
                        }

                        if (newIcon !== existing) {
                            changeIcon(newIcon).catch(captureException);
                        }
                    });
                }
            } else {
                if (data?.length === 1) {
                    selectCoop(data[0].id);
                } else {
                    selectCoop(null);
                }
            }
        }
    }, [data, selectedCoop, selectCoop]);

    if (isLoading) {
        return <SimpleLoadScreen />;
    }

    if (!data) {
        throw error;
    }

    if (!selectedCoop) {
        if (data.length > 1) {
            return <ChooseCoop />;
        } else {
            // Wait for useEffect to fix this state
            return <SimpleLoadScreen />;
        }
    }
    return <>{children}</>;
};

export default SelectedCoopGate;
