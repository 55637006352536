import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import Images from '_images';
import { screenMargin, smallestFontSize, smallestMargin } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { CacheImage, HeimeText } from 'Components';
import { ExternalUserProfile } from 'types/User';

interface InterestedUsersProps {
    isLoading: boolean;
    users: ExternalUserProfile[];
}

const InterestedUsers: React.FC<InterestedUsersProps> = ({ isLoading, users }) => {
    const { t } = useTranslation();
    const { theme } = useThemeContext();

    // Sort users with avatar first
    const imageUsers = users.sort((a, b) => (a.avatar ? -1 : b.avatar ? 1 : 0));
    const namedUsers = [...users];

    const getUsersInterestedText = () => {
        const count = users.length;
        if (count === 0) {
            return '';
        } else if (count === 1) {
            return t('activity:suggestions:one_interested', { fname: namedUsers[0].fname });
        } else if (count === 2) {
            return t('activity:suggestions:two_interested', {
                fname1: namedUsers[0].fname,
                fname2: namedUsers[1].fname,
            });
        } else if (count === 3 || count === 4) {
            const names = namedUsers
                .slice(0, -1)
                .map((user) => user.fname)
                .join(', ');
            return t('activity:suggestions:three_or_four_interested', { names, fname: namedUsers[count - 1].fname });
        } else if (count >= 5) {
            return t('activity:suggestions:many_interested', { fname: namedUsers[0].fname, count: count - 1 });
        }
        return '';
    };
    const avatarStyle = {
        width: 25,
        height: 25,
        marginLeft: -5,
        borderWidth: 1,
        borderColor: theme.mainBackground,
        backgroundColor: theme.mainBackground,
        borderRadius: 20,
        overflow: 'hidden' as const,
    };

    return (
        <View
            style={{
                marginHorizontal: screenMargin,
                gap: smallestMargin,
                alignItems: 'center',
                flexDirection: 'row',
            }}
        >
            {isLoading ? (
                <>
                    <View style={{ flexDirection: 'row' }}>
                        {Array.from({ length: 5 }).map((_, index) => (
                            <View
                                key={index}
                                style={[
                                    avatarStyle,
                                    {
                                        backgroundColor: [theme.darkGray, theme.mediumGrey][index % 2],
                                    },
                                ]}
                            />
                        ))}
                    </View>
                    <HeimeText variant="subtitle" style={{ fontSize: smallestFontSize }}>
                        {t('global:loading')}
                    </HeimeText>
                </>
            ) : (
                <>
                    <View style={{ flexDirection: 'row' }}>
                        {imageUsers.slice(0, 5).map((user) => (
                            <View key={user.id} style={avatarStyle}>
                                <CacheImage
                                    source={user.avatar ?? Images.defaultAvatar}
                                    style={{ width: 25, height: 25 }}
                                />
                            </View>
                        ))}
                    </View>
                    {imageUsers.length > 0 && (
                        <HeimeText variant="subtitle" style={{ fontSize: smallestFontSize }}>
                            {getUsersInterestedText()}
                        </HeimeText>
                    )}
                </>
            )}
        </View>
    );
};

export default InterestedUsers;
