import React, { ReactElement } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useThemeStyle, WW, Theme } from '_utils';
import FileIcon from 'Components/Icon/FileIcon';
import { FileLink } from 'types/Base';
import { smallestFontSize } from '../../../_utils/sizes';
import CacheImage from '../../CacheImage';
import HeimeText from '../../HeimeText';
import Icon from '../../Icon/Icon';

interface AttachmentBubbleProps {
    file: FileLink;
    type?: 'media' | 'files';
    onPress: () => void;
    isFirst?: boolean;
    onLongPress: () => void;
}

const AttachmentBubble = ({
    file,
    type = 'files',
    onPress,
    isFirst,
    onLongPress,
}: AttachmentBubbleProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);

    return (
        <TouchableOpacity onLongPress={onLongPress} onPress={onPress} style={themedStyle.main}>
            {type === 'files' ? (
                <>
                    <View style={themedStyle.previewImageWrapper}>
                        <FileIcon fileName={file.name} />
                    </View>
                    <View style={[themedStyle.contentMain, isFirst ? themedStyle.contentMainNotSecond : undefined]}>
                        <View style={themedStyle.content}>
                            <View style={themedStyle.previewContent}>
                                <HeimeText numberOfLines={1} style={themedStyle.title}>
                                    {file.name}
                                </HeimeText>
                            </View>
                            <Icon name="chevron" color="mediumGrey" />
                        </View>
                    </View>
                </>
            ) : (
                <View
                    style={[
                        themedStyle.contentMain,
                        {
                            paddingLeft: WW * 0,
                            borderBottomWidth: WW * 0,
                        },
                        themedStyle.contentMainNotSecond,
                    ]}
                >
                    <CacheImage
                        style={{ width: WW * 0.75, height: WW * 0.75 }}
                        source={{ original: file.original ?? '' }}
                    />
                </View>
            )}
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            width: WW * 0.75,
            flexDirection: 'row',
        },
        contentMain: {
            flex: 1,
            borderWidth: 1,
            borderColor: theme.lightGrey,
            paddingLeft: WW * 0.02,
            backgroundColor: theme.mainBackground,
        },
        contentMainNotSecond: {
            borderTopRightRadius: 24,
        },
        previewImageWrapper: {
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.lightGreen,
            width: WW * 0.25,
            height: WW * 0.25,
        },
        title: {
            fontSize: smallestFontSize,
            color: theme.darkGreen,
        },
        content: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: WW * 0.03,
            paddingBottom: WW * 0.03,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
        },
        previewContent: { flex: 1 },
    });

export default AttachmentBubble;
