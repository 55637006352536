import React, { ReactElement } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { z } from 'zod';
import { screenMargin, subtitleFontSize } from '_utils/sizes';
import { AboutMe, ErrorText } from './components';
import { useOwnProfile, useUpdateProfile } from '../../_api/useProfile';
import { KeyboardAwareScrollView } from '../../_dependencies/keyboardAware';
import { SafeAreaView } from '../../_dependencies/safeArea';
import { useAppNavigation } from '../../_navigator';
import { Theme, useThemeStyle, useConfirmDiscard, showToast, isAppError, isNetworkError } from '../../_utils';
import { HeaderWithNav, PrimaryButton } from '../../Components';
import { OwnUserProfile } from '../../types/User';

const mapToDefault = (profile: OwnUserProfile): FormValues => ({
    aboutMe: profile.about_me,
});

const schema = z.object({
    aboutMe: z.string(),
});

type FormValues = z.infer<typeof schema>;

const ProfileAboutMe = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    // Expected to be loaded at this point
    const { data: profile } = useOwnProfile();
    const { mutate: updateProfile, isPending: isUpdatingProfile } = useUpdateProfile();
    const { goBack: internalGoBack } = useAppNavigation();

    const { formState, watch, setValue, handleSubmit, setError, ...methods } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: mapToDefault(profile as OwnUserProfile),
        resolver: zodResolver(schema),
    });

    const { content } = useConfirmDiscard(formState.isDirty && !isUpdatingProfile, true, internalGoBack);

    const handleContinue = handleSubmit((formValue: FormValues) => {
        updateProfile(
            {
                about_me: formValue.aboutMe,
            },
            {
                onSuccess: () => {
                    internalGoBack();
                },
                onError: (e) => {
                    if (isAppError<FormValues>(e)) {
                        if (isNetworkError(e)) {
                            return;
                        }
                    }

                    showToast({
                        type: 'error',
                        header: t('global:error_saving_header'),
                        text: t('global:error_saving_description'),
                        position: 'bottom',
                    });
                },
            },
        );
    });

    const errorMessage = Object.values(formState.errors).reduce((curr, val) => (curr ? curr : (val.message ?? '')), '');

    return (
        <SafeAreaView style={themedStyle.safeAreaView} edges={['bottom', 'left', 'right']}>
            <HeaderWithNav title={t('profileEdit:about_me_title')} />
            <KeyboardAwareScrollView style={themedStyle.body}>
                <FormProvider
                    setValue={setValue}
                    watch={watch}
                    formState={formState}
                    handleSubmit={handleSubmit}
                    setError={setError}
                    {...methods}
                >
                    <AboutMe autoFocus />
                </FormProvider>
            </KeyboardAwareScrollView>
            <View>
                {errorMessage ? <ErrorText>{t(errorMessage as 'global:noLang')}</ErrorText> : undefined}
                <PrimaryButton
                    status={isUpdatingProfile ? 'loading' : errorMessage ? 'disabled' : null}
                    bottomAction="modal"
                    onPress={handleContinue}
                    text={t('profileEdit:submit').toUpperCase()}
                />
            </View>
            {content}
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        safeAreaView: {
            display: 'flex',
            height: '100%',
            backgroundColor: theme.mainBackground,
        },
        body: {
            paddingRight: 16,
            paddingLeft: 16,
            flex: 1,
        },
        inputSpacer: { marginBottom: screenMargin },
        inputPrefix: {
            fontSize: subtitleFontSize,
            color: theme.black,
        },
    });

export default ProfileAboutMe;
