import { AppState, AppStateStatus } from 'react-native';

const STATUSES: Record<AppStateStatus, boolean> = {
    active: true,
    background: false,
    inactive: false,
    unknown: false,
    extension: false,
};

const isAppStateActive = (): boolean => {
    const state = AppState.currentState;

    return STATUSES[state];
};

export default isAppStateActive;
