import React, { ComponentProps, ReactElement, useState, useContext } from 'react';
import { DateTimePickerEvent } from '@react-native-community/datetimepicker';
import moment from 'moment';
import { FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, StyleProp, TextStyle, ViewStyle, TouchableOpacity } from 'react-native';
import ControlledInput from './ControlledInput';
import DateTimePickerModal from './DateTimePickerModal';
import SelectorChip from './SelectorChip';
import _fonts from '../_fonts';
import { isiOS, useThemeStyle, WW, Theme, isAndroid } from '../_utils';
import { screenMargin } from '../_utils/sizes';
import { ThemeContext } from '../_utils/themeContext';

interface FormDateTimeInputProps<FormType> {
    name: Path<FormType>;
    label?: string;
    defaultValue: Date;
    mode: ComponentProps<typeof DateTimePickerModal>['mode'];
    minDate?: Date;
    maxDate?: Date;
    labelStyles?: StyleProp<TextStyle>;
    view?: 'input' | 'chip';
    containerStyle?: StyleProp<ViewStyle>;
    rightImage?: ComponentProps<typeof ControlledInput>['rightImage'];
}

const FormDateTimeInput = <FormType extends FieldValues = Record<string, string>>({
    name,
    label,
    defaultValue,
    mode,
    minDate = isAndroid() ? moment('1900-01-01').toDate() : undefined,
    maxDate,
    labelStyles,
    view = 'chip',
    containerStyle,
    rightImage,
}: FormDateTimeInputProps<FormType>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const { field } = useController({ name });
    const [isModalOpen, setModalIsOpen] = useState(false);

    const handleOpen = () => setModalIsOpen(true);
    const handleChange = (newDate: Date | undefined) => {
        if (newDate) {
            const curr = moment(field.value || defaultValue).startOf('minute');
            let result = moment(newDate);
            if (mode === 'time') {
                result = curr.set({ hours: newDate.getHours(), minutes: newDate.getMinutes() });
            } else if (mode === 'date') {
                result = curr.set({ year: newDate.getFullYear(), month: newDate.getMonth(), date: newDate.getDate() });
            }
            field.onChange(result.toDate());
        }
    };
    const handleConfirm = (newDate: Date | undefined) => {
        setModalIsOpen(false);
        handleChange(newDate);
    };

    const handleCancel = () => setModalIsOpen(false);
    const getFormattedValue = (val: Date) => moment(val).format(mode === 'time' ? 'HH:mm' : 'DD MMM YYYY');

    return (
        <>
            {view === 'input' ? (
                <TouchableOpacity onPress={handleOpen}>
                    <View pointerEvents="none">
                        <ControlledInput
                            label={label ?? ''}
                            value={field.value ? getFormattedValue(field.value) : ''}
                            onChange={() => {}}
                            containerStyle={containerStyle}
                            onTouchStart={handleOpen}
                            placeholder={getFormattedValue(defaultValue)}
                            rightImage={rightImage}
                        />
                    </View>
                </TouchableOpacity>
            ) : (
                <View
                    style={[
                        themedStyle.container,
                        view === 'chip' ? themedStyle.containerInline : undefined,
                        containerStyle,
                    ]}
                >
                    {label ? <Text style={[themedStyle.label, labelStyles]}>{label}</Text> : undefined}

                    <SelectorChip
                        title={getFormattedValue(field.value ?? defaultValue)}
                        variant="WhitePrimary"
                        onSelect={handleOpen}
                        containerStyle={[
                            themedStyle.chip,
                            {
                                borderColor: field.value ? theme.main : theme.secondaryLight,
                            },
                        ]}
                    />
                </View>
            )}

            {isModalOpen ? (
                <DateTimePickerModal
                    isVisible
                    mode={mode === 'datetime' && !isiOS() ? 'date' : mode}
                    onChange={
                        handleChange as ((d: Date) => void) & ((d: DateTimePickerEvent, da?: Date | undefined) => void)
                    }
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    date={field.value ?? defaultValue}
                    is24Hour
                    display={
                        mode === 'time'
                            ? 'spinner'
                            : mode === 'date'
                              ? 'spinner'
                              : isiOS()
                                ? ('inline' as 'default')
                                : 'default'
                    }
                    minuteInterval={5}
                    cancelTextIOS={t('global:cancel')}
                    confirmTextIOS={t('global:confirmWithLabel', { label: (label ?? '').toLocaleLowerCase() })}
                    minimumDate={minDate}
                    maximumDate={maxDate}
                />
            ) : null}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: { display: 'flex', marginTop: screenMargin / 2 },
        containerInline: { alignItems: 'center', flexDirection: 'row' },
        label: {
            color: theme.black,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.04,
        },
        chip: { marginRight: 'auto', marginLeft: screenMargin },
    });

export default FormDateTimeInput;
