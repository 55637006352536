import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { z } from 'zod';
import { suggestions, useActivityInterests, useRegisterInterest } from '_api/useActivityInterest';
import { useOwnProfile } from '_api/useProfile';
import { useFindUser } from '_api/useUsers';
import Images from '_images';
import { useAppNavigation } from '_navigator';
import { WH } from '_utils';
import { track } from '_utils/Amplitude';
import useSurveyAfterNavigation from '_utils/hooks/useSurveyAfterNavigation';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { CacheImage, HeaderWithNav, HeimeText, PrimaryButton, QueryItemView, SecondaryButton } from 'Components';
import { isNonDeletedUser } from 'types/User';
import { RoutePropsSchema } from 'types/Utility';
import InterestedUsers from './InterestedUsers';

const routeParams = RoutePropsSchema(
    z.object({
        suggestion: z.enum(suggestions),
        arrangeText: z.enum(['i_arrange', 'ask_neighbors']).optional(),
    }),
);

const defaultArrangeText = Math.random() > 0.5 ? 'i_arrange' : 'ask_neighbors';

const ActivitySuggestionSelected = ({
    route: {
        params: { suggestion, arrangeText = defaultArrangeText },
    },
}: z.infer<typeof routeParams>): ReactElement => {
    const { t } = useTranslation();
    const { theme } = useThemeContext();
    const insets = useSafeAreaInsets();
    const { navigate, goBack } = useAppNavigation();
    const { data: profile } = useOwnProfile();
    const findUser = useFindUser();

    const { data: interests, isLoading: isLoadingInterests, refetch, isRefetching } = useActivityInterests();
    const { mutate: registerInterest, isPending: isRegisteringInterest } = useRegisterInterest();

    const isInterested = Object.entries(interests ?? {})
        .find(([interest]) => interest === suggestion)?.[1]
        ?.some((userId) => userId === profile?.id);

    const { content } = useSurveyAfterNavigation('activitySuggestion', true, goBack);

    useEffect(() => {
        track('Viewing activity suggestion', { suggestion, arrangeText });
    }, [arrangeText, suggestion]);

    const handleInterest = () => {
        track('Pressed interested to attend', { suggestion });
        registerInterest(suggestion);
    };

    const handleArrange = () => {
        track('Pressed wants to arrange', { suggestion, arrangeText });
        navigate('ActivityNew', {
            template: suggestion,
        });
    };

    const interestedUsers =
        Object.entries(interests ?? {})
            .find(([interest]) => interest === suggestion)?.[1]
            ?.map((userId) => findUser(userId))
            .filter(isNonDeletedUser) ?? [];

    return (
        <View style={{ height: '100%', backgroundColor: theme.mainBackground }}>
            <HeaderWithNav title={t(`activity:suggestions:${suggestion}_title`)} />
            <QueryItemView
                style={{ height: '100%', flex: 1 }}
                containerStyle={{
                    paddingHorizontal: screenMargin,
                    gap: screenMargin,
                    paddingBottom: screenMargin,
                }}
                isRefreshing={isRefetching}
                onRefresh={refetch}
            >
                <CacheImage
                    source={Images[suggestion]}
                    style={{ width: '100%', height: WH * 0.3 }}
                    resizeMode="cover"
                />
                <InterestedUsers isLoading={isLoadingInterests} users={interestedUsers} />
                <HeimeText variant="title">{t(`activity:suggestions:${suggestion}_title`)}</HeimeText>
                <HeimeText variant="subtitle">{t(`activity:suggestions:${suggestion}_description`)}</HeimeText>
                <HeimeText variant="subtitle">{t(`activity:suggestions:${suggestion}_arrange_description`)}</HeimeText>
                {suggestion !== 'other' ? (
                    <View
                        style={{
                            backgroundColor: theme.chatMyMessageBubble,
                            borderRadius: screenMargin,
                            padding: screenMargin,
                        }}
                    >
                        <HeimeText variant="subtitle" style={{ fontWeight: 'bold', color: theme.darkGreen }}>
                            {t('activity:suggestions:template_example')}:
                        </HeimeText>
                        <HeimeText variant="subtitle" style={{ color: theme.darkGreen }}>
                            {t(`activity:suggestions:${suggestion}_arrange_description_example`)}
                        </HeimeText>
                    </View>
                ) : null}
            </QueryItemView>
            <View
                style={{
                    paddingBottom: Math.max(insets.bottom, screenMargin),
                    gap: smallestMargin,
                    paddingHorizontal: screenMargin,
                }}
            >
                {suggestion === 'other' ? null : (
                    <PrimaryButton
                        text={isInterested ? t('activity:suggestions:interested') : t('activity:suggestions:interest')}
                        onPress={handleInterest}
                        status={
                            isLoadingInterests || isRegisteringInterest ? 'loading' : isInterested ? 'disabled' : null
                        }
                    />
                )}
                <SecondaryButton
                    text={
                        arrangeText === 'i_arrange'
                            ? t('activity:suggestions:arrange')
                            : t('activity:suggestions:interested_ask_neighbors')
                    }
                    onPress={handleArrange}
                />
            </View>
            {content}
        </View>
    );
};

export default ActivitySuggestionSelected;
