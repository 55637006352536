import React, { ComponentProps, ReactElement } from 'react';
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import Icon from './Icon/Icon';
import _fonts from '../_fonts';
import { Theme, useThemeStyle, WW } from '../_utils';
import { smallestFontSize, subtitleFontSize } from '../_utils/sizes';

interface MyThingsButtonProps {
    onPress: () => void;
    title: string;
    message: string;
    icon: ComponentProps<typeof Icon>['name'];
    mainTitle?: boolean;
}

const MyThingsButton = ({ onPress, title, message, icon, mainTitle }: MyThingsButtonProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);

    return (
        <TouchableOpacity onPress={onPress} style={themedStyle.messageContainer}>
            <View style={themedStyle.iconWrapper}>
                <Icon name={icon} color="secondary" />
            </View>
            <View style={themedStyle.messageContent}>
                <Text style={[themedStyle.title, mainTitle ? themedStyle.mainTitle : undefined]}>{title}</Text>
                <Text style={themedStyle.subTitle}>{message}</Text>
            </View>
            <Icon name="chevron" color="black" />
        </TouchableOpacity>
    );
};
const styles = (theme: Theme) =>
    StyleSheet.create({
        messageContainer: {
            borderBottomColor: theme.lightGrey,
            borderTopColor: theme.lightGrey,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            alignItems: 'center',
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            flexDirection: 'row',
        },
        messageContent: {
            flex: 1,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
        },
        iconWrapper: {
            backgroundColor: theme.lightMustard,
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            borderRadius: WW * 0.1,
        },
        title: {
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            color: theme.black,
        },
        subTitle: {
            fontFamily: _fonts.primaryFont,
            fontSize: smallestFontSize,
            color: theme.secondary,
        },
        mainTitle: { color: theme.main, fontWeight: 'bold', fontSize: subtitleFontSize },
    });

export default MyThingsButton;
