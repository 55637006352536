import React, { ReactElement, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { dismissRecommendedAppVersion, resetRecommendedAppVersion } from '_redux/modals';
import { screenMargin, smallestMargin } from '_utils/sizes';
import DividedImageModal from './components/DividedImageModal';
import { useSettings } from '../../_api';
import { APP_VERSION } from '../../_constants';
import { isiOS, isWeb, openURL, useGlobalState, useAppDispatch, WW, WH } from '../../_utils';
import { captureException } from '../../_utils/Sentry';
import { HeimeText, MainLogo, PrimaryButton, SecondaryButton } from '../../Components';

const isNewerVersion = (value: string, comparable: string) => {
    const valueNumbers = value.split('.').map((curr) => parseInt(curr, 10));
    const comparableNumbers = comparable.split('.').map((curr) => parseInt(curr, 10));
    for (let index = 0; index < Math.min(valueNumbers.length, comparableNumbers.length); index++) {
        if (valueNumbers[index] > comparableNumbers[index]) {
            return true;
        }
    }
    return false;
};

const UpdateAppAlert = (): ReactElement | null => {
    const { t } = useTranslation();
    const { data: settings } = useSettings();
    const dispatch = useAppDispatch();
    const recommendedAppVersionDismissedAt = useGlobalState(
        (state) => state.addProfileModal.recommendedAppVersionDismissedAt,
    );

    const showModal = useMemo(() => {
        try {
            if (!APP_VERSION) {
                return null;
            }
            if (settings?.app_version && isNewerVersion(settings.app_version, APP_VERSION)) {
                return 'force';
            }
            if (settings?.recommended_app_version && isNewerVersion(settings.recommended_app_version, APP_VERSION)) {
                return 'recommended';
            }
            return null;
        } catch (e: unknown) {
            captureException(e);
            return null;
        }
    }, [settings?.app_version, settings?.recommended_app_version]);

    useEffect(() => {
        if (!showModal) {
            dispatch(resetRecommendedAppVersion());
        }
    }, [dispatch, showModal]);

    if (
        !showModal ||
        isWeb() ||
        (showModal === 'recommended' &&
            recommendedAppVersionDismissedAt &&
            moment.unix(recommendedAppVersionDismissedAt).isAfter(moment().subtract({ day: 1 })))
    ) {
        return null;
    }

    const handleDismissClick = () => {
        if (showModal === 'recommended') {
            dispatch(dismissRecommendedAppVersion(moment().unix()));
        }
    };
    const handleGoToStore = () => {
        if (isiOS()) {
            openURL('itms-apps://apps.apple.com/id/app/halojasa/id1545319598?l=no', t);
        } else {
            openURL('https://play.google.com/store/apps/details?id=com.heime.app', t);
        }
    };

    const isIos = isiOS();

    return (
        <DividedImageModal
            onDismiss={handleDismissClick}
            hideCloseButton={showModal === 'force'}
            top={
                <View style={{ alignItems: 'center', width: '100%' }}>
                    <MainLogo imageWidth={WW * 0.6} imageHeight={WH * 0.15} />
                </View>
            }
            bottom={
                <View style={{ width: WW * 0.8, gap: smallestMargin }}>
                    <HeimeText variant="title">{t('updateApp:header')}</HeimeText>
                    <HeimeText variant="subtitle" style={{ textAlign: 'center' }}>
                        {t(
                            showModal === 'force' ? 'updateApp:description_force' : 'updateApp:description_recommended',
                            {
                                store: t(`updateApp:${isIos ? 'ios' : 'android'}`),
                            },
                        )}
                    </HeimeText>
                    {showModal === 'recommended' && (
                        <HeimeText variant="subtitle" style={{ textAlign: 'center' }}>
                            {t('updateApp:description_force_soon')}
                        </HeimeText>
                    )}

                    <PrimaryButton
                        onPress={handleGoToStore}
                        style={{ marginTop: screenMargin }}
                        text={t('updateApp:goToButton', { store: t(`updateApp:${isIos ? 'ios' : 'android'}`) })}
                    />
                    {showModal === 'recommended' ? (
                        <SecondaryButton onPress={handleDismissClick} text={t('updateApp:later')} />
                    ) : null}
                </View>
            }
        />
    );
};

export default UpdateAppAlert;
