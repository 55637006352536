import { ReactElement, useEffect } from 'react';
import Animated, {
    Easing,
    useAnimatedStyle,
    useSharedValue,
    withRepeat,
    withSequence,
    withTiming,
} from 'react-native-reanimated';

const OpacityEffect = ({ children }: { children: ReactElement }) => {
    const opacity = useSharedValue(0);

    useEffect(() => {
        opacity.value = withRepeat(
            withSequence(
                withTiming(0.4, { duration: 1000, easing: Easing.bezier(0.25, 0.1, 0.25, 1) }),
                withTiming(1, { duration: 900, easing: Easing.bezier(0.25, 0.1, 0.25, 1) }),
            ),
            -1,
            true,
        );
    }, [opacity]);

    const animatedStyle = useAnimatedStyle(() => ({
        opacity: opacity.value,
    }));

    return <Animated.View style={animatedStyle}>{children}</Animated.View>;
};

export default OpacityEffect;
