import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Animated } from 'react-native';
import { isAppError, isNetworkError } from '_utils';
import { useGetAccessTokenHeader } from '_utils/Axios';
import { Modal } from 'Components';
import LockRing from 'Screens/Reservations/common/LockRing';
import { useSelectedCoop } from 'SelectedCoop';
import { Lock } from 'types/Reservation';
import { LockAnimation } from './Components';

interface LockModalProps {
    lock: Lock;
    onClose(): void;
}

const RemoteUnlockModal = ({ lock, onClose }: LockModalProps): ReactElement | null => {
    const getAccessTokenHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const fadeAnim = useRef(new Animated.Value(0)).current;

    const [isWaiting, setIsWaiting] = useState(false);

    const { mutate, isPending, isError, reset, isIdle } = useMutation({
        mutationFn: async () => {
            const result = await axios.post<{ success: number }>(
                `cooperatives/${selectedCoopId}/locks/${lock.id}/unlock`,
                {},
                { headers: { authorization: await getAccessTokenHeader() } },
            );

            if (!result.data.success) {
                throw new Error('Result did not return success');
            }
            return result.data;
        },
        retry: (failureCount: number, error: Error) => {
            return isAppError(error) && isNetworkError(error) && failureCount < 1000;
        },
        retryDelay: (failureCount: number) => failureCount * 1000,
        onSuccess: () => {
            setIsWaiting(true);
            setTimeout(() => {
                setIsWaiting(false);
            }, 5000);
        },
    });

    const { t } = useTranslation();

    const onRetry = () => {
        reset();
        mutate();
    };

    useEffect(() => {
        if (!isPending && !isWaiting) {
            Animated.timing(fadeAnim, { toValue: 1, useNativeDriver: true, duration: 1000 }).start();
        } else {
            const sequence = Animated.sequence([
                Animated.timing(fadeAnim, { toValue: 1, useNativeDriver: true, duration: 1000 }),
                Animated.timing(fadeAnim, { toValue: 0, useNativeDriver: true, duration: 1000 }),
            ]);
            Animated.loop(sequence).start();
        }
    }, [fadeAnim, isPending, isWaiting]);

    const props =
        isPending || isWaiting
            ? {
                  header: isPending
                      ? t('expandedReservation:lockModal:unlocking')
                      : t('expandedReservation:lockModal:waiting'),
                  content: (
                      <LockRing color="secondary">
                          <LockAnimation icon="lockFilled" color="secondary" scaleAnim={fadeAnim} />
                      </LockRing>
                  ),
                  buttons: [
                      { text: t('expandedReservation:lockModal:close'), type: 'secondary' as const, onPress: onClose },
                  ],
              }
            : isError
              ? {
                    header: t('expandedReservation:lockModal:unlockFailed'),
                    content: (
                        <LockRing color="red">
                            <LockAnimation icon="lockSlash" color="red" scaleAnim={fadeAnim} />
                        </LockRing>
                    ),
                    buttons: [
                        { text: t('expandedReservation:lockModal:retry'), type: 'danger' as const, onPress: onRetry },
                    ],
                }
              : isIdle
                ? {
                      header: t('expandedReservation:lockModal:remote_unlock'),
                      content: (
                          <LockRing color="main">
                              <LockAnimation icon="lockFilled" color="main" scaleAnim={fadeAnim} />
                          </LockRing>
                      ),
                      buttons: [{ text: t('expandedReservation:unlock'), onPress: mutate }],
                  }
                : {
                      header: t('expandedReservation:lockModal:remote_unlocking'),
                      content: (
                          <LockRing color="green">
                              <LockAnimation icon="lockFilledOpen" color="green" scaleAnim={fadeAnim} />
                          </LockRing>
                      ),
                      buttons: [
                          { text: t('expandedReservation:lockModal:unlockAgain'), onPress: mutate },
                          {
                              text: t('expandedReservation:lockModal:close'),
                              type: 'secondary' as const,
                              onPress: onClose,
                          },
                      ],
                  };

    return <Modal hideCloseButton onDismiss={onClose} buttonsDirection="column" {...props} />;
};

export default RemoteUnlockModal;
