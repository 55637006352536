import { useMemo, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';
import { suggestions, SuggestionType } from '_api/useActivityInterest';
import { fetchBlobWithCache } from '_dependencies/fetchBlob';
import { WW, WH } from '_utils';
import { track } from '_utils/Amplitude';
import { captureException } from '_utils/Sentry';
import { smallestMargin, screenMargin } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { HeimeText, Modal } from 'Components';
import { FileToBeUploaded } from 'types/Utility';
import { FormValues } from '../../schema';

const TemplateSelector = () => {
    const { theme } = useThemeContext();
    const [showModal, setShowModal] = useState<TemplateSuggestion | null>(null);
    const templates = useTemplates();
    const { t } = useTranslation();
    const { setValue, formState, watch } = useFormContext<FormValues>();
    const selectedTemplate = watch('template');

    const dirtyFields = formState.dirtyFields;

    const handleSelectTemplate = (template: TemplateSuggestion) => {
        const templateFields = Object.keys(templates[template]) as (keyof FormValues)[];
        const isDirty = templateFields.some((field) => dirtyFields[field]);
        if (isDirty) {
            setShowModal(template);
            track('Activity template modal opened', {
                template,
            });
        } else {
            handleUseTemplate(template);
        }
    };

    useEffect(() => {
        if (selectedTemplate && selectedTemplate in templates) {
            setTimeout(() => {
                const template = templates[selectedTemplate as keyof typeof templates];
                const settings = {
                    shouldDirty: false,
                    shouldValidate: true,
                    shouldTouch: true,
                };
                (Object.entries(template) as [keyof FormValues | 'picture', string][]).forEach(([key, value]) => {
                    if (key !== 'picture') {
                        setValue(key, value);
                    }
                });

                if (template.picture) {
                    const imageUri = `https://app.heime.com/files/cooperatives/39/docs/production_app/activity_templates/${template.picture}`;
                    fetchBlobWithCache('GET', imageUri)
                        .then((base64Image) => base64Image.base64())
                        .then((base64) => {
                            const image: FileToBeUploaded = {
                                status: 'added',
                                uri: `data:image/jpeg;base64,${base64}`,
                                index: 0,
                                name: selectedTemplate,
                            };
                            setValue('pictures', [image], settings);
                        })
                        .catch(captureException);
                }
            }, 0);
        }
    }, [selectedTemplate, setValue, templates]);

    const handleDismissModal = () => setShowModal(null);

    const onConfirmModal = () => showModal && handleUseTemplate(showModal);

    const handleUseTemplate = async (template: TemplateSuggestion) => {
        track('Activity template pressed', {
            template,
            showModal: !!showModal,
        });
        setValue('template', template);
        setShowModal(null);
    };

    return (
        <ScrollView
            horizontal
            contentContainerStyle={{
                gap: smallestMargin,
                paddingLeft: screenMargin,
                paddingRight: screenMargin,
                paddingTop: WW * 0.03,
                paddingBottom: smallestMargin,
            }}
            showsHorizontalScrollIndicator={false}
            keyboardShouldPersistTaps="always"
        >
            {showModal ? (
                <Modal
                    onDismiss={handleDismissModal}
                    header={t('newActivity:confirm_template')}
                    content={
                        <HeimeText style={{ textAlign: 'center' }}>
                            {t('newActivity:confirm_template_content')}
                        </HeimeText>
                    }
                    buttons={[
                        { text: t('newActivity:confirm_template_cancel'), onPress: handleDismissModal },
                        {
                            text: t('newActivity:confirm_template_confirm'),
                            onPress: onConfirmModal,
                            type: 'danger',
                        },
                    ]}
                />
            ) : null}
            {suggestions.map((suggestion) =>
                suggestion !== 'other' ? (
                    <TouchableOpacity
                        onPress={() => handleSelectTemplate(suggestion)}
                        key={suggestion}
                        style={[
                            {
                                padding: smallestMargin,
                                borderRadius: WH * 0.015,
                                borderWidth: 1,
                            },
                            selectedTemplate === suggestion
                                ? {
                                      backgroundColor: theme.lightGreen,
                                      borderColor: theme.main,
                                  }
                                : {
                                      backgroundColor: theme.mainBackground,
                                      borderColor: theme.mediumGrey,
                                  },
                        ]}
                    >
                        <HeimeText style={{ color: theme.main }}>
                            {t(`activity:suggestions:${suggestion}_title`)}
                        </HeimeText>
                    </TouchableOpacity>
                ) : null,
            )}
        </ScrollView>
    );
};

type TemplateSuggestion = Exclude<SuggestionType, 'other'>;
const useTemplates = (): Record<TemplateSuggestion, Omit<Partial<FormValues>, 'pictures'> & { picture?: string }> => {
    const { t } = useTranslation();

    return useMemo(
        () => ({
            coffee: {
                name: t('activity:suggestions:coffee_title'),
                description: t('activity:suggestions:coffee_arrange_description_example'),
                location: t('activity:suggestions:coffee_arrange_location_example'),
                picture: 'coffee.jpg',
            },
            party: {
                name: t('activity:suggestions:party_title'),
                description: t('activity:suggestions:party_arrange_description_example'),
                location: t('activity:suggestions:party_arrange_location_example'),
                picture: 'party.jpg',
            },
            bbq: {
                name: t('activity:suggestions:bbq_title'),
                description: t('activity:suggestions:bbq_arrange_description_example'),
                location: t('activity:suggestions:bbq_arrange_location_example'),
                picture: 'bbq.jpg',
            },
            quiz: {
                name: t('activity:suggestions:quiz_title'),
                description: t('activity:suggestions:quiz_arrange_description_example'),
                location: t('activity:suggestions:quiz_arrange_location_example'),
                picture: 'quiz.png',
            },
            sport: {
                name: t('activity:suggestions:sport_title'),
                description: t('activity:suggestions:sport_arrange_description_example'),
                location: t('activity:suggestions:sport_arrange_location_example'),
                picture: 'sport.jpg',
            },
            common_dinner: {
                name: t('activity:suggestions:common_dinner_title'),
                description: t('activity:suggestions:common_dinner_arrange_description_example'),
                location: t('activity:suggestions:common_dinner_arrange_location_example'),
                picture: 'common_dinner.jpg',
            },
            knitting: {
                name: t('activity:suggestions:knitting_title'),
                description: t('activity:suggestions:knitting_arrange_description_example'),
                location: t('activity:suggestions:knitting_arrange_location_example'),
                picture: 'knitting.jpg',
            },
            walk: {
                name: t('activity:suggestions:walk_title'),
                description: t('activity:suggestions:walk_arrange_description_example'),
                location: t('activity:suggestions:walk_arrange_location_example'),
                picture: 'walk.jpg',
            },
            bathing: {
                name: t('activity:suggestions:bathing_title'),
                description: t('activity:suggestions:bathing_arrange_description_example'),
                location: t('activity:suggestions:bathing_arrange_location_example'),
                picture: 'bathing.jpg',
            },
            community_effort: {
                name: t('activity:suggestions:community_effort_title'),
                description: t('activity:suggestions:community_effort_arrange_description_example'),
                location: t('activity:suggestions:community_effort_arrange_location_example'),
                picture: 'community_effort.jpg',
            },
            workout: {
                name: t('activity:suggestions:workout_title'),
                description: t('activity:suggestions:workout_arrange_description_example'),
                location: t('activity:suggestions:workout_arrange_location_example'),
                picture: 'workout.jpg',
            },
            boardgames: {
                name: t('activity:suggestions:boardgames_title'),
                description: t('activity:suggestions:boardgames_arrange_description_example'),
                location: t('activity:suggestions:boardgames_arrange_location_example'),
                picture: 'boardgames.jpg',
            },
            christmas_lighting: {
                name: t('activity:suggestions:christmas_lighting_title'),
                description: t('activity:suggestions:christmas_lighting_arrange_description_example'),
                location: t('activity:suggestions:christmas_lighting_arrange_location_example'),
                picture: 'christmas_lighting.jpeg',
            },
        }),
        [t],
    );
};

export default TemplateSelector;
