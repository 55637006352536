import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { offsetFromTop, tabbarNegativeMargin } from '_navigator/TabBar/components/constants';
import { smallestMarginPixels } from '_utils/sizes';
import useKeyboardIsVisible from './useKeyboardIsVisible';

/**
 * For cases where adding a safezone at the bottom is best handled by padding or margin
 *
 * @example
 * const BottomSpacer = () => {
 *   const height = useBottomSpacer();
 *   return <View style={{ height }} />;
 * };
 */
const useBottomSpacer = (): number => {
    const insets = useSafeAreaInsets();
    const isShowingKeyboard = useKeyboardIsVisible();

    if (isShowingKeyboard) {
        return smallestMarginPixels;
    }

    return offsetFromTop - tabbarNegativeMargin + (insets.bottom > 0 && !isShowingKeyboard ? insets.bottom : 10);
};

export default useBottomSpacer;
