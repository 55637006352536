import { useCallback } from 'react';
import {
    InfiniteData,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { NotificationSchema, Notification } from 'types/Notification';
import { useLaravelInfinteQuery } from './utility';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import { LaravelPagingResponse } from '../types/Utility';

const responseSchema = z.array(NotificationSchema);
const useGetNotifications = (): UseInfiniteQueryResult<InfiniteData<Notification[]>, string | Error> => {
    const selectedCoopId = useSelectedCoop();
    return useLaravelInfinteQuery(
        ['notifications', selectedCoopId],
        async ({ pageParam = 1, getAuthHeader }) =>
            await axios.get<LaravelPagingResponse<Notification[]>>(`cooperatives/${selectedCoopId}/notifications2`, {
                headers: { authorization: await getAuthHeader() },
                params: { page: pageParam },
            }),
        {
            gcTime: Infinity,
            staleTime: 1000 * 60 * 60,
        },
        responseSchema,
    );
};

const useInvalidateNotifications = (): (() => void) => {
    const queryClient = useQueryClient();
    return useCallback(
        () =>
            queryClient.invalidateQueries({
                queryKey: ['notifications'],
            }),
        [queryClient],
    );
};

const useMarkNotificationSeen = (): UseMutationResult<{ success: boolean }, string | Error, number[]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const invalidateNotifications = useInvalidateNotifications();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (notifications: number[]) => {
            const result = await axios.patch<{ success: boolean }>(
                `cooperatives/${selectedCoopId}/notifications/seen`,
                { notifications },
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Result returned with success == false');
            }
            return result.data;
        },
        onMutate: (vars) => {
            queryClient.setQueryData<InfiniteData<LaravelPagingResponse<Notification[]>> | undefined>(
                ['notifications', selectedCoopId],
                (data) => {
                    if (!data) {
                        return data;
                    }
                    return {
                        ...data,
                        pages: data?.pages.map((resp) => ({
                            ...resp,
                            data: resp.data.map((notification) => {
                                if (vars.includes(notification.id)) {
                                    return { ...notification, seen: true };
                                }
                                return notification;
                            }),
                        })),
                    };
                },
            );
        },
        onSettled: () => {
            invalidateNotifications();
        },
    });
};

export { useGetNotifications, useMarkNotificationSeen, useInvalidateNotifications };
