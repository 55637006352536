import React, { ReactElement } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { z } from 'zod';
import { useGetGroups } from '_api/useGroups';
import { track } from '_utils/Amplitude';
import OnboardingTitles from 'Screens/Onboarding/Components/OnboardingTitles';
import { ChangeAvatar, DescribeText, ErrorText, AboutMe } from './components';
import { useOwnProfile, useUpdateProfile } from '../../_api/useProfile';
import { KeyboardAwareScrollView } from '../../_dependencies/keyboardAware';
import { SafeAreaView } from '../../_dependencies/safeArea';
import { useAppNavigation } from '../../_navigator';
import { Theme, useThemeStyle, useConfirmDiscard, showToast, isAppError, isNetworkError } from '../../_utils';
import { screenMargin } from '../../_utils/sizes';
import { PrimaryButton } from '../../Components';
import { OwnUserProfile } from '../../types/User';
import OnboardingHeader from '../Onboarding/Components/OnboardingHeader';

const mapToDefault = (profile: OwnUserProfile): FormValues => ({
    aboutMe: profile.about_me,
    avatar: profile.avatar?.original ? undefined : null,
});

const formSchema = z.object({
    aboutMe: z.string(),
    avatar: z.string().nullable().optional(),
});

type FormValues = z.infer<typeof formSchema>;

const ProfileDescribeInfo = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    // Expected to be loaded at this point
    const { data: profile } = useOwnProfile();
    const { mutate: updateProfile, isPending: isUpdatingProfile } = useUpdateProfile();
    const { goBack: internalGoBack, navigate } = useAppNavigation();

    const { formState, watch, setValue, handleSubmit, setError, ...methods } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: mapToDefault(profile as OwnUserProfile),
        resolver: zodResolver(formSchema),
    });

    // Only to preload next screen
    useGetGroups();

    const { content } = useConfirmDiscard(formState.isDirty && !isUpdatingProfile, true, internalGoBack);

    const handleContinue = handleSubmit((formValue: FormValues) => {
        track('ProfileDescribeInfoContinue', {
            aboutMe: formValue.aboutMe === profile?.about_me ? 'not_changed' : 'changed',
            avatar: formValue.avatar === null ? 'removed' : formValue.avatar ? 'changed' : 'not_changed',
        });
        updateProfile(
            {
                about_me: formValue.aboutMe,
                avatar: formValue.avatar,
            },
            {
                onError: (e) => {
                    if (isAppError<FormValues>(e)) {
                        if (isNetworkError(e)) {
                            return;
                        }
                    }

                    showToast({
                        type: 'error',
                        header: t('global:error_saving_header'),
                        text: t('global:error_saving_description'),
                        position: 'bottom',
                    });
                },
            },
        );

        navigate('JoinGroups', undefined);
    });

    const errorMessage = Object.values(formState.errors).reduce((curr, val) => (curr ? curr : (val.message ?? '')), '');

    return (
        <SafeAreaView style={themedStyle.safeAreaView} edges={['top']}>
            {<OnboardingHeader currentStep={4} />}
            <KeyboardAwareScrollView style={themedStyle.body}>
                <OnboardingTitles title={t('onboarding:createProfile')} />

                <FormProvider
                    setValue={setValue}
                    watch={watch}
                    formState={formState}
                    handleSubmit={handleSubmit}
                    setError={setError}
                    {...methods}
                >
                    <ChangeAvatar initialAvatar={profile?.avatar ?? null} />
                    <AboutMe autoFocus={false} />
                    <DescribeText>{t('onboarding:describe_yourself')}</DescribeText>
                </FormProvider>
            </KeyboardAwareScrollView>

            <View>
                {errorMessage ? <ErrorText>{t(errorMessage as 'global:noLang')}</ErrorText> : undefined}
                <PrimaryButton
                    status={isUpdatingProfile ? 'loading' : errorMessage ? 'disabled' : null}
                    bottomAction="modal"
                    onPress={handleContinue}
                    text={t('profileEdit:continue').toUpperCase()}
                />
            </View>
            {content}
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        safeAreaView: {
            display: 'flex',
            height: '100%',
            backgroundColor: theme.mainBackground,
        },
        body: {
            paddingRight: screenMargin,
            paddingLeft: screenMargin,
            flex: 1,
        },
    });

export default ProfileDescribeInfo;
