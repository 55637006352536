import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import Loader from 'Components/Loader';
import SimpleHeader from 'Components/SimpleHeader';
import { Theme, useThemeStyle, WH } from '../_utils';

const SimpleLoadScreen = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <View style={themedStyle.container}>
            <SimpleHeader />
            <Loader />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            display: 'flex',
            height: WH,
            backgroundColor: theme.mainBackground,
        },
    });

export default SimpleLoadScreen;
