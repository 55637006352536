import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { smallestFontSize, smallestMargin, smallestMarginPixels, subtitleFontSize } from '_utils/sizes';
import { HeimeText, Icon } from 'Components';

const ExpiredInfo = ({
    title,
    description,
    style: styleProp,
}: {
    title: string;
    description: string;
    style?: StyleProp<ViewStyle>;
}) => {
    const themedStyle = useThemeStyle(style);

    return (
        <View style={[themedStyle.container, styleProp]}>
            <View style={themedStyle.textContainer}>
                <View style={themedStyle.headerContainer}>
                    <Icon name="info" color="darkGray" />
                    <HeimeText style={themedStyle.header}>{title}</HeimeText>
                </View>
                <HeimeText style={themedStyle.description}>{description}</HeimeText>
            </View>
        </View>
    );
};

const style = (theme: Theme) =>
    StyleSheet.create({
        container: {
            width: '100%',
            borderWidth: 1,
            borderColor: theme.secondaryLight,
            backgroundColor: theme.mediumBackground,
            borderRadius: 10,
            paddingRight: smallestMargin,
            paddingLeft: smallestMargin,
            paddingTop: smallestMargin,
            paddingBottom: smallestMargin,
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: smallestMargin,
        },
        textContainer: {
            gap: smallestMargin,
            marginBottom: smallestMargin,
            marginTop: smallestMargin,
            flex: 1,
        },
        headerContainer: {
            flexDirection: 'row',
            gap: smallestMargin,
            alignItems: 'center',
        },

        header: {
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            color: theme.darkGrey,
            flex: 1,
        },
        description: {
            fontSize: smallestFontSize,
            color: theme.darkGray,
            marginLeft: 24 + smallestMarginPixels,
        },
    });

export default ExpiredInfo;
