import React, { ReactElement, ComponentProps, useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useEditChatMessage, useSendMessage } from '_api/useChats';
import { isAppError, isNetworkError, showToast } from '_utils';
import { MessageInput } from 'Components';
import { ChatMessage } from 'types/Chat';
import { ApiImageObjectToStaleFile, isFileToBeUploaded } from 'types/Utility';

interface ChatMessageInputProps {
    chatId: number;
    onSend?: () => void;
    onFocus?: () => void;
    editing: null | (Omit<ChatMessage, 'id'> & { id: number });
    onEditingEnd: () => void;
}

const ChatMessageInput = ({ chatId, onSend, onFocus, editing, onEditingEnd }: ChatMessageInputProps): ReactElement => {
    const [state, setState] = useState<ComponentProps<typeof MessageInput>['state']>({ text: '', attachments: [] });
    const { t } = useTranslation();
    const { mutate: editMessage } = useEditChatMessage();
    const { mutate: sendMessage } = useSendMessage({
        retry: (failureCount: number, error: AxiosError | unknown) => {
            if (isAppError(error) && isNetworkError(error)) {
                return failureCount < 3;
            }
            return false;
        },
        retryDelay: (failureCount: number) => {
            return failureCount * 1000;
        },
    });

    const handleEditingEnd = () => {
        setState({ text: '', attachments: [] });
        onEditingEnd();
    };

    useEffect(() => {
        if (editing) {
            setState({
                text: editing.text,
                attachments: editing.files.map(ApiImageObjectToStaleFile),
            });
            onFocus?.();
        }
    }, [editing, onFocus]);

    const handleSendMessage: ComponentProps<typeof MessageInput>['onSend'] = ({ content, files }) => {
        const onError = (e: AxiosError | unknown) => {
            setState({
                attachments: files,
                text: content,
            });
            const isNetworkProblem = isAppError(e) && isNetworkError(e);
            showToast({
                type: 'error',
                header: isNetworkProblem ? t('global:networkError_header') : t('global:error_sending_header'),
                text: isNetworkProblem ? t('global:networkError_description') : t('global:error_sending_description'),
                position: 'bottom',
            });
        };

        if (editing) {
            editMessage(
                {
                    chatId,
                    messageId: editing.id,
                    data: {
                        text: content,
                        files: files.filter(isFileToBeUploaded),
                        removeFiles: editing.files
                            .filter((file) => !files.find((arg) => 'id' in arg && arg.id !== file.id))
                            .map((file) => file.id),
                    },
                },
                {
                    onError,
                },
            );
            handleEditingEnd();
        } else {
            sendMessage(
                [
                    chatId,
                    {
                        text: content,
                        files: files.filter(isFileToBeUploaded),
                    },
                ],
                {
                    onError,
                },
            );
        }

        onSend?.();
    };

    return (
        <MessageInput
            state={state}
            setState={setState}
            onFocus={onFocus}
            onSend={handleSendMessage}
            onEditingEnd={handleEditingEnd}
            isEditingText={t('chat:editing_message')}
            isEditing={editing != null}
        />
    );
};

export default ChatMessageInput;
