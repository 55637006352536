import { ComponentProps, ReactElement, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, ScrollView, View } from 'react-native';
import { z } from 'zod';
import { useAddApartmentUser, useConnectApartmentUser, useUpdateApartmentUser } from '_api/useApartments';
import { useAppNavigation } from '_navigator';
import { Theme, getUsernameFromProfile, isAppError, isNetworkError, showToast, useThemeStyle } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize, titleFontSize } from '_utils/sizes';
import {
    FormDropdown,
    FormInput,
    FormRadio,
    HeaderWithNav,
    HeimeText,
    OpinionatedSafeArea,
    PhoneNumberInput,
    PrimaryButton,
} from 'Components';
import { ProfileAvatar } from 'Screens/Profile/Components';
import { cleanPhoneNumber } from 'types/User';
import { EditDetailsPropsSchema, TenantRoleSchema } from '../TenantEditNavigation';

const notifySchema = z.union([z.literal('none'), z.literal('sms'), z.literal('email')]);

const newUserSchema = z.object({
    fname: z.string().nonempty('onboarding:firstName_error').max(255),
    lname: z.string().nonempty('onboarding:lastName_error').max(255),
    email: z.union([z.string().email('onboarding:invalidEmail'), z.string().length(0)]).optional(),
    phone: z.string().nonempty('onboarding:phone_error').max(25),
    tenant_role: TenantRoleSchema,
    notify: notifySchema,
});

const editUserSchema = z.object({
    tenant_role: TenantRoleSchema,
    notify: notifySchema,
});

type FormValues = z.infer<typeof newUserSchema>;

const EditDetails = (props: unknown): ReactElement => {
    const routeProps = EditDetailsPropsSchema.parse(props).route.params;
    const { t } = useTranslation();
    const { navigate } = useAppNavigation();

    const themedStyle = useThemeStyle(style);

    const hasUser = 'user' in routeProps;
    const isEditableUser = 'user' in routeProps && routeProps.user.status === 'notLoggedIn';
    const formValues = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: hasUser
            ? {
                  ...routeProps.user,
                  notify: 'none',
                  email: routeProps.user.email ?? '',
              }
            : { email: '', tenant_role: 'owner', notify: 'none', phone: routeProps.phone },
        resolver: zodResolver(hasUser && !isEditableUser ? editUserSchema : newUserSchema),
    });
    const { mutateAsync: updateUser, isPending: isUpdatingUser } = useUpdateApartmentUser();
    const { mutateAsync: connectUser, isPending: isConnectingUser } = useConnectApartmentUser();
    const { mutateAsync: createUser, isPending: isCreatingUser } = useAddApartmentUser();

    const onSubmit = async (values: FormValues) => {
        try {
            const body = { ...values, ...('phone' in values ? { phone: cleanPhoneNumber(values.phone ?? '') } : {}) };

            if (routeProps.type === 'editUser') {
                await updateUser({
                    ...routeProps,
                    id: routeProps.user.id,
                    body,
                });
            } else if (routeProps.type === 'addUser') {
                await connectUser({
                    apartmentId: routeProps.apartmentId,
                    id: routeProps.user.id,
                    body,
                });
            } else {
                await createUser([routeProps.apartmentId, { ...body, email: values.email ?? 'undefined' }]);
            }
            navigate('MyHome', undefined);
        } catch (e: unknown) {
            if (isAppError<FormValues>(e)) {
                if (isNetworkError(e)) {
                    return;
                }
                if (e.response?.data.errors?.email?.[0]) {
                    formValues.setError('email', {
                        type: 'manual',
                        message: e.response?.data.errors?.email?.[0],
                    });
                    showToast({
                        type: 'error',
                        header: t('global:error_saving_header'),
                        text: e.response?.data.errors?.email?.[0],
                    });
                    return;
                }
                if (e.response?.data.errors?.phone?.[0]) {
                    formValues.setError('phone', {
                        type: 'manual',
                        message: e.response?.data.errors?.phone?.[0],
                    });
                    showToast({
                        type: 'error',
                        header: t('global:error_saving_header'),
                        text: e.response?.data.errors?.email?.[0],
                    });
                    return;
                }
            }
            showToast({
                type: 'error',
                text: t('global:error_saving_description'),
                header: t('global:error_saving_header'),
            });
            throw e;
        }
    };

    const [email, notify] = formValues.watch(['email', 'notify']);

    useEffect(() => {
        if (!email && notify === 'email') {
            formValues.setValue('notify', 'none');
        }
    }, [email, formValues, notify]);

    const hasEmail = email || ('user' in routeProps && 'hasEmail' in routeProps.user && routeProps.user.hasEmail);

    return (
        <OpinionatedSafeArea>
            <HeaderWithNav
                title={routeProps.type === 'editUser' ? t('profileEdit:title') : t('tenantEdit:addDetails')}
            />
            <ScrollView contentContainerStyle={themedStyle.container} bounces={false}>
                <FormProvider {...formValues}>
                    {!isEditableUser && 'user' in routeProps ? (
                        <View style={themedStyle.centered}>
                            <ProfileAvatar profile={{ avatar: routeProps.user.avatar }} />
                            <HeimeText style={themedStyle.headerText}>
                                {getUsernameFromProfile(routeProps.user)}
                            </HeimeText>
                            {routeProps.user.phone ? (
                                <HeimeText style={themedStyle.detail}>{routeProps.user.phone}</HeimeText>
                            ) : null}
                            {routeProps.user.email ? (
                                <HeimeText style={themedStyle.detail}>{routeProps.user.email}</HeimeText>
                            ) : null}
                        </View>
                    ) : (
                        <>
                            <FormInput<FormValues>
                                name="fname"
                                label={t('profileEdit:first_name')}
                                placeholder={t('profileEdit:enterFirstName')}
                                type="nameGiven"
                            />
                            <FormInput<FormValues>
                                name="lname"
                                label={t('profileEdit:last_name')}
                                placeholder={t('profileEdit:enterSurname')}
                                type="familyName"
                            />
                            {routeProps.type === 'editUser' ? (
                                <Controller
                                    name="phone"
                                    render={({ field }) => (
                                        <PhoneNumberInput
                                            label={t('profileEdit:phone')}
                                            placeholder={t('profileEdit:enterPhone')}
                                            {...field}
                                        />
                                    )}
                                />
                            ) : null}
                            <FormInput<FormValues>
                                name="email"
                                label={t('profileEdit:email')}
                                placeholder={t('profileEdit:enterEmail')}
                                type="email"
                            />
                        </>
                    )}
                    <FormDropdown<FormValues, 'tenant_role'>
                        name="tenant_role"
                        label={t('tenantEdit:role')}
                        initialGrey
                        items={[
                            { text: t('tenantEdit:owner'), id: 'owner' },
                            { text: t('tenantEdit:livesWith'), id: 'livesWith' },
                            { text: t('tenantEdit:renting'), id: 'renting' },
                            { text: t('tenantEdit:other'), id: 'other' },
                        ]}
                    />
                    {routeProps.type !== 'editUser' ? (
                        <FormRadio<FormValues>
                            options={
                                [
                                    {
                                        label: t('tenantEdit:notifyNone'),
                                        value: 'none',
                                    },
                                    {
                                        label: t('tenantEdit:notifySms'),
                                        value: 'sms',
                                    },
                                    hasEmail
                                        ? {
                                              label: t('tenantEdit:notifyEmail'),
                                              value: 'email',
                                          }
                                        : null,
                                ].filter(Boolean) as ComponentProps<typeof FormRadio>['options']
                            }
                            name="notify"
                            label={t('tenantEdit:notify')}
                        />
                    ) : null}
                </FormProvider>
            </ScrollView>
            <PrimaryButton
                bottomAction="modal"
                onPress={() => formValues.handleSubmit(onSubmit)()}
                text={t('tenantEdit:continue')}
                status={
                    isUpdatingUser || isConnectingUser || isCreatingUser
                        ? 'loading'
                        : formValues.formState.isValid
                          ? null
                          : 'disabled'
                }
            />
        </OpinionatedSafeArea>
    );
};

const style = (theme: Theme) =>
    StyleSheet.create({
        container: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
        },
        centered: {
            alignItems: 'center',
            paddingTop: screenMargin,
        },
        headerText: {
            fontSize: titleFontSize,
            marginTop: screenMargin,
            fontWeight: 'bold',
        },
        detail: {
            fontSize: subtitleFontSize,
            color: theme.main,
            marginTop: smallestMargin,
        },
    });

export default EditDetails;
