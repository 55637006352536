import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Modal } from 'Components';
import ControlledInput from '../../../Components/ControlledInput';

interface ReasonModalProps {
    reason: string;
    setReason(reason: string): void;
    onComplete(): void;
    onClose(): void;
    required: boolean;
}

const ReasonModal = ({ reason, setReason, onComplete, onClose, required }: ReasonModalProps): ReactElement => {
    const { t } = useTranslation();

    const reasonToLong = reason.length > 255;

    return (
        <Modal
            onDismiss={onClose}
            header={t('serviceExpanded:reserveReasonModalHeader')}
            content={
                <>
                    <ControlledInput
                        optional={!required}
                        label={t('serviceExpanded:reserveReasonModalLabel')}
                        multiline
                        value={reason}
                        onChange={setReason}
                        numberOfLines={3}
                    />
                    {reasonToLong ? (
                        <ErrorMessage>{t('serviceExpanded:reserveReasonModalTooLong')} </ErrorMessage>
                    ) : null}
                </>
            }
            buttons={[
                {
                    text: t('serviceExpanded:reserveReasonModalButton'),
                    onPress: () => {
                        onClose();
                        onComplete();
                    },
                    isDisabled: reasonToLong || (required && !reason),
                },
            ]}
        />
    );
};

export default ReasonModal;
