import React, { ReactElement, useEffect, useState } from 'react';
import moment, { min } from 'moment';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { PageContainer } from './common';
import useTimeSettings from './hooks/useTimeSettings';
import useTimeSync from './hooks/useTimeSync';
import _fonts from '../../../../_fonts';
import { Theme, useThemeStyle, WH, WW } from '../../../../_utils';
import { screenMargin } from '../../../../_utils/sizes';
import { CheckBox, HeaderWithNav, PrimaryButton, FormDateTimeInput } from '../../../../Components';
import { FormValues } from '../schema';

const ActivityDateTime = ({ goBack, goToPreview }: { goBack(): void; goToPreview(): void }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext<FormValues>();
    // We know start_at and end_at is defined so default values only for typesafety
    const [start_at = new Date(), end_at, cancellation, registration] = watch([
        'start_at',
        'end_at',
        'last_cancel_at',
        'last_confirm_at',
    ]);

    const [signupTimeEnabled, setSignUpTimeEnabled] = useState(Boolean(registration));
    const [cancelSameAsRegistered, setCancelSameAsRegistered] = useState(
        cancellation && registration ? moment(cancellation).isSame(registration, 'minute') : true,
    );

    useTimeSync();

    const { start, end } = useTimeSettings();

    const defaultRegistration = start_at;
    const defaultCancellation = registration ?? defaultRegistration;

    const getHandleBackPress = (func: () => void) => () => {
        if (!end_at) {
            setValue('end_at', end.defaultValue);
        }
        if (signupTimeEnabled) {
            if (!registration) {
                setValue('last_confirm_at', defaultRegistration);
            }
            if (!cancellation || cancelSameAsRegistered) {
                setValue('last_cancel_at', defaultCancellation);
            }
        }
        func();
    };

    const handleSignUpTimePress = () => {
        setSignUpTimeEnabled((curr) => !curr);
    };

    useEffect(() => {
        if (!signupTimeEnabled) {
            setValue('last_confirm_at', null);
            setValue('last_cancel_at', null);
        }
    }, [setValue, signupTimeEnabled]);

    useEffect(() => {
        if (cancelSameAsRegistered) {
            setValue('last_cancel_at', registration);
        }
    }, [cancelSameAsRegistered, registration, setValue]);

    const handleSamePress = () => {
        setCancelSameAsRegistered((curr) => !curr);
    };

    const now = new Date();
    return (
        <>
            <HeaderWithNav title={t('newActivity:when_will_activity')} onPress={getHandleBackPress(goBack)} />
            <PageContainer>
                <Text style={themedStyle.title}>{t('newActivity:when_will_activity')}</Text>
                <Text style={themedStyle.subTitle}>{t('newActivity:choose_start')}</Text>
                <FormDateTimeInput mode="datetime" {...start} />
                <FormDateTimeInput mode="time" {...start} />
                <Text style={themedStyle.subTitle}>{t('newActivity:choose_end')}</Text>
                <FormDateTimeInput mode="datetime" {...end} />
                <FormDateTimeInput mode="time" {...end} />
                <Text style={themedStyle.subTitle}>{t('newActivity:signuptime')}</Text>
                <CheckBox
                    checked={signupTimeEnabled}
                    onPress={handleSignUpTimePress}
                    title={t('newActivity:enableSignUpTime')}
                    style={themedStyle.checkBox}
                />
                {signupTimeEnabled ? (
                    <>
                        <Text style={themedStyle.subTitle}>{t('newActivity:registration')}</Text>
                        <View>
                            <FormDateTimeInput
                                name="last_confirm_at"
                                defaultValue={defaultRegistration}
                                maxDate={defaultRegistration}
                                minDate={registration ? min(moment(now), moment(registration)).toDate() : now}
                                mode="datetime"
                            />
                            <FormDateTimeInput
                                name="last_confirm_at"
                                defaultValue={start_at}
                                maxDate={end_at ?? undefined}
                                minDate={registration ? min(moment(now), moment(registration)).toDate() : now}
                                mode="time"
                            />
                        </View>

                        <Text style={themedStyle.subTitle}>{t('newActivity:cancellation')}</Text>
                        <CheckBox
                            checked={cancelSameAsRegistered}
                            onPress={handleSamePress}
                            title={t('newActivity:cancellationSame')}
                            style={themedStyle.checkBox}
                        />
                        {cancelSameAsRegistered ? null : (
                            <View>
                                <FormDateTimeInput
                                    name="last_cancel_at"
                                    defaultValue={defaultCancellation}
                                    mode="datetime"
                                    minDate={cancellation ? min(moment(now), moment(cancellation)).toDate() : now}
                                    maxDate={start_at}
                                />
                                <FormDateTimeInput
                                    name="last_cancel_at"
                                    defaultValue={defaultCancellation}
                                    mode="time"
                                    minDate={cancellation ? min(moment(now), moment(cancellation)).toDate() : now}
                                />
                            </View>
                        )}
                    </>
                ) : null}
            </PageContainer>
            <PrimaryButton
                text={t('newActivity:continue').toUpperCase()}
                onPress={getHandleBackPress(goToPreview)}
                bottomAction="modal"
            />
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        checkBox: {
            marginTop: WH * 0.01,
            marginBottom: WH * 0.01,
            marginLeft: screenMargin,
            marginRight: screenMargin,
        },
        title: {
            marginTop: WW * 0.04,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WH * 0.035,
            color: theme.black,
        },
        subTitle: {
            color: theme.main,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WW * 0.042,
            marginBottom: WW * 0.01,
            marginTop: WW * 0.03,
        },
    });

export default ActivityDateTime;
