import { allStaticScreens, getAllScreens, getNonScreens } from '_navigator/navigationConfiguration/screenConfiguration';
import { NavigationDestination, NonScreen, NotScreenType } from '_navigator/navigationConfiguration/types';

const getParentsArray = (
    currentScreen: NavigationDestination,
    selectedParent: NonScreen | null,
): NavigationDestination[] => {
    const nonScreens = getNonScreens();
    const allScreens = getAllScreens();
    const isScreen = (screenName: string): screenName is keyof typeof allStaticScreens =>
        screenName in allStaticScreens;

    const isNonScreen = (screenName: string): screenName is keyof typeof nonScreens => screenName in nonScreens;

    let curr;
    if (isNonScreen(currentScreen)) {
        curr = nonScreens[currentScreen];
    } else if (isScreen(currentScreen)) {
        if (selectedParent) {
            curr = allScreens.find(
                ([name, settings]) => name === currentScreen && selectedParent === settings.parent,
            )?.[1];
        } else {
            curr = allStaticScreens[currentScreen];
        }
    }
    if (!curr) {
        throw new Error(`Could not find specified navigationDestination:${currentScreen}`);
    }

    const parents: NavigationDestination[] = [currentScreen];
    while (curr.parent !== null) {
        let parent: NotScreenType | null = null;
        if (isNonScreen(curr.parent)) {
            parent = nonScreens[curr.parent];
        }
        if (!parent || !isNonScreen(curr.parent)) {
            throw new Error(
                `Could not find specified parent:${curr.parent}, currPath:[${[...parents, currentScreen].join(', ')}]`,
            );
        }
        if (parent.type !== 'group') {
            parents.push(curr.parent);
        }
        curr = parent;
    }

    return parents.reverse();
};

export default getParentsArray;
