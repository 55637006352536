import { View } from 'react-native';
import { suggestions } from '_api/useActivityInterest';
import { useAppNavigation } from '_navigator';
import { track } from '_utils/Amplitude';
import { screenMargin } from '_utils/sizes';
import { ActivitySuggestionItem } from 'Screens/Activities/common';
import { ArrayElement } from 'types/Utility';

// Take out 4 random suggestions from the suggestions array, and then make sure variant "other" is last in the list if included
const selectedSuggestions = [...suggestions]
    .sort(() => Math.random() - 0.5)
    .slice(0, 4)
    .sort((a, b) => (a === 'other' ? 1 : b === 'other' ? -1 : 0));

const DirectPress = ({
    onPress,
    emoji,
}: {
    onPress(suggestion: ArrayElement<typeof suggestions>): void;
    emoji: boolean;
}) => {
    const { navigate } = useAppNavigation();

    const handleCreateActivity = () => {
        track('Pressed direct press create activity CTA', { variant: 'direct_press', emoji });
        navigate('ActivityNew', undefined);
    };

    return (
        <View style={{ gap: screenMargin }}>
            {selectedSuggestions.map((suggestion) => (
                <ActivitySuggestionItem
                    key={suggestion}
                    onPress={() => onPress(suggestion)}
                    suggestion={suggestion}
                    emoji={emoji}
                />
            ))}
            <ActivitySuggestionItem onPress={handleCreateActivity} emoji={emoji} />
        </View>
    );
};

export default DirectPress;
