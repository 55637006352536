import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text } from 'react-native';
import { Theme, useThemeStyle } from '../../../_utils';
import { Modal } from '../../../Components';

interface ModalProps {
    onDismiss(): void;
    header: string;
    content?: ReactElement;
    onConfirm(): void;
}

const PhoneNumberModal = ({ onDismiss, onConfirm, header, content }: ModalProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const [isCancelling, setIsCancelling] = useState(false);
    const handleCancel = async () => {
        setIsCancelling(true);
        onConfirm();
    };
    return (
        <Modal
            onDismiss={onDismiss}
            header={header}
            content={<Text style={themedStyle.description}>{content}</Text>}
            buttons={[
                { text: t('profileEdit:updateMobileModal:back').toUpperCase(), onPress: onDismiss },
                {
                    text: t('profileEdit:updateMobileModal:submit').toUpperCase(),
                    onPress: handleCancel,
                    type: 'danger',
                    isLoading: isCancelling,
                },
            ]}
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        description: {
            fontSize: 14,
            color: theme.secondaryText,
            textAlign: 'center',
            marginBottom: 12,
        },
    });

export default PhoneNumberModal;
