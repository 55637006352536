import React, { ReactElement, useMemo, useCallback, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { screenMargin, smallestFontSize, subtitleFontSize } from '_utils/sizes';
import { FileLink } from 'types/Base';
import { MessageFile } from 'types/Chat';
import { ArrayElement } from 'types/Utility';
import { CacheImage, FileIcon, ListItem, QueryView } from '.';
import EmptyMessage from './EmptyMessage';
import HeimeText from './HeimeText';
import Icon from './Icon/Icon';
import { useAppNavigation } from '../_navigator';
import { mapFromMessageFileToHeimeFile, useDocumentsView, WH, WW, useThemeStyle } from '../_utils';
import { Theme } from '../_utils/themeContext';

interface MediaListViewProps
    extends Pick<ComponentProps<typeof QueryView>, 'loadMore' | 'isLoadingMore' | 'onRefresh' | 'isRefreshing'> {
    type: 'media' | 'files';
    files: MessageFile[];
    emptyText: string;
    onAdd?: () => void;
}

const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

const MediaListView = ({ type, files, emptyText, onAdd, ...props }: MediaListViewProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { navigate } = useAppNavigation();

    const filteredFiles = useMemo(() => {
        const isImage = (file: FileLink): boolean => {
            return imageExtensions.includes(typeof file.ext === 'string' ? file.ext.toLowerCase() : '');
        };
        let returnValue: (MessageFile | { id: 'add' })[] = files;
        if (type === 'media') {
            returnValue = files.filter((img) => isImage(img));
        } else {
            returnValue = files.filter((img) => !isImage(img));
        }
        if (onAdd) {
            return [{ id: 'add' as const }, ...returnValue];
        }
        return returnValue;
    }, [files, onAdd, type]);

    const [loader, handlePress] = useDocumentsView();

    const renderItem = useCallback(
        ({ item, index }: { item: ArrayElement<typeof filteredFiles>; index: number }) => {
            if (item.id === 'add') {
                if (!onAdd) {
                    return null;
                }
                return <AddButton isRow={type === 'files'} onAdd={onAdd} />;
            }

            return (
                <>
                    {type === 'media' ? (
                        <TouchableOpacity
                            onPress={() => {
                                navigate('ImagePreview', {
                                    images: filteredFiles.filter((elem): elem is MessageFile => elem.id !== 'add'),
                                    selectedIndex: onAdd ? index - 1 : index,
                                });
                            }}
                        >
                            <CacheImage source={item} style={themedStyle.image} />
                        </TouchableOpacity>
                    ) : (
                        <ListItem
                            onPress={() => handlePress(mapFromMessageFileToHeimeFile(item))}
                            avatar={
                                <View style={themedStyle.file}>
                                    <FileIcon fileName={item.name} />
                                </View>
                            }
                            title={item.name}
                        />
                    )}
                </>
            );
        },
        [filteredFiles, handlePress, navigate, onAdd, themedStyle.file, themedStyle.image, type],
    );

    const emptyList = (
        <View style={themedStyle.emptyContainer}>
            <EmptyMessage message={emptyText} />
        </View>
    );

    return (
        <>
            <QueryView
                key={type}
                style={themedStyle.list}
                data={filteredFiles}
                renderItem={renderItem}
                numColumns={type === 'media' ? 3 : undefined}
                emptyList={emptyList}
                footer={onAdd && filteredFiles.length === 1 ? emptyList : undefined}
                {...props}
            />
            {loader}
        </>
    );
};

interface AddButtonProps {
    onAdd: () => void;
    isRow?: boolean;
}
const AddButton = ({ onAdd, isRow }: AddButtonProps) => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    return (
        <TouchableOpacity
            onPress={onAdd}
            style={[themedStyle.addContainer, isRow ? themedStyle.addFileContainer : themedStyle.addImageContainer]}
        >
            <View style={isRow ? themedStyle.addFileRounded : undefined}>
                <View style={themedStyle.addRounded}>
                    <Icon name="plus" color="black" scale={1.5} />
                </View>
            </View>
            <HeimeText style={themedStyle.addImageText}>
                {isRow ? t('group:groupSelected:add_file') : t('group:groupSelected:add_image')}
            </HeimeText>
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        list: { flex: 1, minHeight: WH * 0.3 },
        loader: {
            height: '100%',
            paddingBottom: WH * 0.5,
        },
        image: {
            marginBottom: WW * 0.005,
            alignSelf: 'center',
            width: WW * 0.33,
            height: WW * 0.33,
        },
        backdrop: {
            height: WH,
            width: WW,
            backgroundColor: theme.transparentBlack,
        },
        file: {
            borderRadius: 10,
            marginLeft: WW * 0.04,
            justifyContent: 'center',
            alignItems: 'center',
            width: WW * 0.2,
            height: WW * 0.2,
            backgroundColor: theme.lightGreen,
        },
        emptyContainer: {
            paddingTop: screenMargin,
        },
        addImageContainer: {
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            maxWidth: WW / 3,
            height: WW * 0.33,
            alignItems: 'center',
        },
        addContainer: {
            alignItems: 'center',
            flex: 1,
        },
        addFileContainer: {
            flexDirection: 'row',
            marginTop: smallestFontSize,
            paddingBottom: smallestFontSize,
            marginLeft: screenMargin,
            marginRight: screenMargin,
            alignItems: 'center',
            borderBottomColor: theme.lightGrey,
            borderBottomWidth: 1,
        },
        addImageText: { color: theme.secondaryText, fontSize: subtitleFontSize },
        addRounded: {
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: WH * 0.6,
            borderColor: theme.secondaryText,
            borderTopWidth: 1,
            borderRightWidth: 1,
            borderBottomWidth: 1,
            borderLeftWidth: 1,
            height: WW * 0.15,
            width: WW * 0.15,
        },
        addFileRounded: {
            width: WW * 0.2,
            alignItems: 'center',
            marginRight: WW * 0.04,
        },
    });

export default MediaListView;
