import React, { ReactElement, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, StyleProp, ViewStyle } from 'react-native';
import _fonts from '../../../_fonts';
import { Theme, useThemeStyle, WH, WW } from '../../../_utils';

interface TabsProps {
    style?: StyleProp<ViewStyle>;
    tabs: { title: string; content: ReactElement }[];
}

const Tabs = ({ style, tabs }: TabsProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const [activeTab, setActiveTab] = useState<number>(0);
    const tabWidth = tabs.length ? WW / tabs.length : WW;

    return (
        <View style={style}>
            <View style={themedStyle.tabsWrapper}>
                {tabs.map((tab, index) => (
                    <TouchableOpacity
                        key={tab.title}
                        style={[activeTab === index ? themedStyle.activeTab : themedStyle.tab, { width: tabWidth }]}
                        onPress={() => setActiveTab(index)}
                    >
                        <Text
                            style={[
                                themedStyle.tabText,
                                { fontFamily: activeTab === index ? _fonts.primaryFontBold : _fonts.primaryFont },
                            ]}
                        >
                            {tab.title.toUpperCase()}
                        </Text>
                    </TouchableOpacity>
                ))}
            </View>
            <View style={themedStyle.tabsWrapper} />
            {tabs.map((tab) => tab.content)[activeTab]}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        tabsWrapper: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        tab: {
            borderBottomWidth: 1,
            borderBottomColor: theme.lightGreen,
            paddingTop: WH * 0.02,
            paddingBottom: WH * 0.02,
        },
        activeTab: {
            borderBottomWidth: 2,
            borderBottomColor: theme.main,
            paddingTop: WH * 0.02,
            paddingBottom: WH * 0.02,
        },
        tabText: {
            textAlign: 'center',
            color: theme.main,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
        },
    });

export default Tabs;
