import { StatusType } from '../_api/useSupports';
import { ValueOf } from '../types/Utility';

const statusMap = {
    0: 'reportSelected:statusTypeNew',
    1: 'reportSelected:statusTypeProcessing',
    2: 'reportSelected:statusTypeDone',
    3: 'reportSelected:statusTypeWaiting',
} as const;

const getReportStatusTranslationString = (status: StatusType): ValueOf<typeof statusMap> => {
    return statusMap[status];
};

export default getReportStatusTranslationString;
