import React, { ReactElement, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet } from 'react-native';
import RNRestart from 'react-native-restart';
import { SafeAreaView } from 'react-native-safe-area-context';
import { SecondaryButton } from '.';
import Footer from './Footer';
import GenericErrorScreen from './GenericErrorScreen';
import { isWeb, resetAsyncStorage, WH, WW } from '../_utils';
import { addBreadcrumb, captureException } from '../_utils/Sentry';

interface AppErrorScreenProps {
    resetErrorBoundary(): void;
    error: Error;
}

const AppErrorScreen = ({ resetErrorBoundary, error }: AppErrorScreenProps): ReactElement => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    useEffect(() => {
        addBreadcrumb('errorScreen', 'Error captured by AppErrorScreen');
        captureException(error);
    }, [error]);

    const handleTryAgain = () => {
        addBreadcrumb('errorScreen', 'user clicked "try again"');

        queryClient
            .getQueryCache()
            .findAll(undefined)
            .forEach((query) => query.reset());
        resetErrorBoundary();
    };

    const handleReset = async () => {
        addBreadcrumb('errorScreen', 'user clicked "reset"');
        await resetAsyncStorage();
        if (isWeb()) {
            window.location.reload();
        } else {
            RNRestart.Restart();
        }
    };

    return (
        <SafeAreaView style={Container}>
            <GenericErrorScreen error={error} button={{ text: t('global:goBack'), onPress: handleTryAgain }} />
            <SecondaryButton style={styles.restartButton} text={t('global:resetApp')} onPress={handleReset} />
            <Footer />
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    restartButton: {
        marginBottom: 16,
        marginLeft: 16,
        marginRight: 16,
        marginTop: 0,
        width: WW - 16 * 2,
    },
});

const Container = Platform.select({
    default: { height: '100%' as const, display: 'flex' as const, flex: 1 },
    web: { height: WH },
});

export default AppErrorScreen;
