import { useMemo, useState } from 'react';
import { Category, Product } from 'types/Category';

type ItemType = Category | Product;
type DataType = Pick<Category, 'categories' | 'products'>;

const useServicesSearch = (
    data: DataType,
): {
    items: ItemType[];
    searchText: string;
    handleSearchTextChange(val: string): void;
} => {
    const [searchText, setSearchedText] = useState<string>('');

    const items = useMemo((): ItemType[] => {
        if (searchText) {
            const searchProduct = (product: Product): boolean =>
                product.name?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());

            const searchCategory = (acc: DataType, category: Category): DataType => {
                if (category.name?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())) {
                    acc.categories = [...acc.categories, category];
                }
                const categoriesResult = category.categories.reduce(searchCategory, { categories: [], products: [] });
                const productsResult = category.products.filter(searchProduct, []);
                return {
                    categories: [...acc.categories, ...categoriesResult.categories],
                    products: [...acc.products, ...categoriesResult.products, ...productsResult],
                };
            };

            const flattenedCategories = data?.categories
                .map((category) => category.categories)
                .flat()
                .reduce(searchCategory, { categories: [], products: [] }) ?? { categories: [], products: [] };
            const firstCategoryProducts =
                data?.categories
                    .map((category) => category.products)
                    .flat()
                    .filter(searchProduct) ?? [];
            const firstProducts = data?.products.filter(searchProduct) ?? [];

            return [
                {
                    categories: [...flattenedCategories.categories],
                    products: [...firstCategoryProducts, ...firstProducts, ...flattenedCategories.products],
                    name: '',
                    pictures: [],
                    id: 0,
                },
            ];
        }
        const categories = data?.categories ?? [];
        const products = data?.products ?? [];
        return [...categories, ...products];
    }, [data?.categories, data?.products, searchText]);

    return { items, searchText, handleSearchTextChange: setSearchedText };
};

export default useServicesSearch;
