import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import Animated, { CurvedTransition } from 'react-native-reanimated';
import { addPlate, removePlate, updatePlate } from '_redux/licensePlate';
import { Theme, WW, useAppDispatch, useGlobalState, useThemeStyle } from '_utils';
import { smallestMargin, screenMargin, smallestFontSize, subtitleFontSize } from '_utils/sizes';
import { EmptyMessage, HeaderWithNav, HeimeText, Icon, ControlledInput } from 'Components';
import { ArrayElement } from 'types/Utility';

const ManageLicensePlates = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const [editingPlates, setEditingPlates] = useState<Array<number>>([]);
    const statePlates = useGlobalState((state) => state.licensePlate.licensePlates);
    const [plates, setPlates] = useState<Array<ArrayElement<typeof statePlates> & { deleted?: boolean }>>(statePlates);
    const appDispatch = useAppDispatch();

    const handleSave = () => {
        setEditingPlates([]);
    };

    const handleUpdatePlate = (plate: ArrayElement<typeof plates>) => {
        setPlates(
            plates.map((p) => {
                if (p.id === plate.id) {
                    return plate;
                }
                return p;
            }),
        );
        appDispatch(updatePlate(plate));
    };

    const handleDeletePlate = (plate: ArrayElement<typeof plates>) => {
        setPlates(
            plates.map((p) => {
                if (p.id === plate.id) {
                    return { ...p, deleted: true };
                }
                return p;
            }),
        );
        appDispatch(removePlate(plate));
    };
    const handleRestorePlate = (plate: ArrayElement<typeof plates>) => {
        setPlates(
            plates.map((p) => {
                if (p.id === plate.id) {
                    return { ...p, deleted: false };
                }
                return p;
            }),
        );
        appDispatch(addPlate(plate));
    };

    return (
        <View style={themedStyle.main}>
            <HeaderWithNav
                title={t('managePlates:header')}
                action={
                    editingPlates.length > 0 ? (
                        <TouchableOpacity
                            accessibilityLabel={t('managePlates:save')}
                            onPress={handleSave}
                            style={[themedStyle.button, themedStyle.save]}
                        >
                            <Icon name="check" color="green" />
                        </TouchableOpacity>
                    ) : undefined
                }
            />
            {plates.length === 0 ? (
                <EmptyMessage message={t('managePlates:noPlates')} />
            ) : (
                <ScrollView>
                    {plates.map((plate) => (
                        <Animated.View key={plate.id} style={themedStyle.container} layout={CurvedTransition}>
                            {plate.deleted ? (
                                <>
                                    <Icon name="check" color="main" />
                                    <HeimeText style={themedStyle.deletedConfirmation}>
                                        {t('managePlates:deleted_confirmation')}
                                    </HeimeText>
                                    <TouchableOpacity
                                        style={themedStyle.button}
                                        onPress={() => handleRestorePlate(plate)}
                                    >
                                        <HeimeText style={themedStyle.undoText}>{t('managePlates:undo')}</HeimeText>
                                    </TouchableOpacity>
                                </>
                            ) : editingPlates.includes(plate.id) ? (
                                <>
                                    <ControlledInput
                                        value={plate.value}
                                        onChange={(newValue) => handleUpdatePlate({ ...plate, value: newValue ?? '' })}
                                        label={t('managePlates:plate')}
                                        containerStyle={[themedStyle.inputContainer, themedStyle.plateInput]}
                                    />
                                    <ControlledInput
                                        value={plate.label}
                                        onChange={(newValue) => handleUpdatePlate({ ...plate, label: newValue ?? '' })}
                                        label={t('managePlates:name')}
                                        containerStyle={[themedStyle.inputContainer, themedStyle.labelInput]}
                                    />
                                </>
                            ) : (
                                <>
                                    <HeimeText numberOfLines={1} style={themedStyle.valueText}>
                                        {plate.value}
                                    </HeimeText>
                                    <HeimeText numberOfLines={1} style={themedStyle.labelText}>
                                        {plate.label}
                                    </HeimeText>
                                    <View style={themedStyle.actionContainer}>
                                        <TouchableOpacity
                                            style={themedStyle.button}
                                            onPress={() => setEditingPlates([...editingPlates, plate.id])}
                                            accessibilityLabel={t('managePlates:edit')}
                                        >
                                            <Icon name="edit" color="main" />
                                        </TouchableOpacity>
                                        <TouchableOpacity
                                            style={themedStyle.button}
                                            onPress={() => {
                                                handleDeletePlate(plate);
                                            }}
                                            accessibilityLabel={t('managePlates:delete')}
                                        >
                                            <Icon name="trash" color="red" />
                                        </TouchableOpacity>
                                    </View>
                                </>
                            )}
                        </Animated.View>
                    ))}
                </ScrollView>
            )}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
        save: {
            // From Header with nav
            minWidth: WW * 0.08 + WW * 0.05,
        },
        button: {
            paddingLeft: smallestMargin,
            paddingRight: smallestMargin,
            paddingTop: smallestMargin,
            paddingBottom: smallestMargin,
        },
        container: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: smallestMargin,
            width: '100%',
            borderBottomColor: theme.mediumGrey,
            borderBottomWidth: 1,
            paddingTop: smallestMargin,
            paddingBottom: smallestMargin,
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
        },
        deletedConfirmation: {
            color: theme.main,
            flexGrow: 1,
        },
        deletedText: {
            color: theme.main,
            fontSize: smallestFontSize,
            flexGrow: 1,
        },
        undoText: {
            color: theme.main,
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
        },
        valueText: {
            color: theme.black,
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            flexShrink: 1,
        },
        labelText: {
            color: theme.mediumGrey,
            fontSize: subtitleFontSize,
            flexGrow: 1,
            flexShrink: 100,
        },
        actionContainer: {
            flexDirection: 'row',
            gap: smallestMargin,
        },
        inputContainer: {
            flexGrow: 1,
        },
        plateInput: {
            flexBasis: '35%',
        },
        labelInput: {
            flexBasis: '60%',
        },
    });

export default ManageLicensePlates;
