import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { screenMargin, smallestFontSize, smallestMargin, titleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { HeimeText } from 'Components';
import { Product } from 'types/Category';
import { NonNullablePickProperty } from 'types/Utility';

const PriceDisplay = ({ paymentStrategy }: NonNullablePickProperty<Product, 'paymentStrategy'>) => {
    const { t } = useTranslation();
    const price_type = paymentStrategy.type;

    if (price_type === 'rate_per_hour_with_night_price') {
        const prices = [paymentStrategy.nighttime_price, paymentStrategy.daytime_price].sort();
        return (
            <PriceView
                mainText={`${prices[1]}-${[prices[0]]} KR / ${t(
                    'serviceExpanded:price_type_short:rate_per_hour_per_capacity',
                )}`}
                subText={t('serviceExpanded:price_type:rate_per_hour_with_night_price', paymentStrategy)}
            />
        );
    }

    return (
        <PriceView
            mainText={`${paymentStrategy.price} KR / ${t(`serviceExpanded:price_type_short:${price_type}`)}`}
            subText={t(`serviceExpanded:price_type:${price_type}`)}
        />
    );
};

const PriceView = ({ mainText, subText }: { mainText: string; subText: string }) => {
    const { theme } = useThemeContext();

    return (
        <View
            style={{
                marginLeft: screenMargin,
                marginRight: screenMargin,
                flexDirection: 'row',
                gap: smallestMargin,
                marginBottom: smallestMargin,
                alignItems: 'center',
            }}
        >
            <HeimeText style={{ fontSize: titleFontSize, color: theme.main, fontWeight: 'bold' }}>{mainText}</HeimeText>
            <HeimeText
                style={{
                    color: theme.secondaryText,
                    fontSize: smallestFontSize,
                    flex: 1,
                }}
            >
                {subText}
            </HeimeText>
        </View>
    );
};

export default PriceDisplay;
