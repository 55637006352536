import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';
import { useAppNavigation } from '_navigator';
import { showToast, useRerenderCountdown } from '_utils';
import { screenMargin } from '_utils/sizes';
import { HeimeText, SecondaryButton } from 'Components';
import TimeSyncGuard from 'Screens/Reservations/common/TimeSyncGuard';

const intervals = [900, 600, 300, 120, 60, 30]; // in seconds expected to be sorted

const TimeValidator = ({
    timestamp,
    children,
}: PropsWithChildren<{
    timestamp: number;
}>) => {
    const momentInstance = useMemo(() => moment.unix(timestamp), [timestamp]);
    const { t } = useTranslation();
    useRerenderCountdown(momentInstance, false);
    const { goBack } = useAppNavigation();

    useEffect(() => {
        const timeouts = intervals
            .filter((interval) => moment(momentInstance).subtract(interval, 'seconds').isAfter(moment()))
            .map((interval) =>
                setTimeout(
                    () => {
                        showToast({
                            type: 'info',
                            header: t('procedure:reservation_ends', { time: momentInstance.fromNow() }),
                            text: t('procedure:reservation_ends_explainer'),
                        });
                    },
                    moment(momentInstance).subtract(interval, 'seconds').diff(moment(), 'milliseconds'),
                ),
            );
        return () => timeouts.forEach((timeout) => clearTimeout(timeout));
    }, [momentInstance, t, timestamp]);

    if (momentInstance.isBefore(moment())) {
        return (
            <ScrollView bounces={false} contentContainerStyle={{ padding: screenMargin, gap: screenMargin }}>
                <HeimeText variant="title">{t('procedure:reservation_ended')}</HeimeText>
                <HeimeText variant="subtitle">{t('procedure:reservation_ended_explainer')}</HeimeText>
                <SecondaryButton text={t('procedure:go_back')} onPress={goBack} />
            </ScrollView>
        );
    }

    return <TimeSyncGuard>{children}</TimeSyncGuard>;
};

export default TimeValidator;
