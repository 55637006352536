import React, { ReactElement, useContext, useMemo } from 'react';
import moment from 'moment';
import { Path, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
// eslint-disable-next-line import/no-named-as-default
import Hyperlink from 'react-native-hyperlink';
import { flattenIniniteResult } from '_utils/misc';
import { PageContainer } from './common';
import { useHandleSubmit } from './hooks';
import { useGetGroups } from '../../../../_api/useGroups';
import { useOwnProfile } from '../../../../_api/useProfile';
import _fonts from '../../../../_fonts';
import { WW, WH, useThemeStyle } from '../../../../_utils';
import { useSelectedCoopItem } from '../../../../_utils/hooks';
import { screenMargin, smallestMargin, subtitleFontSize } from '../../../../_utils/sizes';
import { Theme, ThemeContext } from '../../../../_utils/themeContext';
import {
    FullWidthPictureCarousel,
    HeaderWithNav,
    HeimeText,
    Icon,
    PrimaryButton,
    SecondaryButton,
    SelectorChip,
    UserListItem,
} from '../../../../Components';
import { mapFileUploadsToApiImage } from '../../../../types/Utility';
import { DateTimeExplainer, IconWithValue, OverviewLabel } from '../../common';
import { FormValues } from '../schema';

const ActivityPreview = ({
    goBack,
    onEditProperty,
}: {
    goBack(): void;
    onEditProperty(property: Path<FormValues> | 'more'): void;
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext);
    const { data: ownProfile } = useOwnProfile();

    const { watch } = useFormContext<FormValues>();

    const { data: infiniteGroups } = useGetGroups();

    const { onSubmit, isLoading } = useHandleSubmit();

    const {
        start_at,
        end_at,
        pictures,
        name,
        description,
        last_cancel_at,
        user_groups,
        location,
        paymentStrategy: { price },
        message_type,
        quantity,
        last_confirm_at,
        id,
        cooperative_group_id,
        arrangers,
    } = watch();

    const filteredArrangers = useMemo(
        () => arrangers.filter((item) => item !== ownProfile?.id),
        [arrangers, ownProfile?.id],
    );

    const selectedCoop = useSelectedCoopItem();
    const selectedCoopGroup = useMemo(
        () =>
            selectedCoop?.cooperative_groups?.find(
                (group) => group.type === 'coopGroup' && group.id === cooperative_group_id,
            ),
        [selectedCoop, cooperative_group_id],
    );
    const groupItems = useMemo(() => {
        const intial = flattenIniniteResult(infiniteGroups);
        return user_groups.map((groupId) => intial.find((item) => item.id === groupId));
    }, [infiniteGroups, user_groups]);

    return (
        <>
            <HeaderWithNav onPress={goBack} title={id ? t('newActivity:previewEdit') : t('newActivity:preview')} />
            <PageContainer>
                <TouchableOpacity style={themedStyle.imageContainer} onPress={() => onEditProperty('pictures')}>
                    <FullWidthPictureCarousel
                        pictures={mapFileUploadsToApiImage(pictures)}
                        disablePicturePress
                        showPlaceholder
                    />
                    {pictures.length === 0 ? (
                        <HeimeText style={themedStyle.addImageText}>{t('global:addImage')}</HeimeText>
                    ) : null}
                </TouchableOpacity>

                <OverviewLabel
                    onEdit={() => {
                        onEditProperty('name');
                    }}
                    label={name}
                    size="big"
                />

                <TouchableOpacity
                    onPress={() => {
                        onEditProperty('description');
                    }}
                    style={themedStyle.descriptionContainer}
                >
                    {description ? (
                        <View style={themedStyle.descriptionTextContainer}>
                            <Hyperlink linkStyle={{ color: theme.hyperText }} linkDefault={true}>
                                <Text selectable selectionColor={theme.shadow} style={themedStyle.contentDesc}>
                                    {description}
                                </Text>
                            </Hyperlink>
                        </View>
                    ) : (
                        <Text style={themedStyle.contentDesc}>{t('activityDetail:press_to_add')}</Text>
                    )}
                    <View style={[themedStyle.editWrapper, themedStyle.editWrapperDescription]}>
                        <Icon name="edit" color="mediumGrey" />
                    </View>
                </TouchableOpacity>
                <DateTimeExplainer
                    onEdit={() => onEditProperty('start_at')}
                    start={start_at as Date}
                    end={end_at as Date}
                >
                    <IconWithValue
                        hasValue={Boolean(last_confirm_at)}
                        text={
                            last_confirm_at
                                ? t('activityDetail:registrationDeadline', {
                                      datetime: moment(last_confirm_at).format('DD. MMM  HH:mm'),
                                  })
                                : t('activityDetail:no_registrationDeadline')
                        }
                        icon="clockCheck"
                        style={themedStyle.timeExplainer}
                    />
                    <IconWithValue
                        hasValue={Boolean(last_cancel_at)}
                        text={
                            last_cancel_at
                                ? t('activityDetail:cancellation', {
                                      datetime: moment(last_cancel_at).format('DD. MMM  HH:mm'),
                                  })
                                : t('activityDetail:no_cancellation')
                        }
                        icon="clockCross"
                        style={themedStyle.timeExplainer}
                    />
                </DateTimeExplainer>
                <OverviewLabel
                    onEdit={() => {
                        onEditProperty('arrangers');
                    }}
                    label={t('activityDetail:arrangers')}
                >
                    {filteredArrangers.length > 1 ? (
                        <HeimeText style={themedStyle.arrangersMultipleText}>
                            {t('activityDetail:arrangers_you_and', { count: filteredArrangers.length })}
                        </HeimeText>
                    ) : (
                        <>
                            {filteredArrangers.map((item) => (
                                <UserListItem
                                    containerStyle={themedStyle.arrangersSpacing}
                                    disableProfileNavigate
                                    key={item}
                                    id={item}
                                />
                            ))}
                            <UserListItem
                                containerStyle={themedStyle.arrangersSpacing}
                                disableProfileNavigate
                                noBottomBorder
                                id={ownProfile?.id ?? 0}
                            />
                        </>
                    )}

                    <View style={[themedStyle.arrangersSpacing, themedStyle.addArrangerWrapper]}>
                        <Icon name="plusSquare" color="black" />
                        <HeimeText style={themedStyle.addArrangerText}>{t('activityDetail:add_arranger')}</HeimeText>
                    </View>
                </OverviewLabel>

                <OverviewLabel
                    onEdit={() => {
                        onEditProperty('paymentStrategy.price');
                    }}
                    label={t('activityDetail:cost')}
                >
                    <IconWithValue
                        hasValue={Boolean(price)}
                        text={price ? price + ' ' + t('activityDetail:kr') : t('activityDetail:no_cost')}
                        icon="dollarCircle"
                        style={themedStyle.timeExplainer}
                    />
                </OverviewLabel>
                <OverviewLabel
                    onEdit={() => {
                        onEditProperty('quantity');
                    }}
                    label={t('activityDetail:quantity')}
                >
                    <IconWithValue
                        hasValue={Boolean(quantity)}
                        text={
                            quantity
                                ? t('activityDetail:quantityExplainer', { count: quantity as number })
                                : t('activityDetail:no_quantity')
                        }
                        icon="user"
                        style={themedStyle.timeExplainer}
                    />
                </OverviewLabel>
                <OverviewLabel
                    onEdit={() => {
                        onEditProperty('message_type');
                    }}
                    label={t('activityDetail:alert')}
                >
                    <IconWithValue
                        hasValue
                        text={
                            t('activityDetail:noticeOptions', { returnObjects: true })?.[message_type as 1 | 3 | 4 | 5]
                        }
                        icon="bell"
                        style={themedStyle.timeExplainer}
                    />
                </OverviewLabel>
                {(selectedCoop?.cooperative_groups ?? []).length > 1 ? (
                    <OverviewLabel
                        onEdit={() => onEditProperty('cooperative_group_id')}
                        label={t('newActivity:cooperative_group_id')}
                    >
                        <IconWithValue
                            hasValue={Boolean(cooperative_group_id)}
                            text={
                                selectedCoopGroup && selectedCoopGroup.type === 'coopGroup'
                                    ? selectedCoopGroup.name
                                    : `${t('newActivity:noCooperativeGroup')} ${selectedCoop?.name}`
                            }
                            icon="users"
                            style={themedStyle.timeExplainer}
                        />
                    </OverviewLabel>
                ) : null}
                <OverviewLabel
                    onEdit={() => {
                        onEditProperty('location');
                    }}
                    label={t('activityDetail:location')}
                >
                    <IconWithValue
                        hasValue={Boolean(location)}
                        text={location ? location : t('activityDetail:no_location')}
                        icon="location"
                        style={themedStyle.timeExplainer}
                    />
                </OverviewLabel>
                <OverviewLabel
                    onEdit={() => {
                        onEditProperty('user_groups');
                    }}
                    label={t('activityDetail:related_groups')}
                >
                    {user_groups.length ? (
                        <View style={[themedStyle.chipsWrapper, themedStyle.timeExplainer]}>
                            {groupItems.map((group) => (
                                <SelectorChip
                                    variant="WhitePrimary"
                                    containerStyle={themedStyle.chip}
                                    key={group?.id}
                                    title={group?.name}
                                />
                            ))}
                        </View>
                    ) : (
                        <Text style={[themedStyle.contentDesc, themedStyle.timeExplainer]}>
                            {t('activityDetail:no_groups')}
                        </Text>
                    )}
                </OverviewLabel>
            </PageContainer>
            <SecondaryButton
                style={themedStyle.buttonMargin}
                text={t('activityDetail:addDetails')}
                onPress={() => onEditProperty('more')}
            />
            <PrimaryButton
                text={id ? t('activityDetail:publishEdit') : t('activityDetail:publish')}
                bottomAction="modal"
                onPress={onSubmit}
                status={isLoading ? 'loading' : null}
            />
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        imageContainer: { marginLeft: -screenMargin, marginRight: -screenMargin },
        contentDesc: {
            fontFamily: _fonts.primaryFont,
            color: theme.secondaryText,
            fontSize: WW * 0.04,
        },
        descriptionContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: WH * 0.01,
        },
        descriptionTextContainer: {
            flex: 1,
            flexShrink: 1,
        },
        chipsWrapper: {
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        chip: { marginBottom: WH * 0.01 },
        buttonMargin: {
            marginRight: screenMargin,
            marginLeft: screenMargin,
            marginBottom: smallestMargin,
        },
        editWrapper: {
            marginTop: WH * 0.01,
            marginBottom: WH * 0.01,
            marginLeft: WW * 0.02,
            backgroundColor: theme.lightGrey,
            alignSelf: 'flex-start',
            paddingLeft: WW * 0.01,
            paddingRight: WW * 0.01,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            borderRadius: 50,
        },
        editWrapperDescription: {
            marginTop: 0,
            marginBottom: 0,
            flexShrink: 0,
        },
        addImageText: {
            position: 'absolute',
            margin: 'auto',
            bottom: screenMargin * 4,
            color: theme.black,
            textAlign: 'center',
            textAlignVertical: 'center',
            width: '100%',
            fontSize: subtitleFontSize,
        },
        timeExplainer: { marginTop: WH * 0.01 },
        arrangersMultipleText: { paddingTop: smallestMargin, fontSize: subtitleFontSize, color: theme.black },
        arrangersSpacing: { paddingTop: smallestMargin, paddingBottom: smallestMargin },
        addArrangerText: { fontSize: subtitleFontSize, color: theme.black },
        addArrangerWrapper: { flexDirection: 'row', alignItems: 'center', gap: smallestMargin },
    });

export default ActivityPreview;
