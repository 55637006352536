import React, { ComponentProps, ReactElement, useCallback, useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useCoops } from '_api/useCoops';
import { useSurveys } from '_api/useSurveys';
import { MainHeader } from '_navigator/components';
import { useSelectedCoopItem } from '_utils/hooks';
import type { Cooperative } from 'types/Cooperative';
import { Message, MessageType } from 'types/message';
import { useGetMessages } from '../_api/useMessages';
import useAppNavigation from '../_navigator/hooks/useAppNavigation';
import { NavigationDestination } from '../_navigator/navigationConfiguration/types';
import { Theme, useCalculateMessageRead, useThemeStyle } from '../_utils';
import { capitalizeFirstLetter, flattenIniniteResult } from '../_utils/misc';
import { useLogout } from '../Auth';
import { FullPageMenuComponent, Icon, SecondaryButton } from '../Components';
import { useSetSelectedCoop } from '../SelectedCoop/hooks';
import { ArrayElement } from '../types/Utility';

type IconProperty = ComponentProps<typeof Icon>['name'];
type MenuType = {
    icon: IconProperty;
    title: string;
    navigateTo: NavigationDestination | null;
    children?: MenuType[];
    numberSource?: 'item' | 'neighborHelp';
    params?: Record<string, string>;
};

const MenuObjects = (t: TFunction<'global', undefined>, coopType: Cooperative['type']): MenuType[] => [
    {
        icon: 'home',
        title: t('moreMenu:myHome'),
        navigateTo: 'MyHome',
        children: [],
    },
    {
        icon: 'booking',
        title: t('moreMenu:services:title'),
        navigateTo: null,
        children: [
            {
                icon: 'plusSquare',
                title: t('moreMenu:services:doReservation'),
                navigateTo: 'Services',
                params: { showBackHeader: 'true' },
            },
            { icon: 'note', title: t('moreMenu:services:yourReservations'), navigateTo: 'ReservationsAll' },
        ],
    },
    {
        icon: 'groups',
        title: t('navigation:groups'),
        navigateTo: 'GroupsAll',
        children: [],
    },
    {
        icon: 'tags',
        title: t('moreMenu:memberBenefits'),
        navigateTo: 'MemberBenefitsAll',
        children: [],
    },
    {
        icon: 'happy',
        title: t('moreMenu:helpfulNeighbors'),
        navigateTo: 'HelpfulNeighbors',
        children: [],
        numberSource: 'neighborHelp',
    },
    {
        icon: 'tag',
        title: t('moreMenu:Products'),
        navigateTo: 'Items',
        children: [],
        numberSource: 'item',
    },
    {
        icon: 'board',
        title: t('moreMenu:board:title', {
            boardNounCapitalized: capitalizeFirstLetter(t(`typeSpecific:${coopType}:boardNounDefiniteArticle`)),
        }),
        navigateTo: null,
        children: [
            {
                icon: 'boardMembers',
                title: t('moreMenu:board:members'),
                navigateTo: 'Board',
                children: [],
            },
            {
                icon: 'folder',
                title: t('moreMenu:board:documents'),
                navigateTo: 'Documents',
                children: [],
            },
            {
                icon: 'alert',
                title: t('moreMenu:board:messageToBoard', {
                    boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                }),
                navigateTo: 'ReportsAll',
                children: [],
            },
        ],
    },
    {
        icon: 'users',
        title: t('moreMenu:neighbors'),
        navigateTo: 'NeighborsAll',
        children: [],
    },
    {
        icon: 'settings',
        title: t('moreMenu:settings'),
        navigateTo: 'UserMenu',
        children: [],
    },
    {
        icon: 'bell',
        title: t('moreMenu:contact'),
        navigateTo: 'Contact',
        children: [],
    },
];

const getMapFunction =
    (
        navigate: ReturnType<typeof useAppNavigation>['navigate'],
        counts: { nUnreadItems: number; nUnreadHelpers: number },
    ) =>
    ({
        navigateTo,
        children,
        params,
        ...rest
    }: MenuType): Extract<
        ArrayElement<ComponentProps<typeof FullPageMenuComponent>['items']>,
        { onPress?: () => void }
    > => ({
        ...rest,
        onPress: navigateTo ? () => navigate(navigateTo, params) : undefined,
        children: children?.map(getMapFunction(navigate, counts)) ?? [],
        rightNumber:
            rest.numberSource === 'item'
                ? counts.nUnreadItems
                : rest.numberSource === 'neighborHelp'
                  ? counts.nUnreadHelpers
                  : undefined,
    });

const MoreMenu = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { navigate } = useAppNavigation();
    const { t } = useTranslation();
    const logout = useLogout();
    const { data: coops } = useCoops();
    const setSelectedCoop = useSetSelectedCoop();
    const { data: helperMessages } = useGetMessages([MessageType.Helpers]);
    const { data: itemMessages } = useGetMessages([MessageType.Products]);
    const calculateMessageRead = useCalculateMessageRead();
    const { data: surveys } = useSurveys();

    const selectedCoopType = useSelectedCoopItem()?.type ?? 'joint_ownership';

    const handleChooseCoop = useCallback(() => setSelectedCoop(null), [setSelectedCoop]);

    const menuItems = useMemo(() => {
        const unreadHelperMessages = flattenIniniteResult(helperMessages).filter(
            (message: Message) => !calculateMessageRead(message),
        ).length;

        const unreadItemMessages = flattenIniniteResult(itemMessages).filter(
            (message: Message) => !calculateMessageRead(message),
        ).length;

        const items: ComponentProps<typeof FullPageMenuComponent>['items'] = MenuObjects(t, selectedCoopType).map(
            getMapFunction(navigate, { nUnreadItems: unreadItemMessages, nUnreadHelpers: unreadHelperMessages }),
        );
        if (flattenIniniteResult(surveys).length > 0) {
            // Step behind board
            items.splice(8, 0, {
                icon: 'survey',
                title: t('moreMenu:surveys'),
                onPress: () => navigate('SurveysAll', undefined),
                children: [],
                rightNumber: flattenIniniteResult(surveys).filter(
                    (item) =>
                        item.status === 'ACTIVE' &&
                        item.responses.filter((response) => response.status === 'SENT').length === 0,
                ).length,
            });
        }

        if ((coops?.length ?? 0) > 1) {
            items.push({ icon: 'globe', title: t('moreMenu:selectCoop'), onPress: handleChooseCoop, children: [] });
        }
        items.push(
            <SecondaryButton
                key="logout"
                style={themedStyle.logoutButton}
                text={t('moreMenu:logOut')}
                onPress={logout}
            />,
        );
        return items;
    }, [
        helperMessages,
        itemMessages,
        navigate,
        surveys,
        coops?.length,
        t,
        logout,
        calculateMessageRead,
        handleChooseCoop,
        themedStyle,
        selectedCoopType,
    ]);

    const insets = useSafeAreaInsets();

    return (
        <ScrollView
            testID="moreMenu"
            style={themedStyle.scrollStyle}
            bounces={false}
            contentContainerStyle={{ paddingTop: insets.top }}
        >
            <MainHeader padBottom />
            <FullPageMenuComponent items={menuItems} />
        </ScrollView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        logoutButton: { marginTop: 16, marginLeft: 16, marginRight: 16, marginBottom: 16 },
        scrollStyle: {
            backgroundColor: theme.shadow,
        },
    });

export default MoreMenu;
