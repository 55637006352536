import React, { useState, ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListRenderItem, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import QueryView from 'Components/QueryViews/QueryView';
import SecondaryButton from 'Components/SecondaryButton';
import { useSetSelectedCoop } from './hooks';
import { useCoops } from '../_api/useCoops';
import { SafeAreaView } from '../_dependencies/safeArea';
import { useThemeStyle, WH, WW, Theme } from '../_utils';
import { screenMargin } from '../_utils/sizes';
import { useLogout } from '../Auth/AuthContext';
import ControlledInput from '../Components/ControlledInput';
import { ArrayElement } from '../types/Utility';

const ChooseCoop = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const selectCoop = useSetSelectedCoop();
    const logout = useLogout();
    const { data = [], refetch: refetchCoops, isFetching } = useCoops();
    const [searchedText, setSearchedText] = useState<string | undefined>('');

    const selectCurrentCoop = useCallback(
        (id: number) => {
            selectCoop(id);
        },
        [selectCoop],
    );

    const filteredData = useMemo(
        () =>
            data
                .filter(({ name }) => name.toLowerCase().includes(searchedText?.toLowerCase() ?? ''))
                .sort(({ name: aName }, { name: bName }) => aName.localeCompare(bName)),
        [data, searchedText],
    );

    const handleLogout = () => logout();

    const renderItem: ListRenderItem<ArrayElement<typeof data>> = ({ item: coop, index }) => (
        <>
            <TouchableOpacity key={coop.id} style={themedStyle.coop} onPress={() => selectCurrentCoop(coop.id)}>
                <Text style={themedStyle.coopName}>{coop.name}</Text>
            </TouchableOpacity>
            {index === data.length - 1 ? (
                <SecondaryButton onPress={handleLogout} style={themedStyle.logoutButton} text="Logg ut" />
            ) : null}
        </>
    );

    return (
        <SafeAreaView style={themedStyle.main} mode="padding">
            <QueryView
                isRefreshing={isFetching}
                onRefresh={refetchCoops}
                style={themedStyle.scroll}
                top={
                    <View style={themedStyle.top}>
                        <Text style={themedStyle.title}>{t('chooseCoop:title')}</Text>
                        {data.length > 5 ? (
                            <ControlledInput autoFocus onChange={setSearchedText} value={searchedText ?? ''} />
                        ) : null}
                    </View>
                }
                data={filteredData}
                renderItem={renderItem}
                stickyTop
                emptyList={<Text style={themedStyle.emptyMessage}>{t('chooseCoop:noCoops')}</Text>}
                keyboardShouldPersistTaps
            />
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        top: { display: 'flex', backgroundColor: theme.white },
        main: {
            height: '100%',
            display: 'flex',
            backgroundColor: theme.mainBackground,
        },
        scroll: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
        },
        title: {
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: WW * 0.04,
            marginTop: WH * 0.01,
        },
        coop: {
            borderTopWidth: 2,
            borderColor: theme.accent,
            borderBottomWidth: 2,
            marginTop: WH * 0.02,
            marginBottom: WH * 0.02,
            paddingTop: WH * 0.01,
            paddingBottom: WH * 0.01,
        },
        coopName: {
            fontSize: WW * 0.04,
            fontWeight: 'bold',
        },
        coopCity: {
            paddingLeft: WW * 0.02,
        },
        logoutButton: {
            marginBottom: WH * 0.04,
        },
        emptyMessage: {
            marginTop: WH * 0.05,
            marginBottom: WH * 0.05,
        },
    });

export default ChooseCoop;
