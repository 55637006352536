import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text } from 'react-native';
import { Theme, useThemeStyle } from '../../../../_utils';
import { CheckBox, Modal } from '../../../../Components';

interface CancelActivityModal {
    onDismiss(): void;
    onCancel(refund: boolean): void;
    hasCost: boolean;
    hasAttendees: boolean;
}

const CancelActivityModal = ({ onDismiss, onCancel, hasAttendees, hasCost }: CancelActivityModal): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const [refund, setRefund] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);

    const type = hasAttendees ? 'cancel' : 'delete';
    const toggleRefund = () => setRefund((curr) => !curr);

    const handleCancel = async () => {
        setIsCancelling(true);
        onCancel(refund);
    };

    return (
        <Modal
            onDismiss={onDismiss}
            header={t('activity:cancelModal:header', { count: type === 'cancel' ? 1 : 2 })}
            content={
                <>
                    {hasAttendees ? (
                        <Text style={themedStyle.description}>{t('activity:cancelModal:description')}</Text>
                    ) : null}
                    {hasCost && hasAttendees ? (
                        <CheckBox
                            style={themedStyle.checkbox}
                            title={t('activity:cancelModal:checkbox')}
                            onPress={toggleRefund}
                            checked={refund}
                        />
                    ) : null}
                </>
            }
            buttons={[
                { text: t('activity:cancelModal:back'), onPress: onDismiss },
                {
                    text: t('activity:cancelModal:approve', { count: type === 'cancel' ? 1 : 2 }),
                    onPress: handleCancel,
                    type: 'danger',
                    isLoading: isCancelling,
                },
            ]}
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        description: {
            fontSize: 14,
            color: theme.secondaryText,
            textAlign: 'center',
            marginBottom: 12,
        },
        checkbox: {
            alignSelf: 'center',
        },
    });

export default CancelActivityModal;
