import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text } from 'react-native';
import _fonts from '_fonts';
import { useThemeStyle, WH, WW, Theme } from '_utils';
import { FormInput, HeaderWithNav, Icon, PrimaryButton } from 'Components';
import { PageContainer } from './common';

const ActivityLocation = ({ goBack, goToPreview }: { goBack(): void; goToPreview(): void }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    return (
        <>
            <HeaderWithNav title={t('newActivity:location')} onPress={goBack} />
            <PageContainer>
                <Text style={themedStyle.title}>{t('newActivity:choose_location')}</Text>
                <FormInput
                    leftImage={<Icon name="location" color="mediumGrey" />}
                    placeholder={t('newActivity:enter_location')}
                    name="location"
                    optional
                    label={t('newActivity:location')}
                    autoFocus
                />
            </PageContainer>
            <PrimaryButton text={t('newActivity:continue').toUpperCase()} onPress={goToPreview} bottomAction="modal" />
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        title: {
            marginTop: WW * 0.04,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WH * 0.035,
            color: theme.black,
        },
    });

export default ActivityLocation;
