import React, { ComponentProps, memo, ReactElement } from 'react';
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';
import { CacheImage } from '.';
import Icon from './Icon/Icon';
import ListItem from './ListItem';
import { useGetUser } from '../_api/useUsers';
import Images from '../_images';
import { useAppNavigation } from '../_navigator';
import { Theme, useThemeStyle, WW } from '../_utils';
import { getUsernameFromProfile } from '../_utils/misc';

interface UserItemProps {
    title?: string;
    desc?: string;
    titleColor?: string;
    descColor?: string;
    id: number;
    icon?: ComponentProps<typeof Icon>['name'];
    actionButton?: ReactElement;
    containerStyle?: StyleProp<ViewStyle>;
    disableProfileNavigate?: boolean;
    noBottomBorder?: boolean;
    onPress?: () => void;
    numberOfLines?: ComponentProps<typeof ListItem>['numberOfLines'];
}

const UserListItem = ({
    title,
    titleColor,
    desc,
    descColor,
    id,
    icon,
    actionButton,
    containerStyle,
    disableProfileNavigate = false,
    noBottomBorder = false,
    onPress,
    numberOfLines,
}: UserItemProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const user = useGetUser(id);
    const { navigate } = useAppNavigation();

    const handleNavigateToProfile = () => {
        if (!user) {
            return;
        }

        navigate('Profile', { id });
    };

    return (
        <ListItem
            onPress={disableProfileNavigate ? undefined : (onPress ?? handleNavigateToProfile)}
            avatar={
                <>
                    <CacheImage source={user?.avatar ? user.avatar : Images.defaultAvatar} style={themedStyle.avatar} />
                    {icon ? (
                        <View style={themedStyle.adminIconContainer}>
                            <Icon name={icon} color="lightRed" scale={1.5} />
                        </View>
                    ) : null}
                </>
            }
            title={user ? getUsernameFromProfile(user) : (title ?? '')}
            subtitle={desc}
            titleStyle={titleColor ? { color: titleColor } : undefined}
            subtitleStyle={descColor ? { color: descColor } : undefined}
            actionButton={actionButton}
            containerStyle={[containerStyle, noBottomBorder ? themedStyle.noBorderBottom : undefined]}
            numberOfLines={numberOfLines}
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        adminIconContainer: {
            position: 'absolute',
            right: 0,
            bottom: 0,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 8,
            backgroundColor: theme.lightGrey,
            borderRadius: (WW * 0.18) / 2,
        },
        avatar: {
            width: WW * 0.18,
            height: WW * 0.18,
            borderRadius: (WW * 0.18) / 2,
            overflow: 'hidden',
        },
        noBorderBottom: {
            borderBottomWidth: 0,
        },
    });

export default memo(UserListItem);
